import React from 'react';

import Config from 'config';

const TickShield = ({ height, width, color, circleColor, ...props }) => (
  <svg height={height} viewBox="0 0 95 95" width={width} {...props}>
    <circle cx="47.5" cy="47.5" r="47.5" fill={circleColor} />
    <path
      clipRule="evenodd"
      d="M67.998 33.562C59.97 32.85 52.38 28.692 47.078 25c-.03.031-.062.031-.093.062-.032-.031-.063-.031-.095-.062-5.269 3.692-12.86 7.849-20.889 8.562-.03 4.343.502 9.71 1.349 15.604C29.107 61.761 41.684 72.122 47.016 76c5.3-3.847 17.878-14.208 19.665-26.834.784-5.894 1.349-11.292 1.317-15.604zM37.481 52.17a1.978 1.978 0 0 1-.236-2.805l.046-.054a2.022 2.022 0 0 1 2.831-.237l5.772 4.833 9.38-11.792a2.022 2.022 0 0 1 2.85-.311l.06.048c.848.687.982 1.92.304 2.774l-10.74 13.5a2.022 2.022 0 0 1-2.85.311l-.059-.048a1.988 1.988 0 0 1-.326-.334 2.13 2.13 0 0 1-.098-.077l-6.934-5.808z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

TickShield.defaultProps = {
  circleColor: Config.theme.colors.black,
  color: Config.theme.colors.white,
  height: 95,
  width: 95,
};

export default TickShield;
