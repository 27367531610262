import Config from 'config';
import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  dashboard: {
    url: '/accounts/dashboard',
    helpers: {
      get: () => OAuth.getAuthHeader().then(header => [{}, header]),
    },
    transformer: ({ data: { accounts } }) =>
      accounts.map(
        ({ account: { id, reservedMoneyItems, ...account }, ...details }) => ({
          accountId: id,
          reservedMoneyItems: reservedMoneyItems.items || [],
          ...account,
          ...details,
        })
      ),
  },
  history: {
    url: '/accounts/history?accountId=:accountId',
    helpers: {
      find: accountId =>
        OAuth.getAuthHeader().then(header => [{ accountId }, header]),
    },
    transformer: ({ data }) =>
      data.items.map(item => ({
        ...item,
        utcTimestamp: item.utcTimestamp
          ? new Date(item.utcTimestamp)
          : undefined,
      })),
  },
  accounts: {
    url: '/accounts',
    helpers: {
      findAll: () => OAuth.getAuthHeader().then(header => [{}, header]),
    },
    transformer: ({ data }) => ({
      ...data,
      items: data.items.map(({ id, reservedMoneyItems, ...item }) => ({
        accountId: id,
        reservedMoneyItems: reservedMoneyItems.items || [],
        ...item,
      })),
    }),
  },
  accountWithdraw: {
    url: '/accounts/:accountId/:action',
    helpers: {
      withdraw: (accountId, bankId, amount) =>
        OAuth.getAuthHeader().then(header => [
          {
            accountId,
            action: 'withdraw',
          },
          {
            data: { amount, bankId },
            method: 'post',
            transformRequest: [
              data => ({
                bankDetailsId: data.bankId,
                amount: { value: data.amount },
              }),
            ],
            ...header,
          },
        ]),
      withdrawals: accountId =>
        OAuth.getAuthHeader().then(header => [
          {
            accountId,
            action: 'withdrawals',
          },
          header,
        ]),
    },
    transformer: ({ data }) => data,
  },
  cancelWithdrawal: {
    url: '/accounts/:accountId/withdraw/:uuid',
    helpers: {
      cancel: (accountId, uuid) =>
        OAuth.getAuthHeader().then(header => [
          {
            accountId,
            uuid,
          },
          {
            method: 'delete',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  myIsa: {
    url: '/accounts/isa',
    helpers: {
      create: data =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data,
            method: 'post',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  bankDetails: {
    url: '/accounts/:accountId/bank-details/:bankId/transfer-declarations',
    helpers: {
      transfer: (accountId, bankId, amount) =>
        OAuth.getAuthHeader().then(header => [
          { accountId, bankId, amount },
          {
            data: amount,
            method: 'post',
            transformRequest: [data => ({ amount: { value: data } })],
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  cards: {
    url: '/accounts/cards',
    helpers: {
      findAll: () => OAuth.getAuthHeader().then(header => [{}, header]),
    },
    transformer: ({ data }) =>
      data.items.map(item => {
        const transformedItem = { ...item };
        if (typeof transformedItem.expiryDate === 'undefined') {
          transformedItem.expiryDate = {
            month: '',
            year: '',
          };
        }
        return transformedItem;
      }),
  },
  cardsRemove: {
    url: '/accounts/cards/:cardId',
    helpers: {
      remove: cardId =>
        OAuth.getAuthHeader().then(header => [
          { cardId },
          {
            method: 'delete',
            ...header,
          },
        ]),
    },
  },
  cardsDetails: {
    url: '/accounts/cards/:cardId',
    helpers: {
      get: cardId => OAuth.getAuthHeader().then(header => [{ cardId }, header]),
    },
    transformer: ({ data }) => data,
  },
  /**
   * @deprecated will be removed after full migration to 3DSv2
   */
  cardsTopupV1: {
    url: '/accounts/:accountId/cards/topups',
    helpers: {
      createCard: (accountId, cardDetails) =>
        OAuth.getAuthHeader().then(header => [
          { accountId },
          {
            data: cardDetails,
            method: 'post',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  cardsTopup: {
    url: '/accounts/:accountId/cards/topups/v2',
    helpers: {
      createCard: ({ accountId, card, deviceFingerprintingId }) =>
        OAuth.getAuthHeader().then(header => [
          { accountId },
          {
            data: {
              ...card,
              deviceFingerprintingId,
            },
            method: 'post',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  /**
   * @deprecated will be removed after full migration to 3DSv2
   */
  cardsTopupTokenV1: {
    url: '/accounts/:accountId/cards/:cardId/topups/token',
    helpers: {
      topup: (accountId, cardId, amount) =>
        OAuth.getAuthHeader().then(header => [
          { accountId, cardId },
          {
            data: amount,
            method: 'post',
            transformRequest: [data => ({ amount: { value: data } })],
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  cardsTopupToken: {
    url: '/accounts/:accountId/cards/:cardId/topups/token/v2',
    helpers: {
      topup: ({ accountId, cardId, amount, deviceFingerprintingId }) =>
        OAuth.getAuthHeader().then(header => [
          { accountId, cardId },
          {
            data: amount,
            method: 'post',
            transformRequest: [
              data => ({ amount: { value: data }, deviceFingerprintingId }),
            ],
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  cardsTopupTransaction: {
    url: '/accounts/:accountId/cards/topups/:uuid',
    helpers: {
      get: (accountId, uuid) =>
        OAuth.getAuthHeader().then(header => [{ accountId, uuid }, header]),
    },
    transformer: ({ data }) => data,
  },
  cardsTopupThreeDSecureContinue: {
    url:
      '/accounts/cards/topups/three-d/continue/:orderUuid/:authenticationId/v2',
    helpers: {
      post: (orderUuid, authenticationId) =>
        OAuth.getAuthHeader().then(header => [
          { orderUuid, authenticationId },
          { method: 'post', ...header },
        ]),
    },
  },
  transfer: {
    url: '/accounts/transfer',
    helpers: {
      transfer: (sourceAccountId, destinationAccountId, amount) =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: {
              sourceAccountId,
              destinationAccountId,
              amount: {
                value: amount,
              },
            },
            method: 'post',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
