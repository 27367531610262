import { Tab as BaseTab } from 'react-tabs';
import styled from 'styled-components';

import Config from 'config';

const Tab = styled(BaseTab)`
  background-color: ${({ selected }) =>
    selected ? Config.theme.colors.white : 'transparent'};
  border-radius: 2rem;
  color: ${Config.theme.colors.black};
  cursor: pointer;
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.75rem;
  font-weight: ${Config.theme.fontWeight.medium};
  padding: 0.4375rem 1.5rem;
  text-align: center;
  white-space: nowrap;

  &:focus {
    box-shadow: 0 0 0 2px ${Config.theme.colors.highlight};
    outline: none;
  }
`;

// $FlowFixMe
Tab.tabsRole = 'Tab';

export default Tab;
