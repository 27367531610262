import React from 'react';

const Quote = props => (
  <svg width="70" height="50" viewBox="0 0 70 50" fill="none" {...props}>
    <path
      d="M29.735 1.474L7.524 1.474L7.524 24.52L16.041 24.52C16.876 33.037 12.033 38.214 1.846 40.051L4.017 48.735C20.884 47.399 29.735 39.55 29.735 21.013L29.735 1.474ZM68.479 1.474L46.268 1.474L46.268 24.52L54.785 24.52C55.62 33.037 50.777 38.214 40.59 40.051L42.761 48.735C59.628 47.399 68.479 39.55 68.479 21.013L68.479 1.474Z"
      fill="currentColor"
    />
  </svg>
);

export default Quote;
