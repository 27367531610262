import { Variant } from './types';

export const getCalculatorVariant = (variant: Variant): string => {
  switch (variant) {
    case 'isaLoans':
    case 'loans':
      return 'loans';
    case 'shares':
      return 'shares';
    default:
      return '';
  }
};
