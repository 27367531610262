import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    paddingTop: spacing(8),
    paddingBottom: spacing(2),

    [breakpoints.up('lg')]: {
      paddingTop: spacing(10),
      paddingBottom: spacing(4),
    },
  },
  tabList: {
    padding: 0,
    margin: 0,
    [breakpoints.down('lg')]: {
      marginBottom: spacing(3),
    },
  },
  tabIcon: {
    width: '24px',
    height: '24px',
    marginRight: spacing(2),
    color: palette.primary.main,
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  tabPanelsContainer: {
    [breakpoints.up('lg')]: {
      paddingLeft: spacing(6),
    },
  },
}));

export const useDataStyles = makeStyles(({ spacing }) => ({
  underlined: {
    textDecoration: 'underline',
  },
  textCentered: {
    textAlign: 'center',
  },
  listItem: {
    paddingBottom: spacing(4),
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  tableCell: {
    border: '1px solid black',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  step: {
    cursor: 'auto',
  },
}));
