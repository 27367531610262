import React from 'react';

import Config from 'config';

const Examine = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none">
      <path
        d="M2 23V1h15v4h1V.923C18.044.443 17.5 0 17 0H2c-.5.006-1 .464-1 1v22c0 .6.53 1 1 1h15.014c.544 0 .986-.407.986-1v-5h-.986v5H2z"
        fill={fill}
      />
      <path fill={fill} d="M12 10h5V9h-5m0 3h5v-1h-5m0 3h3v-1h-3" />
      <path
        d="M3 5h7V4H3m0 3h7V6H3m0 3h5V8H3m0 3h5v-1H3m0 3h5v-1H3m2 9h1v-2H5m8 2h1v-2h-1m-2 2h1v-1h-1m-4 1h1v-4H7m2 4h1v-3H9"
        fill={fill}
      />
      <path
        d="M14.5 17C11.462 17 9 14.538 9 11.5S11.462 6 14.5 6 20 8.462 20 11.5 17.538 17 14.5 17zm0-.917c2.53 0 4.583-2.052 4.583-4.583 0-2.53-2.052-4.583-4.583-4.583-2.53 0-4.583 2.052-4.583 4.583 0 2.53 2.052 4.583 4.583 4.583z"
        fill={fill2}
      />
      <path fill={fill2} d="M18 14.653L22.347 19l.653-.653L18.653 14" />
    </g>
  </svg>
);

Examine.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default Examine;
