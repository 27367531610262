/* eslint-disable no-underscore-dangle */

import _i18n from 'es2015-i18n-tag';
import { List, Map } from 'immutable';
import { isEmpty } from 'lodash';
import React, { FC, useMemo } from 'react';
import shortid from 'shortid';
// @ts-ignore
import Interpolate from 'react-interpolate-component';

import { ExternalLink } from 'components/Atoms/ExternalLink';
import { useI18n } from 'utils/i18nConnect';

import { ErrorMessage } from '../ErrorMessage';
import { useStyles } from './ErrorMessages.styles';

interface ErrorMessagesProps {
  errors: {
    _error: {
      id: string;
    }[];
  };
}

// TODO refactor error handling across whole app https://britishpearl.atlassian.net/browse/PIT-1993
export const ErrorMessages: FC<ErrorMessagesProps> = ({
  errors: errorsFromProps,
}) => {
  const t = useI18n()('errors');
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translateError = (err: any): string => {
    let errorId = typeof err === 'string' ? err : err.id || '';

    if (typeof err !== 'string' && err._error && err._error.length) {
      errorId = err._error[0].id;
    }

    // Use vendor _i18n to compare as string
    const translated = _i18n('errors')`${errorId}:s(error)`;

    return translated === errorId
      ? t`Something has gone wrong. The team has been made aware.`
      : t`${errorId}:s(error)`;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extractFields = (err: any): string[] => {
    if (typeof err !== 'string' && !err.id && err.fields) {
      const formError = err;
      const fields = Object.keys(err.fields);
      return fields.map(field =>
        formError.fields
          ? translateError({ _error: formError.fields[field] })
          : ''
      );
    }

    return [];
  };

  const errors = useMemo(() => {
    const errorsAsArray =
      List.isList(errorsFromProps) || Map.isMap(errorsFromProps)
        ? errorsFromProps.toJS()
        : errorsFromProps;

    if (!errorsAsArray || isEmpty(errorsAsArray)) {
      return [];
    }

    return Array.isArray(errorsAsArray)
      ? errorsAsArray.filter(Boolean)
      : [errorsAsArray];
  }, [errorsFromProps]);

  return (
    <ErrorMessage>
      {errors.map(error => {
        const message = translateError(error);
        const fields = extractFields(error);

        return (
          <div key={shortid.generate()} className={classes.strongText}>
            <Interpolate
              with={{
                supportEmail: (
                  <ExternalLink href="mailto:support@britishpearl.com">
                    support@britishpearl.com
                  </ExternalLink>
                ),
              }}
            >
              {message}
            </Interpolate>

            {fields.length > 0 && (
              <ul>
                {fields.map(field => {
                  const fieldError = translateError(field);
                  return <li key={shortid.generate()}>{fieldError}</li>;
                })}
              </ul>
            )}
          </div>
        );
      })}
    </ErrorMessage>
  );
};
