import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ButtonLink } from 'components/Atoms/Button';
import { Section } from 'components/Molecules/Section';
import { useI18n } from 'utils/i18nConnect';
import { commonPaths } from 'router/paths';

export const AboutBanner: FC = () => {
  const t = useI18n()('AboutBanner');

  return (
    <Section color="black" size="large">
      <Grid container spacing={2} justify="space-between" alignItems="flex-end">
        <Grid item md={8}>
          <Typography variant="h3" paragraph>
            {t`We’re British Pearl, a property investment platform.`}
          </Typography>
          <Typography variant="body1">
            {t`We specialise in fractionalised property investing. We offer people
            who may not have the time, resources, or appetite to fully fund a
            property investment a way to take advantage of the benefits of
            property investing - with none of the hassle.`}
          </Typography>
        </Grid>
        <Grid item md="auto">
          <ButtonLink
            variant="contained"
            color="white"
            to={commonPaths.howItWorks()}
          >
            {t`How it works`}
          </ButtonLink>
        </Grid>
      </Grid>
    </Section>
  );
};
