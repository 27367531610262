// pip->pit API version 9.0.0

import { Unionize } from 'utility-types';

export namespace Pip {
  export enum AccountOperationSubtype {
    BankFund = 'BankFund',
    BankFundIncentive = 'BankFundIncentive',
    CardFund = 'CardFund',
    TransferIn = 'TransferIn',
    IsaMoveCurrentTaxYearContribution = 'IsaMoveCurrentTaxYearContribution',
    IsaMoveRemainder = 'IsaMoveRemainder',
    Withdraw = 'Withdraw',
    TransferOut = 'TransferOut',
    BuyShares = 'BuyShares',
    BuyLoans = 'BuyLoans',
    SellShares = 'SellShares',
    SellLoans = 'SellLoans',
    RollBackTrade = 'RollBackTrade',
    Dividend = 'Dividend',
    Interest = 'Interest',
    BuySharesParticipationFee = 'BuySharesParticipationFee',
    SellSharesLiquidationFee = 'SellSharesLiquidationFee',
    SellSharesPerformanceFee = 'SellSharesPerformanceFee',
    BuyLoansParticipationFee = 'BuyLoansParticipationFee',
    SellLoansLiquidationFee = 'SellLoansLiquidationFee',
    BuySharesStampDutyReserveTax = 'BuySharesStampDutyReserveTax',
    SellSharesPerformanceFeeValueAddedTax = 'SellSharesPerformanceFeeValueAddedTax',
    ShareExitProceedsWithHurdleRate = 'ShareExitProceedsWithHurdleRate',
    ShareExitProceedsWithoutHurdleRate = 'ShareExitProceedsWithoutHurdleRate',
    LoanExitProceeds = 'LoanExitProceeds',
    ShareExitRepurchase = 'ShareExitRepurchase',
    LoanExitRepurchase = 'LoanExitRepurchase',
  }

  export enum AccountOperationType {
    Credit = 'Credit',
    Debit = 'Debit',
  }

  export enum AccountType {
    Main = 'Main',
    LoanIsa = 'LoanIsa',
  }

  export enum AutomaticVerificationRequestStatus {
    AutomaticAccepted = 'AutomaticAccepted',
    AutomaticRejected = 'AutomaticRejected',
    AutomaticErrored = 'AutomaticErrored',
    AutomaticFlagged = 'AutomaticFlagged',
    ManuallyInProgress = 'ManuallyInProgress',
    ManuallyAccepted = 'ManuallyAccepted',
    ManuallyRejected = 'ManuallyRejected',
  }

  export enum CardBrand {
    AM = 'AM',
    DC = 'DC',
    JC = 'JC',
    MD = 'MD',
    MC = 'MC',
    SO = 'SO',
    VI = 'VI',
    VD = 'VD',
    VE = 'VE',
  }

  export enum CardTransactionState {
    InProgress = 'InProgress',
    Verification3D = 'Verification3D',
    Successful = 'Successful',
    Failed = 'Failed',
    AlreadyProcessed = 'AlreadyProcessed',
  }

  export enum ClassificationMethod {
    HighNetInv = 'HighNetInv',
    SelfCertSophInv = 'SelfCertSophInv',
    RestrictedInv = 'RestrictedInv',
    QuizCertSophInv = 'QuizCertSophInv',
  }

  export enum ExecSubtype {
    UserRequest = 'UserRequest',
    PlatformRequest = 'PlatformRequest',
    TransitionToPostOffer = 'TransitionToPostOffer',
    ImmediateExecution = 'ImmediateExecution',
    QuantityOrderFullyFilled = 'QuantityOrderFullyFilled',
    ClosingInvestment = 'ClosingInvestment',
    OwnershipLimitReached = 'OwnershipLimitReached',
    SelfTradePrevented = 'SelfTradePrevented',
    IsaAccountVoided = 'IsaAccountVoided',
    TradeRolledBack = 'TradeRolledBack',
    NotEnoughMoneyReserved = 'NotEnoughMoneyReserved',
    EndOfFirstEntryExecution = 'EndOfFirstEntryExecution',
    EndOfOrder = 'EndOfOrder',
  }

  export enum ExecType {
    New = 'New',
    PendingNew = 'PendingNew',
    FullFill = 'FullFill',
    PartialFill = 'PartialFill',
    Truncate = 'Truncate',
    Cancel = 'Cancel',
    Reject = 'Reject',
    Information = 'Information',
  }

  export enum ImmediateBidCappingReason {
    OwnershipLimitPossiblyReached = 'OwnershipLimitPossiblyReached',
    NoMoreAssetsAvailableOnOfferMatchingGivenUnitPrice = 'NoMoreAssetsAvailableOnOfferMatchingGivenUnitPrice',
  }

  export enum InvestmentState {
    Hidden = 'Hidden',
    OnPrimaryMarketPreOffer = 'OnPrimaryMarketPreOffer',
    OnPrimaryMarketPostOffer = 'OnPrimaryMarketPostOffer',
    OnPrimaryMarketFullyFunded = 'OnPrimaryMarketFullyFunded',
    OnSecondaryMarket = 'OnSecondaryMarket',
    Closing = 'Closing',
    Closed = 'Closed',
    Cancelled = 'Cancelled',
  }

  export enum InvestmentType {
    BuyToLetRefurbishment = 'BuyToLetRefurbishment',
    BuyToLetImmediate = 'BuyToLetImmediate',
    Development = 'Development',
  }

  export enum ManualVerificationRequestStatus {
    Pending = 'Pending',
    InProgress = 'InProgress',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
  }

  export enum OutExternalTransferState {
    Pending = 'Pending',
    MarkedForProcessing = 'MarkedForProcessing',
    InProgress = 'InProgress',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Cancelled = 'Cancelled',
  }

  export enum OwnershipType {
    Freehold = 'Freehold',
    Leasehold = 'Leasehold',
  }

  export enum PropertyDocumentType {
    CertificateOfIncorporation = 'CertificateOfIncorporation',
    CreditRiskAssessment = 'CreditRiskAssessment',
    InfoPack = 'InfoPack',
    PaymentSchedule = 'PaymentSchedule',
    SolicitorsReport = 'SolicitorsReport',
    SurveyorsReport = 'SurveyorsReport',
    TitleDeeds = 'TitleDeeds',
    Other = 'Other',
  }

  export enum PropertyType {
    House = 'House',
    Flat = 'Flat',
    BlockOfFlats = 'BlockOfFlats',
  }

  export enum Side {
    Bid = 'Bid',
    Offer = 'Offer',
  }

  export enum TenantReady {
    Ready = 'Ready',
    Refurbishment = 'Refurbishment',
    Development = 'Development',
  }

  export enum TradeNotionalSumMethod {
    BuyLoans = 'BuyLoans',
    BuyShares = 'BuyShares',
  }

  export enum UserStatus {
    Active = 'Active',
    Suspended = 'Suspended',
    Closed = 'Closed',
  }

  export enum VerificationVariant {
    AutomaticUk = 'AutomaticUk',
    ManualUk = 'ManualUk',
    ManualNonUk = 'ManualNonUk',
  }

  export interface AccountAllowanceDataView {
    allowedDepositAmount: Money;
    usedDepositAmount: Money;
    usedDepositAmountPercent: Percent;
    remainingAllowedDepositAmount: Money;
    remainingAllowedDepositAmountPercent: Percent;
    startDate: string; // ISO local date
    endDate: string; // ISO local date
  }

  export interface AccountBalanceView {
    accountId: number;
    balance: Money;
    currency: string;
  }

  export interface AccountDetailedView {
    id: number;
    userId: number;
    fullName: string; // SENSITIVE
    nationalInsuranceNumber?: string; // SENSITIVE
    accountType: AccountType;
    balance: Money;
    currency: string;
    referenceNumber: string; // SENSITIVE
    totalReservedAmount: Money;
    totalReservedBidAmount: Money;
    totalReservedWithdrawalAmount: Money;
    totalReservedAndBalanceAmount: Money;
    reservedMoneyItems: ReservedMoneyViewCollection;
    alreadyFunded: boolean;
    currentContribution?: Money;
  }

  export interface AccountDetailedViewCollection {
    allAccountsBalance: Money;
    allAccountsReservedAmount: Money;
    allAccountsReservedBidAmount: Money;
    allAccountsReservedWithdrawalAmount: Money;
    allAccountsReservedAndBalanceAmount: Money;
    items: AccountDetailedView[];
  }

  export interface AccountHistoryItemView {
    accountId: number;
    accountType: AccountType;
    amount: Money;
    balance: Money;
    accountOperationType: AccountOperationType;
    accountOperationSubtype: AccountOperationSubtype;
    investmentId?: number;
    investmentName?: string;
    utcTimestamp: string; // ISO zoned date time
    createdAt: string; // ISO zoned date time
  }

  export interface AccountHistoryItemViewCollection {
    items: AccountHistoryItemView[];
  }

  export interface AccountPortfolioView {
    account: AccountDetailedView;
    sharesValuation?: Money;
    loanPrincipal: Money;
    dividends?: Money;
    interestReceived: Money;
    interestAccrued: Money;
    realisedCapitalGainOrLoss: Money;
    unrealisedCapitalGainOrLoss: Money;
    profitAndLoss: Money;
    fees: Money;
    taxes: Money;
    deposit: Money;
    withdrawals: Money;
    pendingExternalWithdrawalsAmount: Money;
    acceptedExternalWithdrawalsAmount: Money;
    transfersInAmount: Money;
    transfersOutAmount: Money;
    bankFundsAmount: Money;
    cardFundsAmount: Money;
    totalNetCash: Money;
  }

  export interface AddressVerifyView {
    addressLine1: string; // SENSITIVE
    buildingName?: string; // SENSITIVE
    buildingNumber?: string; // SENSITIVE
    flatNumber?: string; // SENSITIVE
    town: string; // SENSITIVE
    postcode: string; // SENSITIVE
    countryCodeIso2: string;
  }

  export interface AddressView {
    id: number;
    addressLine1: string; // SENSITIVE
    buildingName?: string; // SENSITIVE
    buildingNumber?: string; // SENSITIVE
    flatNumber?: string; // SENSITIVE
    town: string; // SENSITIVE
    postcode: string; // SENSITIVE
    countryCodeIso2: string;
    latitude?: number;
    longitude?: number;
    ukGssCode?: string;
  }

  export interface AmenityView {
    key: string;
    value: number | boolean;
  }

  export interface AssetTransactionCostView {
    quantity: Quantity;
    averageUnitPrice?: UnitPrice;
    cleanAmount: Money;
    fees: FeeView[];
    feesAmount: Money;
    taxes: FeeView[];
    taxesAmount: Money;
    capitalGainOrLoss: Money;
  }

  export interface BankTransferDetailsView {
    cmaBankAccountDetails: CmaBankAccountDetails;
    referenceNumber: string; // SENSITIVE
  }

  export interface BestBidAndOffer {
    bestBid?: UnitPrice;
    bestOffer?: UnitPrice;
  }

  export interface BuyAssetTransactionCostImmediateAndStandingView {
    immediateCost: AssetTransactionCostView;
    standingCost: AssetTransactionCostView;
    totalQuantity: Quantity;
    totalCleanAmount: Money;
    totalFeesAmount: Money;
    totalTaxesAmount: Money;
    totalCleanPlusFeesAmount: Money;
    totalCleanPlusFeesAndTaxesAmount: Money;
    tcVersionToSign?: number;
    immediateBidCappingReason?: ImmediateBidCappingReason;
    selfTradeCancelledOrderData: SelfTradeCancelledOrderData[];
  }

  export interface CardDetails {
    cardNumber: string; // SENSITIVE
    expiryDate: CardExpiry; // SENSITIVE
  }

  export interface CardExpiry {
    month: number;
    year: number;
  }

  export interface CardTopUpResultView {
    orderUuid: string;
    account?: AccountBalanceView;
    threeDInitiate: boolean;
    sdkChallengePayload?: string;
  }

  export interface CardTransactionView {
    orderUuid: string;
    amount: Money;
    state: CardTransactionState;
    timestamp: string; // ISO zoned date time
  }

  export interface ClientError {
    correlationId: string;
    errors: ErrorMessage[];
  }

  export interface CmaBankAccountDetails {
    name: string;
    number: string;
    sortCode: string; // SENSITIVE
  }

  export interface ComingSoonInvestmentImageView {
    id: number;
    title: string; // SENSITIVE
    sourceUrl?: string;
    resizedImages: ResizedImage[];
  }

  export interface ComingSoonInvestmentView {
    id: number;
    town: string; // SENSITIVE
    postcode: string; // SENSITIVE
    imageView: ComingSoonInvestmentImageView;
    ownershipType?: OwnershipType;
    propertyType?: PropertyType;
    tenantReady?: TenantReady;
  }

  export interface ConfirmEmailToken {
    id?: number;
    token: string; // SENSITIVE
    userId: number;
    confirmationDateTime?: string; // ISO zoned date time
  }

  export interface ConsumeQuizTryView {
    classificationMethod?: ClassificationMethod;
    version: string;
  }

  export interface DashboardAccountView {
    account: AccountDetailedView;
    sharesValuation: Money;
    loanPrincipal: Money;
    loanAccrued: Money;
  }

  export interface DashboardView {
    accounts: DashboardAccountView[];
  }

  export interface DeclareBankTransferDetailsView {
    amount: Money;
  }

  export interface DeveloperDetailView {
    company: string; // SENSITIVE
    email: string; // SENSITIVE
    firstName: string; // SENSITIVE
    lastName: string; // SENSITIVE
    phone: string; // SENSITIVE
  }

  export interface ErrorMessage {
    key: string;
    msg: string;
    message?: string;
    args: { [key: string]: string };
  }

  export interface ExpiryDateView {
    month: number;
    year: number;
  }

  export interface FeeRuleView {
    investmentState: InvestmentState;
    accountOperationSubtype: AccountOperationSubtype;
    feeType: AccountOperationSubtype;
    percent: Percent;
  }

  export interface FeeView {
    feeRuleView: FeeRuleView;
    feeAmount: Money;
  }

  export interface FlatArea {
    value: number;
  }

  export interface HpiValuesView {
    year1?: Percent;
    year3?: Percent;
    year5?: Percent;
    total?: Percent;
  }

  export interface IbanBankDetailsView {
    id: number;
    userId: number;
    ibanAccountNumber: string; // SENSITIVE
    swiftBicCode: string; // SENSITIVE
    bank?: string; // SENSITIVE
    visible: boolean;
  }

  export interface IbanBankDetailsViewCollection {
    items: IbanBankDetailsView[];
  }

  export interface IncentivesView {
    tradeNotionalCashbackIncentives: TradeNotionalCashbackIncentive[];
  }

  export interface InternalTransferResultView {
    sourceAccountView: AccountBalanceView;
    destinationAccountView: AccountBalanceView;
    amount: Money;
  }

  export interface InternalTransferView {
    sourceAccountId: number;
    destinationAccountId: number;
    amount: Money;
  }

  export interface InvestmentAndComingSoonInvestmentViewCollectionV1 {
    investmentItems: InvestmentAndPropertyDetailedViewV1[];
    comingSoonInvestmentItems: ComingSoonInvestmentView[];
  }

  export interface InvestmentAndComingSoonInvestmentViewCollectionV2 {
    investmentItems: InvestmentAndPropertyDetailedViewV2[];
    comingSoonInvestmentItems: ComingSoonInvestmentView[];
  }

  export interface InvestmentAndPropertyDetailedViewV1 {
    investment: InvestmentViewV1;
    propertyDetailed: PropertyDetailedViewV1;
  }

  export interface InvestmentAndPropertyDetailedViewV2 {
    investment: InvestmentViewV2;
    propertyDetailed: PropertyDetailedViewV2;
  }

  export interface InvestmentAssetSummary {
    assetQuantity: Quantity;
    indicativeUnitPrice: UnitPrice;
    indicativeValuation: Money;
    allocatedProfit: Money;
    accruedInterest?: Money;
  }

  export interface InvestmentDetailsView {
    initialShareQuantity: Quantity;
    initialShareUnitPrice: UnitPrice;
    estimatedShareUnitPrice: UnitPrice;
    estimatedShareDividendRate: Percent;
    initialLoanQuantity: Quantity;
    initialLoanUnitPrice: UnitPrice;
    loanInterestRate: Percent;
    preOfferLoanQuantity: Quantity;
    preOfferShareQuantity: Quantity;
    fundedLoanQuantity: Quantity;
    fundedShareQuantity: Quantity;
  }

  export interface InvestmentPortfolio {
    investmentId: number;
    investmentName: string;
    investmentState: InvestmentState;
    investmentSuspended: boolean;
    investmentUpForSale: boolean;
    propertyDetailed: PropertyDetailedViewV1;
    loanSummary?: InvestmentAssetSummary;
    shareSummary?: InvestmentAssetSummary;
    marketplaceOfAccount: MarketplaceView;
  }

  export interface InvestmentPortfolioCollection {
    items: InvestmentPortfolio[];
  }

  export interface InvestmentViewV1 {
    id: number;
    name: string;
    displayName: string;
    investmentType: InvestmentType;
    propertyId: number;
    spvCompanyName: string;
    initialShareQuantity: Quantity;
    initialShareUnitPrice: UnitPrice;
    estimatedShareUnitPrice: UnitPrice;
    estimatedShareDividendRate: Percent;
    initialLoanQuantity: Quantity;
    initialLoanUnitPrice: UnitPrice;
    loanInterestRate: Percent;
    state: InvestmentState;
    preOfferLoanQuantity: Quantity;
    preOfferShareQuantity: Quantity;
    fundedLoanQuantity: Quantity;
    fundedShareQuantity: Quantity;
    description: string;
    suspended: boolean;
    expectedExitDate: string; // ISO local date
    valuations?: InvestmentViewValuationOnDateViewCollectionByTypeView;
  }

  export interface InvestmentViewV2 {
    id: number;
    name: string;
    displayName: string;
    subDisplayName: string;
    investmentType: InvestmentType;
    propertyId: number;
    spvCompanyName: string;
    state: InvestmentState;
    investmentDetailsView: InvestmentDetailsView;
    description: string;
    suspended: boolean;
    expectedExitDate: string; // ISO local date
    ordinalNumber: number;
    valuations?: InvestmentViewValuationOnDateViewCollectionByTypeView;
    spvOwnershipStartDateTime: string; // ISO zoned date time
    referenceNumberOrdinalSuffix: string;
    upForSale: boolean;
  }

  export interface InvestmentViewValuationOnDateViewCollectionByTypeView {
    Desktop?: ValuationOnDateViewCollection;
    Surveyor?: ValuationOnDateViewCollection;
    HPI?: ValuationOnDateViewCollection;
  }

  export interface InvestmentWithOrdersView {
    investmentId: number;
    investmentState: InvestmentState;
    propertyDetailed: PropertyDetailedViewV1;
    shareBestBidOffer: BestBidAndOffer;
    loanBestBidOffer: BestBidAndOffer;
    shareOrders: OrderView[];
    loanOrders: OrderView[];
  }

  export interface InvestmentWithOrdersViewCollection {
    items: InvestmentWithOrdersView[];
  }

  export interface InvestorOrderPlacedView {
    order: InvestorOrderView;
    uuid: string;
  }

  export interface InvestorOrderView {
    unverifiedAccountId: number;
    investmentId: number;
    side: Side;
    amount?: Money;
    quantity?: Quantity;
    unitPrice?: UnitPrice;
    expiryDateTime?: string; // ISO zoned date time
  }

  export interface IsaAccountCreateView {
    nationalInsuranceNumber?: string; // SENSITIVE
  }

  export interface LoanIsaAccountPortfolioView {
    loanIsaAccountPortfolio: AccountPortfolioView;
    accountAllowanceData: AccountAllowanceDataView;
  }

  export interface ManualVerificationDocumentChecklistItem {
    uploadedDocumentsCount: number;
    uploadedDocumentsLimit: number;
  }

  export interface ManualVerificationDocumentsChecklistView {
    AddressProof?: ManualVerificationDocumentChecklistItem;
    IdWithPhoto?: ManualVerificationDocumentChecklistItem;
    FundSource?: ManualVerificationDocumentChecklistItem;
  }

  export interface MarketplaceItemView {
    quantity: Quantity;
    unitPrice: UnitPrice;
    orderUuid?: string;
  }

  export interface MarketplaceItemViewCollection {
    items: MarketplaceItemView[];
  }

  export interface MarketplaceView {
    investmentId: number;
    shareBid: MarketplaceItemViewCollection;
    shareOffer: MarketplaceItemViewCollection;
    loanBid: MarketplaceItemViewCollection;
    loanOffer: MarketplaceItemViewCollection;
  }

  export interface Money {
    value: number;
  }

  export interface NoticeView {
    id: number;
    title: string; // SENSITIVE
    summary: string;
    documentUrl?: string;
    createdAt: string; // ISO zoned date time
  }

  export interface NoticeViewCollection {
    items: NoticeView[];
  }

  export interface OAuthAccessToken {
    scope?: string;
    refresh_token?: string; // SENSITIVE
    expires_in?: number; // seconds
    access_token: string; // SENSITIVE
    token_type: string;
  }

  export interface OrderExecView {
    uuid: string;
    accountId: number;
    investmentId: number;
    execType: ExecType;
    execSubtype?: ExecSubtype;
    side: Side;
    amount?: Money;
    quantity?: Quantity;
    quantityLeft?: Quantity;
    originalUnitPrice: UnitPrice;
    transactionUnitPrice?: UnitPrice;
    utcTimestamp: string; // ISO zoned date time
    createdAt: string; // ISO zoned date time
  }

  export interface OrderExecViewCollection {
    items: OrderExecView[];
  }

  export interface OrderView {
    uuid: string;
    accountId: number;
    side: Side;
    quantity: Quantity;
    unitPrice: UnitPrice;
  }

  export interface Percent {
    value: number;
  }

  export interface PropertyDetailedViewV1 {
    property: PropertyViewV1;
    address?: AddressView;
    amenities: AmenityView[];
    tagIds: number[];
    cover?: PropertyImageView;
    gallery: PropertyImageView[];
    documents: PropertyDocumentView[];
    floorPlans: PropertyFloorPlanView[];
    financialDetails?: PropertyFinancialDetailViewV1;
  }

  export interface PropertyDetailedViewV2 {
    property: PropertyViewV2;
    address?: AddressView;
    amenities: AmenityView[];
    tagIds: number[];
    cover?: PropertyImageView;
    gallery: PropertyImageView[];
    documents: PropertyDocumentView[];
    floorPlans: PropertyFloorPlanView[];
    financialDetails: PropertyFinancialDetailViewV2;
  }

  export interface PropertyDocumentView {
    id: number;
    documentType: PropertyDocumentType;
    url: string;
  }

  export interface PropertyExpectedCapitalGainViewV1 {
    propertyExpectedCapitalGainFirstYear?: PropertyExpectedCapitalGainViewV1PropertyExpectedCapitalGainViewDto;
    propertyExpectedCapitalGainFiveYearAverage?: PropertyExpectedCapitalGainViewV1PropertyExpectedCapitalGainViewDto;
  }

  export interface PropertyExpectedCapitalGainViewV1PropertyExpectedCapitalGainViewDto {
    id: number;
    capitalGainNominal: Money;
    capitalGain: Percent;
  }

  export interface PropertyExpectedCapitalGainViewV2 {
    capitalGainNominal: Money;
    capitalGain: Percent;
  }

  export interface PropertyExpectedIncomeViewV1 {
    propertyExpectedIncomeFirstYear?: PropertyExpectedIncomeViewV1PropertyExpectedIncomeViewDto;
    propertyExpectedIncomeFirstYearSum?: Money;
    propertyExpectedIncomeFiveYearAverage?: PropertyExpectedIncomeViewV1PropertyExpectedIncomeViewDto;
    propertyExpectedIncomeFiveYearAverageSum?: Money;
  }

  export interface PropertyExpectedIncomeViewV1PropertyExpectedIncomeViewDto {
    id: number;
    grossRentalIncome: Money;
    mortgage: Money;
    insurance: Money;
    groundRent: Money;
    serviceCharge: Money;
    groundRentAndServiceCharge: Money;
    landlordLegalObligations: Money;
    lettingsAndManagementFeeAgents: Money;
    spvReserve: Money;
    corporationTax: Money;
    netRentalIncomePercent: Percent;
  }

  export interface PropertyExpectedIncomeViewV2 {
    grossRentalIncome: Money;
    mortgage: Money;
    insurance: Money;
    groundRent: Money;
    serviceCharge: Money;
    groundRentAndServiceCharge: Money;
    landlordLegalObligations: Money;
    lettingsAndManagementFeeAgents: Money;
    spvReserve: Money;
    corporationTax: Money;
    netRentalIncomePercent: Percent;
    sum: Money;
  }

  export interface PropertyFinancialDetailViewV1 {
    purchaseCost?: PropertyPurchaseCostView;
    preLetExpense?: PropertyPreLetExpenseView;
    propertyReturns?: PropertyReturnsView;
    expectedIncome?: PropertyExpectedIncomeViewV1;
    expectedCapitalGain?: PropertyExpectedCapitalGainViewV1;
    loanInvestorReturn?: PropertyLoanInvestorReturnViewV1;
    shareInvestorReturn?: PropertyShareInvestorReturnViewV1;
  }

  export interface PropertyFinancialDetailViewV2 {
    purchaseCost?: PropertyPurchaseCostView;
    preLetExpense?: PropertyPreLetExpenseView;
    propertyReturns: YearlyPropertyReturnsView;
    expectedIncomes: YearlyPropertyExpectedIncomeView;
    expectedCapitalGains: YearlyPropertyExpectedCapitalGainView;
    loanInvestorReturns: YearlyPropertyLoanInvestorReturnView;
    shareInvestorReturns: YearlyPropertyShareInvestorReturnView;
  }

  export interface PropertyFloorPlanView {
    id: number;
    name: string;
    url: string;
  }

  export interface PropertyImageView {
    id: number;
    title: string; // SENSITIVE
    sourceUrl?: string;
    resizedImages: ResizedImage[];
  }

  export interface PropertyLoanInvestorReturnViewV1 {
    propertyLoanInvestorReturnFirstYear?: PropertyLoanInvestorReturnViewV1PropertyLoanInvestorReturnViewDto;
    propertyLoanInvestorReturnFiveYearAverage?: PropertyLoanInvestorReturnViewV1PropertyLoanInvestorReturnViewDto;
  }

  export interface PropertyLoanInvestorReturnViewV1PropertyLoanInvestorReturnViewDto {
    id: number;
    interestReturn: Percent;
  }

  export interface PropertyLoanInvestorReturnViewV2 {
    interestReturn: Percent;
  }

  export interface PropertyPreLetExpenseView {
    propertyPreLetExpense?: PropertyPreLetExpenseViewPropertyPreLetExpenseViewDto;
    propertyPreLetExpenseSum?: Money;
  }

  export interface PropertyPreLetExpenseViewPropertyPreLetExpenseViewDto {
    id: number;
    refurbishment: Money;
    furniture: Money;
    insurance: Money;
    spvReserve: Money;
  }

  export interface PropertyPurchaseCostView {
    propertyPurchaseCost?: PropertyPurchaseCostViewPropertyPurchaseCostViewDto;
    propertyPurchaseCostSum?: Money;
  }

  export interface PropertyPurchaseCostViewPropertyPurchaseCostViewDto {
    id: number;
    property: Money;
    stampDuty: Money;
    legalAndSearch: Money;
    valuation: Money;
    mortgageArrangement: Money;
    propertyFindingAgent: Money;
    underwriting: Money;
  }

  export interface PropertyReturnsItemsView {
    propertyReturn: Percent;
    capitalGainPercent: Percent;
    capitalGainAmount: Money;
    netRentalIncomePercent: Percent;
    netRentalIncomeAmount: Money;
    grossRentalIncome: Money;
    mortgage: Money;
    insurance: Money;
    groundRent: Money;
    serviceCharge: Money;
    landlordLegalObligations: Money;
    lettingsAndManagementFeeAgents: Money;
    spvReserve: Money;
    corporationTax: Money;
  }

  export interface PropertyReturnsView {
    propertyReturnsFirstYear?: PropertyReturnsItemsView;
    propertyReturnsFiveYearsAvg?: PropertyReturnsItemsView;
    propertyReturnsTotal?: PropertyReturnsItemsView;
  }

  export interface PropertyShareInvestorReturnViewV1 {
    propertyShareInvestorReturnFirstYear?: PropertyShareInvestorReturnViewV1PropertyShareInvestorReturnViewDto;
    propertyShareInvestorReturnFiveYearAverage?: PropertyShareInvestorReturnViewV1PropertyShareInvestorReturnViewDto;
  }

  export interface PropertyShareInvestorReturnViewV1PropertyShareInvestorReturnViewDto {
    id: number;
    totalShareInvestorReturn: Percent;
    dividendReturn: Percent;
    capitalGain: Percent;
    capitalGainAccumulated: Percent;
  }

  export interface PropertyShareInvestorReturnViewV2 {
    totalShareInvestorReturn: Percent;
    dividendReturn: Percent;
    capitalGain: Percent;
    capitalGainAccumulated: Percent;
  }

  export interface PropertyViewV1 {
    id: number;
    title: string; // SENSITIVE
    addressId?: number;
    dataAreaSource?: string;
    ownershipType?: OwnershipType;
    internalAreaSqFt?: FlatArea;
    propertyType?: PropertyType;
    tenantReady?: TenantReady;
    propertyDetails?: string;
    hpi1Year?: Percent;
    hpi5Year?: Percent;
  }

  export interface PropertyViewV2 {
    id: number;
    title: string; // SENSITIVE
    addressId?: number;
    dataAreaSource?: string;
    ownershipType?: OwnershipType;
    internalAreaSqFt?: FlatArea;
    propertyType?: PropertyType;
    tenantReady?: TenantReady;
    propertyDetails?: string;
    hpiValues: HpiValuesView;
  }

  export interface PropertyWithCoverViewCollectionV1 {
    items: PropertyWithCoverViewV1[];
  }

  export interface PropertyWithCoverViewCollectionV2 {
    items: PropertyWithCoverViewV2[];
  }

  export interface PropertyWithCoverViewV1 {
    property: PropertyViewV1;
    cover?: PropertyImageView;
    tags: number[];
  }

  export interface PropertyWithCoverViewV2 {
    property: PropertyViewV2;
    cover?: PropertyImageView;
  }

  export interface Quantity {
    value: number;
  }

  export interface QuizTryInfoView {
    classificationMethod?: ClassificationMethod;
    canTryQuiz: boolean;
    canTryQuizOn?: string; // ISO zoned date time
    triesUsed: number;
    triesLimit: number;
  }

  export interface RedoEmailVerificationView {
    email: string; // SENSITIVE
    captchaToken: string; // SENSITIVE
  }

  export interface ReservedMoneyView {
    transactionUuid: string;
    amount: Money;
  }

  export interface ReservedMoneyViewCollection {
    items: ReservedMoneyView[];
  }

  export interface ResetPasswordTokenView {
    token: string; // SENSITIVE
    password: string; // SENSITIVE
  }

  export interface ResetPasswordView {
    email: string; // SENSITIVE
  }

  export interface SelfTradeCancelledOrderData {
    uuid: string;
    accountId: number;
    quantity: Quantity;
    unitPrice: UnitPrice;
  }

  export interface SellAssetTransactionCostImmediateAndStandingView {
    immediateCost: AssetTransactionCostView;
    standingCost: AssetTransactionCostView;
    totalQuantity: Quantity;
    totalCleanAmount: Money;
    totalFeesAmount: Money;
    totalTaxesAmount: Money;
    totalCapitalGainOrLoss: Money;
    totalCleanMinusFeesAmount: Money;
    totalCleanMinusFeesAndTaxesAmount: Money;
    selfTradeCancelledOrderData: SelfTradeCancelledOrderData[];
  }

  export interface SubscribeToNewsletterView {
    email: string; // SENSITIVE
  }

  export interface TokenizedCardTopUpView {
    amount: Money;
    deviceFingerprintingId?: string;
  }

  export interface TokenizedCardView {
    id: number;
    lastDigits: string; // SENSITIVE
    brand: CardBrand; // SENSITIVE
    expiryDate?: ExpiryDateView; // SENSITIVE
    isExpired?: boolean;
    cardBin?: string;
  }

  export interface TokenizedCardViewCollection {
    items: TokenizedCardView[];
  }

  export interface TradeNotionalCashbackIncentive {
    id?: number;
    uuid: string;
    startDateTime: string; // ISO zoned date time
    endDateTime: string; // ISO zoned date time
    tradeNotionalSumMethod: TradeNotionalSumMethod;
    percent: Percent;
    minTradeNotional: Money;
    maxTradeNotional: Money;
    visible: boolean;
    timestamp: string; // ISO zoned date time
    investmentIds: number[];
  }

  export interface UkBankDetailsCreateView {
    owner: string; // SENSITIVE
    bank?: string; // SENSITIVE
    sortCode: string; // SENSITIVE
    accountNumber: string; // SENSITIVE
    visible?: boolean;
  }

  export interface UkBankDetailsView {
    id: number;
    owner: string; // SENSITIVE
    bank?: string; // SENSITIVE
    sortCode: string; // SENSITIVE
    accountNumber: string; // SENSITIVE
    visible: boolean;
  }

  export interface UkBankDetailsViewCollection {
    items: UkBankDetailsView[];
  }

  export interface UnitPrice {
    value: number;
  }

  export interface UntokenizedCardTopUpView {
    amount: Money;
    cardDetails: CardDetails; // SENSITIVE
    cvc: string; // SENSITIVE
    deviceFingerprintingId?: string;
  }

  export interface UserAndAddressVerifyOptView {
    userAndAddressVerifyView?: UserAndAddressVerifyView;
  }

  export interface UserAndAddressVerifyView {
    userVerifyView: UserVerifyView;
    address: AddressVerifyView;
    previousAddress?: AddressVerifyView;
  }

  export interface UserClassificationDetailsView {
    classificationMethod: ClassificationMethod;
    esignature: string; // SENSITIVE
    quizVersion?: string;
  }

  export interface UserClassificationStateInfo {
    classifiedAs: ClassificationMethod;
    validUntil: string; // ISO zoned date time
    allowReAccreditationAfter: string; // ISO zoned date time
    doesntNeedTakingQuizUntil: string; // ISO zoned date time
    quizVersion?: string;
  }

  export interface UserCreateView {
    firstName: string; // SENSITIVE
    lastName: string; // SENSITIVE
    email: string; // SENSITIVE
    password: string; // SENSITIVE
    captchaToken: string; // SENSITIVE
    referrer?: string; // SENSITIVE
    referrerNext?: string; // SENSITIVE
    referrerFriendCode?: string; // SENSITIVE
  }

  export interface UserDetailedView {
    userView: UserView;
    address?: AddressView;
    previousAddress?: AddressView;
    verificationInfo?: UserVerificationStateInfo;
    classificationInfo?: UserClassificationStateInfo;
    roles?: string[];
    confirmEmailToken?: ConfirmEmailToken; // SENSITIVE
  }

  export interface UserEmailView {
    email: string; // SENSITIVE
  }

  export interface UserPatch {
    phoneNumber?: string; // SENSITIVE
    nationalInsuranceNumber?: string; // SENSITIVE
  }

  export interface UserVerificationStateInfo {
    automaticVerificationTriesLimitNumber: number;
    automaticVerificationTriesNumber: number;
    automaticVerificationStatus?: AutomaticVerificationRequestStatus;
    manualVerificationStatus?: ManualVerificationRequestStatus;
    verificationVariant: VerificationVariant;
  }

  export interface UserVerifyView {
    title?: string; // SENSITIVE
    firstName: string; // SENSITIVE
    middleNames?: string; // SENSITIVE
    lastName: string; // SENSITIVE
    birthDate?: string; // ISO local date // SENSITIVE
    email: string; // SENSITIVE
    phoneNumber?: string; // SENSITIVE
    nationalityIsoCode?: string;
    attribution?: string; // SENSITIVE
  }

  export interface UserView {
    id: number;
    title?: string; // SENSITIVE
    firstName: string; // SENSITIVE
    middleNames?: string; // SENSITIVE
    lastName: string; // SENSITIVE
    birthDate?: string; // ISO local date // SENSITIVE
    email: string; // SENSITIVE
    phoneNumber?: string; // SENSITIVE
    addressId?: number;
    previousAddressId?: number;
    nationalInsuranceNumber?: string; // SENSITIVE
    referrer?: string; // SENSITIVE
    referralCode?: string;
    attribution?: string; // SENSITIVE
    nationalityIsoCode?: string;
    status: UserStatus;
  }

  export interface ValuationOnDateView {
    value: Money;
    valuationInfo?: string;
    valuationDate: string; // ISO local date
    loanToValue: Percent;
    reportUrl?: string;
    indicativeBuyShareUnitPrice: UnitPrice;
    indicativeSellShareUnitPrice: UnitPrice;
    indicativeMidShareUnitPrice: UnitPrice;
  }

  export interface ValuationOnDateViewCollection {
    items: ValuationOnDateView[];
  }

  export interface WithdrawalView {
    uuid?: string;
    state?: OutExternalTransferState;
    createdAt?: string; // ISO zoned date time
    updatedAt?: string; // ISO zoned date time
    bankDetailsId: number;
    amount: Money;
  }

  export interface WithdrawalViewCollection {
    items: WithdrawalView[];
  }

  export interface YearlyPropertyExpectedCapitalGainView {
    year1?: PropertyExpectedCapitalGainViewV2;
    year3?: PropertyExpectedCapitalGainViewV2;
    year5?: PropertyExpectedCapitalGainViewV2;
    total?: PropertyExpectedCapitalGainViewV2;
  }

  export interface YearlyPropertyExpectedIncomeView {
    year1?: PropertyExpectedIncomeViewV2;
    year3?: PropertyExpectedIncomeViewV2;
    year5?: PropertyExpectedIncomeViewV2;
    total?: PropertyExpectedIncomeViewV2;
  }

  export interface YearlyPropertyLoanInvestorReturnView {
    year1?: PropertyLoanInvestorReturnViewV2;
    year3?: PropertyLoanInvestorReturnViewV2;
    year5?: PropertyLoanInvestorReturnViewV2;
    total?: PropertyLoanInvestorReturnViewV2;
  }

  export interface YearlyPropertyReturnsView {
    year1?: PropertyReturnsItemsView;
    year3?: PropertyReturnsItemsView;
    year5?: PropertyReturnsItemsView;
    total?: PropertyReturnsItemsView;
  }

  export interface YearlyPropertyShareInvestorReturnView {
    year1?: PropertyShareInvestorReturnViewV2;
    year3?: PropertyShareInvestorReturnViewV2;
    year5?: PropertyShareInvestorReturnViewV2;
    total?: PropertyShareInvestorReturnViewV2;
  }

  export type ImageSize =
    | '64x64'
    | '150x100'
    | '320x320'
    | '375x250'
    | '768x512'
    | '1280x853'
    | '1800x1200'
    | '1920x1280';

  export type ResizedImage = Unionize<{ [key in ImageSize]: string }>;
}
