import Error from './Error';
import Generic from './Generic';
import Investor from './Investor';
import Legal from './Legal';
import NewOnboarding from './NewOnboarding';
import Public from './Public';
import Reaccreditation from './Reaccreditation';

export {
  Error,
  Generic,
  Investor,
  Legal,
  NewOnboarding,
  Public,
  Reaccreditation,
};
