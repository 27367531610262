import React from 'react';

import Config from 'config';

const Tick = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 28 33" width={width} {...props}>
    <path
      d="M31.438 1.266a2 2 0 0 0-2.814.282L12.223 21.604 4.755 15.29a2 2 0 0 0-2.819.236l-.645.764a2 2 0 0 0 .236 2.819l8.663 7.322c.1.139.22.267.358.38l.774.633a2 2 0 0 0 2.814-.282l18.358-22.45a2 2 0 0 0-.282-2.814l-.774-.633z"
      fill={fill}
    />
  </svg>
);

Tick.defaultProps = {
  fill: Config.theme.colors.white,
  height: 28,
  width: 33,
};

export default Tick;
