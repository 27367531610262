import { Theme } from '@material-ui/core/styles';

import {
  buyTheme,
  coreTheme,
  loansTheme,
  sellTheme,
  sharesTheme,
} from './themes';

import { ThemeNames } from './types';

export const themeMap: Record<ThemeNames, Theme> = {
  core: coreTheme,
  loans: loansTheme,
  shares: sharesTheme,
  buy: buyTheme,
  sell: sellTheme,
};
