import React from 'react';

import Config from 'config';

const Dude = ({ height, width, color, circleColor, ...props }) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 145 145"
    width={width}
    {...props}
  >
    <circle cx="72" cy="72" r="70" fill={circleColor} />
    <path
      d="M72.5 145C32.551 145 0 112.449 0 72.5C0 32.551 32.551 0 72.5 0C112.449 0 145 32.551 145 72.5C145 112.449 112.449 145 72.5 145ZM72.5 4.51275C34.9923 4.51275 4.51274 34.9923 4.51274 72.5C4.51274 110.008 34.9923 140.487 72.5 140.487C110.008 140.487 140.487 110.008 140.487 72.5C140.487 34.9923 109.934 4.51275 72.5 4.51275Z"
      fill={color}
    />
    <path d="M61 86H57V100H61V86Z" fill={color} />
    <path d="M88 86H84V100H88V86Z" fill={color} />
    <path
      d="M97 57H94.7376C91.0155 57 88.0233 53.9723 88.0233 50.2062V45.7015C88.0233 44.4462 87.0015 43.4123 85.7609 43.4123H83.5714V40.3108L77.368 43.4123H58.9767C57.736 43.4123 56.7143 44.4462 56.7143 45.7015V50.2062C56.7143 53.9723 53.722 57 50 57V52.4954C51.2407 52.4954 52.2624 51.4615 52.2624 50.2062V45.7015C52.2624 41.9354 55.2546 38.9077 58.9767 38.9077H76.3462L88.0233 33V39.2769C90.6506 40.2369 92.4751 42.7477 92.4751 45.7015V50.2062C92.4751 51.4615 93.4969 52.4954 94.7376 52.4954H97V57Z"
      fill={color}
    />
    <path d="M50 52H48V57H50V52Z" fill={color} />
    <path
      d="M72.5 93C54.6399 93 47.6902 75.8104 47.3166 64.3507L45 62.0438V35.999C45 30.9388 49.11 26.8461 54.1916 26.8461H54.4905C55.5367 22.9021 59.1236 20 63.3831 20H88.5666C94.8438 20 100 25.1346 100 31.3853V62.0438L97.6834 64.3507C97.3098 75.8104 90.3601 93 72.5 93ZM49.6331 60.1091L51.9497 62.4159V63.3833C51.9497 73.0571 57.5543 88.4608 72.5747 88.4608C87.5951 88.4608 93.1997 73.0571 93.1997 63.3833V62.4159L95.5163 60.1091V31.4597C95.5163 27.6646 92.4524 24.6137 88.6413 24.6137H63.4578C60.9171 24.6137 58.8994 26.6228 58.8994 29.1529V31.4597H54.341C51.8003 31.4597 49.7826 33.4689 49.7826 35.999V60.1091H49.6331Z"
      fill={color}
    />
    <path
      d="M64.2074 121L39 109.459L54.0801 97L75 102.427V109.917L64.2074 121ZM47.6489 108.312L63.1725 115.497L70.4907 107.93V106.019L55.1889 102.045L47.6489 108.312Z"
      fill={color}
    />
    <path
      d="M80.7926 121L70 109.876V102.41L90.9199 97L106 109.419L80.7926 121ZM74.4353 107.895L81.7536 115.438L97.2772 108.276L89.7372 102.029L74.4353 105.99V107.895Z"
      fill={color}
    />
    <path
      d="M22.0933 121L18 119.01C18.4548 118.157 21.4869 113.609 35.586 109.203L42.5598 107L44 111.122L37.0262 113.325C24.6705 117.162 22.1691 120.929 22.0933 121Z"
      fill={color}
    />
    <path
      d="M122.907 121C122.907 121 120.329 117.214 107.974 113.357L101 111.143L102.44 107L109.414 109.214C123.513 113.643 126.545 118.214 127 119.071L122.907 121Z"
      fill={color}
    />
  </svg>
);
Dude.defaultProps = {
  circleColor: Config.theme.colors.bostonBlue,
  color: Config.theme.colors.white,
  height: 95,
  width: 95,
};

export default Dude;
