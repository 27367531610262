import React from 'react';

const SidebarPlus = props => (
  <svg fill="currentColor" viewBox="0 0 17 17" {...props}>
    <rect x="6" width="5" height="17" />
    <rect y="11" width="5" height="17" transform="rotate(-90 0 11)" />
  </svg>
);

export default SidebarPlus;
