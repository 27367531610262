import { isArray, isPlainObject } from 'lodash';

import api from '../endpoints/Portfolio';

export default class Portfolio {
  /**
   * Get portfolio's properties
   */
  static get(id) {
    if (!id) {
      return Promise.reject();
    }
    return api.portfolio
      .get(id)
      .then(portfolios => {
        if (isArray(portfolios)) {
          return portfolios;
        }
        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get portfolio's properties
   */
  static getSummary(id) {
    if (!id) {
      return Promise.reject();
    }
    return api.summary
      .get(id)
      .then(summary => {
        if (isPlainObject(summary)) {
          return summary;
        }
        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get account's ISA properties
   */
  static getIsaSummary(id) {
    if (!id) {
      return Promise.reject();
    }
    return api.isaSummary
      .get(id)
      .then(summary => {
        if (isPlainObject(summary)) {
          return summary;
        }
        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }
}
