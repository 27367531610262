import { createSelector } from 'reselect';
import { MODAL_STATE_KEY } from './constants';

export const selectGlobal = state => state.get('global');
const selectLocation = state => state.get('route');

export const makeSelectSidebarCollapsed = () =>
  createSelector(selectGlobal, state => state.get('isSidebarCollapsed'));

export const makeSelectLocationState = () =>
  createSelector(selectLocation, locationState => locationState.get('state'));

export const makeSelectRedirectToAfterLogin = () =>
  createSelector(
    makeSelectLocationState(),
    locationState => locationState && locationState.get('redirectAfterLogin')
  );

export const makeSelectNewsletterSubscribed = () =>
  createSelector(selectGlobal, state => state.get('newsletterSubscribed'));

export const selectVideo = () =>
  createSelector(selectGlobal, state => state.get('video'));

export const selectModal = createSelector(selectGlobal, global =>
  global.get(MODAL_STATE_KEY)
);
