import { isArray } from 'lodash';

import Config from 'config';

import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  properties: {
    url: '/properties/:id',
    helpers: {
      get: id => OAuth.getAuthHeader().then(header => [{ id }, header]),
      findAll: () => [],
    },
    transformer({ data }) {
      const isMultiple = data.items && isArray(data.items);
      const properties = isMultiple ? data.items : [data];

      const results = properties.map(
        ({ property, tagIds, address, amenities }) => ({
          ...property,
          tagIds,
          address,
          amenities,
        })
      );

      return isMultiple ? results : results[0];
    },
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
