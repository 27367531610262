import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { AppBar } from 'components/Organisms/AppBar';
import media from 'utils/responsive';
import Helmet from 'react-helmet';
import { Footer } from 'components/Organisms/footer';
import withBaseTemplate from '../Base';

// TODO move whole layout responsibility to Template (ensure other templates are working as well e.g. Public, Investor)
// https://britishpearl.atlassian.net/browse/PIT-1710
// below corresponds directly to padding in AppWrapper in app/containers/App.js
const wrapperMarginTop = css`
  margin-top: -4.5rem;

  ${media.large`
    margin-top: -6.25rem;
  `}
`;

const Wrapper = styled.div`
  align-items: ${({ center }) => (center ? 'center' : 'start')};
  display: flex;
  flex-direction: column;
  height: ${({ center }) => (center ? '100vh' : '100%')};
  justify-content: ${({ center }) => (center ? 'center' : 'start')};
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  ${({ hideMasthead }) => hideMasthead && wrapperMarginTop};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const GenericTemplate = ({
  center,
  children,
  className,
  hideMasthead,
  navLinks,
  canonicalLink,
}) => (
  <>
    {canonicalLink && (
      <Helmet>
        <link rel="canonical" href={canonicalLink} />
      </Helmet>
    )}
    {!hideMasthead && <AppBar navLinks={navLinks} />}
    <Wrapper center={center} className={className} hideMasthead={hideMasthead}>
      <MainContent>{React.Children.toArray(children)}</MainContent>
      <Footer hideMarketing />
    </Wrapper>
  </>
);

GenericTemplate.defaultProps = {
  canonicalLink: null,
  center: false,
  children: null,
  className: '',
  hideMasthead: false,
  navLinks: undefined,
};

GenericTemplate.propTypes = {
  canonicalLink: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  hideMasthead: PropTypes.bool,
  navLinks: PropTypes.arrayOf(PropTypes.string),
};

export default withBaseTemplate(GenericTemplate);
