import React from 'react';

const Location = props => (
  <svg width="317" height="456" viewBox="0 0 317 456" fill="none" {...props}>
    <path
      d="M239.643 165.408C239.643 210.879 202.876 247.71 157.525 247.71C112.177 247.71 75.4033 210.879 75.4033 165.408C75.4033 119.957 112.177 83.1004 157.525 83.1004C202.876 83.1004 239.643 119.957 239.643 165.408ZM317 165.717C317 74.1961 246.042 0 158.5 0C70.968 0 0 74.1961 0 165.717C0 257.244 158.5 455.241 158.5 455.241C158.5 455.241 317 257.245 317 165.717Z"
      fill="currentColor"
    />
  </svg>
);

export default Location;
