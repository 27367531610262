import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, shadows, palette }) =>
  createStyles({
    span: {
      textDecoration: 'underline',
      textDecorationStyle: 'dashed',
    },
    tooltip: {
      maxWidth: 200,
      backgroundColor: palette.common.black,
      fontSize: '0.875rem',
      padding: spacing(1, 2),
      textAlign: 'center',
      boxShadow: shadows[1],
    },
    white: {
      color: palette.common.black,
      backgroundColor: palette.common.white,
    },
    black: {
      color: palette.common.white,
      backgroundColor: palette.common.black,
    },
  })
);
