import React from 'react';

import { Consumer } from './configureLanguage';

export const i18nConnect = Component => {
  const Wrapped = props => (
    <Consumer>{i18n => <Component i18n={i18n} {...props} />}</Consumer>
  );
  Wrapped.displayName = Component.displayName || Component.name;
  return Wrapped;
};

export default i18nConnect;
