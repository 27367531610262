import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const LoadingContainer = styled.div`
  align-items: center;
  background: ${({ opaque, transparent }) =>
    transparent
      ? 'transparent'
      : `rgba(255, 255, 255, ${opaque ? '1' : '.9'})`};
  display: flex;
  height: 100%;
  justify-content: center;
  left: -0.5rem;
  max-height: 100vh;
  position: absolute;
  width: calc(100% + 1rem);
  z-index: 2;
`;

const Container = styled.div`
  position: relative;
`;

export const LoaderOverlay = props => {
  const { active, children, className, opaque, transparent } = props;
  return (
    <Container className={className}>
      {active && (
        <LoadingContainer
          data-test-id="loader-overlay"
          opaque={opaque}
          transparent={transparent}
        >
          <CircularProgress size={120} thickness={2} />
        </LoadingContainer>
      )}
      {children}
    </Container>
  );
};

LoaderOverlay.defaultProps = {
  className: undefined,
  opaque: false,
  transparent: false,
  children: undefined,
};

LoaderOverlay.propTypes = {
  className: PropTypes.string,
  opaque: PropTypes.bool,
  transparent: PropTypes.bool,
  children: PropTypes.node,
  active: PropTypes.bool.isRequired,
};

export default LoaderOverlay;
