import AccountActivity from './AccountActivity';
import AccountOverview from './AccountOverview';
// eslint-disable-next-line import/no-named-as-default
import ForgotPasswordChangeForm from './ForgotPasswordChangeForm';
// eslint-disable-next-line import/no-named-as-default
import ForgotPasswordResetForm from './ForgotPasswordResetForm';
import Hero from './Hero';
import InvestmentDetails from './InvestmentDetails';
// eslint-disable-next-line import/no-named-as-default
import LoginForm from './LoginForm';
// eslint-disable-next-line import/no-named-as-default
import RegisterForm from './RegisterForm';
// eslint-disable-next-line import/no-named-as-default
import Sidebar from './Sidebar';

// @deprecated please use nested imports e.g. import Example from 'components/Organisms/Example';
export {
  AccountActivity,
  AccountOverview,
  ForgotPasswordChangeForm,
  ForgotPasswordResetForm,
  Hero,
  InvestmentDetails,
  LoginForm,
  RegisterForm,
  Sidebar,
};
