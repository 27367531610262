import React from 'react';

import Config from 'config';

const OpenhandBars = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none">
      <path
        d="M7.17 23.89c-.147.147-.384.147-.53 0L.11 17.36c-.147-.146-.147-.383 0-.53l2.72-2.72c.147-.147.384-.147.53 0l6.53 6.53c.147.146.147.383 0 .53l-2.72 2.72zm1.927-2.985l-6.002-6.002-2.192 2.192 6.002 6.002 2.192-2.192z"
        fill={fill}
      />
      <path fill={fill} d="M2 17.654L3.346 19 4 18.346 2.654 17" />
      <path fill={fill2} d="M20.18 4.736v6.124H21V4h-5v10h.82V4.736" />
      <path
        d="M15.995 1.724v3.324h.82V1H12v12h.82V1.724M12 8.71V8H8v4.49h.715V8.71"
        fill={fill2}
      />
      <path
        d="M17.078 13.45l2.045-1.095c.707-1.39 1.328-2.037 2.31-2.272 1.022-.244 2.048.07 2.495.688.077.11.094.25.043.375-.436 1.057-2.172 3.848-2.712 4.472-1.157 1.336-3.883 3.95-4.514 3.95H9.318c-.3 0-.46.074-.626.244-.03.03-.146.16-.176.19L8 19.47c.018-.02.135-.15.18-.194.295-.303.633-.463 1.138-.463h7.424c.113 0 .946-.68 1.94-1.633.79-.758 1.76-1.754 2.03-2.065.455-.526 1.957-2.926 2.488-4.045-.33-.27-.957-.41-1.603-.255-.75.18-1.238.705-1.88 1.99-.034.07-.09.126-.156.162l-1.896 1.016c.77 1.115.225 3.017-1.634 3.017h-4.28v-.82h4.28c1.65 0 1.65-2.165 0-2.165h-2.827c-.195 0-.377-.09-.602-.254-.075-.054-.155-.12-.256-.204-.05-.04-.225-.19-.26-.22-.212-.18-.36-.293-.5-.378-.152-.09-.275-.135-.368-.135H8.54c-.522 0-1.307.48-1.88 1.075l-2.14 2.388-.52-.58 2.15-2.4C6.854 12.57 7.79 12 8.54 12h2.674c.234 0 .468.083.718.234.18.11.355.243.595.446.505.427.623.514.675.514h2.826c.415 0 .765.095 1.05.255z"
        fill={fill}
      />
    </g>
  </svg>
);

OpenhandBars.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default OpenhandBars;
