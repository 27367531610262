import React, { FC } from 'react';
import { InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form/immutable';
import { Grid } from '@material-ui/core';
import {
  createImmutableAsyncValidation,
  TextFieldRedux,
} from 'components/Atoms/Form';
import { Button } from 'components/Atoms/Button';
import { useI18n } from 'utils/i18nConnect';
import { validationSchema } from './validationSchema';
import { createInitialValues } from './initialValues';
import { FormValues } from './types';

export const FooterSubscribeFormRaw: FC<Pick<
  InjectedFormProps<FormValues>,
  'handleSubmit'
>> = ({ handleSubmit }) => {
  const t = useI18n()('footer.FooterSubscribeForm');

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container justify="flex-end" alignItems="center" spacing={3}>
        <Grid item xs={12} md lg>
          <Field
            component={TextFieldRedux}
            name="email"
            placeholder={t`Email`}
            margin="none"
            hiddenLabel
          />
        </Grid>
        <Grid item xs={12} md="auto" lg="auto">
          <Button
            variant="outlined"
            color="white"
            fullWidth
            type="submit"
            size="large"
          >
            {t`Subscribe`}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export const FooterSubscribeForm = reduxForm({
  form: 'FooterSubscribeForm',
  asyncValidate: createImmutableAsyncValidation(validationSchema),
  initialValues: createInitialValues(),
})(FooterSubscribeFormRaw);
