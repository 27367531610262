// import { type Dispatch as IDispatch } from 'redux';
import _i18n from 'es2015-i18n-tag';
import React from 'react';
import { connect } from 'react-redux';

import {
  makeSelectLocale,
  makeSelectTranslationsForLocale,
} from 'store/LanguageProvider/selectors';

import { Provider } from './configureLanguage';

const mapStateToProps = state => ({
  locale: makeSelectLocale()(state),
  translations: makeSelectTranslationsForLocale(makeSelectLocale()(state))(
    state
  ),
});

class LanguageProvider extends React.PureComponent {
  debounceTimer = 0;

  groups = new Set();

  newGroups = new Set();

  i18n = (group, format) => {
    this.newGroups.add(group);
    this.debounceRequest();
    return (...values) => _i18n(group, format)(...values);
  };

  debounceRequest() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = 0;
    }

    this.debounceTimer = setTimeout(() => this.requestTranslations(), 250);
  }

  requestTranslations() {
    // const { dispatch } = this.props;
    this.groups.forEach(group => this.newGroups.delete(group));
    // this.newGroups.forEach((group) => dispatch(translationGroupRequest(group)));
    this.newGroups.forEach(group => this.groups.add(group));
  }

  render() {
    const { children } = this.props;

    // Defining an arrow function here creates a new function on every render.
    // That's usually a bug but here it's intentional, so translated strings
    // are rerendered when the locale or loaded translations change
    const i18n = (...args) => this.i18n(...args);

    return <Provider value={i18n}>{children}</Provider>;
  }
}

export default connect(mapStateToProps)(LanguageProvider);
