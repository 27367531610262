import { all, call, fork } from 'redux-saga/effects';

import { logger } from 'services/Logger';

import { sagas as sessionProviderSagas } from './SessionProvider';
import languageProviderSagas from './LanguageProvider/sagas';
import { investorWalletSaga } from './InvestorWallet';
import { investorWithdrawalsSaga } from './InvestorWithdrawals';
import { onboardingAccreditationSaga } from './OnboardingAccreditation';
import { investmentsSaga } from './Investments';
import { noticesSaga } from './Notices';

export function* rootSaga() {
  yield fork(sessionProviderSagas);
  yield fork(languageProviderSagas);
  try {
    yield all([
      investorWalletSaga(),
      investorWithdrawalsSaga(),
      onboardingAccreditationSaga(),
      investmentsSaga(),
      noticesSaga(),
    ]);
  } catch (e) {
    yield call(logger.error, e);
  }
}
