import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AppBar as MUIAppBar, Box, Toolbar } from '@material-ui/core';

import {
  selectors as sessionSelectors,
  SessionAccounts,
  SessionUser,
} from 'store/SessionProvider';

import { authenticatedUserNavLinks, desktopNavLinks } from './navLinks';
import { AppBarLogo } from './components/AppBarLogo';
import { DesktopNavigation } from './components/DesktopNavigation';
import { MobileNavigation } from './components/MobileNavigation';
import { MenuNavLinkItem } from './types';
import { useStyles } from './AppBar.styles';
import {
  PermanentRiskWarningDialog,
  PermanentRiskWarningSection,
} from './components/PermanentRiskWarning';

interface AppBarProps {
  accounts?: SessionAccounts;
  user?: SessionUser;
  navLinks?: MenuNavLinkItem[];
  loading?: boolean;
}

const getMobileNavLinks = (authenticated: boolean): MenuNavLinkItem[] => [
  ...desktopNavLinks,
  ...(authenticated ? authenticatedUserNavLinks : []),
];

export const AppBarRaw: FC<AppBarProps> = ({
  user,
  accounts,
  navLinks,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <MUIAppBar color="inherit" position="sticky">
      <Toolbar className={classes.toolbar}>
        <AppBarLogo alwaysShowText={!user} />
        <Box display="flex" component="nav">
          <DesktopNavigation
            navLinks={navLinks || desktopNavLinks}
            user={user}
            loading={loading}
            accounts={accounts}
          />
          <MobileNavigation
            navLinks={navLinks || getMobileNavLinks(!!user)}
            loggedIn={!!user}
          />
        </Box>
      </Toolbar>
      <PermanentRiskWarningSection onOpen={() => setOpen(true)} />
      <PermanentRiskWarningDialog open={open} onClose={() => setOpen(false)} />
    </MUIAppBar>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: sessionSelectors.makeSelectAccounts(),
  user: sessionSelectors.makeSelectUser(),
  loading: sessionSelectors.selectSessionLoading,
});

export const AppBar = connect(mapStateToProps)(AppBarRaw);
