import React from 'react';

const GooglePlus = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M12.0462.2403C5.5042.2403.182 5.5626.182 12.1045c0 6.542 5.3223 11.8643 11.8642 11.8643 6.5422 0 11.8645-5.3223 11.8645-11.8643S18.588.2403 12.0462.2403zm0 23.212c-6.2573 0-11.348-5.0908-11.348-11.348C.698 5.847 5.7888.756 12.046.756c6.2575 0 11.3484 5.091 11.3484 11.3483-.0003 6.2575-5.091 11.348-11.3484 11.348z" />
      <path d="M19.117 6.7535h-1.405v-1.405c0-.2848-.2312-.516-.516-.516h-.9604c-.285 0-.516.2312-.516.516v1.405h-1.4048c-.285 0-.516.2313-.516.5162v.9605c0 .285.231.516.516.516h1.4047v1.405c0 .285.2312.5162.516.5162h.9604c.284 0 .516-.2313.516-.5162v-1.405h1.405c.284 0 .516-.231.516-.516v-.9605c0-.2852-.231-.5162-.516-.5162zm0 1.4767h-1.921v1.921h-.9604v-1.921h-1.9208v-.9605h1.9208v-1.921h.9603v1.921h1.921v.9605z" />
      <path d="M12.5757 13.0173l-.0942-.0702c-.547-.407-.8583-.687-.9256-.832.001-.218.03-.331.637-.805.934-.731 1.428-1.641 1.428-2.632 0-.698-.172-1.336-.483-1.853.095-.023.183-.073.252-.145l.919-.965c.095-.093.154-.223.154-.367 0-.285-.231-.516-.516-.516H9.784c-2.3797 0-4.39 1.735-4.39 3.788 0 2.052 1.3615 3.515 3.349 3.653-.015.098-.0223.194-.0223.29 0 .222.0394.414.104.583-1.1525.052-2.224.456-3.041 1.149-.8465.718-1.3317 1.674-1.3317 2.624 0 1.781 2.093 3.073 4.9766 3.073 1.41 0 2.646-.375 3.5743-1.085.886-.678 1.395-1.6142 1.395-2.569 0-1.4945-.426-2.2695-1.823-3.3175zm-3.1465 6.4555c-2.419 0-4.4604-.984-4.4604-2.5572 0-1.598 1.6934-3.2627 4.1125-3.2627.2635 0 .505-.007.7556-.007-.332-.322-.6-.6-.6-1.086 0-.289.092-.565.221-.812-.132.009-.266.016-.404.016-1.987 0-3.144-1.396-3.144-3.148 0-1.714 1.761-3.272 3.873-3.272h4.164l-.931.977h-1.093c.771.442 1.181 1.351 1.181 2.354 0 .92-.51 1.662-1.23 2.225-.703.5483-.837.7782-.837 1.246 0 .398.84.9913 1.226 1.2812 1.345 1.0086 1.617 1.6427 1.617 2.904 0 1.5735-1.696 3.138-4.4534 3.138z" />
      <path d="M10.7133 14.3187c-.33-.1073-.6937-.1698-1.0836-.174l-.0426-.0002c-1.653 0-3.046 1.0674-3.046 2.3027 0 1.2715 1.208 2.3298 2.88 2.3298 2.351 0 3.17-.9935 3.17-2.2645 0-.1538-.019-.304-.053-.4493-.184-.7195-.916-1.1138-1.823-1.7445zM9.42 18.261c-1.3253 0-2.3632-.7967-2.3632-1.8138 0-.9517 1.1824-1.7863 2.5303-1.7866l.037.0003c.299.003.588.045.861.127.11.075.217.148.321.218.652.44 1.123.759 1.232 1.185.024.101.037.212.037.321 0 1.176-.868 1.748-2.654 1.748zm.4964-7.1315c1.0867 0 1.7076-.941 1.5177-2.39-.194-1.4737-1.154-2.6827-2.276-2.7163-.016-.0005-.032-.0007-.048-.0007-1.095 0-1.823 1.1163-1.632 2.5695.194 1.473 1.26 2.503 2.383 2.5368.019.0005.038.0007.057.0007zM7.9884 8.525c-.088-.6713.0485-1.269.3742-1.6406.201-.2294.4524-.3456.7466-.3456l.0333.0006c.8338.025 1.6157 1.021 1.7798 2.268.0865.661-.0165 1.197-.2908 1.5095-.173.197-.4137.297-.715.297l-.0414-.001c-.922-.028-1.733-.926-1.886-2.089z" />
    </g>
  </svg>
);

GooglePlus.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default GooglePlus;
