import React from 'react';
import { Provider } from 'react-redux';
import Router from 'router';
import { init as initializeAnalyticsTools } from 'utils/analytics';
import { LanguageProvider } from 'utils/i18nConnect';
import { ReferrerService } from 'services/Referrer/Referrer';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { store } from './configureStore';
import { ThemeProvider } from './components/Molecules/ThemeProvider';

initializeAnalyticsTools();

ReferrerService.init();

const App = props => {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={store}>
      <LanguageProvider>
        <ThemeProvider>
          <SnackbarProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router {...props} />
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  );
};

export default App;
