import api from '../endpoints/Incentives';

// TODO Not used. To be removed?
export default class Incentives {
  /**
   * Get all incentive details
   */
  static findAll() {
    return api.incentives.findAll();
  }
}
