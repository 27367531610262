import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 2rem 1rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4000;

  &::before {
    background-color: rgba(0, 0, 0, 0.65);
    content: '';
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 64rem;
  position: relative;
  width: 100%;
`;

const Scaler = styled.div`
  height: 0;
  padding-top: 56.25%;
`;

const Player = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const Video = ({ url, toggleVideo }) => (
  <Wrapper onClick={toggleVideo}>
    <Content>
      <Scaler>
        <Player
          allow="autoplay; encrypted-media"
          allowFullScreen
          frameborder="0"
          src={`${url}?rel=0&showinfo=0&autoplay=1`}
        />
      </Scaler>
    </Content>
  </Wrapper>
);

Video.propTypes = {
  url: PropTypes.string.isRequired,
  toggleVideo: PropTypes.func.isRequired,
};

export default Video;
