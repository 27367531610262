import React from 'react';

const ExitDoor = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 26" width={width} {...props}>
    <path
      d="M10 22H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5m7 14l4-4-4-4m4 4H9"
      fill="transparent"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

ExitDoor.defaultProps = {
  fill: 'currentColor',
  height: 26,
  width: 24,
};

export default ExitDoor;
