import React, { FC, ReactNode, useCallback, useState } from 'react';
import Script, { ScriptProps } from 'react-load-script';
import { Optional } from 'utility-types';

export interface LazyScriptRenderProps {
  loading: boolean;
  errored: boolean;
  created: boolean;
}

export interface LazyScriptProps
  extends Optional<ScriptProps, 'onCreate' | 'onLoad' | 'onError'> {
  children?: (props: LazyScriptRenderProps) => ReactNode;
}

/**
 * Reusable abstraction using `react-load-script`
 * Loads script asynchronously by default.
 *
 * Original docs: https://github.com/blueberryapps/react-load-script/blob/v0.0.6/README.md
 *
 * Important:
 *
 * Unfortunately `react-load-script` has some limitations which may impact us in future.
 * It lacks possibility to disable `async` loading and seems to be unmaintained.
 *
 * Still it was chosen because it's easy to use and gives us better flexibility than previous `react-async-script`.
 * In future it may be required to copy/paste it's source and adjust to our needs in PIT.
 */
export const LazyScript: FC<LazyScriptProps> = ({
  children,
  url,
  onLoad,
  onError,
  onCreate,
  attributes,
}) => {
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [created, setCreated] = useState(false);
  const handleOnCreate = useCallback(() => {
    setCreated(true);
    if (onCreate) onCreate();
  }, [onCreate]);
  const handleOnLoad = useCallback(() => {
    setLoading(false);
    if (onLoad) onLoad();
  }, [onLoad]);
  const handleOnError = useCallback(() => {
    setErrored(true);
    setLoading(false);
    if (onError) onError();
  }, [onError]);

  return (
    <>
      <Script
        url={url}
        attributes={attributes}
        onCreate={handleOnCreate}
        onLoad={handleOnLoad}
        onError={handleOnError}
      />
      {children
        ? children({
            loading,
            errored,
            created,
          })
        : null}
    </>
  );
};
