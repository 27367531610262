import React from 'react';

import Config from 'config';

const Linkedin = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 38 38" width={width} {...props}>
    <path
      d="M18.5363 0C8.29846 0 0 8.50606 0 19C0 29.4928 8.29846 38 18.5363 38C28.7741 38 37.0726 29.4928 37.0726 19C37.0726 8.50606 28.7741 0 18.5363 0Z"
      fill={fill}
      transform="translate(0.658203)"
    />

    <path
      d="M19.2291 18.8385V11.5508C19.2291 7.64631 17.1959 5.82944 14.4838 5.82944C12.2954 5.82944 11.3152 7.06325 10.7696 7.92894V6.1275H6.64758C6.70203 7.32094 6.64758 18.8385 6.64758 18.8385H10.7696V11.7396C10.7696 11.3608 10.7962 10.9808 10.9051 10.7101C11.2029 9.95125 11.8806 9.16512 13.0194 9.16512C14.5116 9.16512 15.1082 10.3301 15.1082 12.0389V18.8397L19.2291 18.8385ZM2.30429 4.39256C3.74085 4.39256 4.63639 3.41525 4.63639 2.19569C4.60974 0.948812 3.74085 0 2.33094 0C0.921022 0 0 0.948812 0 2.19569C0 3.41644 0.894376 4.39256 2.2788 4.39256H2.30429ZM4.3653 18.8385V6.1275H0.244447V18.8385H4.3653Z"
      fill="white"
      transform="translate(9.57422 8.40527)"
    />
  </svg>
);

Linkedin.defaultProps = {
  fill: Config.theme.colors.black,
  height: 38,
  width: 38,
};

export default Linkedin;
