import { fromJS } from 'immutable';

import * as type from './actionTypes';

const initialState = fromJS({
  loading: true, // Keep this as true, initial state of the session is loading, when we finish the async requests, we set it to false, which allows us to handle the redirects
  submitting: false,
  user: null,
  address: null,
  accounts: null,
  passwordResetSuccess: false,
  passwordChangeSuccess: false,
  registrationSuccess: false,
  resendVerificationEmailSuccess: false,
  sessionIdle: false,
  error: null,
  referrer:
    window && window.location.search
      ? window.location.search.substring(1)
      : null,
  verificationEmailAddress: null,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.USERDETAILS_REQUEST:
    case type.ACCOUNTS_FINDALL_REQUEST: {
      return state.merge({
        loading: true,
      });
    }
    case type.USERDETAILS_UPDATE_REQUEST_SUCCESS:
      return state.mergeDeep({
        user: fromJS(action.payload),
      });
    case type.USERDETAILS_SUCCESS:
      return state.mergeDeep({
        user: fromJS(action.user),
        submitting: false,
        loading: false,
      });
    case type.REGISTER_REQUEST:
      return state.merge({
        submitting: true,
        verificationEmailAddress: action.payload.email,
        resendVerificationEmailSuccess: false,
      });
    case type.REGISTER_SUCCESS:
      return state.merge({
        submitting: false,
        registrationSuccess: true,
      });
    case type.REGISTER_ERROR:
      return state.merge({
        submitting: false,
      });
    case type.LOGIN_REQUEST:
      return state.merge({
        submitting: true,
        verificationEmailAddress: action.payload.email,
        resendVerificationEmailSuccess: false,
      });
    case type.LOGIN_SUCCESS:
      return state.mergeDeep({
        user: fromJS(action.user),
        address: fromJS(action.address),
        submitting: false,
      });
    case type.LOGIN_ERROR:
      return state.merge({
        submitting: false,
      });
    case type.FORGOT_PASSWORD_RESET_REQUEST:
      return state.merge({
        submitting: true,
      });
    case type.FORGOT_PASSWORD_RESET_SUCCESS:
      return state.merge({
        submitting: false,
        passwordResetSuccess: true,
      });
    case type.FORGOT_PASSWORD_RESET_ERROR:
      return state.merge({
        submitting: false,
      });
    case type.FORGOT_PASSWORD_RESET_RESET:
      return state.merge({
        passwordResetSuccess: false,
      });
    case type.FORGOT_PASSWORD_CHANGE_REQUEST:
      return state.merge({
        submitting: true,
      });
    case type.FORGOT_PASSWORD_CHANGE_SUCCESS:
      return state.merge({
        submitting: false,
        passwordChangeSuccess: true,
      });
    case type.FORGOT_PASSWORD_CHANGE_ERROR:
      return state.merge({
        submitting: false,
      });
    case type.RESEND_VERIFICATION_EMAIL_REQUEST:
      return state.merge({
        submitting: true,
        verificationEmailAddress: action.payload.email,
      });
    case type.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return state.merge({
        submitting: false,
        resendVerificationEmailSuccess: true,
      });
    case type.RESEND_VERIFICATION_EMAIL_ERROR:
      return state.merge({
        submitting: false,
      });
    case type.ACCOUNTS_FINDALL_SUCCESS:
      return state.merge({
        accounts: fromJS(action.data),
        loading: false,
      });
    case type.ACCOUNTS_FINDALL_ERROR:
      return state.merge({
        error: action.error,
        accounts: null,
        loading: false,
      });
    case type.USERDETAILS_ERROR:
      return state.merge({
        error: action.error,
        user: null,
        loading: false,
      });
    case type.SESSION_IDLE:
      return state.merge({
        sessionIdle: action.status,
      });
    case type.UPDATE_NI_NUMBER_SUCCESS:
      return state.merge({
        user: state.get('user').merge({
          nationalInsuranceNumber: action.payload.niNumber,
        }),
      });
    case type.USERDETAILS_RESET:
    case type.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
