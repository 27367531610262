import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import media from 'utils/responsive';

import Button from './Button';

const Wrapper = styled.div`
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 1.675rem;')}

  display: inline-grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto;

  .secondaryButton {
    order: 2;
  }

  ${media.medium`
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;

    .secondaryButton {
      order: unset;
    }
  `}
`;

export class ButtonGroup extends React.PureComponent {
  render() {
    const { noMargin, types, isCompact } = this.props;

    return (
      <Wrapper noMargin={noMargin}>
        {types.map(type => (
          <Button
            className={clsx('btn', {
              secondaryButton: types.indexOf(type) === 0,
            })}
            label={type.label}
            appearance={type.appearance}
            buttonType={type.buttonType}
            buttonDestination={type.buttonDestination}
            onClick={
              type.buttonType === 'openModal'
                ? () => this.openModal()
                : () => {}
            }
            ghost={type.ghost}
            key={types.indexOf(type)}
            isCompact={isCompact}
          />
        ))}
      </Wrapper>
    );
  }
}

ButtonGroup.defaultProps = {
  colorTheme: 'default',
};

export default withRouter(ButtonGroup);
