import React from 'react';

import Config from 'config';

const Cancel = ({ fill = Config.theme.colors.red }) => (
  <svg fill="none" height="28" viewBox="0 0 28 28" width="28">
    <circle cx="14" cy="14" r="14" fill={fill} />
    <rect
      fill={Config.theme.colors.white}
      height="18.2857"
      rx="1.38528"
      transform="rotate(45 19.5967 6.45349)"
      width="2.77056"
      x="19.5967"
      y="6.45349"
    />
    <rect
      fill={Config.theme.colors.white}
      height="18.2857"
      rx="1.38528"
      transform="rotate(-45 6.84082 8.58728)"
      width="2.77056"
      x="6.84082"
      y="8.58728"
    />
  </svg>
);

export default Cancel;
