import { GridSpacing } from '@material-ui/core';

import { Size } from './types';

export const getGridSpacing = (size?: Size): GridSpacing => {
  switch (size) {
    case 'small':
      return 1;
    case 'medium':
    default:
      return 4;
  }
};
