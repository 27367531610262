import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    root: {
      maxWidth: '98rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: spacing(3),
      paddingRight: spacing(3),

      [breakpoints.up('md')]: {
        paddingLeft: spacing(6),
        paddingRight: spacing(6),
      },

      [breakpoints.up('lg')]: {
        paddingLeft: spacing(8),
        paddingRight: spacing(8),
      },
    },
  })
);
