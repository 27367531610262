import { call, put, takeLatest } from 'redux-saga/effects';
import { PromiseType } from 'utility-types';

import { Investment } from 'services/Pip';

import { logger } from 'services/Logger';
import {
  getInvestmentsSuccess,
  getInvestmentsError,
  getInvestmentsByIdSuccess,
  getInvestmentsByIdError,
} from './actions';
import {
  INVESTMENTS_BY_ID_GET_REQUEST,
  INVESTMENTS_GET_REQUEST,
} from './actionTypes';
import { GetInvestmentsByIdRequestAction } from './types';

export function* getInvestments() {
  try {
    const data: PromiseType<ReturnType<typeof Investment.findAll>> = yield call(
      Investment.findAll
    );

    yield put(getInvestmentsSuccess(data));
  } catch (error) {
    yield call(logger.error, error);
    yield put(getInvestmentsError(error));
  }
}

export function* getInvestmentById({
  payload,
}: GetInvestmentsByIdRequestAction) {
  try {
    const data: PromiseType<ReturnType<typeof Investment.get>> = yield call(
      Investment.get,
      payload.id
    );

    yield put(getInvestmentsByIdSuccess(data));
  } catch (error) {
    yield call(logger.error, error);
    yield put(getInvestmentsByIdError(payload.id, error));
  }
}

export function* investmentsSaga() {
  yield takeLatest(INVESTMENTS_GET_REQUEST, getInvestments);
  yield takeLatest(INVESTMENTS_BY_ID_GET_REQUEST, getInvestmentById);
}
