import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ typography }) => ({
  strongText: {
    fontWeight: typography.fontWeightBold,
    '& + &': {
      marginTop: '1rem',
    },
  },
}));
