import React, { FC, useCallback, useState } from 'react';
import { Box, Drawer, Grid, Hidden, IconButton } from '@material-ui/core';
import { Close, Menu } from '@material-ui/icons';

import { useI18n } from 'utils/i18nConnect';

import { MenuNavLinkItem } from '../../types';
import { LoginJoinLinks } from '../LoginJoinLinks';
import { AppBarLogo } from '../AppBarLogo';

import { MobileNavigationList } from './MobileNavigationList';
import { useStyles } from './MobileNavigation.styles';

interface MobileNavigationProps {
  loggedIn: boolean;
  navLinks: MenuNavLinkItem[];
}

export const MobileNavigation: FC<MobileNavigationProps> = ({
  loggedIn,
  navLinks,
}) => {
  const classes = useStyles();
  const t = useI18n()('MobileNavigation');
  const [open, setOpen] = useState(false);
  const openDrawer = useCallback(() => setOpen(true), [setOpen]);
  const closeDrawer = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <Hidden implementation="css" lgUp>
        <IconButton
          aria-expanded={open}
          data-test-id="mobile-menu-toggle-button"
          aria-label={t`Mobile menu`}
          onClick={openDrawer}
        >
          <Menu />
        </IconButton>
      </Hidden>
      <Drawer
        open={open}
        onClose={closeDrawer}
        anchor="right"
        PaperProps={{ className: classes.drawer }}
      >
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item xs>
            <Box pl={2} pr={1.5} py={1.5}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <AppBarLogo inverse />
                </Grid>
                <Grid item>
                  <IconButton
                    aria-expanded={open}
                    data-test-id="mobile-menu-toggle-button"
                    aria-label={t`Mobile menu`}
                    onClick={closeDrawer}
                    className={classes.close}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <MobileNavigationList navLinks={navLinks} />
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box p={2} bgcolor="grey.200">
              <LoginJoinLinks loggedIn={loggedIn} />
            </Box>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};
