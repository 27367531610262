import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { Text } from 'components/Atoms/Text';
import { UnorderedList } from 'components/Atoms/UnorderedList';
import { OrderedList } from 'components/Atoms/OrderedList';
import { ListItem } from 'components/Atoms/ListItem';
import { FaqItem } from 'components/Organisms/FaqSection';
import { useI18n } from 'utils/i18nConnect';

import { useDataStyles } from './Faq.styles';

export const useOurLoansFaq = (): FaqItem[] => {
  const classes = useDataStyles();
  const t = useI18n()('pages.faq.ourLoansFaq');
  return [
    {
      id: 2,
      question: t`Who are Loan Investors lending to?`,
      answer: (
        <>
          <Text paragraph>
            {t`Each British Pearl property investment is owned by a limited company referred to as a Special Purpose Vehicle (SPV) as they are incorporated for the special purpose of buying and selling own real estate.`}
          </Text>
          <Text paragraph>
            {t`Our role is to source and manage the property held in the SPVs, facilitate investment in them via our Platform and distribute returns to both Loan and Share Investors.`}
          </Text>
          <Text paragraph>
            {t`As a Loan Investor you provide a secured loan to the SPV which holds your chosen property investment. The SPV, incorporated and managed by British Pearl, is the borrower and the loan is registered as a first charge against the properties held within it.`}
          </Text>
          <Text paragraph>
            {t`This model means that our Loan Investors are only exposed to the credit/counterparty risk of the SPV which they lend to. Unlike traditional P2P arrangements, the borrower is not an independent third party. This gives British Pearl visibility and control, including the ability to intervene at an early stage should any issues arise.`}
          </Text>
          <Text paragraph>
            {t`The following details are common to all our borrowers:`}
          </Text>
          <UnorderedList>
            <ListItem paragraph>
              <Box fontWeight="bold">{t`Restricted borrowers`}</Box>
              {t`No loans are made to individuals or businesses other than the SPVs incorporated and managed by British Pearl.`}
            </ListItem>
            <ListItem paragraph>
              <Box fontWeight="bold">{t`Incorporation information`}</Box>
              {t`All the SPVs are incorporated in England and Wales and registered with Companies House for the sole purpose of buying and selling own real estate.`}
            </ListItem>
            <ListItem paragraph>
              <Box fontWeight="bold">{t`Shareholders`}</Box>
              {t`The shareholders are the investment’s Share Investors. No shareholder can hold more than 24.9% of an SPV’s share capital.`}
            </ListItem>
            <ListItem paragraph>
              <Box fontWeight="bold">{t`Management`}</Box>
              {t`All of the SPVs are managed by British Pearl as investment manager.`}
            </ListItem>
            <ListItem>
              <Box fontWeight="bold">{t`Documentation`}</Box>
              {t`Loan and management documentation is standardised across the SPVs and is available once you have been accepted onto the platform.`}
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      id: 3,
      question: t`How do we assess risk?`,
      answer: (
        <>
          <Text paragraph>
            {t`Our model means that a credit risk assessment of the SPVs themselves is of limited value (the SPV generally only holds the investment property, an amount of cash and the liability of the Loan Investor capital). As the loan is secured, the risk assessment is performed at the property level.`}
          </Text>
          <Text paragraph>
            {t`See the next tab for an overview of our Property Acquisition Process. For details of specific property investments, please refer to the individual Info Packs.`}
          </Text>
          <Text paragraph>
            {t`Two key drivers for assessing risk is how the property is secured and the loan-to-value ratio (LTV)`}
          </Text>
          <Typography variant="h5" paragraph>
            {t`Security and charge`}
          </Typography>
          <Text paragraph>
            {t`All loans are secured with a first charge (as with a bank mortgage) against the property held within each SPV. Loans secured with a first charge are first in line to be repaid upon any sale (after fees and any SHIELD borrowing that may be due) before Share Investors.`}
          </Text>
          <Text paragraph>
            {t`The loan security is held by British Pearl Trustees Limited (‘the Trustees’) on behalf of the Loan Investors. The agreement between the SPV and the Trustees which secures the loans (legal charge) is registered at HM Land Registry and at Companies House. Should the SPV default on the loan, then the Trustees on behalf of the Loan Investors will have the right to take the monies from the sale of the property.`}
          </Text>
          <Typography variant="h5" paragraph>
            {t`The loan-to-value ratio`}
          </Typography>
          <Text paragraph>
            {t`The loan-to-value (LTV) is a measure of how large the loan is in relation to the value of the underlying property against which the loan is secured.`}
          </Text>
          <Text paragraph>
            <Box component="span" display="block">
              <span className={classes.underlined}>
                {t`Total amount of loan`}
              </span>
              {t` X 100 = loan-to-value`}
            </Box>
            {t`Value of property`}
          </Text>
          <Text paragraph>
            {t`Currently our LTVs range from 50-70% which are conservative. An LTV of 70% means that the property would need to fall in value by more than 30% before the loan amount (excluding fees) could no longer be covered by the value of the security.`}
          </Text>
          <Typography variant="h5" paragraph>
            {t`Simplified Example: `}
          </Typography>
          <Text paragraph>
            {t`British Pearl purchases a property for £1,000,000 plus £100,000 in fees such as Stamp Duty giving a total cost of £1,100,000. If the LTV is fixed at 65%. This means that there is £450,000 of equity and £650,000 of debt.`}
          </Text>
          <Text paragraph>
            {t`If the property value fell 25% to £750,000 there would be enough money from the proceeds of sale to repay the loan. If the property value fell by 40% to £600,000 there would not be enough money from the proceeds of sale to fully repay the loan.`}
          </Text>
          <Typography variant="h5" paragraph>
            <strong>{t`Note that this example does not take into account any sale costs that would be incurred.`}</strong>
          </Typography>
          <Text paragraph>
            {t`We use the LTV as a basis to categorise`}{' '}
            <strong>{t`loan risk`}</strong> {t`into the following bands:`}
          </Text>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {t`Risk Categorisation`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t`Description`}
                </TableCell>
                <TableCell className={classes.tableCell}>{t`Action`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>{t`A`}</TableCell>
                <TableCell className={classes.tableCell}>
                  {t`LTV is estimated to be lower than 80% (Share Investor capital buffer is above 20%).`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t`Standard operating model (with loans priced at £1 per £1 of principal).`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>{t`B`}</TableCell>
                <TableCell className={classes.tableCell}>
                  {t`LTV is estimated to be above 80% but lower than 90% (Share Investor capital buffer is above 10% but below 20%).`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t`Increased loan risk categorisation highlighted to Loan Investors with explanation and outlook commentary (with Indicative Loan Price listed at £1 per £1 of principal).`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>{t`C`}</TableCell>
                <TableCell className={classes.tableCell}>
                  {t`LTV is estimated to be above 90% (Share Investor capital buffer is below 10%).`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t`Increased loan risk categorisation highlighted to Loan Investors with explanation and outlook commentary.`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      ),
    },
    {
      id: 4,
      question: 'Due diligence and property acquisition',
      answer: (
        <>
          <Text paragraph>
            {t`Our expert team sources all properties and carries out thorough due diligence in respect of deal structure, legal matters, and investment analysis. The Investment Policy is reviewed periodically in light of customer demand and economic outlook and is approved by the British Pearl Board`}
          </Text>
          <OrderedList paragraph>
            <ListItem paragraph>
              <Box component="span" fontWeight="bold" display="block" mb={2}>
                {t`Sourcing within Investment Policy criteria`}
              </Box>
              {t`We assess a wide range of opportunities nationally. Our internal valuation process is thorough and takes into account many factors including the HPI for the area, the local economy, infrastructure and transport links as well as relevant market comparables. Shortlisted properties are modelled over a full investment horizon in order to give us Share Investor income and capital outlook while ensuring Loan Investor needs can be met.`}
            </ListItem>
            <ListItem paragraph>
              <Box component="span" fontWeight="bold" display="block" mb={2}>
                {t`Investment analysis`}
              </Box>
              {t`The Investment Team models the share and loan investment strategies over the investment horizon including a review of: validating input variables, flexing the forecast assumptions, assessing alternative capital structures, time horizons and pricing models. If the property has potential then an Investment Proposal is made to the Investment Committee.`}
            </ListItem>
            <ListItem paragraph>
              <Box component="span" fontWeight="bold" display="block" mb={2}>
                {t`Due Diligence`}
              </Box>
              <Box component="span" display="block" mb={2}>
                {t`The Investment and Property Teams carry out due diligence on all potential property purchases which includes: review of legal documentation (e.g. covering planning, titles, rights of way, auction pack), external inspection, and internal inspection (mandatory before offer becomes formal).`}
              </Box>
              <Box component="span" display="block" mb={2}>
                {t`A formal valuation will be undertaken by a Royal Institute of Chartered Surveyors (“RICS”) chartered surveyor.`}
              </Box>
            </ListItem>
            <ListItem paragraph>
              <Box component="span" fontWeight="bold" display="block" mb={2}>
                {t`Investment Committee Review`}
              </Box>
              {t`The Investment Committee comprises members of the Executive Committee and Board. The Committee is responsible for assessing the investment proposal from the perspective of potential Share and Loan Investors.`}
            </ListItem>
            <ListItem paragraph>
              <Box component="span" fontWeight="bold" display="block" mb={2}>
                {t`Offer`}
              </Box>
              {t`If the investment fulfills all of our criteria and our offer is accepted, then it will be acquired using an underwriting vehicle. By pre-purchasing, British Pearl takes on the completion risk. The conveyance is carried out independently of British Pearl as an additional layer of due diligence.`}
            </ListItem>
          </OrderedList>
          <Typography variant="h5" paragraph>
            {t`Monitoring`}
          </Typography>
          <Text paragraph>
            {t`Once an investment property has been purchased and is made available as an investment on the platform, it is monitored on a regular basis to establish:`}
          </Text>
          <UnorderedList paragraph>
            <ListItem>
              {t`that it is performing in line with expectations,`}
            </ListItem>
            <ListItem>
              {t`whether the economic backdrop has changed, or`}
            </ListItem>
            <ListItem>
              {t`whether the property itself has faced a changing situation.`}
            </ListItem>
          </UnorderedList>
          <Text>
            {t`Once an investment is fully sold in the New Sale market and opened up into the Resale market we publish quarterly valuation updates mapped to the Land Registry HPI at the local level plus an independent RICS valuation (either onsite or desktop) once a year.`}
          </Text>
        </>
      ),
    },
    {
      id: 5,
      question: 'Our role in pricing loans',
      answer: (
        <>
          <Text paragraph>
            {t`British Pearl sources the investment property, manages the SPVs which holds the property and
              facilitates investment by both Share and Loan Investors. Before launching a new investment to the
              Platform, we decide on the SPV’s capital structure. That is, the balance between shares and loans which is
              a key driver in determining the risk profile of the investment and interest paid on the loans.`}
          </Text>
          <Text paragraph>
            {t`We structure the balance between the shares and the loans to offer various different return and risk
               profiles to our investors. The loan interest rate is calculated and fixed at that point in time to
               reflect the two key drivers of risk in the loan investments. They are:`}
          </Text>
          <OrderedList paragraph>
            <ListItem paragraph>
              {t`Investment term: the longer the initial period of the loan then the higher the interest rate that
                is offered to the investors since the funds are tied up for longer.`}
            </ListItem>
            <ListItem paragraph>
              {t`Loan-to-value (‘LTV’): the higher the LTV then the higher the interest rate that is offered to
                the investors since the equity buffer that is in place is smaller.`}
            </ListItem>
          </OrderedList>
          <Text paragraph>
            {t`Our interest rates are broadly in line with interest-only mortgage rates for a similar Investment term at the start of the investment and are fixed for the duration of the investment.`}
          </Text>
          <Text paragraph>
            {t`Loan Investors have a high level of protection for two key reasons.`}
          </Text>
          <OrderedList paragraph>
            <ListItem paragraph>
              {t`All loans hold a first charge against the investment property held within their individual SPVs.
                This is where an ordinary bank mortgage would normally be ranked and means that Loan Investors are first
                in line to be paid out by the SPV.`}
            </ListItem>
            <ListItem paragraph>
              {t`They all have a loan-to-value (‘LTV’) at the point of purchase of between 50% and 70%. This
                measures how large the loan is in relation to the value of the underlying asset that forms the security
                for the loan capital (see tab How do we assess risk?). An LTV of 60% it means that the underlying
                investment property would have to lose around 40% (excluding fees) of its value before the Loan investor
                faces capital risk.`}
            </ListItem>
          </OrderedList>
          <Text paragraph>
            {t`This means that under normal circumstances and on an on-going basis all active loan investments have an indicative loan price of £1 per £1 unit of principal. If there was a material change in the financial position or outlook for any investment then this position would be revisited in an appropriate and timely fashion.`}
          </Text>
          <Text>
            {t`If available, Loan Investors are free to list their loan holdings for sale (offers) at any price
              they wish on the Resale market. In the same way, potential Loan investors can list their loan buying
              intentions (bids) at any price they wish.`}
          </Text>
        </>
      ),
    },
    {
      id: 6,
      question: t`How do we manage dealing with a loan in late payment or default`,
      answer: (
        <>
          <Text paragraph>
            {t`Despite a rigorous investment assessment process there is no guarantee that an investment will perform and it is possible that temporary issues may mean that interest is not paid on time.`}
          </Text>
          <Text paragraph>
            {t`British Pearl’s Property Management Team oversee the management of all our investment properties and are responsible for ensuring that properties are tenanted with minimal void periods and that operational expenditure is closely monitored. See tab SPV Reserves and SHIELD for details for how we manage short-term cash flow issues.`}
          </Text>
          <Text paragraph>
            {t`The SPVs are contractually obligated to pay interest to Loan Investors. If a payment is missed the unpaid loan interest would roll over (accrue) to the next payment date. Loan Investors are entitled to receive interest until the redemption of their principal loan amount.`}
          </Text>
          <Text paragraph>
            {t`Sustained tenancy issues will affect income and so impact both Share and Loan Investors. Ultimately Share Investors may not receive dividends and Loan Investors may not receive interest payments (although interest always continues to accrue).`}
          </Text>
          <Text paragraph>
            {t`Such circumstances will only be sustainable for a limited period before we would have to consider selling the property. On sale, Loan Investors will be entitled to receive accrued interest plus return of the principal loan amount ahead of, and at the expense of, any payment to Share Investors. Conversely, Loan Investors will not share in any capital appreciation.`}
          </Text>
          <Text paragraph>
            {t`Repayment of the loan principal and any accrued interest is not guaranteed. It is dependent on the property not depreciating significantly in value at sale. It is important, that potential Loan Investors thoroughly review the Info Packs and LTVs to understand the risks of lending.`}
          </Text>
          <Typography variant="h5" paragraph>
            <strong>{t`SPV Reserves and SHIELD`}</strong>
          </Typography>
          <Text paragraph>
            {t`Each SPV maintains its own cashflow pot - referred to as the SPV Reserve - to ensure that it is in a position to meet its liabilities such as void periods and unexpected maintenance costs (e.g boiler replacement).`}
          </Text>
          <Text paragraph>
            {t`British Pearl also offers a short-term loan facility called ‘SHIELD’, should an SPV experience a negative cash flow event after the SPV Reserve has been fully utilised. SHIELD is funded directly from British Pearl revenues and can be deployed to any SPV, unlike the SPV Reserve which is property specific. All SHIELD funds used must be repaid by the SPV.`}
          </Text>
          <Text paragraph>
            {t`Both the SPV Reserves and SHIELD provide temporary liquidity to help good investments that have unfortunately been hit with unforeseen negative cash flow. Neither are Contingency Funds as defined by the FCA: arrangements used for the purpose of making payments to a lender when a borrower does not meet its obligations under a P2P agreement.`}
          </Text>
          <Text>
            {t`While interest payments may be made from the SPV Reserves or SHIELD, the decision would be made at the sole discretion of British Pearl.  Neither liquidity support provides a guarantee that interest or principal payments will be made and Loan Investors should`}{' '}
            <span className={classes.underlined}>{t`not`}</span>{' '}
            {t`rely on them to do so.`}
          </Text>
        </>
      ),
    },
    {
      id: 8,
      question: t`The Resale Market and early exit`,
      answer: (
        <>
          <Text paragraph>
            {t`Both of our share and loan investments such should be viewed as longer-term investments in line with
              the Info Packs that are published for each offering.`}
          </Text>
          <Text paragraph>
            {t`We understand there may be times when people need to access their invested funds. Therefore we
              created a source of liquidity through our secure and automated Resale Market which is activated once the
              property has been fully sold in the New Sale market.`}
          </Text>
          <Text paragraph>
            {t`Here, Loan Investors can list all or some of their loans for sale stating a preferred price. The
              Resale Market will automatically match you to a willing buyer. There is a 0.5% loan sellers fee. Sales
              will depend on matching and we cannot guarantee that Loan Investors will be able to sell at their
              preferred quantities, prices or times.. This could mean selling at less than £100 nominal paid in the New
              Sale Market.`}
          </Text>
          <Text>
            {t`For details on how the Resale Market works please see the Resale Market FAQs.`}
          </Text>
        </>
      ),
    },
    {
      id: 9,
      question: t`What happens if British Pearl ceases trading`,
      answer: (
        <>
          <Text paragraph>
            {t`Each property investment is owned by a special purpose vehicle (“SPV”), which is a UK Limited
              Company incorporated for the “special purpose” of buying property. This is done so that each property
              investment is held completely separately from every other and also from British Pearl (the business that
              operates the investment platform).`}
          </Text>
          <Text paragraph>
            {t`An additional layer of protection is provided by the structure of British Pearl Group. Various
              functions have been kept separate by incorporating distinct legal entities for each, out of reach of any
              potential creditors of British Pearl, including estate management and the security trustee whose role is
              to hold the charged property on behalf of Loan Investors.`}
          </Text>
          <Text paragraph>
            {t`In the event of British Pearl suffering financial difficulties, the British Pearl Board will trigger
              our wind down plan.  The purpose of the plan is to ensure a timely and orderly wind down of our activities
              with a view to mitigating impact on investors prior to British Pearl ceasing trading.`}
          </Text>
          <Text>
            {t`All cash (but not investments) held either in your main or ISA accounts is designated as client
              money. This means, your funds are held in trust in a segregated bank account. Client money is kept
              separate to British Pearl’s funds and is protected by the Financial Services Compensation Scheme, up to a
              personal limit of £85,000.`}
          </Text>
        </>
      ),
    },
    {
      id: 10,
      question: t`Loan investors and tax`,
      answer: (
        <>
          <Text paragraph>
            {t`Valued Added Tax (VAT) is payable on the Resale Market fees, only. All other Loan Investor fees are exempt from VAT.`}
          </Text>
          <Text paragraph>
            {t`Income Tax: `}
            <strong>{t`Interest is paid gross`}</strong>,
            {t` without income tax deducted.`}
          </Text>
          <UnorderedList paragraph>
            <ListItem paragraph>
              {t`Basic rate tax payers are entitled to a ‘Personal Savings Allowance’ of £1,000. This is reduced
                to £500 for Higher Rate Tax Payers and nil for Additional Rate Tax Payers.`}
            </ListItem>
            <ListItem paragraph>
              {t`Where an overseas investor can take advantage of the terms of a Double Taxation Agreement, they
                may have to make a claim for relief under that Agreement before any payment is made.`}
            </ListItem>
            <ListItem paragraph>
              {t`Corporation Tax: Withholding tax will not be applied to interest income paid to Loan Investors
                who are UK corporates. Instead, interest income must be accounted for in the company accounts and the
                income is taxed on an accruals basis and Corporation Tax may be payable, subject to the company’s
                overall profitability.`}
            </ListItem>
            <ListItem paragraph>
              {t`Where the Loan Investor is an overseas corporate, 20% withholding tax will be applicable on
                interest income unless the corporation is a bank or the overseas company has a permanent establishment
                in the UK and the interest forms part of the taxable income of that permanent establishment. The level
                of withholding tax can be reduced by making a claim under the relevant double tax treaty depending on
                the jurisdiction of residence.`}
            </ListItem>
          </UnorderedList>
          <Text>
            {t`Your tax treatment depends on your individual circumstances and may be subject to change by HMRC in
              future. Nothing on our site constitutes tax advice; these tax rates are provided as an indication only and
              all investors are strongly advised to obtain independent advice.`}
          </Text>
        </>
      ),
    },
  ];
};
