import cuid from 'cuid';
import { isEmpty, memoize } from 'lodash';
import store from 'store';

import Config from 'config';

import api from '../endpoints/OAuth';

let timer;

/**
 * Return existing client ID or generate new one
 */
const getClientId = () => {
  let clientId = store.get('cuid');
  if (!clientId) {
    clientId = cuid();
    // keep the client id the same
    store.set('cuid', clientId);
  }
  return clientId;
};

export default class Auth {
  /**
   * Aka login()
   */
  static accessToken(email, password, scope = null) {
    store.set('inactivity', Date.now());
    return api.accessToken
      .create(email, password, scope, getClientId())
      .then(token => {
        store.set('auth', token);

        Auth.refreshToken.cache.clear();
        return Auth.getAccessToken();
      });
  }

  /**
   * Refresh the access token if available
   */
  static refreshToken = memoize(
    (refreshToken = (store.get('auth') || {}).refresh_token) => {
      if (!refreshToken) {
        return Promise.reject(Error('No refresh token to refresh'));
      }

      store.set('inactivity', Date.now());
      return api.accessToken
        .refresh(refreshToken, getClientId())
        .then(token => {
          store.set('auth', token);

          Auth.refreshToken.cache.clear();
          return Auth.getAccessToken();
        })
        .catch(() => {
          store.remove('auth');
          store.remove('cuid');

          return Promise.reject(Error("Can't refresh the token"));
        });
    }
  );

  /**
   * Remove local store, so forget about all access tokens
   */
  static logout() {
    return Auth.getAccessToken()
      .then(token => {
        api.accessToken.remove(token);
        store.remove('auth');
        store.remove('cuid');
        return Promise.resolve(true);
      })
      .catch(() => {
        store.remove('auth');
        store.remove('cuid');
      });
  }

  /**
   * Get access token (use refresh token when needed)
   */
  static getAccessToken() {
    Auth.checkActivity();

    // 401, 403?
    const authStore = store.get('auth') || {};

    if (isEmpty(authStore)) {
      return Promise.reject(Error('User unauthorized'));
    }

    if (!authStore.refresh_token) {
      // it has to be redirected to login page
      return Promise.reject(Error('No refresh token to refresh'));
    }

    if (!Auth.hasAccessToken()) {
      return Auth.refreshToken();
    }
    return Promise.resolve(`${authStore.token_type} ${authStore.access_token}`);
  }

  /**
   * Just check if there is valid access token
   */
  static hasAccessToken() {
    Auth.checkActivity();

    const authStore = store.get('auth') || {};

    return !!(
      authStore &&
      authStore.access_token &&
      new Date().getTime() <= authStore.expires
    );
  }

  /**
   * Returns header configuration with access token
   */
  static getAuthHeader() {
    return Auth.getAccessToken().then(token => ({
      headers: {
        common: {
          Authorization: token,
        },
      },
    }));
  }

  static checkActivity() {
    const inactivity = store.get('inactivity');

    if (
      inactivity &&
      Date.now() - inactivity > Config.sessionIdle.idleTimeout
    ) {
      api.accessToken.remove(store.get('auth'));
      store.remove('auth');
      store.remove('cuid');
    }

    if (!timer) {
      timer = setInterval(() => {
        store.set('inactivity', Date.now());
      }, 1000);
    }
  }
}
