import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useI18n } from 'utils/i18nConnect';
import { Pip } from 'services/Pip';

export interface PropertyCardBannerProps {
  state: Pip.InvestmentState;
  suspended?: boolean;
  upForSale?: boolean;
}

export const PropertyCardBanner: FC<PropertyCardBannerProps> = ({
  suspended,
  upForSale,
  state,
}) => {
  const t = useI18n()('Molecules.PropertyCard.PropertyCardBanner');
  const bannerMessage =
    (state === Pip.InvestmentState.Closed && t`Past investment`) ||
    (state === Pip.InvestmentState.Closing && t`Past investment`) ||
    (suspended && t`Suspended`) ||
    (upForSale && t`For sale`) ||
    (state === Pip.InvestmentState.OnSecondaryMarket && t`Resale market open`);

  return bannerMessage ? (
    <Box bgcolor="common.black" color="common.white" textAlign="center" p={1}>
      <Typography variant="body2">
        <Box component="span" fontWeight="bold">
          {bannerMessage}
        </Box>
      </Typography>
    </Box>
  ) : null;
};
