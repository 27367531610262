import React from 'react';

import Config from 'config';

const Diamond = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 48 48" width={width} {...props}>
    <g fill={fill}>
      <path d="M25.04 42.847c-.4002.502-1.1632.502-1.5636 0L4.0786 18.5237c-.2636-.3305-.291-.791-.0682-1.1503L11.472 5.3338c.1822-.2942.5038-.4732.85-.4732h23.8726c.3462 0 .6677.179.85.4733l7.4598 12.039c.2226.359.1953.8194-.0682 1.15l-19.396 24.324zm17.393-25.0193l-6.795-10.967H12.8786l-6.797 10.967 18.1766 22.792 18.175-22.792z" />
      <path d="M5.9315 18.613h36.137c.5145 0 .9315-.4478.9315-1 0-.5524-.417-1-.9315-1H5.9315c-.5145 0-.9315.4476-.9315 1 0 .5522.417 1 .9315 1z" />
      <path d="M25.1253 6.359c.275-.4788.11-1.09-.369-1.3652-.4787-.275-1.09-.11-1.365.369l-6.918 12.0395c-.1344.234-.1686.5122-.0948.7717l6.918 24.3235c.151.5312.704.8394 1.2353.6883.5312-.151.8394-.7042.6883-1.2354l-6.802-23.9176L25.1258 6.359z" />
      <path d="M23.2964 41.9504c-.151.5312.157 1.0843.6883 1.2354.5312.151 1.0843-.157 1.2354-.6883l6.918-24.3235c.074-.2595.04-.5378-.095-.7717L25.126 5.3627c-.2752-.479-.8864-.644-1.3653-.369-.4787.2753-.644.8865-.3687 1.3654l6.7076 11.674-6.8024 23.918z" />
      <path d="M32.0982 18.2855L37.1184 6.246c.2126-.5098-.0284-1.0953-.538-1.308-.5098-.2125-1.0954.0285-1.308.5382l-5.02 12.0396c-.2127.5097.0282 1.0953.538 1.3078.5097.2126 1.0952-.0284 1.3078-.538zm-13.8357-.7695L13.244 5.4762c-.2124-.5097-.798-.7507-1.3077-.5382-.5098.2125-.7508.798-.5383 1.3078l5.0185 12.0396c.2125.5098.798.7508 1.3078.5383.5097-.2125.7507-.798.5382-1.3078z" />
    </g>
  </svg>
);

Diamond.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 48,
  width: 48,
};

export default Diamond;
