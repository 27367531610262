import React from 'react';
import styled from 'styled-components';

import Config from 'config';

const Element = styled.span`
  position: relative;

  &::before {
    background: linear-gradient(#ffebd4 0%, #efd1bb 100%);
    border-radius: 50%;
    content: "${({ row }) => row}";
    display: block;
    font-weight: ${Config.theme.fontWeight.medium};
    line-height: 1;
    padding: 1rem;
    position: absolute;
    text-align: center;
    width: 1rem;
    z-index: 15;
  }

  @media screen and (max-width: 60em) {
    display: flex;
    flex-direction: column;
    text-align: center;

    &::before {
      left: 50%;
      margin-left: -1.5rem;
      position: relative;
    }
  }
`;

export const CounterIcon = ({ className, ...props }) => (
  <Element className={className} {...props} />
);

CounterIcon.defaultProps = {
  className: '',
};
