import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

const Element = styled.div`
  ${space};
`;

const Container = props => <Element px={[1, 2, 3, 4]} {...props} />;

export default Container;
