import { createMuiTheme } from '@material-ui/core/styles';
import { themeOptions } from '../themeOptions';

export const buyTheme = createMuiTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
    primary: {
      main: '#026FBD',
    },
    secondary: {
      main: '#4BA2E0',
    },
  },
});
