import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';

import { ButtonLink } from 'components/Atoms/Button';
import { loginPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

interface LoginJoinLinksProps {
  loggedIn?: boolean;
}

export const LoginJoinLinks: FC<LoginJoinLinksProps> = ({ loggedIn }) => {
  const t = useI18n()('LoginJoinLinks');
  return (
    <Box width="100%" height="100%" display="flex">
      {loggedIn ? (
        <ButtonLink
          fullWidth
          data-test-id="logout-link"
          to={loginPaths.logout()}
        >
          {t`Log out`}
        </ButtonLink>
      ) : (
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item xs={6}>
            <ButtonLink
              fullWidth
              data-test-id="login-link"
              to={loginPaths.login()}
            >
              {t`Log in`}
            </ButtonLink>
          </Grid>
          <Grid item xs={6}>
            <ButtonLink
              fullWidth
              data-test-id="join-button"
              to={loginPaths.register()}
              variant="outlined"
              color="inherit"
            >
              {t`Join`}
            </ButtonLink>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
