import { LocationDescriptor } from 'history';
import { Role } from 'services/Pip';

import { onboardingPaths, loginPaths } from '../paths';

interface UserState {
  loggedIn: boolean;
  roles: string[];
  currentPathname: string;
  userClassified: boolean;
  userAccreditationExpired: boolean;
}

const ACCREDITATION_ROLES = [Role.InvestorClassified, Role.InvestorVerified];

export const findRedirection = ({
  loggedIn,
  roles,
  currentPathname,
  userClassified,
  userAccreditationExpired,
}: UserState): LocationDescriptor | null => {
  if (!loggedIn) {
    return {
      pathname: loginPaths.login(),
      state: {
        redirectAfterLogin: currentPathname,
      },
    };
  }

  if (
    !ACCREDITATION_ROLES.find(role => roles.includes(role)) &&
    !userClassified &&
    currentPathname.indexOf(onboardingPaths.accreditation()) !== 0
  ) {
    return onboardingPaths.accreditation();
  }

  if (
    userClassified &&
    userAccreditationExpired &&
    currentPathname.indexOf(onboardingPaths.reaccreditation()) !== 0
  ) {
    return onboardingPaths.reaccreditation();
  }

  if (
    userClassified &&
    !userAccreditationExpired &&
    !roles.includes(Role.InvestorVerified) &&
    currentPathname.indexOf(onboardingPaths.verification()) !== 0
  ) {
    return onboardingPaths.verification();
  }
  return null;
};
