const { generatePath } = require('react-router-dom');

const namedPath = (pattern, params) => {
  return params ? generatePath(pattern, params) : pattern;
};

module.exports = {
  loginPaths: {
    login: () => '/login',
    logout: () => '/logout',
    loggedOut: () => '/logged-out',
    register: () => '/register',
    forgotPassword: () => '/login/forgot-password',
    changePassword: params =>
      namedPath('/login/change-password/:token', params),
  },
};
