import { isArray } from 'lodash';

import Config from 'config';
import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  portfolio: {
    url: '/portfolio/:id',
    helpers: {
      get: id => OAuth.getAuthHeader().then(header => [{ id }, header]),
    },
    transformer: ({ data: { items } }) =>
      items && isArray(items)
        ? items.map(
            ({
              propertyDetailed: { property, ...other },
              marketplaceOfAccount,
              ...item
            }) => ({
              property: {
                ...property,
                ...other,
              },
              ...item,
              // I'm sorry for this, I didnt want to fix the transform function, because it would be a big refactor
              // This is to keep the data in line with '/investments/:id/marketplace', so the component can be reused.
              marketplaceOfAccount: {
                investmentId: marketplaceOfAccount.investmentId,
                shareBid: marketplaceOfAccount.shareBid.items,
                shareOffer: marketplaceOfAccount.shareOffer.items,
                loanBid: marketplaceOfAccount.loanBid.items,
                loanOffer: marketplaceOfAccount.loanOffer.items,
              },
            })
          )
        : [],
  },
  summary: {
    url: '/portfolio/:id/summary',
    helpers: {
      get: id => OAuth.getAuthHeader().then(header => [{ id }, header]),
    },
    transformer: ({ data: { account, ...rest } }) => ({
      account: {
        ...account,
        reservedMoneyItems: account.reservedMoneyItems.items || [],
      },
      ...rest,
    }),
  },
  isaSummary: {
    url: '/portfolio/:id/loan-isa-summary',
    helpers: {
      get: id => OAuth.getAuthHeader().then(header => [{ id }, header]),
    },
    transformer: ({
      data: {
        loanIsaAccountPortfolio: { account, ...rest },
        accountAllowanceData,
      },
    }) => ({
      account: {
        ...account,
        reservedMoneyItems: account.reservedMoneyItems.items || [],
      },
      ...rest,
      accountAllowanceData,
    }),
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
