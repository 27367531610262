import { startSubmit, stopSubmit } from 'redux-form/immutable';
import { SagaIterator } from 'redux-saga';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import { Pip, User } from 'services/Pip';
import { logger } from 'services/Logger';
import { userDetailsRequest } from 'store/SessionProvider/actions';
import { track } from 'utils/analytics';
import { sagaPromise } from 'utils/saga';

import {
  classifyError,
  classifySuccess,
  quizAttemptError,
  quizAttemptSuccess,
  quizStatusError,
  quizStatusSuccess,
} from './actions';

import * as type from './actionTypes';
import { ClassifyRequestAction, QuizAttemptRequestAction } from './types';

export function* getQuizStatusFlow(): SagaIterator {
  try {
    const status = yield call(User.quizStatus);
    yield put(quizStatusSuccess((status as unknown) as Pip.QuizTryInfoView));
  } catch (e) {
    yield call(logger.error, e);
    yield put(quizStatusError(e));
  }
}

export function* quizAttempt(payload: {
  quizVersion: string;
  classificationMethod: Pip.ClassificationMethod;
}): SagaIterator {
  try {
    const status = yield call(
      User.quizAttempt,
      payload.classificationMethod,
      payload.quizVersion
    );
    yield put(quizStatusSuccess((status as unknown) as Pip.QuizTryInfoView));

    yield call(track, 'Onboarding', 'Fail Quiz', {
      tries: ((status as unknown) as Pip.QuizTryInfoView).triesUsed,
    });

    return true;
  } catch (error) {
    yield call(logger.error, error);
    yield put(quizAttemptError(error));
  }

  return false;
}

export function* quizAttemptFlow(
  action: QuizAttemptRequestAction
): SagaIterator {
  try {
    // @ts-ignore
    const { data } = yield race({
      data: call(quizAttempt, action.payload),
      error: take(type.QUIZ_ATTEMPT_ERROR),
    });

    if (data) {
      yield put(quizAttemptSuccess());
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

export function* classifyUser(
  payload: Pip.UserClassificationDetailsView
): SagaIterator {
  try {
    const classified = yield call(
      [User, User.classifyMe],
      payload.classificationMethod,
      payload.esignature,
      payload.quizVersion
    );

    if (classified === true) {
      // refresh the user, he should has new privileges...
      const action = userDetailsRequest(true);
      const userDetailsPromise = new Promise((resolve, reject) => {
        // @ts-ignore
        action.resolve = resolve;
        // @ts-ignore
        action.reject = reject;
      });
      yield put(action);
      // @ts-ignore
      yield userDetailsPromise;

      return true;
    }

    yield put(classifyError({ _error: "Can't classify, try again later" }));
  } catch (error) {
    yield call(logger.error, error);
    yield put(classifyError(error));
  }

  return false;
}

export const classifyUserFlow = sagaPromise(function*(
  action: ClassifyRequestAction
) {
  // start submitting the form
  try {
    yield put(startSubmit('OnboardingAccreditationSignForm'));

    const { classified, error } = yield race({
      classified: call(classifyUser, action.payload),
      error: take(type.CLASSIFY_ERROR),
    });

    // finalize the form
    yield put(
      stopSubmit('OnboardingAccreditationSignForm', error && error.payload)
    );

    if (classified) {
      yield call(track, 'Onboarding', 'Accreditation complete', {
        type: action.payload.classificationMethod,
      });

      yield put(classifySuccess());
      return true;
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
});

export function* onboardingAccreditationSaga(): SagaIterator {
  yield takeLatest(type.QUIZ_ATTEMPT_REQUEST, quizAttemptFlow);
  yield takeLatest(type.QUIZ_STATUS_REQUEST, getQuizStatusFlow);
  yield takeLatest(type.CLASSIFY_REQUEST, classifyUserFlow);
}
