import React from 'react';

const ErrorTriangle = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 55 46" width={width} {...props}>
    <path
      d="M54.158 36.237L33.028 3.049A6.534 6.534 0 0 0 27.458 0a6.534 6.534 0 0 0-5.571 3.05L.893 36.236v.061A6.34 6.34 0 0 0 0 39.532C0 43.086 2.94 46 6.526 46h41.937c3.586 0 6.526-2.914 6.526-6.468.075-1.131-.248-2.275-.83-3.295zM48.525 42.2H6.526c-1.476 0-2.692-1.205-2.692-2.668 0-.442.124-.885.322-1.266L25.224 5.14c.509-.824 1.34-1.267 2.308-1.267.955 0 1.799.443 2.308 1.267l21.067 33.064c.26.381.323.824.323 1.328-.012 1.464-1.228 2.669-2.705 2.669z"
      fill={fill}
    />
    <path
      d="M27.532 33.642c-.77 0-1.34.258-1.861.763a2.544 2.544 0 0 0-.77 1.844c0 .824.26 1.463.832 1.906.508.443 1.154.7 1.86.7.708 0 1.34-.257 1.862-.7.509-.443.77-1.082.77-1.906 0-.7-.261-1.328-.77-1.844-.583-.517-1.228-.763-1.923-.763zM27.655 12.382c-.831 0-1.538.258-2.047.824-.509.566-.77 1.267-.77 2.287 0 .701.063 1.906.125 3.492l.57 8.374c.125 1.082.261 1.906.51 2.41.26.504.707.824 1.34.824.645 0 1.091-.258 1.34-.824.26-.566.446-1.328.508-2.348l.856-8.632c.062-.824.124-1.587.124-2.349 0-1.328-.186-2.287-.508-2.988-.385-.75-1.03-1.07-2.048-1.07z"
      fill={fill}
    />
  </svg>
);

ErrorTriangle.defaultProps = {
  fill: 'currentColor',
  height: 46,
  width: 55,
};

export default ErrorTriangle;
