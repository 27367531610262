import { compose } from 'redux';

import { isServerSide } from 'utils/isSSR';
import {
  blogPaths,
  commonPaths,
  glossaryPaths,
  investmentPaths,
  investorPaths,
  loginPaths,
  onboardingPaths,
  propertyPaths,
  registerPaths,
} from './paths';

export const moduleCache = {};

const makeLoader = (routeName, config) => async () => {
  const val = await compose(
    fns =>
      Promise.all(fns).then(results =>
        results.reduce(
          /*eslint-disable */
          (acc, [k, v]) => ((acc[k] = v), acc),
          /* eslint-enable */
          Array.isArray(config) ? [] : {}
        )
      ),
    entries =>
      entries.map(([k, v]) =>
        typeof v === 'function'
          ? Promise.resolve()
              .then(v)
              .then(vv => [k, vv])
          : [k, v]
      ),
    Object.entries
  )(config);

  // Cache the modules for static rendering, see Loader.js
  if (isServerSide()) {
    moduleCache[routeName] = val;
  }
  return val;
};

const loadersTemplate = {
  [commonPaths.home()]: {
    name: 'home',
    container: () => import('containers/Home'),
  },
  [commonPaths.howItWorks()]: {
    name: 'howitworks',
    container: () => import('components/Pages/HowItWorks'),
  },
  [commonPaths.isa()]: {
    name: 'isa',
    // eslint-disable-next-line import/no-cycle
    container: () => import('containers/Isa'),
  },
  [commonPaths.shares()]: {
    name: 'shares',
    // eslint-disable-next-line import/no-cycle
    container: () => import('components/Pages/Shares'),
  },
  [commonPaths.loans()]: {
    name: 'loans',
    // eslint-disable-next-line import/no-cycle
    container: () => import('components/Pages/Loans'),
  },
  [commonPaths.aboutUs()]: {
    name: 'about-us',
    container: () => import('components/Pages/AboutUs'),
  },
  [commonPaths.faq()]: {
    name: 'faq',
    container: () => import('components/Pages/Faq'),
  },
  [commonPaths.keyRisks()]: {
    name: 'key-risks',
    container: () => import('components/Pages/KeyRisks'),
  },
  [glossaryPaths.glossaryByLetter()]: {
    name: 'glossary',
    container: () => import('components/Pages/Glossary'),
  },
  [commonPaths.contact()]: {
    name: 'contact-us',
    container: () => import('components/Pages/ContactUs'),
  },
  [commonPaths.codeOfConduct()]: {
    name: 'conduct',
    container: async () => {
      // eslint-disable-next-line import/no-cycle
      const { CodeOfConduct } = await import('components/Pages/CodeOfConduct');
      return CodeOfConduct;
    },
  },
  [commonPaths.complaints()]: {
    name: 'complaints',
    container: () => import('containers/Complaints'),
  },
  [commonPaths.cookiedPolicy()]: {
    name: 'cookie',
    container: async () => {
      // eslint-disable-next-line import/no-cycle
      const { CookiePolicy } = await import('components/Pages/CookiePolicy');
      return CookiePolicy;
    },
  },
  [commonPaths.privacyNotice()]: {
    name: 'privacy',
    container: async () => {
      // eslint-disable-next-line import/no-cycle
      const { PrivacyNotice } = await import('components/Pages/PrivacyNotice');
      return PrivacyNotice;
    },
  },
  [commonPaths.termsOfUse()]: {
    name: 'terms',
    container: () => import('containers/TermsOfUse'),
  },
  [commonPaths.notFound()]: {
    name: '404',
    container: () => import('containers/ErrorNotFound'),
  },
  [loginPaths.logout()]: {
    name: 'logout',
    container: () => import('containers/Logout'),
  },
  [loginPaths.loggedOut()]: {
    name: 'loggedout',
    container: () => import('containers/LoggedOut'),
  },
  [loginPaths.login()]: {
    name: 'login',
    container: () => import('containers/Login'),
  },
  [loginPaths.forgotPassword()]: {
    name: 'forgotpassword',
    container: () => import('containers/ForgotPasswordReset'),
  },
  [loginPaths.changePassword()]: {
    name: 'changepassword',
    container: () => import('containers/ForgotPasswordChange'),
  },
  [registerPaths.register()]: {
    name: 'register',
    container: () => import('components/Pages/Register'),
  },
  [registerPaths.resendEmail()]: {
    name: 'resendemail',
    container: () => import('containers/RegisterResendEmail'),
  },
  [registerPaths.confirmEmail()]: {
    name: 'RegisterConfirm',
    container: () => import('containers/RegisterConfirm'),
    reducers: () => import('store/RegisterConfirm/reducer'),
    sagas: () => import('store/RegisterConfirm/sagas'),
  },
  [propertyPaths.propertyFilter()]: {
    name: 'properties',
    container: () => import('components/Pages/Properties'),
  },
  [propertyPaths.propertiesAll()]: {
    name: 'properties',
    container: () => import('components/Pages/Properties'),
  },
  [investmentPaths.investmentById()]: {
    name: 'investment',
    container: () => import('containers/Investment'),
    reducers: () =>
      Promise.all([
        'investment',
        import('store/Investment/reducer'),
        'incentives',
        import('store/Incentives/reducer'),
        'portfolio',
        import('store/Portfolio/reducer'),
      ]),
    sagas: () =>
      Promise.all([
        'investment',
        import('store/Investment/sagas'),
        'incentives',
        import('store/Incentives/sagas'),
        'portfolio',
        import('store/Portfolio/sagas'),
      ]),
  },
  [investmentPaths.investmentByIdSummary()]: {
    name: 'investmentSummary',
    container: () => import('containers/InvestmentSummary'),
    reducers: () =>
      Promise.all([
        'investment',
        import('store/Investment/reducer'),
        'investmentSummary',
        import('store/InvestmentSummary/reducer'),
      ]),
    sagas: () => import('store/InvestmentSummary/sagas'),
  },
  [investmentPaths.investmentByIdConfirmation()]: {
    name: 'investmentConfirmation',
    container: () => import('containers/InvestmentConfirmation'),
    reducers: () =>
      Promise.all([
        'investment',
        import('store/Investment/reducer'),
        'investmentConfirmation',
        import('store/InvestmentConfirmation/reducer'),
      ]),
    sagas: () => import('store/InvestmentConfirmation/sagas'),
  },
  [investorPaths.dashboard()]: {
    name: 'investorDashboard',
    container: () => import('containers/InvestorDashboard'),
    reducers: () => import('store/InvestorDashboard/reducer'),
    sagas: () => import('store/InvestorDashboard/sagas'),
  },
  [investorPaths.isaAccount()]: {
    name: 'investorMyIsa',
    container: () => import('containers/InvestorMyIsa'),
    reducers: () =>
      Promise.all([
        'investorMyIsa',
        import('store/InvestorMyIsa/reducer'),
        'investorActivity',
        import('store/InvestorActivity/reducer'),
        'portfolio',
        import('store/Portfolio/reducer'),
      ]),
    sagas: () =>
      Promise.all([
        'investorMyIsa',
        import('store/InvestorMyIsa/sagas'),
        'investorActivity',
        import('store/InvestorActivity/sagas'),
        'portfolio',
        import('store/Portfolio/sagas'),
      ]),
  },
  [investorPaths.documents()]: {
    name: 'investorDocuments',
    container: () => import('containers/InvestorDocuments'),
    reducers: () => import('store/InvestorDocuments/reducer'),
    sagas: () => import('store/InvestorDocuments/sagas'),
  },
  [investorPaths.mainAccount()]: {
    name: 'investorMainAccount',
    container: () => import('containers/InvestorMainAccount'),
    reducers: () =>
      Promise.all([
        'investorMainAccount',
        import('store/InvestorMainAccount/reducer'),
        'investorActivity',
        import('store/InvestorActivity/reducer'),
        'portfolio',
        import('store/Portfolio/reducer'),
      ]),
    sagas: () =>
      Promise.all([
        'investorMainAccount',
        import('store/InvestorMainAccount/sagas'),
        'investorActivity',
        import('store/InvestorActivity/sagas'),
        'portfolio',
        import('store/Portfolio/sagas'),
      ]),
  },
  [investorPaths.profile()]: {
    name: 'investor-profile',
    container: () => import('containers/InvestorProfile'),
  },
  [investorPaths.taxStatement()]: {
    name: 'investor-tax-statement',
    container: async () => {
      // eslint-disable-next-line import/no-cycle
      const { TaxStatementContainer } = await import(
        'components/Pages/TaxStatement/TaxStatementContainer'
      );
      return TaxStatementContainer;
    },
  },
  [onboardingPaths.verification()]: {
    name: 'onboardingVerification',
    container: () => import('containers/OnboardingVerification'),
    reducers: () => import('store/OnboardingVerification/reducer'),
    sagas: () => import('store/OnboardingVerification/sagas'),
  },
  [blogPaths.blogAll()]: {
    name: 'content',
    container: () => import('containers/ContentList'),
    reducers: () => import('store/Content/reducer'),
    sagas: () => import('store/Content/sagas'),
  },
  [blogPaths.blogByPath()]: {
    name: 'content',
    // eslint-disable-next-line import/no-cycle
    container: () => import('containers/Content'),
    reducers: () => import('store/Content/reducer'),
    sagas: () => import('store/Content/sagas'),
  },
  [commonPaths.lovemoney()]: {
    name: 'LoveMoneyLandingPage',
    container: () => import('components/Pages/RegisterLandingPage'),
    additionalProps: {
      brand: 'Love Money',
    },
  },
  [commonPaths.lovemoneyRegister()]: {
    name: 'RegisterConfirm',
    container: () => import('containers/RegisterConfirm'),
    reducers: () => import('store/RegisterConfirm/reducer'),
    sagas: () => import('store/RegisterConfirm/sagas'),
  },
  [commonPaths.expatnetwork()]: {
    name: 'ExpatNetworkLandingPage',
    container: () => import('components/Pages/RegisterLandingPage'),
    additionalProps: {
      brand: 'Expat Network',
    },
  },
  [commonPaths.expatnetworkRegister()]: {
    name: 'RegisterConfirm',
    container: () => import('containers/RegisterConfirm'),
    reducers: () => import('store/RegisterConfirm/reducer'),
    sagas: () => import('store/RegisterConfirm/sagas'),
  },
  [commonPaths.propertyforum()]: {
    name: 'PropertyForumLandingPage',
    container: () => import('components/Pages/RegisterLandingPage'),
    additionalProps: {
      brand: 'Property Forum',
    },
  },
  [commonPaths.propertyforumRegister()]: {
    name: 'RegisterConfirm',
    container: () => import('containers/RegisterConfirm'),
    reducers: () => import('store/RegisterConfirm/reducer'),
    sagas: () => import('store/RegisterConfirm/sagas'),
  },
  [commonPaths.rightmove()]: {
    name: 'RightMoveLandingPage',
    container: () => import('components/Pages/RegisterLandingPage'),
    additionalProps: {
      brand: 'Rightmove',
    },
  },
  [commonPaths.rightmoveRegister()]: {
    name: 'RightMoveConfirm',
    container: () => import('containers/RegisterConfirm'),
    reducers: () => import('store/RegisterConfirm/reducer'),
    sagas: () => import('store/RegisterConfirm/sagas'),
  },
  [commonPaths.express()]: {
    name: 'ExpressLandingPage',
    container: () => import('components/Pages/RegisterLandingPage'),
    additionalProps: {
      brand: 'Express',
    },
  },
  [commonPaths.expressRegister()]: {
    name: 'RegisterConfirm',
    container: () => import('containers/RegisterConfirm'),
    reducers: () => import('store/RegisterConfirm/reducer'),
    sagas: () => import('store/RegisterConfirm/sagas'),
  },
  [commonPaths.landingIsaop()]: {
    name: 'IsaOpenLandingPage',
    container: () =>
      // eslint-disable-next-line import/no-cycle
      import(
        'components/Pages/MarketingLanding/pages/isa/noIncentive/open-isa/OpenIsaPage'
      ),
  },
  [commonPaths.landingIsatrns()]: {
    name: 'IsaTransferLandingPage',
    container: () =>
      // eslint-disable-next-line import/no-cycle
      import(
        'components/Pages/MarketingLanding/pages/isa/noIncentive/open-isa/OpenIsaPage'
      ),
  },
  [commonPaths.landingLoans()]: {
    name: 'LoansPage',
    container: () =>
      // eslint-disable-next-line import/no-cycle
      import(
        'components/Pages/MarketingLanding/pages/loans/noIncentive/LoansPage'
      ),
  },
  [commonPaths.landingShares()]: {
    name: 'SharesPage',
    container: () =>
      // eslint-disable-next-line import/no-cycle
      import(
        'components/Pages/MarketingLanding/pages/shares/noIncentive/SharesPage'
      ),
  },
  '*': {
    name: 'not-found',
    container: () => import('containers/ErrorNotFound'),
  },
};

export const routes = Object.keys(loadersTemplate);

const loaders = Object.entries(loadersTemplate).reduce(
  (acc, [key, val]) => ({ ...acc, [key]: makeLoader(key, val) }),
  {}
);

export default loaders;
