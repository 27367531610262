// NOTE: this file is matched in CI by exact path and tested for compatibility of sensitive fields with what PIP API advertises.
// If this is ever to be moved or renamed, please also update its path in ci/check-sensitive-fields.

import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

interface NetworkRequestResponse {
  body: Body;
  method: string;
  headers: { [k: string]: string };
}

const privateFieldNames = [
  'access_token',
  'accountNumber',
  'addressLine1',
  'attribution',
  'bank',
  'birthDate',
  'brand',
  'buildingName',
  'buildingNumber',
  'captchaToken',
  'cardDetails',
  'cardNumber',
  'company',
  'confirmEmailToken',
  'cvc',
  'email',
  'esignature',
  'expiryDate',
  'firstName',
  'flatNumber',
  'fullName',
  'ibanAccountNumber',
  'lastDigits',
  'lastName',
  'middleNames',
  'nationalInsuranceNumber',
  'owner',
  'password',
  'phone',
  'phoneNumber',
  'postcode',
  'referenceNumber',
  'referrer',
  'referrerFriendCode',
  'referrerNext',
  'refresh_token',
  'sortCode',
  'swiftBicCode',
  'title',
  'token',
  'town',
];

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(
  privateFieldNames
);

export const logRocketNetworkSanitizer = {
  requestSanitizer: (request: NetworkRequestResponse) => {
    request.headers.Authorization = '*';
    return requestSanitizer(request);
  },
  responseSanitizer,
};
