/* eslint-disable import/no-named-as-default */
import CookieBanner from './CookieBanner';
import { Dropdown } from './Dropdown';
import IdleModal from './IdleModal';
import Pager from './Pager';
import ResaleTables from './ResaleTables';
import ResendVerificationEmailLink from './ResendVerificationEmailLink';
import UserProgress from './UserProgress';
import VerificationEmailSentMessage from './VerificationEmailSentMessage';
import Wallet from './Wallet';

// @deprecated please use nested imports e.g. import Example from 'components/Molecules/Example';
export {
  CookieBanner,
  Dropdown,
  IdleModal,
  Pager,
  ResaleTables,
  ResendVerificationEmailLink,
  UserProgress,
  VerificationEmailSentMessage,
  Wallet,
};
