/**
 * Create the store with asynchronously loaded reducers
 */

import Immutable, { fromJS } from 'immutable';
import LogRocket from 'logrocket';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootSaga } from 'store/rootSaga';
import { logRocketStateSanitizer } from 'utils/logRocket';
import { logger } from 'services/Logger';

import createReducer from './reducers';

export const loggerMiddleware = () => next => async action => {
  try {
    return await next(action);
  } catch (error) {
    logger.error(error);
    throw error;
  }
};

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.ATTACH_DEVTOOLS
  ? composeWithDevTools({
      serialize: {
        immutable: Immutable,
      },
    })
  : middleware => middleware;

export default function configureStore(initialState = {}) {
  const middleware = [
    loggerMiddleware,
    sagaMiddleware,
    LogRocket.reduxMiddleware(logRocketStateSanitizer),
  ];

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  return store;
}

export const store = configureStore();
