import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import 'sentrySourceMapsHelper';
import loadersTemplate from 'router/loaders';

import App from './app';

(async function init() {
  if (process.env.NODE_ENV === 'production' && window && document) {
    // TODO We're clearing all ssr code and using `ReactDOM.render`
    // instead of `ReactDOM.hydrate` as temporary hack. The main reason standing
    // behind it at the moment is mismatch between Material-UI styles generated
    // on server vs browser. We're unable to fix it at the moment because of
    // complex logic generating our HTML (so called SSR) + `app/router/loaders.js`
    // which is going to be removed soon. This has to be fixed in future.
    const appEl = document.getElementById('app');
    if (appEl) {
      appEl.innerHTML = '';
    }
    const route = window.location.pathname;
    // @ts-ignore
    if (loadersTemplate[route]) {
      // @ts-ignore
      await loadersTemplate[route]();
      ReactDOM.render(<App />, document.getElementById('app'));
    } else {
      ReactDOM.render(<App />, document.getElementById('app'));
    }
  } else {
    ReactDOM.render(<App />, document.getElementById('app'));
  }
})();
