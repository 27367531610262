import React from 'react';

const Glossary = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M2.3968 5.9213v12.2016c0 .465.3765.8415.8415.8415h6.14c.2684 0 .5252.1064.7145.296a1.8509 1.8509 0 0 0 1.3122.5455h2.1795c.4918 0 .9634-.1956 1.3107-.544a1.0097 1.0097 0 0 1 .7156-.2975h6.1403a.8411.8411 0 0 0 .8415-.8415v-4.2074h-.8415v4.2074h-6.1403c-.4919 0-.9635.1957-1.3107.544a1.0097 1.0097 0 0 1-.7156.2975H11.405a1.009 1.009 0 0 1-.7146-.296 1.8509 1.8509 0 0 0-1.3121-.5455h-6.14V5.9213h-.8415z" />
      <path d="M12.074 7.1835c0-1.27-.7578-2.1037-2.1037-2.1037H8.708v-.8415h1.2623c1.8296 0 2.9452 1.2274 2.9452 2.9452V18.123h-1.0519l-.1262-.1683c-.3112-.4149-.7685-.6732-1.3464-.6732H4.0798V4.2384h2.1037v.8414H4.9213V16.44h5.4697c.6716 0 1.2444.2404 1.683.639V7.1834zm0 10.5187l.4207-.4208h-.4207l.3366.1683a2.7454 2.7454 0 0 0-.137-.1683 2.5968 2.5968 0 0 0-.1996-.2025v.6233z" />
      <path d="M14.5985 16.44h5.4697v-1.2623h.8415v2.1037h-6.3112c-.7087 0-1.0864.1808-1.3089.6133l-.1174.2282H12.074V7.1835c0-1.7178 1.1156-2.9452 2.9452-2.9452h5.8905v1.683h-.8415v-.8415h-5.049c-1.346 0-2.1037.8337-2.1037 2.1037v9.8134c.397-.3843.954-.557 1.683-.557zm-1.9925.9527a2.2336 2.2336 0 0 0-.0647.117l.3742-.2283h-.4208l.4208.4208v-.7053a1.8313 1.8313 0 0 0-.2388.2845 2.06 2.06 0 0 0-.0707.1113zm-6.8432-7.74V3.3968h3.366v6.2559l-1.683-1.122-1.683 1.122zm.8415-1.5724l.8415-.561.8415.561v-3.842h-1.683v3.842z" />
      <path d="M21.7511 10.5495c0-1.8592-1.5068-3.366-3.366-3.366-1.859 0-3.3659 1.5068-3.3659 3.366 0 1.8592 1.5068 3.366 3.366 3.366 1.8591 0 3.366-1.5068 3.366-3.366zm.8415 0c0 2.324-1.8835 4.2075-4.2074 4.2075-2.324 0-4.2075-1.8836-4.2075-4.2075 0-2.3239 1.8836-4.2075 4.2075-4.2075 2.3239 0 4.2074 1.8836 4.2074 4.2075z" />
      <path d="M18.806 9.2873h-1.2623v.8415h.4207v2.5244h1.683v-.8415h-.8415z" />
      <path d="M17.123 12.6532h1.2622v-.8415h-1.2623zm1.683-3.7867V8.025h-.8416v.8415z" />
    </g>
  </svg>
);

Glossary.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Glossary;
