import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { investorPaths } from 'router/paths';

import { routeChange } from 'store/Router/actions';

import { makeSelectIsLoggedIn } from 'store/SessionProvider/selectors';

const mapStateToLoggedInProps = createStructuredSelector({
  isLoggedIn: makeSelectIsLoggedIn(),
});

const RequireNotAuth = withRouter(
  connect(mapStateToLoggedInProps)(
    class extends React.Component {
      componentDidUpdate() {
        const { isLoggedIn, dispatch, location } = this.props;
        if (isLoggedIn) {
          dispatch(routeChange(location));
        }
      }

      render() {
        const { isLoggedIn, match, children } = this.props;
        return !isLoggedIn ? (
          children
        ) : (
          <Redirect from={match.url} to={investorPaths.dashboard()} />
        );
      }
    }
  )
);

export default RequireNotAuth;
