import React, { FC, ReactNode } from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './Tooltip.styles';

export interface TooltipProps {
  title: ReactNode;
  color?: 'white' | 'black';
}

export const Tooltip: FC<TooltipProps> = ({
  title,
  children,
  color = 'black',
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiTooltip
      placement="top"
      title={title}
      classes={{
        tooltip: clsx(classes.tooltip, classes[color]),
      }}
      {...rest}
    >
      <span className={classes.span}>{children}</span>
    </MuiTooltip>
  );
};
