import {
  InvestmentAndComingSoonInvestmentView,
  InvestmentWithProperty,
} from 'services/Pip/types';

import {
  INVESTMENTS_BY_ID_GET_ERROR,
  INVESTMENTS_BY_ID_GET_REQUEST,
  INVESTMENTS_BY_ID_GET_SUCCESS,
  INVESTMENTS_GET_ERROR,
  INVESTMENTS_GET_REQUEST,
  INVESTMENTS_GET_SUCCESS,
} from './actionTypes';
import {
  GetInvestmentsByIdErrorAction,
  GetInvestmentsByIdRequestAction,
  GetInvestmentsByIdSuccessAction,
  GetInvestmentsErrorAction,
  GetInvestmentsRequestAction,
  GetInvestmentsSuccessAction,
} from './types';

export const getInvestmentsRequest = (): GetInvestmentsRequestAction => ({
  type: INVESTMENTS_GET_REQUEST,
});

export const getInvestmentsSuccess = (
  data: InvestmentAndComingSoonInvestmentView
): GetInvestmentsSuccessAction => ({
  type: INVESTMENTS_GET_SUCCESS,
  payload: data,
});

export const getInvestmentsError = (
  error: Error
): GetInvestmentsErrorAction => ({
  type: INVESTMENTS_GET_ERROR,
  payload: error,
});

export const getInvestmentsByIdRequest = (
  id: number
): GetInvestmentsByIdRequestAction => ({
  type: INVESTMENTS_BY_ID_GET_REQUEST,
  payload: { id },
});

export const getInvestmentsByIdSuccess = (
  data: InvestmentWithProperty
): GetInvestmentsByIdSuccessAction => ({
  type: INVESTMENTS_BY_ID_GET_SUCCESS,
  payload: data,
});

export const getInvestmentsByIdError = (
  id: number,
  error: Error
): GetInvestmentsByIdErrorAction => ({
  type: INVESTMENTS_BY_ID_GET_ERROR,
  payload: { id, error },
});
