import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography, Box, Grid } from '@material-ui/core';

import { AppBar } from 'components/Organisms/AppBar';
import { Footer } from 'components/Organisms/footer';
import { Section } from 'components/Molecules/Section';
import Config from 'config';

import withBase from '../Base';

export const Title = styled.h3`
  font-weight: ${Config.theme.fontWeight.medium};
`;

export const NumberList = styled.ol`
  counter-reset: section;
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 1rem;
    position: relative;

    > ol,
    > ul {
      margin-top: 1rem;
    }
  }

  > li {
    &::before {
      content: counters(section, '.') '. ';
      counter-increment: section;
      font-weight: ${Config.theme.fontWeight.medium};
      left: -2.5rem;
      position: absolute;
      width: 2rem;
    }
  }
`;

export const BulletList = styled.ul`
  list-style-type: disc;
  padding-left: 1.125rem;

  li {
    padding-left: 0.5rem;
  }
`;

export const AlphaList = styled.ol`
  list-style-type: lower-alpha;
  padding-left: 1.125rem;

  li {
    padding-left: 0.5rem;
  }
`;

export const RomanList = styled.ol`
  list-style-type: lower-roman;
  padding-left: 1.125rem;

  li {
    padding-left: 0.5rem;
  }
`;

const TableWrapper = styled.div`
  border: 1px solid ${Config.theme.colors.alto};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const CellContent = styled.span`
  text-align: left !important;
  width: 100%;
  white-space: pre;
`;

const Cell = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  hyphens: auto;
  padding: 1rem;

  &:first-child {
    position: relative;

    ${CellContent} {
      text-align: left;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid ${Config.theme.colors.alto};
  }
`;

const Header = styled(Cell)`
  background-color: ${Config.theme.colors.springWood};
`;

const Row = styled.div`
  border-bottom: 1px solid ${Config.theme.colors.pampas};
  display: flex;
  flex-direction: row;
`;

const HeaderRow = styled(Row)`
  font-weight: ${Config.theme.fontWeight.medium};

  ${CellContent} {
    text-align: right;
  }
`;

const SubHeader = styled(Cell)`
  font-weight: ${Config.theme.fontWeight.medium};
`;

const EmptyColumn = styled(Cell)`
  padding-bottom: 2rem;
`;

const TableColumn = styled.div`
  &:not(:first-child) {
    ${HeaderRow} {
      display: none;
    }
  }

  &:last-child {
    ${Row} {
      border-bottom: 0;
    }
  }
`;

export const Table = ({ header, rows, className }) => (
  <TableWrapper className={className}>
    {rows.map(({ subHeader, row, emptyRow }) =>
      emptyRow ? (
        <EmptyColumn key={shortid.generate()} />
      ) : (
        <TableColumn key={shortid.generate()}>
          <HeaderRow>
            {header &&
              header.map(cell => (
                <Header key={shortid.generate()}>
                  <CellContent>{cell}</CellContent>
                </Header>
              ))}
          </HeaderRow>

          <Row>
            {subHeader &&
              subHeader.map(cell => (
                <SubHeader key={shortid.generate()}>
                  <CellContent>{cell}</CellContent>
                </SubHeader>
              ))}

            {row &&
              row.map(cell => (
                <Cell key={shortid.generate()}>
                  <CellContent>{cell}</CellContent>
                </Cell>
              ))}
          </Row>
        </TableColumn>
      )
    )}
  </TableWrapper>
);

Table.defaultProps = {
  className: '',
  header: null,
};

Table.propTypes = {
  header: PropTypes.node,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      emptyRow: PropTypes.bool,
      subHeader: PropTypes.arrayOf(PropTypes.shape({})),
      row: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  className: PropTypes.string,
};

const LegalTemplate = ({ children, heading }) => (
  <>
    <AppBar />

    <Section size="large">
      <Grid container justify="center">
        <Grid item xs={12} lg={8}>
          <Box mb={8} textAlign="center">
            <Typography variant="h1">
              <Box fontWeight="300">{heading}</Box>
            </Typography>
          </Box>

          {React.Children.toArray(children)}
        </Grid>
      </Grid>
    </Section>

    <Footer />
  </>
);

LegalTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
};

export default withBase(LegalTemplate);
