import React, { FC } from 'react';
import { Collapse } from '@material-ui/core';

import { LoadablePasswordStrengthChecker } from 'components/Atoms/PasswordStrengthChecker';
import { logRocketHideClassName } from 'utils/logRocket';

import { TextFieldRedux, TextFieldReduxProps } from './TextFieldRedux';

export interface PasswordFieldReduxProps extends TextFieldReduxProps {
  showStrengthChecker?: boolean;
  formValues?: string[];
}

export const PasswordFieldRedux: FC<PasswordFieldReduxProps> = ({
  showStrengthChecker = false,
  input: { value, ...inputProps },
  meta: { pristine, ...metaProps },
  formValues,
  ...props
}) => {
  return (
    <>
      <TextFieldRedux
        className={logRocketHideClassName}
        type="password"
        input={{
          value,
          ...inputProps,
        }}
        meta={{ pristine, ...metaProps }}
        {...props}
      />
      <Collapse in={Boolean(showStrengthChecker && value && !pristine)}>
        <LoadablePasswordStrengthChecker
          value={value}
          formValues={formValues}
        />
      </Collapse>
    </>
  );
};
