import { Pip } from '../pip.models';
import {
  InvestmentDetails,
  InvestmentValuations,
  InvestmentWithProperty,
  Valuation,
  StateTransformer,
} from '../types';
import { itemsToArray, optional } from './generic';
import { pipPropertyDetailedViewV2ToPropertyDetailed } from './properties';

export const pipInvestmentDetailsViewToInvestmentDetails: StateTransformer<
  Pip.InvestmentDetailsView,
  InvestmentDetails
> = input => ({
  initialShareQuantity: input.initialShareQuantity.value,
  initialShareUnitPrice: input.initialShareUnitPrice.value,
  estimatedShareUnitPrice: input.estimatedShareUnitPrice.value,
  estimatedShareDividendRate: input.estimatedShareDividendRate.value,
  initialLoanQuantity: input.initialLoanQuantity.value,
  initialLoanUnitPrice: input.initialLoanUnitPrice.value,
  loanInterestRate: input.loanInterestRate.value,
  preOfferLoanQuantity: input.preOfferLoanQuantity.value,
  preOfferShareQuantity: input.preOfferShareQuantity.value,
  fundedLoanQuantity: input.fundedLoanQuantity.value,
  fundedShareQuantity: input.fundedShareQuantity.value,
});

export const pipValuationOnDateViewToValuation: StateTransformer<
  Pip.ValuationOnDateView,
  Valuation
> = input => ({
  value: input.value.value,
  valuationInfo: input.valuationInfo,
  valuationDate: new Date(input.valuationDate),
  loanToValue: input.loanToValue.value,
  reportUrl: input.reportUrl,
  indicativeBuyShareUnitPrice: input.indicativeBuyShareUnitPrice.value,
  indicativeSellShareUnitPrice: input.indicativeSellShareUnitPrice.value,
  indicativeMidShareUnitPrice: input.indicativeMidShareUnitPrice.value,
});

export const pipInvestmentViewValuationOnDateViewCollectionByTypeViewToInvestmentValuations: StateTransformer<
  Pip.InvestmentViewValuationOnDateViewCollectionByTypeView,
  InvestmentValuations
> = input => {
  return {
    Desktop: optional(
      itemsToArray(pipValuationOnDateViewToValuation),
      input.Desktop
    ),
    Surveyor: optional(
      itemsToArray(pipValuationOnDateViewToValuation),
      input.Surveyor
    ),
    HPI: optional(itemsToArray(pipValuationOnDateViewToValuation), input.HPI),
  };
};

export const pipInvestmentAndPropertyDetailedViewV2ToInvestmentWithProperty: StateTransformer<
  Pip.InvestmentAndPropertyDetailedViewV2,
  InvestmentWithProperty
> = input => {
  const { investment, propertyDetailed } = input;

  return {
    id: investment.id,
    name: investment.name,
    displayName: investment.displayName,
    subDisplayName: investment.subDisplayName,
    investmentType: investment.investmentType,
    propertyId: investment.propertyId,
    spvCompanyName: investment.spvCompanyName,
    state: investment.state,
    investmentDetailsView: pipInvestmentDetailsViewToInvestmentDetails(
      investment.investmentDetailsView
    ),
    description: investment.description,
    suspended: investment.suspended,
    upForSale: investment.upForSale,
    expectedExitDate: new Date(investment.expectedExitDate),
    ordinalNumber: investment.ordinalNumber,
    valuations: optional(
      pipInvestmentViewValuationOnDateViewCollectionByTypeViewToInvestmentValuations,
      investment.valuations
    ),
    spvOwnershipStartDateTime: new Date(investment.spvOwnershipStartDateTime),
    referenceNumberOrdinalSuffix: investment.referenceNumberOrdinalSuffix,
    propertyDetailedView: pipPropertyDetailedViewV2ToPropertyDetailed(
      propertyDetailed
    ),
  };
};
