import { fromJS } from 'immutable';

import { LOGOUT } from 'store/SessionProvider/actionTypes';

import { DEFAULT_LOCALE } from '../App/constants';

import * as types from './actionTypes';

const initialState = fromJS({
  locale: DEFAULT_LOCALE,
  groups: [],
  translations: {
    [DEFAULT_LOCALE]: {},
  },
  loading: 0,
  error: null,
});

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case types.CHANGE_LOCALE:
      return state.set('locale', action.locale);
    case types.TRANSLATION_GROUP_REQUEST:
      return state
        .updateIn(['groups'], groups =>
          groups
            .push(action.group)
            .toSet()
            .toList()
        )
        .update('loading', x => x + 1);
    case types.TRANSLATION_GROUP_SUCCESS:
      return state
        .mergeIn(['translations', action.locale], {
          [action.group]: action.translations,
        })
        .update('loading', x => x - 1);
    case types.TRANSLATION_GROUP_FAILURE:
      return state.set('error', action.error).update('loading', x => x - 1);
    default:
      return state;
  }
}

export default languageProviderReducer;
