import React from 'react';
import styled from 'styled-components';

import Config from 'config';

import { useI18n } from 'utils/i18nConnect';
import media from 'utils/responsive';

const r = 54;
const c = Math.PI * (r * 2);

const Svg = styled.svg`
  fill: none;
  height: 100%;
  left: 0;
  position: absolute;
  stroke: white;
  stroke-dasharray: ${() => c};
  stroke-dashoffset: ${({ total }) => c * (1 - total / 100)};
  stroke-linecap: round;
  stroke-width: 0.375rem;
  transform: rotate(-90deg);
  width: 100%;
  z-index: 10;

  ${media.large`
    stroke-width: .5rem;
  `};
`;

const Element = styled.span.attrs({
  color: ({ type }) =>
    !type ? Config.theme.colors.black : Config.theme.colors[type],
})`
  background: ${({ color }) => color};
  border: 0.25rem solid ${({ color }) => color};
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 5rem;
  position: relative;
  width: 5rem;

  ${media.large`
    border: .5rem solid ${({ color }) => color};
    height: 7rem;
    width: 7rem;
  `} &::after {
    background: transparent;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.375rem rgba(255, 255, 255, 0.4);
    content: '';
    display: block;
    height: calc(100% - 0.125rem);
    left: 1px;
    position: absolute;
    top: 1px;
    width: calc(100% - 0.125rem);
    z-index: 1;

    ${media.large`
      box-shadow: inset 0 0 0 .4375rem rgba(255, 255, 255, .4);
    `};
  }
`;

const Percentage = styled.p`
  color: ${Config.theme.colors.white};
  line-height: 1;
  margin: auto;
  stroke-width: 0; /* Fix for Safari text rendering */
  text-align: center;
`;

const Amount = styled.span`
  font-size: 0.875rem;
  font-weight: 700;

  ${media.large`
    font-size: 1.25rem;
  `};
`;

const Sold = styled.span`
  display: block;
  font-size: 0.8125rem;

  ${media.large`
    font-size: .875rem;
  `};
`;

const ProgressBubble = ({ className, total, type }) => {
  const t = useI18n()('ProgressBubble');
  return (
    <Element className={className} type={type}>
      <Percentage>
        <Amount>{t`${total}:n(wholePercentRoundDown)`}</Amount>

        <Sold>{t`sold`}</Sold>
      </Percentage>

      <Svg
        shapeRendering="geometricPrecision"
        total={total}
        viewBox="0 0 120 120"
      >
        <circle cx="60" cy="60" r={r} />
      </Svg>
    </Element>
  );
};

ProgressBubble.defaultProps = {
  className: undefined,
};

export default ProgressBubble;
