import React, { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { H2 } from 'components/Atoms/Heading';
import { Section } from 'components/Molecules/Section';

import { useI18n } from 'utils/i18nConnect';

import { FooterSubscribeForm, useSubscription } from './FooterSubscribeForm';
import { FooterBox } from './FooterBox';

export const FooterSubscribe: FC = () => {
  const t = useI18n();

  const { initialSent, sent, handleSubmission } = useSubscription();
  if (initialSent) {
    return null;
  }
  return (
    <Section size="medium">
      <FooterBox bgcolor="primary.main" color="white" borderRadius={24}>
        <Grid
          container
          spacing={4}
          alignItems="flex-end"
          justify="space-between"
        >
          <Grid item xs={12} md={12} lg={9} xl={6}>
            <H2 color="inherit" gutterBottom>
              {t(
                'component.FooterSubscribe.heading1'
              )`Subscribe to our newsletter `}
            </H2>
            <Typography variant="body2" color="inherit">
              {t(
                'component.FooterSubscribe.text'
              )`Sign up to receive details of investment opportunities and special offers as well as commentary on the property market and crowd investing.`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={9} xl={5}>
            {sent ? (
              <Box pl={1} pr={1} pt={1.5} pb={1.5}>
                <Typography>
                  {t(
                    'footer.FooterSubscribe.success.subscribed'
                  )`You have been subscribed.`}
                </Typography>
              </Box>
            ) : (
              <FooterSubscribeForm onSubmit={handleSubmission} />
            )}
          </Grid>
        </Grid>
      </FooterBox>
    </Section>
  );
};
