import React from 'react';

import Config from 'config';

const Surveyors = ({
  fill = Config.theme.colors.akaroa,
  fill2 = Config.theme.colors.quickSand,
  height = 64,
  width = 64,
  ...props
}) => (
  <svg height={height} viewBox="0 0 64 64" width={width} {...props}>
    <g fill="none">
      <path fill={fill2} d="M41.1702 43.8936V57.298h2V43.8935" />
      <path
        d="M46.7215 55.465l-1.06-1.696-10.7234 6.7022c-.292.1827-.47.5032-.47.848V64h2v-2.1266l10.254-6.4084z"
        fill={fill}
      />
      <path fill={fill2} d="M19.7234 43.8936V57.298h2V43.8935" />
      <path
        d="M51.2128 59.9787V54.617c0-1.1437-.3347-1.9117-1.055-2.748-.1203-.1394-.5573-.6187-.5412-.6007l-4.0584-4.0605-1.4142 1.4142 4.0212 4.0213c-.036-.038.3788.417.4772.531.429.498.5704.8227.5704 1.4427v5.3617h2zm-37.5318 0V54.617c0-.62.1414-.9448.5702-1.4426.0984-.1142.5132-.569.5144-.5704l3.984-3.982-1.4142-1.4142-4.0212 4.0213c-.021.022-.458.501-.5783.64-.721.837-1.055 1.605-1.055 2.748v5.362h2zM26.4255 64h2v-2.681c0-.3446-.1776-.665-.47-.8478L17.232 53.769l-1.06 1.696 10.2535 6.4084V64z"
        fill={fill}
      />
      <path
        d="M45.1915 26.468v8.0426c0 2.1312-.5205 5.1704-1.1722 6.147-.2753.413-1.0383 1.2337-2.1156 2.2884-.1123.11-.2274.222-.3453.3358-.598.5777-1.24 1.18-1.8822 1.7705-.225.2067-.4336.3972-.6208.5668-.112.102-.192.174-.2334.211-1.6658 1.596-3.8167 2.426-6.0348 2.426h-2.6808c-2.2186 0-4.3703-.8293-6.0098-2.4022-.0662-.06-.1463-.1323-.2583-.234-.1872-.1695-.396-.36-.621-.567-.642-.5905-1.2842-1.193-1.8823-1.771-.1176-.1135-.2325-.2252-.3445-.335-1.0774-1.055-1.8407-1.8754-2.116-2.2882-.651-.977-1.1724-4.0185-1.1724-6.147v-8.043h-2v8.0427c0 2.497.576 5.8576 1.509 7.2567.3817.5715 1.199 1.4503 2.381 2.6077.115.113.233.228.354.3446.611.5907 1.265 1.204 1.918 1.805.229.2103.441.404.632.577.115.104.1967.1776.2418.218 1.995 1.9146 4.645 2.9362 7.3697 2.9362h2.6808c2.7243 0 5.373-1.0212 7.3943-2.959.0202-.0174.102-.091.217-.195.1904-.1727.403-.3664.6313-.5766.6533-.601 1.307-1.214 1.918-1.8046.121-.117.2393-.232.355-.345 1.1822-1.1578 1.9995-2.0367 2.3802-2.608.933-1.3984 1.5083-4.7564 1.5083-7.2567v-8.043h-2z"
        fill={fill2}
      />
      <path
        d="M34.468 2v11.0638h2V1c0-.5523-.4476-1-1-1h-8.0425c-.5523 0-1 .4477-1 1v12.0638h2V2h6.0426zm18.4256 22.1277H10v2h42.8936m-5.0213-10.383v2.6808c0 .3788.214.725.5528.8945l2.129 1.064v2.0628h2V19.766c0-.3788-.214-.725-.553-.8945l-2.128-1.064v-2.0628c0-6.4215-3.3014-10.6002-9.896-14.2776-.6663-.371-1.487.111-1.487.874v6.702h2V4.089c5.007 3.12 7.383 6.566 7.383 11.6557zm-36.979 3.1268c-.339.1694-.553.5157-.553.8945v2.6808h2V20.384l2.128-1.064c.339-.1694.553-.5157.553-.8945v-2.6808c0-5.148 2.365-8.611 7.383-11.6754v4.9733h2V2.3404c0-.7585-.8116-1.241-1.478-.8784-6.614 3.598-9.905 7.8044-9.905 14.2827v2.0628l-2.128 1.064z"
        fill={fill}
      />
    </g>
  </svg>
);

Surveyors.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 64,
  width: 64,
};

export default Surveyors;
