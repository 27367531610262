import { TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';

import React, { FC } from 'react';
import { omit } from 'lodash';

import clsx from 'clsx';
import capitalize from '@material-ui/core/utils/capitalize';
import {
  useHelperTextStyles,
  useInputColorStyles,
  useInputStyles,
  useLabelStyles,
  useSelectStyles,
} from './TextField.styles';

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'variant' | 'color'> {
  color?: 'primary' | 'secondary' | 'green' | 'purple';
}

export const TextField: FC<TextFieldProps> = ({
  InputLabelProps = {},
  InputProps = {},
  FormHelperTextProps = {},
  SelectProps = {},
  color = 'secondary',
  hiddenLabel,
  ...rest
}) => {
  const { root: inputRoot, ...inputClasses } = useInputStyles();
  const inputColorClasses = useInputColorStyles();
  const labelClasses = useLabelStyles();
  const selectClasses = useSelectStyles();
  const {
    root: helperTextRoot,
    hiddenLabelRoot,
    ...helperTextClasses
  } = useHelperTextStyles();

  return (
    <MuiTextField
      fullWidth
      variant="filled"
      color="secondary"
      margin="normal"
      hiddenLabel={hiddenLabel}
      InputLabelProps={{
        classes: labelClasses,
        ...InputLabelProps,
      }}
      InputProps={{
        classes: {
          root: clsx(
            inputRoot,
            inputColorClasses[
              `color${capitalize(color)}` as keyof typeof inputColorClasses
            ]
          ),
          ...inputClasses,
          focused: inputColorClasses.focused,
          error: inputColorClasses.error,
          disabled: inputColorClasses.disabled,
        },
        disableUnderline: true,
        ...InputProps,
      }}
      FormHelperTextProps={{
        classes: {
          root: clsx(helperTextRoot, { [hiddenLabelRoot]: hiddenLabel }),
          ...helperTextClasses,
        },
        ...FormHelperTextProps,
      }}
      SelectProps={{
        classes: selectClasses,
        ...SelectProps,
      }}
      // to prevent @material-ui/picker passing this value through
      {...omit(rest, 'variant')}
    />
  );
};
