import React from 'react';
import { isCollection } from 'immutable';

export const toJS = WrappedComponent => wrappedComponentProps => {
  const propsJS = Object.entries(wrappedComponentProps).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: isCollection(val) ? val.toJS() : val,
    }),
    {}
  );

  return <WrappedComponent {...propsJS} />;
};
