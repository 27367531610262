import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useI18n } from 'utils/i18nConnect';
import { useRoutedModalContext } from 'components/Organisms/RoutedModal';
import { Button } from 'components/Atoms/Button';

const PropertyPageFaqDialog = () => {
  const { closeModal } = useRoutedModalContext();
  const i18n = useI18n();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open
      onClose={closeModal}
      aria-labelledby="property-page-faq-dialog-title"
      aria-describedby="property-page-faq-dialog-description"
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <DialogTitle id="property-page-faq-dialog-title">
        {i18n('FaqContainer')`How to choose your properties`}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          <b>{i18n('PropertyPageFaqContainer')`Step 1: `}</b>
          {i18n(
            'FaqContainer'
          )`Select shares or loans (or a mixture) based on your investment needs to see available properties. Loan Investors are entitled to fixed interest income with their capital secured against the asset. Share Investors are entitled to dividend income and any capital gains at the end of the investment. Both investors are usually paid on a monthly basis.`}
        </Typography>
        <Typography variant="body1" paragraph>
          <b>{i18n('PropertyPageFaqContainer')`Step 2: `}</b>
          {i18n(
            'FaqContainer'
          )`Read about the properties that interest you and select the ones that best suit your needs. Things to consider are the location, investment case, the financial forecasts and investment term.`}
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <b>{i18n('FaqContainer')`Look at the loan-to-value (LTV).`}</b>
            </Typography>
            <Typography variant="body1" paragraph>
              {i18n(
                'FaqContainer'
              )`This shows the size of the loans (mortgage) in relation to the value of the property.  A higher LTV typically indicates higher risk which is reflected in the       return. Click here to learn more.`}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <b>{i18n(
                'FaqContainer'
              )`Choose how long you want to invest for.`}</b>
            </Typography>
            <Typography variant="body1" paragraph>
              {i18n(
                'FaqContainer'
              )`The property cards below show how much time is left until we sell the property (up to 5 years). We offer a Resale Market which allows investors to sell their loans or shares to other investors and access funds early, although this is not guaranteed.`}
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          <b>{i18n('PropertyPageFaqContainer')`Step 3: `}</b>
          {i18n('FaqContainer')`Start building your personal portfolio.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={closeModal}
        >
          {i18n('PropertyPageFaqContainer')`Ok`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { PropertyPageFaqDialog };
