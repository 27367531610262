import React from 'react';

import Config from 'config';

const Lock = ({ height, width, color, circleColor, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 95 95" width={width} {...props}>
    <circle cx="47.5" cy="47.5" r="47.5" fill={circleColor} />
    <path
      d="M60.413 40.252v-6.475C60.413 26.741 54.826 21 47.98 21c-6.847 0-12.392 5.741-12.392 12.777v6.475c-3.109.086-5.587 2.72-5.587 5.913v16.921C30 66.366 32.562 69 35.755 69h24.49C63.438 69 66 66.367 66 63.086v-16.92c0-3.195-2.478-5.828-5.587-5.914zm-10.46 14.33v6.82c0 .907-.714 1.641-1.596 1.641h-.798c-.882 0-1.596-.734-1.596-1.64v-6.82c-1.513-.734-2.52-2.331-2.52-4.144 0-2.547 2.016-4.619 4.494-4.619 2.478 0 4.495 2.072 4.495 4.619.042 1.813-.966 3.41-2.479 4.144zm6.26-14.33H39.787v-6.475c0-4.662 3.696-8.46 8.233-8.46 4.537 0 8.233 3.798 8.233 8.46v6.475h-.042z"
      fill={color}
    />
  </svg>
);

Lock.defaultProps = {
  circleColor: Config.theme.colors.mangoTango,
  color: Config.theme.colors.white,
  height: 95,
  width: 95,
};

export default Lock;
