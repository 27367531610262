import * as type from './actionTypes';

export const investorWithdrawalsFindRequest = accountId => ({
  type: type.INVESTOR_WITHDRAWALS_FIND_REQUEST,
  accountId,
});

export const investorWithdrawalsFindSuccess = (data, accountId) => ({
  type: type.INVESTOR_WITHDRAWALS_FIND_SUCCESS,
  data,
  accountId,
});

export const investorWithdrawalsFindError = errors => ({
  type: type.INVESTOR_WITHDRAWALS_FIND_ERROR,
  error: true,
  payload: errors,
});

export const investorWithdrawalCancelRequest = (accountId, uuid) => ({
  type: type.INVESTOR_WITHDRAWALS_CANCEL_REQUEST,
  accountId,
  uuid,
});

export const investorWithdrawalCancelSuccess = uuid => ({
  type: type.INVESTOR_WITHDRAWALS_CANCEL_SUCCESS,
  uuid,
});

export const investorWithdrawalCancelError = errors => ({
  type: type.INVESTOR_WITHDRAWALS_CANCEL_ERROR,
  error: true,
  payload: errors,
});
