import React from 'react';

import Config from 'config';

const NewClose = ({ fill, height, width, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 20 20" width={width} {...props}>
    <path
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm3.298-14.69a.99.99 0 0 1 1.4 1.399l-3.219 3.218 3.343 3.344a.99.99 0 0 1-1.4 1.399l-3.342-3.343-3.219 3.218a.99.99 0 0 1-1.4-1.4l3.22-3.218-3.095-3.093a.99.99 0 0 1 1.4-1.4l3.094 3.094 3.218-3.219z"
      fill={fill}
    />
  </svg>
);

NewClose.defaultProps = {
  fill: Config.theme.colors.black,
  height: 20,
  width: 20,
};

export default NewClose;
