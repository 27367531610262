import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { Pip } from 'services/Pip';

import { PropertyCardTitle } from './PropertyCardTitle';
import { PropertyCardImage } from './PropertyCardImage';
import { PropertyCardInfo } from './PropertyCardInfo';
import { PropertyCardPlaceholder } from './PropertyCardPlaceholder';
import { ProductType } from './types';

export interface PropertyCardProps {
  id?: number;
  ltv?: number;
  title: string;
  subtitle: string;
  expectedExitDate: Date;
  imageSrc?: string;
  sharesValue: number;
  loansValue: number;
  comingSoon?: boolean;
  availableShares: number;
  availableLoans: number;
  suspended?: boolean;
  state: Pip.InvestmentState;
  filter?: ProductType;
  upForSale: boolean;
}

export const PropertyCard: FC<PropertyCardProps> = ({
  id,
  ltv,
  title,
  subtitle,
  expectedExitDate,
  imageSrc,
  sharesValue,
  loansValue,
  comingSoon = false,
  availableShares,
  availableLoans,
  filter = [],
  state,
  suspended = false,
  upForSale,
}) => (
  <Box
    data-test-id="property"
    data-test-has-shares={availableShares >= 5}
    data-test-has-loans={availableLoans >= 5}
    bgcolor="grey.300"
    position="relative"
    borderRadius="16px"
    overflow="hidden"
    boxShadow="0 0.25rem 0.875rem rgba(0, 0, 0, 0.2)"
  >
    {comingSoon ? (
      <>
        <PropertyCardTitle
          investmentId={id}
          title={title}
          subtitle={subtitle}
          comingSoon={comingSoon}
          expectedExitDate={expectedExitDate}
        />
        <PropertyCardPlaceholder />
      </>
    ) : (
      <>
        <PropertyCardTitle
          investmentId={id}
          title={title}
          subtitle={subtitle}
          ltv={ltv}
          expectedExitDate={expectedExitDate}
        />
        <PropertyCardImage
          title={title}
          imageSrc={imageSrc}
          hideLoans={filter === ProductType.Shares}
          state={state}
          suspended={suspended}
          upForSale={upForSale}
        />
        <PropertyCardInfo
          sharesValue={sharesValue}
          loansValue={loansValue}
          sharesSoldOut={
            state !== Pip.InvestmentState.OnSecondaryMarket &&
            availableShares < 1
          }
          loansSoldOut={
            state !== Pip.InvestmentState.OnSecondaryMarket &&
            availableLoans < 1
          }
          hideLoans={filter === ProductType.Shares}
          hideShares={filter === ProductType.Loans}
        />
      </>
    )}
  </Box>
);
