import React from 'react';

import Config from 'config';

const Document = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 22 30" width={width} {...props}>
    <path
      d="M20.875,8.53921356 L13.9607864,1.625 L1.625,1.625 L1.625,28.375 L20.875,28.375 L20.875,8.53921356 Z M21.875,30.375 L0.625,30.375 C0.0727152502,30.375 -0.375,29.9272847 -0.375,29.375 L-0.375,0.625 C-0.375,0.0727152502 0.0727152502,-0.375 0.625,-0.375 L14.375,-0.375 C14.6402165,-0.375 14.8945704,-0.26964316 15.0821068,-0.0821067812 L22.5821068,7.41789322 C22.7696432,7.6054296 22.875,7.85978351 22.875,8.125 L22.875,29.375 C22.875,29.9272847 22.4272847,30.375 21.875,30.375 Z"
      fill={fill}
    />
    <path
      d="M15.375,0.625 C15.375,0.0727152502 14.9272847,-0.375 14.375,-0.375 C13.8227153,-0.375 13.375,0.0727152502 13.375,0.625 L13.375,8.125 C13.375,8.67728475 13.8227153,9.125 14.375,9.125 L21.875,9.125 C22.4272847,9.125 22.875,8.67728475 22.875,8.125 C22.875,7.57271525 22.4272847,7.125 21.875,7.125 L15.375,7.125 L15.375,0.625 Z"
      fill={fill}
    />
  </svg>
);

Document.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 30,
  width: 22,
};

export default Document;
