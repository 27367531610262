import React from 'react';
import Loadable from 'react-loadable';

import { LoaderOverlay } from 'components/Atoms';

import { InvestorAuth } from './Auth';

const LoadableReaccreditation = Loadable({
  loader: () => import('containers/Reaccreditation'),
  loading: () => <LoaderOverlay active />,
});

export const ReaccreditationRoutes = () => (
  <InvestorAuth>
    <LoadableReaccreditation />
  </InvestorAuth>
);
