import React from 'react';

const EnvelopeOpened = props => (
  <svg viewBox="0 0 74 70" fill="currentColor" {...props}>
    <path d="M73.9443 26.8146C73.9441 26.7042 73.9195 26.5375 73.8766 26.4415C73.7638 26.0324 73.5131 25.6588 73.1379 25.3979L62.6329 18.0955V10.2674C62.6329 9.22967 61.7913 8.38804 60.7536 8.38804H48.6681L38.3852 1.23901C37.7425 0.791678 36.8892 0.789815 36.2445 1.23529L25.8952 8.38804H13.8627C12.8247 8.38804 11.9833 9.22967 11.9833 10.2674V18.0033L1.42734 25.299C0.908058 25.6579 0.627131 26.2316 0.618244 26.8183C0.617671 26.8293 0.615234 26.8398 0.615234 26.8513L0.671276 67.5907C0.671993 68.0891 0.870649 68.5668 1.22338 68.9188C1.57569 69.2701 2.05298 69.4673 2.55062 69.4673C2.55163 69.4673 2.55234 69.4673 2.5532 69.4673L72.1235 69.3717C73.1612 69.3703 74.0017 68.5277 74.0003 67.4898L73.9443 26.8146ZM62.6328 22.6733L68.638 26.8468L62.6328 31.0217V22.6733ZM37.3099 5.06894L42.0842 8.38789H32.5081L37.3099 5.06894ZM15.742 12.1467H58.8741V33.6349L44.3926 43.7027L38.4882 39.1228C38.4791 39.1156 38.4712 39.1112 38.4623 39.1047C37.8024 38.6096 36.869 38.5914 36.1842 39.1228L30.237 43.7358L15.742 33.7181V12.1467ZM11.9833 22.5723V31.1205L5.7992 26.8465L11.9833 22.5723ZM4.3788 30.4359L27.1209 46.1528L4.42466 63.7572L4.3788 30.4359ZM8.05049 65.7012L37.3363 42.9857L66.517 65.6208L8.05049 65.7012ZM47.5049 46.1168L70.1906 30.3454L70.2364 63.7498L47.5049 46.1168Z" />
    <path d="M50.0317 19.6641H24.5854C23.5477 19.6641 22.7061 20.5057 22.7061 21.5434C22.7061 22.5811 23.5477 23.4228 24.5854 23.4228H50.0317C51.0697 23.4228 51.911 22.5811 51.911 21.5434C51.911 20.5057 51.0697 19.6641 50.0317 19.6641Z" />
    <path d="M50.0317 29.4375H24.5854C23.5477 29.4375 22.7061 30.2791 22.7061 31.3168C22.7061 32.3546 23.5477 33.1962 24.5854 33.1962H50.0317C51.0697 33.1962 51.911 32.3546 51.911 31.3168C51.911 30.2791 51.0697 29.4375 50.0317 29.4375Z" />
  </svg>
);

export default EnvelopeOpened;
