import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    button: {
      height: spacing(8),
      borderRadius: '0',
    },
    buttonBorderBottomLeftRadius: {
      borderBottomLeftRadius: '8px',
    },
    buttonBorderBottomRightRadius: {
      borderBottomRightRadius: '8px',
    },
  })
);
