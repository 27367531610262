// Keys should match the AccountOperationSubtypes from PIP, described in
// modules/common/models/src/main/scala/com/britishpearl/account/model/AccountOperationSubtype.scala

export default i18n => ({
  BankFund: i18n('AccountActivity')`Bank fund`,
  CardFund: i18n('AccountActivity')`Card fund`,
  Withdraw: i18n('AccountActivity')`Withdraw`,
  TransferIn: i18n('AccountActivity')`Transfer in`,
  TransferOut: i18n('AccountActivity')`Transfer out`,
  IsaMoveCurrentTaxYearContribution: i18n(
    'AccountActivity'
  )`ISA transfer (current tax year contributions)`,
  IsaMoveRemainder: i18n(
    'AccountActivity'
  )`ISA transfer (without current tax year contributions)`,
  BuyLoans: i18n('AccountActivity')`Loans buy`,
  SellLoans: i18n('AccountActivity')`Loans sale`,
  BuyShares: i18n('AccountActivity')`Shares buy`,
  SellShares: i18n('AccountActivity')`Shares sale`,
  Dividend: i18n('AccountActivity')`Dividend`,
  Interest: i18n('AccountActivity')`Interest`,
  SpvProfits: i18n('AccountActivity')`SPV profits`,
  BankFundIncentive: i18n('AccountActivity')`British Pearl incentive`,
  BuySharesParticipationFee: i18n(
    'AccountActivity'
  )`Shares buy participation fee`,
  SellSharesLiquidationFee: i18n(
    'AccountActivity'
  )`Shares sale liquidation fee`,
  SellSharesPerformanceFee: i18n(
    'AccountActivity'
  )`Shares sale performance fee`,
  BuyLoansParticipationFee: i18n(
    'AccountActivity'
  )`Loans buy participation fee`,
  SellLoansLiquidationFee: i18n('AccountActivity')`Loans sale liquidation fee`,
  BuySharesStampDutyReserveTax: i18n('AccountActivity')`Shares SDRT`,
  SellSharesPerformanceFeeValueAddedTax: i18n(
    'AccountActivity'
  )`Shares sale performance fee VAT`,
});
