import React, { FC } from 'react';

import { LogoMark as LogoMarkBase } from 'components/Atoms/Icons';

import { useStyles } from './LogoMark.styles';

export const LogoMark: FC = () => {
  const classes = useStyles();
  return <LogoMarkBase className={classes.root} width={48} height={48} />;
};
