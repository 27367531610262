import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Config from 'config';
import { useI18n } from 'utils/i18nConnect';
import media from 'utils/responsive';

const Wrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Config.theme.colors.pearl};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 3rem 0;
`;

const Balance = styled.div`
  flex: 1;
  flex-basis: 100%;
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-align: center;

  ${media.large`
    flex-basis: auto;
  `} p {
    font-weight: ${Config.theme.fontWeight.light};
    line-height: 1;
    margin-bottom: 1rem;
  }

  small {
    display: block;
    font-size: 0.875rem;
  }
`;

const AccountBalance = styled(Balance)`
  flex-basis: auto;
  flex-grow: 0;
  font-size: 2rem;
  margin: 0 1rem;
`;

export const Summary = ({ mainBalance, isaBalance }) => {
  const i18n = useI18n();

  return (
    <Wrapper>
      <>
        <AccountBalance>
          <p>{i18n('Wallet')`${mainBalance}:c`}</p>

          <small>{i18n('Wallet')`Main account`}</small>
        </AccountBalance>

        <AccountBalance>
          <p>{i18n('Wallet')`${isaBalance}:c`}</p>

          <small>{i18n('Wallet')`ISA account`}</small>
        </AccountBalance>
      </>
    </Wrapper>
  );
};

Summary.defaultProps = {
  isaBalance: 0,
  mainBalance: 0,
};

Summary.propTypes = {
  mainBalance: PropTypes.number,
  isaBalance: PropTypes.number,
};
