import React from 'react';

const Bricks = props => (
  <svg viewBox="0 0 37 37" fill="none" {...props}>
    <path
      d="M35.1674 1H1.69445C1.31096 1 1 1.3109 1 1.69445V35.1674C1 35.5509 1.31096 35.8618 1.69445 35.8618H35.1674C35.5509 35.8618 35.8618 35.5509 35.8618 35.1674V1.69445C35.8618 1.3109 35.5509 1 35.1674 1ZM2.38889 2.38889H17.7596V9.47238H2.38889V2.38889ZM26.0237 10.8613V17.7365H10.8382V10.8613H26.0237ZM2.38889 10.8613H9.44923V17.7365H2.38889V10.8613ZM2.38889 19.1254H17.7596V26.0468H2.38889V19.1254ZM9.4493 34.4729H2.38889V27.4358H9.44923V34.4729H9.4493ZM26.0237 34.4729H10.8382V27.4358H26.0237V34.4729ZM34.4729 34.4729H27.4125V27.4358H29.6811C30.0646 27.4358 30.3756 27.1249 30.3756 26.7413C30.3756 26.3578 30.0646 26.0469 29.6811 26.0469H19.1485V19.1254H34.4729V34.4729ZM34.4729 17.7365H27.4125V10.8613H34.4729V17.7365H34.4729ZM34.4729 9.47238H19.1485V2.38889H34.4729V9.47238Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M32.5975 26.0469H32.1808C31.7973 26.0469 31.4863 26.3578 31.4863 26.7413C31.4863 27.1249 31.7973 27.4358 32.1808 27.4358H32.5975C32.981 27.4358 33.2919 27.1249 33.2919 26.7413C33.2919 26.3578 32.981 26.0469 32.5975 26.0469Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default Bricks;
