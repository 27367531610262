import untypedApi from '../endpoints/Notices';
import { Pip } from '../pip.models';

interface Api {
  notices: {
    get: () => Promise<Pip.NoticeView[]>;
  };
}

const api = (untypedApi as unknown) as Api;

export default class Notices {
  static findAll(): Promise<Pip.NoticeView[]> {
    return api.notices.get();
  }
}
