import React from 'react';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LoaderOverlay from '../LoaderOverlay';

class ActiveIndicator extends React.PureComponent {
  render() {
    const { active, children, className, opaque, transparent } = this.props;

    return (
      <Box
        position="relative"
        className={clsx('js-only', className)}
        data-test-id={`active-indicator-${active ? 'loading' : 'complete'}`}
      >
        <LoaderOverlay
          active={active}
          opaque={opaque}
          transparent={transparent}
        />
        {children}
      </Box>
    );
  }
}

ActiveIndicator.defaultProps = {
  className: '',
  opaque: false,
  transparent: false,
  children: undefined,
};

ActiveIndicator.propTypes = {
  className: PropTypes.string,
  opaque: PropTypes.bool,
  transparent: PropTypes.bool,
  children: PropTypes.node,
  active: PropTypes.bool.isRequired,
};

export default ActiveIndicator;
