import React from 'react';

import Config from 'config';

const MailIcon = ({ fill, height, width, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 40 28" width={width} {...props}>
    <path
      d="M22.5344 1.75757L20.411 3.43981C19.5085 4.14284 18.0753 4.14284 17.1728 3.43981L14.439 1.28051L12.8464 0.0251077L0 10.1186C0.530845 10.5203 1.22094 10.7714 1.96412 10.7714H35.6462C36.3894 10.7714 37.0795 10.5203 37.6103 10.1186L24.7639 0L22.5344 1.75757Z"
      fill={fill}
      transform="translate(1.19191 16.8476)"
    />
    <path
      d="M0 0V18.7055L11.8909 9.34021L0 0Z"
      fill={fill}
      transform="translate(0 5.8252)"
    />
    <path
      d="M36.7875 0H3.10544C1.53945 0 0.23888 1.07965 0 2.4857L15.713 14.8389L18.3407 16.8978C19.2431 17.6008 20.6764 17.6008 21.5788 16.8978L24.3392 14.7385L39.9195 2.4857C39.6276 1.07965 38.327 0 36.7875 0Z"
      fill={fill}
    />
    <path
      d="M11.8909 18.7055V0L0 9.34021L11.8909 18.7055Z"
      fill={fill}
      transform="translate(28.109 5.8252)"
    />
  </svg>
);

MailIcon.defaultProps = {
  fill: Config.theme.colors.white,
  height: 40,
  width: 28,
};

export default MailIcon;
