import React, { FC } from 'react';

import { Box, InputAdornment } from '@material-ui/core';
import { InputAdornmentProps } from '@material-ui/core/InputAdornment';
import { useStyles } from './StartAdornment.styles';
import {
  TXT_LABEL_HIDDEN_PB,
  TXT_LABEL_HIDDEN_PT,
  TXT_PB,
  TXT_PT,
} from './constants';

interface StartAdornmentProps extends Omit<InputAdornmentProps, 'position'> {
  hiddenLabel?: boolean;
}

export const StartAdornment: FC<StartAdornmentProps> = ({
  children,
  hiddenLabel,
}) => {
  const classes = useStyles();
  return (
    <InputAdornment position="start" classes={classes}>
      <Box
        color="text.secondary"
        pt={hiddenLabel ? TXT_LABEL_HIDDEN_PT : TXT_PT}
        pb={hiddenLabel ? TXT_LABEL_HIDDEN_PB : TXT_PB}
        pl={2}
      >
        {children}
      </Box>
    </InputAdornment>
  );
};
