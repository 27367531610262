import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeSelectUser } from 'store/SessionProvider/selectors';
import { FooterTradingName } from './components/FooterTradingName';
import { FooterSiteMap } from './components/FooterSiteMap';
import { FooterCapitalAtRisk } from './components/FooterCapitalAtRisk';
import { FooterSubscribe } from './components/FooterSubscribe';
import { FooterJoin } from './components/FooterJoin';
import { FooterDivider } from './components/FooterDivider';
import { BP_FOOTER_EL_ID } from './constants';

export interface FooterProps {
  hideCore?: boolean;
  hideLegal?: boolean;
  hideMarketing?: boolean;
}

export const Footer: FC<FooterProps> = ({
  hideCore = false,
  hideLegal = false,
  hideMarketing: hideMarketingProp = false,
}) => {
  const user = useSelector(makeSelectUser());
  const hideMarketing = hideMarketingProp || !!user;

  return (
    <Box width="100%" id={BP_FOOTER_EL_ID}>
      <>
        {hideMarketing || (
          <>
            <FooterJoin />
            <FooterSubscribe />
          </>
        )}
        {hideLegal || <FooterCapitalAtRisk />}
        {hideCore || (
          <>
            <FooterSiteMap />
            <FooterDivider />
            <FooterTradingName />
          </>
        )}
      </>
    </Box>
  );
};
