import React from 'react';

const Chevron = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M24 0H0v24h24" />
      <path
        d="M9 8.175L10.175 7l5 5-5 5L9 15.825 12.817 12"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

Chevron.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Chevron;
