import React, { FC } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

export const ListItem: FC<TypographyProps> = ({
  children,
  variant = 'body2',
  paragraph,
}) => (
  <Typography component="li" variant={variant} paragraph={paragraph}>
    {children}
  </Typography>
);
