import React from 'react';

import Config from 'config';

const Resale = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <polyline
      fill={fill}
      points="20.906625 6.68302222 22.6975125 8.80551111 23.930775 0.0355555556 16.531125 1.4972 18.321975 3.61968889 17.046375 5.13151111 17.5767 5.75995556 19.382625 3.61951111 18.09195 2.08973333 23.018325 1.1164 22.197375 6.95555556 20.9067 5.42595556 18.5872125 8.17502222 19.1174625 8.80355556"
    />
    <path
      d="M17.9637 12.1414222 C17.9637 8.01653333 15.1425 4.67284444 11.6620875 4.67284444 C8.1813375 4.67284444 5.3602125 8.0164 5.3602125 12.1412889 C5.3602125 16.2661778 8.1813375 19.6097333 11.6617125 19.6097333 C15.1420875 19.6097333 17.9632125 16.2661778 17.9632125 12.1408444 L17.9637 12.1414222 Z M18.7137 12.1414222 C18.7137 16.7573333 15.556725 20.4989333 11.6620875 20.4989333 C7.7673375 20.4989333 4.6102125 16.7573333 4.6102125 12.1414667 C4.6102125 7.52555556 7.7669625 3.78395556 11.6617125 3.78395556 C15.5561625 3.78395556 18.7132125 7.52528889 18.7132125 12.1412889 L18.7137 12.1414222 Z"
      fill={fill}
    />
    <polyline
      fill={fill}
      points="16.019175 17.9339556 20.8983 23.7166222 21.42855 23.0880444 16.5495 17.3058222"
    />
    <path
      d="M14.9920125 7.56631111 L11.2464375 12.0055111 C11.1920625 12.0699556 11.1049125 12.0699556 11.0505375 12.0055111 L7.8187875 8.17502222 L7.2885375 8.80355556 L10.5206625 12.6340444 C10.8679125 13.0456 11.4300375 13.0456 11.7772875 12.6340444 L15.5229375 8.19484444 L14.9925375 7.56631111 L14.9920125 7.56631111 Z"
      fill={fill}
    />
    <path
      d="M16.532775 10.6097778 L11.972625 16.0144 C11.5176 16.5541778 10.779375 16.5541778 10.324425 16.0145333 L6.7913625 11.8271556 L6.2610375 12.4560444 L9.7942875 16.6431556 C10.5420375 17.5302667 11.7555375 17.5302667 12.5032875 16.6431556 L17.0632875 11.2387111 L16.5330375 10.6102667 L16.532775 10.6097778 Z M5.7642 5.74022222 L4.285575 3.98777778 C3.537825 3.1008 2.324325 3.1008 1.5765 3.98791111 L0.0979875 5.74022222 L0.6283125 6.36875556 L2.1069375 4.61631111 C2.562 4.07653333 3.3001875 4.07653333 3.7551375 4.61617778 L5.233875 6.36875556 L5.7642 5.74022222 Z M4.2234375 8.78368889 L3.5593125 7.99666667 C3.2120625 7.58511111 2.6500875 7.58511111 2.3028375 7.99666667 L0.097875 10.6097778 L0.6282 11.2383111 L2.83305 8.6252 C2.887425 8.56075556 2.974575 8.56075556 3.02895 8.6252 L3.693075 9.41222222 L4.223325 8.78368889 L4.2234375 8.78368889 Z"
      fill={fill}
    />
  </svg>
);

Resale.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 24,
  width: 24,
};

export default Resale;
