import React from 'react';

import styled, { css } from 'styled-components';

import { Column } from 'components/Atoms/Grid';

import { Chevron } from 'components/Atoms/Icons';
import Config from 'config';

import { i18nConnect } from 'utils/i18nConnect';

import media from 'utils/responsive';

const Value = styled.p`
  font-size: 1.5rem;
  font-weight: ${Config.theme.fontWeight.light};
  line-height: 1;
  margin: 0 0 0.5rem;

  ${media.large`
    font-size: 2.25rem;
  `};
`;

const More = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: ${Config.theme.fontWeight.medium};
  justify-content: flex-end;
  margin: auto 0 0;

  svg {
    transform: rotate(90deg);
    transition: transform 0.3s;
  }

  ${media.large`
    justify-content: flex-start;
  `};
`;

const Item = styled(({ large, ...props }) => <Column {...props} />)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0;
  text-align: center;

  ${media.large`
    margin-left: 1.225rem;
    margin-right: 2rem;
    text-align: left;
  `}

  ${({ large }) =>
    large
      ? css`
          margin-left: 0;
          margin-top: 0;

          p:first-child {
            font-size: 2.25rem;

            ${media.medium`
        font-size: 3.5rem;
      `};
          }
        `
      : null}

  ${({ selected }) =>
    selected &&
    css`
      ${Value} {
        color: ${Config.theme.colors.quickSand};
      }

      ${More} svg {
        transform: rotate(-90deg);
      }
    `}
`;

const Label = styled.p`
  font-size: 0.875rem;
  margin: 0 0 1rem;
`;

const MoreLessLabel = styled.div`
  line-height: 2;
`;

class Overview extends React.PureComponent {
  render() {
    const {
      large,
      sum,
      label,
      id,
      itemSelected,
      clickHandler,
      i18n,
    } = this.props;

    return (
      <Item large={large} selected={itemSelected === id}>
        <Value>{i18n('AccountOverview')`${Number(sum)}:c`}</Value>

        <Label>{label}</Label>

        <More onClick={clickHandler}>
          <MoreLessLabel>
            {itemSelected === id
              ? i18n('AccountOverview')`Less`
              : i18n('AccountOverview')`More`}
          </MoreLessLabel>

          <Chevron />
        </More>
      </Item>
    );
  }
}

export default i18nConnect(Overview);
