import axios, { defaults } from 'axios/lib/axios';
import {
  concat,
  isEmpty,
  isPlainObject,
  memoize,
  replace,
  startsWith,
  toLower,
} from 'lodash';

import Config from 'config';

import { removeValueStructureRecursively } from './removeValueStructureRecursively';

/**
 * Converts server error to frontend's common error schema (@see redux-form errors)
 * @return Object { field1: 'error', field2: 'error', _error: 'global error' }
 */
const convertServerErrorToFrontend = errors => {
  if (errors && Array.isArray(errors)) {
    return errors.reduce((all, err) => {
      let messages = [];
      if (err && err.msg) {
        messages = (Array.isArray(err.msg) ? err.msg : [err.msg]).reduce(
          (acc, id) =>
            acc.concat([
              {
                id,
                values:
                  Array.isArray(err.args) &&
                  err.args.length === 1 &&
                  !isEmpty(err.args[0])
                    ? err.args[0]
                    : {},
              },
            ]),
          []
        );
      }

      return messages.concat(all);
    }, []);
  }

  return [];
};

export default memoize(authAdapter => {
  const fetch = axios.create();

  // responseAuth401Interceptors
  if (authAdapter) {
    fetch.interceptors.response.use(
      // onFulfilled
      response => {
        const { config } = response;

        config.apiretry = false;

        return response;
      },
      // onRejected
      error => {
        if (!error.config || !error.response) {
          throw error;
        }
        const {
          response: { status },
          config,
        } = error;
        const hasAuthHeader = startsWith(
          toLower(config.headers.Authorization),
          'bearer'
        );
        if (
          authAdapter &&
          hasAuthHeader &&
          status === 401 &&
          !config.apiretry
        ) {
          config.apiretry = true;
          // reply with new token!
          return authAdapter
            .refreshToken()
            .then(() =>
              !authAdapter
                ? error
                : authAdapter
                    .getAuthHeader()
                    .then(tokenHeader =>
                      axios.request({ ...config, ...tokenHeader })
                    )
            );
        }

        throw error;
      }
    );
  }

  // responseErrorHandlerInterceptors
  fetch.interceptors.response.use(
    // onFulfilled
    response => response,
    // onRejected
    error => {
      if (!error.response && error.stack) {
        // the real exception, not from axios...
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ _error: [{ id: error.message }] });
      }
      const { response } = error;
      const { data, status } = response || {};
      if (!status || status < 400 || status >= 500) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ _error: [{ id: 'error.server.internal' }] });
      }

      // @see PIP-251 and the "new error schema"
      const { errors, fields: errorFields } = data || {};
      // parse global errors
      let _error = convertServerErrorToFrontend(errors);

      // parse fields errors
      const fields = !isPlainObject(errorFields)
        ? undefined
        : Object.entries(errorFields).reduce((acc, [field, fieldErrors]) => {
            acc[replace(field, /^obj\./, '')] = convertServerErrorToFrontend(
              fieldErrors
            );
            return acc;
          }, {});

      // cleaning
      if (!_error || isEmpty(_error)) {
        if (status === 404) {
          _error = [{ id: 'error.server.notfound' }];
        } else if (status === 403) {
          _error = [{ id: 'error.server.forbidden' }];
        } else if (fields && !isEmpty(fields)) {
          _error = [{ id: 'error.server.validation' }];
        } else if (!fields || isEmpty(fields)) {
          throw error;
        } else {
          _error = [{ id: 'error.server.undefined' }];
        }
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        _error,
        fields: fields || null,
      });
    }
  );

  fetch.interceptors.response.use(
    // onFulfilled
    response => {
      const { data, config, ...rest } = response;

      // TODO This interceptor needs to be removed once we fully migrate to new endpoints and use proper transformers
      //  for data coming from server. Skip this hack for /investments/ and do transformation inside endpoint impl
      if (
        !isPlainObject(data) ||
        config.url.startsWith(`${Config.api.url}/investments`)
      ) {
        return response;
      }

      return {
        ...rest,
        config,
        data: removeValueStructureRecursively(data),
      };
    }
  );

  if (process.env.BP_EMULATE_SLOW_NETWORK) {
    fetch.interceptors.response.use(response => {
      let resolve;
      setTimeout(() => resolve(response), 2000 + Math.random() * 3000);

      return new Promise(res => {
        resolve = res;
      });
    });
  }

  return (url, options) => {
    const config = options;

    // make sure there is always default transform
    config.transformRequest = concat(
      config.transformRequest || [],
      defaults.transformRequest
    );
    config.transformResponse = concat(
      defaults.transformResponse,
      config.transformResponse || []
    );

    return fetch.request({ url, ...config });
  };
});
