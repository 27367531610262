import { fromJS } from 'immutable';

import { LOGOUT } from 'store/SessionProvider/actionTypes';

import * as type from './actionTypes';

const initialState = fromJS({
  data: {},
  error: null,
});

const transformStateToJs = (state, path = null) =>
  path ? state.getIn(path).toJS() : state.toJS();

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case type.PORTFOLIO_PROPERTY_FINDALL_SUCCESS: {
      const { accountId, ...rest } = action.payload;
      return fromJS({
        ...transformStateToJs(state),
        data: {
          ...transformStateToJs(state, ['data']),
          [accountId]: rest,
        },
      });
    }
    case type.PORTFOLIO_PROPERTY_FINDALL_ERROR:
      return state.deleteIn(['data', action.id]);
    default:
      return state;
  }
};
