import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import { makeSelectUser } from 'store/SessionProvider/selectors';

import { getCurrent } from 'utils/responsive';

import Homepage from 'components/Pages/Home';

import { showVideo } from 'store/App/actions';

export class HomepageContainer extends React.Component {
  state = {
    currentBreakpoint: 'wide',
  };

  componentDidMount() {
    this.onResize();

    if (window && window.attachEvent) {
      window.attachEvent('onresize', this.onResize);
    } else if (window && window.addEventListener) {
      window.addEventListener('resize', this.onResize, true);
    }
  }

  onResize = () => window && this.setState({ currentBreakpoint: getCurrent() });

  render() {
    const { currentBreakpoint } = this.state;
    const { user, dispatchShowVideo, history } = this.props;

    return (
      <Homepage
        user={user}
        currentBreakpoint={currentBreakpoint}
        showVideo={dispatchShowVideo}
        history={history}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  dispatchShowVideo: url => dispatch(showVideo(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageContainer);
