import React from 'react';

const CreditCard = props => (
  <svg viewBox="0 0 38 26" fill="none" {...props}>
    <path
      d="M33.6636 1H4.79806C2.70458 1 1 2.683 1 4.75005V21.2501C1 23.3171 2.70458 25.0001 4.79806 25.0001H33.6635C35.757 25.0001 37.4615 23.3171 37.4615 21.2501V4.75005C37.4616 2.683 35.757 1 33.6636 1ZM35.9424 21.25C35.9424 22.4906 34.92 23.5 33.6636 23.5H4.79806C3.54163 23.5 2.51921 22.4906 2.51921 21.25V4.75005C2.51921 3.50953 3.54163 2.50005 4.79806 2.50005H33.6635C34.9199 2.50005 35.9423 3.50953 35.9423 4.75005V21.25H35.9424Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M36.702 5.5H1.75964C1.34033 5.5 1 5.83602 1 6.25003V10.75C1 11.164 1.34033 11.5001 1.75964 11.5001H36.702C37.1214 11.5001 37.4617 11.164 37.4617 10.75V6.25003C37.4616 5.83602 37.1213 5.5 36.702 5.5ZM35.9424 10H2.51921V6.99998H35.9423V10H35.9424Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M15.4327 16H6.31726C5.89795 16 5.55762 16.336 5.55762 16.75C5.55762 17.164 5.89795 17.5 6.31726 17.5H15.4327C15.852 17.5 16.1923 17.164 16.1923 16.75C16.1923 16.336 15.852 16 15.4327 16Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M15.4327 19H6.31726C5.89795 19 5.55762 19.336 5.55762 19.75C5.55762 20.164 5.89795 20.5 6.31726 20.5H15.4327C15.852 20.5 16.1923 20.164 16.1923 19.75C16.1922 19.336 15.852 19 15.4327 19Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M30.6252 14.5H29.106C27.8496 14.5 26.8271 15.5095 26.8271 16.75V18.25C26.8271 19.4905 27.8496 20.5 29.106 20.5H30.6252C31.8816 20.5 32.9041 19.4905 32.9041 18.25V16.75C32.9041 15.5095 31.8816 14.5 30.6252 14.5ZM31.3849 18.2501C31.3849 18.6641 31.0445 19.0001 30.6252 19.0001H29.106C28.6867 19.0001 28.3464 18.6641 28.3464 18.2501V16.7501C28.3464 16.3361 28.6867 16 29.106 16H30.6252C31.0445 16 31.3849 16.3361 31.3849 16.7501V18.2501Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default CreditCard;
