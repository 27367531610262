import React from 'react';
import styled from 'styled-components';

import Config from 'config';
import { useI18n } from 'utils/i18nConnect';

import { normalizePercentages } from 'utils/normalize';

const StyledLabel = styled.div`
  border: 0.25rem solid white;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: row;
  font-weight: ${Config.theme.fontWeight.light};
  height: 3rem;
  margin-bottom: 2rem;
  overflow: hidden;

  &:empty {
    display: none;
  }
`;

const Amount = styled.div`
  align-items: center;
  background-color: ${({ colour }) => colour};
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  margin: ${({ amount }) => (amount ? '0 .125rem' : '0')};
  overflow: hidden;
  text-align: center;
  width: ${({ amount }) => amount}%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;
Amount.displayName = 'Amount';

export const BalanceGraph = ({ summaryBalance: { cash, loans, shares } }) => {
  const t = useI18n()('BalanceGraph');
  const [loansAmount, sharesAmount, cashAmount] = normalizePercentages([
    loans ? loans.amount : 0,
    shares ? shares.amount : 0,
    cash ? cash.amount : 0,
  ]);

  return (
    <StyledLabel>
      {loans && loansAmount > 0 && (
        <Amount colour={loans.labelColour} amount={loansAmount}>
          {t`${loansAmount / 100}:p`}
        </Amount>
      )}

      {shares && sharesAmount > 0 && (
        <Amount colour={shares.labelColour} amount={sharesAmount}>
          {t`${sharesAmount / 100}:p`}
        </Amount>
      )}

      {cash && cashAmount > 0 && (
        <Amount colour={cash.labelColour} amount={cashAmount}>
          {t`${cashAmount / 100}:p`}
        </Amount>
      )}
    </StyledLabel>
  );
};

export default BalanceGraph;
