import React from 'react';
import styled from 'styled-components';

import Config from 'config';

const incOrDec = (x, value) => ({
  target: {
    value: value + 1000 * (x === '+' ? 1 : -1),
  },
});

const themeDarkColor = {
  SHARES: Config.theme.colors.shares,
  LOANS: Config.theme.colors.wildBlueYonder,
  ISA: Config.theme.colors.white,
};

const CSSVariables = styled.div`
  --thumbSize: 1.5rem;
  --thumbInnerSize: 0.75rem;
  --thumbInnerRadius: calc(--thumbInnerSize / 2);
  --thumbOuter: calc((--thumbSize - --thumbInnerSize) / 2);
  --themeColorLighter: ${Config.theme.colors.alto};
  --themeColorDarker: ${({ theme }) => themeDarkColor[theme]};
`;

const Control = ({ text, fn, value, modifier }) => (
  <button
    key={text}
    className={`control control-${modifier}`}
    type="button"
    onClick={() => fn(incOrDec(text, value))}
  >
    {text}
  </button>
);

function Slider(props) {
  const { className, ...rest } = props;
  const { min, max, step, value, theme, handleChange, controls } = rest;
  return (
    <CSSVariables theme={theme}>
      <div className={className}>
        {controls && (
          <Control text="-" fn={handleChange} value={value} modifier="minus" />
        )}
        <div className="range-wrapper">
          <input
            className="range-input"
            type="range"
            min={min}
            max={max}
            step={step}
            value={value || min}
            onChange={handleChange}
          />
          <div
            style={{ width: `${((value - min) / (max - min)) * 100}%` }}
            className="progress-bar"
          />
        </div>

        {controls && (
          <Control text="+" fn={handleChange} value={value} modifier="plus" />
        )}
      </div>
    </CSSVariables>
  );
}

const Styled = styled(Slider)`
  width: 100%;
  height: 30px;
  pointer-events: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .range-wrapper {
    position: relative;
    flex: 1;
  }
  .range-input {
    appearance: none;
    width: 100%;
    flex-grow: 1;
    cursor: pointer;
    margin: 0;
    height: 100%;
    background: transparent;
  }
  .range-input::-moz-range-thumb {
    -moz-appearance: none;
    margin-top: calc(var(--thumbSize) / 2 * -1);
    width: var(--thumbSize);
    height: var(--thumbSize);
    border: none;
    border-radius: var(--thumbSize);
    background: var(--themeColorDarker);
    top: 3px;
    position: relative;
  }

  .range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: calc(var(--thumbSize) / 2 * -1);
    width: var(--thumbSize);
    height: var(--thumbSize);
    border: none;
    border-radius: var(--thumbSize);
    background: var(--themeColorDarker);
    top: 3px;
    position: relative;
  }

  .range-input::-moz-range-track {
    width: 100%;
    height: 7px;
    border-radius: 12px;
    margin: calc(var(--thumbOuter) * -1);
    background: var(--themeColorLighter);
    position: relative;
  }

  .range-input::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    border-radius: 12px;
    margin: calc(var(--thumbOuter) * -1);
    background: var(--themeColorLighter);
    position: relative;
  }

  .range-input::-moz-focus-outer {
    border: 0;
  }

  .range-input:focus {
    outline: none;
  }

  .control {
    background: none;
    border: none;
    color: var(--themeColorDarker);
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
  }

  .control-minus {
    padding-top: 0.125rem;
    margin-right: 0.75rem;
  }

  .control-plus {
    padding-top: 0.375rem;
    margin-left: 0.75rem;
  }

  .progress-bar {
    background: var(--themeColorDarker);
    border-radius: 12px 0 0 12px;
    bottom: 0;
    height: 7px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    margin: auto;
  }
`;

export default Styled;
