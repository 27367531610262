import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, typography, palette }) =>
  createStyles({
    menu: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 0,
      padding: 0,
      textAlign: 'center',
      height: '100%',
      paddingRight: spacing(1),
    },
    menuItem: {
      display: 'inline-flex',
      fontWeight: typography.fontWeightBold,
      padding: 0,
      fontSize: '1rem',
      textAlign: 'center',
      position: 'relative',
    },
    link: {
      display: 'inline-block',
      lineHeight: 1,
      padding: spacing(4, 2),
      transition: 'padding 0.3s',
      cursor: 'pointer',
      fontWeight: typography.fontWeightBold,
      '&:focus': {
        boxshadow: `inset 0 -6px 0 ${palette.primary.main}`,
        color: palette.common.black,
      },

      '&:hover, &:active': {
        boxShadow: 'inset 0 -6px 0 #f3c7b8',
        color: palette.common.black,
      },

      '&:focus, &:active': {
        textDecoration: 'none',
      },

      '&.selected, &.selected:hover, &.selected:focus': {
        boxBhadow: `inset 0 -6px 0 ${palette.primary.main}`,
        color: palette.common.black,
      },
    },
  })
);
