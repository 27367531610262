import { isServerSide } from 'utils/isSSR';
import { BrowserStorage } from 'services/BrowserStorage';
import {
  REFERRER_CODE_KEY,
  REFERRER_NEXT_CODE_KEY,
  REFERRER_FRIEND_CODE_KEY,
  REFERRER_EXTERNAL_CODE_QUERY_PARAM,
  REFERRER_FRIEND_CODE_QUERY_PARAM,
} from './constants';

class ReferrerService {
  static init() {
    if (isServerSide()) return;
    ReferrerService.persistReferrerFromQuery(window.location.search);
  }

  static getReferrerCode() {
    return BrowserStorage.get(REFERRER_CODE_KEY);
  }

  static setReferrerCode(code) {
    BrowserStorage.set(REFERRER_CODE_KEY, code);
  }

  static getReferrerNextCode() {
    return BrowserStorage.get(REFERRER_NEXT_CODE_KEY);
  }

  static setReferrerNextCode(code) {
    BrowserStorage.set(REFERRER_NEXT_CODE_KEY, code);
  }

  static getReferrerFriendCode() {
    return BrowserStorage.get(REFERRER_FRIEND_CODE_KEY);
  }

  static setReferrerFriendCode(code) {
    BrowserStorage.set(REFERRER_FRIEND_CODE_KEY, code);
  }

  /**
   * Saves referral code in `localStorage`.
   *
   * When it's first (external) referral then will be stored under `REFERRER_CODE_KEY`
   * otherwise under `REFERRER_NEXT_CODE_KEY`.
   *
   * @param {string} code
   * @see REFERRER_NEXT_CODE_KEY
   * @see REFERRER_CODE_KEY
   */
  static saveReferrerExternalCode(code) {
    const referrerCode = ReferrerService.getReferrerCode();
    if (referrerCode && referrerCode !== code) {
      ReferrerService.setReferrerNextCode(code);
      return;
    }

    ReferrerService.setReferrerCode(code);
  }

  /**
   * Retrieves referrer codes from query params and saves them in `BrowserStorage`.
   *
   * @param {string} query
   * @see BrowserStorage
   * @see REFERRER_CODE_KEY
   * @see REFERRER_NEXT_CODE_KEY
   * @see REFERRER_FRIEND_CODE_KEY
   */
  static persistReferrerFromQuery = query => {
    const searchParams = new URLSearchParams(query);
    const referrerFriendCode = searchParams.get(
      REFERRER_FRIEND_CODE_QUERY_PARAM
    );
    const referrerExternalCode = searchParams.get(
      REFERRER_EXTERNAL_CODE_QUERY_PARAM
    );

    if (referrerFriendCode) {
      ReferrerService.setReferrerFriendCode(referrerFriendCode);
    }
    if (referrerExternalCode) {
      ReferrerService.saveReferrerExternalCode(referrerExternalCode);
    }
  };

  static getReferrerCodes() {
    return {
      referrer: ReferrerService.getReferrerCode(),
      referrerNext: ReferrerService.getReferrerNextCode(),
      referrerFriendCode: ReferrerService.getReferrerFriendCode(),
    };
  }
}

export { ReferrerService };
