import appConfig from 'config';

import { ThreeDSecure } from './ThreeDSecure';
// TODO Find more mature way to mock things for E2E tests
import { ThreeDSecureStub } from './ThreeDSecure.stub';

const {
  paysafe: { apiKey, accountId, environment },
} = appConfig;

export const createThreeDSecure = (): ThreeDSecure =>
  process.env.testContext
    ? new ThreeDSecureStub()
    : new ThreeDSecure({
        apiKey,
        accountId,
        environment,
      });
