import { fromJS } from 'immutable';

import Config from 'config';

import { User } from 'services/Pip';

import { LOGOUT } from 'store/SessionProvider/actionTypes';

import * as type from './actionTypes';
import { MODAL_STATE_KEY } from './constants';

export const initialState = fromJS({
  isSidebarCollapsed: window
    ? window.matchMedia(
        `(max-width: ${(Config.theme.breakpoints[2] * 16 - 1) / 16}em)`
      ).matches
    : true,
  newsletterSubscribed: false,
  // TODO To be replaced with MODAL_STATE_KEY + predefined modals per use case
  modal: {
    isShown: false,
    message: null,
    type: 'info',
    interactions: [],
  },
  [MODAL_STATE_KEY]: null,
  video: {
    isShown: false,
    url: null,
  },
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case type.SIDEBAR_COLLAPSE_TOGGLE:
      return state.merge({
        isSidebarCollapsed: !state.get('isSidebarCollapsed'),
      });
    case type.NEWSLETTER_SUBSCRIBE:
      User.newsletterSubscribe(action.email);
      return state.merge({
        newsletterSubscribed: true,
      });
    case type.CLOSE_MODAL: {
      return state.merge({
        modal: fromJS({
          isShown: false,
          interactions: [],
          message: null,
        }),
      });
    }
    case type.OPEN_MODAL: {
      return state.merge({
        modal: fromJS({
          isShown: true,
          interactions: action.payload.interactions,
          type: action.payload.type,
          message: action.payload.message,
        }),
      });
    }
    case type.CLOSE_VIDEO: {
      return state.merge({
        video: fromJS({
          isShown: false,
          url: null,
        }),
      });
    }
    case type.SHOW_VIDEO: {
      return state.merge({
        video: fromJS({
          isShown: true,
          url: action.payload.url,
        }),
      });
    }
    case type.OPEN_GLOBAL_MODAL:
      return state.set(MODAL_STATE_KEY, fromJS(action.payload));
    case type.CLOSE_GLOBAL_MODAL:
      return state.set(MODAL_STATE_KEY, initialState.get(MODAL_STATE_KEY));

    default:
      return state;
  }
}

export default appReducer;
