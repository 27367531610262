import invariant from 'invariant';

import { PaysafeEnvironment, PaysafeSdk } from './types';

export interface ThreeDSecureConstructorProps {
  apiKey: string;
  accountId: string;
  environment?: PaysafeEnvironment;
}

export class ThreeDSecure {
  private paysafe!: PaysafeSdk;

  private readonly apiKey: string;

  private readonly accountId: string;

  private readonly environment?: PaysafeEnvironment;

  constructor({
    apiKey,
    accountId,
    environment,
  }: ThreeDSecureConstructorProps) {
    this.apiKey = apiKey;
    this.accountId = accountId;
    this.environment = environment;
  }

  private init = (): void => {
    if (this.paysafe) return;

    invariant(window.paysafe, 'ThreeDSecure: Paysafe SDK not loaded.');
    this.paysafe = window.paysafe;
  };

  start = (cardBin: string): Promise<string> => {
    this.init();

    invariant(cardBin, 'ThreeDSecure: cardBin is required.');
    invariant(
      cardBin.length === 6,
      'ThreeDSecure: cardBin should have 6 digits.'
    );

    return new Promise((resolve, reject) => {
      this.paysafe.threedsecure.start(
        this.apiKey,
        {
          environment: this.environment,
          accountId: this.accountId,
          card: {
            cardBin,
          },
        },
        (deviceFingerprintingId, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(deviceFingerprintingId);
          }
        }
      );
    });
  };

  challenge = (sdkChallengePayload: string): Promise<string> => {
    invariant(
      this.paysafe,
      'ThreeDSecure: Service was not initialised. Seems like you are trying to call `challenge()` method prior to `start()` which should not happen.'
    );
    invariant(
      sdkChallengePayload,
      'ThreeDSecure: sdkChallengePayload is required.'
    );

    return new Promise((resolve, reject) => {
      this.paysafe.threedsecure.challenge(
        this.apiKey,
        {
          environment: this.environment,
          sdkChallengePayload,
        },
        (authenticationId, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(authenticationId);
          }
        }
      );
    });
  };
}
