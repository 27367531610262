import React from 'react';
// TODO https://britishpearl.atlassian.net/browse/PIT-1977
// migrate from `react-google-recaptcha` to something different / custom implementation using components/Molecules/LazyScript
// this will allow us to remove `react-async-script` dependency from PIT
import Recaptcha from 'react-google-recaptcha';
import { connect } from 'react-redux';

import {
  Field,
  formValueSelector,
  reduxForm,
  startSubmit,
  SubmissionError,
} from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import { Element } from 'react-scroll';
import { pick } from 'lodash';

import {
  makeSelectIsSubmitting,
  selectIsRegistrationSuccess,
} from 'store/SessionProvider/selectors';
import Config from 'config';
import { actions as sessionActions } from 'store/SessionProvider';
import { VerificationEmailSentMessage } from 'components/Molecules';
import { ErrorMessages } from 'components/Atoms/ErrorMessages';
import { ButtonSubmit } from 'components/Atoms/Button';
import { commonPaths, loginPaths } from 'router/paths';

import { ReferrerService } from 'services/Referrer';
import { i18nConnect } from 'utils/i18nConnect';
import { normalizeEmail } from 'utils/normalize';

import {
  CheckboxFieldRedux,
  PasswordFieldRedux,
  TextFieldRedux,
} from 'components/Atoms/Form';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'components/Atoms/Link';

import { createInitialValues } from './initialValues';
import { asyncValidateRegisterForm } from './validation';

export const REGISTER_FORM_EL = 'register-form';
export const REGISTER_FORM_NAME = 'RegisterForm';

export class RegisterForm extends React.Component {
  onSubmit = (values, dispatch, { form, recaptchaEnabled }) => {
    dispatch(startSubmit(form));

    const sanitisedValues = pick(values, [
      'firstName',
      'lastName',
      'email',
      'password',
      'referrer',
      'referrerNext',
      'referrerFriendCode',
    ]);

    if (recaptchaEnabled) {
      new Promise((resolve, reject) => {
        this.promise = { resolve, reject };
      })
        .then(token => {
          if (token) {
            dispatch(sessionActions.registerSubmit(sanitisedValues, token));
            this.recaptcha.reset();
          } else {
            throw new SubmissionError({ _error: 'Bad captcha error' });
          }
        })
        .catch(err => {
          throw new SubmissionError(err || { _error: 'Bad captcha error' });
        });

      const token = this.recaptcha.getValue();
      if (token && this.promise) {
        this.promise.resolve(token);
      } else {
        this.recaptcha.execute();
      }
    } else {
      dispatch(sessionActions.registerSubmit(sanitisedValues, 'dummyToken'));
    }
  };

  renderRegisterForm() {
    const {
      handleSubmit,
      error,
      invalid,
      isSubmitting,
      submitting,
      formValues,
      i18n,
      recaptchaEnabled,
      showLoginButton,
      checkboxColor,
    } = this.props;

    return (
      <Box maxWidth="40rem" textAlign="left" mx="auto" mt={4}>
        <Element name={REGISTER_FORM_EL}>
          <form
            data-test-id="register-form"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <ErrorMessages errors={error} />
            <Field
              component={TextFieldRedux}
              label={i18n('RegisterForm')`First name`}
              name="firstName"
              autoComplete="off"
              data-test-id="user-first-name"
            />
            <Field
              component={TextFieldRedux}
              label={i18n('RegisterForm')`Last name`}
              name="lastName"
              autoComplete="off"
              data-test-id="user-last-name"
            />
            <Field
              data-test-id="user-email"
              component={TextFieldRedux}
              label={i18n('RegisterForm')`Email`}
              name="email"
              normalize={normalizeEmail}
              type="email"
              autoComplete="off"
            />
            <Field
              data-test-id="user-password"
              component={PasswordFieldRedux}
              formValues={formValues}
              showStrengthChecker
              label={i18n('RegisterForm')`Create password`}
              name="password"
              autoComplete="new-password"
            />

            <Field
              component="input"
              name="referrer"
              type="hidden"
              autoComplete="off"
            />
            <Field
              component="input"
              name="referrerNext"
              type="hidden"
              autoComplete="off"
            />
            <Field
              component="input"
              name="referrerFriendCode"
              type="hidden"
              autoComplete="off"
            />
            <Box mt={2} mb={1}>
              <Field
                name="isTermsAccepted"
                margin="none"
                fullWidth
                label={
                  <span>
                    {i18n('RegisterForm')`I agree to the `}
                    <Link to={commonPaths.termsOfUse()}>
                      {i18n('RegisterForm')`Terms of use `}
                    </Link>
                  </span>
                }
                component={CheckboxFieldRedux}
                color={checkboxColor}
                data-test-id="agree-to-terms"
              />
              <Field
                name="isPrivacyAccepted"
                margin="none"
                fullWidth
                label={
                  <span>
                    {i18n('RegisterForm')`I agree to the `}
                    <Link to={commonPaths.privacyNotice()}>
                      {i18n('RegisterForm')`Privacy notice `}
                    </Link>
                  </span>
                }
                component={CheckboxFieldRedux}
                color={checkboxColor}
                data-test-id="agree-to-privacy"
              />
            </Box>
            <Box mt={4} textAlign="center">
              <ButtonSubmit
                disabled={invalid}
                // TODO: https://britishpearl.atlassian.net/browse/PIT-1887
                loading={isSubmitting || submitting}
                data-test-id="register-button"
              >
                {i18n('RegisterForm')`Register account`}
              </ButtonSubmit>
            </Box>
            {showLoginButton && (
              <Box mt={2} textAlign="center">
                <Typography variant="body1">
                  {i18n('RegisterForm')`Already have an account? `}
                  <Link to={loginPaths.login()}>{i18n(
                    'RegisterForm'
                  )`Log in`}</Link>
                </Typography>
              </Box>
            )}

            <Box maxWidth="26rem" textAlign="center" mx="auto" mt={4}>
              {i18n(
                'RegisterForm'
              )`You can manage your subscription settings via your profile page when your account is open.`}
            </Box>

            {recaptchaEnabled && (
              <Recaptcha
                onChange={token => {
                  if (!token) {
                    this.recaptcha.reset();
                  } else if (this.promise) {
                    this.promise.resolve(token);
                  } else {
                    throw new SubmissionError({ _error: 'Bad captcha error' });
                  }
                }}
                ref={ref => (this.recaptcha = ref)} // eslint-disable-line no-return-assign
                sitekey={Config.keys.google.recaptcha}
                size="invisible"
              />
            )}
          </form>
        </Element>
      </Box>
    );
  }

  renderRegistrationSuccessfulForm() {
    const {
      formValues: { email },
    } = this.props;
    return (
      <Box maxWidth="40rem" width="100%" textAlign="left" mx="auto" mt={4}>
        <VerificationEmailSentMessage
          email={email}
          scrollToOnMount={REGISTER_FORM_EL}
        />
      </Box>
    );
  }

  render() {
    const { isRegisterSuccessful } = this.props;

    return (
      <Element name={REGISTER_FORM_EL}>
        {isRegisterSuccessful
          ? this.renderRegistrationSuccessfulForm()
          : this.renderRegisterForm()}
      </Element>
    );
  }
}

RegisterForm.defaultProps = {
  isRegisterSuccessful: false,
  pristine: true,
  referrer: '',
  referrerNext: '',
  referrerFriendCode: '',
  submitting: false,
  transparent: true,
  recaptchaEnabled: true,
  showLoginButton: false,
  checkboxColor: 'white',
  destroyOnUnmount: true,
};

const formValues = state =>
  formValueSelector(REGISTER_FORM_NAME)(
    state,
    'firstName',
    'lastName',
    'email'
  );

const mapStateToProps = state =>
  createStructuredSelector({
    formValues,
    isSubmitting: makeSelectIsSubmitting(),
    isRegisterSuccessful: selectIsRegistrationSuccess,
    initialValues: () =>
      createInitialValues(ReferrerService.getReferrerCodes()),
  })(state);

export default connect(mapStateToProps)(
  i18nConnect(
    reduxForm({
      form: REGISTER_FORM_NAME,
      asyncValidate: asyncValidateRegisterForm,
    })(RegisterForm)
  )
);
