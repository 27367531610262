import { Pip } from 'services/Pip';
import { InvestmentWithProperty } from 'services/Pip/types';

import { InvestmentSummary } from './types';
import { getLatestInvestmentValuation } from './utils';

export const toInvestmentSummary = (
  investment: InvestmentWithProperty
): InvestmentSummary => {
  const {
    propertyDetailedView,
    investmentDetailsView: details,
    state,
  } = investment;
  const comingSoon = !propertyDetailedView;
  const loansAndSharesUnavailable =
    state === Pip.InvestmentState.Closing ||
    state === Pip.InvestmentState.Closed;
  const latestValuation = getLatestInvestmentValuation(investment);

  return {
    id: comingSoon ? undefined : investment.id,
    ltv: latestValuation ? latestValuation.loanToValue : undefined,
    title: investment.displayName,
    subtitle: investment.subDisplayName,
    expectedExitDate: investment.expectedExitDate,
    imageSrc: propertyDetailedView.cover
      ? propertyDetailedView.cover.sourcesBySize['768x512']
      : undefined,
    sharesValue: details.estimatedShareDividendRate,
    loansValue: details.loanInterestRate,
    comingSoon,
    availableShares: loansAndSharesUnavailable
      ? 0
      : details.initialShareQuantity - details.fundedShareQuantity,
    availableLoans: loansAndSharesUnavailable
      ? 0
      : details.initialLoanQuantity - details.fundedLoanQuantity,
    state,
    suspended: investment.suspended,
    upForSale: investment.upForSale,
  };
};
