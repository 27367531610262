const PERCENT = 100;

const calculateIsa = ({ investmentAmount, monthsUntilExit, rate: ratePA }) => {
  const firstMonthInterest = (investmentAmount * ratePA) / PERCENT / 12;
  const totalInterestReturn =
    investmentAmount *
      (1 + (ratePA / PERCENT / 24) * 3) *
      (1 + ratePA / PERCENT / 24) ** (monthsUntilExit * 2 - 3) -
    investmentAmount;
  const averageAnnualInterest = (totalInterestReturn / monthsUntilExit) * 12;

  return {
    firstMonthInterest: Math.floor(firstMonthInterest),
    averageAnnualInterest: Math.round(averageAnnualInterest),
    totalInterestReturn: Math.round(totalInterestReturn),
    investmentPlusReturn: investmentAmount + Math.round(totalInterestReturn),
  };
};

export default calculateIsa;
