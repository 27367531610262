import { makeStyles } from '@material-ui/core/styles';
import { APP_BAR_DESKTOP_HEIGHT, APP_BAR_HEIGHT } from './constants';

export const useStyles = makeStyles(({ breakpoints }) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: `${APP_BAR_HEIGHT}px`,
    [breakpoints.up('lg')]: {
      minHeight: `${APP_BAR_DESKTOP_HEIGHT}px`,
    },
  },
}));
