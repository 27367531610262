import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import clsx from 'clsx';

import { Link } from 'components/Atoms/Link';

const Wrapper = styled.nav`
  display: flex;
`;

const ItemList = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  height: 2.75rem;
  justify-content: space-around;
  margin: 0;
  overflow: hidden;
  padding: 0.75rem 1rem 1rem;

  &.showHidden .hide {
    display: block;
  }
`;

const Item = styled.li`
  display: block;

  &.hide {
    display: none;
  }
`;

const StyledLink = styled(props => <Link {...props} />)`
  padding: 0.75rem 0.5rem;
`;

export class Pager extends React.Component {
  state = {
    pagesShown: 0,
  };

  componentDidMount() {
    if (window) {
      window.addEventListener('resize', this.resize.bind(this));
    }
  }

  resize() {
    if (this.element && this.element.children && this.element.children[0]) {
      const { className } = this.element;
      this.element.className = `${className} showHidden`;
      const firstRect = this.element.children[0].getBoundingClientRect();
      const pagesShown = Array.from(this.element.children).reduce(
        (firstWrapped, node, i) =>
          firstWrapped ||
          (node.getBoundingClientRect().top > firstRect.top ? i - 1 : 0),
        0
      );
      this.element.className = className;

      const { pagesShown: pagesWereShown } = this.state;

      if (pagesShown !== pagesWereShown) {
        this.setState({ pagesShown });
      }
    }
  }

  render() {
    const { children, totalPages, match, location } = this.props;
    const { pagesShown } = this.state;

    if (totalPages <= 1) {
      return children({ page: 0 });
    }

    return (
      <Route
        path={`${match.path}/:page?`}
        render={({ match: m }) => {
          if (!m.params.page) return <Redirect to={`${match.path}/1`} />;

          const page = m.params.page ? m.params.page - 1 : 0;
          const oneIndexedPage = page + 1;

          const previous = oneIndexedPage > 1 ? oneIndexedPage - 1 : 1;
          const next =
            oneIndexedPage < totalPages ? oneIndexedPage + 1 : totalPages;

          // Show subset of page links if they don't all fit
          const showPages = pagesShown
            ? Array.from(
                { length: pagesShown },
                (x, i) =>
                  i +
                  Math.max(
                    0,
                    Math.min(
                      page - Math.floor(pagesShown / 2),
                      totalPages - pagesShown
                    )
                  )
              )
            : Array.from({ length: totalPages }, (x, i) => i);

          const linkBase = `${match.url}${
            match.url.slice(-1) === '/' ? '' : '/'
          }`;

          return (
            <>
              {children({ page })}

              <Wrapper>
                <StyledLink
                  underline="hover"
                  to={{
                    pathname: `${linkBase}${
                      m.params.page ? `${previous}` : ''
                    }`,
                    state: {
                      fromTab: true,
                    },
                  }}
                >
                  Previous
                </StyledLink>

                <ItemList
                  innerRef={el => {
                    this.element = el;
                    this.resize();
                  }}
                >
                  {Array.from({ length: totalPages }).map((x, i) => {
                    const linkPathname = `${linkBase}${i + 1}`;
                    return (
                      <Item
                        className={clsx({
                          hide: showPages.indexOf(i) === -1,
                        })}
                        key={i} // eslint-disable-line react/no-array-index-key
                      >
                        <StyledLink
                          underline={
                            location.pathname === linkPathname
                              ? 'always'
                              : 'hover'
                          }
                          to={{
                            pathname: linkPathname,
                            state: {
                              fromTab: true,
                            },
                          }}
                        >
                          {i + 1}
                        </StyledLink>
                      </Item>
                    );
                  })}
                </ItemList>

                <StyledLink
                  underline="hover"
                  to={{
                    pathname: `${linkBase}${next}`,
                    state: {
                      fromTab: true,
                    },
                  }}
                >
                  Next
                </StyledLink>
              </Wrapper>
            </>
          );
        }}
      />
    );
  }
}

export default withRouter(Pager);
