import React from 'react';
import i18n from 'es2015-i18n-tag';

import Img from 'assets/images/MarketingHeroes/cashback/HeroLoans.jpg';

import { Hero } from 'components/Organisms/NewHero';

import { getCurrent } from 'utils/responsive';
import { setConfigByMedia } from 'components/Pages/MarketingLanding/pages/common/utils';
import { CapitalAtRisk } from 'components/Molecules/CapitalAtRisk';
import { CalculatorSection } from 'components/Molecules/CalculatorSection';
import { FaqSection } from 'components/Organisms/FaqSection';
import FeaturedInvestments from 'components/Pages/Home/components/FeaturedInvestments';
import {
  accreditationItem,
  confirmEmailItem,
  fundAndInvestItem,
  KeyItemsSection,
  verifyIdItem,
} from 'components/Molecules/KeyItemsSection';
import { CheckItemsSection } from 'components/Molecules/CheckItemsSection';
import {
  getPropertiesByVariant,
  InvestInAPropertyTableSection,
  propertiesData,
} from 'components/Molecules/InvestInAPropertyTableSection';
import { commonPaths } from 'router/paths';

import { useLoansFaqData } from '../common/content/useLoansFaqData';

import { HowToInvestBanner } from '../../components/HowToInvestBanner';

import HeroContent from './content/HeroContent';
import { loanInvestmentCheckItems } from './data/loanInvestmentCheckItems';

export const templateProps = {
  description: i18n('LoansNoIncentivePage')`Invest in Loans`,
  title: i18n('LoansNoIncentivePage')`Invest in Loans`,
  navLinks: [
    {
      to: commonPaths.landingIsaop(),
      label: i18n('OpenIsaNoIncentivePage')`Innovative Finance ISA`,
    },
    {
      to: commonPaths.landingShares(),
      label: i18n('OpenIsaNoIncentivePage')`Invest in shares`,
    },
    {
      to: commonPaths.landingLoans(),
      label: i18n('OpenIsaNoIncentivePage')`Invest in loans`,
    },
  ],
};

export default () => [
  {
    componentType: Hero,
    props: {
      imageOffsets: {
        small: '58%',
        medium: '82%',
        large: '105%',
      },
      image: Img,
      imageConfig: setConfigByMedia(getCurrent(), false),
      currentBreakpoint: getCurrent(),
      content: <HeroContent />,
    },
  },
  {
    componentType: CapitalAtRisk,
    props: {
      showForecastText: false,
      color: 'white',
    },
  },
  {
    componentType: HowToInvestBanner,
  },
  {
    componentType: CapitalAtRisk,
  },
  {
    componentType: CalculatorSection,
    props: {
      properties: getPropertiesByVariant(propertiesData, 'loans'),
      variant: 'loans',
    },
  },
  {
    componentType: CheckItemsSection,
    props: {
      heading: i18n('IsaLandingPage')`What are British Pearl loan investments?`,
      description: i18n(
        'IsaLandingPage'
      )`Our loan investments offer you a way to act like a bank and provide a mortgage that is secured against a specific property. You are eligible to receive fixed interest, usually paid monthly, and priority over share investors for principal repayment at the end of the term.`,
      items: loanInvestmentCheckItems,
      variant: 'even',
      size: 'small',
      sectionProps: {
        color: 'grey',
      },
    },
  },
  {
    componentType: CapitalAtRisk,
    props: {
      showForecastText: false,
    },
  },
  {
    componentType: FeaturedInvestments,
    props: {},
  },
  {
    componentType: InvestInAPropertyTableSection,
    props: {
      properties: getPropertiesByVariant(propertiesData, 'loans'),
      variant: 'loans',
    },
  },
  {
    componentType: KeyItemsSection,
    props: {
      sectionProps: {
        color: 'grey',
        // for react scroll
        name: 'instructions',
      },
      items: [
        confirmEmailItem,
        accreditationItem,
        verifyIdItem,
        fundAndInvestItem,
      ],
      heading: i18n('LoansPage')`Follow these easy steps to invest`,
    },
  },
  {
    componentType: FaqSection,
    props: {
      faqList: useLoansFaqData(),
    },
  },
];
