import React from 'react';
import i18n from 'es2015-i18n-tag';

import { Text } from 'components/Atoms/Text';

export const questions = [
  {
    id: 1,
    question: i18n('SharesPage')`What is a share investment?`,
    answer: (
      <Text>
        {i18n(
          'SharesPage'
        )`Share Investors buy shares in a property, entitling them to a share of net rental income and exposure to property price changes when the property is sold.`}
      </Text>
    ),
  },
  {
    id: 2,
    question: i18n('SharesPage')`What is a loan investment?`,
    answer: (
      <Text>
        {i18n(
          'SharesPage'
        )`Loan Investors provide a portion of a secured loan against a property. Interest accrues daily and is usually paid monthly during the term of the investment. Investors receive their capital back once the loan matures and/or the property is sold.`}
      </Text>
    ),
  },
  {
    id: 3,
    question: i18n('SharesPage')`How does it work?`,
    answer: (
      <>
        <Text paragraph>
          {i18n(
            'SharesPage'
          )`British Pearl enables people to invest in buy to let and development projects, as either shareholders, lenders, or both.`}
        </Text>
        <Text>
          {i18n(
            'SharesPage'
          )`We source and manage all our properties, taking the pain out of property investing and management. Our robust, easy-to-use platform gives you access to an exciting market, plus flexibility and control over your investment.`}
        </Text>
      </>
    ),
  },
  {
    id: 4,
    question: i18n('SharesPage')`What will I earn?`,
    answer: (
      <Text>
        {i18n(
          'SharesPage'
        )`We can’t guarantee what your earnings will be. Each of our properties has a unique share and loan return, based on a number of factors including loan-to-value. For our latest returns, check out our site. `}
      </Text>
    ),
  },
  {
    id: 5,
    question: i18n('SharesPage')`Can I access my money early?`,
    answer: (
      <Text>
        {i18n(
          'SharesPage'
        )`Yes. Just because it’s a property investment doesn’t mean it has to be illiquid. If you need to access your money before you investment matures, you can sell your loans or shares on to other investors in the Resale Market. This is subject to demand and fees.`}
      </Text>
    ),
  },
];
