import React from 'react';

import Config from 'config';

const NewVerifyID = ({ fill, height, width, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 34 38" width={width} {...props}>
    <path
      d="M31.5451 4.48832C29.8263 2.69299 18.3002 0 16.4803 0C14.6267 0 3.10059 2.69299 1.41549 4.48832C0.471829 5.45248 0 11.4701 0 14.1631H32.9269C32.9269 11.4701 32.4551 5.45248 31.5451 4.48832Z"
      fill={fill}
      transform="translate(0.462921 23.3798)"
    />
    <path
      d="M1.21327 14.8946C2.96578 18.9839 6.30229 21.2115 8.52662 21.2115C10.7173 21.2115 13.7841 19.0504 15.5367 14.9943C15.9748 13.9969 16.3455 12.7668 16.6151 11.5366C16.9184 10.5392 17.0532 9.3091 17.0532 7.71326C17.0532 3.55741 13.2112 0 8.52662 0C3.80833 0 0 3.55741 0 7.7465C0 8.84365 0.0674043 9.80781 0.202213 10.6057C0.370723 12.0686 0.707744 13.6977 1.21327 14.8946Z"
      fill={fill}
      transform="translate(8.41586 0.971436)"
    />
  </svg>
);

NewVerifyID.defaultProps = {
  fill: Config.theme.colors.white,
  height: 38,
  width: 34,
};

export default NewVerifyID;
