import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    backgroundColor: '#4ac885',
    '&:hover': {
      backgroundColor: '#2c925c',
    },
  },
  link: {
    color: '#4ac885',
  },
}));
