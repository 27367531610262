import React, { ReactElement, useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { omit } from 'lodash';
import { WrappedFieldProps } from 'redux-form';

import { TextField, TextFieldProps } from './TextField';

type AutoCompleteFieldReduxProps<Option> = WrappedFieldProps &
  Omit<
    AutocompleteProps<Option>,
    'renderInput' | 'onChange' | 'value' | 'disableClearable'
  > &
  Partial<TextFieldProps>;

export function AutoCompleteFieldRedux<Option>({
  meta: { submitting, touched, invalid, error },
  input: { onBlur, value, onChange },
  options,
  label,
  disabled,
  required,
  ...rest
}: AutoCompleteFieldReduxProps<Option>): ReactElement {
  const handleChange = useCallback((e, v) => onChange(v), [onChange]);
  return (
    <Autocomplete
      disabled={submitting || disabled}
      // TODO looks like event types are invalid
      // @ts-ignore
      onChange={handleChange}
      disableClearable
      options={options}
      value={value}
      renderInput={({ InputProps, ...props }) => (
        <TextField
          required={required}
          label={label}
          InputProps={{
            // TODO: currently this is breaking styles
            ...omit(InputProps, 'className'),
          }}
          error={touched && invalid}
          helperText={touched && error}
          onBlur={onBlur}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}
