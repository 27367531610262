import * as actionType from './actionTypes';

export const sidebarCollapseToggle = () => ({
  type: actionType.SIDEBAR_COLLAPSE_TOGGLE,
});

export const newsletterSubscribe = email => ({
  type: actionType.NEWSLETTER_SUBSCRIBE,
  email,
});

// TODO To be replaced with new approach (see comment in ./reducer.js)
export const closeModal = () => ({
  type: actionType.CLOSE_MODAL,
});

// TODO To be replaced with new approach (see comment in ./reducer.js)
export const openModal = ({ interactions, message, type }) => ({
  type: actionType.OPEN_MODAL,
  payload: {
    message,
    type,
    interactions,
  },
});

export const openGlobalModal = name => ({
  type: actionType.OPEN_GLOBAL_MODAL,
  payload: name,
});

export const closeGlobalModal = () => ({
  type: actionType.CLOSE_GLOBAL_MODAL,
});

export const showVideo = url => ({
  type: actionType.SHOW_VIDEO,
  payload: {
    url,
  },
});

export const closeVideo = () => ({
  type: actionType.CLOSE_VIDEO,
});
