import React, { FC, SVGProps } from 'react';

const Rosette: FC<SVGProps<SVGSVGElement>> = props => (
  <svg viewBox="0 0 64 64" {...props}>
    <g fill="none">
      <path
        d="M31.93 58.0175l-5.825 5.8348V44.5175h-.8882V66l6.7133-6.7244L38.644 66V44.5175h-.8882v19.3348"
        fill="currentColor"
      />
      <path
        d="M42.06 22.9987c0-5.54-4.5348-10.0313-10.1297-10.0313-5.5957 0-10.1297 4.49-10.1297 10.0313 0 5.5414 4.534 10.0313 10.1297 10.0313S42.06 28.54 42.06 22.9987zm-.7666-17.2752c3.676-.4782 6.977 2.2644 7.141 5.9325.078 1.7487 1.0118 3.3513 2.5007 4.2936 3.127 1.9734 3.876 6.1756 1.62 9.0904-1.075 1.3897-1.399 3.2105-.867 4.8802 1.112 3.503-1.043 7.1977-4.662 7.9938-1.726.3813-3.156 1.57-3.834 3.187-1.421 3.391-5.469 4.8495-8.76 3.1558-1.567-.8068-3.433-.8068-5.002 0-3.29 1.6937-7.338.235-8.76-3.1557-.678-1.617-2.108-2.805-3.833-3.187-3.619-.796-5.774-4.49-4.662-7.994.531-1.669.2075-3.49-.8674-4.879-2.2557-2.915-1.5077-7.117 1.619-9.09 1.49-.942 2.423-2.543 2.501-4.294.164-3.668 3.465-6.41 7.1406-5.932 1.7524.227 3.5073-.406 4.7034-1.695 2.5072-2.704 6.812-2.704 9.32 0 1.196 1.289 2.951 1.922 4.703 1.695zM35.928 4.6307c-2.1515-2.3202-5.8444-2.3202-7.996 0-1.394 1.5024-3.4393 2.2396-5.4824 1.9757-3.1533-.41-5.9848 1.9424-6.1255 5.089-.091 2.0398-1.178 3.907-2.915 5.0052-2.682 1.6927-3.324 5.2974-1.389 7.798 1.253 1.62 1.631 3.7427 1.012 5.6885-.953 3.006.895 6.175 4 6.858 2.012.445 3.679 1.83 4.469 3.715 1.22 2.909 4.692 4.16 7.515 2.707 1.829-.94 4.005-.94 5.832 0 2.823 1.453 6.2953.202 7.515-2.707.79-1.885 2.457-3.27 4.469-3.715 3.1046-.683 4.953-3.852 4-6.857-.6197-1.946-.242-4.069 1.011-5.689 1.935-2.5 1.293-6.105-1.3894-7.798-1.736-1.098-2.8243-2.966-2.9154-5.005-.1405-3.146-2.972-5.499-6.126-5.089-2.0424.264-4.088-.473-5.482-1.976zm7.0312 18.368c0 6.0332-4.9366 10.9218-11.029 10.9218-6.0922 0-11.0288-4.8886-11.0288-10.9218 0-6.0332 4.9366-10.9218 11.029-10.9218 6.0915 0 11.0288 4.89 11.0288 10.921z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Rosette;
