import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, typography, palette }) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      background: 'none',
      fontSize: 'inherit',
      lineHeight: 2,
      padding: spacing(4, 2),
      cursor: 'pointer',
      fontWeight: typography.fontWeightBold,
      opacity: 0.9,
      whiteSpace: 'nowrap',
      color: palette.common.black,
      '&:focus': {
        boxShadow: `inset 0 -6px 0 ${palette.primary.main}`,
        opacity: 1,
      },

      '&:hover, &:active': {
        boxShadow: `inset 0 -6px 0 ${palette.common.black}`,
        opacity: 1,
      },

      '&:focus, &:active': {
        textDecoration: 'none',
      },

      '&.active, &.active:hover, &.active:focus': {
        boxShadow: `inset 0 -6px 0 ${palette.primary.main}`,
        opacity: 1,
      },
    },
  })
);
