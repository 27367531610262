import React from 'react';
import styled from 'styled-components';

import Config from 'config';
import { i18nConnect } from 'utils/i18nConnect';

import media from 'utils/responsive';

import Details from './Details';
import Investments from './Investments';

const Wrapper = styled.div`
  border-top: 1px solid ${Config.theme.colors.pearl};
  padding: 1rem 0 0;
  position: relative;
`;

const InvestmentWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.wide`
    flex-direction: row;
  `};
`;

const Items = ({ i18n, item, label, accountId }) => (
  <Wrapper
    data-test-investment-property="investment-property"
    data-test-investment-id={item.get('investmentId')}
  >
    <Details item={item} />

    <InvestmentWrapper>
      {(label === 'both' || label === 'shares') && (
        <Investments
          accountId={accountId}
          type={i18n('InvestmentDetails')`Shares`}
          item={item}
          label={label}
        />
      )}

      {(label === 'both' || label === 'loans') && (
        <Investments
          accountId={accountId}
          type={i18n('InvestmentDetails')`Loans`}
          item={item}
          label={label}
        />
      )}
    </InvestmentWrapper>
  </Wrapper>
);

export default i18nConnect(Items);
