import React from 'react';
import i18n from 'es2015-i18n-tag';

import Img from 'assets/images/MarketingHeroes/cashback/HeroShares.jpg';
import { Hero } from 'components/Organisms/NewHero';
import { getCurrent } from 'utils/responsive';
import { setConfigByMedia } from 'components/Pages/MarketingLanding/pages/common/utils';
import { CapitalAtRisk } from 'components/Molecules/CapitalAtRisk';
import { CalculatorSection } from 'components/Molecules/CalculatorSection';
import { FaqSection } from 'components/Organisms/FaqSection';
import FeaturedInvestments from 'components/Pages/Home/components/FeaturedInvestments';
import { commonPaths } from 'router/paths';
import {
  accreditationItem,
  confirmEmailItem,
  fundAndInvestItem,
  KeyItemsSection,
  verifyIdItem,
} from 'components/Molecules/KeyItemsSection';
import { CheckItemsSection } from 'components/Molecules/CheckItemsSection';
import {
  getPropertiesByVariant,
  InvestInAPropertyTableSection,
  propertiesData,
} from 'components/Molecules/InvestInAPropertyTableSection';

import HeroContent from './content/HeroContent';
import { shareInvestmentCheckItems } from './data/shareInvestmentCheckItems';

import { questions as faqList } from '../common/content/faqs';
import { HowToInvestBanner } from '../../components/HowToInvestBanner';

export const templateProps = {
  description: i18n('SharesNoIncentivePage')`Invest in Shares`,
  title: i18n('SharesNoIncentivePage')`Invest in Shares`,
  navLinks: [
    {
      to: commonPaths.landingIsaop(),
      label: i18n('OpenIsaNoIncentivePage')`Innovative Finance ISA`,
    },
    {
      to: commonPaths.landingShares(),
      label: i18n('OpenIsaNoIncentivePage')`Invest in shares`,
    },
    {
      to: commonPaths.landingLoans(),
      label: i18n('OpenIsaNoIncentivePage')`Invest in loans`,
    },
  ],
};

export default [
  {
    componentType: Hero,
    props: {
      image: Img,
      imageConfig: setConfigByMedia(getCurrent(), false),
      currentBreakpoint: getCurrent(),
      content: <HeroContent />,
    },
  },
  {
    componentType: CapitalAtRisk,
    props: {
      showTaxText: false,
      color: 'white',
    },
  },
  {
    componentType: HowToInvestBanner,
  },
  {
    componentType: CapitalAtRisk,
  },
  {
    componentType: CalculatorSection,
    props: {
      properties: getPropertiesByVariant(propertiesData, 'shares'),
      variant: 'shares',
    },
  },
  {
    componentType: CheckItemsSection,
    props: {
      heading: i18n('SharesPage')`What are British Pearl share investments?`,
      description: i18n(
        'SharesPage'
      )`Our share investments offer you a way to buy into a property, without buying the whole property. You are entitled to dividends made up of net rental income, and a share of any property appreciation when it’s sold.`,
      items: shareInvestmentCheckItems,
      variant: 'even',
      size: 'small',
      sectionProps: {
        color: 'grey',
      },
    },
  },
  {
    componentType: CapitalAtRisk,
    props: {
      showTaxText: false,
    },
  },
  {
    componentType: FeaturedInvestments,
    props: {},
  },
  {
    componentType: InvestInAPropertyTableSection,
    props: {
      properties: getPropertiesByVariant(propertiesData, 'shares'),
      variant: 'shares',
    },
  },
  {
    componentType: KeyItemsSection,
    props: {
      sectionProps: {
        color: 'grey',
        // for react scroll
        name: 'instructions',
      },
      items: [
        confirmEmailItem,
        accreditationItem,
        verifyIdItem,
        fundAndInvestItem,
      ],
      heading: i18n('SharesPage')`Follow these easy steps to invest`,
    },
  },
  {
    componentType: FaqSection,
    props: {
      faqList,
    },
  },
];
