import React from 'react';
import i18n from 'es2015-i18n-tag';

import Img from 'assets/images/MarketingHeroes/cashback/HeroIsa.jpg';
import { Hero } from 'components/Organisms/NewHero';
import { CalculatorSection } from 'components/Molecules/CalculatorSection';
import { CapitalAtRisk } from 'components/Molecules/CapitalAtRisk';
import { FaqSection } from 'components/Organisms/FaqSection';
import FeaturedInvestments from 'components/Pages/Home/components/FeaturedInvestments';
import { getCurrent } from 'utils/responsive';
import { setConfigByMedia } from 'components/Pages/MarketingLanding/pages/common/utils';
import {
  accreditationItem,
  confirmEmailItem,
  fundIsaWalletItem,
  KeyItemsSection,
  verifyIdItem,
} from 'components/Molecules/KeyItemsSection';
import { CheckItemsSection } from 'components/Molecules/CheckItemsSection';
import {
  getPropertiesByVariant,
  InvestInAPropertyTableSection,
  propertiesData,
} from 'components/Molecules/InvestInAPropertyTableSection';
import { commonPaths } from 'router/paths';

import { questions as faqList } from '../../common/content/faqs';
import { HowToInvestBanner } from '../../../components/HowToInvestBanner';

import HeroContent from './content/HeroContent';
import { isaCheckItems } from './data/isaCheckItems';

export const templateProps = {
  description: i18n('OpenIsaNoIncentivePage')`Open ISA`,
  title: i18n('OpenIsaNoIncentivePage')`Open ISA`,
  navLinks: [
    {
      to: commonPaths.landingIsaop(),
      label: i18n('OpenIsaNoIncentivePage')`Innovative Finance ISA`,
    },
    {
      to: commonPaths.landingShares(),
      label: i18n('OpenIsaNoIncentivePage')`Invest in shares`,
    },
    {
      to: commonPaths.landingLoans(),
      label: i18n('OpenIsaNoIncentivePage')`Invest in loans`,
    },
  ],
};

export default [
  {
    componentType: Hero,
    props: {
      imageOffsets: {
        small: '58%',
        medium: '82%',
        large: '105%',
      },
      image: Img,
      imageConfig: setConfigByMedia(getCurrent(), false),
      currentBreakpoint: getCurrent(),
      content: <HeroContent />,
    },
  },
  {
    componentType: CapitalAtRisk,
    props: {
      color: 'white',
      showForecastText: false,
    },
  },
  {
    componentType: HowToInvestBanner,
  },
  {
    componentType: CapitalAtRisk,
  },
  {
    componentType: CalculatorSection,
    props: {
      properties: getPropertiesByVariant(propertiesData, 'loans'),
      variant: 'isaLoans',
    },
  },
  {
    componentType: CheckItemsSection,
    props: {
      heading: i18n(
        'IsaLandingPage'
      )`Benefits of choosing a British Pearl IFISA`,
      description: i18n(
        'IsaLandingPage'
      )`Earn tax-free interest on our property-backed loan investments with our IFISA. Consistent, monthly, and tax-free interest from rental income, secured by a first charge.`,
      items: isaCheckItems,
      variant: 'even',
      size: 'small',
      sectionProps: {
        color: 'grey',
      },
    },
  },
  {
    componentType: CapitalAtRisk,
    props: {
      showForecastText: false,
    },
  },
  {
    componentType: FeaturedInvestments,
    props: {},
  },
  {
    componentType: InvestInAPropertyTableSection,
    props: {
      properties: getPropertiesByVariant(propertiesData, 'loans'),
      variant: 'loans',
    },
  },
  {
    componentType: KeyItemsSection,
    props: {
      sectionProps: {
        color: 'grey',
        // for react scroll
        name: 'instructions',
      },
      items: [
        confirmEmailItem,
        accreditationItem,
        verifyIdItem,
        fundIsaWalletItem,
      ],
      heading: i18n('OpenIsaPage')`Follow these easy steps to invest`,
    },
  },
  {
    componentType: FaqSection,
    props: {
      faqList,
    },
  },
];
