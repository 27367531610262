import React, { FC } from 'react';
import { List, ListItem, Box } from '@material-ui/core';

import { Link } from 'components/Atoms/Link';

import { MenuNavLinkItem } from '../../types';

import { useStyles } from './MobileNavigationList.styles';

interface MobileNavigationListProps {
  navLinks: MenuNavLinkItem[];
}

export const MobileNavigationList: FC<MobileNavigationListProps> = ({
  navLinks,
}) => {
  const classes = useStyles();

  return (
    <List component="nav">
      {navLinks.map(firstTierLink => {
        return Array.isArray(firstTierLink.to) ? (
          <List
            disablePadding
            subheader={
              <ListItem component="div" className={classes.listItem}>
                {firstTierLink.label}
              </ListItem>
            }
          >
            {firstTierLink.to.map(({ to, label }) => (
              <ListItem
                button
                key={to}
                component={Link}
                to={to}
                className={classes.listItemIndent}
              >
                <Box component="span" color="primary.main" pr={2}>
                  •
                </Box>{' '}
                {label}
              </ListItem>
            ))}
          </List>
        ) : (
          <ListItem
            button
            key={firstTierLink.to}
            component={Link}
            to={firstTierLink.to}
            className={classes.listItem}
          >
            {firstTierLink.label}
          </ListItem>
        );
      })}
    </List>
  );
};
