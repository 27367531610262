import { fromJS } from 'immutable';

import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';

import { logger } from 'services/Logger';
import { Account } from 'services/Pip';
import { sagaPromise } from 'utils/saga';

import {
  investorWithdrawalCancelError,
  investorWithdrawalCancelSuccess,
  investorWithdrawalsFindError,
  investorWithdrawalsFindRequest,
  investorWithdrawalsFindSuccess,
} from './actions';
import * as type from './actionTypes';

export function* getInvestorWithdrawalsFind(accountId) {
  try {
    return yield call([Account, Account.findWithdrawals], accountId);
  } catch (error) {
    yield call(logger.error, error);
    yield put(investorWithdrawalsFindError(error));
  }

  return false;
}

const getInvestorWithdrawalsFindFlow = sagaPromise(function*({ accountId }) {
  if (Number.isNaN(accountId)) {
    return false;
  }

  try {
    const { withdrawals } = yield race({
      withdrawals: call(getInvestorWithdrawalsFind, accountId),
      error: take(type.INVESTOR_WITHDRAWALS_FIND_ERROR),
    });

    if (withdrawals) {
      yield put(investorWithdrawalsFindSuccess(fromJS(withdrawals), accountId));
      return true;
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
});

export function* getInvestorWithdrawalsFindWatcher() {
  yield takeLatest(
    [type.INVESTOR_WITHDRAWALS_FIND_REQUEST],
    getInvestorWithdrawalsFindFlow
  );
}

export function* cancelInvestorWithdrawal(accountId, uuid) {
  try {
    return yield call([Account, Account.cancelWithdrawal], accountId, uuid);
  } catch (error) {
    yield call(logger.error, error);
    yield put(investorWithdrawalCancelError(error));
  }

  return false;
}

const cancelInvestorWithdrawalFlow = sagaPromise(function*({
  accountId,
  uuid,
}) {
  try {
    const winner = yield race({
      success: call(cancelInvestorWithdrawal, accountId, uuid),
      error: take(type.INVESTOR_WITHDRAWALS_CANCEL_ERROR),
    });

    if (winner.success) {
      yield put(investorWithdrawalCancelSuccess(uuid));
      yield put(investorWithdrawalsFindRequest(accountId));
      return true;
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
});

export function* cancelInvestorWithdrawalWatcher() {
  yield takeLatest(
    [type.INVESTOR_WITHDRAWALS_CANCEL_REQUEST],
    cancelInvestorWithdrawalFlow
  );
}

export function* investorWithdrawalsSaga() {
  try {
    yield all([
      getInvestorWithdrawalsFindWatcher(),
      cancelInvestorWithdrawalWatcher(),
    ]);
  } catch (e) {
    yield call(logger.error, e);
  }
}
