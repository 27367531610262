import _i18n, { i18nConfig as _i18nConfig } from 'es2015-i18n-tag';
import React from 'react';

import { DEFAULT_LOCALE } from 'store/App/constants';
import enTranslationMessages from 'translations/en-GB/common.json';
import enTranslationErrors from 'translations/en-GB/errors.json';
import enTranslationVariables from 'translations/en-GB/variables.json';

const sharedConfig = {
  locales: DEFAULT_LOCALE,
  number: {
    currency: 'GBP',
  },
  standardFormatters: {
    number: {
      // Custom formatter for percentages: automatically divides by 100 and pass
      // decimal digits, if present.
      percent: (locales, numberOptions, value) =>
        (value / 100).toLocaleString(locales, {
          ...numberOptions,
          maximumFractionDigits: 2,
          style: 'percent',
        }),

      wholePercent: (locales, numberOptions, value) =>
        (value / 100).toLocaleString(locales, {
          ...numberOptions,
          maximumFractionDigits: 0,
          style: 'percent',
        }),

      wholePercentRoundDown: (locales, numberOptions, value) =>
        (Math.floor(value) / 100).toLocaleString(locales, {
          ...numberOptions,
          maximumFractionDigits: 0,
          style: 'percent',
        }),

      oneDecimalPercent: (locales, numberOptions, value) =>
        (value / 100).toLocaleString(locales, {
          ...numberOptions,
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
          style: 'percent',
        }),

      twoDecimalsPercent: (locales, numberOptions, value) =>
        (value / 100).toLocaleString(locales, {
          ...numberOptions,
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: 'percent',
        }),

      wholeCurrency: (locales, numberOptions, value) =>
        value.toLocaleString(locales, {
          ...numberOptions,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: 'currency',
        }),
    },
    string: {
      i18n: (locales, stringOptions, value) => _i18n('variables')([value]),
      error: (locales, stringOptions, value) => _i18n('errors')([value]),
    },
    date: {
      defaultDateFormat: (locales, numberOptions, value) =>
        value.toLocaleString(locales, {
          ...numberOptions,
          weekday: undefined,
          era: undefined,
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: undefined,
          minute: undefined,
          second: undefined,
          timeZoneName: undefined,
        }),
    },
  },
};

_i18nConfig({
  ...sharedConfig,
  translations: {
    ...enTranslationMessages,
    variables: enTranslationVariables,
    errors: enTranslationErrors,
  },
});

delete global.i18n;
delete global.i18nConfig;
delete global.i18nGroup;

const I18nContext = React.createContext(_i18n);
const { Provider, Consumer } = I18nContext;

export { I18nContext, Provider, Consumer };
