import Config from 'config';
import { logger } from 'services/Logger';
import { logRocketNetworkSanitizer } from './logRocket';

let LogRocket;
let googleAnalytics;
let facebookPixel;
let linkedInInsights;

const trackingEnabled =
  window &&
  process.env.NODE_ENV === 'production' &&
  !process.env.testContext &&
  Config.userTracking.enabled &&
  !navigator.userAgent.includes('Pingdom') &&
  !navigator.userAgent.includes('SpeedCurve');

const unfailable = func => async (...args) => {
  try {
    await func(...args);
  } catch (error) {
    logger.error(error);
    console.error(error); // eslint-disable-line no-console
  }
};

/* eslint-disable no-param-reassign */
const taboola = () =>
  ((t, f, a, x) => {
    window._tfa = window._tfa || [];
    // eslint-disable-next-line no-unused-expressions
    if (!document.getElementById(x)) {
      t.async = 1;
      t.src = a;
      t.id = x;
      f.parentNode.insertBefore(t, f);
    }
  })(
    document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1186982/tfa.js',
    'tb_tfa_script'
  );

/* eslint-disable */
const outbrain = () => {
  /** DO NOT MODIFY THIS CODE**/
  !(function(_window, _document) {
    var OB_ADV_ID = '008edca530954df1c2b7896e4bf1a6ade3';
    if (_window.obApi) {
      var toArray = function(object) {
        return Object.prototype.toString.call(object) === '[object Array]'
          ? object
          : [object];
      };
      _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(
        toArray(OB_ADV_ID)
      );
      return;
    }
    var api = (_window.obApi = function() {
      api.dispatch
        ? api.dispatch.apply(api, arguments)
        : api.queue.push(arguments);
    });
    api.version = '1.1';
    api.loaded = true;
    api.marketerId = OB_ADV_ID;
    api.queue = [];
    var tag = _document.createElement('script');
    tag.async = true;
    tag.src = '//amplify.outbrain.com/cp/obtp.js';
    tag.type = 'text/javascript';
    var script = _document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(tag, script);
  })(window, document);
  obApi('track', 'PAGE_VIEW');
};

export const init = unfailable(async () => {
  if (trackingEnabled) {
    LogRocket = (await import('logrocket')).default;
    googleAnalytics = (await import('react-ga')).default;
    facebookPixel = (await import('react-facebook-pixel')).default;
    linkedInInsights = (await import('react-linkedin-insight')).default;

    LogRocket.init(Config.keys.logRocket, {
      network: logRocketNetworkSanitizer,
    });
    require('logrocket-react')(LogRocket); // eslint-disable-line global-require

    googleAnalytics.initialize(Config.keys.google.analytics);

    facebookPixel.init(Config.keys.facebook);

    linkedInInsights.init(Config.keys.linkedIn);
    taboola();
    outbrain();
    trackPageView({ taboolaInit: true });

    window.trackingInitialised = true;
  }
});

export const track = unfailable((cat, e, obj = null) => {
  if (trackingEnabled) {
    const first = obj && Object.values(obj)[0];
    let eventSession;

    LogRocket.getSessionURL(sessionURL => {
      eventSession = { 'LogRocket Session': sessionURL };
    });

    googleAnalytics.event({
      category: cat,
      action: e,
      ...(obj &&
        (typeof first === 'string' ? { label: first } : { value: first })),
    });

    LogRocket.track(e);
  }
});

export const fbTrack = unfailable((e, obj = null) => {
  if (trackingEnabled) {
    facebookPixel.track(e, obj);
  }
});

export const identify = unfailable(({ id, firstName, lastName, email }) => {
  if (trackingEnabled) {
    LogRocket.identify(id, {
      name: `${firstName} ${lastName}`,
      email,
    });
  }
});

export const trackPageView = unfailable((args = {}) => {
  if (trackingEnabled) {
    // SalesForce tracker.
    // eslint-disable-next-line dot-notation
    if (window['_etmc'] && trackingEnabled) {
      // eslint-disable-next-line dot-notation
      window['_etmc'].push(['trackPageView']);
    }

    if (window) {
      googleAnalytics.pageview(
        window.location.pathname + window.location.search
      );
      facebookPixel.pageView();
      // When init taboola, you need the ID, otherwise, push the events.
      if (args.taboolaInit) {
        window._tfa.push({ notify: 'event', name: 'page_view', id: 1186982 });
      } else {
        window._tfa.push({
          notify: 'event',
          name: 'page_view',
          'item-url': window.location.pathname + window.location.search,
        });
      }
    }
  }
});
