import React from 'react';

const SidebarDocuments = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3" />
      <path
        d="M13.5 4.5h-6c-.825 0-1.4925.675-1.4925 1.5L6 18c0 .825.6675 1.5 1.4925 1.5H16.5c.825 0 1.5-.675 1.5-1.5V9l-4.5-4.5zm1.5 12H9V15h6v1.5zm0-3H9V12h6v1.5zm-2.25-3.75V5.625l4.125 4.125H12.75z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarDocuments.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarDocuments;
