import React from 'react';

import Config from 'config';

const Twitter = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 38 38" width={width} {...props}>
    <path
      d="M18.5363 0C8.29846 0 0 8.50606 0 19C0 29.4928 8.29846 38 18.5363 38C28.7741 38 37.0726 29.4928 37.0726 19C37.0726 8.50606 28.7741 0 18.5363 0Z"
      fill={fill}
      transform="translate(0.269531)"
    />

    <path
      d="M12.7785 0.204516C10.9364 0.892079 9.7721 2.66383 9.90417 4.60302L9.94819 5.35114L9.21138 5.2597C6.52941 4.9082 4.18572 3.71714 2.19539 1.7162L1.22224 0.723454L0.973155 1.45614C0.442554 3.09014 0.781999 4.81677 1.88723 5.97814C2.47691 6.61939 2.34368 6.71083 1.32766 6.32964C0.974313 6.20733 0.663831 6.11589 0.634868 6.1622C0.53176 6.26908 0.885107 7.65845 1.16547 8.20945C1.54894 8.9742 2.32978 9.72114 3.18477 10.1653L3.90768 10.5168L3.05154 10.5298C2.22667 10.5298 2.19655 10.5453 2.28576 10.8671C2.58002 11.8598 3.74433 12.9143 5.04187 13.3727L5.95594 13.6933L5.16004 14.1826C3.98067 14.8868 2.59508 15.2834 1.20949 15.3119C0.545662 15.3273 0 15.3879 0 15.4342C0 15.5862 1.79918 16.4412 2.84416 16.7785C5.98374 17.7712 9.71301 17.3425 12.5132 15.648C14.5035 14.4415 16.4927 12.0427 17.4218 9.71995C17.9234 8.48377 18.4239 6.22277 18.4239 5.13858C18.4239 4.43558 18.4679 4.34414 19.2928 3.50458C19.7794 3.01533 20.2358 2.48095 20.325 2.32895C20.4733 2.03802 20.4571 2.03802 19.7064 2.29808C18.454 2.75645 18.2768 2.69589 18.8966 2.00833C19.353 1.51908 19.8987 0.633205 19.8987 0.37433C19.8987 0.329205 19.6774 0.405204 19.4272 0.541767C19.1619 0.694954 18.5722 0.924142 18.1296 1.0607L17.3337 1.32077L16.612 0.814891C16.2135 0.540579 15.6551 0.234204 15.3596 0.142766C14.6066 -0.0709836 13.4573 -0.0401086 12.7785 0.204516Z"
      fill="white"
      transform="translate(8.60645 10.3477)"
    />
  </svg>
);

Twitter.defaultProps = {
  fill: Config.theme.colors.black,
  height: 38,
  width: 38,
};

export default Twitter;
