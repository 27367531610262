import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    img: {
      width: '100%',
      height: spacing(28),
    },
  })
);
