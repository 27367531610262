import React from 'react';

import Config from 'config';

const NewRosette = ({ fill, height, width, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 32 43" width={width} {...props}>
    <path
      d="M7.40178 0C3.31881 0 0 3.27624 0 7.30685C0 11.3375 3.32438 14.6137 7.40178 14.6137C11.4847 14.6137 14.8036 11.3319 14.8036 7.30685C14.8091 3.27624 11.4847 0 7.40178 0Z"
      fill={fill}
      transform="translate(8.73488 7.04395)"
    />
    <path
      d="M10.8377 1.66841C10.2744 1.11227 9.52693 0.803921 8.7293 0.803921C7.56911 0.803921 6.48144 0.512085 5.52763 0L0 11.3925L5.94597 9.72962L8.36117 15.346L14.1342 3.44694C12.8904 3.17713 11.7525 2.57144 10.8377 1.66841Z"
      fill={fill}
      transform="translate(0 27.654)"
    />
    <path
      d="M5.40492 0.803921C4.60729 0.803921 3.85986 1.11227 3.2965 1.66841C2.37616 2.57694 1.23828 3.18264 0 3.44694L5.77305 15.346L8.18825 9.72962L14.1342 11.3925L8.60659 0C7.65278 0.512085 6.56511 0.803921 5.40492 0.803921Z"
      fill={fill}
      transform="translate(17.8658 27.654)"
    />
    <path
      d="M27.6618 10.9699C26.7527 10.0724 26.2395 8.85549 26.2395 7.58905C26.2395 4.94603 24.0697 2.80408 21.3924 2.80408C20.1095 2.80408 18.8712 2.2975 17.9676 1.39997C16.0767 -0.466658 13.0033 -0.466658 11.1124 1.39997C10.2032 2.2975 8.97054 2.80408 7.68764 2.80408C5.01028 2.80408 2.84051 4.94603 2.84051 7.58905C2.84051 8.85549 2.32735 10.0779 1.41816 10.9699C-0.472721 12.8365 -0.472721 15.8705 1.41816 17.7371C2.32735 18.6347 2.84051 19.8516 2.84051 21.118C2.84051 23.761 5.01028 25.903 7.68764 25.903C8.97054 25.903 10.2088 26.4095 11.1124 27.3071C13.0033 29.1737 16.0767 29.1737 17.9676 27.3071C18.8768 26.4095 20.1095 25.903 21.3924 25.903C24.0697 25.903 26.2395 23.761 26.2395 21.118C26.2395 19.8516 26.7527 18.6347 27.6618 17.7371C29.5527 15.865 29.5527 12.8365 27.6618 10.9699ZM14.5372 24.0804C9.10441 24.0804 4.68119 19.7139 4.68119 14.3508C4.68119 8.98764 9.10441 4.62115 14.5372 4.62115C19.9756 4.62115 24.3932 8.98764 24.3932 14.3508C24.3932 19.7139 19.9756 24.0804 14.5372 24.0804Z"
      fill={fill}
      transform="translate(1.59945)"
    />
  </svg>
);

NewRosette.defaultProps = {
  fill: Config.theme.colors.white,
  height: 32,
  width: 43,
};

export default NewRosette;
