import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    root: {
      padding: spacing(3),
      [breakpoints.up('sm')]: {
        padding: spacing(6),
      },
    },
  })
);
