module.exports = {
  commonPaths: {
    notFound: () => '/404',
    home: () => '/',
    howItWorks: () => '/how-it-works',

    termsOfUse: () => '/terms-of-use',
    privacyNotice: () => '/privacy-notice',
    codeOfConduct: () => '/code-of-conduct',
    cookiedPolicy: () => '/cookie-policy',
    complaints: () => '/complaints',

    loans: () => '/loans',
    shares: () => '/shares',
    isa: () => '/isa',

    aboutUs: () => '/about-us',
    faq: () => '/faqs',
    support: () => '/support',
    contact: () => '/contact-us',
    keyRisks: () => '/key-risks',

    reaccreditation: () => '/reaccreditation',

    landingIsaop: () => '/landing-isaop',
    landingIsatrns: () => '/landing-isatrns',
    landingLoans: () => '/landing-loans',
    landingShares: () => '/landing-shares',
    isaCashback: () => '/isa-cashback',
    loansCashback: () => '/loans-cashback',
    sharesCashback: () => '/shares-cashback',

    express: () => '/express',
    expressRegister: () => '/express/confirm/:token',

    rightmove: () => '/rightmove',
    rightmoveRegister: () => '/rightmove/confirm/:token',

    propertyforum: () => '/propertyforum',
    propertyforumRegister: () => '/propertyforum/confirm/:token',

    expatnetwork: () => '/propertyforum',
    expatnetworkRegister: () => '/propertyforum/confirm/:token',

    lovemoney: () => '/lovemoney',
    lovemoneyRegister: () => '/lovemoney/confirm/:token',
  },
};
