export const LOGIN_REQUEST = 'SessionProvider/LOGIN_REQUEST';
export const LOGIN_SUBMIT = 'SessionProvider/LOGIN_SUBMIT';
export const LOGIN_SUCCESS = 'SessionProvider/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'SessionProvider/LOGIN_ERROR';

export const REGISTER_REQUEST = 'SessionProvider/REGISTER_REQUEST';
export const REGISTER_SUBMIT = 'SessionProvider/REGISTER_SUBMIT';
export const REGISTER_SUCCESS = 'SessionProvider/REGISTER_SUCCESS';
export const REGISTER_ERROR = 'SessionProvider/REGISTER_ERROR';

export const LOGOUT = 'SessionProvider/LOGOUT';
export const LOGOUT_ERROR = 'SessionProvider/LOGOUT_ERROR';

export const RESEND_VERIFICATION_EMAIL_REQUEST =
  'SessionProvider/RESEND_VERIFICATION_EMAIL_REQUEST';
export const RESEND_VERIFICATION_EMAIL_SUBMIT =
  'SessionProvider/RESEND_VERIFICATION_EMAIL_SUBMIT';
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  'SessionProvider/RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_ERROR =
  'SessionProvider/RESEND_VERIFICATION_EMAIL_ERROR';

export const USERDETAILS_UPDATE_REQUEST_SUCCESS =
  'SessionProvider/USERDETAILS_UPDATE_REQUEST_SUCCESS';

export const USERDETAILS_REQUEST = 'SessionProvider/USERDETAILS_REQUEST';
export const USERDETAILS_SUCCESS = 'SessionProvider/USERDETAILS_SUCCESS';
export const USERDETAILS_ERROR = 'SessionProvider/USERDETAILS_ERROR';

export const USERDETAILS_RESET = 'SessionProvider/USERDETAILS_RESET';

export const FORGOT_PASSWORD_RESET_REQUEST =
  'SessionProvider/FORGOT_PASSWORD_RESET_REQUEST';
export const FORGOT_PASSWORD_RESET_SUCCESS =
  'SessionProvider/FORGOT_PASSWORD_RESET_SUCCESS';
export const FORGOT_PASSWORD_RESET_ERROR =
  'SessionProvider/FORGOT_PASSWORD_RESET_ERROR';
export const FORGOT_PASSWORD_RESET_RESET =
  'SessionProvider/FORGOT_PASSWORD_RESET_RESET';

export const FORGOT_PASSWORD_CHANGE_REQUEST =
  'SessionProvider/FORGOT_PASSWORD_CHANGE_REQUEST';
export const FORGOT_PASSWORD_CHANGE_SUCCESS =
  'SessionProvider/FORGOT_PASSWORD_CHANGE_SUCCESS';
export const FORGOT_PASSWORD_CHANGE_ERROR =
  'SessionProvider/FORGOT_PASSWORD_CHANGE_ERROR';

export const ACCOUNTS_FINDALL_REQUEST =
  'SessionProvider/ACCOUNTS_FINDALL_REQUEST';
export const ACCOUNTS_FINDALL_SUCCESS =
  'SessionProvider/ACCOUNTS_FINDALL_SUCCESS';
export const ACCOUNTS_FINDALL_ERROR = 'SessionProvider/ACCOUNTS_FINDALL_ERROR';

export const SESSION_IDLE = 'SessionProvider/SESSION_IDLE';

export const UPDATE_NI_NUMBER_REQUEST =
  'SessionProvider/UPDATE_NI_NUMBER_REQUEST';
export const UPDATE_NI_NUMBER_SUCCESS =
  'SessionProvider/UPDATE_NI_NUMBER_SUCCESS';
