import React from 'react';

const Email = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <path
      d="M12.0516 0C5.4968 0 .1806 5.316.1806 11.871c0 6.5548 5.3162 11.871 11.871 11.871 6.555 0 11.871-5.3162 11.871-11.871C23.9226 5.316 18.6066 0 12.0516 0zm0 23.2258c-6.271 0-11.3548-5.084-11.3548-11.3548C.6968 5.6 5.7806.516 12.0516.516c6.271 0 11.355 5.084 11.355 11.355 0 6.271-5.084 11.3548-11.355 11.3548z"
      fill={fill}
    />
    <path
      d="M19.9484 7.0452v-.0517c0-.0258-.0258-.0258-.0258-.0516 0 0 0-.026-.0258-.026-.0258-.026-.0516-.026-.0774-.052h-.026c-.0257 0-.0515-.026-.0773-.026H4.362c-.0258 0-.0774 0-.1032.026H4.233c-.026 0-.0518.026-.0776.051 0 0 0 .026-.0257.026-.0258.025-.0258.025-.0258.051v9.755c0 .0772.025.129.077.2062.051.0517.129.0775.18.0775.0253 0 .077 0 .103-.026h15.122c.026.026.0772.026.103.026.0774 0 .129-.026.1807-.0777.0776-.0775.1034-.2065.052-.284V7.122c.026-.0516.026-.0516.026-.0774zm-.9032.3096l-7.0194 6.787-7.0193-6.787h14.0387zm-7.2 7.355c.0516.0515.1032.0773.1806.0773.0774 0 .129-.025.1807-.077l7.2258-6.993v8.413l-3.6646-3.664c-.1032-.103-.258-.103-.3612 0-.1033.103-.1033.258 0 .3617l3.6645 3.6646H4.9806l3.6646-3.6645c.1032-.103.1032-.258 0-.3612-.1033-.103-.258-.103-.3613 0L4.619 16.13V7.716l7.225 6.9937z"
      fill={fill}
    />
  </svg>
);

Email.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Email;
