import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { Link } from 'components/Atoms/Link';
import { Tooltip } from 'components/Atoms/Tooltip';
import { Section } from 'components/Molecules/Section';
import { useI18n } from 'utils/i18nConnect';
import { commonPaths } from 'router/paths';

export interface CapitalAtRiskProps {
  color?: 'white' | 'black';
  showForecastText?: boolean;
  showTaxText?: boolean;
  className?: string;
}

export const CapitalAtRisk: FC<CapitalAtRiskProps> = ({
  color = 'black',
  showForecastText = true,
  showTaxText = true,
  className,
}) => {
  const t = useI18n()('CapitalAtRisk');

  return (
    <Section color={color} size="small" className={className}>
      <Typography component="div">
        {t`Capital at risk. No `}
        <Tooltip
          title={
            <>
              {t`Financial Services`}
              <br />
              {t`Compensation Scheme`}
            </>
          }
          color="white"
        >
          {t`FSCS`}
        </Tooltip>{' '}
        {t`on invested sums.`}{' '}
        {showForecastText &&
          t`Forecasts are not guarantees and performance may vary. `}
        {showTaxText &&
          t`Tax treatment depends on individual circumstances and may change. `}
        {t`Before investing`}
        &nbsp;
        <Link to={commonPaths.keyRisks()}>{t`read more`}</Link>.
      </Typography>
    </Section>
  );
};
