import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { LOGOUT } from 'store/SessionProvider/actionTypes';

import * as actionTypes from './actionTypes';
import {
  AnswersActionTypes,
  AnswersState,
  ClassifyActionTypes,
  ClassifyState,
  QuizActionTypes,
  QuizState,
  ScreeningActionTypes,
  ScreeningState,
} from './types';
import { Pip } from '../../services/Pip';

const screeningInitialState = fromJS({
  classificationMethod: undefined,
});

export const screening = (
  state: ScreeningState = screeningInitialState,
  action: ScreeningActionTypes
): ScreeningState => {
  switch (action.type) {
    case LOGOUT:
      return screeningInitialState;
    case actionTypes.ACCREDITATION_SCREENING:
      return state.merge({
        classificationMethod: action.payload,
      });
    case actionTypes.QUIZ_STATUS_SUCCESS: {
      const { classificationMethod } = action.payload;

      // Allow to select new classification method for users with deprecated classification method
      if (
        classificationMethod &&
        classificationMethod === Pip.ClassificationMethod.QuizCertSophInv
      ) {
        return state.merge({
          classificationMethod: undefined,
        });
      }

      return state.merge({
        classificationMethod,
      });
    }
    default:
      return state;
  }
};

const answersInitialState: AnswersState = fromJS({});

export const answers = (
  state: AnswersState = answersInitialState,
  action: AnswersActionTypes
): AnswersState => {
  switch (action.type) {
    case LOGOUT:
      return answersInitialState;
    case actionTypes.QUESTION_ANSWERED:
      return state.merge({
        [action.payload.id]: fromJS({
          correct: action.payload.answer === '1',
          position: action.payload.index,
        }),
      });
    case actionTypes.ACCREDITATION_SCREENING:
      return answersInitialState;
    default:
      return state;
  }
};

const quizInitialState: QuizState = fromJS({
  data: null,
  loading: false,
  results: false,
  complete: false,
});

export const quiz = (
  state: QuizState = quizInitialState,
  action: QuizActionTypes
): QuizState => {
  switch (action.type) {
    case LOGOUT:
      return quizInitialState;
    case actionTypes.TOGGLE_QUIZ_RESULTS:
      return state.merge({
        results: !state.get('results'),
      });
    case actionTypes.QUIZ_STATUS_SUCCESS:
      return state.merge({
        data: fromJS(action.payload),
        loading: false,
      });
    case actionTypes.QUIZ_STATUS_REQUEST:
      return state.merge({
        loading: true,
      });
    case actionTypes.QUIZ_STATUS_ERROR:
      return state.merge({
        loading: false,
      });
    case actionTypes.QUIZ_COMPLETE:
      return state.merge({
        complete: true,
      });
    default:
      return state;
  }
};

const classifyInitialState: ClassifyState = fromJS({
  submitting: false,
  success: null,
  risksAccepted: false,
});
export const classify = (
  state: ClassifyState = classifyInitialState,
  action: ClassifyActionTypes
): ClassifyState => {
  switch (action.type) {
    case LOGOUT:
      return classifyInitialState;
    case actionTypes.CLASSIFY_REQUEST:
      return state.merge({
        submitting: true,
      });
    case actionTypes.CLASSIFY_SUCCESS:
      return state.merge({
        submitting: false,
        success: true,
      });
    case actionTypes.CLASSIFY_ERROR:
      return state.merge({
        submitting: false,
      });
    case actionTypes.CLASSIFY_SET_RISKS_ACCEPTED:
      return state.merge({
        risksAccepted: action.payload,
      });
    default:
      return state;
  }
};

export const onboardingAccreditationReducer = combineReducers({
  screening,
  quiz,
  answers,
  classify,
});
