import api from '../endpoints/Content';

export default class Content {
  static get(url) {
    if (!url) {
      return Promise.reject();
    }

    return api.content.get(url);
  }

  static getAll() {
    return api.content.getAll();
  }
}
