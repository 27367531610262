import React from 'react';

import Config from 'config';

const BuyShares = ({
  fill = Config.theme.colors.akaroa,
  height = 24,
  width = 24,
  ...props
}) => (
  <svg height={height} viewBox="0 0 64 64" width={width} {...props}>
    <g fill="none" fillRule="evenodd" stroke={fill} strokeWidth="2">
      <path d="M44.8035 44.0546v18.452l-4.1004-4.258-4.1 4.258v-18.452m-.6836 7.5174H12" />
      <path d="M12.6834 51.4203V1h38.9542v50.572h-6.956M20.8843 11.9345h22.5524M20.8843 19.452h22.5524m-22.5524 6.834h13.668" />
      <path d="M47.5372 38.5874c0 3.775-3.059 6.834-6.834 6.834-3.7752 0-6.8342-3.059-6.8342-6.834 0-3.7752 3.059-6.834 6.834-6.834 3.7752 0 6.8342 3.0602 6.8342 6.834z" />
    </g>
  </svg>
);

BuyShares.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default BuyShares;
