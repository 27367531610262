import { createSelector } from 'reselect';
import { isAfter } from 'date-fns';

export const select = state => state.get('session');

export const selectSessionLoading = createSelector(select, sessionState =>
  sessionState ? sessionState.get('loading') : true
);

export const makeSelectUser = () =>
  createSelector(select, sessionState =>
    sessionState ? sessionState.get('user') : undefined
  );

export const makeSelectAddress = () =>
  createSelector(select, sessionState =>
    sessionState ? sessionState.getIn(['user', 'address']) : undefined
  );

export const makeSelectUserEmail = () =>
  createSelector(makeSelectUser(), user =>
    user ? user.get('email') : undefined
  );

export const selectUserPhoneNumber = createSelector(makeSelectUser(), user =>
  user ? user.get('phoneNumber') : undefined
);

export const makeSelectUserName = () =>
  createSelector(makeSelectUser(), user =>
    user ? `${user.get('firstName')} ${user.get('lastName')}` : undefined
  );

export const makeSelectIsLoggedIn = () =>
  createSelector(makeSelectUser(), user => user && user.get('id') > 0);

export const makeSelectRoles = () =>
  createSelector(makeSelectUser(), makeSelectIsLoggedIn(), (user, isLoggedIn) =>
    isLoggedIn ? user.get('roles').toJS() : []
  );

export const makeSelectVerificationInfo = () =>
  createSelector(makeSelectUser(), makeSelectIsLoggedIn(), (user, isLoggedIn) =>
    isLoggedIn ? user.get('verificationInfo').toJS() : null
  );

export const selectClassificationInfo = createSelector(
  makeSelectUser(),
  makeSelectIsLoggedIn(),
  (user, isLoggedIn) =>
    isLoggedIn && user.get('classificationInfo')
      ? user.get('classificationInfo').toJS()
      : null
);

export const selectPreviousAccreditationQuizVersion = createSelector(
  selectClassificationInfo,
  classificationInfo => classificationInfo?.quizVersion || '0.0.0'
);

export const selectUserHasClassificationInfo = createSelector(
  selectClassificationInfo,
  classificationInfo => !!classificationInfo
);

export const selectClassificationValidUntil = createSelector(
  selectClassificationInfo,
  classificationInfo => classificationInfo && classificationInfo.validUntil
);

export const selectUserAccreditationExpired = createSelector(
  selectClassificationInfo,
  classificationInfo =>
    !!classificationInfo &&
    isAfter(new Date(), new Date(classificationInfo.validUntil))
);

export const selectUserReAccreditationAllowed = createSelector(
  selectClassificationInfo,
  classificationInfo =>
    !!classificationInfo &&
    isAfter(new Date(), new Date(classificationInfo.allowReAccreditationAfter))
);

export const selectUserAccreditationQuizExpired = createSelector(
  selectClassificationInfo,
  classificationInfo =>
    !!classificationInfo &&
    isAfter(new Date(), new Date(classificationInfo.doesntNeedTakingQuizUntil))
);

export const makeSelectIsSubmitting = () =>
  createSelector(select, state => state.get('submitting'));

export const makeSelectIsPasswordResetSuccess = () =>
  createSelector(select, state => state.get('passwordResetSuccess'));

export const makeSelectIsPasswordChangeSuccess = () =>
  createSelector(select, state => state.get('passwordChangeSuccess'));

export const selectVerificationEmailAddress = createSelector(select, state =>
  state.get('verificationEmailAddress')
);

export const selectIsResendVerificationEmailSuccess = createSelector(
  select,
  state => state.get('resendVerificationEmailSuccess')
);

export const selectIsRegistrationSuccess = createSelector(select, state =>
  state.get('registrationSuccess')
);

export const makeSelectAccounts = () =>
  createSelector(select, makeSelectIsLoggedIn(), (state, isLoggedIn) =>
    isLoggedIn && state.has('accounts') ? state.get('accounts') : null
  );

export const makeSelectError = () =>
  createSelector(select, state => state.get('error'));

export const makeSelectAccountList = () =>
  createSelector(makeSelectAccounts(), state => state && state.get('items'));

export const makeSelectAccountId = accountType =>
  createSelector(makeSelectAccountList(), state => {
    const account =
      state && state.find(item => item.get('accountType') === accountType);
    return account && account.get('accountId');
  });

export const makeSelectFundedWallet = () =>
  createSelector(makeSelectAccountList(), state => {
    const account =
      state && state.find(item => item.get('accountType') === 'Main');
    return account && account.get('alreadyFunded');
  });

export const makeSelectAccountBalance = () =>
  createSelector(
    makeSelectAccounts(),
    state => state && state.get('allAccountsBalance')
  );

export const makeSelectSessionIsIdle = () =>
  createSelector(select, state => state && state.get('sessionIdle'));

export const makeSelectSessionIsIdleable = () =>
  createSelector(select, state => state && state.get('user'));

export const makeSelectUserReferralCode = () =>
  createSelector(makeSelectUser(), user =>
    user ? user.get('referralCode') : undefined
  );
