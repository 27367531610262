import { fromJS } from 'immutable';

import * as type from './actionTypes';

// The initial state of the App
const initialState = fromJS({});

function routeReducer(state = initialState, action) {
  switch (action.type) {
    case type.ROUTE_CHANGE:
      return fromJS(action.route);
    default:
      return state;
  }
}

export default routeReducer;
