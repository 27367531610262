import * as type from './actionTypes';

export const findAllPortfolioPropertiesRequest = ({
  accountId,
  accountType,
}) => ({
  type: type.PORTFOLIO_PROPERTY_FINDALL_REQUEST,
  accountId,
  accountType,
});

export const findAllPortfolioPropertiesSuccess = (id, data) => ({
  type: type.PORTFOLIO_PROPERTY_FINDALL_SUCCESS,
  payload: {
    accountId: id,
    ...data,
  },
});

export const findAllPortfolioPropertiesError = (id, errors) => ({
  type: type.PORTFOLIO_PROPERTY_FINDALL_ERROR,
  id,
  error: true,
  payload: errors,
});
