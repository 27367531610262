import { createSelector } from 'reselect';

const select = state => state.get('investorWallet');
const selectVisitedInvestment = state => state.get('investment');
const selectUserSession = state => state.get('session');

export const makeSelectVisitedInvestments = () =>
  createSelector(
    selectVisitedInvestment,
    investments => investments && investments.get('data')
  );

export const makeSelectError = () =>
  createSelector(select, state => state.get('error'));

export const selectBankData = createSelector(select, state =>
  state.getIn(['bank', 'data'])
);

export const selectCardsData = createSelector(select, state =>
  state.getIn(['cards', 'data'])
);

export const selectBankConfirmed = createSelector(select, state =>
  state.getIn(['bank', 'confirmed'])
);

export const selectReferenceNumber = createSelector(select, state =>
  state.get('referenceNumber')
);

export const selectBankError = createSelector(select, state =>
  state.getIn(['bank', 'error'])
);

export const makeSelectCardConfirmed = () =>
  createSelector(select, state => state.getIn(['cards', 'confirmed']));

export const selectCardsSubmitting = createSelector(select, state =>
  state.getIn(['cards', 'submitting'])
);

export const makeSelectCardError = () =>
  createSelector(select, state => state.getIn(['cards', 'error']));

export const makeSelectAccounts = () =>
  createSelector(selectUserSession, userSession =>
    userSession.getIn(['accounts', 'items'])
  );

export const makeSelectIsaAccount = () =>
  createSelector(
    makeSelectAccounts(),
    accounts =>
      accounts &&
      accounts.find(account => account.get('accountType') === 'LoanIsa')
  );

export const makeSelectMainAccount = () =>
  createSelector(
    makeSelectAccounts(),
    accounts =>
      accounts &&
      accounts.find(account => account.get('accountType') === 'Main')
  );

export const selectMainAccountReference = createSelector(
  makeSelectMainAccount(),
  account => account && account.get('referenceNumber')
);

export const selectAccountId = createSelector(
  selectUserSession,
  userSession => {
    const items = userSession.getIn(['accounts', 'items']);
    return (
      items &&
      items.reduce(
        (acc, account) =>
          account.get('accountType') === 'Main'
            ? account.get('accountId')
            : acc,
        null
      )
    );
  }
);

export const makeSelectCardsIsSubmitting = () =>
  createSelector(select, state => state.getIn(['cards', 'submitting']));

export const selectBankIsSubmitting = createSelector(select, state =>
  state.getIn(['bank', 'submitting'])
);

export const makeSelectTransferIsSubmitting = () =>
  createSelector(select, state => state.getIn(['transfer', 'submitting']));

export const makeSelectTransferConfirmed = () =>
  createSelector(select, state => state.getIn(['transfer', 'confirmed']));

export const makeSelectTransferError = () =>
  createSelector(select, state => state.getIn(['transfer', 'error']));

export const selectAddedFunds = createSelector(select, state =>
  state.get('addedFunds')
);

export const selectWithdrawnFunds = createSelector(select, state =>
  state.get('withdrawnFunds')
);

export const selectTransferredFunds = createSelector(select, state =>
  state.get('transferredFunds')
);

export const selectTransferData = createSelector(select, state =>
  state.getIn(['transfer', 'data'])
);

export const selectThreeDSecure = createSelector(select, state =>
  state.get('threeDSecure')
);

export const selectThreeDSecureError = createSelector(select, state =>
  state.getIn(['threeDSecure', 'error'])
);
