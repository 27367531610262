import React, { FC, ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

import { Section, SectionProps } from 'components/Molecules/Section';
import { H2 } from 'components/Atoms/Heading';
import { getGridLayout } from './getGridLayout';
import { getGridSpacing } from './getGridSpacing';
import { Size, Variant } from './types';

interface CheckItem {
  id: string;
  heading?: ReactNode;
  description?: ReactNode;
}

export interface CheckItemsSectionProps {
  heading: ReactNode;
  description?: ReactNode;
  items: CheckItem[];
  variant?: Variant;
  size?: Size;
  sectionProps?: Partial<SectionProps>;
}

export const CheckItemsSection: FC<CheckItemsSectionProps> = ({
  heading,
  description,
  items = [],
  variant,
  size,
  sectionProps,
}) => {
  const gridLayout = getGridLayout(variant);
  const gridSpacing = getGridSpacing(size);

  return (
    <Section size="large" {...sectionProps}>
      <Box textAlign="center" mb={8}>
        <Grid container justify="center">
          <Grid item xs={12} lg={9}>
            <H2 paragraph>{heading}</H2>
            {description && (
              <Typography variant="body1">{description}</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container justify="space-evenly" spacing={gridSpacing}>
        {items.map(item => (
          <Grid key={item.id} item {...gridLayout}>
            <Grid container spacing={1}>
              <Grid item xs="auto">
                <CheckCircle color="primary" fontSize="large" />
              </Grid>
              <Grid item xs>
                <Box pt={item.heading ? 1 : 0.6}>
                  {item.heading && (
                    <Typography variant="h4">{item.heading}</Typography>
                  )}
                  {item.description && (
                    <Typography variant={item.heading ? 'body2' : 'body1'}>
                      {item.description}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};
