import React, { FC } from 'react';
import Loadable from 'react-loadable';
import { Box, CircularProgress } from '@material-ui/core';

interface PasswordStrengthCheckerProps {
  value: string;
  formValues?: string[];
}

export const LoadablePasswordStrengthChecker = Loadable({
  loader: async () => {
    const { PasswordStrengthChecker } = await import(
      './PasswordStrengthChecker'
    );
    return PasswordStrengthChecker as FC<PasswordStrengthCheckerProps>;
  },
  loading: () => (
    <Box my={7} textAlign="center">
      <CircularProgress color="inherit" />
    </Box>
  ),
});
