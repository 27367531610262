export const INVESTOR_ACTIVITY_FIND_REQUEST = 'InvestorActivity/FIND_REQUEST';
export const INVESTOR_ACTIVITY_FIND_SUCCESS = 'InvestorActivity/FIND_SUCCESS';
export const INVESTOR_ACTIVITY_FIND_ERROR = 'InvestorActivity/FIND_ERROR';

export const INVESTOR_ACTIVITY_CANCEL_SHARE_BID =
  'InvestorActivity/INVESTOR_ACTIVITY_CANCEL_SHARE_BID';
export const INVESTOR_ACTIVITY_CANCEL_LOAN_BID =
  'InvestorActivity/INVESTOR_ACTIVITY_CANCEL_LOAN_BID';
export const INVESTOR_ACTIVITY_CANCEL_REQUEST =
  'InvestorActivity/INVESTOR_ACTIVITY_CANCEL_REQUEST';
export const INVESTOR_ACTIVITY_CANCEL_SUCCESS =
  'InvestorActivity/INVESTOR_ACTIVITY_CANCEL_SUCCESS';
export const INVESTOR_ACTIVITY_CANCEL_ERROR =
  'InvestorActivity/INVESTOR_ACTIVITY_CANCEL_ERROR';
