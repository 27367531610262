import React from 'react';
import styled from 'styled-components';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { differenceInCalendarMonths } from 'date-fns';
import PropTypes from 'prop-types';

import Config from 'config';
import { i18nConnect } from 'utils/i18nConnect';
import media from 'utils/responsive';
import { exitDateStringToDate } from 'utils/date';
import { Slider } from 'components/Atoms';
import { StartAdornment, TextField } from 'components/Atoms/Form';
import { Tooltip } from 'components/Atoms/Tooltip';

import calculateIsa from './calculateIsa';
import { calculateShareReturns, Market } from './investmentToolCalculate';

const PENNIES = 100;
const PERCENT = 100;
const A_POUND_IN_PENNIES = 100;

// Color palette was extracted to allow styled components to not be passed i18n
const colorPalette = {
  loans: {
    darker: Config.theme.colors.wildBlueYonder,
    lighter: Config.theme.colors.purpleAthensGray,
  },
  shares: {
    darker: Config.theme.colors.shares,
    lighter: Config.theme.colors.jaggedIce,
  },
};

const typeDefinitions = i18n => ({
  loans: {
    investmentAmount: 5000,
    maxInvestment: 100000,
    colors: colorPalette.loans,
    labels: {
      firstRow: i18n('ReturnsCalculator')`First months\' interest`,
      thirdRow: i18n('ReturnsCalculator')`Average annual interest`,
      totalReturn: i18n('ReturnsCalculator')`Total interest return`,
      investmentPlusReturn: i18n('ReturnsCalculator')` Investment + return`,
    },
    note: (
      <>
        {i18n(
          'ReturnsCalculator'
        )`The above figures and forecasts relate to the initial offering as seen in the Info Pack, they are not applicable to investments in the Resale Market. Forecasts are a guide only and not guaranteed since performance may vary. It is assumed that all interest income is reinvested at the same interest rate. Figures are presented net of fees. For further information, please see the Financials section of the Info Pack for each investment.`}
      </>
    ),
  },
  shares: {
    investmentAmount: 10000,
    maxInvestment: 100000,
    colors: colorPalette.shares,
    labels: {
      firstRow: i18n('ReturnsCalculator')`Monthly dividend`,
      secondRow: i18n('ReturnsCalculator')`Annual dividend`,
      thirdRow: i18n('ReturnsCalculator')`Capital gain`,
      totalReturn: (
        <Tooltip
          title={
            <>
              {i18n('ReturnsCalculator')`Dividend and`}
              <br />
              {i18n('ReturnsCalculator')`capital gains`}
            </>
          }
        >
          {i18n('ReturnsCalculator')`Total share return`}
        </Tooltip>
      ),
      investmentPlusReturn: i18n('ReturnsCalculator')` Investment + return`,
    },
    note: (
      <>
        {i18n(
          'ReturnsCalculator'
        )`The above figures and forecasts relate to the initial offering as seen in the Info Pack, they are not applicable to investments in the Resale Market. Forecasts are a guide only and not guaranteed since performance may vary. Figures are presented net of fees. For further information, please see the Financials section of the Info Pack for each investment.`}
      </>
    ),
  },
});

const CSSVariables = styled.div`
  --themeColorLighter: ${({ theme }) => colorPalette[theme].lighter};
  --themeColorDarker: ${({ theme }) => colorPalette[theme].darker};
`;

const Wrapper = styled.div`
  border-radius: 1.5rem;
  border: 2px solid var(--themeColorDarker);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0.5rem;
  margin-bottom: 2rem;

  ${media.medium`
    padding: 0.75rem;
  `}

  .dataRow {
    display: flex;
    padding: 0.4375rem 0.625rem;
    justify-content: space-between;
  }

  .caption {
    font-size: 0.875rem;
  }

  .value {
    font-size: 0.875rem;
    font-weight: ${Config.theme.fontWeight.medium};
  }

  ${media.medium`
    .caption, .value {
      font-size: 1.25rem;
    }

    .dataRow {
      padding: 0.9375rem 1.25rem;
    }
  `}
`;

const PropertyDetailsIsa = styled.div`
  display: flex;
  border: 1px solid ${Config.theme.colors.alto};
  padding: 0.4375rem 0.625rem;
  margin-bottom: 0.625rem;

  .detail {
    width: 100%;
    text-align: left;
    line-height: 1;
  }

  .detail:nth-of-type(2) {
    text-align: center;
    border-right: 1px solid ${Config.theme.colors.alto};
    border-left: 1px solid ${Config.theme.colors.alto};
  }

  .detail:last-of-type {
    text-align: right;
  }

  .caption {
    font-size: 0.75rem;
  }

  .value {
    font-size: 0.875rem;
    font-weight: ${Config.theme.fontWeight.medium};
  }

  ${media.medium`
    padding: 0.4375rem 1.25rem;
    margin-bottom: 1.625rem;

    .caption {
      font-size: 1rem;
      line-height: 1.4;
    }

    .value {
      font-size: 1.375rem;
    }
  `}
`;

const PropertyDetailsShares = styled.div`
  display: flex;
  border: 1px solid ${Config.theme.colors.alto};
  padding: 0.4375rem 0.625rem;
  margin-bottom: 0.625rem;
  flex-wrap: wrap;

  .detail {
    width: 100%;
    text-align: center;
    line-height: 1;
  }

  .caption {
    font-size: 0.75rem;
  }

  .value {
    font-size: 0.875rem;
    font-weight: ${Config.theme.fontWeight.medium};
  }

  ${media.small.andBelow`
    .detail {
      flex-basis: 50%;
      padding-top: 0.4375rem;
    }

    .detail:nth-of-type(1),
    .detail:nth-of-type(2) {
      border-bottom: 1px solid ${Config.theme.colors.alto};
      padding-bottom: 0.4375rem;
      padding-top: 0;
    }

    .detail:nth-of-type(2n) {
      text-align: right;
      position: relative;

      &:before{
        content: '';
        background: ${Config.theme.colors.alto};
        height: 80%;
        position: absolute;
        width: 1px;
        display: block;
      }
    }

    .detail:nth-of-type(2n+1) {
      text-align: left;
    }
  `}

  ${media.medium`
    padding: 0.4375rem 1.25rem;
    margin-bottom: 1.625rem;
    flex-wrap: nowrap;

    .caption {
      font-size: 1rem;
      line-height: 1.4;
    }

    .value {
      font-size: 1.375rem;
    }

    .detail {
      border-left: 1px solid ${Config.theme.colors.alto};
    }

    .detail:first-of-type {
      text-align: left;
      border-left: none;
    }

    .detail:last-of-type {
      text-align: right;
    }
  `}
`;

const FirstRow = styled.div`
  border: 1px solid ${Config.theme.colors.alto};
`;

const SecondRow = styled.div`
  border: 1px solid ${Config.theme.colors.alto};
  border-top: none;
`;

const ThirdRow = styled.div`
  border: 1px solid ${Config.theme.colors.alto};
  border-top: none;
  border-bottom: none;

  ${media.medium`
    margin-bottom: 1rem;
    border-bottom: 1px solid ${Config.theme.colors.alto};
  `}
`;

const TotalInterestReturn = styled.div`
  background: var(--themeColorLighter);
  font-weight: ${Config.theme.fontWeight.medium};
`;

const InvestmentAndReturn = styled.div`
  background: var(--themeColorDarker);
  border-radius: 0 0 1rem 1rem;
  color: ${Config.theme.colors.white};
  font-weight: ${Config.theme.fontWeight.medium};

  .caption,
  .value {
    font-size: 0.875rem;
  }

  @media (min-width: 376px) {
    .caption,
    .value {
      font-size: 1rem;
    }
  }

  ${media.medium`
    .caption, .value {
      font-size: 1.5rem;
    }
  `}
`;

const StyledNote = styled.p`
  font-size: 1rem;

  ${media.medium`
    font-size: 1.25rem;
  `}
`;

const atMostTwoDecimalPlaces = value => /^[0-9]*\.?[0-9]?[0-9]?$/.test(value);
const fitToRange = (value, min, max) => Math.max(Math.min(value, max), min);

export class ReturnsCalculator extends React.PureComponent {
  constructor(props) {
    super(props);

    const { type, i18n, properties } = props;
    const currentPropertyIndex = properties.findIndex(
      ({ defaultShares, defaultLoans }) =>
        type === 'shares' ? defaultShares : defaultLoans
    );
    const currentPropertyId = properties[currentPropertyIndex].id;
    const definitions = typeDefinitions(i18n, currentPropertyId)[type];

    this.state = {
      currentPropertyIndex,
      investmentAmount: definitions.investmentAmount,
      minInvestment: 0,
      maxInvestment: definitions.maxInvestment,
    };
  }

  handleInput = e => {
    const value = Number(e.target.value);
    const { minInvestment, maxInvestment } = this.state;
    if (!Number.isNaN(value) && atMostTwoDecimalPlaces(e.target.value)) {
      this.setState({
        investmentAmount: fitToRange(value, minInvestment, maxInvestment),
      });
    } else {
      this.setState(prevState => ({
        investmentAmount: prevState.investmentAmount,
      }));
    }
  };

  handlePropertyChange = event => {
    this.setState({ currentPropertyIndex: event.target.value });
  };

  render() {
    const { properties, i18n, type } = this.props;
    const {
      investmentAmount,
      currentPropertyIndex,
      minInvestment,
      maxInvestment,
    } = this.state;
    const {
      id: currentPropertyId,
      LTV,
      exitDate,
      term: termInYears,
      rate,
      dividendRate,
      capitalGain,
    } = properties[currentPropertyIndex];
    const definitions = typeDefinitions(i18n, currentPropertyId)[type];
    const monthsUntilExit = differenceInCalendarMonths(
      exitDateStringToDate(exitDate),
      new Date()
    );

    let calculationResult;
    if (type === 'loans') {
      calculationResult = calculateIsa({
        investmentAmount: investmentAmount * PENNIES,
        monthsUntilExit,
        rate,
      });
      calculationResult = {
        firstRow: calculationResult.firstMonthInterest,
        thirdRow: calculationResult.averageAnnualInterest,
        totalReturn: calculationResult.totalInterestReturn,
        investmentPlusReturn: calculationResult.investmentPlusReturn,
      };
    } else if (type === 'shares') {
      calculationResult = calculateShareReturns({
        investmentAmount: investmentAmount * PENNIES,
        monthsUntilExit,
        termInYears,
        dividendReturn: dividendRate / PERCENT,
        capitalGain: capitalGain / PERCENT,
        marketType: Market.PRIMARY,
        purchasePrice: A_POUND_IN_PENNIES,
        estimatedShareUnitPrice: A_POUND_IN_PENNIES,
      });
      calculationResult = {
        firstRow: calculationResult.estimatedIncome.month,
        secondRow: calculationResult.estimatedIncome.annual,
        thirdRow: calculationResult.estimatedCapitalGain.term,
        totalReturn: calculationResult.estimatedReturn.term,
        investmentPlusReturn: calculationResult.totalInvestmentAndReturn.term,
        totalCapitalGain: calculationResult.totalCapitalGain,
      };
    }

    return (
      <CSSVariables theme={type}>
        <Wrapper>
          <Box
            bgcolor="var(--themeColorDarker)"
            p={2}
            borderRadius="16px 16px 0 0"
          >
            <Grid container justify="flex-end" alignItems="center" spacing={1}>
              <Grid item xs={12} md="auto">
                <Typography variant="body1">
                  <Box
                    component="span"
                    fontWeight="bold"
                    pr={1}
                    color="common.white"
                  >
                    {i18n('ReturnsCalculator')`I want to invest:`}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="none"
                  value={investmentAmount}
                  onChange={this.handleInput}
                  color={type === 'loans' ? 'purple' : 'green'}
                  InputProps={{
                    startAdornment: (
                      <StartAdornment hiddenLabel>£</StartAdornment>
                    ),
                  }}
                  hiddenLabel
                />
              </Grid>
              <Grid item xs={12}>
                <Slider
                  controls
                  step={1}
                  min={minInvestment}
                  max={maxInvestment}
                  theme="ISA"
                  handleChange={this.handleInput}
                  value={investmentAmount}
                />
              </Grid>
            </Grid>
          </Box>
          <Box py={2}>
            <Grid container justify="flex-end" alignItems="center" spacing={1}>
              <Grid item xs={12} md="auto">
                <Typography variant="body1">
                  <Box component="span" fontWeight="bold" pr={1}>
                    {i18n('ReturnsCalculator')`Select investment:`}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="none"
                  select
                  value={currentPropertyIndex}
                  onChange={this.handlePropertyChange}
                  color={type === 'loans' ? 'purple' : 'green'}
                  hiddenLabel
                >
                  {properties.map((property, index) => (
                    <MenuItem key={`property-${property.id}`} value={index}>
                      {property.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
          {type === 'loans' && (
            <PropertyDetailsIsa>
              <div className="detail">
                <span className="caption">LTV</span>
                <br />
                <span className="value">
                  {i18n('ReturnsCalculator')`${LTV / PERCENT}:p`}
                </span>
              </div>
              <div className="detail">
                <span className="caption">Term</span>
                <br />
                <span className="value">
                  {Math.floor(monthsUntilExit)}{' '}
                  {i18n('ReturnsCalculator')`months`}
                </span>
              </div>
              <div className="detail">
                <span className="caption">Rate p.a.</span>
                <br />
                <span className="value">
                  {i18n('ReturnsCalculator')`${rate}:n(twoDecimalsPercent)`}
                </span>
              </div>
            </PropertyDetailsIsa>
          )}
          {type === 'shares' && (
            <PropertyDetailsShares>
              <div className="detail">
                <span className="caption">LTV</span>
                <br />
                <span className="value">
                  {i18n('ReturnsCalculator')`${LTV / PERCENT}:p`}
                </span>
              </div>
              <div className="detail">
                <span className="caption">Term</span>
                <br />
                <span className="value">
                  {Math.floor(monthsUntilExit)}{' '}
                  {i18n('ReturnsCalculator')`months`}
                </span>
              </div>
              <div className="detail">
                <span className="caption">Dividend p.a.</span>
                <br />
                <span className="value">
                  {i18n(
                    'ReturnsCalculator'
                  )`${dividendRate}:n(twoDecimalsPercent)`}
                </span>
              </div>
              <div className="detail">
                <span className="caption">Capital gain</span>
                <br />
                <span className="value">
                  {i18n(
                    'ReturnsCalculator'
                  )`${calculationResult.totalCapitalGain *
                    PERCENT}:n(twoDecimalsPercent)`}
                </span>
              </div>
            </PropertyDetailsShares>
          )}
          <FirstRow className="dataRow">
            <span className="caption">{definitions.labels.firstRow}</span>
            <span className="value">
              {i18n('ReturnsCalculator')`${calculationResult.firstRow /
                PENNIES}:c`}
            </span>
          </FirstRow>
          {definitions.labels.secondRow && (
            <SecondRow className="dataRow">
              <span className="caption">{definitions.labels.secondRow}</span>
              <span className="value">
                {i18n('ReturnsCalculator')`${calculationResult.secondRow /
                  PENNIES}:c`}
              </span>
            </SecondRow>
          )}
          {definitions.labels.thirdRow && (
            <ThirdRow className="dataRow">
              <span className="caption">{definitions.labels.thirdRow}</span>
              <span className="value">
                {i18n('ReturnsCalculator')`${calculationResult.thirdRow /
                  PENNIES}:c`}
              </span>
            </ThirdRow>
          )}
          <TotalInterestReturn className="dataRow">
            <span className="caption">{definitions.labels.totalReturn}</span>
            <span className="value">
              {i18n('ReturnsCalculator')`${calculationResult.totalReturn /
                PENNIES}:c`}
            </span>
          </TotalInterestReturn>
          <InvestmentAndReturn className="dataRow">
            <span className="caption">
              {definitions.labels.investmentPlusReturn}
            </span>
            <span className="value">
              {i18n(
                'ReturnsCalculator'
              )`${calculationResult.investmentPlusReturn / PENNIES}:c`}
            </span>
          </InvestmentAndReturn>
        </Wrapper>
        <div>
          <StyledNote>{definitions.note}</StyledNote>
        </div>
      </CSSVariables>
    );
  }
}

ReturnsCalculator.propTypes = {
  type: PropTypes.oneOf(['loans', 'shares']).isRequired,
  i18n: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default i18nConnect(ReturnsCalculator);
