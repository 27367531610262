import { Record } from 'immutable';

import { RegisterFormValues } from './types';

export const createInitialValues = Record<RegisterFormValues>({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  isTermsAccepted: false,
  isPrivacyAccepted: false,
  referrer: null,
  referrerNext: null,
  referrerFriendCode: null,
  captchaToken: '',
});
