import React from 'react';
import * as PropTypes from 'prop-types';
import { RoutedModalContextProvider } from './RoutedModalContext';

export const RoutedModal = ({ children, history, layer, pathLayers }) => {
  const closeModal = () => {
    history.push(pathLayers.slice(0, layer).join('~'));
  };

  return (
    <RoutedModalContextProvider closeModal={closeModal}>
      {children}
    </RoutedModalContextProvider>
  );
};

RoutedModal.propTypes = {
  children: PropTypes.node,
  layer: PropTypes.number.isRequired,
  pathLayers: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

RoutedModal.defaultProps = {
  children: null,
};
