import React from 'react';

const Wallet = props => (
  <svg viewBox="0 0 36 36" fill="none" {...props}>
    <g>
      <path
        d="M35.4016 16.5746H33.6945V8.72192C33.6945 6.83931 32.163 5.30774 30.2803 5.30774H27.9683L25.6022 1.05366C25.2416 0.403531 24.5554 0 23.8111 0C23.4642 0 23.1201 0.0894611 22.8162 0.258077L13.7358 5.30763H3.59289C1.71028 5.30763 0.178711 6.8392 0.178711 8.72182V32.1658C0.178711 34.0484 1.71028 35.58 3.59289 35.58H30.2803C32.163 35.58 33.6945 34.0484 33.6945 32.1658V25.2807H35.4016V16.5746ZM30.2803 6.67335C31.3319 6.67335 32.1909 7.47223 32.3063 8.49444H29.7402L28.7282 6.67335H30.2803ZM23.4806 1.45167C23.8008 1.27211 24.2317 1.3977 24.4085 1.71665L28.1778 8.49381H10.816L23.4806 1.45167ZM32.3288 32.1658C32.3288 33.2952 31.4097 34.2144 30.2802 34.2144H3.59289C2.46347 34.2144 1.54432 33.2952 1.54432 32.1658V8.72192C1.54432 7.5925 2.46347 6.67335 3.59289 6.67335H11.2803L8.00538 8.49455H4.01969C3.64209 8.49455 3.33684 8.80044 3.33684 9.17741C3.33684 9.55438 3.64209 9.86027 4.01969 9.86027H5.54924H30.4995H32.3288V16.5753H27.3352C25.0163 16.5753 23.129 18.4627 23.129 20.7815V21.0752C23.129 23.3942 25.0164 25.2815 27.3352 25.2815H32.3288V32.1658ZM34.0359 23.9151H33.6945H27.3352C25.7688 23.9151 24.4946 22.641 24.4946 21.0745V20.7808C24.4946 19.2144 25.7687 17.9401 27.3352 17.9401H33.6945H34.0359V23.9151ZM29.4267 20.9837C29.4267 21.8168 28.7521 22.4914 27.9191 22.4914C27.086 22.4914 26.4114 21.8167 26.4114 20.9837C26.4114 20.1507 27.0861 19.4761 27.9191 19.4761C28.7522 19.476 29.4267 20.1513 29.4267 20.9837Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </g>
  </svg>
);

export default Wallet;
