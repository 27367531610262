import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { propertyPaths, investmentPaths } from 'router/paths';

import Loader from './Loader';

export default () => (
  <Switch>
    <Route exact path={investmentPaths.investmentById()} component={Loader} />
    <Route
      exact
      path={investmentPaths.investmentByIdSummary()}
      component={Loader}
    />
    <Route
      exact
      path={investmentPaths.investmentByIdConfirmation()}
      component={Loader}
    />
    <Redirect to={propertyPaths.propertiesAll()} />
  </Switch>
);
