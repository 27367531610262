import React from 'react';

import Config from 'config';

const DoubleRosette = ({ height, width, color, circleColor, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 95 95" width={width} {...props}>
    <circle cx="47.5" cy="47.5" r="47.5" fill={circleColor} />
    <path
      d="M47.171 31.678c-5.103 0-9.252 4.036-9.252 9.001 0 4.966 4.156 9.002 9.252 9.002 5.104 0 9.253-4.043 9.253-9.002.007-4.965-4.149-9.001-9.253-9.001zM40.547 59.123a3.755 3.755 0 0 0-2.635-1.065c-1.45 0-2.81-.36-4.002-.99L27 71.102l7.433-2.048 3.019 6.919 7.216-14.659a8.398 8.398 0 0 1-4.12-2.191zM56.087 58.058c-.997 0-1.931.38-2.635 1.065a8.349 8.349 0 0 1-4.12 2.191l7.215 14.659 3.02-6.92 7.432 2.05-6.91-14.035a8.526 8.526 0 0 1-4.002.99z"
      fill={color}
    />
    <path
      d="M63.578 36.514A5.816 5.816 0 0 1 61.8 32.35c0-3.256-2.712-5.895-6.06-5.895a6.134 6.134 0 0 1-4.28-1.73c-2.364-2.299-6.206-2.299-8.57 0a6.146 6.146 0 0 1-4.28 1.73c-3.347 0-6.06 2.64-6.06 5.895 0 1.56-.64 3.066-1.777 4.165a5.787 5.787 0 0 0 0 8.337 5.816 5.816 0 0 1 1.778 4.165c0 3.256 2.712 5.895 6.059 5.895 1.603 0 3.151.624 4.28 1.73 2.364 2.299 6.206 2.299 8.57 0a6.146 6.146 0 0 1 4.28-1.73c3.348 0 6.06-2.64 6.06-5.895 0-1.56.641-3.06 1.778-4.165a5.787 5.787 0 0 0 0-8.337zM47.172 52.665c-6.791 0-12.32-5.379-12.32-11.986 0-6.607 5.529-11.986 12.32-11.986 6.798 0 12.32 5.38 12.32 11.986 0 6.607-5.522 11.986-12.32 11.986z"
      fill={color}
    />
  </svg>
);

DoubleRosette.defaultProps = {
  circleColor: Config.theme.colors.asparagus,
  color: Config.theme.colors.white,
  height: 95,
  width: 95,
};

export default DoubleRosette;
