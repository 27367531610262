import { createMuiTheme } from '@material-ui/core/styles';

import Config from 'config';

import { themeOptions } from '../themeOptions';

export const sharesTheme = createMuiTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
    primary: {
      main: Config.theme.colors.shares,
    },
  },
});
