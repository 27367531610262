import appConfig from 'config';

import { LoggerService } from './LoggerService';
import { SentryLoggerProvider } from './SentryLoggerProvider';
import { ConsoleLoggerProvider } from './ConsoleLoggerProvider';

export const logger = new LoggerService(
  appConfig.sentry.enabled
    ? new SentryLoggerProvider()
    : new ConsoleLoggerProvider()
);
