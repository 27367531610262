import React from 'react';

const PlayButton = props => (
  <svg viewBox="0 0 123 123" {...props}>
    <path
      clipRule="evenodd"
      d="M61.5 123c33.965 0 61.5-27.534 61.5-61.5S95.465 0 61.5 0 0 27.534 0 61.5 27.535 123 61.5 123zm-8.75-46.344L79 61.5 52.75 46.344v30.312z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default PlayButton;
