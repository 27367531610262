class BrowserStorageProvider {
  constructor(storage) {
    this.storage = storage;
  }

  get(key) {
    return this.storage.get(key);
  }

  set(key, value) {
    this.storage.set(key, value);
  }
}

export { BrowserStorageProvider };
