import React from 'react';

const SidebarProfile = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3z" />
      <path
        d="M12 12c1.6575 0 3-1.3425 3-3s-1.3425-3-3-3-3 1.3425-3 3 1.3425 3 3 3zm0 1.5c-2.0025 0-6 1.005-6 3V18h12v-1.5c0-1.995-3.9975-3-6-3z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarProfile.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarProfile;
