import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import styled from 'styled-components';

import { Button } from 'components/Atoms/Button';
import Config from 'config';
import { useI18n } from 'utils/i18nConnect';

const Modal = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000;
`;

const Container = styled.div`
  background-color: ${Config.theme.colors.white};
  border-radius: 0.5rem;
  max-width: 40rem;
  padding: 2rem;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: ${Config.theme.fontWeight.light};
  padding-bottom: 1rem;
`;

export const IdleModal = ({
  autologoutTimeout,
  inactiveLogOut,
  close,
  logout,
}) => {
  const t = useI18n()('IdleModal');

  useEffect(() => {
    const timeout = setTimeout(inactiveLogOut, autologoutTimeout);

    return () => clearTimeout(timeout);
  });

  return (
    <Modal>
      <Container>
        <Box mb={6} textAlign="center">
          <Title>{t`You are about to be logged out`}</Title>

          {t`You have been inactive for 15 minutes so, for your security, we will log you out of
              your account in the next minute unless you wish to continue`}
        </Box>

        <Grid container spacing={3} justify="center">
          <Grid item>
            <Button onClick={logout} variant="contained" color="secondary">
              {t`Log out now`}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={close} variant="contained" color="secondary">
              {t`Stay logged in`}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default IdleModal;
