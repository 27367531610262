import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { List } from 'immutable';

import { ActiveIndicator } from 'components/Atoms';
import { Pager } from 'components/Molecules';
import Config from 'config';
import { Pip } from 'services/Pip';
import { i18nConnect } from 'utils/i18nConnect';
import media from 'utils/responsive';

import SUB_TYPE_LABEL from './accountOperationSubtypesLabels';

const Table = styled(({ children, ...props }) => (
  <table {...props}>
    <tbody>{children}</tbody>
  </table>
))`
  width: 100%;

  tbody {
    width: 100%;
  }
`;

const Item = styled.td`
  padding: 0.5rem 1rem;
  width: 30%;

  &:last-child {
    width: 35%;
  }

  ${media.large`
    padding: 1rem;
    width: 15%;

    &:last-child {
      width: 15%;
    }
  `};
`;

const Header = Item.withComponent('th').extend`
  text-align: left;
`;

const DescriptionItem = Item.extend`
  width: 35%;

  ${media.large`
    width: 40%;
  `};
`;

const DescriptionHeader = DescriptionItem.withComponent('th').extend`
  text-align: left;
`;

const NumberItem = Item.extend`
  text-align: right;
`;

const NumberHeader = NumberItem.withComponent('th');

const ActivityRow = styled.tr`
  border-bottom: 1px solid ${Config.theme.colors.pampas};
  display: table-row;
  line-height: 1.5;
  opacity: 1;
  transition: all 0.5s;
  width: 100%;

  ${media.medium.andBelow`
    cursor: pointer;

    font-size: .7em;

    ${({ collapsed }) =>
      collapsed &&
      css`
        line-height: 0;
        opacity: 0;
        transition: all 0.5s;
      `}
  `} ${media.large`
    padding: 2rem 1rem;
  `};
`;

const MediumActivityRow = ActivityRow.extend`
  ${media.large`
    display: none;
  `};
`;

const LargeActivityRow = ActivityRow.extend`
  display: none;

  ${media.large`
    display: table-row;
  `};
`;

const ActivityHeader = ActivityRow.extend`
  background-color: ${Config.theme.colors.antiqueWhite};
  cursor: initial;
  font-weight: ${Config.theme.fontWeight.medium};
`;

const MediumActivityHeader = ActivityHeader.extend`
  ${media.large`
    display: none;
  `};
`;

const LargeActivityHeader = ActivityHeader.extend`
  display: none;

  ${media.large`
    display: table-row;
  `};
`;

const NoActivity = styled.p`
  font-size: 1.25rem;
  font-weight: ${Config.theme.fontWeight.light};
  margin-top: 2rem;
`;

export class AccountActivity extends React.Component {
  state = {};

  clickHandler = id => {
    this.setState(prevState => ({
      [id]: !prevState[id],
    }));
  };

  i18nAccountOperation = subtype => {
    const { i18n } = this.props;

    const accountOperation = SUB_TYPE_LABEL(i18n);

    return accountOperation[subtype] ? accountOperation[subtype] : subtype;
  };

  render() {
    const { data, i18n } = this.props;
    const activityState = this.state;
    const activityDetails = data instanceof List ? data.toArray() : [];

    const perPage = 10;

    return (
      <ActiveIndicator active={!data}>
        {activityDetails && activityDetails.length ? (
          <Pager totalPages={Math.ceil(activityDetails.length / perPage)}>
            {({ page }) => (
              <Table>
                <MediumActivityHeader>
                  <Header>{i18n('AccountActivity')`Date`}</Header>

                  <DescriptionHeader>
                    {i18n('AccountActivity')`Item`}
                  </DescriptionHeader>

                  <NumberHeader>
                    {i18n('AccountActivity')`Balance`}
                  </NumberHeader>
                </MediumActivityHeader>

                <LargeActivityHeader>
                  <Header>{i18n('AccountActivity')`Date`}</Header>

                  <DescriptionHeader>
                    {i18n('AccountActivity')`Item`}
                  </DescriptionHeader>

                  <NumberHeader>{i18n('AccountActivity')`Credit`}</NumberHeader>

                  <NumberHeader>{i18n('AccountActivity')`Debit`}</NumberHeader>

                  <NumberHeader>
                    {i18n('AccountActivity')`Balance`}
                  </NumberHeader>
                </LargeActivityHeader>

                {activityDetails
                  .filter(
                    (item, key) =>
                      key >= page * perPage && key < (page + 1) * perPage
                  )
                  .map((item, key) => (
                    <Fragment
                      // eslint-disable-next-line react/no-array-index-key
                      key={key}
                    >
                      <MediumActivityRow onClick={() => this.clickHandler(key)}>
                        <Item>
                          {i18n('AccountActivity')`${new Date(
                            item.get('utcTimestamp')
                          )}:t(d)`}
                        </Item>

                        <DescriptionItem>
                          {item.get('investmentName')}
                        </DescriptionItem>

                        <NumberItem>
                          {i18n('AccountActivity')`${item.get('balance')}:c`}
                        </NumberItem>
                      </MediumActivityRow>

                      {item.get('accountOperationType') ===
                        Pip.AccountOperationType.Credit && (
                        <MediumActivityRow
                          onClick={() => this.clickHandler(key)}
                          collapsed={!activityState[key]}
                        >
                          <Item>
                            {i18n('AccountActivity')`${new Date(
                              item.get('utcTimestamp')
                            )}:t(T)`}
                          </Item>

                          <DescriptionItem>
                            <Typography variant="caption">
                              {this.i18nAccountOperation(
                                item.get('accountOperationSubtype')
                              )}
                            </Typography>
                          </DescriptionItem>

                          <NumberItem>
                            {i18n('AccountActivity')`Credit: ${item.get(
                              'amount'
                            )}:c`}
                          </NumberItem>
                        </MediumActivityRow>
                      )}

                      {item.get('accountOperationType') ===
                        Pip.AccountOperationType.Debit && (
                        <MediumActivityRow
                          collapsed={!activityState[key]}
                          onClick={() => this.clickHandler(key)}
                        >
                          <Item>
                            {i18n('AccountActivity')`${new Date(
                              item.get('utcTimestamp')
                            )}:t(T)`}
                          </Item>

                          <DescriptionItem>
                            <Typography variant="caption">
                              {this.i18nAccountOperation(
                                item.get('accountOperationSubtype')
                              )}
                            </Typography>
                          </DescriptionItem>

                          <NumberItem>
                            {i18n('AccountActivity')`Debit: ${item.get(
                              'amount'
                            )}:c`}
                          </NumberItem>
                        </MediumActivityRow>
                      )}

                      <LargeActivityRow>
                        <Item>
                          {i18n('AccountActivity')`${new Date(
                            item.get('utcTimestamp')
                          )}:t(G)`}
                        </Item>

                        <DescriptionItem>
                          <div>
                            {this.i18nAccountOperation(
                              item.get('accountOperationSubtype')
                            )}
                          </div>

                          <Typography variant="caption">
                            {item.get('investmentName')}
                          </Typography>
                        </DescriptionItem>

                        <NumberItem>
                          {item.get('accountOperationType') ===
                            Pip.AccountOperationType.Credit &&
                            i18n('AccountActivity')`${item.get('amount')}:c`}
                        </NumberItem>

                        <NumberItem>
                          {item.get('accountOperationType') ===
                            Pip.AccountOperationType.Debit &&
                            i18n('AccountActivity')`${item.get('amount')}:c`}
                        </NumberItem>

                        <NumberItem>
                          {i18n('AccountActivity')`${item.get('balance')}:c`}
                        </NumberItem>
                      </LargeActivityRow>
                    </Fragment>
                  ))}
              </Table>
            )}
          </Pager>
        ) : (
          <NoActivity>{i18n('AccountActivity')`No activity`}</NoActivity>
        )}
      </ActiveIndicator>
    );
  }
}

AccountActivity.defaultProps = {
  data: [],
};

export default i18nConnect(AccountActivity);
