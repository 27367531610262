/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageProvider/CHANGE_LOCALE';
export const TRANSLATION_GROUP_REQUEST =
  'app/LanguageProvider/TRANSLATION_GROUP_REQUEST';
export const TRANSLATION_GROUP_SUCCESS =
  'app/LanguageProvider/TRANSLATION_GROUP_SUCCESS';
export const TRANSLATION_GROUP_FAILURE =
  'app/LanguageProvider/TRANSLATION_GROUP_FAILURE';
export const TRANSLATION_GROUP_LOAD =
  'app/LanguageProvider/TRANSLATION_GROUP_LOAD';
