import React, { FC } from 'react';
import { Box, Fade, LinearProgress } from '@material-ui/core';

import { Container } from 'components/Atoms/Container';

import { getPy } from './getPy';
import { getColors } from './getColors';
import { SectionProps } from './types';

export const Section: FC<SectionProps> = ({
  color,
  size,
  className,
  children,
  loading,
  ...props
}) => {
  const colors = getColors(color);
  const py = getPy(size);

  return (
    <Box position="relative">
      <Fade in={loading}>
        <Box position="absolute" width="100%">
          <LinearProgress />
        </Box>
      </Fade>
      <Box
        overflow="hidden"
        py={py}
        className={className}
        {...colors}
        {...props}
      >
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};
