import React from 'react';

const Faq = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M9.9884 14.933c0-1.1117.9011-2.0128 2.0128-2.0128 1.1118 0 2.0129.9011 2.0129 2.0128 0 1.1118-.9011 2.0129-2.0129 2.0129-1.1117 0-2.0128-.9011-2.0128-2.0129zm1.0064 0c0 .556.4505 1.0064 1.0064 1.0064.556 0 1.0065-.4505 1.0065-1.0064s-.4506-1.0064-1.0065-1.0064-1.0064.4505-1.0064 1.0064zm-.9465-9.561c.186 0 .1333.086.1333-.3496l.0028-.053c.0673-.6365.4155-1.2084.9814-1.5647.6878-.4323 1.5878-.4118 2.2606.0478 1.0997.7516 1.2227 2.2926.2959 3.2194-.2047.2047-1.1711 1.049-1.29 1.1578-.7844.718-1.2462 1.2877-1.3922 1.782-.0304.1028-.0453.199-.0453.2893v1.5096c0-.028.0325 0 .06 0h1.4496v-1.0064c0-.4422.2755-.8313.775-1.2836.1785-.1618.3804-.326.643-.5275.099-.0759.4797-.3631.566-.4295.1892-.1455.317-.2507.4052-.3352 1.6852-1.6118 1.4353-4.4176-.5649-5.7647-1.306-.8798-3.0884-.812-4.3397.162-.8986.6992-1.4124 1.721-1.4554 2.7984a11.166 11.166 0 0 1-.0021.1734c-.0014.075-.0016.1324-.0018.1746h1.5186zm0 1.0065H8.5387c-.4201 0-.7229-.1144-.896-.426-.1164-.2098-.1254-.333-.1175-.773.0013-.0712.0019-.1125.0024-.177.055-1.3888.7081-2.6877 1.8433-3.5711 1.5934-1.2401 3.852-1.3261 5.52-.2024 2.5315 1.7048 2.849 5.2697.6985 7.3265-.1206.1155-.2716.2399-.4874.4058-.0912.0702-.4726.3579-.567.4302-.2427.1863-.4255.335-.58.475-.2986.2704-.4441.476-.4441.5377v1.0064c0 .5517-.3802 1.0064-.9466 1.0064h-1.5096c-.552 0-1.0663-.4416-1.0663-1.0064V9.901c0-.1904.0297-.382.0865-.5743.2137-.7238.765-1.4036 1.6779-2.2393.141-.129 1.0816-.9509 1.2578-1.1271.4856-.4856.4218-1.2847-.152-1.6768-.343-.2344-.8098-.245-1.1569-.0269-.2944.1853-.4728.4714-.5137.7954-.0058.9-.277 1.3265-1.1397 1.3265z" />
      <path d="M6.9692 22.2426c0 .9053 1.1016 1.35 1.7301.6993l3.363-3.48h7.487c1.3895 0 2.516-1.1265 2.516-2.516v-7.045c0-1.3894-1.1265-2.516-2.516-2.516h-1.5096v1.0064h1.5097c.8336 0 1.5096.676 1.5096 1.5097v7.0449c0 .8336-.676 1.5096-1.5096 1.5096h-7.7006a.5032.5032 0 0 0-.3619.1535l-3.5114 3.6337v-3.284a.5032.5032 0 0 0-.5031-.5032h-2.516c-.8337 0-1.5097-.676-1.5097-1.5096v-7.045c0-.8336.676-1.5096 1.5096-1.5096H6.466V7.385H4.9563c-1.3895 0-2.516 1.1266-2.516 2.516v7.045c0 1.3895 1.1265 2.516 2.516 2.516h2.0129v2.7807z" />
    </g>
  </svg>
);

Faq.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Faq;
