import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { routeChange } from 'store/Router/actions';

import { makeSelectIsLoggedIn } from 'store/SessionProvider/selectors';

const mapStateToLoggedInProps = createStructuredSelector({
  isLoggedIn: makeSelectIsLoggedIn(),
});

const RequireAuth = withRouter(
  connect(mapStateToLoggedInProps)(
    class extends React.Component {
      componentDidUpdate() {
        const { isLoggedIn, dispatch, location } = this.props;
        if (!isLoggedIn) {
          dispatch(routeChange(location));
        }
      }

      render() {
        const { isLoggedIn, match, location, children } = this.props;
        const login = {
          pathname: '/login',
          state: {
            redirectAfterLogin: location.pathname,
          },
        };
        return isLoggedIn ? children : <Redirect from={match.url} to={login} />;
      }
    }
  )
);

export default RequireAuth;
