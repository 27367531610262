const { generatePath } = require('react-router-dom');

const namedPath = (pattern, params) => {
  return params ? generatePath(pattern, params) : pattern;
};

module.exports = {
  blogPaths: {
    blogAll: () => '/blog',
    blogByPath: params => {
      const formattedParams = params && {
        ...params,
        path: params.path.replace(/^\/|\/$/g, ''),
      };
      return namedPath('/blog/:path', formattedParams);
    },
  },
};

// import { commonPaths } from 'router/paths';
