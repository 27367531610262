/* eslint-disable no-unused-expressions */

import { injectGlobal } from 'styled-components';

import Config from 'config';
// TODO when upgrading to sytled components 4 change to
// import { createGlobalStyle } from 'styled-components';

import fontFace from './font-face';

const {
  theme: {
    fontFamily: { base },
    fontWeight: { light, medium },
    colors: { black },
  },
} = Config;

// TODO when upgrading to sytled components 4 change to
// export default createGlobalStyle`
injectGlobal`
  * {
    box-sizing: border-box;
  }

  ${fontFace}

  html,
  body {
    color: ${black};
    font-size: 100%;
    line-height: 1.7;
    margin: 0;
    min-height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-font-smoothing: antialiased;
    width: 100%;
  }

  body.videoLocked {
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    font-weight: ${light};
    margin: 0 0 1rem;
  }

  p {
    line-height: 1.5;
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b,
  strong {
    font-weight: ${medium};
  }

  button {
    font-family: ${base[0]}, ${base[1]};
    
    &,
    &:visited,
    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  .grecaptcha-badge {
    display: none !important;
  }

  `;
