import libUrl from 'url';

import { get, merge } from 'lodash';

import { getCacheManager, setExpire } from './cache';

import urlTransform from './urlTransform';

export default function endpoint(url, options, meta = {}) {
  const cache = {};
  let requestHolder = null;

  function getOptions(urlT, params) {
    let globalOptions = {};
    if (meta.holder) {
      globalOptions =
        meta.holder.options instanceof Function
          ? meta.holder.options(urlT, params)
          : meta.holder.options;
    }
    const baseOptions = !(options instanceof Function)
      ? options
      : options(urlT, params);
    return merge({}, globalOptions, baseOptions, params);
  }

  function getUrl(pathvars, params) {
    let urlT = urlTransform(url, pathvars, meta.urlOptions);
    let rootUrl = get(meta, ['holder', 'rootUrl']);

    rootUrl = !(rootUrl instanceof Function) ? rootUrl : rootUrl(urlT, params);
    if (rootUrl) {
      const rootUrlObject = libUrl.parse(rootUrl);
      const urlObject = libUrl.parse(urlT);
      if (!urlObject.host) {
        const urlPath = `${(rootUrlObject.path || '').replace(/\/$/, '')}/${(
          urlObject.path || ''
        ).replace(/^\//, '')}`;
        urlT = `${rootUrlObject.protocol || 'http:'}//${rootUrlObject.host ||
          'localhost'}${urlPath}`;
      }
    }
    return urlT;
  }

  function fetch(pathvars, params = {}, { expire } = {}) {
    const urlT = getUrl(pathvars, params);
    const opts = getOptions(urlT, params);
    const cacheManager = getCacheManager(expire, meta.cache);
    let id;

    if (cacheManager) {
      id = urlT + cacheManager.id(merge({}, opts, params));
      const data = cacheManager.getData(
        cache && id && cache[id] !== undefined ? cache[id] : undefined
      );

      if (data !== undefined) {
        return Promise.resolve(data);
      }
    }

    if (!meta.fetch) {
      return Promise.reject();
    }

    const response = meta.fetch(urlT, opts);

    if (cacheManager && id) {
      response.then(data => {
        const cacheExpire = cache[id] ? cache[id].expire : null;

        cache[id] = {
          expire: setExpire(cacheManager.expire, cacheExpire),
          data,
        };

        return data;
      });
    }

    return Promise.resolve(response);
  }

  function abort() {
    const defer = requestHolder;
    requestHolder = null;
    const err = new Error('Application abort request');
    if (defer && defer.reject) {
      defer.reject(err);
    }
    return err;
  }

  /**
   * Fetch data from server
   * @param pathvars    path vars for url
   * @param params      fetch params
   * @param opts        fetch options
   */
  function request(pathvars, params = {}, opts) {
    const response = fetch(pathvars, params, opts).then(possibleError => {
      if (possibleError instanceof Error) {
        return Promise.reject(possibleError);
      }

      return possibleError;
    });

    const result = response.then(
      data =>
        new Promise((resolve, reject) =>
          !meta.validation
            ? resolve(data)
            : meta.validation(data).then(() => resolve(data), reject)
        )
    );

    let ret = result;
    const responseHandler = get(meta, ['holder', 'responseHandler']);

    if (responseHandler) {
      if (result && result.then) {
        ret = result.then(
          data => {
            const res = responseHandler(data);
            if (res === undefined) {
              return data;
            }
            return res;
          },
          err => responseHandler(undefined, err)
        );
      } else {
        ret = responseHandler(result);
      }
    }

    return ret;
  }

  /**
   * Fetch data from server
   * @param  {Object}   pathvars    path vars for url
   * @param  {Object}   params      fetch params
   */
  async function fn(pathvars, params) {
    const promises = [];

    (meta.prefetch || []).forEach(callback => {
      promises.push(callback({ ...this, request: { pathvars, params } }));
    });

    await Promise.all(promises);
    return new Promise((resolve, reject) => {
      const promise = request(pathvars, params || {}, {}).then(resolve, reject);

      requestHolder = {
        resolve,
        reject,
        promise,
      };
    }).then(d => {
      requestHolder = null;
      const data =
        meta.transformer instanceof Function ? meta.transformer(d) : d;

      if (meta.postfetch) {
        meta.postfetch.forEach(postfetch => {
          if (postfetch instanceof Function) {
            postfetch({
              data,
              request: { pathvars, params },
            });
          }
        });
      }

      return data;
    });
  }

  /**
   Pure rest request
   */
  fn.request = request;

  /**
   Abort request
   */
  fn.abort = abort;

  const helpers = meta.helpers || {};

  const fnHelperCallback = func => async (...args) => {
    const helpersResult = func(...args);

    const [pathvars, params] = await helpersResult;

    return fn(pathvars, params);
  };

  return Object.keys(helpers).reduce(
    (memo, key) => merge(memo, { [key]: fnHelperCallback(helpers[key]) }),
    fn
  );
}
