import { RecordOf } from 'immutable';
import { AnyAction } from 'redux';

const stateSanitizer = <T>(state: RecordOf<T>): T =>
  state
    .removeIn(['form', 'LoginForm', 'values', 'password'])
    .removeIn(['form', 'RegisterForm', 'values', 'password'])
    .removeIn(['form', 'ForgotPasswordChangeForm', 'values', 'password'])
    .removeIn(['form', 'ForgotPasswordChangeForm', 'values', 'repeatPassword'])
    .removeIn(['form', 'AddCardForm', 'values', 'number'])
    .removeIn(['form', 'AddCardForm', 'values', 'name'])
    .removeIn(['form', 'AddCardForm', 'values', 'expiry'])
    .removeIn(['form', 'AddCardForm', 'values', 'cvv'])
    .removeIn(['form', 'InvestorWalletAddAccountForm', 'values', 'bank'])
    .removeIn([
      'form',
      'InvestorWalletAddAccountForm',
      'values',
      'accountNumber',
    ])
    .removeIn(['form', 'InvestorWalletAddAccountForm', 'values', 'sortCode'])
    .removeIn(['form', 'InvestorWalletAddAccountForm', 'values', 'owner'])
    .removeIn(['form', 'MyIsaForm', 'values', 'insurance'])
    .removeIn(['investorWallet', 'bank', 'data']);

const sanitizeFormChange = <T extends AnyAction>(action: T): T => {
  switch (action.meta.form) {
    case 'AddCardForm':
    case 'InvestorWalletAddAccountForm':
      if (action.meta.field !== 'amount') {
        return {
          ...action,
          payload: null,
        };
      }
      break;
    default:
      break;
  }

  switch (action.meta.field) {
    case 'password':
    case 'insurance':
      return {
        ...action,
        payload: null,
      };
    default:
      break;
  }

  return action;
};

const actionSanitizer = <T extends AnyAction>(action: T): T => {
  switch (action.type) {
    case '@@redux-form/CHANGE':
    case '@@redux-form/BLUR':
      return sanitizeFormChange(action);
    case 'SessionProvider/LOGIN_SUBMIT':
    case 'SessionProvider/LOGIN_REQUEST':
    case 'SessionProvider/REGISTER_SUBMIT':
    case 'SessionProvider/REGISTER_REQUEST':
      return {
        ...action,
        payload: {
          ...action.payload,
          password: null,
        },
      };
    case 'SessionProvider/FORGOT_PASSWORD_CHANGE__REQUEST':
      return {
        ...action,
        data: {
          ...action.data,
          password: null,
        },
      };
    case 'SessionProvider/LOGIN_SUCCESS':
    case 'SessionProvider/USERDETAILS_SUCCESS':
      return {
        ...action,
        user: {
          ...action.user,
          nationalInsuranceNumber: null,
        },
      };
    case 'InvestorBankDetails/BANK_FINDALL_SUCCESS':
      return {
        ...action,
        data: null,
      };
    case 'InvestorMyIsa/MYISA_CREATE_REQUEST':
      return {
        ...action,
        insurance: null,
      };
    default:
      break;
  }

  return action;
};

export const logRocketStateSanitizer = {
  stateSanitizer,
  actionSanitizer,
};
