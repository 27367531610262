import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

const Element = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;

  ${space};
`;

const Row = props => <Element mx={[0, -1]} {...props} />;

export default Row;
