import React from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import styled from 'styled-components';

import { Chevron } from 'components/Atoms/Icons';
import Config from 'config';

import {
  investorActivityCancelLoans,
  investorActivityCancelShares,
} from 'store/InvestorActivity/actions';

import { i18nConnect } from 'utils/i18nConnect';
import media from 'utils/responsive';
import { capitalise } from 'utils/typography';

import Table from './Table';

const Toggle = styled.div`
  background-color: ${({ sharesToggle }) =>
    Config.theme.colors[sharesToggle ? 'shares' : 'wildBlueYonder']};
  color: ${Config.theme.colors.white};
  cursor: pointer;
  font-size: 1rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 1.2rem;
  min-height: 3rem;
  padding: 0.4rem;
  text-align: center;
`;

const Highlight = styled.div`
  border: ${({ isLoans }) =>
    `0.125rem solid ${
      Config.theme.colors[isLoans ? 'wildBlueYonder' : 'shares']
    }`};
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Item = styled.div`
  border: 1px solid ${Config.theme.colors.white};
  border-bottom-style: none;
  border-right-style: none;
  color: ${({ isShares }) =>
    Config.theme.colors[isShares ? 'shares' : 'wildBlueYonder']};
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  justify-content: center;
  line-height: 1.75rem;
  min-height: 3.625rem;
  padding-bottom: 1.06rem;
  padding-top: 1.06rem;
  position: relative;
  text-overflow: ellipsis;
  width: calc(100% / 3);

  &:first-child {
    color: ${Config.theme.colors.black};
    font-size: 1rem;
    padding-left: 1rem;
    text-align: left;
  }

  &:nth-child(2) {
    color: ${Config.theme.colors.bid};
  }

  &:last-child {
    color: ${Config.theme.colors.offer};
  }
`;

const TableRow = styled.div`
  border-bottom: 1px solid ${Config.theme.colors.white};
  border-top: 1px solid ${Config.theme.colors.white};
  display: flex;
  width: 100%;

  &:nth-child(odd) {
    background-color: #eee;
    border: #eee;
  }

  &:nth-child(even) {
    background-color: #e2e2e2;
    border: #e2e2e2;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledChevron = styled(({ open, ...props }) => <Chevron {...props} />)`
  color: ${Config.theme.colors.white};
  transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.3s;
`;

const Label = styled.div`
  &::before {
    content: '${({ type }) => (type === 'shares' ? 'Qty.' : 'Prin.')}';

    ${media.medium`
      content: '${({ type }) =>
        type === 'shares' ? 'Quantity' : 'Principal'}';
    `}
  }
`;

export const getItems = ({
  row,
  i18n,
  type,
  // eslint-disable-next-line no-shadow
  investorActivityCancelLoans,
  // eslint-disable-next-line no-shadow
  investorActivityCancelShares,
  isAccountResale,
  accountId,
  suspended,
}) =>
  row.map((x, i) => {
    const id = x.get('orderUuid');
    const isLoans = type === 'loans';
    const q = x.get('quantity');
    return (
      <Item
        data-test-is-my-position={id ? true : null}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      >
        {id && !isAccountResale && <Highlight isLoans={isLoans} />}
        <strong>{i18n('Investment')`${x.get('unitPrice') || 0}:c`}</strong>

        <div>
          {isLoans
            ? i18n('Investment')`${q || 0}:n(wholeCurrency)`
            : i18n('Investment')`${q || 0}:n`}
        </div>
        {id && isAccountResale && !suspended && (
          <Box
            position="absolute"
            right="8px"
            color={Config.theme.colors[isLoans ? 'blueHaze' : 'monteCarlo']}
          >
            <IconButton
              size="small"
              data-test-id={`cancel-button-${type}`}
              color="inherit"
              onClick={() => {
                if (isLoans) {
                  investorActivityCancelLoans(id, accountId);
                } else {
                  investorActivityCancelShares(id, accountId);
                }
              }}
            >
              <Cancel />
            </IconButton>
          </Box>
        )}
      </Item>
    );
  });

export const padEmptyCells = (collection, len = 3) => {
  const arr = collection.toArray();
  const arrLength = arr.length;
  return arrLength >= len
    ? arr
    : [
        ...arr,
        ...Array.from({ length: len - arrLength }, () =>
          Map({
            unitPrice: ' ',
            quantity: ' ',
          })
        ),
      ];
};

export const LENGTH_THRESHOLD = 3;

export const getMaxLength = (collection, isAccountResale) =>
  collection.reduce(
    (acc, curr) => Math.max(acc, curr.size),
    isAccountResale ? 0 : LENGTH_THRESHOLD
  );

export const shouldRenderCells = ({
  isAccountResale,
  type,
  sharesExpanded,
  loansExpanded,
}) => {
  if (!isAccountResale) {
    return true;
  }
  return !!(
    isAccountResale &&
    ((type === 'shares' && sharesExpanded) ||
      (type === 'loans' && loansExpanded))
  );
};

export class ResaleTables extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sharesExpanded: props.isAccountResale,
      loansExpanded: props.isAccountResale,
    };
  }

  getChevronBool = type => {
    const { loansExpanded, sharesExpanded } = this.state;
    return type === 'shares' ? sharesExpanded : loansExpanded;
  };

  getToggle(type, maxLength) {
    const { isAccountResale } = this.props;
    const { loansExpanded, sharesExpanded } = this.state;
    const toggleCondition = maxLength > LENGTH_THRESHOLD || isAccountResale;
    return (
      <Toggle
        sharesExpanded={sharesExpanded}
        loansExpanded={loansExpanded}
        key={type}
        sharesToggle={type === 'shares'}
        onClick={toggleCondition ? this.handleToggle(type) : () => {}}
      >
        {toggleCondition && (
          <StyledChevron
            open={this.getChevronBool(type)}
            height={40}
            width={40}
          />
        )}
      </Toggle>
    );
  }

  handleToggle = type => () => {
    this.setState(prevState => ({
      [`${type}Expanded`]:
        type === 'loans' ? !prevState.loansExpanded : !prevState.sharesExpanded,
    }));
  };

  createRows({
    arr,
    toggleState,
    isLongest,
    objectWithPaddedCells,
    type,
    suspended,
  }) {
    const { i18n } = this.props;
    return (
      <>
        {arr
          .slice(
            0,
            toggleState
              ? objectWithPaddedCells[isLongest].length
              : LENGTH_THRESHOLD
          )
          .map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={i}>
              <Item>
                {i18n('Investment')`Price`}
                <Label type={type} />
              </Item>
              {getItems({ ...this.props, row, type, suspended })}
            </TableRow>
          ))}
      </>
    );
  }

  render() {
    const { i18n, marketplace, data, isAccountResale, suspended } = this.props;
    const { loansExpanded, sharesExpanded } = this.state;
    return (
      <Grid container spacing={4}>
        {data.map(({ type, lookup1, lookup2 }) => {
          const isShares = type === 'shares';
          const toggleState = isShares ? sharesExpanded : loansExpanded;
          const collection1 = marketplace.get(lookup1);
          const collection2 = marketplace.get(lookup2);
          const isLongest =
            collection1.size > collection2.size ? 'collection1' : 'collection2';
          const maxLength = getMaxLength(
            [collection1, collection2],
            isAccountResale
          );

          // If there is no data, and the table should be hidden when empty
          if (isAccountResale && !maxLength) {
            return null;
          }

          const objectWithPaddedCells = {
            collection1: padEmptyCells(collection1, maxLength),
            collection2: padEmptyCells(collection2, maxLength),
          };

          const arr = objectWithPaddedCells[isLongest].map((x, i) => [
            objectWithPaddedCells.collection1[i],
            objectWithPaddedCells.collection2[i],
          ]);

          const cellsRendered = shouldRenderCells({
            isAccountResale,
            type,
            sharesExpanded,
            loansExpanded,
          });

          return (
            <Grid key={type} item xs={12} md>
              <Table
                handleToggle={this.handleToggle(type)}
                shares={type === 'shares'}
                title={i18n('Investment')`${capitalise(type)}`}
                unit={i18n('Investment')`Bids to buy`}
                amount={i18n('Investment')`Offers to sell`}
                shouldRenderCells={cellsRendered}
              >
                {cellsRendered &&
                  this.createRows({
                    arr,
                    toggleState,
                    objectWithPaddedCells,
                    isLongest,
                    type,
                    suspended,
                  })}
                {cellsRendered && this.getToggle(type, maxLength)}
              </Table>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default connect(null, {
  investorActivityCancelLoans,
  investorActivityCancelShares,
})(i18nConnect(ResaleTables));
