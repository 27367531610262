import Config from 'config';

import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  notices: {
    url: '/notices',
    helpers: {
      get: () => [],
    },
    transformer({ data: { items: notices } }) {
      return notices;
    },
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
