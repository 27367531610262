import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Chevron } from 'components/Atoms/Icons';
import Config from 'config';

import media from 'utils/responsive';

const AnimatedChevron = styled(Chevron)`
  transform: rotate(90deg);
  transition: transform 0.3s;
  height: 48px;
  width: 48px;
  color: ${Config.theme.colors.white};
  margin-right: 1rem;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(-90deg);
    `};
`;

const Container = styled.div`
  background-color: ${Config.theme.colors.pomegranate};
  cursor: pointer;
  position: relative;
  width: 100%;
  z-index: 1;

  ${media.large`
    border: 0;
    background-color: ${Config.theme.colors.white};
  `};
`;

const ContentWrapper = styled.ul`
  background-color: ${Config.theme.colors.white};
  display: ${({ open }) => (open ? 'block' : 'none')};
  font-size: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: calc(100% + 0.5rem);
  width: 100%;

  li {
    align-items: center;
    color: ${Config.theme.colors.black};
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    outline: none;
    padding: 1rem;
    transition: background-color 0.1s;
    position: relative;
    overflow: hidden;
    border-right: 1px solid ${Config.theme.colors.pampas};

    &:hover {
      background-color: ${Config.theme.colors.cashmere};
      color: ${Config.theme.colors.white};
    }

    &.selected,
    &.selected:hover {
      color: ${Config.theme.colors.white};
      background-color: ${Config.theme.colors.pomegranate};
      border-right: none;

      svg {
        color: ${Config.theme.colors.white};
      }

      &:after {
        position: absolute;
        right: -0.5rem;
        top: calc(50% - 0.5rem);
        content: '';
        width: 1rem;
        height: 1rem;
        background-color: ${Config.theme.colors.white};
        transform: rotate(45deg);
      }
    }

    ${media.medium.andBelow`
      img:first-child,
      svg:first-child {
        height: 1.25rem;
        margin-right: .5rem;
        width: 1.25rem;
      }
    `} p {
      line-height: 1;
    }
  }

  &::before,
  &::after {
    border-bottom: 1rem solid;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    margin-left: -1rem;
    position: absolute;
    width: 0;
  }

  &::after {
    border-bottom-color: ${Config.theme.colors.white};
    top: -0.9275rem;
  }

  &::before {
    border-bottom-color: ${Config.theme.colors.pearlBush};
    top: calc(-0.9275rem - 1px);
  }

  ${media.large`
    display: block;
    position: relative;
    box-shadow: none;
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    top: 0;

    &::before,
    &::after {
      display: none;
    }

    li {
      font-size: 1.25rem;
    }
  `};
`;

const Label = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    display: flex;
    align-items: center;
    color: ${Config.theme.colors.white};
    font-size: 1.25rem;
    font-weight: ${Config.theme.fontWeight.medium};
    padding: 1rem;

    img:first-child,
    svg:first-child {
      height: 2rem;
      margin-right: 1rem;
      width: 2rem;
      color: ${Config.theme.colors.white};
    }

    p {
      line-height: 1;
    }
  }

  ${media.large`
    display: none;
  `};
`;

export class Dropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    const selectedItem = React.Children.toArray(props.children).reduce(
      (acc, item, index) => (item.props.selected ? index : acc),
      0
    );

    this.state = {
      open: false,
      selectedItem,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.openDisabled !== state.openDisabled) {
      return {
        ...state,
        openDisabled: props.openDisabled,
        open: false,
      };
    }
    return null;
  }

  handleClick = (index, e) => {
    const { openDisabled } = this.state;

    this.setState(prevState => ({
      open: openDisabled ? prevState.open : !prevState.open,
      selectedItem: index !== null ? index : prevState.selectedItem,
    }));

    if (index === null) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    const { open, selectedItem } = this.state;
    const { children, className } = this.props;
    const childrenWithProps = React.Children.map(children, (child, index) =>
      React.cloneElement(child, {
        onClick: e => this.handleClick(index, e),
        className: `${selectedItem === index && 'selected'}`,
      })
    );

    return (
      <Container className={className}>
        <Label onClickCapture={e => this.handleClick(null, e)}>
          {children[selectedItem]}

          <AnimatedChevron open={open} />
        </Label>

        <ContentWrapper open={open}>
          {React.Children.toArray(childrenWithProps)}
        </ContentWrapper>
      </Container>
    );
  }
}

Dropdown.defaultProps = {
  className: undefined,
  openDisabled: false,
};

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  openDisabled: PropTypes.bool,
};
