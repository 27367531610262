import React, { FC } from 'react';

import appConfig from 'config';
import { logger } from 'services/Logger';
import { LazyScript } from 'components/Molecules/LazyScript';

export const handleSalesForceTrackingLoaded = (): void => {
  if (!window._etmc) return;

  window._etmc.push(['setOrgId', appConfig.salesforce.tracker.orgId]);
  window._etmc.push(['trackPageView']);
};

export const handleSalesForceTrackingLoadError = (): void => {
  logger.error('SalesForceTracking: Failed to load script.');
};

export const SalesForceTrackingScript: FC = () => (
  <LazyScript
    url="https://100001648.collect.igodigital.com/collect.js"
    onLoad={handleSalesForceTrackingLoaded}
    onError={handleSalesForceTrackingLoadError}
  />
);
