import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { location as locationPropTypes } from 'history-prop-types';

export const TrueLocationContext = createContext({
  trueLocation: {},
});

export const TrueLocationContextProvider = ({ children, trueLocation }) => (
  <TrueLocationContext.Provider
    value={{
      trueLocation,
    }}
  >
    {children}
  </TrueLocationContext.Provider>
);

TrueLocationContextProvider.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  trueLocation: PropTypes.shape(locationPropTypes).isRequired,
};

TrueLocationContextProvider.defaultProps = {
  children: null,
};

export const useTrueLocationContext = () => useContext(TrueLocationContext);

export const withTrueLocationContext = Component => props => (
  <TrueLocationContext.Consumer>
    {state => <Component {...props} trueLocation={state.trueLocation} />}
  </TrueLocationContext.Consumer>
);
