import React, { FC } from 'react';

import appConfig from 'config';
import { logger } from 'services/Logger';
import { LazyScript } from 'components/Molecules/LazyScript';

export const handleGoogleAdwordsLoaded = (): void => {
  /* eslint-disable @typescript-eslint/camelcase */
  window.google_conversion_id = appConfig.keys.google.adwords;
  window.google_conversion_label = '-wqdCNnWjoYBEJjg7_sC';
  window.google_remarketing_only = false;
  /* eslint-enable @typescript-eslint/camelcase */
};

export const handleGoogleAdwordsLoadError = (): void => {
  logger.error('GoogleAdwords: Failed to load script.');
};

export const GoogleAdwordsScript: FC = () => (
  <LazyScript
    url="//www.googleadservices.com/pagead/conversion.js"
    onLoad={handleGoogleAdwordsLoaded}
    onError={handleGoogleAdwordsLoadError}
  />
);
