import React, { PropsWithChildren } from 'react';

import { useStyles } from './NavItem.styles';

export const NavButtonItem = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
>(function NavButtonItem({ children, type = 'button', ...props }, ref) {
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/button-has-type
    <button {...props} type={type} ref={ref} className={classes.root}>
      {children}
    </button>
  );
});
