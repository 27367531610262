import React from 'react';
import PropTypes from 'prop-types';

export const MarketingLanding = ({
  components,
  loggedIn,
  isaAccountOpened,
}) => (
  <div>
    {components.map(component => (
      <component.componentType
        {...component.props}
        key={components.indexOf(component)}
        loggedIn={loggedIn}
        isaAccountOpened={isaAccountOpened}
      />
    ))}
  </div>
);

MarketingLanding.propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loggedIn: PropTypes.bool,
  isaAccountOpened: PropTypes.bool,
};

MarketingLanding.defaultProps = {
  loggedIn: false,
  isaAccountOpened: false,
};
