import React, { FC, ReactNode } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  PropTypes,
} from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import capitalize from '@material-ui/core/utils/capitalize';
import {
  useCheckboxStyles,
  useFormControlLabelStyles,
} from './CheckboxFieldRedux.styles';

export interface CheckboxFieldReduxProps {
  // FormControl
  fullWidth?: boolean;
  margin?: PropTypes.Margin;

  // FormControlLabel
  disabled?: boolean;
  label?: ReactNode;

  // Checkbox
  color?: 'primary' | 'secondary' | 'white';
}

export const CheckboxFieldRedux: FC<WrappedFieldProps &
  CheckboxFieldReduxProps> = ({
  input: { value, onChange },
  meta: { submitting },
  margin = 'normal',
  label,
  disabled,
  fullWidth,
  color = 'secondary',
  ...rest
}) => {
  const classes = useFormControlLabelStyles();
  const checkboxClasses = useCheckboxStyles();

  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <FormControlLabel
        disabled={submitting || disabled}
        label={label}
        classes={classes}
        control={
          <Checkbox
            classes={{
              colorSecondary:
                checkboxClasses[
                  `color${capitalize(color)}` as keyof typeof checkboxClasses
                ],
              checked: checkboxClasses.checked,
            }}
            checked={Boolean(value)}
            onChange={onChange}
            color="secondary"
          />
        }
        {...rest}
      />
    </FormControl>
  );
};
