import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Typography } from '@material-ui/core';

import { PropertyCard } from 'components/Molecules/PropertyCard';
import { propertyPaths } from 'router/paths';
import Config from 'config';

import { H2 } from 'components/Atoms/Heading';
import { Section } from 'components/Molecules/Section';
import { ButtonLink } from 'components/Atoms/Button';
import { InvestmentWithProperty } from 'services/Pip/types';
import {
  getInvestmentsRequest,
  selectInvestmentsList,
  toInvestmentSummary,
} from 'store/Investments';
import { useI18n } from 'utils/i18nConnect';

interface StateProps {
  investments: InvestmentWithProperty[];
}

interface DispatchProps {
  dispatchGetInvestmentsRequest: () => void;
}

type Props = StateProps & DispatchProps;

export const FeaturedInvestments: FC<Props> = ({
  investments,
  dispatchGetInvestmentsRequest,
}) => {
  const t = useI18n()('FeaturedInvestments');

  useEffect(() => {
    dispatchGetInvestmentsRequest();
  }, [dispatchGetInvestmentsRequest]);

  if (Config.featuredInvestments.length === 0) {
    return null;
  }

  return (
    <Section color="grey" size="large">
      <Box textAlign="center" mb={8}>
        <H2 paragraph>{t`Featured investments`}</H2>
        <Typography variant="body1">
          {t`You can start building your own diversified property portfolio`}
          <br />
          {t`It's easy, take a look at our selection`}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {investments
          .filter(item => Config.featuredInvestments.includes(item.id))
          .slice(0, 2)
          .map(investment => (
            <Grid item xs={12} md={6} key={investment.id}>
              <PropertyCard {...toInvestmentSummary(investment)} />
            </Grid>
          ))}
      </Grid>

      <Box textAlign="center" mt={8}>
        <ButtonLink
          variant="contained"
          color="primary"
          to={propertyPaths.propertiesAll()}
        >
          {t`See more properties`}
        </ButtonLink>
      </Box>
    </Section>
  );
};

const mapStateToProps = createStructuredSelector<AppState, StateProps>({
  investments: selectInvestmentsList,
});

export default connect(mapStateToProps, {
  dispatchGetInvestmentsRequest: getInvestmentsRequest,
})(FeaturedInvestments);
