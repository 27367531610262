import React, { FC } from 'react';
import clsx from 'clsx';
import { BoxProps } from '@material-ui/core/Box';
import { Box } from '@material-ui/core';
import { useStyles } from './Container.styles';

/**
 * This is to create a consistent container, first step is to integrate with the
 * current implementation. Once the implementation has been centralised, we can
 * flip this component out with a better approach.
 *
 * The container components role is to provide consistent margins horizontally,
 * the container component does not handle vertical rhythm.
 */

export const Container: FC<BoxProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      {children}
    </Box>
  );
};
