import React, { ElementType, FC, ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { H2 } from 'components/Atoms/Heading';
import { Section, SectionProps } from 'components/Molecules/Section';

export interface KeyItem {
  id: string;
  description: ReactNode;
  title: ReactNode;
  Icon: ElementType;
  color: string;
}

interface KeyItemsSectionProps {
  heading: ReactNode;
  items: KeyItem[];
  sectionProps?: Partial<SectionProps>;
}

export const KeyItemsSection: FC<KeyItemsSectionProps> = ({
  heading,
  items,
  sectionProps,
}) => (
  <Section size="large" {...sectionProps}>
    <Box textAlign="center" mb={8}>
      <H2>{heading}</H2>
    </Box>
    <Grid container spacing={4} justify="space-around">
      {items.map(({ Icon, id, description, title, color }) => (
        <Grid key={id} item xs={10} md={7} lg={5} xl={3}>
          <Box textAlign="center" color={color}>
            <Box mb={2}>
              <Icon circleColor="currentColor" height={80} width={80} />
            </Box>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body2" color="textPrimary">
              {description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Section>
);
