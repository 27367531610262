import React from 'react';

const SidebarPortfolio = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3" />
      <path
        d="M10.5 6H6c-.825 0-1.4925.675-1.4925 1.5l-.0075 9c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5V9c0-.825-.675-1.5-1.5-1.5h-6L10.5 6z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarPortfolio.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarPortfolio;
