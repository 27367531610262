// TODO when upgrading to sytled components 4 change to this
// import GlobalStyle from 'global-styles';

import 'global-styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { createStructuredSelector } from 'reselect';

import styled, { ThemeProvider } from 'styled-components';

import { actions as appActions, selectors as appSelectors } from 'store/App';

import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from 'store/SessionProvider';
import Config from 'config';
import { GlobalModal } from 'containers/GlobalModal';
import { CookieBanner, IdleModal } from 'components/Molecules';
import { Video } from 'components/Atoms/Video';
import { toJS } from 'components/Immutable/ToJS';
import { SessionIdle } from 'components/Molecules/SessionIdle';
import { GoogleAdwordsScript } from 'components/Molecules/GoogleAdwordsScript';
import { SalesForceTrackingScript } from 'components/Molecules/SalesForceTrackingScript';

const AppWrapper = styled.div`
  position: relative;
`;

export class App extends React.PureComponent {
  componentDidMount() {
    const { userDetails, findAllAccounts } = this.props;

    userDetails();
    findAllAccounts();

    document.body.classList.add('hydrated');
  }

  render() {
    const {
      children,
      history,
      updateSessionIdle,
      sessionIsIdle,
      sessionIsIdleable,
      video: { isShown: videoIsVisible, url: videoUrl },
      closeVideo,
    } = this.props;

    // TODO https://britishpearl.atlassian.net/browse/PIT-1974
    // Investigate how we load 3rd party scripts
    return (
      <ThemeProvider theme={Config.theme}>
        <>
          {/* TODO when upgrading to sytled components 4 add this: <GlobalStyle /> */}
          <AppWrapper>{React.Children.toArray(children)}</AppWrapper>

          {process.env.NODE_ENV === 'production' &&
            Config.userTracking.enabled && (
              <>
                <SalesForceTrackingScript />
                <GoogleAdwordsScript />
              </>
            )}

          {Config.euCookieBannerEnabled && !process.env.testContext && (
            <CookieBanner />
          )}

          <GlobalModal />

          {Config.sessionIdle.enabled && sessionIsIdleable && (
            <SessionIdle
              idle={sessionIsIdle}
              onIdle={() => updateSessionIdle(true)}
              timeout={Config.sessionIdle.idleTimeout}
            >
              <IdleModal
                autologoutTimeout={Config.sessionIdle.autologoutTimeout}
                close={() => updateSessionIdle(false)}
                inactiveLogOut={() => history.push('/logged-out?inactive=true')}
                logout={() => history.push('/logged-out')}
              />
            </SessionIdle>
          )}
          {videoUrl && (
            <Video
              visible={videoIsVisible}
              url={videoUrl}
              onClose={closeVideo}
            />
          )}
        </>
      </ThemeProvider>
    );
  }
}

App.defaultProps = {
  findAllAccounts: () => {},
  userDetails: () => {},
};

const mapStateToProps = createStructuredSelector({
  sessionIsIdle: sessionSelectors.makeSelectSessionIsIdle(),
  sessionIsIdleable: sessionSelectors.makeSelectSessionIsIdleable(),
  video: appSelectors.selectVideo(),
});

const mapDispatchToProps = dispatch => ({
  userDetails: () => dispatch(sessionActions.userDetailsRequest()),
  findAllAccounts: () => dispatch(sessionActions.accountsFindAllRequest()),
  updateSessionIdle: bool => dispatch(sessionActions.sessionIdle(bool)),
  closeVideo: () => dispatch(appActions.closeVideo()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  toJS
)(App);
