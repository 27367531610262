import i18n from 'es2015-i18n-tag';
import {
  blogPaths,
  commonPaths,
  glossaryPaths,
  investorPaths,
  propertyPaths,
} from 'router/paths';

import { MenuNavLinkItem } from './types';

const t = i18n('NavLinks');

export const authenticatedUserNavLinks: MenuNavLinkItem[] = [
  { to: investorPaths.dashboard(), label: t`Dashboard` },
  { to: investorPaths.notices(), label: t`Notices` },
  {
    to: [
      {
        to: investorPaths.fundWallet(),
        label: t`Fund wallet`,
      },
      {
        to: investorPaths.withdrawFunds(),
        label: t`Withdraw`,
      },
    ],
    label: t`My wallet`,
  },
  {
    to: [
      {
        to: investorPaths.mainAccount(),
        label: t`Main account`,
      },
      { to: investorPaths.isaAccount(), label: t`ISA` },
      {
        to: investorPaths.taxStatement(),
        label: t`Tax statement`,
      },
    ],
    label: t`My Account`,
  },
  { to: investorPaths.profile(), label: t`Profile` },
  { to: investorPaths.documents(), label: t`Documents` },
];

export const desktopNavLinks: MenuNavLinkItem[] = [
  {
    to: [
      {
        to: commonPaths.isa(),
        label: t`Innovative Finance ISA`,
      },
      { to: commonPaths.shares(), label: t`Invest in shares` },
      { to: commonPaths.loans(), label: t`Invest in loans` },
      { to: commonPaths.howItWorks(), label: t`How it works` },
    ],
    label: t`Products`,
  },
  { to: propertyPaths.propertiesAll(), label: t`Properties` },
  { to: commonPaths.aboutUs(), label: t`About us` },
  {
    to: [
      { to: commonPaths.faq(), label: t`FAQs` },
      { to: commonPaths.contact(), label: t`Contact` },
      { to: commonPaths.keyRisks(), label: t`Key risks` },
      { to: glossaryPaths.glossaryAll(), label: t`Glossary` },
    ],
    label: t`Support`,
  },
  { to: blogPaths.blogAll(), label: t`Blog` },
];
