import React from 'react';

const Lock = props => (
  <svg viewBox="0 0 29 36" fill="none" {...props}>
    <path
      d="M4.21875 13.2188H6.7526V7.43695C6.7526 3.33619 10.1592 0 14.3464 0C18.5335 0 21.9401 3.33619 21.9401 7.43695V13.2188H24.4688C26.795 13.2188 28.6875 15.1113 28.6875 17.4375V31.7812C28.6875 34.1075 26.795 36 24.4688 36H4.21875C1.89253 36 0 34.1075 0 31.7812V17.4375C0 15.1113 1.89253 13.2188 4.21875 13.2188ZM19.1276 7.43695C19.1276 4.887 16.9827 2.8125 14.3464 2.8125C11.71 2.8125 9.5651 4.887 9.5651 7.43695V13.2188H19.1276V7.43695ZM2.8125 31.7812C2.8125 32.5567 3.44334 33.1875 4.21875 33.1875H24.4688C25.2442 33.1875 25.875 32.5567 25.875 31.7812V17.4375C25.875 16.6621 25.2442 16.0312 24.4688 16.0312H4.21875C3.44334 16.0312 2.8125 16.6621 2.8125 17.4375V31.7812Z"
      fill="currentColor"
    />
    <path
      d="M14.3438 20.1094C15.7806 20.1094 16.9453 21.2741 16.9453 22.7109C16.9453 23.6284 16.47 24.4343 15.7526 24.8975V27.9844C15.7526 28.761 15.123 29.3906 14.3464 29.3906C13.5698 29.3906 12.9401 28.761 12.9401 27.9844V24.9008C12.2198 24.4382 11.7422 23.6306 11.7422 22.7109C11.7422 21.2741 12.9069 20.1094 14.3438 20.1094Z"
      fill="currentColor"
    />
  </svg>
);

export default Lock;
