export function scrollTo(x, y) {
  if (!window) {
    return;
  }

  window.scrollTo(x, y);
}

export function scrollToTop() {
  scrollTo(0, 0);
}

export function scrollIntoView(elem) {
  if (elem && window) {
    elem.scrollIntoView();
    // Scroll down a bit so that the header won't cover the element
    scrollTo(window.pageXOffset, window.pageYOffset - 100);
  }
}

const isObject = value => !Array.isArray(value) && typeof value === 'object';

const isEmpty = value =>
  value === null ||
  value === undefined ||
  (Array.isArray(value) && value.length === 0) || // Empty array
  (value.trim && value.trim() === '') || // Empty string
  (isObject(value) && Object.keys(value).length === 0); // Empty object

/*
 Returns the keys that contain non-emtpy values.
 Concatenates keys if the values are objects.
 For example:
 {
   field1: 'Required',
   field2: '',
   field3: [],
   address: {
     field1: '',
     field2: 3,
     field3: [ 'error1', 'error2' ]
   }
 }
 will produce the following array:
 [ 'field1', 'address.field2', 'address.field3' ]
*/
const extractNonEmptyKeys = obj =>
  Object.keys(obj).reduce((acc, currentKey) => {
    const value = obj[currentKey];
    if (isObject(value)) {
      const newKeys = extractNonEmptyKeys(value)
        .filter(Boolean)
        .map(childKey => `${currentKey}.${childKey}`);
      if (!isEmpty(newKeys)) {
        return [...acc, ...newKeys];
      }
    } else if (!isEmpty(value)) {
      return [...acc, currentKey];
    }
    return acc;
  }, []);

export function scrollToFirstError(errors) {
  // Solution based on the implementation proposed in:
  // https://github.com/erikras/redux-form/issues/2365#issuecomment-325013116
  // In a nutshell, since errors object is in weird order we use querySelector
  // for all the names we want and the browser takes care of returning the
  // first one for us.
  const errorElement = document.querySelector(
    extractNonEmptyKeys(errors)
      .map(fieldName => `[name="${fieldName}"]`)
      .join(',')
  );
  if (errorElement) {
    scrollIntoView(errorElement);
  }
}
