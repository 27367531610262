export const INVESTOR_WITHDRAWALS_FIND_REQUEST =
  'InvestorWithdrawals/FIND_REQUEST';
export const INVESTOR_WITHDRAWALS_FIND_SUCCESS =
  'InvestorWithdrawals/FIND_SUCCESS';
export const INVESTOR_WITHDRAWALS_FIND_ERROR = 'InvestorWithdrawals/FIND_ERROR';

export const INVESTOR_WITHDRAWALS_CANCEL_REQUEST =
  'InvestorWithdrawals/CANCEL_REQUEST';
export const INVESTOR_WITHDRAWALS_CANCEL_SUCCESS =
  'InvestorWithdrawals/CANCEL_SUCCESS';
export const INVESTOR_WITHDRAWALS_CANCEL_ERROR =
  'InvestorWithdrawals/CANCEL_ERROR';
