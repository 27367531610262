import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RequireNotAuth } from './Auth';

import Loader from './Loader';

export default ({ match }) => (
  <RequireNotAuth>
    <Switch>
      <Route exact path={`${match.url}`} component={Loader} />
      <Route exact path={`${match.url}/resend-email`} component={Loader} />
      <Route exact path={`${match.url}/confirm/:token`} component={Loader} />
      <Redirect to={match.url} />
    </Switch>
  </RequireNotAuth>
);
