import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Config from 'config';

import { i18nConnect } from 'utils/i18nConnect';
import media from 'utils/responsive';

import { makeSelectUser } from 'store/SessionProvider/selectors';

import Buttons from 'components/Pages/Home/components/Buttons';

const Content = styled.div`
  color: ${Config.theme.colors.white};

  h1 {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: ${Config.theme.fontWeight.medium};
    margin-bottom: 0.625rem;
  }

  h2 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
    line-height: 120%;
    font-weight: ${Config.theme.fontWeight.book};
  }

  .emphasis {
    font-weight: ${Config.theme.fontWeight.medium};
  }

  .description {
    font-size: 0.9375rem;
    line-height: 1.375rem;
  }

  .button {
    margin-bottom: 0.625rem;
  }

  .button:last-of-type {
    margin-bottom: 1rem;
  }

  .conditionalSmallBreak {
    &::after {
      content: '';
      display: block;
    }
  }

  ${media.medium`
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1.875rem;
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: 1.875rem;
    }

    .conditionalSmallBreak {
      display: none;
    }

    .conditionalMediumBreak {
      &::after {
        content: '';
        display: block;
      }
    }
  `};

  ${media.wide`
    h1 {
      font-size: 3rem;
      margin-bottom: 1.875rem;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1.875rem;
    }
  `};
`;

const HeroContent = ({ i18n }) => (
  <Content>
    <h1>
      {i18n('LoansPage')`Hassle-free`}{' '}
      <span className="conditionalSmallBreak" />
      <span className="conditionalMediumBreak" />
      {i18n('LoansPage')`property investing `}
    </h1>
    <h2>
      <span>
        {i18n('LoansPage')`Earn up to `}{' '}
        <span className="emphasis">{i18n('LoansPage')`4.30%`}</span>{' '}
        {i18n('LoansPage')`p.a.`} <br />
        {i18n('LoansPage')`on our ISA-eligble property investments.`} <br />
        {i18n('LoansPage')`*T&Cs apply.`}
      </span>
    </h2>

    <Buttons
      types={[
        {
          label: i18n('HomePageHero')`View properties`,
          appearance: 'light',
          buttonType: 'internalLink',
          buttonDestination: '/properties',
          ghost: true,
        },
        {
          label: i18n('HomePageHero')`Join`,
          appearance: 'default',
          buttonType: 'internalLink',
          buttonDestination: '/register',
        },
      ]}
    />
  </Content>
);

HeroContent.propTypes = {
  i18n: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps)(i18nConnect(HeroContent));
