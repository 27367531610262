import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useI18n } from 'utils/i18nConnect';

export interface PropertyCardInfoItemProps {
  value: number;
  soldOut: boolean;
  investmentType: ReactNode;
  investmentTypeReturn: ReactNode;
}

export const PropertyCardInfoItem: FC<PropertyCardInfoItemProps> = ({
  value,
  soldOut,
  investmentType,
  investmentTypeReturn,
}) => {
  const t = useI18n()('components.PropertyCardInfoItem');
  return (
    <Box
      bgcolor="primary.main"
      color="common.white"
      p={2.5}
      textAlign="center"
      height="100%"
    >
      <Typography variant="h3" component="div">
        {soldOut ? investmentType : `${value.toFixed(2)}%`}
      </Typography>
      <Typography variant="body2" component="div">
        {soldOut ? t`Sold Out` : investmentTypeReturn}
      </Typography>
    </Box>
  );
};
