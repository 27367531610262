import * as type from './actionTypes';

// Triggered whenever the user clicks the register submit button
export const registerSubmit = (payload, token) => ({
  type: type.REGISTER_SUBMIT,
  payload,
  token,
});

// Triggered whenever a register request is dispatched from whenever point in the code
export const registerRequest = payload => ({
  type: type.REGISTER_REQUEST,
  payload,
});

// triggered when the register has succeded
export const registerSuccess = user => ({
  type: type.REGISTER_SUCCESS,
  user,
});

// triggered when the registraion failed
export const registerError = errors => ({
  type: type.REGISTER_ERROR,
  error: true,
  payload: errors,
});

export const registerReset = () => ({
  type: type.USERDETAILS_RESET,
});

// Triggered whenever the user clicks the login submit button
export const loginSubmit = payload => ({
  type: type.LOGIN_SUBMIT,
  payload,
});

// Triggered whenever a login request is dispatched from whenever point in the code
export const loginRequest = payload => ({
  type: type.LOGIN_REQUEST,
  payload,
});

// triggered when the login has succeded
export const loginSuccess = (user = null, address) => ({
  type: type.LOGIN_SUCCESS,
  user,
  address,
});

// triggered when the login failed
export const loginError = errors => ({
  type: type.LOGIN_ERROR,
  error: true,
  payload: errors,
});

// triggered to logout the user
export const logout = () => ({
  type: type.LOGOUT,
});

// in case of logout error (almost not possible anyway)
export const logoutError = errors => ({
  type: type.LOGOUT_ERROR,
  error: true,
  payload: errors,
});

export const userDetailsUpdateSuccess = phoneNumber => ({
  type: type.USERDETAILS_UPDATE_REQUEST_SUCCESS,
  payload: phoneNumber,
});

// Triggered whenever the user clicks the resend verification email submit button
export const resendVerificationEmailSubmit = (payload, token) => ({
  type: type.RESEND_VERIFICATION_EMAIL_SUBMIT,
  payload,
  token,
});

// Triggered whenever a resend verification email request is dispatched from whenever point in the code
export const resendVerificationEmailRequest = payload => ({
  type: type.RESEND_VERIFICATION_EMAIL_REQUEST,
  payload,
});

// triggered when the resend verification email has succeded
export const resendVerificationEmailSuccess = () => ({
  type: type.RESEND_VERIFICATION_EMAIL_SUCCESS,
});

// triggered when the registraion failed
export const resendVerificationEmailError = errors => ({
  type: type.RESEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: errors,
});

// request for user details
export const userDetailsRequest = (force = false) => ({
  type: type.USERDETAILS_REQUEST,
  force,
});

export const userDetailsSuccess = (user = null) => ({
  type: type.USERDETAILS_SUCCESS,
  user,
});

export const userDetailsError = errors => ({
  type: type.USERDETAILS_ERROR,
  error: true,
  payload: errors,
});

export const forgotPasswordResetRequest = email => ({
  type: type.FORGOT_PASSWORD_RESET_REQUEST,
  email,
});

export const forgotPasswordResetSuccess = () => ({
  type: type.FORGOT_PASSWORD_RESET_SUCCESS,
});

export const forgotPasswordResetError = errors => ({
  type: type.FORGOT_PASSWORD_RESET_ERROR,
  error: true,
  payload: errors,
});

export const forgotPasswordResetReset = () => ({
  type: type.FORGOT_PASSWORD_RESET_RESET,
});

export const forgotPasswordChangeRequest = data => ({
  type: type.FORGOT_PASSWORD_CHANGE_REQUEST,
  data,
});

export const forgotPasswordChangeSuccess = () => ({
  type: type.FORGOT_PASSWORD_CHANGE_SUCCESS,
});

export const forgotPasswordChangeError = errors => ({
  type: type.FORGOT_PASSWORD_CHANGE_ERROR,
  error: true,
  payload: errors,
});

export const accountsFindAllRequest = () => ({
  type: type.ACCOUNTS_FINDALL_REQUEST,
});

export const accountsFindAllSuccess = data => ({
  type: type.ACCOUNTS_FINDALL_SUCCESS,
  data,
});

export const accountsFindAllError = errors => ({
  type: type.ACCOUNTS_FINDALL_ERROR,
  error: true,
  payload: errors,
});

export const sessionIdle = status => ({
  type: type.SESSION_IDLE,
  status,
});

export const updateNiNumberRequest = niNumber => ({
  type: type.UPDATE_NI_NUMBER_REQUEST,
  payload: { niNumber },
});

export const updateNiNumberSuccess = niNumber => ({
  type: type.UPDATE_NI_NUMBER_SUCCESS,
  payload: { niNumber },
});
