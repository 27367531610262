import React, { FC } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import { NavLink } from 'components/Atoms/Link';

import { MenuNavLink as NavLinkType } from '../../types';

import { useStyles, usePaperStyles } from './NavigationMenu.styles';

interface NavigationMenuProps {
  navLinks: NavLinkType[];
  component?: React.ElementType;
}

export const NavigationMenu: FC<NavigationMenuProps> = ({
  children,
  navLinks,
  component: Component = 'button',
}) => {
  const [open, setOpen] = React.useState(false);
  const triggerRef = React.useRef<HTMLElement>(null);

  const classes = useStyles();
  const paperClasses = usePaperStyles();

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      triggerRef.current &&
      triggerRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Component onClick={handleToggle} ref={triggerRef}>
        {children}
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Component>
      <Popper
        open={open}
        anchorEl={triggerRef.current}
        role={undefined}
        placement="bottom"
        className={classes.popper}
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: false,
          },
          offset: {
            enabled: true,
            offset: '0, -10px',
          },
          arrow: {
            enabled: true,
            element: `.${classes.arrow}`,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top',
            }}
            timeout={160}
          >
            <Paper elevation={3} classes={paperClasses}>
              <span className={classes.arrow} />

              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} data-test-id="menu-list">
                  {navLinks.map(({ to, label: linkLabel }) => (
                    <MenuItem
                      key={to}
                      component={NavLink}
                      to={to}
                      underline="none"
                      className={classes.link}
                    >
                      {linkLabel}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
