import React, { PropsWithChildren } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { NavLinkProps } from './types';

export const NavLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<NavLinkProps>
>(function NavLink(
  { children, color = 'inherit', underline = 'always', ...props },
  ref
) {
  return (
    <MuiLink
      component={ReactRouterNavLink}
      color={color}
      underline={underline}
      ref={ref}
      {...props}
    >
      {children}
    </MuiLink>
  );
});
