import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'utils/responsive';

import { stylesForFit, stylesForPlace } from '../utils';

export const Wrapper = styled.div`
  position: relative;
  height: 23.94rem;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;

  ${media.medium`
    height: 26.56rem;
  `} ${media.large`
    height: 34.19rem;
  `};
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ parentWidth }) => parentWidth || '65%'};
  height: 100%;
`;

export const HeroContent = styled.div`
  align-content: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  right: 0;
  min-width: 9.375rem;
  position: absolute;
  z-index: 200;
  margin: auto;
  width: 80%;
  max-width: 570px;
  left: 10%;

  ${media.medium`
  left: 16%;
`}

  ${media.large`
    right: inherit;
  `}

  ${media.wide`
    max-width:35rem;
  `};
`;

export const HeroImage = styled.div`
  ${({ src, config, currentBreakpoint }) =>
    config.type === 'place'
      ? stylesForPlace(src, currentBreakpoint, config)
      : stylesForFit(src, currentBreakpoint, config)}};
`;

export const GradientBackground = ({ currentBreakpoint }) => (
  <svg height="100%" viewBox="0 0 2391 863">
    <defs>
      {currentBreakpoint === 'wide' ? (
        <linearGradient
          x1="100%"
          y1="5.45318626e-06%"
          x2="93.8858334%"
          y2="106.354577%"
          id="linearGradient-1"
        >
          <stop stopColor="#7A7CA2" offset="0%" />
          <stop stopColor="#449ABB" offset="53.0387%" />
          <stop stopColor="#00A88E" offset="99.4475%" />
        </linearGradient>
      ) : (
        <linearGradient
          id="linearGradient-1"
          x1="0"
          y1="0"
          x2="863.781"
          y2="61.5154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7A7CA2" />
          <stop offset="0.430387" stopColor="#449ABB" />
          <stop offset="0.994475" stopColor="#00A88E" />
        </linearGradient>
      )}
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="banner-shade" fill="url(#linearGradient-1)">
        <path
          d="M0.067606,478.538 C3.4779,210.496 123.369,-4.7061e-05 123.369,-4.7061e-05 L2390.25083,-4.7061e-05 L2390.25083,863 L94.5001,863 L77.354,863 C41.4876,775.869 -1.9479,636.953 0.067606,478.538 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

GradientBackground.propTypes = {
  currentBreakpoint: PropTypes.string,
};

GradientBackground.defaultProps = {
  currentBreakpoint: '',
};

export const ContentContainer = styled.div`
  height: 100%;
  width: 70%;
  z-index: 100;
  position: relative;
  ${media.medium`
    width: 62%;
  `}

  ${media.large`
    width: 50%;
  `}

  ${media.wide`
    width: 50%;
  `}
`;
