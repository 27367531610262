import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    pointerEvents: 'none',
    position: 'absolute',
    height: '100%',
    margin: '0 !important',
    maxHeight: 'inherit',
  },
}));
