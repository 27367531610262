// TODO: https://britishpearl.atlassian.net/browse/PIT-1989
export const propertiesData = [
  {
    id: 36,
    name: 'Halifax',
    LTV: 50 /* Percent */,
    exitDate: '2021-11-30',
    term: 3 /* Years */,
    rate: 3.75 /* Percent P.A */,
    dividendRate: 3.15 /* Percent */,
    capitalGain: 4.95 /* Percent */,
    shareReturn: 8.1 /* Percent */,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 3,
    name: 'Lancaster',
    LTV: 70 /* Percent */,
    exitDate: '2023-07-31',
    term: 5 /* Years */,
    rate: 4.4 /* Percent P.A */,
    dividendRate: 3.98 /* Percent */,
    capitalGain: 3.78 /* Percent */,
    shareReturn: 7.76 /* Percent */,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 69,
    name: 'Hereford',
    LTV: 50 /* Percent */,
    exitDate: '2022-01-31',
    term: 3 /* Years */,
    rate: 3.75 /* Percent P.A */,
    dividendRate: 3.01 /* Percent */,
    capitalGain: 8.74 /* Percent */,
    shareReturn: 11.74 /* Percent */,
    defaultLoans: true,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 35,
    name: 'Portsmouth No. 2',
    LTV: 60 /* Percent */,
    exitDate: '2023-09-30',
    term: 5 /* Years */,
    rate: 4.2 /* Percent P.A */,
    dividendRate: 4.39 /* Percent */,
    capitalGain: 5.11 /* Percent */,
    shareReturn: 9.5 /* Percent */,
    defaultShares: true /* Use as ISA calculator default */,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 68,
    name: 'Lincoln',
    LTV: 65 /* Percent */,
    exitDate: '2024-02-29',
    term: 5 /* Years */,
    rate: 4.3 /* Percent P.A */,
    dividendRate: 4.29 /* Percent */,
    capitalGain: 4.85 /* Percent */,
    shareReturn: 9.13 /* Percent */,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 1,
    name: 'Acton',
    LTV: 50 /* Percent */,
    exitDate: '2022-03-31',
    term: 5 /* Years */,
    rate: 4.0 /* Percent P.A */,
    dividendRate: 1.02 /* Percent */,
    capitalGain: 4.36 /* Percent */,
    shareReturn: 5.38 /* Percent */,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 37,
    name: 'Andover',
    LTV: 60 /* Percent */,
    exitDate: '2021-10-31',
    term: 3 /* Years */,
    rate: 3.95 /* Percent P.A */,
    dividendRate: 1.23 /* Percent */,
    capitalGain: 11.02 /* Percent */,
    shareReturn: 12.25 /* Percent */,
    hasShares: true,
    hasLoans: true,
  },
  {
    id: 2,
    name: 'Portsmouth No. 1',
    LTV: 70 /* Percent */,
    exitDate: '2023-07-31',
    term: 5 /* Years */,
    rate: 4.4 /* Percent P.A */,
    dividendRate: 7.11 /* Percent */,
    capitalGain: 6.28 /* Percent */,
    shareReturn: 13.39 /* Percent */,
    hasShares: true,
    hasLoans: true,
  },
];
