import i18n from 'es2015-i18n-tag';

export const messages = {
  required: i18n('validation.required')`is required`,
  integer: i18n('validation.required')`has to be whole number`,
  positive: i18n('validation.positive')`has to be greater than 0`,
  dateInFuture: i18n('validation.dateInFuture')`must be a future date`,
  validEmail: i18n('validation.validEmail')`must be a valid email`,
  validDate: i18n('validation.validDate')`must be a valid date`,
  minCharacterLength: ({ min }: { min: number }) =>
    i18n('validation.minLen')`must be at least ${min} characters`,
  containUppercaseLetter: i18n(
    'validation.containUppercaseChar'
  )`must contain at least one uppercase letter`,
  containDigit: i18n(
    'validation.containUppercaseChar'
  )`must contain at least one digit`,
  validName: i18n('validation.validName')`must be a valid name`,
  agreeToPrivacy: i18n(
    'validation.agreeToPrivacy'
  )`must agree to the privacy notice`,
  agreeToTerms: i18n('validation.agreeToTerms')`must agree to the terms of use`,
};
