import React from 'react';

import Config from 'config';

const TickShield = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <path
      d="M20.1996 4.313c.7528-.2135 1.4753.3365 1.4753 1.0877v6.2022c0 3.968-1.755 7.018-4.661 9.243-1.002.767-2.073 1.38-3.147 1.856-.379.168-.732.307-1.052.419-.115.04-.217.074-.307.102l-.14.042c-.096.024-.194.037-.292.037-.099 0-.198-.013-.307-.042-.028-.008-.068-.02-.1248-.0378-.0903-.0285-.193-.0625-.307-.1027-.32-.1124-.6734-.2515-1.052-.4194-1.073-.476-2.1444-1.089-3.1464-1.856-2.906-2.2243-4.66-5.2737-4.66-9.2423V5.4c0-.7627.7353-1.308 1.4795-1.086 1.989.6744 5.139-.416 7.183-3.3576.437-.629 1.4332-.629 1.8702 0 1.719 2.474 3.7913 3.484 5.976 3.484.3332 0 .64-.026.9114-.069.156-.025.29-.055.3-.0585zm-1.211 1.088c-2.4894 0-4.8596-1.155-6.7643-3.896-.0552-.0793-.2385-.0793-.2936 0-2.285 3.2882-5.8797 4.5327-8.2623 3.7245-.1128-.0336-.2284.052-.2284.1712v6.2022c0 3.645 1.6 6.426 4.2842 8.48.9376.717 1.9442 1.294 2.952 1.741.3546.157.6843.287.9808.391.1788.063.2864.096.3674.12.0175.004.036.007.0526.007.1346-.031.242-.065.421-.128.2965-.104.626-.234.9807-.391 1.008-.447 2.0144-1.023 2.952-1.741 2.6843-2.0548 4.2842-4.8358 4.2842-8.4808V5.4c0-.114-.112-.2005-.227-.172-.252.08-.844.1724-1.5.1724z"
      fill={fill2}
    />
    <path
      d="M8.5794 12.9333c-.1874-.1874-.4914-.1874-.6788 0-.1875.1875-.1875.4914 0 .6788l2.691 2.691c.204.205.541.184.7182-.044l5.651-7.265c.1627-.209.125-.511-.0843-.673-.2092-.163-.5108-.125-.6736.084l-5.318 6.836-2.307-2.3062z"
      fill={fill}
    />
  </svg>
);

TickShield.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default TickShield;
