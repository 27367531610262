// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place

import { startSubmit, stopSubmit } from 'redux-form/immutable';

import {
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { logger } from 'services/Logger';
import { Account, Auth, User } from 'services/Pip';

import { ACCOUNT_OVERVIEW_REQUEST } from 'store/InvestorDashboard/actionTypes';

import { fbTrack, identify, track } from 'utils/analytics';

import { sagaPromise } from 'utils/saga';

import { GLOBAL_MODAL_NAMES, actions as globalActions } from 'store/App';
import { getPortfolioPropertyFindAllFlow } from '../Portfolio/sagas';

import {
  accountsFindAllError,
  accountsFindAllSuccess,
  forgotPasswordChangeError,
  forgotPasswordChangeSuccess,
  forgotPasswordResetError,
  forgotPasswordResetSuccess,
  loginError,
  loginRequest,
  loginSuccess,
  logoutError,
  registerError,
  registerRequest,
  registerSuccess,
  resendVerificationEmailError,
  resendVerificationEmailRequest,
  resendVerificationEmailSuccess,
  userDetailsError,
  userDetailsSuccess,
  updateNiNumberSuccess,
} from './actions';
import * as type from './actionTypes';
import { makeSelectUser, selectUserReAccreditationAllowed } from './selectors';

/**
 * Effect to handle registration
 * @param user The user to create
 * @param token Captcha token
 */
export function* register(user, token) {
  try {
    yield put(registerRequest(user));
    return yield call(User.create, user, token);
  } catch (error) {
    yield call(logger.error, error);
    yield put(registerError(error));
  }

  return false;
}

/**
 * Effect to handle logging out
 */
export function* unauthorize() {
  try {
    return yield call([Auth, Auth.logout]);
  } catch (error) {
    yield call(logger.error, error);
    yield put(logoutError(error));
  }

  return null;
}

export function* updateNiNumber({ payload: { niNumber } }) {
  try {
    yield call(User.edit, { nationalInsuranceNumber: niNumber });
    yield put(updateNiNumberSuccess(niNumber));
  } catch (e) {
    yield call(logger.error, e);
  }
}

/**
 * Effect to handle resending of verification email
 * @param email The email address to resend verification email to
 * @param token Captcha token
 */
export function* resendVerificationEmail(payload, token) {
  try {
    yield put(resendVerificationEmailRequest(payload, token));
    return yield call(
      [User, User.resendVerificationEmail],
      payload.email,
      token
    );
  } catch (error) {
    yield call(logger.error, error);
    yield put(resendVerificationEmailError(error));
  }

  return false;
}

/**
 * User account balance
 */
export function* accountsFindAll() {
  try {
    return yield call([Account, Account.findAll]);
  } catch (error) {
    yield call(logger.error, error);
    yield put(accountsFindAllError(error));
  }

  return false;
}

/**
 * Effect to handle downloading user details
 */
export function* details() {
  try {
    return yield call(User.get);
  } catch (error) {
    yield call(logger.error, error);
    yield put(userDetailsError(error));
  }

  return false;
}

/**
 * Effect to handle authorization
 * @param email The email of the user
 * @param password The password of the user
 */
export function* authorize({ email, password }) {
  try {
    yield put(loginRequest({ email, password }));
    const token = yield call([Auth, Auth.accessToken], email, password);
    if (token) {
      yield accountsFindAll();
      return yield details();
    }
  } catch (error) {
    yield call(logger.error, error);
    // If we get an error we send Redux the appropriate action and return
    yield put(loginError(error));
  }

  return false;
}

/**
 * Forgot password request
 */
export function* forgotPasswordReset(email) {
  try {
    return yield call(User.forgotPasswordReset, email);
  } catch (error) {
    yield call(logger.error, error);
    yield put(forgotPasswordResetError(error));
  }

  return false;
}

/**
 * Password change request
 */
export function* forgotPasswordChange(data) {
  try {
    return yield call(User.forgotPasswordChange, data);
  } catch (error) {
    yield call(logger.error, error);
    yield put(forgotPasswordChangeError(error));
  }

  return false;
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
export const logoutFlow = sagaPromise(function*() {
  try {
    yield call(unauthorize);
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }
});

/**
 * Register saga
 */
export const registerFlow = sagaPromise(function*(response) {
  try {
    yield put(startSubmit('RegisterForm'));

    const { user, error } = yield race({
      user: call(register, response.payload, response.token),
      error: take(type.REGISTER_ERROR),
    });

    yield put(stopSubmit('RegisterForm', error ? error.payload : undefined));

    if (error) {
      return false;
    }

    yield call(identify, user);
    yield call(track, 'User', 'Create account');
    yield call(fbTrack, 'Lead', {
      // eslint-disable-next-line camelcase
      content_name: 'Create account',
    });
    if (user.referrer) {
      yield call(track, 'User', 'Register with referral code', {
        referrer: user.referrer,
      });
      yield call(fbTrack, 'Lead', {
        // eslint-disable-next-line camelcase
        content_name: 'Register with referral code',
        value: user.referrer,
      });
    }

    yield put(registerSuccess(user));
    return true;
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }
});

/**
 * Login saga
 */
export const loginFlow = sagaPromise(function*(response) {
  try {
    // start submitting the form
    yield put(startSubmit('LoginForm'));

    // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
    // lead to a race condition. This is unlikely, but just in case, we call `race` which
    // returns the "winner", i.e. the one that finished first
    const winner = yield race({
      // put a login request
      user: call(authorize, response.payload),
      /* eslint-disable camelcase */
      error_details: take(type.USERDETAILS_ERROR),
      error_login: take(type.LOGIN_ERROR),
      error_accounts: take(type.ACCOUNTS_FINDALL_ERROR),
      /* eslint-enable camelcase */
      logout: take(type.LOGOUT),
    });
    const error = winner.error_login || winner.error_details || winner.accounts;

    if (error) {
      // finalize the form
      yield put(stopSubmit('LoginForm', { _error: error.payload }));
    } else {
      // finalize the form
      yield put(stopSubmit('LoginForm'));

      // If `authorize` was the winner...
      if (winner.user) {
        yield call(identify, winner.user);
        yield call(track, 'Account', 'Log in');
        yield put(loginSuccess(winner.user));

        const { accounts } = yield race({
          accounts: call(accountsFindAll),
          error: take(type.ACCOUNTS_FINDALL_ERROR),
        });

        if (accounts) {
          yield put(accountsFindAllSuccess(accounts));
        }

        return true;
      }
    }
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }

  return false;
});

/**
 * Resend verification email saga
 */
export const resendVerificationEmailFlow = sagaPromise(function*(response) {
  try {
    const FORM = 'ResendVerificationEmailForm';

    yield put(startSubmit(FORM));

    const { error } = yield race({
      email: call(resendVerificationEmail, response.payload, response.token),
      error: take(type.RESEND_VERIFICATION_EMAIL_ERROR),
    });

    if (error) {
      yield put(stopSubmit(FORM, error.payload));
      return false;
    }

    yield put(stopSubmit(FORM));
    yield put(resendVerificationEmailSuccess());
    return true;
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }
});

/**
 * Download or get from state user details
 */
export const userDetailsFlow = sagaPromise(function*({ force }) {
  try {
    const existingUser = yield select(makeSelectUser());
    if (existingUser && !force) {
      yield put(userDetailsSuccess(existingUser));
      return true;
    }

    const { user, error } = yield race({
      // put a login request
      user: call(details),
      error: take(type.USERDETAILS_ERROR),
      logout: take(type.LOGOUT),
    });

    if (user) {
      yield put(userDetailsSuccess(user));
      return true;
    }
    if (error) {
      yield put(userDetailsError(error));
    } else {
      yield put(userDetailsError({ _error: "Can't get user details" }));
    }
    return false;
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }
});

/**
 * Forgot password
 */
export function* forgotPasswordResetFlow({ email }) {
  try {
    const winner = yield race({
      success: call(forgotPasswordReset, email),
      error: take(type.FORGOT_PASSWORD_RESET_ERROR),
    });

    if (winner.error) {
      yield put(stopSubmit('ForgotPasswordResetForm', winner.error.payload));
    } else {
      yield put(stopSubmit('ForgotPasswordResetForm'));

      if (winner.success) {
        yield call(track, 'Account', 'Forgot password');
        yield put(forgotPasswordResetSuccess());
      }
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

/**
 * Change password
 */
export const forgotPasswordChangeFlow = sagaPromise(function*({ data }) {
  try {
    yield put(startSubmit('ForgotPasswordChangeForm'));

    const winner = yield race({
      success: call(forgotPasswordChange, data),
      error: take(type.FORGOT_PASSWORD_CHANGE_ERROR),
    });

    if (winner.error) {
      yield put(stopSubmit('ForgotPasswordChangeForm', winner.error.payload));
    } else {
      yield put(stopSubmit('ForgotPasswordChangeForm'));

      if (winner.success) {
        yield call(track, 'Account', 'Change password');
        yield put(forgotPasswordChangeSuccess());
        return true;
      }
    }
    return false;
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }
});

/**
 * User accounts list
 */
export const accountsFlow = sagaPromise(function*() {
  try {
    const { accounts } = yield race({
      accounts: call(accountsFindAll),
      error: take(type.ACCOUNTS_FINDALL_ERROR),
    });

    if (accounts) {
      yield* accounts.items.map(function*({ accountId, accountType }) {
        yield* getPortfolioPropertyFindAllFlow({ accountId, accountType });
      });

      yield put(accountsFindAllSuccess(accounts));
    }
  } catch (e) {
    yield call(logger.error, e);
    // sagaPromise needs to handle rejection
    throw e;
  }
});

export function* checkReaccreditationFlow() {
  try {
    const reaccreditationAllowed = yield select(
      selectUserReAccreditationAllowed
    );

    if (!reaccreditationAllowed) return;

    yield put(
      globalActions.openGlobalModal(GLOBAL_MODAL_NAMES.reaccreditation)
    );
  } catch (e) {
    yield call(logger.error, e);
  }
}

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function* root() {
  try {
    yield takeEvery(type.REGISTER_SUBMIT, registerFlow);
    yield takeEvery(type.LOGIN_SUBMIT, loginFlow);
    yield takeLeading(type.LOGIN_SUCCESS, checkReaccreditationFlow);
    yield takeEvery(
      type.RESEND_VERIFICATION_EMAIL_SUBMIT,
      resendVerificationEmailFlow
    );
    yield takeLatest(type.LOGOUT, logoutFlow);
    yield takeEvery(type.USERDETAILS_REQUEST, userDetailsFlow);
    yield takeEvery(
      type.FORGOT_PASSWORD_RESET_REQUEST,
      forgotPasswordResetFlow
    );
    yield takeEvery(
      type.FORGOT_PASSWORD_CHANGE_REQUEST,
      forgotPasswordChangeFlow
    );
    yield takeLeading(
      [type.ACCOUNTS_FINDALL_REQUEST, ACCOUNT_OVERVIEW_REQUEST],
      accountsFlow
    );
    yield takeLatest(type.UPDATE_NI_NUMBER_REQUEST, updateNiNumber);
  } catch (e) {
    yield call(logger.error, e);
  }
}
