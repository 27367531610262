import React from 'react';
import store from 'store';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

import Config from 'config';
import { Button } from 'components/Atoms/Button';
import { Link } from 'components/Atoms/Link';
import { commonPaths } from 'router/paths';

import { i18nConnect } from 'utils/i18nConnect';
import media from 'utils/responsive';

const Wrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  bottom: 0;
  color: ${Config.theme.colors.white};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  height: auto;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 10000;

  ${media.medium`
    flex-direction: row;
    font-size: 1rem;
  `};
`;

export class CookieBanner extends React.PureComponent {
  state = {
    show: false,
  };

  componentDidMount() {
    if (document && !store.get('cookie-banner-seen')) {
      this.setState({
        show: true,
      });

      window.addEventListener('scroll', this.cookiePolicyConsent);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.cookiePolicyConsent);
  }

  cookiePolicyConsent = () => {
    store.set('cookie-banner-seen', 'true');
    this.setState({
      show: false,
    });
    window.removeEventListener('scroll', this.cookiePolicyConsent);
  };

  render() {
    const { i18n } = this.props;
    const { show } = this.state;

    return (
      <>
        {show && (
          <Wrapper>
            <div>
              {i18n(
                'CookieBanner'
              )`By interacting with the site, you agree to our use of cookies.
              To find out more, see our `}

              <Link to={commonPaths.cookiedPolicy()}>
                {i18n('CookieBanner')`cookie policy`}
              </Link>
              {i18n('CookieBanner')`. `}
            </div>

            <Box mx={2}>
              <Button
                onClick={this.cookiePolicyConsent}
                color="white"
                variant="contained"
              >
                {i18n('CookieBanner')`Close`}
              </Button>
            </Box>
          </Wrapper>
        )}
      </>
    );
  }
}

export default i18nConnect(CookieBanner);
