import React, { FC, useMemo } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
} from '@material-ui/core';

import { Link } from 'components/Atoms/Link';
import { Section } from 'components/Molecules/Section';
import Config from 'config';
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from 'components/Atoms/Icons';
import { useI18n } from 'utils/i18nConnect';
import { blogPaths, commonPaths } from 'router/paths';
import Typography from '@material-ui/core/Typography';

export const FooterSiteMap: FC = () => {
  const t = useI18n();

  const socialLinks = useMemo(
    () => [
      {
        href: 'https://www.instagram.com/britishpearl',
        title: t('footer.FooterSiteMap.title.instagram')`Instagram`,
        Icon: Instagram,
      },
      {
        href: 'https://www.linkedin.com/company/british-pearl',
        title: t('footer.FooterSiteMap.title.linkedIn')`LinkedIn`,
        Icon: Linkedin,
      },
      {
        href: 'https://www.facebook.com/BritishPearl',
        title: t('footer.FooterSiteMap.title.facebook')`Facebook`,
        Icon: Facebook,
      },
      {
        href: 'https://twitter.com/britishpearl',
        title: t('footer.FooterSiteMap.title.twitter')`Twitter`,
        Icon: Twitter,
      },
      {
        href: 'https://www.youtube.com/channel/UC40yitWbhZVzh5Lczh89FsQ',
        title: t('footer.FooterSiteMap.title.youtube')`YouTube`,
        Icon: Youtube,
      },
    ],
    [t]
  );

  const footerLinks = useMemo(
    () => [
      {
        subheader: t('footer.FooterSiteMap.subheading.legal')`Legal`,
        links: [
          {
            to: commonPaths.keyRisks(),
            label: t('footer.FooterSiteMap.link.keyRisk')`Key risks`,
          },
          {
            to: commonPaths.termsOfUse(),
            label: t('footer.FooterSiteMap.link.termsOfUse')`Terms of use`,
          },
          {
            to: commonPaths.privacyNotice(),
            label: t('footer.FooterSiteMap.link.privacyNotice')`Privacy notice`,
          },
          {
            to: commonPaths.cookiedPolicy(),
            label: t('footer.FooterSiteMap.link.cookiePolicy')`Cookie policy`,
          },
          {
            to: commonPaths.complaints(),
            label: t('footer.FooterSiteMap.link.complaints')`Complaints`,
          },
        ],
      },
      {
        subheader: t('footer.FooterSiteMap.subheading.invest')`Invest`,
        links: [
          {
            to: commonPaths.howItWorks(),
            label: t('footer.FooterSiteMap.link.howItWorks')`How it works`,
          },
          {
            to: commonPaths.loans(),
            label: t(
              'footer.FooterSiteMap.link.loans'
            )`Invest in property loans`,
          },
          {
            to: commonPaths.shares(),
            label: t(
              'footer.FooterSiteMap.link.shares'
            )`Invest in property shares`,
          },
          {
            to: commonPaths.isa(),
            label: t('footer.FooterSiteMap.link.isa')`Innovative Finance ISA`,
          },
        ],
      },
      {
        subheader: t('footer.FooterSiteMap.subheading.explore')`Explore`,
        links: [
          {
            to: commonPaths.aboutUs(),
            label: t('footer.FooterSiteMap.link.aboutUs')`About us`,
          },
          {
            to: commonPaths.faq(),
            label: t('footer.FooterSiteMap.link.faq')`FAQs`,
          },
          {
            to: blogPaths.blogAll(),
            label: t('footer.FooterSiteMap.link.blog')`Blog`,
          },
          {
            to: commonPaths.support(),
            label: t('footer.FooterSiteMap.link.support')`Support`,
          },
        ],
      },
    ],
    [t]
  );

  return (
    <Section size="medium" color="grey">
      <Grid container spacing={2}>
        {footerLinks.map(({ subheader, links }) => (
          <Grid item xs={12} sm={6} lg={3} key={subheader}>
            <List
              subheader={
                <Box my={1}>
                  <Typography variant="h4">{subheader}</Typography>
                </Box>
              }
              disablePadding
            >
              {links.map(({ to, label }) => (
                <ListItem disableGutters key={to}>
                  <Link
                    to={to}
                    variant="body1"
                    color="inherit"
                    underline="hover"
                  >
                    {label}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} lg={3}>
          <Box mt={1} mb={2}>
            <Typography variant="h4">
              {t('footer.FooterSiteMap.subheading.contact')`Get in touch`}
            </Typography>
          </Box>
          <div>
            <MuiLink
              href={`tel:${Config.contact.phone.replace(/\s/g, '')}`}
              variant="body1"
              color="inherit"
            >
              {Config.contact.phone}
            </MuiLink>
          </div>
          <div>
            <MuiLink
              href={`mailto:${Config.contact.email}`}
              variant="body1"
              color="inherit"
            >
              {Config.contact.email}
            </MuiLink>
          </div>
          <Box mt={3} mb={2}>
            <Typography variant="h4">
              {t('footer.FooterSiteMap.subheading.followUs')`Follow us`}
            </Typography>
          </Box>
          {socialLinks.map(({ href, title, Icon }) => (
            <IconButton
              key={href}
              href={href}
              title={title}
              size="small"
              target="_blank"
            >
              <Icon fill={undefined} width={40} height={40} />
            </IconButton>
          ))}
        </Grid>
      </Grid>
    </Section>
  );
};
