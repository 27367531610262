import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.error.main,
    borderRadius: spacing(1),
    color: palette.common.white,
    padding: spacing(2),
    '&:only-child': {
      borderRadius: spacing(1),
      textAlign: 'center',
    },
    '&:not(:only-child)': {
      marginBottom: spacing(2),
    },
  },
}));
