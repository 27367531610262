import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';

import { LoaderOverlay } from 'components/Atoms';
import { investorPaths } from 'router/paths';

import Loader from './Loader';
import { InvestorAuth } from './Auth';

const LoadableInvestorWallet = Loadable({
  loader: () => import('containers/InvestorWallet'),
  loading: () => <LoaderOverlay active />,
});

const LoadableInvestorNotices = Loadable({
  loader: () => import('components/Pages/InvestorNotices'),
  loading: () => <LoaderOverlay active />,
});

/*
const LoadableReferFriend = Loadable({
  loader: () => import('containers/ReferFriend'),
  loading: () => <LoaderOverlay active />,
});
*/

const Investor = () => (
  <InvestorAuth>
    <Switch>
      <Route exact path={investorPaths.dashboard()} component={Loader} />
      <Route
        exact
        path={investorPaths.notices()}
        component={LoadableInvestorNotices}
      />
      <Route path={investorPaths.isaAccount()} component={Loader} />
      <Route exact path={investorPaths.activity()} component={Loader} />
      <Route exact path={investorPaths.documents()} component={Loader} />
      <Route component={Loader} path={investorPaths.mainAccount()} />
      <Route exact path={investorPaths.profile()} component={Loader} />
      <Route path={investorPaths.wallet()} component={LoadableInvestorWallet} />
      <Route path={investorPaths.taxStatement()} component={Loader} />
      {/*
      <Route
        exact
        path={investorPaths.referFriend()}
        component={LoadableReferFriend}
      />
      */}
      <Redirect
        from={investorPaths.investor()}
        to={investorPaths.dashboard()}
      />
    </Switch>
  </InvestorAuth>
);

Investor.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Investor;
