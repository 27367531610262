import React from 'react';

import Config from 'config';

const Surveyors = ({
  fill = Config.theme.colors.akaroa,
  fill2 = Config.theme.colors.quickSand,
  height = 64,
  width = 64,
  ...props
}) => (
  <svg height={height} viewBox="0 0 64 64" width={width} {...props}>
    <g fill="none">
      <path
        d="M18.4473 7.2227l4.861-4.861h20.373v16.702h2V.3617H22.48l-6.0327 6.0327V19.064h2"
        fill={fill}
      />
      <path fill={fill2} d="M20.1707 8.532v2h6.4468V4.085h-2v4.447" />
      <path
        d="M2.189 34.367l3.045-6.1567 19.2974-6.5182 3.3696 9.946 1.8943-.6412-4.0108-11.8385L3.8087 26.581l-3.78 7.6428 10.0605 29.6978 18.5725-6.2745-.6402-1.895-16.6792 5.635"
        fill={fill}
      />
      <path
        d="M4.2416 35.0552l.64 1.8948 6.108-2.0633-2.0684-6.1034-1.8942.642 1.4258 4.2073"
        fill={fill2}
      />
      <path
        d="M61.8497 33.3886l-17.302-10.747-6.6864 1.565-15.033 24.2448 21.4295 13.312 17.592-28.3748zM20.0754 49.0958l16.523-26.6476 8.3005-1.9427L64.602 32.744 44.9028 64.5186 20.0754 49.0958z"
        fill={fill}
      />
      <path
        d="M38.6352 26.2276l-1.0552 1.699 5.4758 3.401 3.3965-5.4775-1.6998-1.0534-2.3417 3.7765"
        fill={fill2}
      />
    </g>
  </svg>
);

Surveyors.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 64,
  width: 64,
};

export default Surveyors;
