import { differenceInSeconds } from 'date-fns';
import React from 'react';
import { withRouter } from 'react-router-dom';
import sessionStorage from 'store';
import styled from 'styled-components';

import loaders, { moduleCache } from 'router/loaders';
import ErrorInternal from 'containers/ErrorInternal';
import { LoaderOverlay } from 'components/Atoms';

import { getInjectors, injectReducersAndSagas } from 'utils/injector';
import { isServerSide } from 'utils/isSSR';
import { ReactReduxContext } from 'react-redux';

const StyledLoaderOverlay = styled(LoaderOverlay)`
  padding-top: 5rem;
`;

class Loader extends React.Component {
  state = {
    Component: null,
    additionalProps: null,
  };

  componentDidMount() {
    this.displayMatch(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.displayMatch(this.props);
    }
  }

  componentDidCatch(error) {
    // eslint-disable-next-line no-console
    console.error('Dynamic page loading failed', error);

    if (!window) {
      return;
    }

    const lastReload = Number(sessionStorage.get('lastReload'));
    const now = Date.now();

    if (
      process.env.NODE_ENV === 'production' &&
      (Number.isNaN(lastReload) || differenceInSeconds(now, lastReload) > 10)
    ) {
      try {
        sessionStorage.set('lastReload', now);
      } catch (e) {
        this.displayError();
      }
      window.location.reload();
    } else {
      this.displayError();
    }
  }

  displayError() {
    this.setState({
      Component: ErrorInternal,
    });
  }

  async displayMatch(props) {
    const { match } = props;
    const routeLoader = loaders[match.path] || loaders['/404'];
    try {
      const modules = await routeLoader();
      this.injectIntoStore(modules);
      const { container, additionalProps } = await routeLoader();
      this.setState({
        Component:
          // eslint-disable-next-line no-underscore-dangle
          container.__esModule && container.default
            ? container.default
            : container,
        additionalProps,
      });
    } catch (err) {
      this.componentDidCatch(err);
    }
  }

  injectIntoStore(items) {
    const { store } = this.props;

    const { name, reducers, sagas } = items;
    const { injectReducer, injectSagas } = getInjectors(store);
    injectReducersAndSagas({
      reducers,
      sagas,
      name,
      injectReducer,
      injectSagas,
    });
  }

  renderStatic() {
    const { match } = this.props;
    const cachedModules = moduleCache[match.path];
    this.injectIntoStore(cachedModules);
    const { container, additionalProps } = cachedModules;
    const ServerComponent =
      // eslint-disable-next-line no-underscore-dangle
      container.__esModule && container.default ? container.default : container;
    return <ServerComponent {...additionalProps} {...this.props} />;
  }

  render() {
    if (isServerSide()) {
      return this.renderStatic();
    }
    const { Component, additionalProps } = this.state;
    return Component ? (
      <Component {...additionalProps} {...this.props} />
    ) : (
      <StyledLoaderOverlay active />
    );
  }
}

const LoaderWithRouter = withRouter(Loader);

export default function LoaderWithStore(props) {
  return (
    <ReactReduxContext.Consumer>
      {({ store }) => <LoaderWithRouter {...props} store={store} />}
    </ReactReduxContext.Consumer>
  );
}
