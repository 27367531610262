import { LoggerProvider } from './types';

/* eslint-disable no-console, @typescript-eslint/no-explicit-any, class-methods-use-this */
export class ConsoleLoggerProvider implements LoggerProvider {
  public log(...args: any[]): void {
    console.log(...args);
  }

  public info(...args: any[]): void {
    console.info(...args);
  }

  public warn(...args: any[]): void {
    console.warn(...args);
  }

  public error(...args: any[]): void {
    console.error(...args);
  }
}
