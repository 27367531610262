import { Pip } from 'services/Pip';

export const BP_GALLERY_EL_ID = 'bpGallery';

export const INVESTMENT_STATES_ALLOWED_TO_INVEST = [
  Pip.InvestmentState.OnPrimaryMarketPreOffer,
  Pip.InvestmentState.OnPrimaryMarketPostOffer,
  Pip.InvestmentState.OnPrimaryMarketFullyFunded,
  Pip.InvestmentState.OnSecondaryMarket,
];
