import React from 'react';
import Helmet from 'react-helmet';

import { i18nConnect } from 'utils/i18nConnect';

import Config from 'config';

import CashbackHero from 'assets/images/MarketingHeroes/CashbackHero.jpg';

const Base = Template =>
  i18nConnect(({ title, description, keywords, social, i18n, ...props }) => (
    <>
      <Helmet defaultTitle="British Pearl" titleTemplate="%s | British Pearl">
        <html lang="en" prefix="og: http://ogp.me/ns#" />

        <title>{title || i18n('Template')`Property Investment Platform`}</title>

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta
          content={
            description ||
            i18n(
              'Template'
            )`Property investment platform enabling people to invest in individual properties as a
              shareholder or lender for as little as £100.`
          }
          name="description"
        />

        <link
          href="/apple-touch-icon-precomposed.png"
          rel="apple-touch-icon-precomposed apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color={Config.theme.colors.pomegranate}
        />
        <meta name="apple-mobile-web-app-title" content="British Pearl" />
        <meta name="application-name" content="British Pearl" />
        <meta
          name="msapplication-TileColor"
          content={Config.theme.colors.pomegranate}
        />
        <meta name="theme-color" content="#2c3231" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={Config.theme.colors.white}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@britishpearl" />

        <meta
          name="google-site-verification"
          content="2H3gchzRPq7OEODM3d6dR-TBwFgM8ha3OzSHtv4XrcY"
        />
        <meta name="msvalidate.01" content="2CE2E71B147A02032119BB4BFF0275FC" />

        <meta
          content={
            social && social.title
              ? social.title
              : title || i18n('Template')`Property Investment Platform`
          }
          name="twitter:title"
        />
        <meta
          content={
            social && social.description
              ? social.description
              : description ||
                i18n('Template')`Property investment
            platform enabling people to invest in individual properties as a shareholder or lender for as little as
            £100.`
          }
          name="twitter:description"
        />
        <meta
          content={`https://www.britishpearl.com${
            CashbackHero.images ? CashbackHero.images[2].path : CashbackHero
          }`}
          name="twitter:image"
        />

        <meta
          content={
            social && social.title
              ? social.title
              : title || i18n('Template')`Property Investment Platform`
          }
          property="og:title"
        />

        <meta
          content={
            social && social.description
              ? social.description
              : description ||
                i18n('Template')`Property investment
            platform enabling people to invest in individual properties as a shareholder or lender for as little as
            £100.`
          }
          property="og:description"
        />

        <meta
          content={`https://www.britishpearl.com${
            CashbackHero.images ? CashbackHero.images[2].path : CashbackHero
          }`}
          property="og:image"
        />

        <meta
          content={
            keywords ||
            i18n('Template')`Property investment platform, Property investment`
          }
          name="keywords"
        />

        <script type="application/ld+json">
          {JSON.stringify(Config.structuredData)}
        </script>

        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        />
      </Helmet>

      <Template {...props} />
    </>
  ));

export default Base;
