import Config from 'config';
import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  costsBuySummary: {
    url: '/costs/investments/:investmentId/accounts/:accountId/:assetType/buy',
    helpers: {
      get: (accountId, amount, investmentId, assetType, unitPrice) =>
        OAuth.getAuthHeader().then(header => [
          {
            accountId,
            amount,
            investmentId,
            assetType,
            unitPrice,
          },
          header,
        ]),
    },
    transformer: ({ data }) => data,
  },
  costsSellSummary: {
    url: '/costs/investments/:investmentId/accounts/:accountId/:assetType/sell',
    helpers: {
      get: (accountId, quantity, investmentId, assetType, unitPrice) =>
        OAuth.getAuthHeader().then(header => [
          {
            accountId,
            quantity,
            investmentId,
            assetType,
            unitPrice,
          },
          header,
        ]),
    },
    transformer: ({ data }) => data,
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
