import React from 'react';
import styled from 'styled-components';

import Config from 'config';
import { i18nConnect } from 'utils/i18nConnect';

import media from 'utils/responsive';

const Wrapper = styled.ul`
  border-bottom: 1px solid ${Config.theme.colors.pampas};
  border-top: 1px solid ${Config.theme.colors.pampas};
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 1rem;
  overflow: hidden;
  padding: 1rem 0 0;
  top: 10.75rem;

  li {
    flex-basis: 100%;
    margin-bottom: 1rem;
    min-width: 25%;
    text-align: right;

    ${media.medium`
      flex-basis: initial;
      text-align: left;
    `};
  }

  p {
    font-size: 1.5rem;
    font-weight: ${Config.theme.fontWeight.light};
    margin: 0;

    ${media.medium`
      font-size: 2.25rem;
    `};
  }

  ${media.medium`
    margin: 0 0 2rem;
    padding: 2rem 0 0;
  `};
`;

export const Breakdown = ({ breakdown, i18n }) => (
  <Wrapper>
    {breakdown.map(item => (
      <li key={item.id}>
        <p>{i18n('AccountOverview')`${Math.abs(item.itemAmount)}:c`}</p>

        {item.label}
      </li>
    ))}
  </Wrapper>
);

export default i18nConnect(Breakdown);
