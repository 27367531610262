import React from 'react';

import Config from 'config';

const Plug = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 512 512" width={width} {...props}>
    <g strokeWidth="4" fill="none" fillRule="evenodd">
      <path
        d="M353.9787 3H159.4255C141.5158 3 127 17.5158 127 35.4255v194.5532c0 17.9097 14.5158 32.4256 32.4255 32.4256h194.5532c17.9097 0 32.4256-14.5159 32.4256-32.4256V35.4255C386.4043 17.5158 371.8884 3 353.9787 3z"
        stroke={fill}
      />
      <path
        d="M234.6708 164.2305h-21.617m86.1092 0h-21.617m-21.1632-44.5324v-21.617m-78.8451 10.4639A38 38 0 0 0 167 134.8094V176c0 20.9868 17.0132 38 38 38h103c20.9868 0 38-17.0132 38-38v-41.0148a38 38 0 0 0-10.735-26.4691C298.041 70.1727 271.421 51 256 51c-15.4198 0-41.7714 19.1806-78.4623 57.545z"
        stroke={fill2}
      />
      <path
        d="M256.3617 262.4043v32.4255c0 17.9097 14.5158 32.4255 32.4255 32.4255H429.298c17.9097 0 32.4255 14.5158 32.4255 32.4256 0 17.9097-14.5158 32.4255-32.4255 32.4255H83.4255C65.5158 392.1064 51 406.6222 51 424.5319s14.5158 32.4255 32.4255 32.4255h140.5107c17.9097 0 32.4255 14.5159 32.4255 32.4256V511"
        stroke={fill}
      />
    </g>
  </svg>
);

Plug.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 512,
  width: 512,
};

export default Plug;
