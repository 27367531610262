import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    imageWrapper: {
      position: 'relative',
      paddingBottom: '56.25%', // 16:9 = (9/16) * 100 = 56.25%
    },
    image: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      overflow: ' hidden',
    },
  })
);
