import React from 'react';
import styled from 'styled-components';

import media from 'utils/responsive';
import { i18nConnect } from 'utils/i18nConnect';
import Config from 'config';

import { Tab, Tabs, TabList, TabPanel } from 'components/Atoms/Tabs';
import { capitalise } from 'utils/typography';

import Items from './Items';

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const EmptyResults = styled.div`
  margin-top: 2rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  font-weight: ${Config.theme.fontWeight.light};
  padding: 0 1rem;

  ${media.medium`
    padding: 0;
  `} ${media.wide`
    margin-top: 0;
  `};
`;

const Controls = styled(({ noLabel, ...props }) => <TabList {...props} />)`
  display: ${({ noLabel }) => (noLabel ? 'none' : 'flex')};
  margin: 1rem auto;

  ${media.medium`
    display: ${({ noLabel }) => (noLabel ? 'none' : 'inline-flex')};
    margin: 1rem 0;
    padding: .25rem;
  `};
`;

Controls.tabsRole = 'TabList';

const SORTED = ['both', 'shares', 'loans'];

const InvestmentDetails = ({
  properties,
  investmentTypes,
  noLabel,
  accountId,
  i18n,
}) => {
  const TABS = Object.keys(investmentTypes).sort(
    (a, b) => SORTED.indexOf(a) - SORTED.indexOf(b)
  );
  return (
    <Wrapper data-test-id="investment-details-wrapper">
      {properties && properties.size > 0 ? (
        <StyledTabs>
          <Controls noLabel={noLabel}>
            {TABS.map(x => (
              <Tab key={x}>{i18n('InvestmentDetails')`${capitalise(x)}`}</Tab>
            ))}
          </Controls>

          {TABS.map(
            x =>
              investmentTypes[x] && (
                <TabPanel key={x}>
                  {properties.toArray().map(([, item]) => (
                    <Items
                      accountId={accountId}
                      item={item}
                      key={item.get('investmentId')}
                      label={x}
                    />
                  ))}
                </TabPanel>
              )
          )}
        </StyledTabs>
      ) : (
        <EmptyResults>{i18n('InvestmentDetails')`No investments`}</EmptyResults>
      )}
    </Wrapper>
  );
};

InvestmentDetails.defaultProps = {
  noLabel: false,
};

export default i18nConnect(InvestmentDetails);
