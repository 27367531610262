import React from 'react';
import styled, { css } from 'styled-components';

import Config from 'config';

const mediaQuery = (...query) => {
  const template = (...rules) => css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

  template.and = andQuery =>
    mediaQuery`${css(...query)} and ${andQuery.toString()}`;
  template.or = orQuery => mediaQuery`${css(...query)}, ${orQuery.toString()}`;
  template.toString = () => css(...query);

  return template;
};

// Breakpoints: [48, 64, 87.5]
// medium => 48rem == 768px
// large => 64rem == 1024px
// wide => 87.5rem == 1400px
export const mediaMapping = {
  medium: Config.theme.breakpoints[0],
  large: Config.theme.breakpoints[1],
  wide: Config.theme.breakpoints[2],
};

const em2px = em => {
  let fontSize;

  try {
    fontSize = parseFloat(
      window
        .getComputedStyle(document.getElementById('app'), null)
        .getPropertyValue('font-size')
    );
  } catch (err) {
    fontSize = 16;
  }

  return em * fontSize;
};

const mediaQueries = {
  small: {
    andBelow: `(max-width: ${em2px(mediaMapping.medium) - 1}px)`,
    andAbove: '(min-width: 0)',
    only: `(max-width: ${em2px(mediaMapping.medium) - 1}px)`,
  },
  medium: {
    andBelow: `(max-width: ${em2px(mediaMapping.large) - 1}px)`,
    andAbove: `(min-width: ${em2px(mediaMapping.medium)}px)`,
    only: `(min-width: ${em2px(mediaMapping.medium)})px and (max-width: ${em2px(
      mediaMapping.large
    ) - 1}px)`,
  },
  large: {
    andBelow: `(max-width: ${em2px(mediaMapping.wide) - 1}px)`,
    andAbove: `(min-width: ${em2px(mediaMapping.large)}px)`,
    only: `(max-width: ${em2px(mediaMapping.wide) -
      1}px) and (min-width: ${em2px(mediaMapping.large)}px)`,
  },
  wide: {
    andBelow: '(min-width: 0)',
    andAbove: `(min-width: ${em2px(mediaMapping.wide)}px)`,
    only: `(min-width: ${em2px(mediaMapping.wide)}px)`,
  },
};

mediaQueries.small.only = mediaQueries.small.andBelow;
mediaQueries.medium.only = `${mediaQueries.medium.andAbove} and ${mediaQueries.medium.andBelow}`;
mediaQueries.large.only = `${mediaQueries.large.andAbove} and ${mediaQueries.large.andBelow}`;
mediaQueries.wide.only = mediaQueries.wide.andAbove;

// eslint-disable-next-line react/prop-types
export const MediaQuery = ({ children, ...props }) => {
  const sizeProps = ['small', 'medium', 'large', 'wide'];

  const operatorProps = ['only', 'andAbove', 'andBelow'];

  const sizes = Object.keys(props)
    .filter(prop => props[prop])
    .filter(prop => sizeProps.indexOf(prop) >= 0);

  const operators = Object.keys(props)
    .filter(prop => props[prop])
    .filter(prop => operatorProps.indexOf(prop) >= 0);

  const operator = operators.length ? operators[0] : 'andAbove';

  const query = sizes.reduce(
    (q, size, i) =>
      i
        ? `${q} and ${mediaQueries[size][operator]}`
        : mediaQueries[size][operator],
    ''
  );

  const childProps = Object.keys(props)
    .filter(
      prop =>
        sizeProps.indexOf(prop) === -1 && operatorProps.indexOf(prop) === -1
    )
    .reduce((cp, prop) => Object.assign(cp, { [prop]: props[prop] }), {});

  const BaseMediaQuery = styled.div`
    display: none;

    ${mediaQuery`${query}``
      display: block;
    `};
  `;

  return <BaseMediaQuery {...childProps}>{children}</BaseMediaQuery>;
};

const makeQuery = size => {
  const helper = mediaQuery`${mediaQueries[size].andAbove}`;
  helper.only = mediaQuery`${mediaQueries[size].only}`;
  helper.andAbove = helper;
  helper.andBelow = mediaQuery`${mediaQueries[size].andBelow}`;
  return helper;
};

const w = window && window.matchMedia ? window : undefined;

const matchMedia = media => w && w.matchMedia(media).matches;

const [sml, med, lrg, wde] = Config.theme.newBreakpoints.map(value =>
  em2px(value)
);

export const isXSmall = () =>
  matchMedia(`(min-width:0px) and (max-width: ${sml - 1}px)`);

export const isSmall = () =>
  matchMedia(`(min-width:${sml}px) and (max-width: ${med - 1}px)`);

export const isMedium = () =>
  matchMedia(`(min-width:${med}px) and (max-width: ${lrg - 1}px)`);

export const isLarge = () =>
  matchMedia(`(min-width:${lrg}px) and (max-width: ${wde - 1}px)`);

export const isWide = () => matchMedia(`(min-width:${wde}px)`);

export const getCurrent = () => {
  if (isXSmall()) {
    return 'xsmall';
  }
  if (isSmall()) {
    return 'small';
  }
  if (isMedium()) {
    return 'medium';
  }
  if (isLarge()) {
    return 'large';
  }
  return 'wide';
};

export default {
  small: makeQuery('small'),
  medium: makeQuery('medium'),
  large: makeQuery('large'),
  wide: makeQuery('wide'),
};
