import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { useI18n } from 'utils/i18nConnect';

import { ThemeProvider } from 'components/Molecules/ThemeProvider';
import { PropertyCardInfoItem } from './PropertyCardInfoItem';

interface PropertyCardInfoProps {
  sharesValue: number;
  loansValue: number;
  hideShares?: boolean;
  hideLoans?: boolean;
  sharesSoldOut?: boolean;
  loansSoldOut?: boolean;
}

export const PropertyCardInfo: FC<PropertyCardInfoProps> = ({
  sharesValue,
  loansValue,
  hideShares,
  hideLoans,
  sharesSoldOut = false,
  loansSoldOut = false,
}) => {
  const i18n = useI18n();

  return (
    <Grid container>
      {!hideShares && (
        <Grid item xs>
          <ThemeProvider themeName="shares">
            <PropertyCardInfoItem
              soldOut={sharesSoldOut}
              investmentType={i18n('PropertyCard')`Shares`}
              investmentTypeReturn={i18n(
                'PropertyCard'
              )`Est. net \nshare return`}
              value={sharesValue}
            />
          </ThemeProvider>
        </Grid>
      )}
      {!hideLoans && (
        <Grid item xs>
          <ThemeProvider themeName="loans">
            <PropertyCardInfoItem
              soldOut={loansSoldOut}
              investmentType={i18n('PropertyCard')`Loans`}
              investmentTypeReturn={i18n('PropertyCard')`Net loan \ninterest`}
              value={loansValue}
            />
          </ThemeProvider>
        </Grid>
      )}
    </Grid>
  );
};
