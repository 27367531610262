import React from 'react';

const SidebarRewards = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3z" />
      <path
        d="M18 7.5h-1.635c.0825-.2325.135-.4875.135-.75 0-1.245-1.005-2.25-2.25-2.25-.7875 0-1.47.405-1.875 1.0125L12 6.015l-.375-.51C11.22 4.905 10.5375 4.5 9.75 4.5 8.505 4.5 7.5 5.505 7.5 6.75c0 .2625.0525.5175.135.75H6c-.8325 0-1.4925.6675-1.4925 1.5L4.5 17.25c0 .8325.6675 1.5 1.5 1.5h12c.8325 0 1.5-.6675 1.5-1.5V9c0-.8325-.6675-1.5-1.5-1.5zM14.25 6c.4125 0 .75.3375.75.75s-.3375.75-.75.75-.75-.3375-.75-.75.3375-.75.75-.75zm-4.5 0c.4125 0 .75.3375.75.75s-.3375.75-.75.75S9 7.1625 9 6.75 9.3375 6 9.75 6zM18 17.25H6v-1.5h12v1.5zm0-3.75H6V9h3.81l-1.56 2.1225L9.465 12l1.785-2.43.75-1.02.75 1.02L14.535 12l1.215-.8775L14.19 9H18v4.5z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarRewards.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarRewards;
