import { createStyles, makeStyles } from '@material-ui/core/styles';
import { emphasize, fade } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette, spacing, transitions }) =>
  createStyles({
    root: {
      borderRadius: spacing(2),
      textTransform: 'none',
      fontWeight: 700,
      // MUI currently does not transition text color, which makes the transition look jagged
      transition: transitions.create(
        ['background-color', 'box-shadow', 'border', 'color'],
        {
          duration: transitions.duration.short,
        }
      ),
      '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="white"`. */
    outlinedWhite: {
      color: palette.common.white,
      borderColor: `${palette.common.white}`,
      '&:hover': {
        backgroundColor: fade(
          palette.common.white,
          palette.action.hoverOpacity
        ),
      },
    },
    containedWhite: {
      color: palette.common.black,
      backgroundColor: palette.common.white,
      '&:hover': {
        backgroundColor: emphasize(palette.common.white),
      },
    },
    outlinedPrimary: {
      borderColor: `${palette.primary.main}`,
    },
    outlinedSecondary: {
      color: palette.common.black,
    },
    outlined: {
      borderWidth: '2px',
      '&:hover': {
        borderWidth: '2px',
      },
      '&:disabled': {
        borderWidth: '2px',
      },
    },
    large: {
      height: spacing(6),
    },
  })
);
