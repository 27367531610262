// TODO: https://britishpearl.atlassian.net/browse/PIT-1988
import React from 'react';
import styled from 'styled-components';

import Config from 'config';
import media from 'utils/responsive';

import { Chevron } from 'components/Atoms/Icons';

export const StyledWrapper = styled.div`
  color: ${Config.theme.colors.black};
  background: ${Config.theme.colors.white};

  table {
    table-layout: fixed;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  tr {
    width: 100%;
    height: 3.25rem;
  }

  th {
    width: 18%;
    border-right: 1px solid ${Config.theme.colors.white};
    background: ${Config.theme.colors.pomegranate};
    color: ${Config.theme.colors.white};
  }

  th:first-of-type {
    border-left: 0;
    width: 46%;
  }

  th:last-of-type {
    border-right: 0
  }

  tbody td {
    border: 1px solid ${Config.theme.colors.cloudy};
    text-align: center;
  }

  tbody td:first-of-type {
    text-align: left;
    padding-left: 0.5rem;
    font-weight: ${Config.theme.fontWeight.medium};
  }

  tfoot td {
    color: ${Config.theme.colors.white};
    background: ${Config.theme.colors.pomegranate};
  }

  tfoot tr {
    height: 2.125rem;
  }

  ${media.medium`
    tbody td:first-of-type {
      padding-left: 0.875rem;
    }
  `}

  ${media.large`
    tr {
      height: 4.1875rem;
    }

    tfoot tr {
      height: 2.75rem;
    }

    tbody td:first-of-type {
      padding-left: 1.375rem;
    }
  `}

  ${media.wide`
    tbody td:first-of-type {
      padding-left: 2.125rem;
    }
  `}

  table thead tr:first-child th:first-child {
    border-top-left-radius: 1rem;
  }
  table thead tr:first-child th:last-child {
    border-top-right-radius: 1rem;
  }

  table tfoot tr:first-child td:first-child {
    border-bottom-left-radius: 1rem;
  }
  table tfoot tr:first-child td:last-child {
    border-bottom-right-radius: 1rem;
  }

  table tbody tr {
    td{
      border-width: 1px 0 0 1px;

      &:first-child {
        border-width: 1px 0 0 1px;
      }
      &:last-child {
        border-right-width: 1px;
      }
    }
  }
`;

export const StyledTh = styled.th`
  font-size: 1rem;
  line-height: 1.4375rem;

  ${media.medium`
    font-size: 1.25rem;
    line-height: 1.8125rem;
  `}
`;

export const StyledTd = styled.td`
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${media.medium`
    font-size: 1.25rem;
    line-height: 1.8125rem;
  `}
`;

export const StyledYearsFull = styled.span`
  display: none;
  ${media.medium`
      display: inline-block;
  `}
`;

export const StyledYearsShorthand = styled.span`
  display: inline-block;
  ${media.medium`
      display: none;
  `}
`;

export const StyledPaCaption = styled.span`
  position: absolute;
  display: block;
  bottom: -0.75rem;
  right: 0;
  line-height: 0.625rem;
  font-size: 0.75rem;

  ${media.medium`
    display: inline-block;
    position: relative;
    bottom: unset;
    right: unset;
    padding-left: 0.5rem;
    line-height: unset;
    font-size: unset;
  `}
`;

export const StyledChevron = styled(({ open, ...props }) => (
  <Chevron {...props} />
))`
  color: ${Config.theme.colors.white};
  transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.3s;
`;
