import React from 'react';
import { useSelector } from 'react-redux';

import { Generic as Template } from 'components/Templates';
import { MarketingLanding } from 'components/Pages/MarketingLanding';
import { makeSelectUser } from 'store/SessionProvider/selectors';
import { makeSelectIsaAccount } from 'store/InvestorWallet/selectors';

import components, { templateProps } from './layout';

export const OpenIsaPage = () => {
  const user = useSelector(makeSelectUser());
  const isaAccount = useSelector(makeSelectIsaAccount());
  return (
    <Template
      description={templateProps.description}
      title={templateProps.title}
      navLinks={templateProps.navLinks}
    >
      <MarketingLanding
        components={components}
        loggedIn={!!user}
        isaAccountOpened={!!isaAccount}
      />
    </Template>
  );
};
