import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    ul: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
      paddingLeft: spacing(2),
    },
    li: {
      paddingLeft: spacing(0.5),
    },
  })
);
