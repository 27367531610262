import { createSelector } from 'reselect';

export const selectLanguage = state => state.get('language');

export const makeSelectLocale = () =>
  createSelector(selectLanguage, languageState => languageState.get('locale'));

export const makeSelectGroups = () =>
  createSelector(selectLanguage, languageState => languageState.get('groups'));

export const makeSelectTranslations = () =>
  createSelector(selectLanguage, languageState =>
    languageState.get('translations')
  );

export const makeSelectTranslationsForLocale = locale =>
  createSelector(selectLanguage, languageState =>
    languageState.getIn(['translations', locale])
  );

export const makeSelectTranslationsForLocaleAndGroup = (locale, group) =>
  createSelector(selectLanguage, languageState =>
    languageState.getIn(['translations', locale, group])
  );
