import React from 'react';

const Risks = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M8.5457 16.073c-1.417-1.4172-2.2285-3.2562-2.4439-5.1719-.1705-1.5167.0886-2.916.486-3.3134L5.935 6.935c-.6422.6422-.9493 2.301-.7505 4.0692.2376 2.114 1.135 4.148 2.7083 5.7215l.6528-.6527zm7.1799-7.18c-1.5734-1.5735-3.6075-2.471-5.7215-2.7088-1.7682-.1988-3.427.1083-4.0692.7505l.6527.6527c.3974-.3974 1.7967-.6565 3.3134-.486 1.9157.2155 3.7547 1.027 5.1719 2.4443l.6527-.6527z" />
      <path d="M6.5877 6.9348l-.979-.9789-.6526.6527.9789.979zm5.7395 7.2542l6.4616 6c.709.7087 1.7653.7087 2.4864-.0118l.4617-.4617c.7212-.7206.7212-1.7782-.0002-2.499l-.923-.9229-.6526.6527.923.923c.3607.3604.3607.8332.0002 1.1934l-.4617.4617c-.3606.3602-.8329.3602-1.1936-.0002l-6.4737-6.0117-.628.6764z" />
      <path d="M11.7813 13.6736c-.4879-.003-1.18.3438-1.7669.931-.581.5807-.9268 1.264-.9309 1.7521-.0038.5048-.4194.8986-.9212.8854-.4537-.011-1.0654.2796-1.5747.789-.6416.6415-.9158 1.4264-.7365 1.8335.154.3525.104.7577-.1713 1.0321l-.0758.0758c-.3731.374-.9806.3426-1.325-.0544-3.127-3.6117-2.3816-9.9455 1.656-13.9835C9.9725 2.8973 16.3064 2.152 19.9183 5.279c.397.3445.428.952.0543 1.3248l-.0762.0761c-.2752.2753-.6803.3253-1.0326.1705-.4063-.1785-1.191.0956-1.833.7368-.5093.5097-.7998 1.1215-.7886 1.575.0123.502-.3806.9168-.8853.9215-.4883.0041-1.1721.35-1.753.9304-.5868.5873-.9331 1.279-.9301 1.7674a.8865.8865 0 0 1-.8926.892zm7.4625-7.6464l.0053-.0053-.0053.0053zm-14.217 14.217l-.0035.0036.0035-.0035zm6.7241-7.4936c.0098-.756.4646-1.6529 1.2004-2.3892.7294-.7289 1.6169-1.1825 2.3678-1.2002-.009-.7244.3907-1.5577 1.0592-2.2268.8835-.8824 2.042-1.2871 2.8569-.929.0124.0054.0352-.0072.0682-.0378-3.2205-2.7761-9.0036-2.092-12.7158 1.6197-3.712 3.7125-4.3962 9.4956-1.62 12.716l.0557-.0555c-.3764-.8272.0286-1.9865.9116-2.8695.668-.668 1.501-1.0676 2.226-1.0594.0182-.7517.4718-1.6385 1.2008-2.367.7357-.7362 1.6333-1.1916 2.3892-1.2013z" />
    </g>
  </svg>
);

Risks.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Risks;
