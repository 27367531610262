import React from 'react';

import Config from 'config';

const FolderStar = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 48 48" width={width} {...props}>
    <g fill={fill}>
      <path d="M16.6293 7l2.3158 2.3158c.188.1875.442.293.708.293h19v1.6086h2V8.6087c0-.5523-.4477-1-1-1H20.067L17.751 5.293c-.1876-.1876-.442-.293-.707-.293H5.739C4.227 5 3 6.2268 3 7.739v27.8262c0 2.065 1.7296 3.7116 3.8006 3.604 1.933-.1013 3.4168-1.7928 3.4168-3.7518V14.6957c0-.4078.3314-.7392.739-.7392H42.261c.4076 0 .739.3314.739.7392V37.174h-3.3478v2H44c.5523 0 1-.4478 1-1V14.6956c0-1.5123-1.2268-2.7392-2.739-2.7392H10.9564c-1.5123 0-2.739 1.227-2.739 2.7392v20.7217c0 .928-.687 1.7107-1.521 1.7544C5.7707 37.22 5 36.486 5 35.5652V7.7392C5 7.3312 5.3314 7 5.739 7h10.8903z" />
      <path d="M6.6087 39.174H24v-2H6.6087" />
      <path d="M29.7023 31.7253c-.1504.2842-.4283.4787-.7467.5227l-4.1858.579 2.9342 3.434c.1814.2122.2658.4908.2327.768l-.4748 3.9757 4.029-1.5614c.234-.0907.494-.09.727.002l4.0246 1.584-.4753-4.009c-.033-.2772.0517-.5557.2333-.768l2.932-3.4254-4.184-.579c-.3184-.044-.5962-.239-.747-.523l-2.15-4.062-2.15 4.0615zm1.2663-6.667c.3756-.7095 1.392-.7095 1.7676 0l2.7944 5.2788 5.4893.76c.783.1086 1.136 1.04.622 1.641l-3.836 4.482.6203 5.2347c.089.751-.6555 1.3252-1.3592 1.0483l-5.217-2.0536-5.2164 2.0215c-.7034.2723-1.4437-.302-1.3543-1.051l.6203-5.193-3.837-4.4903c-.5136-.601-.16-1.532.623-1.6402l5.4894-.7594 2.7943-5.278z" />
    </g>
  </svg>
);

FolderStar.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 48,
  width: 48,
};

export default FolderStar;
