import React, { FC, useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import { H2 } from 'components/Atoms/Heading';
import { Tooltip } from 'components/Atoms/Tooltip';
import { Link } from 'components/Atoms/Link';
import { Section } from 'components/Molecules/Section';
import { investmentPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

import {
  StyledChevron,
  StyledPaCaption,
  StyledTd,
  StyledTh,
  StyledWrapper,
  StyledYearsFull,
  StyledYearsShorthand,
} from './styledTable';
import { Property, Variant } from './types';

interface InvestInAPropertyTableSectionProps {
  properties: Property[];
  variant: Variant;
  visibleRowsCount?: number;
}

export const InvestInAPropertyTableSection: FC<InvestInAPropertyTableSectionProps> = ({
  properties,
  variant,
  visibleRowsCount = 4,
}) => {
  const t = useI18n()('molecules.InvestInAPropertyTableSection');
  const [expanded, setExpanded] = useState(false);
  const toggleTable = useCallback(
    () => setExpanded(prevState => !prevState),
    []
  );

  return (
    <Section size="large">
      <Box textAlign="center" mb={8}>
        <H2>{t`Choose to invest in any number of our properties`}</H2>
      </Box>
      <StyledWrapper>
        <table cellSpacing="0">
          <thead>
            <tr>
              <StyledTh>
                {variant === 'loans' && t`Property loan`}
                {variant === 'shares' && t`Property share`}
              </StyledTh>
              <StyledTh>
                <Tooltip title={t`Loan to value`}>
                  <Box component="span" fontWeight="bold">
                    {t`LTV`}
                  </Box>
                </Tooltip>
              </StyledTh>
              <StyledTh>{t`Term`}</StyledTh>
              <StyledTh>
                <Box component="span" position="relative">
                  {t`Rate`}
                  <StyledPaCaption className="paCaption">{t`p.a.`}</StyledPaCaption>
                </Box>
              </StyledTh>
            </tr>
          </thead>
          <tbody>
            {properties
              .sort((a, b) =>
                variant === 'loans'
                  ? b.rate - a.rate
                  : b.shareReturn - a.shareReturn
              )
              .slice(0, expanded ? properties.length : visibleRowsCount)
              .map(({ id, name, LTV, term, rate, shareReturn }) => (
                <tr key={id}>
                  <StyledTd>
                    <Link
                      to={investmentPaths.investmentById({ investmentId: id })}
                    >
                      {name}
                    </Link>
                  </StyledTd>
                  <StyledTd>{`${LTV}%`}</StyledTd>
                  <StyledTd>
                    {term}
                    <StyledYearsShorthand>{t`yrs`}</StyledYearsShorthand>
                    <StyledYearsFull>&nbsp;{t`years`}</StyledYearsFull>
                  </StyledTd>
                  <StyledTd>
                    {variant === 'loans' && `${rate.toFixed(2)}%`}
                    {variant === 'shares' && `${shareReturn.toFixed(2)}%`}
                  </StyledTd>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <StyledTd
                colSpan={4}
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={toggleTable}
              >
                {/*
                // @ts-ignore */}
                <StyledChevron open={expanded} height={40} width={40} />
              </StyledTd>
            </tr>
          </tfoot>
        </table>
      </StyledWrapper>
      <Typography variant="body1">
        {t`The above data is in relation to the initial offering as seen in the Info Pack and subject to availability, it is not applicable to investments in the Resale Market.`}
      </Typography>
    </Section>
  );
};
