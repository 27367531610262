/**
 * Default response transformers
 */
export default {
  array(data) {
    if (!data) {
      return [];
    }

    return Array.isArray(data) ? data : [data];
  },
  object(data) {
    if (!data) {
      return {};
    }
    return typeof data === 'object' && !Array.isArray(data) ? data : { data };
  },
};
