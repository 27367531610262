import * as type from './actionTypes';

export const removeBankRequest = id => ({
  type: type.BANK_REMOVE_REQUEST,
  id,
});

export const removeBankSuccess = () => ({
  type: type.BANK_REMOVE_SUCCESS,
});

export const removeBankError = errors => ({
  type: type.BANK_REMOVE_ERROR,
  error: true,
  payload: errors,
});

export const findAllBankRequest = () => ({
  type: type.BANK_FINDALL_REQUEST,
});
export const findAllBankSuccess = data => ({
  type: type.BANK_FINDALL_SUCCESS,
  data,
});
export const findAllBankError = errors => ({
  type: type.BANK_FINDALL_ERROR,
  error: true,
  payload: errors,
});

export const createBankRequest = (bank, accountId, amount, operation) => ({
  type: type.BANK_CREATE_REQUEST,
  bank,
  accountId,
  amount,
  operation,
});

export const createBankSuccess = bank => ({
  type: type.BANK_CREATE_SUCCESS,
  bank,
});

export const createBankError = errors => ({
  type: type.BANK_CREATE_ERROR,
  error: true,
  payload: errors,
});

export const withdrawRequest = (accountId, bankId, amount) => ({
  type: type.BANK_WITHDRAW_REQUEST,
  accountId,
  amount,
  bankId,
});

export const topupBankRequest = (accountId, bankId, amount) => ({
  type: type.BANK_TOPUP_REQUEST,
  accountId,
  bankId,
  amount,
});

export const topupBankSuccess = (referenceNumber, amount) => ({
  type: type.BANK_TOPUP_SUCCESS,
  referenceNumber,
  amount,
});

export const topupBankError = errors => ({
  type: type.BANK_TOPUP_ERROR,
  error: true,
  payload: errors,
});

export const withdrawSuccess = amount => ({
  type: type.BANK_WITHDRAW_SUCCESS,
  amount,
});

export const withdrawError = errors => ({
  type: type.BANK_WITHDRAW_ERROR,
  error: true,
  payload: errors,
});

export const findAllCardsRequest = () => ({
  type: type.CARDS_FINDALL_REQUEST,
});
export const findAllCardsSuccess = data => ({
  type: type.CARDS_FINDALL_SUCCESS,
  data,
});
export const findAllCardsError = errors => ({
  type: type.CARDS_FINDALL_ERROR,
  error: true,
  payload: errors,
});

export const createCardRequest = (accountId, card) => ({
  type: type.CARD_CREATE_REQUEST,
  accountId,
  card,
});

export const createCardSuccess = (card, amount) => ({
  type: type.CARD_CREATE_SUCCESS,
  card,
  amount,
});

export const createCardError = errors => ({
  type: type.CARD_CREATE_ERROR,
  error: true,
  payload: errors,
});

export const topupCardRequest = (accountId, cardId, amount) => ({
  type: type.CARD_TOPUP_REQUEST,
  accountId,
  cardId,
  amount,
});

export const topupCardSuccess = (card, amount) => ({
  type: type.CARD_TOPUP_SUCCESS,
  card,
  amount,
});

export const topupCardError = errors => ({
  type: type.CARD_TOPUP_ERROR,
  error: true,
  payload: errors,
});

export const removeCardRequest = id => ({
  type: type.CARD_REMOVE_REQUEST,
  payload: id,
});

export const removeCardSuccess = id => ({
  type: type.CARD_REMOVE_SUCCESS,
  payload: id,
});

export const removeCardError = errors => ({
  type: type.CARD_REMOVE_ERROR,
  error: true,
  payload: errors,
});

export const transferRequest = (
  sourceAccountId,
  destinationAccountId,
  amount
) => ({
  type: type.TRANSFER_REQUEST,
  sourceAccountId,
  destinationAccountId,
  amount,
});

export const transferSuccess = (
  sourceAccountId,
  destinationAccountId,
  amount
) => ({
  type: type.TRANSFER_SUCCESS,
  sourceAccountId,
  destinationAccountId,
  amount,
});

export const transferError = errors => ({
  type: type.TRANSFER_ERROR,
  error: true,
  payload: errors,
});

export const cardsReset = () => ({
  type: type.CARDS_RESET,
});

export const bankReset = () => ({
  type: type.BANK_RESET,
});

export const transferReset = () => ({
  type: type.TRANSFER_RESET,
});

export const createThreeDSecureRequest = action => ({
  type: type.THREE_D_SECURE_REQUEST,
  payload: action,
});

export const threeDSecureGenericError = () => ({
  type: type.THREE_D_SECURE_GENERIC_ERROR,
});

export const fetchCardTransaction = action => {
  const { accountId, uuid } = action;
  return {
    type: type.FETCH_CARD_TRANSACTION,
    accountId,
    uuid,
  };
};

export const fetchCardTransactionSuccess = action => ({
  type: type.FETCH_CARD_TRANSACTION_SUCCESS,
  status: action.state,
  amount: action.amount,
});

export const cardTransactionReset = () => ({
  type: type.CARD_TRANSACTION_RESET,
});
