import React from 'react';

import Config from 'config';

const Tick = ({ height, width, color, ...props }) => (
  <svg height={height} viewBox="0 0 25 19" width={width} {...props}>
    <path
      d="M8.168 19c-.65 0-1.301-.243-1.797-.73L.743 12.748a2.45 2.45 0 0 1 0-3.517 2.565 2.565 0 0 1 3.584 0l3.83 3.76L20.674.73a2.565 2.565 0 0 1 3.584 0 2.45 2.45 0 0 1 0 3.516L9.964 18.27a2.558 2.558 0 0 1-1.797.73z"
      fill={color}
    />
  </svg>
);

Tick.defaultProps = {
  color: Config.theme.colors.pomegranate,
  height: 19,
  width: 25,
};

export default Tick;
