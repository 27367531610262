import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useI18n } from 'utils/i18nConnect';
import { Button, ButtonLink } from 'components/Atoms/Button';
import { commonPaths } from 'router/paths';

const ReaccreditationDialog = ({ disallowSkip, onClose }) => {
  const i18n = useI18n();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="reaccreditation-dialog-title"
      aria-describedby="reaccreditation-dialog-description"
      fullScreen={fullScreen}
      disableBackdropClick
    >
      <DialogTitle id="reaccreditation-dialog-title">
        {i18n('ReaccreditationDialog')`Reaccreditation`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="reaccreditation-dialog-description">
          {i18n(
            'ReaccreditationDialog'
          )`FCA rules require us to categorise our investors so we are sure that you understand the risks of investing.
            Recent regulatory changes and updates to the platform mean that we are asking you to update your status.
            Please action so that you can access your account.  If you have any questions, please contact us.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!disallowSkip && (
          <Button
            data-test-id="reaccreditation-dialog.skip-button"
            onClick={onClose}
            color="primary"
            size="medium"
          >
            {i18n('ReaccreditationDialog')`Ask me later`}
          </Button>
        )}
        <ButtonLink
          data-test-id="reaccreditation-dialog.accept-button"
          variant="contained"
          color="primary"
          to={commonPaths.reaccreditation()}
          onClick={onClose}
          size="medium"
          autoFocus
        >
          {i18n('ReaccreditationDialog')`Update now`}
        </ButtonLink>
      </DialogActions>
    </Dialog>
  );
};

ReaccreditationDialog.propTypes = {
  disallowSkip: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ReaccreditationDialog.defaultProps = {
  disallowSkip: false,
};

export { ReaccreditationDialog };
