import i18n from 'es2015-i18n-tag';

import { i18nConnect } from './i18nConnect';
import { useI18n } from './useI18n';
import LanguageProvider from './LanguageProvider';

export const i18nTest = i18n;

export { LanguageProvider, i18nConnect, useI18n };

export default i18nConnect;
