import { get, pickBy, identity } from 'lodash';

import { Pip } from '../pip.models';
import {
  StateTransformer,
  HpiValuesView,
  PropertyDetailed,
  PropertyExpectedCapitalGain,
  PropertyExpectedIncome,
  PropertyFinancialDetails,
  PropertyLoanInvestorReturn,
  PropertyPreLetExpense,
  PropertyPurchaseCost,
  PropertyReturns,
  PropertyShareInvestorReturn,
  PropertyView,
  Yearly,
  YearlyPropertyExpectedCapitalGain,
  YearlyPropertyExpectedIncome,
  YearlyPropertyLoanInvestorReturn,
  YearlyPropertyReturns,
  YearlyPropertyShareInvestorReturn,
  ImageShape,
} from '../types';
import { flattenValue, optional } from './generic';

export const pipImageViewToImageShape = (
  img: Pip.PropertyImageView
): ImageShape => ({
  id: img.id,
  title: img.title,
  sourcesBySize: img.resizedImages.reduce(
    (sourcesBySize, resizedImg) => ({
      ...sourcesBySize,
      [Object.keys(resizedImg)[0]]: Object.values(resizedImg)[0],
    }),
    {}
    // force cast as we're not able to tell TS that all sizes are properly assigned - feel free to improve it
  ) as ImageShape['sourcesBySize'],
});

export function yearlyTransformer<A, B>(
  transformer: StateTransformer<A, B>,
  data: Yearly<A>
): Yearly<B> {
  return pickBy(
    {
      total: optional(transformer, data.total),
      year5: optional(transformer, data.year5),
      year3: optional(transformer, data.year3),
      year1: optional(transformer, data.year1),
    },
    identity
  );
}

export const pipPropertyExpectedIncomeViewV2ToPropertyExpectedIncome: StateTransformer<
  Pip.PropertyExpectedIncomeViewV2,
  PropertyExpectedIncome
> = data => ({
  sum: data.sum.value,
  netRentalIncomePercent: data.netRentalIncomePercent.value,
  corporationTax: data.corporationTax.value,
  spvReserve: data.spvReserve.value,
  lettingsAndManagementFeeAgents: data.lettingsAndManagementFeeAgents.value,
  landlordLegalObligations: data.landlordLegalObligations.value,
  groundRentAndServiceCharge: data.groundRentAndServiceCharge.value,
  serviceCharge: data.serviceCharge.value,
  groundRent: data.groundRent.value,
  insurance: data.insurance.value,
  mortgage: data.mortgage.value,
  grossRentalIncome: data.grossRentalIncome.value,
});

export const pipYearlyPropertyExpectedIncomeViewToYearlyPropertyExpectedIncome: StateTransformer<
  Pip.YearlyPropertyExpectedIncomeView,
  YearlyPropertyExpectedIncome
> = data =>
  yearlyTransformer(
    pipPropertyExpectedIncomeViewV2ToPropertyExpectedIncome,
    data
  );

export const pipPropertyShareInvestorReturnViewV2ToPropertyShareInvestorReturn: StateTransformer<
  Pip.PropertyShareInvestorReturnViewV2,
  PropertyShareInvestorReturn
> = data => ({
  capitalGainAccumulated: data.capitalGainAccumulated.value,
  capitalGain: data.capitalGain.value,
  dividendReturn: data.dividendReturn.value,
  totalShareInvestorReturn: data.totalShareInvestorReturn.value,
});

export const pipYearlyPropertyShareInvestorReturnViewToYearlyPropertyShareInvestorReturn: StateTransformer<
  Pip.YearlyPropertyShareInvestorReturnView,
  YearlyPropertyShareInvestorReturn
> = data =>
  yearlyTransformer(
    pipPropertyShareInvestorReturnViewV2ToPropertyShareInvestorReturn,
    data
  );

export const pipPropertyExpectedCapitalGainViewToPropertyExpectedCapitalGain: StateTransformer<
  Pip.PropertyExpectedCapitalGainViewV2,
  PropertyExpectedCapitalGain
> = data => ({
  capitalGain: data.capitalGain.value,
  capitalGainNominal: data.capitalGainNominal.value,
});

export const pipYearlyPropertyExpectedCapitalGainViewToYearlyPropertyExpectedCapitalGain: StateTransformer<
  Pip.YearlyPropertyExpectedCapitalGainView,
  YearlyPropertyExpectedCapitalGain
> = data =>
  yearlyTransformer(
    pipPropertyExpectedCapitalGainViewToPropertyExpectedCapitalGain,
    data
  );

export const pipPropertyPurchaseCostViewToPropertyPurchaseCost: StateTransformer<
  Pip.PropertyPurchaseCostView,
  PropertyPurchaseCost
> = data => ({
  sum: get(data, ['propertyPurchaseCostSum', 'value']),
  underwriting: get(data, ['propertyPurchaseCost', 'underwriting', 'value']),
  propertyFindingAgent: get(data, [
    'propertyPurchaseCost',
    'propertyFindingAgent',
    'value',
  ]),
  mortgageArrangement: get(data, [
    'propertyPurchaseCost',
    'mortgageArrangement',
    'value',
  ]),
  valuation: get(data, ['propertyPurchaseCost', 'valuation', 'value']),
  legalAndSearch: get(data, [
    'propertyPurchaseCost',
    'legalAndSearch',
    'value',
  ]),
  stampDuty: get(data, ['propertyPurchaseCost', 'stampDuty', 'value']),
  property: get(data, ['propertyPurchaseCost', 'property', 'value']),
});

export const pipPropertyLoanInvestorReturnViewToPropertyLoanInvestorReturn: StateTransformer<
  Pip.PropertyLoanInvestorReturnViewV2,
  PropertyLoanInvestorReturn
> = data => ({
  interestReturn: data.interestReturn.value,
});

export const pipYearlyPropertyLoanInvestorReturnViewToYearlyPropertyLoanInvestorReturn: StateTransformer<
  Pip.YearlyPropertyLoanInvestorReturnView,
  YearlyPropertyLoanInvestorReturn
> = data =>
  yearlyTransformer(
    pipPropertyLoanInvestorReturnViewToPropertyLoanInvestorReturn,
    data
  );

export const pipPropertyReturnsViewToPropertyReturns: StateTransformer<
  Pip.PropertyReturnsItemsView,
  PropertyReturns
> = data => ({
  propertyReturn: data.propertyReturn.value,
  capitalGainPercent: data.capitalGainPercent.value,
  capitalGainAmount: data.capitalGainAmount.value,
  netRentalIncomePercent: data.netRentalIncomePercent.value,
  netRentalIncomeAmount: data.netRentalIncomeAmount.value,
  grossRentalIncome: data.grossRentalIncome.value,
  mortgage: data.mortgage.value,
  insurance: data.insurance.value,
  groundRent: data.groundRent.value,
  serviceCharge: data.serviceCharge.value,
  landlordLegalObligations: data.landlordLegalObligations.value,
  lettingsAndManagementFeeAgents: data.lettingsAndManagementFeeAgents.value,
  spvReserve: data.spvReserve.value,
  corporationTax: data.corporationTax.value,
});

export const pipYearlyPropertyReturnsViewToYearlyPropertyReturns: StateTransformer<
  Pip.YearlyPropertyReturnsView,
  YearlyPropertyReturns
> = data => yearlyTransformer(pipPropertyReturnsViewToPropertyReturns, data);

export const pipPropertyPreLetExpenseViewToPropertyPreLetExpense: StateTransformer<
  Pip.PropertyPreLetExpenseView,
  PropertyPreLetExpense
> = data => ({
  sum: get(data, ['propertyPreLetExpenseSum', 'value']),
  spvReserve: get(data, ['propertyPreLetExpense', 'spvReserve', 'value']),
  insurance: get(data, ['propertyPreLetExpense', 'insurance', 'value']),
  furniture: get(data, ['propertyPreLetExpense', 'furniture', 'value']),
  refurbishment: get(data, ['propertyPreLetExpense', 'refurbishment', 'value']),
});

export const pipPropertyFinancialDetailViewV2ToPropertyFinancialDetails: StateTransformer<
  Pip.PropertyFinancialDetailViewV2,
  PropertyFinancialDetails
> = input => ({
  purchaseCost: optional(
    pipPropertyPurchaseCostViewToPropertyPurchaseCost,
    input.purchaseCost
  ),
  preLetExpense: optional(
    pipPropertyPreLetExpenseViewToPropertyPreLetExpense,
    input.preLetExpense
  ),
  propertyReturns: pipYearlyPropertyReturnsViewToYearlyPropertyReturns(
    input.propertyReturns
  ),
  expectedIncomes: pipYearlyPropertyExpectedIncomeViewToYearlyPropertyExpectedIncome(
    input.expectedIncomes
  ),
  expectedCapitalGains: pipYearlyPropertyExpectedCapitalGainViewToYearlyPropertyExpectedCapitalGain(
    input.expectedCapitalGains
  ),
  loanInvestorReturns: pipYearlyPropertyLoanInvestorReturnViewToYearlyPropertyLoanInvestorReturn(
    input.loanInvestorReturns
  ),
  shareInvestorReturns: pipYearlyPropertyShareInvestorReturnViewToYearlyPropertyShareInvestorReturn(
    input.shareInvestorReturns
  ),
});

export const pipHpiValuesViewToHpiValues: StateTransformer<
  Pip.HpiValuesView,
  HpiValuesView
> = input => ({
  year1: optional(flattenValue, input.year1),
  year3: optional(flattenValue, input.year3),
  year5: optional(flattenValue, input.year5),
  total: optional(flattenValue, input.total),
});

export const pipPropertyViewV2ToProperty: StateTransformer<
  Pip.PropertyViewV2,
  PropertyView
> = input => ({
  id: input.id,
  title: input.title,
  addressId: input.addressId,
  dataAreaSource: input.dataAreaSource,
  ownershipType: input.ownershipType,
  internalAreaSqFt: input.internalAreaSqFt?.value,
  propertyType: input.propertyType,
  tenantReady: input.tenantReady,
  propertyDetails: input.propertyDetails,
  hpiValues: pipHpiValuesViewToHpiValues(input.hpiValues),
});

export const pipPropertyDetailedViewV2ToPropertyDetailed: StateTransformer<
  Pip.PropertyDetailedViewV2,
  PropertyDetailed
> = input => ({
  property: pipPropertyViewV2ToProperty(input.property),
  address: input.address,
  amenities: input.amenities,
  tagIds: input.tagIds,
  cover: input.cover ? pipImageViewToImageShape(input.cover) : undefined,
  gallery: input.gallery.map(pipImageViewToImageShape),
  documents: input.documents,
  floorPlans: input.floorPlans,
  financialDetails: pipPropertyFinancialDetailViewV2ToPropertyFinancialDetails(
    input.financialDetails
  ),
});
