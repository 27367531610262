import {
  INVESTMENTS_BY_ID_GET_ERROR,
  INVESTMENTS_BY_ID_GET_REQUEST,
  INVESTMENTS_BY_ID_GET_SUCCESS,
  INVESTMENTS_GET_ERROR,
  INVESTMENTS_GET_REQUEST,
  INVESTMENTS_GET_SUCCESS,
} from './actionTypes';
import { InvestmentsActions, InvestmentsState } from './types';

const initialState: InvestmentsState = {
  byId: {},
  allIds: {
    data: [],
    error: undefined,
    loading: false,
  },
  comingSoon: [],
};

export default (state = initialState, action: InvestmentsActions) => {
  switch (action.type) {
    case INVESTMENTS_GET_REQUEST:
      return {
        ...state,
        allIds: {
          ...state.allIds,
          loading: true,
          error: undefined,
        },
      };
    case INVESTMENTS_GET_SUCCESS:
      return {
        ...state,
        allIds: {
          data: action.payload.investments.map(investment => investment.id),
          loading: false,
          error: undefined,
        },
        byId: Object.fromEntries(
          action.payload.investments.map(investment => [
            investment.id,
            {
              data: investment,
              error: undefined,
              loading: false,
            },
          ])
        ),
        comingSoon: action.payload.comingSoonInvestments,
      };
    case INVESTMENTS_GET_ERROR:
      return {
        ...state,
        allIds: {
          ...state.allIds,
          loading: false,
          error: action.payload,
        },
      };
    case INVESTMENTS_BY_ID_GET_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            data: state.byId[action.payload.id]
              ? state.byId[action.payload.id]
              : undefined,
            loading: true,
            error: undefined,
          },
        },
      };
    case INVESTMENTS_BY_ID_GET_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            data: action.payload,
            loading: false,
            error: undefined,
          },
        },
      };
    case INVESTMENTS_BY_ID_GET_ERROR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            data: state.byId[action.payload.id]
              ? state.byId[action.payload.id]
              : undefined,
            loading: false,
            error: action.payload.error,
          },
        },
      };
    default:
      return state;
  }
};
