import React from 'react';

import Config from 'config';

const BuildPortfolio = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 64 64" width={width} {...props}>
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <g stroke={fill2} strokeLinecap="round">
        <path d="M37.0456 22.944v8.8802h5.328v-6.216h4.4402v6.216h1.7118l3.2017-1.6623c.1394-.2716.2774-.5328.4146-.784v-5.99" />
        <path
          d="M39.2253 17.6563l5.3685-5.3686L54.8058 22.5"
          strokeLinejoin="round"
        />
        <path d="M48.1458 13.1758h3.108v3.108" />
      </g>
      <g stroke={fill2} strokeLinecap="round">
        <path d="M18.5182 21.1276v8.9058c1.161-.7222 2.4726-1.2806 3.6182-1.2806h3.163v-4.2346h5.651v4.867c1.3462.831 2.6542 2.1858 3.469 2.1858h3.3124v-9.8783" />
        <path
          d="M15.1276 20.5625L28.125 7.565l12.9974 12.9975"
          strokeLinejoin="round"
        />
        <path d="M32.6458 8.6953h3.9558v3.9557" />
      </g>
      <g stroke={fill} strokeLinejoin="round">
        <path d="M22.1364 48.48c.5396-.5396 1.092-1.409 2.818-1.409H44.682c1.5655 0 10.178-8.6363 11.2727-9.8636C57.007 36.0266 61.6995 28.977 63 25.9347c-.8173-1.1005-3.0253-2.015-5.6364-1.409-2.4912.579-3.9567 2.367-5.6363 5.6362l-6.4593 3.3535" />
        <path d="M30.591 38.6165h11.2726c5.6364 0 5.6364-7.0455 0-7.0455h-7.4442c-1.1752 0-3.3762-2.8182-5.2376-2.8182h-7.0454c-1.905 0-4.2696 1.5444-5.6364 2.8182l-5.6364 5.6364" />
        <path d="M17.909 58.3438l7.0455-7.0456-16.909-16.909L1 41.4346zm-9.8635-16.909l2.818 2.818" />
      </g>
    </g>
  </svg>
);

BuildPortfolio.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default BuildPortfolio;
