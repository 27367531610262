import React, { useEffect } from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import PropTypes from 'prop-types';

import Config from 'config';
import { useAppBarHeight } from 'components/Organisms/AppBar';
import { useI18n } from 'utils/i18nConnect';
import media from 'utils/responsive';

const SuccessDialog = styled.div`
  background: ${Config.theme.colors.white};
  border-radius: 1.25rem;
  color: ${Config.theme.colors.black};
  margin: 3rem auto 2rem;
  padding: 2rem;
  text-align: center;

  ${media.medium`
    padding: 2.125rem 2.5rem;
  `};
`;

const SuccessTitle = styled.div`
  color: ${Config.theme.colors.pomegranate};
  font-size: 1.5rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 2.125rem;
  margin: 1rem auto 2.125rem;
  overflow-wrap: break-word;
`;

const Email = styled.span`
  word-break: break-all;
`;

const SuccessCaption = styled.div`
  font-size: 1.125rem;
  font-weight: ${Config.theme.fontWeight.book};
  line-height: 1.625rem;
  margin: 0 auto 1rem;
  max-width: 34.25rem;
`;

export const VerificationEmailSentMessage = ({
  className,
  email,
  scrollToOnMount,
}) => {
  const i18n = useI18n();
  const appBarHeight = useAppBarHeight();

  useEffect(() => {
    scroller.scrollTo(scrollToOnMount, { offset: -appBarHeight });
  });

  return (
    <SuccessDialog className={className}>
      <SuccessTitle>
        <span data-test-id="register-thankyou-message">
          {i18n(
            'VerificationEmailSentMessage'
          )`Thank you, we have sent a verification email to`}
          <br />
          <Email>{email}</Email>
          <br />
          {i18n('VerificationEmailSentMessage')`Please check your inbox.`}
        </span>
      </SuccessTitle>
      <SuccessCaption>
        {i18n(
          'RegisterForm'
        )`If you didn’t get an email from British Pearl, try checking your spam folder`}
      </SuccessCaption>
    </SuccessDialog>
  );
};

VerificationEmailSentMessage.defaultProps = {
  className: '',
  scrollToOnMount: 0,
};

VerificationEmailSentMessage.propTypes = {
  className: PropTypes.string,
  scrollToOnMount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  email: PropTypes.string.isRequired,
};

export default VerificationEmailSentMessage;
