import React from 'react';
import * as PropTypes from 'prop-types';

const TaxStatementIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6926 13.4133C16.611 13.341 15.571 12.4191 14.6671 11.6178C13.9574 10.9887 13.6622 10.0831 13.8741 9.21348C14.2177 7.80373 13.6642 6.29902 12.3815 5.35253C10.4326 3.91444 7.4703 4.19117 5.93311 6.05473C5.27661 6.85063 5.00144 7.82473 5.10839 8.77339C5.38372 11.2149 7.99783 12.7048 10.4655 12.233C11.4523 12.0443 12.4798 12.3078 13.1936 12.9405C14.1159 13.7581 15.1767 14.6984 15.2098 14.7277C15.4145 14.9092 15.6829 14.9999 15.9512 14.9999C16.2196 14.9999 16.4879 14.9092 16.6926 14.7277C17.1021 14.3648 17.1021 13.7763 16.6926 13.4133ZM10.7626 11.3413C8.63 12.0374 6.28621 10.813 5.99434 8.77339C5.72539 6.894 7.35328 5.22256 9.5408 5.22233C10.6498 5.22222 11.6876 5.66607 12.3815 6.48403C13.7913 8.14579 13.0252 10.6028 10.7626 11.3413Z"
      fill="currentColor"
    />
    <path
      d="M2.60922 5.11597H6.07414C8.09546 3.43526 11.2237 3.53735 13.117 5.22946V0.718113C13.117 0.321512 12.7543 0 12.3069 0H6.64937C4.89692 0 3.21624 0.617082 1.97701 1.71554L1.93541 1.75242C0.696181 2.85088 0 4.34073 0 5.89421V13.1713C0 13.5679 0.362701 13.8894 0.810154 13.8894H12.3069C12.6074 13.8894 12.8696 13.7444 13.0094 13.5291C13.0045 13.5247 12.9996 13.5204 12.9947 13.516C12.3134 12.9121 11.3431 12.6406 10.3937 12.7864C8.74609 13.0394 7.05714 12.5392 5.90507 11.4921H2.60922C2.3167 11.4921 2.07517 11.284 2.07393 11.0248C2.07269 10.7647 2.31006 10.5533 2.60348 10.5533H5.11775C4.7889 10.0266 4.57085 9.42335 4.50628 8.77346H2.60922C2.3167 8.77346 2.07517 8.56536 2.07393 8.30606C2.07269 8.046 2.31006 7.83463 2.60348 7.83463H4.52269C4.60723 7.20935 4.83813 6.60203 5.21287 6.05479H2.60348C2.31041 6.05479 2.07295 5.84377 2.07393 5.58375C2.07496 5.3243 2.31653 5.11597 2.60922 5.11597ZM4.51091 2.39734H10.5135C10.806 2.39734 11.0431 2.60749 11.0431 2.86675C11.0431 3.12601 10.806 3.33616 10.5135 3.33616H4.51091C4.21848 3.33616 3.98136 3.12601 3.98136 2.86675C3.9814 2.60757 4.21843 2.39734 4.51091 2.39734Z"
      fill="currentColor"
    />
    <path
      d="M8.87827 10.6043L6.64307 8.62291L7.40352 7.94878L8.87827 9.2561L11.6537 6.7959L12.4141 7.46999L8.87827 10.6043Z"
      fill="currentColor"
    />
  </svg>
);

TaxStatementIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

TaxStatementIcon.defaultProps = {
  width: 17,
  height: 15,
};

export default TaxStatementIcon;
