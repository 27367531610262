import React from 'react';
import { Box } from '@material-ui/core';
import i18n from 'es2015-i18n-tag';

import { ExternalLink } from 'components/Atoms/ExternalLink';
import { Text } from 'components/Atoms/Text';

export const questions = [
  {
    question: i18n('IsaLandingPage')`What is an ISA?`,
    answer: (
      <>
        <Text paragraph>
          {i18n(
            'IsaLandingPage'
          )`An Individual Savings Account (ISA) is a tax-efficient way for UK consumers to save or invest. Each year, UK residents can save up to their annual allowance (£20,000 for 2018/19 and 2019/20) in ISA accounts. Earnings on these accounts are exempt from Income Tax and Capital Gains Tax.`}
        </Text>
        <Text paragraph>
          {i18n(
            'IsaLandingPage'
          )`There are currently four types of ISA: cash (FSCS protected), stocks and shares, innovative finance and lifetime (FSCS protected). `}
        </Text>
        <Text paragraph>
          {i18n(
            'IsaLandingPage'
          )`Savers and investors can fund one of each of these per year, so long as the total capital doesn't exceed their annual allowance. British Pearl offer an Innovative Finance ISA (IFISA).`}
        </Text>
        <Text paragraph>
          <Box component="span" fontWeight="bold">
            {i18n(
              'IsaLandingPage'
            )`When you invest with British Pearl, your capital is at risk. No FSCS on invested sums. Tax treatment depends on individual circumstances and may change.`}
          </Box>
        </Text>
        <Text>
          <Box component="span" fontWeight="bold">
            {i18n(
              'IsaLandingPage'
            )`For more information please see the HMRC website `}
            <ExternalLink href="https://www.gov.uk/individual-savings-accounts">
              {i18n('LoansPage')`here`}
            </ExternalLink>
          </Box>
        </Text>
      </>
    ),
  },
  {
    question: i18n('IsaLandingPage')`Who can open a British Pearl IFISA?`,
    answer: (
      <>
        <Text paragraph>
          {i18n(
            'IsaLandingPage'
          )`To be eligible to open and invest in a British Pearl ISA you need to be a UK resident for tax purposes and aged 18 or over.`}
        </Text>
        <Text paragraph>
          {i18n(
            'IsaLandingPage'
          )`You can have multiple ISAs but you can only open one of each type in a tax year. The current tax year runs from 6 April 2019 to 5 April 2020.`}
        </Text>
        <Text>
          <Box component="span" fontWeight="bold">
            {i18n(
              'IsaLandingPage'
            )`For more information please see the HMRC website `}
            <ExternalLink href="https://www.gov.uk/individual-savings-accounts">
              {i18n('IsaLandingPage')`here`}
            </ExternalLink>
            {i18n('IsaLandingPage')`.`}
          </Box>
        </Text>
      </>
    ),
  },
  {
    question: i18n(
      'IsaLandingPage'
    )`Can I have a standard British Pearl account as well?`,
    answer: (
      <Text>
        {i18n(
          'IsaLandingPage'
        )`Absolutely. The two accounts work in the same way. The only difference is that you do not have to pay any income or capital gains tax on loans held in your British Pearl ISA account.`}
      </Text>
    ),
  },
  {
    question: i18n('IsaLandingPage')`How much can I invest?`,
    answer: (
      <Text>
        {i18n(
          'IsaLandingPage'
        )`The total amount you can save in ISAs in the current tax year is £20,000. This is known as the ISA allowance. The current tax year runs from 6 April 2019 to 5 April 2020. You can take advantage of your allowance by opening a British Pearl ISA. If you have any existing ISAs, you can transfer funds from them over to us. Bear in mind that you cannot carry over any unused allowance from one tax year to another. The ISA allowance resets back to the annual limit set by the government.`}
      </Text>
    ),
  },
  {
    question: i18n('IsaPage')`How secure are the loans?`,
    answer: (
      <Text>
        {i18n(
          'IsaPage'
        )`The loans are backed by the individual property that they are against. This means that the loan repayments are paid first when the property is sold before any cash is distributed to the share Investors.`}
      </Text>
    ),
  },
  {
    question: i18n(
      'IsaLandingPage'
    )`Can I buy both shares and loans in my ISA?`,
    answer: (
      <Text>
        {i18n(
          'IsaLandingPage'
        )`You are currently only able to buy loans in the ISA.`}
      </Text>
    ),
  },
  {
    question: i18n('IsaLandingPage')`Is this ISA flexible?`,
    answer: (
      <Text>
        {i18n(
          'IsaLandingPage'
        )`Yes, the British Pearl ISA is 'Flexible'. This means that if you take any cash out of your British Pearl ISA you can replace it during the same tax year without using up any of your annual allowance. So, if you had used up all of your allowance but needed to take out £5,000 for an emergency, you would be able to put £5,000 back in before the end of the tax year.`}
      </Text>
    ),
  },
  {
    question: i18n('IsaLandingPage')`What are the charges?`,
    answer: (
      <Text>
        {i18n(
          'IsaLandingPage'
        )`There are no additional charges for opening a British Pearl ISA, transferring ISAs over or the day to day management of your ISA. All fees are exactly the same as the standard British Pearl account.`}
      </Text>
    ),
  },
];
