import { Pip } from 'services/Pip';

import { INVESTMENT_STATES_ALLOWED_TO_INVEST } from './constants';

// TODO fix types - current one is just based on old JS implementation
export const hasPortfolio = (
  portfolio?: Record<string, { shares: number; loans: number }>
): boolean =>
  !!(
    portfolio &&
    Object.values(portfolio).reduce(
      (acc, { shares, loans }) => acc + shares + loans,
      0
    )
  );

export const isInvestmentStateAllowingToInvest = (
  state: Pip.InvestmentState
): boolean => INVESTMENT_STATES_ALLOWED_TO_INVEST.includes(state);

interface CalculatePercentOfInvestmentFundraiseProps {
  investmentState: Pip.InvestmentState;
  initialQuantity: number;
  fundedQuantity: number;
}

export const calculatePercentOfInvestmentFundraise = ({
  investmentState,
  initialQuantity,
  fundedQuantity,
}: CalculatePercentOfInvestmentFundraiseProps): number => {
  const fundraisingStopped =
    investmentState === Pip.InvestmentState.Closing ||
    investmentState === Pip.InvestmentState.Closed;

  return fundraisingStopped
    ? 100
    : Math.floor((fundedQuantity / initialQuantity) * 100);
};
