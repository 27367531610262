import { createStyles, makeStyles } from '@material-ui/core/styles';

export const usePaperStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: palette.common.black,
      color: palette.common.white,
      minWidth: '200px',
    },
  })
);

export const useStyles = makeStyles(({ palette }) =>
  createStyles({
    popper: {
      zIndex: 1,
    },
    arrow: {
      position: 'absolute',
      top: '-10px',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: `10px solid ${palette.common.black}`,
    },
    link: {
      '&.active, &.active:hover, &.active:focus': {
        color: palette.primary.main,
      },
    },
  })
);
