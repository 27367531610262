import React, { FC } from 'react';

import { MenuNavLinkItem } from '../../types';
import { NavLinkItem, NavButtonItem } from '../NavItem';
import { NavigationMenu } from '../NavigationMenu';

import { useStyles } from './Navigation.styles';

interface NavigationProps {
  navLinks: MenuNavLinkItem[];
}

export const Navigation: FC<NavigationProps> = ({ navLinks }) => {
  const classes = useStyles();

  return (
    <div data-test-id="desktop-navigation" className={classes.menu}>
      {navLinks.map(({ label, to }) => (
        <div className={classes.menuItem} data-test-id={label} key={label}>
          {Array.isArray(to) ? (
            <NavigationMenu navLinks={to} component={NavButtonItem}>
              {label}
            </NavigationMenu>
          ) : (
            <NavLinkItem to={to} key={label}>
              {label}
            </NavLinkItem>
          )}
        </div>
      ))}
    </div>
  );
};
