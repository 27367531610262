import { LoggerProvider } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class LoggerService {
  private readonly provider: LoggerProvider;

  public constructor(provider: LoggerProvider) {
    this.provider = provider;
  }

  public log = (...args: any[]): unknown => this.provider.log(...args);

  public info = (...args: any[]): unknown => this.provider.info(...args);

  public warn = (...args: any[]): unknown => this.provider.warn(...args);

  public error = (...args: any[]): unknown => this.provider.error(...args);
}
