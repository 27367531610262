import React from 'react';

const SidebarDashboard = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3" />
      <path
        d="M5.25 12.75h6v-7.5h-6v7.5zm0 6h6v-4.5h-6v4.5zm7.5 0h6v-7.5h-6v7.5zm0-13.5v4.5h6v-4.5h-6z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarDashboard.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarDashboard;
