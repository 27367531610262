import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    listItem: {
      fontWeight: 'bold',
    },
    listItemIndent: {
      paddingLeft: spacing(3),
    },
  })
);
