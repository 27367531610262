import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ breakpoints, spacing }) =>
  createStyles({
    gridContainer: {
      minHeight: '100%',
    },
    drawer: {
      display: 'block',
      width: '100%',
      [breakpoints.up('sm')]: {
        width: '320px',
      },
    },
    close: {
      marginRight: spacing(0.5),
      [breakpoints.up('sm')]: {
        marginRight: spacing(1.5),
      },
    },
  })
);
