import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from 'components/Organisms/AppBar';
import { Box } from '@material-ui/core';
import { Footer } from 'components/Organisms/footer';
import withBase from '../Base';

const PublicTemplate = ({
  children,
  renderBanner,
  footerClearFixClassName,
}) => (
  <>
    <AppBar />
    {React.Children.toArray(children)}
    <Footer />
    {footerClearFixClassName && (
      <Box bgcolor="grey.200" className={footerClearFixClassName} />
    )}
    {renderBanner && renderBanner()}
  </>
);

export default withBase(PublicTemplate);

PublicTemplate.defaultProps = {
  renderBanner: null,
  footerClearFixClassName: '',
};

PublicTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  renderBanner: PropTypes.func,
  footerClearFixClassName: PropTypes.string,
};
