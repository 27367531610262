import React from 'react';
import styled from 'styled-components';

import Config from 'config';
import { i18nConnect } from 'utils/i18nConnect';

import media from 'utils/responsive';

const Wrapper = styled.div`
  background-color: ${Config.theme.colors.black};
  color: ${Config.theme.colors.white};
  margin: 0 auto 1.5rem;
  padding: 1.5rem 0;
  position: relative;
  width: 100%;

  ${media.medium`
    margin-bottom: 3rem;
  `} ${media.large`
    margin-bottom: 4rem;
    padding: 2rem 0;
  `};
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 1.2;

  ${media.medium`
    font-size: 1.5rem;
  `};
`;

const Content = styled.div`
  font-size: .875rem;
  margin: 0 auto;
  max-width: 87.5rem;
  overflow: hidden;
  padding: 0 1.5rem;

  ${media.medium`
    padding: 0 3rem;
  `}

  ${media.large`
    font-size: .9375rem;
    padding: 0 4rem;
  `}

  ${media.wide`
    font-size: 1rem;
  `}
`;

const MessageBlock = ({ title, children, className }) => (
  <Wrapper className={className}>
    <Content>
      {title && <Title>{title}</Title>}

      {React.Children.toArray(children)}
    </Content>
  </Wrapper>
);

MessageBlock.defaultProps = {
  className: '',
};

export default i18nConnect(MessageBlock);
