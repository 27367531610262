import React from 'react';

const ChatQuestion = props => (
  <svg viewBox="0 0 79 79" fill="currentColor" {...props}>
    <path d="M27.8506 41.8145H32.4795V46.4434H27.8506V41.8145Z" />
    <path d="M79 53.4639C79 41.9489 71.2412 31.8882 60.2999 28.8565C59.6122 12.8283 46.3596 0 30.165 0C13.5317 0 0 13.5317 0 30.165C0 35.5859 1.44292 40.864 4.18289 45.4977L0.110901 60.2186L14.8324 56.1472C19.0918 58.666 23.8967 60.0848 28.8559 60.2987C31.887 71.2406 41.9483 79 53.4639 79C58.0602 79 62.53 77.7759 66.4574 75.45L78.8885 78.8885L75.45 66.4574C77.7759 62.53 79 58.0602 79 53.4639ZM15.5629 51.1422L6.74989 53.5802L9.1879 44.7672L8.63219 43.8981C6.01336 39.8008 4.62891 35.0519 4.62891 30.165C4.62891 16.0842 16.0842 4.62891 30.165 4.62891C44.2458 4.62891 55.7012 16.0842 55.7012 30.165C55.7012 44.2458 44.2458 55.7012 30.165 55.7012C25.2782 55.7012 20.5299 54.3167 16.432 51.6979L15.5629 51.1422ZM72.2501 72.2501L65.7106 70.4407L64.8372 71.0091C61.4517 73.2084 57.5184 74.3711 53.4639 74.3711C44.4134 74.3711 36.4605 68.5193 33.6427 60.1282C47.5017 58.5303 58.5303 47.5017 60.1288 33.6421C68.5193 36.4605 74.3711 44.4134 74.3711 53.4639C74.3711 57.5184 73.2084 61.4517 71.0091 64.8372L70.4407 65.7106L72.2501 72.2501Z" />
    <path d="M34.7939 23.1445C34.7939 24.4591 34.2587 25.6723 33.2871 26.5614L27.8506 31.5374V37.1855H32.4795V33.5758L36.4123 29.9764C38.3253 28.2255 39.4229 25.7356 39.4229 23.1445C39.4229 18.0395 35.2701 13.8867 30.165 13.8867C25.06 13.8867 20.9072 18.0395 20.9072 23.1445H25.5361C25.5361 20.592 27.6125 18.5156 30.165 18.5156C32.7176 18.5156 34.7939 20.592 34.7939 23.1445Z" />
  </svg>
);

export default ChatQuestion;
