import i18n from 'es2015-i18n-tag';

export const shareInvestmentCheckItems = [
  {
    id: 'a',
    description: i18n('SharesPage')`Earn an attractive rate of up to 9.50%`,
  },
  {
    id: 'b',
    description: i18n('SharesPage')`Dividends, if agreed, usually paid monthly`,
  },
  { id: 'c', description: i18n('SharesPage')`Register and invest in minutes` },
  {
    id: 'd',
    description: i18n('SharesPage')`3 - 5 year investment terms available `,
  },
  {
    id: 'e',
    description: i18n(
      'SharesPage'
    )`Option to exit early through our Resale Market`,
  },
  {
    id: 'f',
    description: i18n(
      'SharesPage'
    )`Diversify loans across multiple UK properties`,
  },
];
