import { StateTransformer } from '../types';

export function optional<A, B>(
  transformer: StateTransformer<A, B>,
  value?: A
): B | undefined {
  return value ? transformer(value) : undefined;
}

export function flattenValue(input: { value: number }): number {
  return input.value;
}

export function itemsToArray<A, B>(transformer: StateTransformer<A, B>) {
  return ({ items }: { items: A[] }) => items.map(transformer);
}
