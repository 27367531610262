import {
  validateRegisterPassword,
  validateSameValue,
} from 'services/validation/fieldValidator';

export default (values, { i18n }) => {
  const password = validateRegisterPassword(i18n, values.get('password'));
  const repeatPassword = validateSameValue(
    i18n,
    values.get('repeatPassword'),
    values.get('password')
  );

  return {
    password,
    repeatPassword,
  };
};
