import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import { useI18n } from 'utils/i18nConnect';
import { commonPaths, registerPaths } from 'router/paths';
import { ButtonLink } from 'components/Atoms/Button';
import { H2 } from 'components/Atoms/Heading';
import { Section } from 'components/Molecules/Section';

import { FooterBox } from './FooterBox';

export const FooterJoin: FC = () => {
  const t = useI18n();
  // TODO https://britishpearl.atlassian.net/browse/PIT-1792
  const showFaqButton = useRouteMatch(commonPaths.howItWorks());

  return (
    <Section size="medium">
      <FooterBox>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={12} xl={8}>
            <H2 color="primary">
              {t('footer.FooterJoin.title1')`Join British Pearl today. `}
              <Box component="span" fontWeight="300">
                {t(
                  'footer.FooterJoin.title2'
                )`Start building your own portfolio.`}
              </Box>
            </H2>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={6}>
                <ButtonLink
                  to={
                    showFaqButton ? commonPaths.faq() : commonPaths.howItWorks()
                  }
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  {showFaqButton
                    ? t('footer.FooterJoin.link.FAQs')`FAQs`
                    : t('footer.FooterJoin.link.howItWorks')`How it works`}
                </ButtonLink>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={6}>
                <ButtonLink
                  to={registerPaths.register()}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {t('footer.FooterJoin.link.join')`Join`}
                </ButtonLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FooterBox>
    </Section>
  );
};
