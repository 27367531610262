import i18n from 'es2015-i18n-tag';

export const loanInvestmentCheckItems = [
  {
    id: 'a',
    description: i18n(
      'SharesPage'
    )`Earn an attractive rate of up to 4.30% p.a.`,
  },
  {
    id: 'b',
    description: i18n(
      'SharesPage'
    )`Loans secured with a first charge over UK property`,
  },
  {
    id: 'c',
    description: i18n('SharesPage')`Register and invest in minutes`,
  },
  {
    id: 'd',
    description: i18n('SharesPage')`3-5 year investment terms available`,
  },
  {
    id: 'e',
    description: i18n(
      'SharesPage'
    )`Interest accrues daily, and usually paid monthly`,
  },
  {
    id: 'f',
    description: i18n(
      'SharesPage'
    )`Diversify loans across multiple UK properties`,
  },
  {
    id: 'g',
    description: i18n(
      'SharesPage'
    )`Option to exit early through our Resale Market`,
  },
  {
    id: 'h',
    description: i18n(
      'SharesPage'
    )`Available with tax-free earnings in our IFISA`,
  },
];
