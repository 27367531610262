import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    gridContainer: {
      flexDirection: 'column',
      [breakpoints.up('lg')]: {
        flexDirection: 'row-reverse',
      },
    },
  })
);
