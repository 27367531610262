const gothamLightWoff2 = require('assets/fonts/GothamPro-Light.woff2');
const gothamLightWoff = require('assets/fonts/GothamPro-Light.woff');
const gothamWoff2 = require('assets/fonts/GothamPro.woff2');
const gothamWoff = require('assets/fonts/GothamPro.woff');
const gothamMediumWoff2 = require('assets/fonts/GothamPro-Medium.woff2');
const gothamMediumWoff = require('assets/fonts/GothamPro-Medium.woff');

const css = `
  @font-face {
    font-family: Gotham;
    font-weight: 300;
    src: url('${gothamLightWoff2}') format('woff2'),
         url('${gothamLightWoff}') format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 400;
    src: url('${gothamWoff2}') format('woff2'),
         url('${gothamWoff}') format('woff');
    font-display: fallback;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 700;
    src: url('${gothamMediumWoff2}') format('woff2'),
         url('${gothamMediumWoff}') format('woff');
    font-display: fallback;
  }

  html,
  body {
    font-family: 'Gotham', sans-serif;
  }
`;

module.exports = css;
