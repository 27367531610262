import React, { FC } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { LinkProps as MuiLinkProps } from '@material-ui/core/Link';

export const ExternalLink: FC<MuiLinkProps> = ({
  children,
  color = 'inherit',
  underline = 'always',
  target = '_blank',
  rel = 'noopener noreferrer',
  ...props
}) => {
  return (
    <MuiLink
      color={color}
      underline={underline}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </MuiLink>
  );
};
