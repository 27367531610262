import { GridProps } from '@material-ui/core';

import { Variant } from './types';

export const getGridLayout = (variant?: Variant): Partial<GridProps> => {
  switch (variant) {
    case 'even':
      return {
        xs: 12,
        sm: 10,
        md: 8,
        lg: 6,
        xl: 5,
      };
    case 'odd':
    default:
      return {
        xs: 12,
        sm: 6,
        xl: 4,
      };
  }
};
