import { ThreeDSecure } from './ThreeDSecure';

export class ThreeDSecureStub extends ThreeDSecure {
  constructor() {
    super({
      apiKey: 'apiKey',
      accountId: 'accountId',
      environment: 'TEST',
    });
  }

  start = (): Promise<string> => Promise.resolve('mock-deviceFingerprintingId');

  challenge = (): Promise<string> => Promise.resolve('mock-authenticationId');
}
