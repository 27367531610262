import React from 'react';

const House = props => (
  <svg viewBox="0 0 38 38" fill="none" {...props}>
    <path
      d="M37.0975 16.94C37.0308 16.0712 36.6297 15.2806 35.9683 14.7139L32.7586 11.9627V4.14498C32.7586 3.54459 32.2719 3.05792 31.6716 3.05792H27.3232C26.7228 3.05792 26.2506 3.54466 26.2506 4.14498V6.372L20.749 1.6832C19.5191 0.629484 17.7338 0.629628 16.5078 1.68009L1.13985 14.7137C-0.219279 15.8783 -0.395612 17.9333 0.787188 19.3139C1.68871 20.3623 3.13278 20.6966 4.36385 20.2639V36.9031C4.36385 37.5035 4.8361 37.9902 5.43642 37.9902H31.6715C32.2719 37.9902 32.7586 37.5034 32.7586 36.9031V20.2641C34.0294 20.7135 35.443 20.3369 36.322 19.3125C36.8887 18.6509 37.1642 17.8083 37.0975 16.94ZM28.4103 5.23204H30.599V10.0991L28.4103 8.23549V5.23204ZM22.9893 35.816H14.1332V27.1048H22.9893V35.816ZM30.599 35.816H25.149V26.0322C25.149 25.4318 24.6623 24.9451 24.062 24.9451H13.046C12.4457 24.9451 11.9735 25.4319 11.9735 26.0322V35.816H6.52355V18.7003L18.6228 8.43683L30.599 18.6902V35.816H30.599ZM34.6714 17.8972C34.286 18.3464 33.6008 18.4123 33.138 18.0154L19.3339 6.18277C18.9284 5.83511 18.3305 5.83366 17.9232 6.17915C17.629 6.42861 4.02815 17.966 3.9706 18.0148C3.51531 18.4044 2.82679 18.3513 2.43695 17.8978C2.04667 17.4423 2.09943 16.7546 2.55066 16.3678L17.9186 3.33412C18.3289 2.98276 18.9242 2.98269 19.3341 3.33398L34.5535 16.3647C35.0085 16.7546 35.0612 17.4424 34.6714 17.8972Z"
      fill="currentColor"
    />
    <path
      d="M21.8875 13.9297H15.22C14.6196 13.9297 14.1475 14.4164 14.1475 15.0167V21.5392C14.1475 22.1396 14.6197 22.6263 15.22 22.6263H21.8875C22.4879 22.6263 22.9745 22.1396 22.9745 21.5392V15.0167C22.9746 14.4164 22.4879 13.9297 21.8875 13.9297ZM20.8149 20.4523H16.3071V16.1039H20.8149V20.4523Z"
      fill="currentColor"
    />
    <path
      d="M19.713 31.469C20.3134 31.469 20.8001 30.9823 20.8001 30.382C20.8001 29.7816 20.3134 29.2949 19.713 29.2949C19.1127 29.2949 18.626 29.7816 18.626 30.382C18.626 30.9823 19.1127 31.469 19.713 31.469Z"
      fill="currentColor"
    />
  </svg>
);

export default House;
