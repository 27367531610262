import * as Yup from 'yup';

import { messages } from 'validation/messages';
import { createImmutableAsyncValidation } from 'components/Atoms/Form';

const matchValidName = /^[A-Za-z-'.\s]*$/;
const matchUppercaseLetter = /[A-Z]/;
const matchDigit = /\d/;

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(matchValidName, messages.validName)
    .required(messages.required),
  lastName: Yup.string()
    .matches(matchValidName, messages.validName)
    .required(messages.required),
  email: Yup.string()
    .email(messages.validEmail)
    .required(messages.required),
  password: Yup.string()
    .matches(matchUppercaseLetter, messages.containUppercaseLetter)
    .matches(matchDigit, messages.containDigit)
    .min(8, messages.minCharacterLength)
    .required(messages.required),
  isTermsAccepted: Yup.boolean().oneOf([true], messages.agreeToTerms),
  isPrivacyAccepted: Yup.boolean().oneOf([true], messages.agreeToPrivacy),
});

export const asyncValidateRegisterForm = createImmutableAsyncValidation(
  validationSchema
);
