import { makeStyles } from '@material-ui/core/styles';

import Config from 'config';
import { AlertCssProps, AlertType } from './types';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: '0.2rem',
    padding: '1rem 2rem',
    color: palette.common.white,
    backgroundColor: ({ type }: AlertCssProps) => {
      switch (type) {
        case AlertType.Error:
          return palette.error.dark;
        case AlertType.Warn:
          return Config.theme.colors.pumpkin;
        default:
          return Config.theme.colors.bostonBlue;
      }
    },
  },
}));
