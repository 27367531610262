import { getTime } from 'date-fns';

import { InvestmentWithProperty, Valuation } from 'services/Pip';

export const getLatestInvestmentValuation = (
  investment: InvestmentWithProperty
) => {
  const { valuations } = investment;

  if (!valuations) {
    return null;
  }

  const desktopValuations = valuations.Desktop || [];
  const surveyorValuations = valuations.Surveyor || [];
  const hpiValuations = valuations.HPI || [];

  // we're merging all valuations to get the latest one
  const valuationsByDate: Valuation[] = [
    ...desktopValuations,
    ...surveyorValuations,
    ...hpiValuations,
  ].sort((a, b) => getTime(b.valuationDate) - getTime(a.valuationDate));

  return valuationsByDate[0];
};
