import { fromJS } from 'immutable';

import { LOGOUT } from 'store/SessionProvider/actionTypes';

import * as type from './actionTypes';

export const investorWalletInitialState = fromJS({
  addedFunds: null,
  withdrawnFunds: null,
  transferredFunds: null,
  referenceNumber: null,
  bank: {
    confirmed: false,
    submitting: false,
    data: [],
    error: [],
  },
  cards: {
    confirmed: false,
    submitting: false,
    data: [],
    error: [],
  },
  threeDSecure: {
    submitting: false,
    error: false,
    payload: {},
  },
  transfer: {
    confirmed: false,
    submitting: false,
    data: {},
    error: [],
  },
});

export const investorWalletReducer = (
  state = investorWalletInitialState,
  action
) => {
  switch (action.type) {
    case LOGOUT:
      return investorWalletInitialState;
    case type.BANK_RESET:
      return state
        .merge({
          withdrawnFunds: null,
          referenceNumber: null,
          addedFunds: null,
        })
        .mergeIn(['bank'], {
          confirmed: false,
          submitting: false,
          error: [],
        });
    case type.BANK_FINDALL_REQUEST:
      return state.mergeIn(['bank'], {
        submitting: true,
      });
    case type.BANK_FINDALL_SUCCESS:
      return state.mergeIn(['bank'], {
        data: fromJS(action.data),
        submitting: false,
      });
    case type.BANK_FINDALL_ERROR:
      return state.mergeIn(['bank'], {
        submitting: false,
      });
    case type.BANK_CREATE_INIT:
      return investorWalletInitialState;
    case type.BANK_CREATE_REQUEST:
      return state.mergeIn(['bank'], {
        submitting: true,
      });

    case type.BANK_WITHDRAW_REQUEST:
      return state.mergeIn(['bank'], {
        submitting: true,
      });
    case type.BANK_WITHDRAW_SUCCESS:
      return state.merge({ withdrawnFunds: action.amount }).mergeIn(['bank'], {
        confirmed: true,
        submitting: false,
      });
    case type.BANK_WITHDRAW_ERROR:
      return state.mergeIn(['bank'], {
        error: action.payload,
        confirmed: false,
        submitting: false,
      });
    case type.BANK_TOPUP_REQUEST:
      return state.mergeIn(['bank'], {
        submitting: true,
      });
    case type.BANK_TOPUP_SUCCESS:
      return state
        .merge({
          addedFunds: action.amount,
          referenceNumber: action.referenceNumber,
        })
        .mergeIn(['bank'], {
          confirmed: true,
          submitting: false,
        });
    case type.BANK_TOPUP_ERROR:
      return state.mergeIn(['bank'], {
        error: fromJS(action.payload),
        confirmed: false,
        submitting: false,
      });
    case type.BANK_CREATE_SUCCESS:
      return state.mergeIn(['bank'], {
        confirmed: true,
        submitting: false,
      });
    case type.BANK_CREATE_ERROR:
      return state.mergeIn(['bank'], {
        error: fromJS(action.payload),
        confirmed: false,
        submitting: false,
      });
    case type.BANK_REMOVE_REQUEST:
      return state.get('bank');
    case type.BANK_REMOVE_SUCCESS:
      return state.mergeIn(['bank'], {
        confirmed: true,
      });
    case type.BANK_REMOVE_ERROR:
      return state.mergeIn(['bank'], {
        error: action.payload,
        confirmed: false,
      });
    case type.CARDS_RESET:
      return state
        .merge({
          addedFunds: null,
          referenceNumber: null,
        })
        .mergeIn(['cards'], {
          confirmed: false,
          submitting: false,
          error: fromJS([]),
        });
    case type.CARDS_FINDALL_REQUEST:
      return state.mergeIn(['cards'], {
        submitting: true,
      });
    case type.CARDS_FINDALL_SUCCESS:
      return state.mergeIn(['cards'], {
        data: fromJS(action.data),
        submitting: false,
      });
    case type.CARDS_FINDALL_ERROR:
      return state.mergeIn(['cards'], {
        submitting: false,
      });
    case type.CARD_CREATE_INIT:
      return investorWalletInitialState;
    /* No such type? - simlrh
    case type.CARD_CREATE_RESET:
      return state.mergeIn(['cards'], {
        confirmed: false,
      });
    */
    case type.CARD_CREATE_REQUEST:
      return state
        .mergeIn(['cards'], {
          submitting: true,
        })
        .mergeIn(['cards', 'threeDSecure'], {
          submitting: true,
        });
    case type.CARD_CREATE_SUCCESS:
      return state
        .merge({ addedFunds: action.amount })
        .mergeIn(['cards'], {
          confirmed: true,
          submitting: false,
        })
        .mergeIn(['cards', 'threeDSecure'], {
          submitting: false,
        });
    case type.CARD_TOPUP_ERROR:
    case type.CARD_CREATE_ERROR:
      return state.mergeIn(['cards'], {
        error: fromJS(action.payload),
        confirmed: false,
        submitting: false,
      });
    case type.CARD_TOPUP_REQUEST:
      return state.mergeIn(['cards'], {
        submitting: true,
      });
    case type.CARD_TOPUP_SUCCESS:
      return state.merge({ addedFunds: action.amount }).mergeIn(['cards'], {
        confirmed: true,
        submitting: false,
      });
    case type.CARD_REMOVE_REQUEST:
      return state.mergeIn(['cards'], {
        submitting: true,
      });
    case type.CARD_REMOVE_SUCCESS:
      return state
        .setIn(['cards', 'submitting'], false)
        .updateIn(['cards', 'data'], cards =>
          cards.filter(card => card.get('id') !== action.payload)
        );
    case type.CARD_REMOVE_ERROR:
      return state.mergeIn(['cards'], {
        submitting: false,
        error: fromJS(action.payload),
      });
    case type.TRANSFER_REQUEST:
      return state.mergeIn(['transfer'], {
        submitting: true,
      });
    case type.TRANSFER_SUCCESS: {
      const { type: unused, ...data } = action;
      return state
        .merge({ transferredFunds: action.amount })
        .mergeIn(['transfer'], {
          confirmed: true,
          submitting: false,
          data: fromJS(data),
        });
    }
    case type.TRANSFER_ERROR:
      return state.mergeIn(['transfer'], {
        error: action.payload,
        confirmed: false,
        submitting: false,
      });
    case type.TRANSFER_RESET:
      return state.merge({ transferredFunds: null }).mergeIn(['transfer'], {
        confirmed: false,
        submitting: false,
        error: [],
      });
    case type.THREE_D_SECURE_REQUEST: {
      return state.mergeIn(['threeDSecure'], {
        payload: action.payload,
        submitting: true,
      });
    }
    case type.THREE_D_SECURE_GENERIC_ERROR: {
      return state.mergeIn(['threeDSecure'], {
        error: true,
        submitting: false,
      });
    }
    case type.FETCH_CARD_TRANSACTION: {
      const { accountId, uuid } = action;
      return state.mergeIn(['threeDSecure'], {
        accountId,
        uuid,
      });
    }
    case type.FETCH_CARD_TRANSACTION_SUCCESS: {
      const { status, amount } = action;
      if (status === 'Successful') {
        return state
          .mergeIn(['threeDSecure'], {
            status,
            amount,
          })
          .merge({
            addedFunds: amount,
          });
      }

      return state
        .mergeIn(['cards'], {
          error: status,
          confirmed: false,
          submitting: false,
        })
        .merge({ addedFunds: null, error: [status] })
        .mergeIn(['threeDSecure'], { error: status });
    }
    case type.CARD_TRANSACTION_RESET: {
      return state
        .merge({
          addedFunds: null,
          referenceNumber: null,
          error: null,
        })
        .mergeIn(['cards'], {
          confirmed: false,
          submitting: false,
          error: [],
        })
        .mergeIn(['threeDSecure'], {
          error: false,
        });
    }
    default:
      return state;
  }
};
