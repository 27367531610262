import {
  all,
  call,
  put,
  race,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import appConfig from 'config';
import { logger } from 'services/Logger';
import { Account, BankDetail } from 'services/Pip';
import { ThreeDSecure } from 'services/ThreeDSecure';

import { fbTrack, track } from 'utils/analytics';
import { sagaPromise } from 'utils/saga';

import { investorWithdrawalsFindRequest } from '../InvestorWithdrawals/actions';

import { accountsFindAllRequest } from '../SessionProvider/actions';

import {
  createBankError,
  createCardError,
  createCardSuccess,
  createThreeDSecureRequest,
  fetchCardTransactionSuccess,
  findAllBankError,
  findAllBankRequest,
  findAllBankSuccess,
  findAllCardsError,
  findAllCardsRequest,
  findAllCardsSuccess,
  removeBankError,
  removeBankSuccess,
  removeCardError,
  removeCardSuccess,
  threeDSecureGenericError,
  topupBankError,
  topupBankRequest,
  topupBankSuccess,
  topupCardError,
  topupCardSuccess,
  transferError,
  transferSuccess,
  withdrawError,
  withdrawRequest,
  withdrawSuccess,
} from './actions';

import * as type from './actionTypes';

export function* findAllCards() {
  try {
    return yield call([Account, Account.findAllCards]);
  } catch (error) {
    yield call(logger.error, error);
    yield put(findAllCardsError(error));
  }

  return false;
}

export function* findAllCardsFlow() {
  try {
    const { cards } = yield race({
      cards: call(findAllCards),
      error: take(type.CARDS_FINDALL_ERROR),
    });

    if (cards) {
      yield put(findAllCardsSuccess(cards));
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

export function* findAllCardsRequestWatcher() {
  yield takeLatest([type.CARDS_FINDALL_REQUEST], findAllCardsFlow);
}

export function* removeCard({ payload: id }) {
  try {
    yield call(Account.removeCard, id);
    yield put(removeCardSuccess(id));
  } catch (error) {
    yield put(removeCardError(error));
    yield call(logger.error, error);
  }
}

export function* removeCardWatcher() {
  yield takeEvery(type.CARD_REMOVE_REQUEST, removeCard);
}

export function* createCardDetailV1(accountId, card) {
  try {
    return yield call([Account, Account.createCardV1], accountId, card);
  } catch (error) {
    yield call(logger.error, error);
    yield put(createCardError(error));
  }

  return false;
}

export function* createCardDetail(accountId, card) {
  try {
    const cardBin = card.cardDetails.cardNumber.substring(0, 6);

    const deviceFingerprintingId = yield call(ThreeDSecure.start, cardBin);

    return yield call(Account.createCard, {
      accountId,
      card,
      deviceFingerprintingId,
    });
  } catch (error) {
    yield call(logger.error, error);
    yield put(createCardError(error));
    return false;
  }
}

export function* topupBank(accountId, bankId, amount) {
  try {
    return yield call([Account, Account.topupBank], accountId, bankId, amount);
  } catch (error) {
    yield call(logger.error, error);
    yield put(topupBankError(error));
  }

  return false;
}

export function* topupBankFlow({ accountId, bankId, amount }) {
  try {
    const { bankDetails } = yield race({
      bankDetails: call(topupBank, accountId, bankId, amount),
      error: take(type.BANK_TOPUP_ERROR),
    });

    if (bankDetails) {
      yield put(topupBankSuccess(bankDetails.referenceNumber, amount));
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

export function* topupBankWatcher() {
  yield takeLatest(type.BANK_TOPUP_REQUEST, topupBankFlow);
}

export const createCardDetailFlowV1 = sagaPromise(function*({
  accountId,
  card,
}) {
  try {
    const { cardDetail } = yield race({
      cardDetail: call(createCardDetailV1, accountId, card),
      error: take(type.CARD_CREATE_ERROR),
    });

    if (cardDetail) {
      yield call(track, 'Invest', 'Fund wallet via card', {
        amount: card.amount.value,
      });
      yield call(fbTrack, 'Purchase', {
        // eslint-disable-next-line camelcase
        content_name: 'Fund wallet via card',
        value: card.amount.value,
        currency: 'GBP',
      });

      const { card: threeDSecureCard } = createCardSuccess(
        cardDetail,
        card.amount.value
      );

      if (threeDSecureCard.threeDInitiate && threeDSecureCard.orderUuid) {
        yield put(createThreeDSecureRequest(threeDSecureCard));
        return true;
      }
      if (!threeDSecureCard.threeDInitiate) {
        yield put(createCardSuccess(cardDetail, card.amount.value));
        yield put(findAllCardsRequest());
        yield put(accountsFindAllRequest());
        return true;
      }
    }
  } catch (e) {
    yield call(logger.error, e);
  }
  return false;
});

export function* threeDSecureChallenge(cardDetail) {
  try {
    const authenticationId = yield call(
      ThreeDSecure.challenge,
      cardDetail.sdkChallengePayload
    );

    try {
      yield call(
        Account.topupCardThreeDSecureContinue,
        cardDetail.orderUuid,
        authenticationId
      );
      return true;
    } catch (error) {
      yield put(topupCardError(error));
      return false;
    }
  } catch (error) {
    yield put(threeDSecureGenericError());
    return false;
  }
}

// untokenizedCardTopup
export function* createCardDetailFlow({ accountId, card }) {
  try {
    const { cardDetail } = yield race({
      cardDetail: call(createCardDetail, accountId, card),
      error: take(type.CARD_CREATE_ERROR),
    });

    if (cardDetail) {
      yield call(track, 'Invest', 'Fund wallet via card', {
        amount: card.amount.value,
      });
      yield call(fbTrack, 'Purchase', {
        // eslint-disable-next-line camelcase
        content_name: 'Fund wallet via card',
        value: card.amount.value,
        currency: 'GBP',
      });

      if (cardDetail.threeDInitiate) {
        if (cardDetail.sdkChallengePayload) {
          // 3DS v2
          const threeDSecureChallengePassed = yield call(
            threeDSecureChallenge,
            cardDetail
          );

          if (threeDSecureChallengePassed) {
            yield put(createCardSuccess(cardDetail, card.amount.value));
          }
        } else {
          // 3DS v1
          yield put(createThreeDSecureRequest(cardDetail));
        }
      } else {
        yield put(createCardSuccess(cardDetail, card.amount.value));
      }
      // refetch balance
      yield put(accountsFindAllRequest());
    }
  } catch (e) {
    yield call(logger.error, e);
  } finally {
    // even though card topup may fail it does not mean card was not added to user account
    // so we are refetching all cards
    yield put(findAllCardsRequest());
  }
}

export function* createCardDetailWatcher() {
  // TODO temporary and dirty way to flag 3DSv2 feature
  yield takeLatest(
    type.CARD_CREATE_REQUEST,
    appConfig.threeDSecureV2Enabled
      ? createCardDetailFlow
      : createCardDetailFlowV1
  );
}

export function* topupCardV1(accountId, cardId, amount) {
  try {
    return yield call(
      [Account, Account.topupCardV1],
      accountId,
      cardId,
      amount
    );
  } catch (error) {
    yield call(logger.error, error);
    yield put(topupCardError(error));
  }

  return false;
}

export function* topupCard(accountId, cardId, amount) {
  try {
    const { cardBin } = yield call(Account.getCardDetails, cardId);
    const deviceFingerprintingId = yield call(ThreeDSecure.start, cardBin);

    return yield call(Account.topupCard, {
      accountId,
      cardId,
      amount,
      deviceFingerprintingId,
    });
  } catch (error) {
    yield call(logger.error, error);
    yield put(topupCardError(error));
    return false;
  }
}

// tokenizedCardTopup
export function* topupCardFlow({ accountId, cardId, amount }) {
  try {
    const { cardDetail } = yield race({
      cardDetail: call(topupCard, accountId, cardId, amount),
      error: take(type.CARD_TOPUP_ERROR),
    });

    if (cardDetail) {
      yield call(track, 'Invest', 'Fund wallet via card', {
        amount,
      });
      yield call(fbTrack, 'Purchase', {
        // eslint-disable-next-line camelcase
        content_name: 'Fund wallet via card',
        value: amount,
        currency: 'GBP',
      });

      if (cardDetail.threeDInitiate) {
        if (cardDetail.sdkChallengePayload) {
          // 3DS v2
          yield call(threeDSecureChallenge, cardDetail);
        } else {
          // 3DS v1
          return yield put(createThreeDSecureRequest(cardDetail));
        }
      }

      yield put(topupCardSuccess(cardDetail, amount));
      yield put(accountsFindAllRequest());
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
}

export function* topupCardFlowV1({ accountId, cardId, amount }) {
  try {
    const { cardDetail } = yield race({
      cardDetail: call(topupCardV1, accountId, cardId, amount),
      error: take(type.CARD_TOPUP_ERROR),
    });

    if (cardDetail) {
      yield call(track, 'Invest', 'Fund wallet via card', {
        amount,
      });
      yield call(fbTrack, 'Purchase', {
        // eslint-disable-next-line camelcase
        content_name: 'Fund wallet via card',
        value: amount,
        currency: 'GBP',
      });

      const { card: threeDSecureCard } = topupCardSuccess(cardDetail, amount);

      if (threeDSecureCard.threeDInitiate && threeDSecureCard.orderUuid) {
        return yield put(createThreeDSecureRequest(threeDSecureCard));
      }

      yield put(topupCardSuccess(cardDetail, amount));
      yield put(accountsFindAllRequest());
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
}

export function* topupCardWatcher() {
  // TODO temporary and dirty way to flag 3DSv2 feature
  yield takeLatest(
    type.CARD_TOPUP_REQUEST,
    appConfig.threeDSecureV2Enabled ? topupCardFlow : topupCardFlowV1
  );
}

export function* findAllBank() {
  try {
    return yield call([BankDetail, BankDetail.findAll]);
  } catch (error) {
    yield call(logger.error, error);
    yield put(findAllBankError(error));
  }

  return false;
}

export function* findAllBankFlow() {
  try {
    const { bankDetails } = yield race({
      bankDetails: call(findAllBank),
      error: take(type.BANK_FINDALL_ERROR),
    });

    if (bankDetails) {
      yield put(findAllBankSuccess(bankDetails));
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

export function* findAllBankRequestWatcher() {
  yield takeLatest([type.BANK_FINDALL_REQUEST], findAllBankFlow);
}

export function* removeBank(id) {
  try {
    return yield call([BankDetail, BankDetail.remove], id);
  } catch (error) {
    yield call(logger.error, error);
    yield put(removeBankError(error));
  }

  return false;
}

export function* removeBankFlow({ id }) {
  try {
    const { bankDetail } = yield race({
      bankDetail: call(removeBank, id),
      error: take(type.BANK_REMOVE_ERROR),
    });

    if (bankDetail) {
      yield put(removeBankSuccess());
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

export function* removeBankWatcher() {
  yield takeLatest(type.BANK_REMOVE_REQUEST, removeBankFlow);
}

export function* createBankDetail(bank) {
  try {
    return yield call([BankDetail, BankDetail.create], bank);
  } catch (error) {
    yield call(logger.error, error);
    yield put(createBankError(error));
  }

  return false;
}

export const createBankDetailFlow = sagaPromise(function*({
  bank,
  accountId,
  amount,
  operation,
}) {
  try {
    const { bankDetail } = yield race({
      bankDetail: call(createBankDetail, bank),
      error: take(type.BANK_CREATE_ERROR),
    });

    if (bankDetail) {
      switch (operation) {
        case 'fund':
          yield call(track, 'Invest', 'Fund wallet via bank', {});
          yield call(fbTrack, 'Purchase', {
            // eslint-disable-next-line camelcase
            content_name: 'Fund wallet via bank',
          });

          yield put(topupBankRequest(accountId, bankDetail.id, 1));
          break;
        case 'withdraw':
          yield call(track, 'Account', 'Withdrawn funds via bank', {
            amount,
          });

          yield put(withdrawRequest(accountId, bankDetail.id, amount));
          break;

        default:
          break;
      }
      yield put(findAllBankRequest());
      return true;
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
});

export function* createBankDetailWatcher() {
  yield takeLatest(type.BANK_CREATE_REQUEST, createBankDetailFlow);
}

export function* withdrawBank(accountId, bankId, amount) {
  try {
    return yield call([Account, Account.withdraw], accountId, bankId, amount);
  } catch (error) {
    yield call(logger.error, error);
    yield put(withdrawError(error));
  }

  return false;
}

export function* withdrawBankFlow({ accountId, bankId, amount }) {
  try {
    const { withdrawDetail } = yield race({
      withdrawDetail: call(withdrawBank, accountId, bankId, amount),
      error: take(type.BANK_WITHDRAW_ERROR),
    });

    if (withdrawDetail) {
      yield put(withdrawSuccess(amount));
      yield put(accountsFindAllRequest());
      yield put(investorWithdrawalsFindRequest(accountId));
    }
  } catch (e) {
    yield call(logger.error, e);
  }
}

export function* withdrawBankWatcher() {
  yield takeLatest(type.BANK_WITHDRAW_REQUEST, withdrawBankFlow);
}

export function* transfer(sourceAccountId, destinationAccountId, amount) {
  try {
    return yield call(
      [Account, Account.transfer],
      sourceAccountId,
      destinationAccountId,
      amount
    );
  } catch (error) {
    yield call(logger.error, error);
    yield put(transferError(error));
  }

  return false;
}

export const transferFlow = sagaPromise(function*({
  sourceAccountId,
  destinationAccountId,
  amount,
}) {
  try {
    const { transferDetail } = yield race({
      transferDetail: call(
        transfer,
        sourceAccountId,
        destinationAccountId,
        amount
      ),
      error: take(type.TRANSFER_ERROR),
    });

    if (transferDetail) {
      yield put(transferSuccess(sourceAccountId, destinationAccountId, amount));
      yield put(accountsFindAllRequest());
      return true;
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
});

export function* transferWatcher() {
  yield takeLatest(type.TRANSFER_REQUEST, transferFlow);
}

export function* cardTransfer(accountId, uuid) {
  try {
    return yield call([Account, Account.topupCardTransaction], accountId, uuid);
  } catch (error) {
    yield call(logger.error, error);
    yield put(topupCardError(error));
  }
  return false;
}

export const cardTopupTransactionFlow = sagaPromise(function*({
  accountId,
  uuid,
}) {
  try {
    const { transferDetail } = yield race({
      transferDetail: call(cardTransfer, accountId, uuid),
      error: take(type.CARD_CREATE_ERROR),
    });

    if (transferDetail) {
      yield put(fetchCardTransactionSuccess(transferDetail));
      return true;
    }
  } catch (e) {
    yield call(logger.error, e);
  }

  return false;
});

export function* cardTopupTransactionWatcher() {
  yield takeLatest(type.FETCH_CARD_TRANSACTION, cardTopupTransactionFlow);
}

export function* investorWalletSaga() {
  try {
    yield all([
      findAllCardsRequestWatcher(),
      createCardDetailWatcher(),
      topupBankWatcher(),
      topupCardWatcher(),
      removeCardWatcher(),
      findAllBankRequestWatcher(),
      createBankDetailWatcher(),
      withdrawBankWatcher(),
      transferWatcher(),
      removeBankWatcher(),
      cardTopupTransactionWatcher(),
    ]);
  } catch (e) {
    yield call(logger.error, e);
  }
}
