import React from 'react';
import PropTypes from 'prop-types';
import Config from 'config';

const LogoMark = ({ height, width, ...props }) => (
  <svg height={height} viewBox="0 0 1000 1000" width={width} {...props}>
    <path
      d="M1000 500C1000 776.142 776.142 1000 500 1000C223.858 1000 0 776.142 0 500C0 223.858 223.858 0 500 0C776.142 0 1000 223.858 1000 500Z"
      fill={Config.theme.colors.cashmere}
    />
    <path
      d="M712.918 349.091C719.771 147.828 569.004 0.000178059 363.411 1.42003e-10C157.818 -0.000178059 -6.48967 167.452 0.197096 349.091C6.88387 530.73 363.411 960 363.411 960C363.411 960 706.066 550.354 712.918 349.091Z"
      fill={Config.theme.colors.pomegranate}
      transform="translate(143 20)"
    />
    <path
      d="M383.773 189.427C383.773 294.044 297.863 378.853 191.887 378.853C85.9106 378.853 0 294.044 0 189.427C0 84.8092 85.9106 0 191.887 0C297.863 0 383.773 84.8092 383.773 189.427Z"
      fill="white"
      transform="translate(307.475 182.367)"
    />
  </svg>
);

LogoMark.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

LogoMark.defaultProps = {
  height: 1000,
  width: 1000,
};

export default LogoMark;
