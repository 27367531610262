import React from 'react';

import Config from 'config';

const Calendar = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none">
      <path
        d="M23 9H0v.96h23M17 6V5h-1v2h4V5h-1v1M5 6V5H4v2h4V5H7v1"
        fill={fill}
      />
      <path
        d="M4 2v-.91C4 .47 4.478 0 5 0h2c.522 0 1 .462 1 1.09V2h8v-.91C16 .57 16.478 0 17 0h2c.522 0 1 .57 1 1.09V2h2c1 0 2 1.002 2 2v18c0 .84-1.092 2-2 2H2c-1 0-2-1-2-2V4c0-.975 1-2 2-2h2zM2 3c-.462 0-1 .583-1 1v18c0 .437.558 1 1 1h20c.473 0 1-.563 1-1V4c0-.417-.538-1-1-1h-3V2c0-1 0-1-1-1s-1 0-1 1v1H7V2c0-1 0-1-1-1s-1 .09-1 1v1H2z"
        fill={fill}
      />
      <path
        d="M11 12.96h1.92V12H11m4 .96h1.92V12H15m-8 .96h1.92V12H7m12 .96h1.92V12H19M3 16.96h1.92V16H3m8 .96h1.92V16H11m4 .96h1.92V16H15m4 .96h1.92V16H19m-12 .96h1.92V16H7m-4 4.96h1.92V20H3m8 .96h1.92V20H11m4 .96h1.92V20H15m-8 .96h1.92V20H7"
        fill={fill2}
      />
    </g>
  </svg>
);

Calendar.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default Calendar;
