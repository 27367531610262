const { generatePath } = require('react-router-dom');

const namedPath = (pattern, params) => {
  return params ? generatePath(pattern, params) : pattern;
};

module.exports = {
  investmentPaths: {
    investmentAll: () => '/investment',
    investmentById: params => namedPath('/investment/:investmentId', params),
    investmentByIdConfirmation: params =>
      namedPath('/investment/:investmentId/confirmation', params),
    investmentByIdSummary: params =>
      namedPath('/investment/:investmentId/summary', params),
  },
};
