import React, { FC } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

export const Text: FC<TypographyProps> = ({
  children,
  variant = 'body2',
  paragraph,
  color,
  ...rest
}) => (
  // @ts-ignore TODO fix MUI types
  <Typography
    component="p"
    variant={variant}
    paragraph={paragraph}
    color={color}
    {...rest}
  >
    {children}
  </Typography>
);
