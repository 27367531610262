import { isArray, isEmpty, isString } from 'lodash';

import Config from 'config';
import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  bankDetails: {
    url: '/bank-details/uk/:id',
    helpers: {
      get: id => OAuth.getAuthHeader().then(header => [{ id }, header]),
      findAll: () =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            transformResponse: data =>
              data && isArray(data.items) ? data.items : [],
            ...header,
          },
        ]),
      create: bankDetail =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: bankDetail,
            method: 'post',
            transformRequest: [({ amount, ...cleanData }) => cleanData],
            ...header,
          },
        ]),
      remove: id =>
        OAuth.getAuthHeader().then(header => [
          { id },
          {
            method: 'delete',
            transformResponse: data => isString(data) && isEmpty(data),
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
