import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const useFormControlLabelStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    alignItems: 'start',
  },
  label: {
    padding: spacing(1.25, 0),
  },
}));

export const useCheckboxStyles = makeStyles(({ palette }: Theme) => ({
  colorWhite: {
    color: palette.common.white,
    '&$checked': {
      color: palette.common.white,
      '&:hover': {
        backgroundColor: fade(
          palette.common.white,
          palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&$disabled': {
      color: palette.action.disabled,
    },
    '&:hover': {
      backgroundColor: fade(palette.common.white, palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorPrimary: {
    color: palette.primary.main,
    '&$checked': {
      color: palette.primary.main,
      '&:hover': {
        backgroundColor: fade(
          palette.primary.main,
          palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&$disabled': {
      color: palette.action.disabled,
    },
    '&:hover': {
      backgroundColor: fade(palette.primary.main, palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorSecondary: {
    color: palette.secondary.main,
    '&$checked': {
      color: palette.secondary.main,
      '&:hover': {
        backgroundColor: fade(
          palette.secondary.main,
          palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&$disabled': {
      color: palette.action.disabled,
    },
    '&:hover': {
      backgroundColor: fade(
        palette.secondary.main,
        palette.action.hoverOpacity
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  /* Pseudo-class applied to the root element if `checked={true}`. */
  checked: {},
  /* Pseudo-class applied to the root element if `disabled={true}`. */
  disabled: {},
}));
