const { generatePath } = require('react-router-dom');

const namedPath = (pattern, params) => {
  return params ? generatePath(pattern, params) : pattern;
};

module.exports = {
  glossaryPaths: {
    // TODO: https://britishpearl.atlassian.net/browse/PIT-1926
    glossary: () => '/glossary',

    glossaryAll: () => '/glossary/all',
    glossaryByLetter: params => namedPath('/glossary/:letter', params),
  },
};
