const { commonPaths } = require('./commonPaths');
const { onboardingPaths } = require('./onboardingPaths');
const { investorPaths } = require('./investorPaths');
const { referFriendPaths } = require('./referFriendPaths');
const { propertyPaths } = require('./propertyPaths');
const { registerPaths } = require('./registerPaths');
const { investmentPaths } = require('./investmentPaths');
const { loginPaths } = require('./loginPaths');
const { blogPaths } = require('./blogPaths');
const { glossaryPaths } = require('./glossaryPaths');

module.exports = {
  commonPaths,
  onboardingPaths,
  investorPaths,
  referFriendPaths,
  propertyPaths,
  registerPaths,
  investmentPaths,
  loginPaths,
  blogPaths,
  glossaryPaths,
};
