import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { PropertyCardImageSkeleton } from './PropertyCardImageSkeleton';

export const PropertyCardSkeleton: FC = () => (
  <Box
    bgcolor="grey.300"
    position="relative"
    borderRadius="16px"
    overflow="hidden"
    boxShadow="0 0.25rem 0.875rem rgba(0, 0, 0, 0.2)"
  >
    <Box
      bgcolor="common.white"
      display="flex"
      alignItems="center"
      flexDirection="column"
      p={2.5}
    >
      <Skeleton animation="wave" height={32} width={150} />
      <Skeleton animation="wave" height={22} width={80} />
      <Skeleton animation="wave" height={18} width={120} />
    </Box>
    <PropertyCardImageSkeleton />
    <Box pb="90px" bgcolor="common.white" />
  </Box>
);
