import React from 'react';

import Config from 'config';

const Crane = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 512 512" width={width} {...props}>
    <g strokeWidth="4" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path
        d="M181.3333 10.6667l213.3334 106.6666L181.3333 10.6667zm-10.6666 0L21.3333 117.3333"
        stroke={fill2}
      />
      <path
        d="M106.6667 170.6667h394.6666L448 117.3333H106.6667m64-1.3333V10.6667h10.6666L224 85.3333V116"
        stroke={fill}
      />
      <path
        d="M394 170.6667v74.6666M288 330h213.3333m-42.6666.6667l-64-85.3334-64 85.3334"
        stroke={fill2}
      />
      <path
        d="M416 117.3333l-53.3333 53.3334-53.3334-53.3334L256 170.6667l-53.3333-53.3334-53.3334 53.3334M170 172v329.3333M224 172v329.3333m-53.3333-288L224 266.6667 170.6667 320 224 373.3333l-53.3333 53.3334L224 480M10.6667 213.3333h96v-96h-96z"
        stroke={fill}
      />
    </g>
  </svg>
);

Crane.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 512,
  width: 512,
};

export default Crane;
