import React from 'react';

const SidebarWallet = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3z" />
      <path
        d="M18.75 16.5v.75c0 .825-.675 1.5-1.5 1.5H6.75c-.8325 0-1.5-.675-1.5-1.5V6.75c0-.825.6675-1.5 1.5-1.5h10.5c.825 0 1.5.675 1.5 1.5v.75H12c-.8325 0-1.5.675-1.5 1.5v6c0 .825.6675 1.5 1.5 1.5h6.75zM12 15h7.5V9H12v6zm3-1.875c-.6225 0-1.125-.5025-1.125-1.125s.5025-1.125 1.125-1.125 1.125.5025 1.125 1.125-.5025 1.125-1.125 1.125z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarWallet.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarWallet;
