import React, { FC } from 'react';
import { useI18n } from 'utils/i18nConnect';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  Link as MuiLink,
} from '@material-ui/core';
import { Button } from 'components/Atoms/Button';
import { useStyles } from './PermanentRiskWarningDialog.styles';

interface PermanentRiskWarningDialogProps {
  open: boolean;
  onClose: () => void;
}
const PermanentRiskWarningDialog: FC<PermanentRiskWarningDialogProps> = ({
  open = false,
  onClose,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const t = useI18n()('PermanentRiskWarningDialog');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          variant="h5"
          color="primary"
          paragraph
          style={{ color: '#4ac885' }}
        >
          {t`Risk Summary`}
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`Estimated reading time: 2 min`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          {t`Due to the potential loss of invested capital, the Financial Conduct Authority (FCA) considers this investment to be high risk.`}
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`What are the key risks?`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`1. You could lose all the money you invest`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <ul>
            <li>
              {t`It is important to remember that you could be exposed to losses associated with the property market. This means that you could receive less money back than you originally invested.`}
            </li>
            <li>
              {t`It is important to remember that you could be exposed to losses associated with the property market. This means that you could receive less money back than you originally invested.`}
            </li>
            <li>
              {t`Investments in property related shares are typically made through special purpose vehicles (SPV) for a specific real estate project. SPVs are often limited liability companies incorporated in the U.K or abroad. Investors in these shares can lose 100% of the money they invested.`}
            </li>
            <li>
              {t`Many peer-to-peer (P2P) loans are made to SPV borrowers who can’t borrow money from traditional lenders such as banks. These borrowers have a higher risk of not paying you back.`}
            </li>
            <li>
              {t`Advertised rates of return aren’t guaranteed. This is not a savings account. If a SPV borrower doesn’t pay you back as agreed, you could earn less money than expected or nothing at all. A higher advertised rate of return means a higher risk of losing your money.`}
            </li>
            <li>
              {t`Certain investments can be held in an Innovative Finance ISA (IFISA). An IFISA does not reduce the risk of the investment or protect you from losses, so you can still lose all your money. It only means that any potential gains from your investment will be tax free.`}
            </li>
            <li>
              {t` British Pearl conducts due diligence on the SPVs you are investing in, such as how well they are expected to perform. You should not rely on our due diligence; you should conduct your own research before investing.`}
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`2. You are unlikely to be protected if something goes wrong`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <ul>
            <li>
              {t`Protection from the Financial Services Compensation Scheme (FSCS), in relation to claims against failed regulated firms, does not cover poor investment performance. Read more on the FSCS investment protection checker `}
              <MuiLink
                className={styles.link}
                href="https://www.fscs.org.uk/check/investment-protection-checker/"
              >
                here
              </MuiLink>
              {t`.`}
            </li>
            <li>
              {t`Protection from the Financial Ombudsman Service (FOS) does not cover poor investment performance. If you have a complaint against an FCA-regulated firm, the FOS may be able to consider it. Learn more about FOS protection `}
              <MuiLink
                className={styles.link}
                href="https://www.financial-ombudsman.org.uk/consumers"
              >
                here
              </MuiLink>
              {t`.`}
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`3. You are unlikely to get your money back quickly`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <ul>
            <li>
              {t`Even if the SPV you invest in is successful, investment projects can overrun which can extend the length of time your funds are tied up in the investment.`}
            </li>
            <li>
              {t`SPVs could also face cash-flow problems that delay payments to investors. In a worst-case scenario, it could take several years to get your money back (e.g., in the case of a block with many units, selling individual units sequentially to maximise their value). The SPV could also fail altogether and be unable to repay any of the money owed to you.`}
            </li>
            <li>
              {t`Dividends are not guaranteed. They are paid at the discretion of the Directors of the SPV that holds the property, based on the financial position of the SPV. In the worst-case scenario, dividends may be suspended.`}
            </li>
            <li>
              {t`You are unlikely to be able to sell your investment earlier than the advertised investment term. You may have the rare opportunity to sell your investment earlier through British Pearl’s Resale Market, however liquidity is subject to supply and demand. There is no guarantee you will find a buyer at the price you are willing to sell, and the price you are able to sell your shares at might be lower than the price they were originally purchased for. Furthermore, you may have to pay early exit fees or additional charges to take any money out of your investment.`}
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`4. Don’t put all your eggs in one basket`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <ul>
            <li>
              {t`Putting all your money into a single platform or type of investment is risky. Spreading your money across different platforms and investments makes you less dependent on any one to do well. Similarly, when investing via the British Pearl platform, we recommend that investors diversify their investment(s) across multiple properties.`}
            </li>
            <li>
              {t`A good rule of thumb is not to invest more than 10% of your money in `}
              <MuiLink
                className={styles.link}
                href="https://www.fca.org.uk/investsmart/5-questions-ask-you-invest"
              >
                high-risk investments
              </MuiLink>
              {t`.`}
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`5. The value of your investment can be reduced`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <ul>
            <li>
              {t`Property values are subject to broader market conditions as well as specific risks - you can read more about this in our `}
              <MuiLink
                className={styles.link}
                href="https://www.britishpearl.com/key-risks"
              >
                Key Risks section
              </MuiLink>
              {t`. Your investment is linked to the underlying value of property in your portfolio and as such, the value of your investment can go down as well as up.`}
            </li>
            <li>
              {t`If an investment project overruns (time or costs) the company may issue new shares or increase its borrowings. If you do not participate in the purchase of the new share issuance your percentage ownership of the SPV/property will decrease. If new debt is issued this will increase the costs to the SPV. This could mean that the value of your investment reduces as a result of more debt to pay or more shareholders to be repaid.`}
            </li>
            <li>
              {t`New shares could have additional rights that your shares don’t have or may be available at a lower price than you purchased them for. The new shares or additional borrowing may be payable before the existing shares or debt. These new issues of shares or bonds/debt could further reduce your chances of getting a return on your investment.`}
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>{t`6. The platform could fail`}</strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <ul>
            <li>
              {t`If the platform fails, it may be impossible for you to recoup your investments. It could take years to get your money back, or you may not get it back at all. Even though British Pearl has plans in place to prevent this, they may not work in a disorderly failure.`}
            </li>
          </ul>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>
            {t`If you are interested in learning more about how to protect yourself, visit the FCA’s website `}
            <MuiLink
              className={styles.link}
              href="https://www.fca.org.uk/investsmart"
            >
              here
            </MuiLink>
            {t`.`}
          </strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>
            {t`For further information about investment-based and loan-based crowdfunding, visit the FCA’s website `}
            <MuiLink
              className={styles.link}
              href="https://www.fca.org.uk/consumers/crowdfunding"
            >
              here
            </MuiLink>
            {t`.`}
          </strong>
        </Typography>
        <Typography variant="subtitle2" paragraph>
          <strong>
            {t`Further details on the key risks involved in investing through British Pearl can be found `}
            <MuiLink
              className={styles.link}
              href="https://www.britishpearl.com/key-risks"
            >
              here
            </MuiLink>
            {t`.`}
          </strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.button}
          onClick={onClose}
          variant="contained"
          size="medium"
          autoFocus
        >
          {t`Close`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { PermanentRiskWarningDialog };
