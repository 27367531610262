import { connect } from 'react-redux';
import { ReaccreditationDialog } from 'domains/Accreditation/ReaccreditationDialog';
import { createStructuredSelector } from 'reselect';
import { selectors as sessionProviderSelectors } from 'store/SessionProvider';
import { actions as globalActions } from 'store/App';

const ReaccreditationDialogContainer = connect(
  createStructuredSelector({
    disallowSkip: sessionProviderSelectors.selectUserAccreditationExpired,
  }),
  {
    onClose: globalActions.closeGlobalModal,
  }
)(ReaccreditationDialog);

export { ReaccreditationDialogContainer };
