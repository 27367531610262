import React from 'react';

import Config from 'config';

const ProgressCircle = ({
  abort,
  completed,
  fill,
  fixed,
  height,
  width,
  ...props
}) => {
  const shape = [
    <path
      d="M23 12c0-6.075-4.9255-11-11-11C5.9234 1 1 5.9237 1 12s4.9234 11 11 11c6.0745 0 11-4.925 11-11z"
      key="1"
    />,
  ];

  if (completed || fixed) {
    shape.push(
      <path
        d="M17 9l-7.5 7L7 13.5"
        key="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }

  if (abort) {
    shape.push(
      <path
        d="M8.49758389,7.76061036 L15.7262074,16.0601411"
        key="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
    shape.push(
      <path
        d="M8.27379257,16.0601411 L15.6930504,7.54173392"
        key="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }

  return (
    <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={completed && !fixed ? fill : Config.theme.colors.akaroa}
        strokeWidth="2"
      >
        {shape}
      </g>
    </svg>
  );
};

ProgressCircle.defaultProps = {
  abort: false,
  completed: false,
  fill: Config.theme.colors.green,
  fixed: false,
  height: 24,
  width: 24,
};

export default ProgressCircle;
