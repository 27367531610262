import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import { useStyles } from './PropertyCardImage.styles';

export const PropertyCardImageSkeleton: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.imageWrapper}>
      <Box position="absolute" width="100%" height="100%">
        <Skeleton animation="wave" variant="rect" height="100%" />
      </Box>
    </div>
  );
};
