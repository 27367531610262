import React, { FC } from 'react';
import ReactIdleTimer from 'react-idle-timer';
import { isServerSide } from 'utils/isSSR';

interface SessionIdleProps {
  onIdle: () => void;
  timeout: number;
  idle: boolean;
}

export const SessionIdle: FC<SessionIdleProps> = ({
  onIdle,
  idle,
  timeout,
  children,
}) => (
  <>
    {!isServerSide() && (
      <ReactIdleTimer debounce={1000} onIdle={onIdle} timeout={timeout} />
    )}
    {idle && children}
  </>
);
