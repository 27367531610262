import { createStyles, makeStyles } from '@material-ui/core/styles';

import { APP_BAR_DESKTOP_HEIGHT, APP_BAR_HEIGHT } from '../../constants';

export const useStyles = makeStyles(({ breakpoints, spacing }) =>
  createStyles({
    root: {
      display: 'block',
      width: 'auto',
      height: APP_BAR_HEIGHT - spacing(4),

      [breakpoints.up('lg')]: {
        height: APP_BAR_DESKTOP_HEIGHT - spacing(6),
      },
    },
  })
);
