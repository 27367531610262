import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Loader from './Loader';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={Loader} />
    <Route exact path={`${match.url}/:path`} component={Loader} />
  </Switch>
);
