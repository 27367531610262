import { getAsyncInjectors } from 'utils/asyncInjectors';

const defaultExport = mod => (mod.__esModule ? mod.default : mod);

export const injector = ({ arr, fn, name }) => {
  if (Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i += 2) {
      fn(arr[i], defaultExport(arr[i + 1]));
    }
  } else {
    fn(name, defaultExport(arr));
  }
};

const asyncInjectors = new Map();

export const getInjectors = store =>
  asyncInjectors.get(store) ||
  asyncInjectors.set(store, getAsyncInjectors(store)).get(store);

export const injectReducersAndSagas = ({
  reducers,
  sagas,
  name,
  injectReducer,
  injectSagas,
}) => {
  if (reducers) {
    injector({ arr: reducers, fn: injectReducer, name });
  }

  if (sagas) {
    injector({ arr: sagas, fn: injectSagas, name });
  }
};
