import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { LoadingLink as BaseLink } from 'components/Atoms/LoadingLink';
import { investorPaths } from 'router/paths';

import {
  Chevron,
  SidebarDashboard,
  SidebarDocuments,
  SidebarMyIsa,
  SidebarPortfolio,
  SidebarProfile,
  /* SidebarPlus, */
} from 'components/Atoms/Icons';
import { Wallet } from 'components/Molecules';
import Config from 'config';

import { actions as appActions } from 'store/App';

import { useI18n } from 'utils/i18nConnect';

import media from 'utils/responsive';

import TaxStatementIcon from '../../Atoms/Icons/TaxStatementIcon';

const SidebarClose = styled.div`
  background-color: ${Config.theme.colors.antiqueWhite};
  cursor: pointer;
  display: flex;
  padding: 1rem 1.625rem;

  svg {
    margin-left: auto;
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
`;

const Wrapper = styled.nav`
  background-color: ${Config.theme.colors.antiqueWhite};
  bottom: 0;
  display: none;
  left: 0;
  padding: 5.5rem 0 2rem;
  position: fixed;
  top: 0;
  transition: width 0.3s;
  z-index: 199;

  ${({ isCollapsed }) =>
    isCollapsed
      ? css`
          overflow: hidden;
          width: 4.75rem;

          div:first-child svg {
            transform: rotate(0deg);
          }

          li svg {
            margin-right: 1.75rem;
          }
        `
      : css`
          width: 20.25rem;
        `};

  ${media.large`
    display: block;
  `};
`;

const Navigation = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Link = styled(BaseLink)`
  align-items: center;
  color: ${Config.theme.colors.black};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 1;
  overflow: hidden;
  padding: 1rem 1.5rem;
  text-overflow: clip;
  white-space: nowrap;
  text-decoration: none;

  svg {
    color: ${Config.theme.colors.wafer};
    margin-right: 1.5rem;
    min-width: 1.5rem;
    transition: all 0.3s;

    .collapsed & {
      margin-right: 1.75rem;
    }
  }

  span {
    opacity: 1;
    transition: opacity 0.3s;

    .collapsed & {
      opacity: 0;
    }
  }

  &:hover {
    border-right: 0.25rem solid ${Config.theme.colors.wafer};

    svg {
      color: ${Config.theme.colors.quickSand};
    }
  }

  &.selected {
    border-right: 0.25rem solid ${Config.theme.colors.quickSand};
    color: ${Config.theme.colors.quickSand};

    svg {
      color: ${Config.theme.colors.quickSand};
    }
  }
`;

/*
const StyledSidebarPlus = styled(SidebarPlus)`
  padding: 4px;
`;
*/

export const Sidebar = ({ className, isCollapsed, toggleCollapsed }) => {
  const i18n = useI18n();

  return (
    <Wrapper
      aria-expanded={!isCollapsed}
      aria-label="Secondary"
      className={className}
      isCollapsed={isCollapsed}
      role="navigation"
    >
      <SidebarClose aria-label="Collapse sidebar" onClick={toggleCollapsed}>
        <Chevron />
      </SidebarClose>

      <Navigation>
        <li>
          <Link data-test-id="sidebar-dashboard" to={investorPaths.dashboard()}>
            <SidebarDashboard />

            <span>{i18n('Sidebar')`Dashboard`}</span>
          </Link>
        </li>

        <li>
          <Link to={investorPaths.notices()} data-test-id="sidebar-notices">
            <SidebarDocuments />

            <span>{i18n('Sidebar')`Notices`}</span>
          </Link>
        </li>

        <li>
          <Link
            to={investorPaths.mainAccount()}
            data-test-id="sidebar-main-account"
          >
            <SidebarPortfolio />

            <span>{i18n('Sidebar')`Main account`}</span>
          </Link>
        </li>

        <li>
          <Link
            to={investorPaths.isaAccount()}
            data-test-id="sidebar-isa-account"
          >
            <SidebarMyIsa />

            <span>{i18n('Sidebar')`ISA account`}</span>
          </Link>
        </li>

        <li>
          <Link to={investorPaths.documents()} data-test-id="sidebar-documents">
            <SidebarDocuments />

            <span>{i18n('Sidebar')`Documents`}</span>
          </Link>
        </li>

        <li>
          <Link
            to={investorPaths.taxStatement()}
            data-test-id="sidebar-profile"
          >
            <TaxStatementIcon />
            <span>{i18n('Sidebar')`Tax statement`}</span>
          </Link>
        </li>

        <li>
          <Link to={investorPaths.profile()} data-test-id="sidebar-profile">
            <SidebarProfile />

            <span>{i18n('Sidebar')`Profile`}</span>
          </Link>
        </li>
      </Navigation>

      <Wallet.Balance />
      {/*
      <Navigation>
        <li>
          <Link
            to={investorPaths.referFriend()}
            data-test-id="sidebar-refer-friend"
          >
            <StyledSidebarPlus />

            <span>{i18n('Sidebar')`Refer a friend`}</span>
          </Link>
        </li>
      </Navigation>
      */}
    </Wrapper>
  );
};

Sidebar.defaultProps = {
  className: '',
  isCollapsed: false,
  toggleCollapsed: () => {},
};

Sidebar.propTypes = {
  className: PropTypes.string,
  isCollapsed: PropTypes.bool,
  toggleCollapsed: PropTypes.func,
};

const mapStateToProps = state => ({
  isCollapsed: state.get('global').get('isSidebarCollapsed'),
});

const mapDispatchToProps = dispatch => ({
  toggleCollapsed: () => dispatch(appActions.sidebarCollapseToggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
