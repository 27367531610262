import React from 'react';

const SidebarMyIsa = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3 3h18v18H3z" />
      <path
        d="M6.3368 6.3368c-3.1157 3.1158-3.1157 8.1895 0 11.3053C7.8948 19.221 9.9578 20 12 20c2.042 0 4.1053-.779 5.6632-2.3368 3.1157-3.1158 3.1157-8.1895 0-11.3053-3.1158-3.137-8.2106-3.137-11.3264-.022zm7.979 9.4527h-4.2105c-.1264 0-.2527-.042-.358-.1053-.2947-.1895-.3578-.5895-.1684-.8842.421-.6105.505-1.0526.505-1.5368 0-.1895-.021-.4-.042-.6316h-.358c-.358 0-.632-.2737-.632-.6316 0-.358.2733-.6316.6312-.6316h.2316c-.021-.1263-.021-.2526-.021-.379-.105-1.642 1.095-2.6104 2.316-2.7578.0842 0 .1474-.021.2316-.021.5684-.021 1.116.1473 1.537.463.5472.4.905 1.0317.9683 1.7896.042.358-.2317.6526-.5686.6947-.1686.021-.337-.043-.4633-.148-.126-.105-.21-.253-.231-.421-.042-.4-.1895-.695-.463-.884-.2317-.169-.5475-.253-.8844-.211-.5894.063-1.242.484-1.2 1.4314 0 .1685.021.316.042.4633h1.6633c.358 0 .6315.2737.6315.6316 0 .358-.2736.6314-.6315.6314h-1.537c.021.2108.021.442.021.653 0 .421-.0843.821-.2317 1.242h3.221c.358 0 .6316.295.6316.6316 0 .3367-.295.6104-.6317.6104z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

SidebarMyIsa.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default SidebarMyIsa;
