import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import Config from 'config';

const {
  theme: { fontFamily, fontWeight, colors },
} = Config;

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: fontFamily.base.join(', '),
    body1: {
      fontWeight: fontWeight.book,
      fontSize: '1.125rem',
    },
    body2: {
      fontWeight: fontWeight.book,
      fontSize: '1rem',
    },
    h1: {
      fontSize: '2.5rem',
      fontWeight: fontWeight.medium,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: fontWeight.medium,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: fontWeight.medium,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: fontWeight.medium,
    },
  },
  palette: {
    error: {
      main: colors.red,
    },
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      main: colors.pomegranate,
    },
    secondary: {
      main: colors.akaroa,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  // TODO https://britishpearl.atlassian.net/browse/PIT-1526
  zIndex: {
    mobileStepper: 8000,
    appBar: 8100,
    drawer: 8200,
    modal: 8300,
    snackbar: 8400,
    tooltip: 8500,
  },
  // TODO https://britishpearl.atlassian.net/browse/PIT-1539
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024, // TODO: to change to 992px
      xl: 1400, // TODO:  to change to 1200px
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiDialog: {
      paper: {
        padding: '1rem',
      },
    },
  },
};
