import React from 'react';

const TickCircle = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M8.2247 12.442c-.2802-.2756-.7344-.2756-1.0146 0-.28.2757-.28.7226 0 .9983l2.3916 2.353c.2734.269.7142.2764.9968.017l7.174-6.5884c.2895-.266.3052-.713.035-.998-.2705-.285-.7244-.3004-1.014-.0344l-6.6673 6.123-1.9012-1.8708z" />
      <path d="M22.5306 12c0-5.816-4.7153-10.5306-10.5306-10.5306C6.1828 1.4694 1.4694 6.183 1.4694 12S6.1828 22.5306 12 22.5306c5.8153 0 10.5306-4.7147 10.5306-10.5306zM24 12c0 6.6275-5.3732 12-12 12-6.6288 0-12-5.3715-12-12S5.3712 0 12 0c6.6268 0 12 5.3725 12 12z" />
    </g>
  </svg>
);

TickCircle.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default TickCircle;
