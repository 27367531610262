import { isPlainObject, toLower } from 'lodash';

import Config from 'config';
import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

export default Api({
  accessToken: {
    url: '/users/oauth2/access_token',
    transformer: ({ data, status }) => {
      if (
        status === 200 &&
        isPlainObject(data) &&
        toLower(data.token_type) === 'bearer'
      ) {
        return {
          ...data,
          expires: Date.now() + (data.expires_in || 0) * 1000,
        };
      }

      return null;
    },
    helpers: {
      // aka login
      create: (email, password, scope = null, clientId = 'client_id') => {
        const data = {
          // eslint-disable-next-line camelcase
          client_id: clientId,
          email,
          password,
          scope, // Gotta Catch 'Em All
          // eslint-disable-next-line camelcase
          grant_type: 'password',
        };

        return [{}, { data, method: 'post' }];
      },
      refresh: (refreshToken, clientId = 'client_id') => {
        const data = {
          // eslint-disable-next-line camelcase
          client_id: clientId,
          // eslint-disable-next-line camelcase
          refresh_token: refreshToken,
          // eslint-disable-next-line camelcase
          grant_type: 'refresh_token',
        };

        return [{}, { data, method: 'post' }];
      },
      remove: token => [
        {},
        {
          headers: {
            common: {
              Authorization: token,
            },
          },
          method: 'delete',
        },
      ],
    },
  },
})
  .use('fetch', ApiAdapter())
  .use('rootUrl', Config.api.url);
