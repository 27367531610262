import React, { FC } from 'react';
import { Hidden } from '@material-ui/core';

import { Link } from 'components/Atoms/Link';
import { commonPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

import { Logo } from './Logo';
import { LogoMark } from './LogoMark';

export interface AppBarLogoProps {
  alwaysShowText?: boolean;
  inverse?: boolean;
}

export const AppBarLogo: FC<AppBarLogoProps> = ({
  alwaysShowText = false,
  inverse = false,
}) => {
  const t = useI18n()('components.Organisms.AppBarLogo');
  return (
    <Link to={commonPaths.home()} title={t`Home`}>
      {alwaysShowText ? (
        <Logo />
      ) : (
        <>
          <Hidden xsDown={!inverse} smUp={inverse}>
            <Logo />
          </Hidden>
          <Hidden smUp={!inverse} xsDown={inverse}>
            <LogoMark />
          </Hidden>
        </>
      )}
    </Link>
  );
};
