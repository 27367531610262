import { Size } from './types';

type GetPy = (size?: Size) => number;

export const getPy: GetPy = size => {
  switch (size) {
    case 'small':
      return 2;
    case 'medium':
      return 4;
    case 'large':
      return 8;
    default:
      return 0;
  }
};
