import { TabList as BaseTabList } from 'react-tabs';
import styled from 'styled-components';

import Config from 'config';

const TabList = styled(BaseTabList)`
  align-items: stretch;
  background: ${Config.theme.colors.almond};
  border-radius: 2rem;
  display: flex;
  margin: 0 auto;
  padding: 0.25rem;
`;

// $FlowFixMe
TabList.tabsRole = 'TabList';

export default TabList;
