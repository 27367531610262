import React from 'react';
import styled from 'styled-components';

import Config from 'config';
import { useI18n } from 'utils/i18nConnect';

import media from 'utils/responsive';

const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${Config.theme.fontWeight.light};
`;

const Amount = styled.div`
  font-size: 2rem;

  ${media.medium`
    font-size: 2.5rem;
  `};
`;
Amount.displayName = 'Amount';

const Label = styled.div`
  font-size: 0.8rem;
`;
Label.displayName = 'Label';

const LabelColour = styled.span`
  margin-right: 1rem;

  &::after {
    background-color: ${({ colour }) => colour};
    content: '';
    display: inline-block;
    height: 0.25rem;
    width: 2rem;
  }
`;

export const BalanceLabel = ({ amount, className, label, labelColour }) => {
  const t = useI18n()('BalanceLabel');
  return (
    <StyledLabel className={className}>
      <Amount>{t`${amount}:c`}</Amount>

      <Label>
        <LabelColour colour={labelColour} />

        {label}
      </Label>
    </StyledLabel>
  );
};

BalanceLabel.defaultProps = {
  className: undefined,
};

export default BalanceLabel;
