import { format } from 'date-fns';
import { isArray, isEmpty } from 'lodash';

import Config from 'config';
import Api from 'services/Api';

import { filterByIsoCode } from 'data/countries';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

export default Api({
  users: {
    url: '/users/:id',
    helpers: {
      get: id => OAuth.getAuthHeader().then(header => [{ id }, header]),
      create: (user, captchaToken) => [
        {},
        {
          data: { captchaToken, ...user },
          method: 'post',
        },
      ],
    },
    transformer({ data }) {
      let { users } = data;

      if (!isArray(data)) {
        users = [data];
      }

      users = users.map(user => {
        // Slightly convoluted logic, to adapt to PIP model(s).
        // In the same /users/* endpoints, the response model is quite different:
        // - Can have a userView object, that contains all the properties, or just the properties.
        // - Can have a role or not (and the default role is specific to the endpoint called)
        // - Can have an address object or not

        const {
          address: addressWithIso,
          roles = [],
          userView = user,
          verificationInfo,
          classificationInfo,
          previousAddress: prevAddWithIso,
        } = user;

        const period = prevAddWithIso ? 0 : 1;

        const { countryCodeIso2: currentCountryIso } = addressWithIso || {};
        const address = addressWithIso && {
          ...addressWithIso,
          period,
          countryCodeIso2:
            currentCountryIso && filterByIsoCode(currentCountryIso).country,
        };

        const { countryCodeIso2: previousCountryIso } = prevAddWithIso || {};
        const previousAddress = prevAddWithIso && {
          ...prevAddWithIso,
          countryCodeIso2:
            previousCountryIso && filterByIsoCode(previousCountryIso).country,
        };

        const { nationalityIsoCode: nationalityIso } = userView || {};
        const nationalityIsoCode =
          nationalityIso && filterByIsoCode(nationalityIso).nationality;

        const addressDefault = address ? { address } : {};
        const addressPrevious = previousAddress ? { previousAddress } : {};

        const [attributionGroup, attribution, specify] =
          userView && userView.attribution
            ? userView.attribution.split(' > ')
            : [];

        const [y, m, d] = ((userView && userView.birthDate) || '').split('-');

        return {
          ...userView,
          birthDate: userView.birthDate ? `${d} / ${m} / ${y}` : undefined,
          ...addressDefault,
          roles,
          verificationInfo,
          classificationInfo,
          attribution,
          attributionGroup,
          specify,
          ...addressPrevious,
          nationalityIsoCode,
        };
      });

      users = isArray(data) ? users : users[0];

      return users;
    },
  },
  modifyUser: {
    url: '/users',
    helpers: {
      edit: phoneNumber =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: phoneNumber,
            method: 'patch',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  confirmEmail: {
    url: '/users/ce/:token',
    helpers: {
      confirm: token => [{ token }],
    },
    transformer: ({ data }) => data.email,
  },
  resendVerificationEmail: {
    url: '/users/email-verifications',
    helpers: {
      resend: (email, captchaToken) => [
        {},
        {
          data: {
            email,
            captchaToken,
          },
          method: 'post',
          transformRequest: [data => data],
        },
      ],
    },
    transformer: ({ data }) => isEmpty(data),
  },
  classification: {
    url: '/users/classification',
    helpers: {
      classify: (classificationMethod, esignature, quizVersion) =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: {
              classificationMethod,
              esignature,
              quizVersion,
            },
            method: 'post',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => isEmpty(data),
  },
  quizStatus: {
    url: '/users/classification-quiz/number-of-tries',
    helpers: {
      get: () =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            validateStatus: status =>
              (status >= 200 && status < 300) || status === 404,
            ...header,
          },
        ]),
    },
    // @TODO: Fix this bit against PIP, since for a newly registered user, the quiz endpoint returns a 404 and we need
    // to fudge the response to start the Quiz process.
    transformer: ({ data }) => data || { canTryQuiz: true, triesUsed: 0 },
  },
  quizAttempt: {
    url: '/users/classification-quiz',
    helpers: {
      submit: data =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data,
            method: 'put',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  verification: {
    url: '/users/verification-request',
    helpers: {
      verify: user =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: user,
            method: 'post',
            transformRequest: [
              data => {
                // Getting the fields from the form and removing the frontend-only properties.
                const {
                  address,
                  previousAddress,
                  roles,
                  password,
                  attributionGroup,
                  classificationInfo,
                  ...userVerifyView
                } = data;
                // TODO introduce data transformers
                const cleanAddress = {
                  addressLine1: address.addressLine1,
                  buildingName: address.buildingName,
                  buildingNumber: address.buildingNumber,
                  flatNumber: address.flatNumber,
                  town: address.town,
                  postcode: address.postcode,
                  countryCodeIso2: address.countryCodeIso2,
                };
                const cleanPreviousAddress = previousAddress
                  ? {
                      addressLine1: previousAddress.addressLine1,
                      buildingName: previousAddress.buildingName,
                      buildingNumber: previousAddress.buildingNumber,
                      flatNumber: previousAddress.flatNumber,
                      town: previousAddress.town,
                      postcode: previousAddress.postcode,
                      countryCodeIso2: previousAddress.countryCodeIso2,
                    }
                  : undefined;
                const cleanUserVerifyView = {
                  title: userVerifyView.title,
                  firstName: userVerifyView.firstName,
                  middleNames: userVerifyView.middleNames,
                  lastName: userVerifyView.lastName,
                  birthDate: userVerifyView.birthDate
                    ? format(new Date(userVerifyView.birthDate), 'yyyy-MM-dd')
                    : undefined,
                  email: userVerifyView.email,
                  phoneNumber: userVerifyView.phoneNumber,
                  nationalityIsoCode: userVerifyView.nationalityIsoCode,
                  attribution: userVerifyView.attribution,
                };

                return {
                  userAndAddressVerifyView: {
                    userVerifyView: cleanUserVerifyView,
                    address: cleanAddress,
                    previousAddress: cleanPreviousAddress,
                  },
                };
              },
            ],
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => isEmpty(data),
  },
  forgotPassword: {
    url: '/users/password/:action',
    helpers: {
      token: email => [
        {
          action: 'reset-tokens',
        },
        {
          data: email,
          method: 'post',
        },
      ],
      change: data => [
        {},
        {
          data,
          method: 'post',
        },
      ],
    },
    transformer: ({ data }) => isEmpty(data),
  },
  newsletter: {
    url: '/users/email/newsletter',
    helpers: {
      subscribe: email => [
        {},
        {
          data: email,
          method: 'post',
          transformRequest: [data => ({ email: data })],
        },
      ],
    },
    transformer: ({ data }) => isEmpty(data),
  },
  webLead: {
    url: '/crm/developer-details',
    helpers: {
      subscribe: userData => [
        {},
        {
          data: userData,
          method: 'post',
          transformRequest: [data => ({ ...data })],
        },
      ],
    },
    transformer: ({ data }) => isEmpty(data),
  },
  verificationDocuments: {
    url: '/users/manual-verification-documents',
    helpers: {
      get: () => OAuth.getAuthHeader().then(header => [{}, header]),
      upload: file =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: file,
            method: 'post',
            transformResponse: data => data,
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
