import { isArray } from 'lodash';

import api from '../endpoints/Accounts';

export default class AccountHistory {
  /**
   * Get accounts dashboard
   */
  static find(accountId) {
    return api.history
      .find(accountId)
      .then(data => {
        if (isArray(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }
}
