import React, { FC } from 'react';

import Config from 'config';
import { Logo as LogoBase } from 'components/Atoms/Icons';

import { useStyles } from './Logo.styles';

export const Logo: FC = () => {
  const classes = useStyles();
  return (
    <LogoBase
      className={classes.root}
      width={349}
      height={105}
      textColor={Config.theme.colors.black}
    />
  );
};
