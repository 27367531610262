import React from 'react';

import Config from 'config';

const Properties = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none">
      <path
        d="M7.862 23.53c-.27 0-.49-.22-.49-.49V8.824c0-.27.22-.49.49-.49h8.824c.27 0 .49.22.49.49V23.04c0 .27-.22.49-.49.49H7.862zm.49-.98h7.844V9.314H8.352V22.55z"
        fill={fill}
      />
      <path d="M4.92 18.628h.98v-.98h-.98" fill={fill2} />
      <path
        d="M8.843 9.314c-.27 0-.49-.22-.49-.49v-2.45c0-.272.22-.49.49-.49h6.863c.27 0 .49.218.49.49v2.45c0 .27-.22.49-.49.49H8.843zm6.372-.98v-1.47H9.333v1.47h5.882z"
        fill={fill}
      />
      <path
        d="M10.803 6.863c-.27 0-.49-.22-.49-.49v-2.45c0-.272.22-.492.49-.492h2.942c.27 0 .49.22.49.494v2.45c0 .272-.22.49-.49.49h-2.942zm2.45-.98v-1.47h-1.96v1.47h1.96z"
        fill={fill}
      />
      <path
        d="M9.823 11.765h4.902v-.98H9.823m0 3.92h4.902v-.98H9.823m0 3.923h4.902v-.98H9.823m0 3.922h4.902v-.98H9.823M4.92 12.746h.98v-.98h-.98m0-.98h.98v-.98h-.98m0 4.9h.98v-.98h-.98m0 2.94h.98v-.98h-.98m0 4.904h.98v-.98h-.98"
        fill={fill2}
      />
      <path
        d="M21.098 10.294V22.55h-4.412v.98h4.902c.27 0 .49-.22.49-.49V9.804c0-.27-.22-.49-.49-.49h-4.902v.98h4.412z"
        fill={fill}
      />
      <path
        d="M18.647 14.216v1.47h.98v-1.47m-.98-2.45v1.47h.98v-1.47m-.98 4.9v1.47h.98v-1.47m-.98 2.944v1.47h.98v-1.47"
        fill={fill2}
      />
      <path
        d="M12.764 3.922V0h-.98v3.922m8.333 5.882h.98v-2.45c0-.272-.22-.49-.49-.49h-4.9v.98h4.41v1.96zM8.352 5.392h.98v-3.43c0-.13-.05-.256-.143-.348l-.49-.49c-.194-.19-.505-.19-.696 0l-5.39 5.392c-.093.092-.144.217-.144.347V23.04c0 .27.22.49.49.49h5.412v-.98H3.45V7.066l4.902-4.902v3.228z"
        fill={fill}
      />
    </g>
  </svg>
);

Properties.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default Properties;
