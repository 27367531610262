import { isArray } from 'lodash';

import Config from 'config';

import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';
import { pipInvestmentAndPropertyDetailedViewV2ToInvestmentWithProperty } from '../transformers';
import { removeValueStructureRecursively } from '../adapters/removeValueStructureRecursively';

export default Api({
  investmentsV1: {
    url: '/investments/:id',
    helpers: {
      findAll: () => [{ id: 'v2' }],
      get: id => [{ id }],
    },
    transformer({ data: rawData }) {
      const data = removeValueStructureRecursively(rawData);
      const isMultiple = data.investmentItems && isArray(data.investmentItems);
      const properties = isMultiple ? data.investmentItems : [data];

      const results = properties.map(
        ({ investment, propertyDetailed: { property, ...rest } }) => ({
          ...investment,
          property: {
            ...property,
            ...rest,
          },
        })
      );

      return isMultiple
        ? [results, data.comingSoonInvestmentItems]
        : results[0];
    },
  },
  investment: {
    url: '/investments/v2/:id',
    helpers: {
      get: id => [{ id }],
    },
    transformer({ data }) {
      return pipInvestmentAndPropertyDetailedViewV2ToInvestmentWithProperty(
        data
      );
    },
  },
  investments: {
    url: '/investments/v2',
    helpers: {
      get: () => [],
    },
    transformer({ data: { investmentItems, comingSoonInvestmentItems } }) {
      return {
        comingSoonInvestments: comingSoonInvestmentItems,
        investments: investmentItems.map(
          pipInvestmentAndPropertyDetailedViewV2ToInvestmentWithProperty
        ),
      };
    },
  },
  sharesOrders: {
    url: '/investments/shares/orders',
    helpers: {
      create: orderDetails =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: orderDetails,
            method: 'post',
            // Catching both successful and failed responses.
            validateStatus: status =>
              (status >= 200 && status < 300) || status === 400,
            transformRequest: [
              ({
                assetType,
                tcVersionToSign,
                totalCleanPlusFeesAmount,
                totalCleanPlusFeesAndTaxesAmount,
                ...cleanData
              }) => cleanData,
            ],
            ...header,
          },
        ]),
    },
    transformer: ({ data: rawData }) => {
      const { uuid, errors = null } = removeValueStructureRecursively(rawData);

      return {
        transactionId: uuid || 'failed',
        errors,
        assetType: 'shares',
      };
    },
  },
  loansOrders: {
    url: '/investments/loans/orders',
    helpers: {
      create: orderDetails =>
        OAuth.getAuthHeader().then(header => [
          {},
          {
            data: orderDetails,
            method: 'post',
            // Catching both successful and failed responses.
            validateStatus: status =>
              (status >= 200 && status < 300) || status === 400,
            transformRequest: [
              ({
                assetType,
                tcVersionToSign,
                totalCleanPlusFeesAmount,
                totalCleanPlusFeesAndTaxesAmount,
                ...cleanData
              }) => cleanData,
            ],
            ...header,
          },
        ]),
    },
    transformer: ({ data: rawData }) => {
      const { uuid, errors = null } = removeValueStructureRecursively(rawData);

      return {
        transactionId: uuid || 'failed',
        errors,
        assetType: 'loans',
      };
    },
  },
  cancelSharesOrder: {
    url: '/investments/shares/orders/:orderUuid',
    helpers: {
      cancel: orderUuid =>
        OAuth.getAuthHeader().then(header => [
          {
            orderUuid,
          },
          {
            method: 'delete',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => removeValueStructureRecursively(data),
  },
  cancelLoansOrder: {
    url: '/investments/loans/orders/:orderUuid',
    helpers: {
      cancel: orderUuid =>
        OAuth.getAuthHeader().then(header => [
          {
            orderUuid,
          },
          {
            method: 'delete',
            ...header,
          },
        ]),
    },
    transformer: ({ data }) => data,
  },
  marketPlace: {
    url: '/investments/:id/marketplace',
    helpers: {
      get: async (id, loggedIn) => {
        const header = loggedIn ? await OAuth.getAuthHeader() : null;
        return [{ id }, header];
      },
    },
    transformer: ({ data: rawData }) => {
      const {
        investmentId,
        shareBid: { items: shareBid },
        shareOffer: { items: shareOffer },
        loanBid: { items: loanBid },
        loanOffer: { items: loanOffer },
      } = removeValueStructureRecursively(rawData);

      return {
        investmentId,
        shareBid,
        shareOffer,
        loanBid,
        loanOffer,
      };
    },
  },
  orderStatus: {
    url: '/investments/:assetType/orders/:uoid',
    helpers: {
      get: (assetType, uoid) =>
        OAuth.getAuthHeader().then(header => [{ assetType, uoid }, header]),
    },
    transformer: ({ data }) => removeValueStructureRecursively(data),
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
