/* eslint-disable no-useless-escape */

import { parse } from 'url';

import { concat, isArray, mergeWith, omit } from 'lodash';

import qs from 'qs';

const rxClean = /\/?(\(:[^\)]+\)|:[^\/]+\/?)/g;

/**
 * Url modification
 * @param  {String} url     url template
 * @param  {Object} parameters  params for url template
 * @param  {Object} options transformation options, accepts +delimiter+, +arrayFormat+,
 *                          +qsStringifyOptions+ and +qsParseOptions+
 * @return {String}         result url
 */
export default function urlTransform(url, parameters = {}, options = {}) {
  if (!url) {
    return '';
  }
  const usedKeys = {};
  const params = parameters || {};
  const urlWithParams = Object.keys(params).reduce((uri, key) => {
    const value = params[key];
    const rx = new RegExp(`(\\(:${key}\\)|:${key})(\/?)`, 'g');
    return uri.replace(rx, (_, _1, slash) => {
      usedKeys[key] = value;
      return value ? value + slash : value;
    });
  }, url);

  if (!urlWithParams) {
    return urlWithParams;
  }

  const { protocol = 'http', host, path = '/' } = parse(urlWithParams);
  const cleanURL = host
    ? `${protocol}//${host}${path.replace(rxClean, '')}`
    : path.replace(rxClean, '');
  const usedKeysArray = Object.keys(usedKeys);

  if (usedKeysArray.length !== Object.keys(params).length) {
    const urlObject = cleanURL.split('?');
    const { arrayFormat, delimiter } = options;
    const qsParseOptions = {
      arrayFormat,
      delimiter,
      ...options.qsParseOptions,
    };

    const parsedParams = urlObject[1]
      ? qs.parse(urlObject[1], qsParseOptions)
      : {};
    const mergeParams = mergeWith(
      parsedParams,
      omit(params, usedKeysArray),
      (objValue, srcValue) =>
        isArray(objValue) || isArray(srcValue)
          ? concat(objValue || [], srcValue)
          : undefined
    );
    const qsStringifyOptions = {
      arrayFormat,
      delimiter,
      ...options.qsStringifyOptions,
    };
    const urlStringParams = qs.stringify(mergeParams, qsStringifyOptions);
    return `${urlObject[0]}?${urlStringParams}`;
  }
  return cleanURL;
}
