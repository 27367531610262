const RECORD_NAME = 'isa-redirect';
const DURATION = 1000 * 60 * 60;

/**
 * Set record in local storage for ISA flow.
 *
 * @param {number} duration miliseconds
 */
export function createIsaLsRecord(duration = DURATION) {
  const location = {
    path: '/investor/isa-account',
  };
  const expires = Date.now() + duration;
  const record = JSON.stringify({
    location,
    expires,
  });
  localStorage.setItem(RECORD_NAME, record);
}

export function clearIsaLsRecord() {
  localStorage.removeItem(RECORD_NAME);
}

/**
 * Check if ISA flow record is still valid and return location to
 * redirect user to.
 *
 * @returns {object|string} saved record
 */
export function readIsaLsRecord() {
  try {
    const record = JSON.parse(localStorage.getItem(RECORD_NAME));
    const { location, expires } = record;

    if (Date.now() > parseInt(expires, 10)) {
      return '';
    }

    clearIsaLsRecord();
    return location.path;
  } catch (err) {
    return '';
  }
}
