import React from 'react';

import { useStyles } from './MenuTrigger.styles';

export const MenuTrigger = React.forwardRef<
  HTMLAnchorElement,
  React.HTMLAttributes<HTMLAnchorElement>
>(function Anchor(props, ref) {
  const classes = useStyles();
  return (
    <a
      {...props}
      ref={ref}
      className={classes.root}
      data-test-id="logged-in-menu"
    >
      {props.children}
    </a>
  );
});
