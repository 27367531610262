import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from 'components/Organisms/AppBar';
import { Footer } from 'components/Organisms/footer';
import withBaseTemplate from '../Base';

export const ReaccreditationRaw = ({ children }) => (
  <>
    <AppBar />
    {children}
    <Footer hideMarketing hideLegal />
  </>
);

ReaccreditationRaw.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withBaseTemplate(ReaccreditationRaw);
