import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { LogoMarkGray } from 'components/Atoms/Icons';

export const PropertyCardPlaceholder: FC = () => (
  <Box paddingBottom="56.25%" position="relative">
    <Box
      component={LogoMarkGray}
      position="absolute"
      m="auto"
      width="64px"
      height="64px"
      left={0}
      right={0}
      top={0}
      bottom={0}
    />
  </Box>
);
