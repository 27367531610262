import React, { FC } from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';

/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore TODO: https://britishpearl.atlassian.net/browse/PIT-1979
import DesktopLogos from 'assets/images/FeaturedBy/LogosDesktop.png';
// @ts-ignore TODO: https://britishpearl.atlassian.net/browse/PIT-1979
import MobileLogos from 'assets/images/FeaturedBy/LogosMobile.png';
// @ts-ignore TODO: https://britishpearl.atlassian.net/browse/PIT-1979
import TabletLogos from 'assets/images/FeaturedBy/LogosTablet.png';
/* eslint-enable @typescript-eslint/ban-ts-ignore */

import { Img } from 'components/Atoms/Img';
import { Section } from 'components/Molecules/Section';
import { useI18n } from 'utils/i18nConnect';

import { useStyles } from './FeaturedBy.styles';

export const FeaturedBy: FC = () => {
  const t = useI18n()('molecules.FeaturedBy');
  const classes = useStyles();

  return (
    <Section color="grey" size="medium">
      <Box mb={4} textAlign="center">
        <Typography variant="h4">{t`As featured by`}</Typography>
      </Box>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={12} lg={12} xl={9}>
          <Hidden implementation="css" only={['xs', 'sm', 'md']}>
            <Img
              alt={t`As featured by`}
              src={DesktopLogos}
              placeholder={false}
              className={classes.img}
            />
          </Hidden>
          <Hidden implementation="css" only={['xs', 'sm', 'lg', 'xl']}>
            <Img
              alt={t`As featured by`}
              src={TabletLogos}
              placeholder={false}
              className={classes.img}
            />
          </Hidden>
          <Hidden implementation="css" only={['md', 'lg', 'xl']}>
            <Img
              alt={t`As featured by`}
              src={MobileLogos}
              placeholder={false}
              className={classes.img}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Section>
  );
};
