import { useCallback, useState } from 'react';
import { User } from 'services/Pip';
import { fbTrack, track } from 'utils/analytics';
import { logger } from 'services/Logger';
import { BrowserStorage } from 'services/BrowserStorage';
import { FormValues } from './types';

const NEWSLETTER_SUBSCRIBE = 'newsletterSubscribed';

interface UseSubscriptionValues {
  initialSent: boolean;
  sent: boolean;
  handleSubmission: (values: FormValues) => void;
}

export const useSubscription = (): UseSubscriptionValues => {
  const initialSent = !!BrowserStorage.get(NEWSLETTER_SUBSCRIBE);
  const [sent, setSent] = useState(initialSent);

  const handleSubmission = useCallback(({ email }) => {
    try {
      User.newsletterSubscribe(email);
      track('User', 'Subscribe to newsletter');
      fbTrack('Lead', {
        // eslint-disable-next-line camelcase,@typescript-eslint/camelcase
        content_name: 'Subscribe to newsletter',
      });
      setSent(true);
      BrowserStorage.set(NEWSLETTER_SUBSCRIBE, '1');
    } catch (e) {
      logger.error(e);
    }
  }, []);

  return { initialSent, sent, handleSubmission };
};
