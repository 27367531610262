import { isArray, isPlainObject } from 'lodash';

import api from '../endpoints/BankDetails';

export default class BankDetail {
  /**
   * Get detail about given bank
   */
  static get(id) {
    if (!id) {
      return Promise.reject();
    }
    return api.bankDetails
      .get(id)
      .then(bankDetail => {
        if (isPlainObject(bankDetail)) {
          return bankDetail;
        }
        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get all bank accounts
   */
  static findAll() {
    return api.bankDetails
      .findAll()
      .then(bankDetails => {
        if (isArray(bankDetails)) {
          return bankDetails;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Create new bank details
   */
  static create(bankDetail) {
    return api.bankDetails
      .create(bankDetail)
      .then(createdBankDetail => {
        if (isPlainObject(createdBankDetail)) {
          return createdBankDetail;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Delete bank details
   */
  static remove(id) {
    if (!id) {
      return Promise.reject();
    }

    return api.bankDetails.remove(id).then(() => true);
  }
}
