const onlyNums = v => v.replace(/[^\d]/g, '');

const mixedChars = c => c.replace(/[^0-9a-zA-z]/g, '');

export const normalizeSortCode = value => {
  if (!value) {
    return value;
  }

  const newValue = onlyNums(value);

  if (newValue.length <= 2) {
    return newValue;
  }
  if (newValue.length <= 4) {
    return `${newValue.slice(0, 2)}-${newValue.slice(2)}`;
  }
  return `${newValue.slice(0, 2)}-${newValue.slice(2, 4)}-${newValue.slice(
    4,
    6
  )}`;
};

export const normalizeNINumber = value => {
  if (!value) {
    return value;
  }

  const newValue = mixedChars(value);

  if (newValue.length <= 2) {
    return newValue;
  }

  if (newValue.length <= 4) {
    return `${newValue.slice(0, 2).toUpperCase()} ${newValue
      .slice(2)
      .toUpperCase()}`;
  }

  if (newValue.length <= 6) {
    return `${newValue.slice(0, 2)} ${newValue.slice(2, 4)} ${newValue.slice(
      4,
      6
    )}`;
  }

  if (newValue.length <= 8) {
    return `${newValue.slice(0, 2)} ${newValue.slice(2, 4)} ${newValue.slice(
      4,
      6
    )} ${newValue.slice(6, 8)}`;
  }

  return `${newValue.slice(0, 2).toUpperCase()} ${newValue.slice(
    2,
    4
  )} ${newValue.slice(4, 6)} ${newValue.slice(6, 8)}
 ${newValue.slice(8, 9).toUpperCase()}`;
};

export const normalizeAccountNumber = value =>
  value ? value.replace(/[^\d]/g, '').slice(0, 8) : value;

export const normalizePhone = value =>
  value ? value.replace(/[^\d+ ()-]/g, '') : value;

export const normalizeNumericValues = value =>
  value ? value.replace(/[^\d]/g, '') : value;

export const normalizeMoney = value => {
  if (!value) {
    return value;
  }

  const parts = value.replace(/[^\d^.]/g, '').split('.');

  if (parts.length > 2) {
    parts.splice(2);
  }

  if (parts.length > 1) {
    parts[1] = parts[1].slice(0, 2);
  }

  return parts.join('.');
};

export const normalizeCardNumber = value => {
  if (!value) {
    return value;
  }

  const newValue = onlyNums(value);

  if (newValue.length <= 4) {
    return newValue;
  }
  if (newValue.length <= 8) {
    return `${newValue.slice(0, 4)} ${newValue.slice(4)}`;
  }
  if (newValue.length <= 12) {
    return `${newValue.slice(0, 4)} ${newValue.slice(4, 8)} ${newValue.slice(
      8
    )}`;
  }
  return `${newValue.slice(0, 4)} ${newValue.slice(4, 8)} ${newValue.slice(
    8,
    12
  )} ${newValue.slice(12, 16)}`;
};

export const normalizeExpiry = value => {
  if (!value) {
    return value;
  }

  const newValue = onlyNums(value);

  if (newValue.length <= 2) {
    return newValue;
  }
  return `${newValue.slice(0, 2)} / ${newValue.slice(2, 4)}`;
};

export const normalizeCvv = value => {
  if (!value) {
    return value;
  }

  return onlyNums(value).slice(0, 3);
};

export const normalizePercentages = values => {
  const total = values.reduce((acc, x) => acc + x, 0);

  // Store indices and sort
  const percentages = values
    .map((x, i) => ({ i, value: (x / total) * 100 }))
    .sort((a, b) => a.value - b.value);

  // Rounding on largest value = total of rounding errors on other elements
  const rounded = percentages.map(x => ({
    i: x.i,
    value: Math.round(x.value),
  }));
  const error = percentages.reduce(
    (acc, x, i, arr) =>
      acc + (i < arr.length - 1 ? x.value - rounded[i].value : 0),
    0
  );
  rounded[rounded.length - 1].value = Math.round(
    percentages[percentages.length - 1].value + error
  );

  // Undo sort using stored indices
  return rounded.sort((a, b) => a.i - b.i).map(x => x.value);
};

export const normalizeEmail = value => value.toLowerCase().replace(/\s/g, '');
