import React from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import {
  historyPropTypes,
  location as locationPropTypes,
} from 'history-prop-types';
import { TrueLocationContextProvider } from './TrueLocationContext';

const LayeredSwitchRaw = ({
  children,
  layer,
  component,
  location: trueLocation,
  history,
}) => {
  const pathLayers = trueLocation.pathname.split('~');
  const formattedPath = pathLayers[layer];

  const ReactRouterSwitch = (
    <Switch location={{ ...trueLocation, pathname: formattedPath }}>
      {children}
    </Switch>
  );

  return (
    <TrueLocationContextProvider trueLocation={trueLocation}>
      {component
        ? component({
            formattedPath,
            pathLayers,
            layer,
            history,
            children: ReactRouterSwitch,
          })
        : ReactRouterSwitch}
    </TrueLocationContextProvider>
  );
};

LayeredSwitchRaw.propTypes = {
  children: PropTypes.node.isRequired,
  layer: PropTypes.oneOf([0, 1]).isRequired,
  component: PropTypes.func,
  location: PropTypes.shape(locationPropTypes).isRequired,
  history: PropTypes.shape(historyPropTypes).isRequired,
};

LayeredSwitchRaw.defaultProps = {
  component: null,
};

export const LayeredSwitch = withRouter(LayeredSwitchRaw);
