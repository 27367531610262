import React from 'react';

import BuildPortfolio from 'assets/images/HomePage/BuildPortfolio.jpg';
import Convenient from 'assets/images/HomePage/Convenient.jpg';
import DevelopmentInvestment from 'assets/images/HomePage/DevelopmentInvestment.jpg';
import ExpertAdvantage from 'assets/images/HomePage/ExpertAdvantage.jpg';
import ISA from 'assets/images/HomePage/ISA.jpg';
import RentalInvestment from 'assets/images/HomePage/RentalInvestment.jpg';
import ResaleMarket from 'assets/images/HomePage/ResaleMarket.jpg';
import SharesLoans from 'assets/images/HomePage/SharesLoans.jpg';
import TransparentFinancials from 'assets/images/HomePage/TransparentFinancials.jpg';

import { Tooltip } from 'components/Atoms/Tooltip';
import { Link } from 'components/Atoms/Link';
import { DoubleRosette, Lock, Wallet } from 'components/Atoms/NewIcons';
import Config from 'config';
import { commonPaths } from 'router/paths';

const benefitsItems = i18n => [
  {
    id: 'home.benefitsItems.resaleMarket',
    title: i18n('Home')`Resale Market`,
    description: i18n(
      'Home'
    )`Do you want to exit an investment early? Have you missed out? Our Resale Market
      allows you to list your shares and loans during the life of the investment.`,
    Image: ResaleMarket,
  },
  {
    id: 'home.benefitsItems.expertAdvantage',
    title: i18n('Home')`Expert advantage`,
    description: i18n(
      'Home'
    )`Our professional acquisition and management team has proven experience of achieving
      both competitive monthly income and long term growth.`,
    Image: ExpertAdvantage,
  },
  {
    id: 'home.benefitsItems.isa',
    title: i18n('Home')`IFISA`,
    description: (
      <>
        {i18n('Home')`Our loan investments are ISA eligible, so you can earn
        interest tax-free in a British Pearl IFISA.`}
        {'  '}
        <Link to={commonPaths.isa()} className="link">
          {i18n('Home')`Read more about out IFSA`}
        </Link>
        {'. '}

        {i18n(
          'Home'
        )` ISA rules apply.  Capital at risk. Read our full risk statement `}
        <Link to={commonPaths.keyRisks()} className="link">
          {i18n('Home')`here`}
        </Link>
        {i18n('Home')`.`}
      </>
    ),
    Image: ISA,
  },
  {
    id: 'home.benefitsItems.buildPortfolio',
    title: i18n('Home')`Build your portfolio`,
    description: i18n(
      'Home'
    )`Choose the level of risk you are comfortable with and invest in both rental or
      development properties across the UK.`,
    Image: BuildPortfolio,
  },
  {
    id: 'home.benefitsItems.convenient',
    title: i18n('Home')`Convenient`,
    description: i18n(
      'Home'
    )`Remove the stress of buying and managing investment properties by investing from
      anywhere using our easy and secure online process that even works on your mobile.`,
    Image: Convenient,
  },
  {
    id: 'home.benefitsItems.developmentInvestment',
    title: i18n('Home')`Development investment`,
    description: i18n(
      'Home'
    )`Potentially higher returns, with terms up to two years. Share Investors receive
      a share of any capital gain on sale. Loan Investors receive monthly fixed interest.`,
    Image: DevelopmentInvestment,
  },
  {
    id: 'home.benefitsItems.makeALoan',
    title: i18n('Home')`Shares & Loans`,
    description: i18n(
      'Home'
    )`We provide access to the advantages enjoyed by property professionals.
    Share Investors have access to property investment and Loan Investors can act like a bank and provide a mortgage.`,
    Image: SharesLoans,
  },
  {
    id: 'home.benefitsItems.transparentFinancials',
    title: i18n('Home')`Transparent financials`,
    description: i18n(
      'Home'
    )`We always want you to know exactly where you stand. So we give you full investment
      information with no hidden fees.`,
    Image: TransparentFinancials,
  },
  {
    id: 'home.benefitsItems.rentalInvestment',
    title: i18n('Home')`Rental investment`,
    description: i18n(
      'Home'
    )`Typical investment term is 3 to 5 years. Share Investors receive a share of any net
     rental income and any capital gain on sale. Loan Investors receive monthly fixed interest.`,
    Image: RentalInvestment,
  },
];

const securityItems = i18n => [
  {
    id: 'home.securityItems.secured',
    title: i18n('Home')`Ring-fenced investments`,
    color: Config.theme.colors.mangoTango,
    description: i18n(
      'Home'
    )`Each property is owned separately and ring-fenced from any liabilities of other
      properties and from British Pearl`,
    Icon: Lock,
  },
  {
    id: 'home.securityItems.fundWallet',
    title: i18n('Home')`Segregated client money`,
    color: Config.theme.colors.bostonBlue,
    description: (
      <span>
        {i18n(
          'Home'
        )`Your uninvested money is held in a segregated Barclays client account protected by the `}
        <Tooltip
          title={
            <>
              {i18n('Home')`Financial Services`}
              <br />
              {i18n('Home')`Compensation Scheme`}
            </>
          }
        >
          {i18n('Home')`FSCS`}
        </Tooltip>
        {i18n('Home')` (up to £85,000)`}
      </span>
    ),
    Icon: Wallet,
  },
  {
    id: 'home.securityItems.doubleRosette',
    title: i18n('Home')`FCA`,
    color: Config.theme.colors.asparagus,
    description: i18n(
      'Home'
    )`British Pearl is authorised and regulated by the Financial Conduct Authority`,
    Icon: DoubleRosette,
  },
];

export { benefitsItems, securityItems };
