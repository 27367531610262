import {
  NOTICES_GET_ERROR,
  NOTICES_GET_REQUEST,
  NOTICES_GET_SUCCESS,
} from './actionTypes';
import { NoticesActions, NoticesState } from './types';

const initialState: NoticesState = {
  byId: {},
  allIds: {
    data: [],
    error: undefined,
    loading: false,
  },
};

export default (state = initialState, action: NoticesActions) => {
  switch (action.type) {
    case NOTICES_GET_REQUEST:
      return {
        ...state,
        allIds: {
          ...state.allIds,
          loading: true,
          error: undefined,
        },
      };
    case NOTICES_GET_SUCCESS:
      return {
        ...state,
        allIds: {
          data: action.payload.map(notice => notice.id),
          loading: false,
          error: undefined,
        },
        byId: Object.fromEntries(
          action.payload.map(notice => [
            notice.id,
            {
              data: notice,
              error: undefined,
              loading: false,
            },
          ])
        ),
      };
    case NOTICES_GET_ERROR:
      return {
        ...state,
        allIds: {
          ...state.allIds,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
