import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useStyles } from './H2.styles';

interface H2Props extends TypographyProps {
  fontWeight?: 'lighter' | 'normal' | 'bold';
}

export const H2: FC<H2Props> = ({
  children,
  fontWeight = 'bold',
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant="h2"
      className={clsx(className, {
        [classes.fontWeightBold]: fontWeight === 'bold',
        [classes.fontWeightNormal]: fontWeight === 'normal',
        [classes.fontWeightLighter]: fontWeight === 'lighter',
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};
