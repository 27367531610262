import React from 'react';

import Config from 'config';

const Person = ({ height, width, color, circleColor, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 95 95" width={width} {...props}>
    <circle cx="47.5" cy="47.5" r="47.5" fill={circleColor} />
    <path
      d="M71.2326 56.2107C68.6823 53.5457 51.5798 49.5483 48.8794 49.5483C46.129 49.5483 29.0265 53.5457 26.5261 56.2107C25.1259 57.6419 24.4258 66.5743 24.4258 70.5717H73.2829C73.2829 66.5743 72.5828 57.6419 71.2326 56.2107Z"
      fill={color}
    />
    <path
      d="M38.0268 38.3952C40.6272 44.4654 45.5779 47.7719 48.8784 47.7719C52.1289 47.7719 56.6796 44.5641 59.2799 38.5433C59.93 37.0628 60.4801 35.2368 60.8802 33.4108C61.3302 31.9303 61.5303 30.1043 61.5303 27.7355C61.5303 21.5667 55.8294 16.2861 48.8784 16.2861C41.8774 16.2861 36.2266 21.5667 36.2266 27.7848C36.2266 29.4134 36.3266 30.8446 36.5266 32.029C36.7766 34.2004 37.2767 36.6186 38.0268 38.3952Z"
      fill={color}
    />
  </svg>
);
Person.defaultProps = {
  circleColor: Config.theme.colors.bostonBlue,
  color: Config.theme.colors.white,
  height: 95,
  width: 95,
};

export default Person;
