import React from 'react';

import Config from 'config';

const Facebook = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 38 38" width={width} {...props}>
    <path
      d="M18.5363 0C8.29846 0 0 8.50606 0 19C0 29.4928 8.29846 38 18.5363 38C28.7741 38 37.0726 29.4928 37.0726 19C37.0726 8.50606 28.7741 0 18.5363 0Z"
      fill={fill}
      transform="translate(0.611328)"
    />
    <path
      d="M2.12936 21.1446H6.39154V10.5711H9.23455L9.61106 6.92787H6.39154L6.39618 5.10388C6.39618 4.15387 6.48422 3.64444 7.8142 3.64444H9.59137V0H6.74721C3.33074 0 2.12936 1.76819 2.12936 4.7405V6.92787H0V10.5723H2.12936V21.1446Z"
      fill="white"
      transform="translate(14.3418 8.42789)"
    />
  </svg>
);

Facebook.defaultProps = {
  fill: Config.theme.colors.black,
  height: 38,
  width: 38,
};

export default Facebook;
