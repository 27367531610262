import { takeEvery } from 'redux-saga/effects';
import * as type from './actionTypes';

// TODO: put these back in when Localisation is a priority.
const NO_OP = function* NoOp() {
  yield false;
};

export default function* root() {
  yield takeEvery(type.CHANGE_LOCALE, NO_OP);
  yield takeEvery(type.TRANSLATION_GROUP_REQUEST, NO_OP);
  yield takeEvery(type.TRANSLATION_GROUP_LOAD, NO_OP);
}
