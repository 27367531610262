import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import Config from 'config';

import media from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CellContent = styled.span`
  text-align: right;
  width: 100%;
`;

const Cell = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 1rem;

  &:first-child {
    position: relative;

    ${CellContent} {
      text-align: left;

      ${media.small.only`
        display: block;
        position: absolute;
        right: 1rem;
        text-align: right;
        white-space: nowrap;
        width: auto;
      `};
    }
  }
`;

const Header = styled(Cell)`
  ${media.medium`
    background-color: ${Config.theme.colors.springWood};
  `};
`;

const Row = styled.div`
  border-bottom: 1px solid ${Config.theme.colors.pampas};
  display: flex;
  flex-direction: row;

  ${media.small.only`
    flex: 1;
    flex-direction: column;

    ${Cell}:first-child {
      background-color: ${Config.theme.colors.antiqueWhite};
    }
  `};
`;

const HeaderRow = styled(Row)`
  font-weight: ${Config.theme.fontWeight.medium};

  ${CellContent} {
    text-align: right;

    ${media.small.only`
      text-align: left;
    `};
  }
`;

const SubHeader = styled(Cell)`
  font-weight: ${Config.theme.fontWeight.medium};
`;

const EmptyColumn = styled(Cell)`
  padding-bottom: 2rem;

  ${media.small.only`
    display: none;
  `};
`;

const Column = styled.div`
  &:not(:first-child) {
    ${HeaderRow} {
      display: none;

      ${media.small.only`
        display: flex;
      `};
    }
  }

  &:last-child {
    ${Row} {
      border-bottom: 0;
    }
  }

  ${media.small.only`
    display: flex;
  `};
`;

const Table = ({ header, rows, className }) => (
  <Wrapper className={className}>
    {rows.map(({ subHeader, row, emptyRow }) =>
      emptyRow ? (
        <EmptyColumn key={shortid.generate()} />
      ) : (
        <Column key={shortid.generate()}>
          <HeaderRow>
            {header &&
              header.map(cell => (
                <Header key={shortid.generate()}>
                  <CellContent>{cell}</CellContent>
                </Header>
              ))}
          </HeaderRow>

          <Row>
            {subHeader &&
              subHeader.map(cell => (
                <SubHeader key={shortid.generate()}>
                  <CellContent>{cell}</CellContent>
                </SubHeader>
              ))}

            {row &&
              row.map(cell => (
                <Cell key={shortid.generate()}>
                  <CellContent>{cell}</CellContent>
                </Cell>
              ))}
          </Row>
        </Column>
      )
    )}
  </Wrapper>
);

Table.defaultProps = {
  className: '',
  header: null,
};

export default Table;
