import React from 'react';

import Config from 'config';

const Withdraw = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 64 64" width={width} {...props}>
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke={fill2} d="M51.9568 7.2174V43.739h-18.261" />
      <path
        d="M31.079 32.6433c-3.7213 1.8483-4.556 7.1635-5.3283 8.8513-1.1752 2.5644-.8413 3.1278-2.169 3.1278-1.328 0-5.5384-2.5904-5.5384-2.5904v-6.2817l5.2174-6.3587V16.348L8.005 31.2243c-2.0908 2.349-3.0156 5.4574-3.005 8.602.0233 6.2623.0012 18.261 0 22.174h16.05s6.0155-2.8265 8.7325-6.5217c2.7182-3.6965 4.607-13.7608 5.6035-16.616 2.409-6.8988-.904-7.9096-4.307-6.2192z"
        stroke={fill}
      />
      <path
        d="M23.261 37.2174v-30m5.2175 10.4348v11.739m7.826 9.1305h6.5218c0-2.1613 1.7518-3.913 3.913-3.913V17.6522M45.8738 7.218c.5557.8518.866 1.8053.866 2.8096 0 3.6457-4.0877 6.441-9.1303 6.441-5.044 0-9.1305-2.7953-9.1305-6.441 0-1.0056.3105-1.9578.866-2.811m8.264 15.653v2.6087"
        stroke={fill2}
      />
      <path
        d="M19.348 11.1304h-2.6086V2h41.739v9.1304H55.87m-1.3045-3.913h-33.913"
        stroke={fill}
      />
    </g>
  </svg>
);

Withdraw.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default Withdraw;
