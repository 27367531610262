import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import { Img } from 'components/Atoms/Img';
import { useI18n } from 'utils/i18nConnect';

import { Pip } from 'services/Pip';
import { useStyles } from './PropertyCardImage.styles';
import { PropertyCardBanner } from './PropertyCardBanner';

interface PropertyCardImageProps {
  hideLoans?: boolean;
  title: string;
  imageSrc?: string;
  suspended: boolean;
  state: Pip.InvestmentState;
  upForSale: boolean;
}

export const PropertyCardImage: FC<PropertyCardImageProps> = ({
  title,
  imageSrc,
  hideLoans = false,
  state,
  suspended,
  upForSale,
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  return (
    <div className={classes.imageWrapper}>
      <Img src={imageSrc} className={classes.image} alt={title} />
      {!hideLoans && (
        <Box
          bgcolor="common.white"
          color="common.black"
          position="absolute"
          bottom={0}
          right={0}
          width="50%"
          textAlign="center"
          p={1}
          borderRadius="16px 0 0 0"
        >
          <Typography variant="body2">
            <Box component="span" fontWeight="bold">
              {i18n('PropertyCard')`ISA eligible`}
            </Box>
          </Typography>
        </Box>
      )}
      <Box position="absolute" top={0} width="100%">
        <PropertyCardBanner
          state={state}
          suspended={suspended}
          upForSale={upForSale}
        />
      </Box>
    </div>
  );
};
