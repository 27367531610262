import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Config from 'config';
import loadersTemplate from 'router/loaders';

export class LoadingLink extends React.Component {
  handleHover = async () => {
    const { to } = this.props;
    if (loadersTemplate[to]) {
      await loadersTemplate[to]();
    }
  };

  render() {
    const { to, activeClassName, isLight, block, ...navLinkProps } = this.props;

    return (
      <NavLink
        {...navLinkProps}
        activeClassName={activeClassName}
        onMouseEnter={this.handleHover}
        to={to}
      />
    );
  }
}

LoadingLink.propTypes = {
  activeClassName: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isLight: PropTypes.bool,
  target: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      key: PropTypes.string,
    }),
  ]).isRequired,
};

LoadingLink.defaultProps = {
  block: false,
  isLight: false,
  target: undefined,
  activeClassName: 'selected',
};

export const StyledLoadingLink = styled(LoadingLink)`
  color: ${Config.theme.colors.black};
  font-weight: ${Config.theme.fontWeight.medium};

  &:visited {
    color: ${Config.theme.colors.black};
  }

  &:focus {
    color: ${Config.theme.colors.highlight};
    outline: none;
  }
`;

export default StyledLoadingLink;
