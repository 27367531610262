import { call } from 'redux-saga/effects';

/*
 * If you need to be notified of the result of a saga action
 * wrap the dispatch in dispatchPromise and the relevant saga in
 * sagaPromise. The saga generator may return a boolean success
 * value, if no value is returned success is assumed.
 */

export const dispatchPromise = (dispatch, action) =>
  new Promise((resolve, reject) => {
    dispatch({
      ...action,
      resolve,
      reject,
    });
  });

export const sagaPromise = saga =>
  function*({ resolve, reject, ...action }, ...args) {
    const success = yield* saga(action, ...args);

    if (resolve) {
      if (success || success === undefined) {
        yield call(resolve);
      } else {
        yield call(reject);
      }
    }
  };
