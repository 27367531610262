import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Pip } from 'services/Pip';

import { getNoticesRequest } from '../actions';
import { selectNoticesListState } from '../selectors';

export type UseGetNoticesResult = [
  Pip.NoticeView[],
  boolean,
  Error | undefined
];

export const useGetNotices = (): UseGetNoticesResult => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(selectNoticesListState);

  useEffect(() => {
    dispatch(getNoticesRequest());
  }, [dispatch]);

  return [data, loading, error];
};
