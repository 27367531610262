import React from 'react';

import Config from 'config';

const Email = ({ height, width, color, circleColor, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 95 95" width={width} {...props}>
    <circle cx="47.5" cy="47.5" r="47.5" fill={circleColor} />
    <path
      d="M53.0314 54.3565L49.8795 56.8545C48.5399 57.8985 46.4124 57.8985 45.0729 56.8545L41.0148 53.6481L38.6509 51.7839L19.582 66.7721C20.37 67.3686 21.3944 67.7414 22.4975 67.7414H72.4943C73.5974 67.7414 74.6218 67.3686 75.4097 66.7721L56.3409 51.7466L53.0314 54.3565Z"
      fill={color}
    />
    <path
      d="M17.8125 35.3789V63.1555L35.463 49.2486L17.8125 35.3789Z"
      fill={color}
    />
    <path
      d="M72.4189 26.729H22.4221C20.0976 26.729 18.1671 28.3322 17.8125 30.4201L41.1364 48.7639L45.0369 51.8212C46.3764 52.8651 48.504 52.8651 49.8435 51.8212L53.941 48.6147L77.0679 30.4201C76.6345 28.3322 74.704 26.729 72.4189 26.729Z"
      fill={color}
    />
    <path
      d="M77.1876 63.1555V35.3789L59.5371 49.2486L77.1876 63.1555Z"
      fill={color}
    />
  </svg>
);

Email.defaultProps = {
  circleColor: Config.theme.colors.pomegranate,
  color: Config.theme.colors.white,
  height: 95,
  width: 95,
};

export default Email;
