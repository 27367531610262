import React from 'react';

import Config from 'config';

const Youtube = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 28 28" width={width} {...props}>
    <rect
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
      height="28"
      transform="translate(0.515625)"
      width="27.4848"
    />
    <rect
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
      height="28"
      transform="translate(0.515625)"
      width="27.4848"
    />
    <rect
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
      height="28"
      transform="translate(0.515625)"
      width="27.4848"
    />
    <path
      d="M14.258 0C6.66794 0 0.515625 6.26762 0.515625 14C0.515625 21.7315 6.66794 28 14.258 28C21.8482 28 28.0005 21.7315 28.0005 14C28.0005 6.26762 21.8482 0 14.258 0Z"
      fill={Config.theme.colors.black}
    />
    <rect
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
      height="16.9662"
      transform="translate(7.52344 5.52124)"
      width="13.4701"
    />
    <path
      d="M14.2555 13.3062C14.2555 13.3053 19.6932 13.3141 20.3382 13.9712C20.9859 14.6292 20.9936 17.8754 20.9936 17.8938C20.9936 17.8938 20.9884 21.1576 20.3382 21.8173C19.6924 22.4727 14.2555 22.4876 14.2555 22.4876C14.2555 22.4876 8.81866 22.4736 8.17191 21.8173C7.5243 21.1593 7.52344 17.9183 7.52344 17.8938C7.52344 17.8754 7.52515 14.6292 8.17191 13.9712C8.81866 13.3149 14.2555 13.3062 14.2555 13.3062Z"
      fill={Config.theme.colors.white}
    />
    <path
      d="M19.8326 18.6636V17.6258C19.8326 17.2399 19.7364 16.9442 19.544 16.7386C19.3507 16.5329 19.0741 16.4297 18.7143 16.4297C18.3621 16.4297 18.0761 16.5408 17.8536 16.7631C17.632 16.9853 17.5195 17.2741 17.5195 17.6258V19.4537C17.5195 19.8466 17.6226 20.1546 17.8236 20.3768C18.0254 20.6008 18.3037 20.7119 18.655 20.7119C19.0458 20.7119 19.3404 20.6069 19.5362 20.3969C19.7346 20.1843 19.8317 19.8702 19.8317 19.4528V19.2437H19.0243V19.4292C19.0243 19.6698 18.9968 19.8238 18.9453 19.8938C18.8938 19.9638 18.8027 19.9988 18.6722 19.9988C18.5468 19.9988 18.4592 19.9586 18.4059 19.8754C18.3552 19.7923 18.3295 19.6436 18.3295 19.4292V18.6636H19.8326ZM18.3295 18.0283V17.6171C18.3295 17.4464 18.3561 17.3239 18.4102 17.2522C18.4652 17.1761 18.5554 17.1402 18.6799 17.1402C18.7993 17.1402 18.8878 17.1761 18.9402 17.2522C18.9951 17.3239 19.0235 17.4464 19.0235 17.6171V18.0283H18.3295Z"
      fill="#333333"
    />
    <path
      d="M16.9118 16.7399C16.7813 16.5675 16.5915 16.4809 16.3424 16.4809C16.2204 16.4809 16.1002 16.5124 15.9799 16.578C15.8614 16.6419 15.7497 16.7372 15.6432 16.8606V15.0835H14.8496V20.6012H15.6432V20.2889C15.7454 20.4149 15.8588 20.505 15.9791 20.5645C16.0985 20.624 16.235 20.6529 16.3896 20.6529C16.6224 20.6529 16.8019 20.5776 16.9239 20.4227C17.0475 20.2687 17.1085 20.0482 17.1085 19.7604V17.5011C17.1085 17.1677 17.0441 16.9131 16.9118 16.7399ZM16.3003 19.6475C16.3003 19.7787 16.2762 19.8706 16.2316 19.9275C16.1869 19.9861 16.1165 20.0141 16.0203 20.0141C15.9533 20.0141 15.8915 19.9992 15.8305 19.9704C15.7695 19.9424 15.7068 19.8916 15.6432 19.8269V17.2902C15.6965 17.2342 15.7506 17.1922 15.8056 17.166C15.8597 17.1389 15.9155 17.1266 15.9722 17.1266C16.0778 17.1266 16.1603 17.1616 16.217 17.2325C16.272 17.3034 16.3003 17.4084 16.3003 17.5449V19.6475Z"
      fill="#333333"
    />
    <path
      d="M13.474 20.6002V20.1531C13.328 20.3184 13.1777 20.4444 13.0197 20.5302C12.8625 20.6177 12.7105 20.6597 12.5636 20.6597C12.3815 20.6597 12.2467 20.6002 12.153 20.4777C12.0629 20.3561 12.0156 20.1758 12.0156 19.9326V16.5288H12.8007V19.6508C12.8007 19.7479 12.817 19.8171 12.8488 19.8608C12.8831 19.9046 12.9372 19.9256 13.0102 19.9256C13.0678 19.9256 13.1416 19.8976 13.2301 19.8389C13.3186 19.7812 13.3993 19.7094 13.474 19.6211V16.5288H14.2582V20.6002H13.474Z"
      fill="#333333"
    />
    <path
      d="M10.7551 15.885V20.6004H9.86871V15.885H8.95312V15.0835H11.6707V15.885H10.7551Z"
      fill="#333333"
    />
    <path
      d="M18.6777 11.5989H17.7931V11.1036C17.6316 11.2856 17.4615 11.4256 17.2846 11.5201C17.1085 11.6155 16.9376 11.6636 16.7718 11.6636C16.5657 11.6636 16.4128 11.5963 16.3098 11.4633C16.2067 11.3294 16.1543 11.1299 16.1543 10.8621V7.1145H17.0372V10.5524C17.0372 10.6583 17.0579 10.7353 17.0948 10.7825C17.1309 10.8306 17.1918 10.8543 17.2743 10.8543C17.3387 10.8543 17.4212 10.8228 17.5208 10.7598C17.6196 10.6968 17.7115 10.6163 17.7931 10.5183V7.1145H18.6777V11.5989Z"
      fill={Config.theme.colors.white}
    />
    <path
      d="M15.0868 7.32711C14.8523 7.11011 14.5517 7.00073 14.1858 7.00073C13.7847 7.00073 13.4635 7.10398 13.2264 7.30961C12.9877 7.51523 12.8683 7.79173 12.8691 8.13998V10.464C12.8691 10.8455 12.986 11.1482 13.217 11.374C13.4498 11.5989 13.7598 11.7117 14.1463 11.7117C14.5492 11.7117 14.8635 11.6024 15.0946 11.3845C15.323 11.1657 15.4373 10.8656 15.4373 10.4824V8.16536C15.4364 7.82411 15.3196 7.54411 15.0868 7.32711ZM14.5354 10.5497C14.5354 10.6687 14.5002 10.7632 14.4298 10.8332C14.3585 10.9015 14.264 10.9356 14.1446 10.9356C14.0269 10.9356 13.9368 10.9024 13.8749 10.835C13.8122 10.7667 13.7796 10.6722 13.7796 10.5497V8.10673C13.7796 8.00873 13.8139 7.92911 13.8809 7.86873C13.9488 7.80836 14.0364 7.77861 14.1446 7.77861C14.2606 7.77861 14.355 7.80836 14.4272 7.86873C14.4993 7.92911 14.5354 8.00873 14.5354 8.10673V10.5497Z"
      fill={Config.theme.colors.white}
    />
    <path
      d="M10.4602 5.52124H9.45703L10.6423 9.19099V11.599H11.6386V9.07724L12.7973 5.52124H11.7847L11.1697 7.95024H11.107L10.4602 5.52124Z"
      fill={Config.theme.colors.white}
    />
  </svg>
);

Youtube.defaultProps = {
  fill: 'currentColor',
  height: 28,
  width: 28,
};

export default Youtube;
