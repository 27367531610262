import { Property, Variant } from './types';

export const getPropertiesByVariant = (
  properties: Property[],
  variant: Variant
): Property[] => {
  switch (variant) {
    case 'loans':
      return properties.filter(({ hasLoans }) => hasLoans);
    case 'shares':
      return properties.filter(({ hasShares }) => hasShares);
    default:
      return [];
  }
};
