import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  StaticRouter,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { trackPageView } from 'utils/analytics';
import { scrollTo } from 'utils/forms';
// eslint-disable-next-line import/no-named-as-default
import App from 'containers/App';
import Home from 'containers/Home';
import ErrorNotFound from 'containers/ErrorNotFound';

import { routeChange } from 'store/Router/actions';

import { RoutedModal } from 'components/Organisms/RoutedModal';
import { PropertyPageFaqDialog } from 'domains/Property/PropertyPageFaqDialog';
import { LayeredSwitch } from './components/LayeredSwitch';
import Loader from './Loader';
import LoginRoutes from './Login';
import RegisterRoutes from './Register';
import InvestmentRoutes from './Investment';
import { OnboardingRoutes } from './OnboardingRoutes';
import InvestorRoutes from './Investor';
import ContentRoutes from './Content';
import MarketingRoutes from './Marketing';
import { ReaccreditationRoutes } from './ReaccreditationRoutes';
import {
  blogPaths,
  commonPaths,
  /* referFriendPaths, */
  glossaryPaths,
  investorPaths,
  loginPaths,
  onboardingPaths,
  registerPaths,
  propertyPaths,
  investmentPaths,
} from './paths';
/*
import { ReferFriendRoutes } from './ReferFriendRoutes';
*/

const shouldScrollToTop = (from, to) => {
  if (from.pathname === to.pathname) {
    return false;
  }

  return !(to.state && to.state.fromTab);
};

const RouteWatcher = withRouter(
  connect()(
    class extends React.Component {
      componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const { dispatch, history, location } = this.props;
        dispatch(routeChange(location));
        history.listen(toLocation => {
          // eslint-disable-next-line react/prop-types
          const { location: fromLocation } = this.props;

          if (shouldScrollToTop(fromLocation, toLocation)) {
            scrollTo(0, 0);
          }
          dispatch(routeChange(toLocation));

          if (window && window.trackingInitialised) {
            trackPageView();
          }
        });
      }

      render() {
        // eslint-disable-next-line react/prop-types
        const { children } = this.props;
        return children;
      }
    }
  )
);

const AppWithRouter = withRouter(App);

const Router = window ? BrowserRouter : StaticRouter;

// Default to an empty context to fix StaticRouter lacks context warning
// eslint-disable-next-line react/prop-types
const Routes = ({ context, ...otherProps }) => (
  <Router context={context || {}} {...otherProps}>
    <RouteWatcher>
      <AppWithRouter>
        <LayeredSwitch layer={0}>
          <Route exact path={commonPaths.home()} component={Home} />
          <Route
            exact
            path={propertyPaths.propertiesAll()}
            component={Loader}
          />
          <Route
            exact
            path={propertyPaths.propertyFilter()}
            component={Loader}
          />
          <Route
            path={investmentPaths.investmentAll()}
            component={InvestmentRoutes}
          />
          <Route exact path={commonPaths.howItWorks()} component={Loader} />
          <Route exact path={commonPaths.isa()} component={Loader} />
          <Route exact path={commonPaths.aboutUs()} component={Loader} />
          <Redirect
            exact
            from={glossaryPaths.glossary()}
            to={glossaryPaths.glossaryByLetter({ letter: 'all' })}
          />
          <Redirect
            exact
            from={commonPaths.support()}
            to={commonPaths.contact()}
          />
          <Redirect
            exact
            from={glossaryPaths.glossary()}
            to={glossaryPaths.glossaryByLetter()}
          />
          <Route path={commonPaths.support()} component={Loader} />
          <Route path={commonPaths.faq()} component={Loader} />
          <Route path={commonPaths.keyRisks()} component={Loader} />
          <Route path={glossaryPaths.glossaryByLetter()} component={Loader} />
          <Route path={commonPaths.contact()} component={Loader} />
          <Route exact path={commonPaths.codeOfConduct()} component={Loader} />
          <Route exact path={commonPaths.complaints()} component={Loader} />
          <Route exact path={commonPaths.cookiedPolicy()} component={Loader} />
          <Route exact path={commonPaths.privacyNotice()} component={Loader} />
          <Route exact path={commonPaths.termsOfUse()} component={Loader} />
          <Route path={loginPaths.login()} component={LoginRoutes} />
          <Route exact path={loginPaths.logout()} component={Loader} />
          <Route exact path={loginPaths.loggedOut()} component={Loader} />
          <Route path={registerPaths.register()} component={RegisterRoutes} />
          <Route
            path={onboardingPaths.onboarding()}
            component={OnboardingRoutes}
          />
          <Route
            path={onboardingPaths.reaccreditation()}
            component={ReaccreditationRoutes}
          />
          <Route path={investorPaths.investor()} component={InvestorRoutes} />
          <Route path={blogPaths.blogAll()} component={ContentRoutes} />
          <Route path={commonPaths.lovemoney()} component={RegisterRoutes} />
          <Route path={commonPaths.expatnetwork()} component={RegisterRoutes} />
          <Route
            path={commonPaths.propertyforum()}
            component={RegisterRoutes}
          />
          <Route path={commonPaths.rightmove()} component={RegisterRoutes} />
          <Route path={commonPaths.express()} component={RegisterRoutes} />
          <Route
            exact
            path={commonPaths.landingIsaop()}
            component={MarketingRoutes}
          />
          <Route
            exact
            path={commonPaths.landingLoans()}
            component={MarketingRoutes}
          />
          <Route
            exact
            path={commonPaths.landingIsatrns()}
            component={MarketingRoutes}
          />
          <Route
            exact
            path={commonPaths.landingShares()}
            component={MarketingRoutes}
          />
          <Route
            exact
            path={commonPaths.isaCashback()}
            component={MarketingRoutes}
          />
          <Route
            exact
            path={commonPaths.sharesCashback()}
            component={MarketingRoutes}
          />
          <Route
            exact
            path={commonPaths.loansCashback()}
            component={MarketingRoutes}
          />
          <Route exact path={commonPaths.shares()} component={Loader} />
          <Route exact path={commonPaths.loans()} component={Loader} />
          {/*
          <Route path={referFriendPaths.root()} component={ReferFriendRoutes} />
          */}
          <Route path={commonPaths.notFound()} component={ErrorNotFound} />
          <Route path="*" component={Loader} />
        </LayeredSwitch>
        <LayeredSwitch layer={1} component={RoutedModal}>
          <Route
            exact
            path={propertyPaths.faq()}
            component={PropertyPageFaqDialog}
          />
        </LayeredSwitch>
      </AppWithRouter>
    </RouteWatcher>
  </Router>
);

export default Routes;
