import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Img } from 'components/Atoms/Img';
import { H2 } from 'components/Atoms/Heading';
import { Section } from 'components/Molecules/Section';
import { i18nConnect } from 'utils/i18nConnect';

import { useStyles } from './Benefits.styles';

import { benefitsItems } from '../data';

const Benefits = ({ heading, i18n }) => {
  const classes = useStyles();
  return (
    <Section size="large">
      <Box textAlign="center" mb={8}>
        <H2>{heading}</H2>
      </Box>
      <Grid container spacing={4}>
        {benefitsItems(i18n).map(({ id, Image, title, description }) => (
          <Grid key={id} item xs={12} md={6} lg={4}>
            <Box
              bgcolor="grey.200"
              height="100%"
              borderRadius={16}
              overflow="hidden"
            >
              <Img src={Image} alt="" forceIndex={2} className={classes.img} />
              <Box p={4}>
                <Typography variant="h4" paragraph>
                  {title}
                </Typography>
                <Typography variant="body2">{description}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

Benefits.propTypes = {
  heading: PropTypes.string.isRequired,
  i18n: PropTypes.func.isRequired,
};

export default i18nConnect(Benefits);
