import React from 'react';

import Config from 'config';

const HouseMagnify = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 48 48" width={width} {...props}>
    <g fill={fill}>
      <path d="M21.772 40.0426v-10.064c0-.5522-.4477-1-1-1h-6.8085c-.5523 0-1 .4478-1 1v10.064H9.006V22.319h-2v18.7236c0 .5522.4478 1 1 1h5.9575c.5523 0 1-.4478 1-1v-10.064h4.8085v10.064c0 .5522.4477 1 1 1H35.24c.5524 0 1-.4478 1-1v-5.1064h-2v4.1064H21.772z" />
      <path d="M31.1287 16.2177l1.4142-1.4142-8.511-8.5105c-.3905-.3907-1.0237-.3907-1.4142 0L3.8948 25.016l1.4142 1.4142 18.016-18.016 7.8035 7.8035z" />
      <path d="M14.6656 8.851v5.8086h2V7.851c0-.5522-.4477-1-1-1h-4.2553c-.5523 0-1 .4478-1 1v11.064h2V8.851h2.2553zm24.681 17.298c0-3.9133-3.172-7.0852-7.085-7.0852-3.9135 0-7.0853 3.172-7.0853 7.085 0 3.9134 3.1718 7.0852 7.085 7.0852 3.9133 0 7.0852-3.1718 7.0852-7.085zm2 0c0 5.0178-4.0674 9.085-9.085 9.085-5.018 0-9.0853-4.0672-9.0853-9.085 0-5.018 4.0672-9.0852 9.085-9.0852 5.018 0 9.0852 4.0673 9.0852 9.085z" />
      <path d="M36.235 33.239l6.8086 6.8085 1.4142-1.4142-6.8085-6.8085" />
    </g>
  </svg>
);

HouseMagnify.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 48,
  width: 48,
};

export default HouseMagnify;
