import { fromJS } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';

import { Img } from 'components/Atoms/Img';
import {
  Subtitle as PropertySubtitle,
  Title as PropertyTitle,
} from 'components/Atoms/Property';
import Config from 'config';
import { i18nConnect } from 'utils/i18nConnect';
import { investmentPaths } from 'router/paths';
import { ButtonLink } from 'components/Atoms/Button';
import { ThemeProvider } from 'components/Molecules/ThemeProvider';

import { isInvestmentStateAllowingToInvest } from '../../Pages/Investment/utils';

const StyledImg = styled(Img)`
  background-color: ${Config.theme.colors.antiqueWhite};
  border-radius: 50%;
  height: 5rem;
  min-width: 5rem;
  width: 5rem;
`;

const Title = styled(PropertyTitle)`
  font-size: 1.5rem;
  line-height: 1;
`;

const Subtitle = styled(PropertySubtitle)`
  font-size: 1rem;
  line-height: 1;
`;

const Details = ({ item, i18n }) => {
  const itemImages =
    item.getIn(['property', 'cover', 'resizedImages']) || fromJS([]);
  const images = Object.assign({}, ...itemImages.toJS());
  const size = Config.imagesize;
  const hasPosition = item.get('shareSummary') || item.get('loanSummary');
  const isResale = item.get('investmentState') === 'OnSecondaryMarket';
  return (
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs="auto">
              <StyledImg
                sizes="(max-width: 27.184999rem) 80vw, (min-width: 27.185rem) and (max-width: 47.9375rem) 20rem, (max-width: 1024px) 40vw, (max-width: 1280px) 25vw, (min-width: 1281px) 20rem"
                src={{
                  src: images[size.medium],
                  images: [{ path: images[size.medium] }],
                  srcSet: `${images[size.small]} ${parseInt(
                    size.small,
                    10
                  )}w, ${images[size.medium]} ${parseInt(size.medium, 10)}w`,
                }}
                alt={item.get('investmentName')}
                title={item.get('investmentName')}
              />
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid item xs={12}>
                  <Title content={item.get('investmentName')} />
                </Grid>
                <Grid item xs={12}>
                  <Subtitle investment={item} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isInvestmentStateAllowingToInvest(item.get('investmentState')) &&
          !item.get('investmentSuspended') && (
            <Grid item xs={12} sm="auto">
              {isResale ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm>
                    <ThemeProvider themeName="buy">
                      <ButtonLink
                        fullWidth
                        variant="contained"
                        color="primary"
                        to={investmentPaths.investmentById({
                          investmentId: item.get('investmentId'),
                        })}
                      >
                        {i18n('InvestmentDetails')`Buy`}
                      </ButtonLink>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={12} sm>
                    <ThemeProvider themeName="sell">
                      <ButtonLink
                        fullWidth
                        variant="contained"
                        color="primary"
                        to={investmentPaths.investmentById({
                          investmentId: item.get('investmentId'),
                        })}
                        disabled={!hasPosition}
                      >
                        {i18n('InvestmentDetails')`Sell`}
                      </ButtonLink>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              ) : (
                <ButtonLink
                  fullWidth
                  variant="contained"
                  color="primary"
                  to={investmentPaths.investmentById({
                    investmentId: item.get('investmentId'),
                  })}
                >
                  {i18n('InvestmentDetails')`Invest more`}
                </ButtonLink>
              )}
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default i18nConnect(Details);
