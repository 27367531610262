import React, { FC } from 'react';
import { Box, Divider, Hidden } from '@material-ui/core';

import { SessionAccounts, SessionUser } from 'store/SessionProvider';

import { MenuNavLinkItem } from '../../types';

import { Navigation } from '../Navigation';
import { LoginJoinLinks } from '../LoginJoinLinks';
import { LoggedInMenu } from '../LoggedInMenu';

interface DesktopNavigationProps {
  accounts?: SessionAccounts;
  user?: SessionUser;
  navLinks: MenuNavLinkItem[];
  loading?: boolean;
}

export const DesktopNavigation: FC<DesktopNavigationProps> = ({
  navLinks,
  user,
  loading,
  accounts,
}) => {
  return (
    <Hidden implementation="css" mdDown>
      <Box display="flex">
        <Navigation navLinks={navLinks} />
        <Divider orientation="vertical" flexItem light />
        <Box pl={3}>
          {user ? (
            <LoggedInMenu user={user} loading={loading} accounts={accounts} />
          ) : (
            <LoginJoinLinks loggedIn={false} />
          )}
        </Box>
      </Box>
    </Hidden>
  );
};
