import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Config from 'config';
import {
  TXT_LABEL_HIDDEN_PB,
  TXT_LABEL_HIDDEN_PT,
  TXT_PB,
  TXT_PT,
} from './constants';

export const useInputStyles = makeStyles(({ spacing, transitions }: Theme) => ({
  root: {
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    transition: transitions.create(['border-color'], {
      duration: transitions.duration.short,
    }),
  },
  input: {
    padding: spacing(TXT_PT, 2, TXT_PB),
    borderRadius: 8,
  },
  inputHiddenLabel: {
    padding: spacing(TXT_LABEL_HIDDEN_PT, 2, TXT_LABEL_HIDDEN_PB),
  },
  inputAdornedStart: {
    paddingLeft: spacing(4),
  },
  adornedStart: {
    paddingLeft: 0,
  },
}));

export const useInputColorStyles = makeStyles(({ palette }: Theme) => ({
  colorPrimary: {
    borderColor: palette.primary.main,
    backgroundColor: palette.common.white,

    '&:hover': {
      borderColor: palette.primary.main,
      backgroundColor: palette.common.white,
    },
    '&:focus': {
      borderColor: palette.primary.main,
      backgroundColor: palette.common.white,
    },
    '&$focused': {
      borderColor: palette.primary.main,
      backgroundColor: palette.common.white,
    },
    '&$error': {
      borderColor: palette.error.main,
      backgroundColor: palette.common.white,
    },
    '&$disabled': {
      borderColor: palette.action.disabled,
      backgroundColor: palette.common.white,
    },
  },
  colorSecondary: {
    borderColor: palette.secondary.main,
    backgroundColor: palette.common.white,

    '&:hover': {
      borderColor: palette.secondary.main,
      backgroundColor: palette.common.white,
    },
    '&:focus': {
      borderColor: palette.secondary.main,
      backgroundColor: palette.common.white,
    },
    '&$focused': {
      borderColor: palette.secondary.main,
      backgroundColor: palette.common.white,
    },
    '&$error': {
      borderColor: palette.error.main,
      backgroundColor: palette.common.white,
    },
    '&$disabled': {
      borderColor: palette.action.disabled,
      backgroundColor: palette.common.white,
    },
  },
  colorPurple: {
    borderColor: Config.theme.colors.wildBlueYonder,
    backgroundColor: palette.common.white,

    '&:hover': {
      borderColor: Config.theme.colors.wildBlueYonder,
      backgroundColor: palette.common.white,
    },

    '&:focus': {
      borderColor: Config.theme.colors.wildBlueYonder,
      backgroundColor: palette.common.white,
    },
    '&$focused': {
      borderColor: Config.theme.colors.wildBlueYonder,
      backgroundColor: palette.common.white,
    },
    '&$error': {
      borderColor: palette.error.main,
      backgroundColor: palette.common.white,
    },
    '&$disabled': {
      borderColor: palette.action.disabled,
      backgroundColor: palette.common.white,
    },
  },
  colorGreen: {
    borderColor: Config.theme.colors.shares,
    backgroundColor: palette.common.white,

    '&:hover': {
      borderColor: Config.theme.colors.shares,
      backgroundColor: palette.common.white,
    },

    '&:focus': {
      borderColor: Config.theme.colors.shares,
      backgroundColor: palette.common.white,
    },
    '&$focused': {
      borderColor: Config.theme.colors.shares,
      backgroundColor: palette.common.white,
    },
    '&$error': {
      borderColor: palette.error.main,
      backgroundColor: palette.common.white,
    },
    '&$disabled': {
      borderColor: palette.action.disabled,
      backgroundColor: palette.common.white,
    },
  },
  focused: {
    backgroundColor: palette.common.white,
  },
  error: {
    borderColor: palette.error.main,
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

export const useSelectStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    background: 'none',
    '&:focus': {
      background: 'none',
    },
  },
  icon: {
    right: spacing(1.5),
  },
}));

export const useHelperTextStyles = makeStyles(
  ({ spacing, palette }: Theme) => ({
    root: {
      position: 'absolute',
      width: '100%',
      bottom: spacing(0.5),
      margin: 0,
      padding: '0 16px',
      textAlign: 'right',

      // TODO: most error messages are short but when they are not.. need new designs
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      pointerEvents: 'none',
    },
    hiddenLabelRoot: {
      bottom: spacing(-2),
      '&$error': {
        color: palette.common.white,
      },
    },
    /* Pseudo-class applied to the root element if `error={true}`. */
    error: {},
  })
);

export const useLabelStyles = makeStyles(({ palette }: Theme) => ({
  error: {
    // TODO: props not being passed from InputLabel to FormLabel
    color: `${palette.error.main} !important`,
    '&$shrink': {
      color: `${palette.error.main} !important`,
    },
  },
  shrink: {
    // TODO: props not being passed from InputLabel to FormLabel
    color: `${palette.text.secondary} !important`,
    transform: `translate(16px, 28px) scale(1)`,
  },

  filled: {
    transform: `translate(16px, 28px) scale(1)`,
    '&$shrink': {
      transform: 'translate(16px, 12px) scale(0.75)',
    },
  },
}));
