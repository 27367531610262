import React from 'react';

import styled from 'styled-components';

import { Chevron } from 'components/Atoms/Icons';

import Config from 'config';

import { i18nConnect } from 'utils/i18nConnect';
import media from 'utils/responsive';

const Wrapper = styled.div`
  border-radius: 1.125rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: hidden;
  text-align: center;
  width: 100%;

  ${media.wide`
    width: 100%;
    margin-right: 0;
  `};
`;

const TableHead = styled.div`
  background: ${({ shares }) =>
    shares ? Config.theme.colors.shares : Config.theme.colors.loans};
`;

const Title = styled.div`
  color: ${Config.theme.colors.white};
  cursor: ${({ shouldRenderCells }) =>
    shouldRenderCells ? 'inherit' : 'pointer'};
  font-weight: ${Config.theme.fontWeight.medium};
  padding: ${({ shouldRenderCells }) =>
    shouldRenderCells ? '.5rem 0' : '.25rem 0'};
  text-align: center;
`;

const Heading = styled.div`
  background-color: ${Config.theme.colors.white};
  color: ${Config.theme.colors.black};
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
`;

const Body = styled.div`
  color: ${({ redColor }) =>
    redColor ? Config.theme.colors.offer : Config.theme.colors.bid};
  flex-direction: column;
  position: relative;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  justify-content: center;
  line-height: 1.4rem;
  padding: 1rem 0;
  width: calc(66.333% / 2);

  &:first-child {
    margin-left: 33.333%;
  }

  &:last-child {
    border-left: 1px solid ${Config.theme.colors.white};
  }

  ${media.medium`
    margin-left: initial;
    font-size: 1rem;
    width: 50%;
  `};
`;

const Bold = styled.span`
  font-weight: 600;
  margin-right: 0.2rem;
`;

const BoldFirstWord = str => {
  if (str) {
    const [first, ...rest] = str.split(' ');
    return (
      <>
        <Bold>{first}</Bold>

        <span>{rest.join(' ')}</span>
      </>
    );
  }
  return null;
};

const StyledChevron = styled(({ open, ...props }) => <Chevron {...props} />)`
  color: ${Config.theme.colors.white};
  height: 1.5rem;
  position: relative;
  top: 0.45rem;
  transform: rotate(90deg);
  width: 1.5rem;
`;

const Table = ({
  i18n,
  title,
  unit,
  amount,
  shares,
  handleToggle,
  children,
  shouldRenderCells,
}) => (
  <Wrapper>
    <TableHead shares={shares} shouldRenderCells={shouldRenderCells}>
      <Title
        onClick={!shouldRenderCells ? handleToggle : () => {}}
        shouldRenderCells={shouldRenderCells}
      >
        {i18n('Investment')``} {title}
        {!shouldRenderCells && <StyledChevron />}
      </Title>

      {shouldRenderCells && (
        <Heading>
          <Item>
            {i18n('Investment')``} {BoldFirstWord(unit)}
          </Item>

          <Item>
            {i18n('Investment')``} {BoldFirstWord(amount)}
          </Item>
        </Heading>
      )}
    </TableHead>

    <Body>{children}</Body>
  </Wrapper>
);

export default i18nConnect(Table);
