import { isPlainObject } from 'lodash';

import api from '../endpoints/Users';

export default class User {
  /**
   * Get detail info about given user (in fact only currently logged in works)
   *
   * @param id User id to get details, could be "me" as "myself"
   */
  static get(id = 'me') {
    return api.users
      .get(id)
      .then(user => {
        if (isPlainObject(user)) {
          return user;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Register the user
   *
   * @param user User to create
   * @param token ReCaptcha token
   */
  static create(user, token) {
    return api.users
      .create(user, token)
      .then(createdUser => {
        if (isPlainObject(createdUser)) {
          return createdUser;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Update user (replace selected user fields with given ones)
   *
   * @param user The user details to change
   */
  static edit(user) {
    return api.modifyUser.edit(user);
  }

  /**
   * Confirm registration, returns an email address of user
   *
   * @param token Token value from registration mail
   */
  static confirmEmail(token) {
    return api.confirmEmail.confirm(token);
  }

  /**
   * Resend verification email, always returns 204 no content
   *
   * @param email Email address to resend verification email to
   */
  static resendVerificationEmail(email, captchaToken) {
    return api.resendVerificationEmail.resend(email, captchaToken);
  }

  /**
   * Certification process
   *
   * @param classificationMethod
   * @param esignature
   * @param [quizVersion]
   */
  static classifyMe(classificationMethod, esignature, quizVersion) {
    return api.classification.classify(
      classificationMethod,
      esignature,
      quizVersion
    );
  }

  /**
   * Check quiz status
   */
  static quizStatus() {
    return api.quizStatus.get();
  }

  /**
   * Quiz failed action
   */
  static quizAttempt(classificationMethod, version) {
    return api.quizAttempt
      .submit({ classificationMethod, version })
      .then(response => {
        if (isPlainObject(response)) {
          return response;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Verify the user
   *
   * @param user User to verify
   */
  static verifyMe(user) {
    return api.verification.verify(user);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  static forgotPasswordReset(email) {
    return api.forgotPassword.token({ email });
  }

  /**
   * Change password
   *
   * @param data
   */
  static forgotPasswordChange(data) {
    return api.forgotPassword.change(data);
  }

  /**
   * Subscribe to newsletter
   */
  static newsletterSubscribe(email) {
    return api.newsletter.subscribe(email);
  }

  /**
   * Send details to SalesForce WebToLead service.
   */
  static webLeadSubscribe(data) {
    return api.webLead.subscribe(data);
  }

  /**
   * Fetch documents uploaded for manual verification.
   */
  static getVerificationDocuments() {
    return api.verificationDocuments.get();
  }

  /**
   * Upload document for manual verification.
   */
  static uploadVerificationDocument(file) {
    return api.verificationDocuments.upload(file);
  }

  /**
   * Submit user for manual verification.
   */
  static manualVerification(user) {
    return api.manualVerification.verify(user);
  }
}
