import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import { Link } from 'components/Atoms/Link';
import { Section } from 'components/Molecules/Section';
import { commonPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

export const FooterCapitalAtRisk: FC = () => {
  const t = useI18n();
  return (
    <Section size="medium">
      <Typography variant="body1">
        <Box fontWeight="bold" component="span">
          {t(
            'footer.FooterCapitalAtRisk.text.legal1'
          )`Your capital is at risk. `}
        </Box>
        {t(
          'footer.FooterCapitalAtRisk.text.legal2'
        )`The value of your investment can go down as well as up and you may get back less than you invest. Past performance is not a reliable guide to future performance. Your investments are not protected by the Financial Services Compensation scheme (FSCS). Forecasts are not guarantees and performance may vary. Our Resale Market opens only when investments are fully sold. Exiting early, when you want and at the price you want is not guaranteed. Risks include the total loss of your share investment or loan. Tax treatment depends on individual circumstances and may be subject to change in the future. If you are not sure about investing, seek independent, professional advice. Before investing, read our `}
        <Link to={commonPaths.keyRisks()}>
          {t('footer.FooterCapitalAtRisk.text.legal3')`Key risks`}
        </Link>
        {t('footer.FooterCapitalAtRisk.text.legal4')`.`}
      </Typography>
    </Section>
  );
};
