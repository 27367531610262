import React from 'react';
import { useSelector } from 'react-redux';

import { Generic as Template } from 'components/Templates';
import { MarketingLanding } from 'components/Pages/MarketingLanding';
import Config from 'config';
import { makeSelectUser } from 'store/SessionProvider/selectors';

import components, { templateProps } from './layout';

export const SharesPage = () => {
  const user = useSelector(makeSelectUser());
  return (
    <Template
      description={templateProps.description}
      title={templateProps.title}
      navLinks={templateProps.navLinks}
      canonicalLink={Config.canonicalLinks.sharesLandingPage}
    >
      <MarketingLanding components={components} loggedIn={!!user} />
    </Template>
  );
};
