import { isPlainObject } from 'lodash';

import untypedApi from '../endpoints/Investments';
import {
  InvestmentAndComingSoonInvestmentView,
  InvestmentWithProperty,
} from '../types';

interface Api {
  investment: {
    get: (id: number) => Promise<InvestmentWithProperty>;
  };
  investments: {
    get: () => Promise<InvestmentAndComingSoonInvestmentView>;
  };
  // TODO to be removed once migrated to V2
  investmentsV1: {
    get: (id: string) => Promise<unknown>;
  };
  // TODO type properly methods below
  sharesOrders: {
    create: (orderDetails: unknown) => Promise<unknown>;
  };
  loansOrders: {
    create: (orderDetails: unknown) => Promise<unknown>;
  };
  marketPlace: {
    get: (id: string, loggedIn: boolean) => Promise<unknown>;
  };
  orderStatus: {
    get: (assetType: string, uoid: string) => Promise<unknown>;
  };
  cancelSharesOrder: {
    cancel: (orderId: string) => Promise<unknown>;
  };
  cancelLoansOrder: {
    cancel: (orderId: string) => Promise<unknown>;
  };
}

const api = (untypedApi as unknown) as Api;

export default class Investment {
  static get(id: number): Promise<InvestmentWithProperty> {
    return api.investment.get(id);
  }

  static findAll(): Promise<InvestmentAndComingSoonInvestmentView> {
    return api.investments.get();
  }

  /**
   * Get detail about given investment
   * @deprecated use V2
   */
  static getV1(id: string) {
    if (!id) {
      return Promise.reject();
    }

    return api.investmentsV1
      .get(id)
      .then(investment => {
        if (isPlainObject(investment)) {
          return investment;
        }

        return Promise.reject();
      })

      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Place an order for an investment.
   */
  static create(assetType: 'shares' | 'loans', orderDetails: unknown) {
    switch (assetType) {
      case 'shares':
        return api.sharesOrders
          .create(orderDetails)
          .then(placedOrder => {
            if (isPlainObject(placedOrder)) {
              return placedOrder;
            }

            return Promise.reject();
          })
          .catch(err => Promise.reject(err.message || err));

      case 'loans':
        return api.loansOrders
          .create(orderDetails)
          .then(placedOrder => {
            if (isPlainObject(placedOrder)) {
              return placedOrder;
            }

            return Promise.reject();
          })
          .catch(err => Promise.reject(err.message || err));
      default:
        return Promise.reject();
    }
  }

  static cancel(assetType: 'shares' | 'loans', orderId: string) {
    return api[
      assetType === 'shares' ? 'cancelSharesOrder' : 'cancelLoansOrder'
    ]
      .cancel(orderId)
      .then(() => Promise.resolve(true))
      .catch(err => Promise.reject(err.message ? [err.message] : err));
  }

  static marketPlace(id: string, loggedIn: boolean) {
    if (!id) {
      return Promise.reject();
    }

    return api.marketPlace
      .get(id, loggedIn)
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get detail about given order
   */
  static orderStatus(data: { assetType: string; transactionId: string }) {
    const { assetType, transactionId: uoid } = data;

    if (!uoid || !assetType) {
      return Promise.reject();
    }

    return api.orderStatus
      .get(assetType, uoid)
      .then(order => {
        if (isPlainObject(order)) {
          return order;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }
}
