import React from 'react';

const Bargraph = props => (
  <svg viewBox="0 0 33 39" fill="none" {...props}>
    <path
      d="M7.57311 25.2773H1.11429C0.498819 25.2773 0 25.7762 0 26.3916V37.1286C0 37.7441 0.498819 38.2429 1.11429 38.2429H7.5734C8.18859 38.2429 8.68769 37.7441 8.68769 37.1286V26.3916C8.6874 25.7762 8.18859 25.2773 7.57311 25.2773ZM6.45882 36.0143H2.22858V27.5059H6.45911V36.0143H6.45882Z"
      fill="currentColor"
    />
    <path
      d="M19.5597 19.2754H13.1006C12.4851 19.2754 11.9863 19.7742 11.9863 20.3897V37.1281C11.9863 37.7436 12.4851 38.2424 13.1006 38.2424H19.5597C20.1749 38.2424 20.674 37.7436 20.674 37.1281V20.3897C20.674 19.7742 20.1749 19.2754 19.5597 19.2754ZM18.4454 36.0138H14.2149V21.504H18.4454V36.0138Z"
      fill="currentColor"
    />
    <path
      d="M31.5448 10.209H25.086C24.4705 10.209 23.9717 10.7078 23.9717 11.3233V37.1258C23.9717 37.7413 24.4705 38.2401 25.086 38.2401H31.5448C32.1603 38.2401 32.6591 37.7413 32.6591 37.1258V11.3233C32.6591 10.7078 32.1603 10.209 31.5448 10.209ZM30.4308 36.0115H26.2003V12.4373H30.4308V36.0115Z"
      fill="currentColor"
    />
    <path
      d="M1.18775 14.2163C1.36244 14.2163 1.53974 14.1751 1.70543 14.0881L13.3434 7.96817L17.9758 10.3697C18.3081 10.5421 18.7047 10.536 19.0315 10.3534L29.3517 4.59454L28.9548 5.64209C28.7366 6.21751 29.0262 6.86084 29.6016 7.07906C29.7316 7.1281 29.8651 7.1516 29.9962 7.1516C30.446 7.1516 30.8697 6.87738 31.0386 6.43196L32.5138 2.53978C32.5692 2.39352 32.5951 2.2168 32.5826 2.05866C32.5719 1.89964 32.5278 1.74062 32.4451 1.59234C32.255 1.25167 31.9129 1.05028 31.551 1.02446L27.5584 0.228783C26.9551 0.108358 26.3681 0.500101 26.248 1.10367C26.1275 1.70725 26.5193 2.29399 27.1229 2.41442L28.2743 2.64366L18.4691 8.11529L13.8526 5.72189C13.5291 5.55416 13.1437 5.55532 12.8214 5.72508L0.668038 12.1154C0.12337 12.4021 -0.0858497 13.0756 0.200558 13.6203C0.399911 13.9996 0.787011 14.2163 1.18775 14.2163Z"
      fill="currentColor"
    />
  </svg>
);

export default Bargraph;
