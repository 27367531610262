import React, { FC } from 'react';
import { Box, Link as MuiLink, Typography } from '@material-ui/core';
import { useI18n } from '../../../../../utils/i18nConnect';
import { Section } from '../../../../Molecules/Section';

interface PermanentRiskWarningDialogProps {
  onOpen: () => void;
}
const PermanentRiskWarningSection: FC<PermanentRiskWarningDialogProps> = ({
  onOpen,
}) => {
  const t = useI18n()('PermanentRiskWarningSection');

  return (
    <Box
      component={Section}
      color="black"
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      size="small"
      style={{ backgroundColor: '#4ac885', color: 'white' }}
    >
      <Box style={{ maxWidth: 900, margin: 'auto' }}>
        <Typography variant="subtitle2" style={{fontSize: '1.125rem'}}>
          {t`Don’t invest unless you’re prepared to lose all the money you invest. This is a high-risk investment and you are unlikely to be protected if something goes wrong. `}
          <MuiLink
            variant="subtitle2"
            onClick={onOpen}
            color="inherit"
            underline="always"
            style={{fontSize: '1.125rem'}}
          >
            {t`Take 2 mins to learn more`}
          </MuiLink>
        </Typography>
      </Box>
    </Box>
  );
};

export { PermanentRiskWarningSection };
