import { createMuiTheme } from '@material-ui/core/styles';
import { themeOptions } from '../themeOptions';

export const sellTheme = createMuiTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
    primary: {
      main: '#C43E38',
    },
    secondary: {
      main: '#FE5C5C',
    },
  },
});
