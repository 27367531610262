import React from 'react';

import Config from 'config';

const Instagram = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 28 28" width={width} {...props}>
    <rect
      width="27.4848"
      height="28"
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
    />
    <rect
      width="27.4848"
      height="28"
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
    />
    <rect
      fill={Config.theme.colors.blackPure}
      fillOpacity="0"
      height="28"
      width="27.4848"
    />
    <path
      d="M13.7424 0C6.15231 0 0 6.26762 0 14C0 21.7315 6.15231 28 13.7424 28C21.3325 28 27.4848 21.7315 27.4848 14C27.4848 6.26762 21.3325 0 13.7424 0Z"
      fill={Config.theme.colors.black}
    />
    <path
      d="M18.9455 6.93896H8.54076C7.56161 6.93896 6.80664 7.70809 6.80664 8.70559V19.3045C6.80664 20.302 7.56161 21.0711 8.54076 21.0711H18.9446C19.9238 21.0711 20.6788 20.302 20.6788 19.3045V8.70559C20.6796 7.70722 19.9238 6.93896 18.9455 6.93896ZM13.7534 11.2291C15.2917 11.2291 16.5388 12.4602 16.5388 13.9783C16.5388 15.4965 15.2917 16.7276 13.7534 16.7276C12.216 16.7276 10.9689 15.4956 10.9689 13.9783C10.9689 12.4593 12.216 11.2291 13.7534 11.2291ZM18.9455 18.8626C18.9455 19.1723 18.8158 19.3045 18.5117 19.3045H8.97451C8.67132 19.3045 8.54076 19.1723 8.54076 18.8626V12.6798L9.62126 12.9196C9.50531 13.3037 9.44261 13.7097 9.44261 14.1315C9.44261 16.48 11.3734 18.3866 13.7526 18.3866C16.1334 18.3866 18.0634 16.4808 18.0634 14.1315C18.0634 13.7097 18.0007 13.3037 17.8847 12.9196L18.9446 12.6798V18.8626H18.9455ZM18.9455 10.9132C18.9455 11.1573 18.7514 11.3551 18.5117 11.3551H16.7776C16.538 11.3551 16.3439 11.1573 16.3439 10.9132V9.14659C16.3439 8.90246 16.538 8.70471 16.7776 8.70471H18.5117C18.7514 8.70471 18.9455 8.90246 18.9455 9.14659V10.9132Z"
      fill={Config.theme.colors.white}
    />
  </svg>
);

Instagram.defaultProps = {
  fill: 'currentColor',
  height: 28,
  width: 28,
};

export default Instagram;
