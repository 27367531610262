import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';

import { LoaderOverlay } from 'components/Atoms';

import { InvestorAuth } from './Auth';
import Loader from './Loader';

const LoadableOnboardingAccreditation = Loadable({
  loader: () => import('containers/OnboardingAccreditation'),
  loading: () => <LoaderOverlay active />,
});

const OnboardingRoutes = ({ match }) => (
  <InvestorAuth>
    <Switch>
      <Route
        exact
        path={`${match.url}/accreditation`}
        component={LoadableOnboardingAccreditation}
      />
      <Route path={`${match.url}/verification`} component={Loader} />
      <Route exact path={`${match.url}/add-funds`} component={Loader} />
      <Redirect to={match.url} />
    </Switch>
  </InvestorAuth>
);

OnboardingRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export { OnboardingRoutes };
