import React, { FC, ReactNode, useState } from 'react';

import { Button, ButtonLink } from 'components/Atoms/Button';
import { Video } from 'components/Atoms/Video';
import PlayButton from 'components/Atoms/Icons/PlayButton';
import { propertyPaths, registerPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

import { useStyles } from './CashbackHero.styles';

import { HeroSvgMask } from './HeroSvgMask';

interface CashbackHeroProps {
  title: ReactNode;
  text: ReactNode;
  isLoggedIn?: boolean;
}

export const CashbackHero: FC<CashbackHeroProps> = ({
  title,
  text,
  isLoggedIn = false,
}) => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const t = useI18n()('CashbackHero');
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.background} />
      <div className={classes.mask}>
        <HeroSvgMask />
      </div>
      <div className={classes.content}>
        <h1 className={classes.heading}>{title}</h1>
        <h3 className={classes.subtitle}>{text}</h3>
        <div className={classes.buttons}>
          <ButtonLink
            fullWidth
            variant="contained"
            color="primary"
            to={
              isLoggedIn
                ? propertyPaths.propertiesAll()
                : registerPaths.register()
            }
          >
            {isLoggedIn ? t`View properties` : t`Join`}
          </ButtonLink>
          <Button
            fullWidth
            variant="outlined"
            color="white"
            onClick={() => setIsVideoVisible(true)}
          >
            {t`What we do`}
            <PlayButton width={24} height={24} className={classes.playButton} />
          </Button>
          <Video
            visible={isVideoVisible}
            onClose={() => setIsVideoVisible(false)}
            url="https://www.youtube.com/embed/9djkHCoF7nQ"
          />
        </div>
      </div>
    </div>
  );
};
