import React from 'react';

import { i18nConnect } from 'utils/i18nConnect';
import { Link } from 'components/Atoms/Link';
import { registerPaths } from 'router/paths';

import { Box, Typography } from '@material-ui/core';

export const ResendVerificationEmailLink = ({ className, i18n }) => (
  <Box className={className} textAlign="center">
    <Typography variant="body1">
      {i18n(
        'ResendVerificationEmailLink'
      )`Didn't receive a verification email?`}
    </Typography>
    <Link to={registerPaths.resendEmail()}>
      {i18n('ResendVerificationEmailLink')`Click here to resend`}
    </Link>
  </Box>
);

ResendVerificationEmailLink.defaultProps = {
  className: '',
};

export default i18nConnect(ResendVerificationEmailLink);
