import React from 'react';

export const HeroSvgMask = () => (
  <svg height="100%" viewBox="0 0 2391 863">
    <defs>
      <linearGradient
        x1="0"
        y1="0"
        x2="863.781"
        y2="61.5154"
        id="linearGradient-1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7A7CA2" />
        <stop stopColor="#449ABB" offset="0.430387" />
        <stop stopColor="#00A88E" offset="0.994475" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="banner-shade" fill="url(#linearGradient-1)">
        <path
          d="M0.067606,478.538 C3.4779,210.496 123.369,-4.7061e-05 123.369,-4.7061e-05 L2390.25083,-4.7061e-05 L2390.25083,863 L94.5001,863 L77.354,863 C41.4876,775.869 -1.9479,636.953 0.067606,478.538 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);
