import { makeStyles, createStyles } from '@material-ui/core/styles';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore TODO: https://britishpearl.atlassian.net/browse/PIT-1979
import CashbackHero from 'assets/images/MarketingHeroes/CashbackHero.jpg';

export const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: 383,
      overflow: 'hidden',
      paddingRight: spacing(3),
      [breakpoints.up('sm')]: {
        paddingRight: spacing(6),
      },
      [breakpoints.up('md')]: {
        height: 425,
      },
      [breakpoints.up('lg')]: {
        height: 547,
      },
    },
    background: {
      width: '50%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${
        CashbackHero.images ? CashbackHero.images[3].path : CashbackHero
      })`,
      backgroundPosition: 'center center',
      [breakpoints.up('md')]: {
        width: '55%',
      },
      [breakpoints.up('lg')]: {
        width: '60%',
      },
    },
    content: {
      position: 'relative',
      width: '50%',
      height: '100%',
      zIndex: 2,

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      [breakpoints.up('md')]: {
        width: '45%',
      },
      [breakpoints.up('lg')]: {
        width: '40%',
      },
    },
    mask: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '75%',
      height: '100%',

      [breakpoints.up('sm')]: {
        width: '65%',
      },
      [breakpoints.up('md')]: {
        width: '60%',
      },
      [breakpoints.up('lg')]: {
        width: '50%',
      },
    },
    heading: {
      // fontSize: '3rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#FFF',
      lineHeight: 1.2,
      [breakpoints.up('sm')]: {
        fontSize: '2rem',
      },
      [breakpoints.up('md')]: {
        fontSize: '2.5rem',
      },
      [breakpoints.up('lg')]: {
        fontSize: '3rem',
      },
    },
    subtitle: {
      // fontSize: '1.5rem',
      fontSize: '0.9375rem',
      lineHeight: 1.4,
      color: '#FFF',
      [breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
      [breakpoints.up('md')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.up('lg')]: {
        fontSize: '1.25rem',
      },
    },
    buttons: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      gridTemplateColumns: '1fr',
      gridGap: '.5rem',
      [breakpoints.up('md')]: {
        gridTemplateRows: '1fr',
        gridTemplateColumns: '170px 170px',
      },
    },
    playButton: {
      marginLeft: spacing(1),
      marginTop: '-1px',
    },
  })
);
