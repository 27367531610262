import { isNil, isPlainObject, keys, mapValues } from 'lodash';

/**
 * Removes weird `{ someKey: { value: 123 } }` structure and coverts to simple `{ someKey: 123 }`
 */
export const removeValueStructureRecursively = data => {
  if (Array.isArray(data)) {
    return data.map(removeValueStructureRecursively);
  }
  if (!isPlainObject(data)) {
    return data;
  }

  return mapValues(data, value =>
    keys(value).length === 1 && !isNil(value.value)
      ? value.value
      : removeValueStructureRecursively(value)
  );
};
