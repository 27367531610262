import React from 'react';

import Config from 'config';

const OpenhandMoney = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none">
      <path
        d="M20.867 15.35c-.274.308-1.26 1.29-2.062 2.036-1.002.933-1.85 1.606-1.958 1.606h-7.54c-.518 0-.865.16-1.17.46l-.18.192.547.547.18-.18c.165-.16.322-.23.623-.23h7.54c.648 0 3.416-2.57 4.598-3.9.55-.61 2.313-3.37 2.76-4.41.054-.123.036-.27-.046-.38-.46-.617-1.51-.93-2.56-.684-1 .23-1.64.873-2.36 2.244l-2.36 1.22.35.685 2.466-1.28c.072-.04.13-.098.166-.17.65-1.266 1.144-1.78 1.9-1.955.646-.15 1.27-.02 1.604.24-.54 1.104-2.06 3.455-2.514 3.97z"
        fill={fill}
      />
      <path
        d="M11.46 16.534h4.31c2.67 0 2.67-3.467 0-3.467h-2.846c-.045 0-.167-.08-.668-.46-.243-.184-.42-.304-.603-.404-.253-.137-.49-.213-.73-.213H8.23c-.757 0-1.704.516-2.418 1.18l-2.164 2.165.547.547 2.154-2.154c.57-.534 1.36-.964 1.88-.964h2.69c.09 0 .21.04.36.12.14.076.29.178.5.34l.26.2c.1.076.18.134.26.185.23.15.41.23.61.23h2.84c1.636 0 1.636 1.92 0 1.92h-4.31v.77z"
        fill={fill}
      />
      <path
        d="M6.888 23.96c-.15.152-.396.152-.547 0L-.12 17.5c-.15-.15-.15-.396 0-.547l2.69-2.693c.152-.15.397-.15.548 0l6.462 6.46c.152.152.152.397 0 .548L6.89 23.96zm1.872-2.966L2.844 15.08.7 17.223l5.914 5.916 2.146-2.15z"
        fill={fill}
      />
      <path fill={fill} d="M2.57 17.498l1.078 1.077.547-.547-1.077-1.077" />
      <path
        d="M7.69 5.847h1.08v-.774H7.69m-1.766-.87C6.93 4.043 7.54 3.27 7.54 2.23h-.774c0 .753-.437 1.227-1.23 1.227-.213 0-.386.174-.386.387v3.232c0 .214.173.387.387.387.68 0 1.23.55 1.23 1.228h.773c0-.97-.695-1.78-1.616-1.96V4.2zm11.462.87H16.31v.774h1.076m1.767.88c-.92.18-1.616.99-1.616 1.964h.774c0-.67.55-1.22 1.23-1.22.22 0 .39-.17.39-.38V3.85c0-.213-.17-.387-.38-.387-.79 0-1.23-.474-1.23-1.23h-.77c0 1.04.61 1.81 1.62 1.97v2.52zM12.538 2.92c1.403 0 2.542 1.14 2.54 2.54C15.08 6.865 13.94 8 12.54 8 11.137 8 10 6.864 10 5.46c-.002-1.4 1.137-2.54 2.54-2.54zm0 .774c-.976 0-1.768.792-1.767 1.767.01.98.8 1.77 1.77 1.77.98 0 1.77-.79 1.77-1.76s-.79-1.77-1.76-1.77z"
        fill={fill2}
      />
      <path
        d="M3.383 10.156c-.214 0-.387-.174-.387-.387V1.15c0-.212.173-.386.387-.386h18.31c.215 0 .388.174.388.387v8.62c0 .22-.17.39-.38.39H3.39zm.387-.775h17.537V1.54H3.77v7.84z"
        fill={fill2}
      />
    </g>
  </svg>
);

OpenhandMoney.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default OpenhandMoney;
