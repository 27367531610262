export const BANK_FINDALL_REQUEST = 'InvestorBankDetails/BANK_FINDALL_REQUEST';
export const BANK_FINDALL_SUCCESS = 'InvestorBankDetails/BANK_FINDALL_SUCCESS';
export const BANK_FINDALL_ERROR = 'InvestorBankDetails/BANK_FINDALL_ERROR';

export const BANK_CREATE_INIT = 'InvestorBankDetailCreate/BANK_CREATE_INIT';
export const BANK_RESET = 'InvestorBankDetailCreate/BANK_RESET';
export const BANK_CREATE_REQUEST =
  'InvestorBankDetailCreate/BANK_CREATE_REQUEST';
export const BANK_CREATE_SUCCESS =
  'InvestorBankDetailCreate/BANK_CREATE_SUCCESS';
export const BANK_CREATE_ERROR = 'InvestorBankDetailCreate/BANK_CREATE_ERROR';

export const BANK_TOPUP_REQUEST = 'InvestorCardTopup/BANK_TOPUP_REQUEST';
export const BANK_TOPUP_SUCCESS = 'InvestorCardTopup/BANK_TOPUP_SUCCESS';
export const BANK_TOPUP_ERROR = 'InvestorCardTopup/BANK_TOPUP_ERROR';

export const BANK_WITHDRAW_REQUEST = 'InvestorBankSingle/BANK_WITHDRAW_REQUEST';
export const BANK_WITHDRAW_SUCCESS = 'InvestorBankSingle/BANK_WITHDRAW_SUCCESS';
export const BANK_WITHDRAW_ERROR = 'InvestorBankSingle/BANK_WITHDRAW_ERROR';

export const BANK_REMOVE_REQUEST = 'InvestorBankSingle/BANK_REMOVE_REQUEST';
export const BANK_REMOVE_SUCCESS = 'InvestorBankSingle/BANK_REMOVE_SUCCESS';
export const BANK_REMOVE_ERROR = 'InvestorBankSingle/BANK_REMOVE_ERROR';

export const CARDS_FINDALL_REQUEST =
  'InvestorCardDetails/CARDS_FINDALL_REQUEST';
export const CARDS_FINDALL_SUCCESS =
  'InvestorCardDetails/CARDS_FINDALL_SUCCESS';
export const CARDS_FINDALL_ERROR = 'InvestorCardDetails/CARDS_FINDALL_ERROR';

export const CARD_CREATE_INIT = 'InvestorCardDetailCreate/CARD_CREATE_INIT';
export const CARDS_RESET = 'InvestorCardDetailCreate/CARDS_RESET';
export const CARD_CREATE_REQUEST =
  'InvestorCardDetailCreate/CARD_CREATE_REQUEST';
export const CARD_CREATE_SUCCESS =
  'InvestorCardDetailCreate/CARD_CREATE_SUCCESS';
export const CARD_CREATE_ERROR = 'InvestorCardDetailCreate/CARD_CREATE_ERROR';

export const CARD_TOPUP_REQUEST = 'InvestorCardTopup/CARD_TOPUP_REQUEST';
export const CARD_TOPUP_SUCCESS = 'InvestorCardTopup/CARD_TOPUP_SUCCESS';
export const CARD_TOPUP_ERROR = 'InvestorCardTopup/CARD_TOPUP_ERROR';

export const CARD_REMOVE_REQUEST = 'InvestorCardSingle/CARD_REMOVE_REQUEST';
export const CARD_REMOVE_SUCCESS = 'InvestorCardSingle/CARD_REMOVE_SUCCESS';
export const CARD_REMOVE_ERROR = 'InvestorCardSingle/CARD_REMOVE_ERROR';

export const TRANSFER_REQUEST = 'InvestorTransfer/TRANSFER_REQUEST';
export const TRANSFER_SUCCESS = 'InvestorTransfer/TRANSFER_SUCCESS';
export const TRANSFER_ERROR = 'InvestorTransfer/TRANSFER_ERROR';
export const TRANSFER_RESET = 'InvestorTransfer/TRANSFER_RESET';

export const THREE_D_SECURE_REQUEST =
  'InvestorCardSingle/THREE_D_SECURE_REQUEST';
export const THREE_D_SECURE_GENERIC_ERROR =
  'InvestorCardSingle/THREE_D_SECURE_GENERIC_ERROR';

export const FETCH_CARD_TRANSACTION =
  'InvestorCardSingle/FETCH_CARD_TRANSACTION';

export const FETCH_CARD_TRANSACTION_SUCCESS =
  'InvestorCardSingle/FETCH_CARD_TRANSACTION_SUCCESS';

export const CARD_TRANSACTION_RESET =
  'InvestorCardSingle/CARD_TRANSACTION_RESET';
