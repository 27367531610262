import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';

import { TextField, TextFieldProps } from './TextField';

export type TextFieldReduxProps = WrappedFieldProps & Partial<TextFieldProps>;

export const TextFieldRedux: FC<TextFieldReduxProps> = ({
  label,
  input,
  disabled,
  meta: { touched, invalid, error, submitting },
  ...custom
}) => (
  <TextField
    label={label}
    error={touched && invalid}
    helperText={touched && error}
    disabled={submitting || disabled}
    {...input}
    {...custom}
  />
);
