import React, { FC, ReactNode } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import {
  useExpansionPanelStyles,
  useExpansionPanelSummaryStyles,
} from './ExpandableBlock.styles';

interface ExpandableBlockProps {
  title: ReactNode;
  text: ReactNode;
}

export const ExpandableBlock: FC<ExpandableBlockProps> = ({ title, text }) => {
  const expansionPanelClasses = useExpansionPanelStyles();
  const expansionPanelSummaryClasses = useExpansionPanelSummaryStyles();

  return (
    <ExpansionPanel classes={expansionPanelClasses}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
        classes={expansionPanelSummaryClasses}
      >
        <Typography variant="h4">{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography variant="body2" component="div">
          {text}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
