import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { Section } from 'components/Molecules/Section';
import ReturnsCalculator from 'components/Organisms/ReturnsCalculator';
import { useI18n } from 'utils/i18nConnect';

import { Property } from '../InvestInAPropertyTableSection';

import { Variant } from './types';
import { getCalculatorVariant } from './getCalculatorVariant';
import { useStyles } from './CalculatorSection.styles';
import { CalculatorSectionSharesText } from './CalculatorSectionSharesText';
import { CalculatorSectionLoansText } from './CalculatorSectionLoansText';
import { CalculatorSectionIsaLoansText } from './CalculatorSectionIsaLoansText';

export interface CalculatorSectionProps {
  properties: Property[];
  variant: Variant;
  loggedIn?: boolean;
  isaAccountOpened?: boolean;
}

export const CalculatorSection: FC<CalculatorSectionProps> = ({
  properties,
  variant,
  loggedIn = false,
  isaAccountOpened = false,
}) => {
  const i18n = useI18n();
  const classes = useStyles();

  const calculatorVariant = getCalculatorVariant(variant);

  return (
    <Section size="large">
      <Grid
        container
        spacing={4}
        alignItems="baseline"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={10} lg={5}>
          {variant === 'shares' && (
            <CalculatorSectionSharesText loggedIn={loggedIn} />
          )}
          {variant === 'loans' && (
            <CalculatorSectionLoansText loggedIn={loggedIn} />
          )}
          {variant === 'isaLoans' && (
            <CalculatorSectionIsaLoansText
              loggedIn={loggedIn}
              isaAccountOpened={isaAccountOpened}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={7}>
          <ReturnsCalculator
            i18n={i18n}
            type={calculatorVariant}
            properties={properties}
          />
        </Grid>
      </Grid>
    </Section>
  );
};
