import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    fontWeightBold: {
      fontWeight: 'bold',
    },
    fontWeightNormal: {
      fontWeight: 'normal',
    },
    fontWeightLighter: {
      fontWeight: 'lighter',
    },
  })
);
