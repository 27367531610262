import React from 'react';

import styled from 'styled-components';

import { AppBar } from 'components/Organisms/AppBar';
import { LoaderOverlay } from 'components/Atoms';
import Config from 'config';

import media from 'utils/responsive';
import { isServerSide } from 'utils/isSSR';

import { Footer } from 'components/Organisms/footer';
import withBaseTemplate from '../Base';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 4rem 1rem;

  ${media.wide`
    margin: 0 10rem;
    padding-top: 12rem;
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.p`
  font-size: 3rem;
  font-weight: ${Config.theme.fontWeight.light};
`;

const StyledLoaderOverlay = styled(LoaderOverlay)`
  padding-top: 5rem;
`;

// eslint-disable-next-line react/prop-types
export const ErrorTemplate = ({ children, Icon, message }) => {
  const errorTemplateContent = (
    <>
      <AppBar />

      <Wrapper>
        <Content>
          <Message>{message}</Message>

          {React.Children.toArray(children)}
        </Content>

        <Icon width={512} height={512} />
      </Wrapper>

      <Footer />
    </>
  );

  return isServerSide() ? (
    <>
      <noscript>{errorTemplateContent}</noscript>
      <StyledLoaderOverlay className="js-only" active />
    </>
  ) : (
    errorTemplateContent
  );
};

ErrorTemplate.defaultProps = {
  children: null,
};

export default withBaseTemplate(ErrorTemplate);
