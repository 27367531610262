import React, { FC, useEffect, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { isEmpty } from 'lodash';
import { Collapse } from '@material-ui/core';

import { useStyles } from './ErrorMessage.styles';

export const ErrorMessage: FC = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  useEffect(() => {
    if (containerRef.current && !isEmpty(children)) {
      scrollIntoView(containerRef.current, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [containerRef, children]);

  return (
    <Collapse
      in={!isEmpty(children)}
      data-test-id="error-message"
      ref={containerRef}
    >
      <div className={classes.root}>{children}</div>
    </Collapse>
  );
};
