import React, { FC } from 'react';
import clsx from 'clsx';

import { ButtonProps } from './Button';
import { ButtonSubmit } from './ButtonSubmit';
import { useStyles } from './ButtonBottom.styles';

interface BottomButtonProps extends ButtonProps {
  borderBottomLeftRadius?: boolean;
  borderBottomRightRadius?: boolean;
}

// TODO: try to remove this button rather than utilising it more, this should be removed with new implementation of features.
export const ButtonBottom: FC<BottomButtonProps> = ({
  borderBottomLeftRadius = true,
  borderBottomRightRadius = true,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <ButtonSubmit
      className={clsx(classes.button, {
        [classes.buttonBorderBottomLeftRadius]: borderBottomLeftRadius,
        [classes.buttonBorderBottomRightRadius]: borderBottomRightRadius,
      })}
      {...props}
    >
      {children}
    </ButtonSubmit>
  );
};
