import { useRouteMatch } from 'react-router-dom';

export const useGetRouteMatch = (to: string, exact?: boolean): boolean => {
  const match = useRouteMatch(to);

  if (match === null) {
    return false;
  }

  return exact ? match.isExact : true;
};
