/**
 * Img.react.js
 *
 * Renders an image, enforcing the usage of the alt="" tag
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// We want height 100% on the NoScript only in the case that the noscript
// is wrapping an images which is relatively positioned and actually moved
// (left or top) and clipped by its parent wrapper (e.g. Benefits on homepage)
const StyledNoscript = styled.noscript`
  display: block;
  height: ${({ left, top }) => (left || top ? '100%' : 'initial')};
`;

const Image = styled.img`
  background-image: url(${({ placeholder }) => placeholder});
  background-position: ${({ position }) => position || '50% 50%'};
  background-size: cover;
  display: block;
  overflow: hidden;
  transition: all ${({ skipAnimation }) => (skipAnimation ? '.1s' : '.3s')};
  width: ${({ width }) => (width !== undefined ? width : 'unset')};
  min-height: ${({ minHeight }) =>
    minHeight !== undefined ? `${minHeight}px` : 'unset'};

  @supports (object-fit: cover) {
    object-fit: cover;
    object-position: ${({ position }) => position || '50% 50%'};
  }

  filter: ${({ loaded, placeholder }) =>
    `blur(${
      loaded || (placeholder && placeholder.includes('svg+xml')) ? 0 : '1rem'
    });`};
`;
StyledNoscript.displayName = 'NoScript';
Image.displayName = 'Image';

const getEffectivePosition = positions => {
  if (!positions) {
    // This will eventually default to 50% 50%
    return '';
  }
  const [, selectedPosition] = positions.reduce(
    (positionSoFar, position) => {
      const [xWidth] = position;
      const [prevXWidth] = positionSoFar;
      if (
        prevXWidth <= xWidth &&
        window &&
        window.matchMedia &&
        window.matchMedia(`(min-width: ${xWidth}px)`).matches
      ) {
        return position;
      }
      return positionSoFar;
    },
    [0, '']
  );
  return selectedPosition;
};

class Img extends React.PureComponent {
  state = {
    shouldLoad: false,
    loaded: false,
    skipAnimation: false,
  };

  mountedTime = new Date();

  componentDidMount() {
    this.mountedTime = new Date();

    if (window) {
      window.addEventListener('scroll', this.handleResize);
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('scroll', this.handleResize);
      window.removeEventListener('resize', this.handleResize);
    }
  }

  setElement = el => {
    this.element = el;
    this.handleResize();
  };

  handleLoad = () => {
    const loadedTime = new Date();

    this.setState({
      loaded: true,
      skipAnimation: loadedTime.getTime() - this.mountedTime.getTime() < 250,
    });
  };

  handleResize = () => {
    if (!window || !this.element) {
      return;
    }

    const elementTop = this.element.getBoundingClientRect().top;
    const elementBottom = this.element.getBoundingClientRect().bottom;

    const OFFSET = 100;

    const shouldLoad =
      elementBottom > -OFFSET && elementTop < window.innerHeight + OFFSET;

    if (shouldLoad) {
      window.removeEventListener('scroll', this.handleResize);
      window.removeEventListener('resize', this.handleResize);

      this.setState({ shouldLoad: true });
    }
  };

  render() {
    const {
      alt,
      className,
      position,
      imagePositionSet,
      sizes,
      src,
      forceIndex,
      left,
      top,
      width,
      calculateMinHeight,
      placeholder,
    } = this.props;
    const { loaded, shouldLoad, skipAnimation } = this.state;
    const isForcedIndex = typeof forceIndex === 'number';

    if (!src) {
      return null;
    }

    // In blog posts the src is the image url, not an object
    const largeSrc = src.images
      ? src.images[src.images.length - 1].path
      : src.src || src;
    const effectiveSrc = isForcedIndex ? src.images[forceIndex].path : largeSrc;
    // Use effectiveImagePosition only on client side, not server side
    const effectiveImagePosition =
      position || getEffectivePosition(imagePositionSet);
    // We need to compare to both boolean and string since the tests use process.env which
    // converts to a string, and webpack define plugin does the opposite and converts a string
    // to a boolean...
    const isServerSide =
      process &&
      process.env &&
      (process.env.SSR === true || process.env.SSR === 'true');

    let minHeight;

    if (calculateMinHeight && window) {
      minHeight = Math.ceil(Math.min(window.innerWidth, 750) / 3);
    }

    return isServerSide ? (
      <StyledNoscript left={left} top={top}>
        <Image
          alt={alt}
          src={effectiveSrc}
          srcSet={isForcedIndex ? undefined : src.srcSet}
          sizes={isForcedIndex ? undefined : sizes}
          skipAnimation
          loaded
          position={position}
          className={className}
          left={left}
          top={top}
          width={width}
          minHeight={minHeight}
        />
      </StyledNoscript>
    ) : (
      <Image
        alt={alt}
        placeholder={placeholder ? src.placeholder : undefined}
        src={shouldLoad ? effectiveSrc : undefined}
        srcSet={shouldLoad && !isForcedIndex ? src.srcSet : undefined}
        sizes={isForcedIndex ? undefined : sizes}
        skipAnimation={skipAnimation}
        onLoad={this.handleLoad}
        loaded={loaded}
        position={effectiveImagePosition}
        className={clsx('js-only', className)}
        innerRef={this.setElement}
        left={left}
        top={top}
        width={width}
        minHeight={minHeight}
      />
    );
  }
}

Img.defaultProps = {
  calculateMinHeight: undefined,
  className: undefined,
  forceIndex: undefined,
  position: undefined,
  imagePositionSet: undefined,
  sizes: undefined,
  src: undefined,
  left: undefined,
  top: undefined,
  width: undefined,
  placeholder: true,
};

const ImgSrcPropType = PropTypes.shape({
  height: PropTypes.number,
  width: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    })
  ),
  src: PropTypes.string,
  srcSet: PropTypes.string,
  placeholder: PropTypes.string,
});

Img.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
  imagePositionSet: PropTypes.arrayOf(PropTypes.string),
  sizes: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, ImgSrcPropType]),
  forceIndex: PropTypes.number,
  left: PropTypes.string,
  top: PropTypes.string,
  width: PropTypes.string,
  calculateMinHeight: PropTypes.bool,
  placeholder: PropTypes.bool,
};

export { Img, ImgSrcPropType };
