import { isArray, isEmpty, isPlainObject } from 'lodash';

import api from '../endpoints/Accounts';

export default class Account {
  /**
   * Create ISA account
   */
  static createIsa(data) {
    return (
      api.myIsa
        .create(data)
        .then(response => {
          if (!isEmpty(response)) {
            return response;
          }

          return Promise.reject();
        })
        // eslint-disable-next-line no-underscore-dangle
        .catch(err => Promise.reject(err.message ? [err.message] : err._error))
    );
  }

  /**
   * Get account details
   */
  static findAll() {
    return api.accounts
      .findAll()
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Withdraw money from account.
   */
  static withdraw(accountId, bankId, amount) {
    return api.accountWithdraw
      .withdraw(accountId, bankId, amount)
      .then(() => true)
      .catch(err => Promise.reject(err.message ? [err.message] : err));
  }

  /**
   * Cancel a pending withdrawal
   */
  static cancelWithdrawal(accountId, uuid) {
    return api.cancelWithdrawal
      .cancel(accountId, uuid)
      .then(() => true)
      .catch(err => Promise.reject(err.message ? [err.message] : err));
  }

  /**
   * Find pending withdrawals
   */
  static findWithdrawals(accountId) {
    return api.accountWithdraw
      .withdrawals(accountId)
      .then(data => {
        if (data && data.items) {
          return data.items;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message ? [err.message] : err));
  }

  /**
   * Transfer money between accounts.
   */
  static transfer(sourceAccountId, destinationAccountId, amount) {
    return api.transfer
      .transfer(sourceAccountId, destinationAccountId, amount)
      .then(() => true)
      .catch(err => Promise.reject(err.message ? [err.message] : err));
  }

  /**
   * Get all stored (tokenised) cards.
   */
  static findAllCards() {
    return api.cards
      .findAll()
      .then(data => {
        if (isArray(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Add card.
   * @deprecated will be removed after full migration to 3DSv2
   */
  static createCardV1(accountId, card) {
    return api.cardsTopupV1
      .createCard(accountId, card)
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Add card.
   */
  static createCard({ accountId, card, deviceFingerprintingId }) {
    return api.cardsTopup
      .createCard({ accountId, card, deviceFingerprintingId })
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get tokenized card details.
   */
  static getCardDetails(cardId) {
    return api.cardsDetails
      .get(cardId)
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  static removeCard(cardId) {
    return api.cardsRemove
      .remove(cardId)
      .catch(err => Promise.reject(err.message ? [err.message] : err));
  }

  /**
   * Top up from existing card.
   * @deprecated will be removed after full migration to 3DSv2
   */
  static topupCardV1(accountId, cardId, amount) {
    return api.cardsTopupTokenV1
      .topup(accountId, cardId, amount)
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Top up from existing card.
   */
  static topupCard({ accountId, cardId, amount, deviceFingerprintingId }) {
    return api.cardsTopupToken
      .topup({ accountId, cardId, amount, deviceFingerprintingId })
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Top up from existing bank account.
   */
  static topupBank(accountId, bankId, amount) {
    return api.bankDetails
      .transfer(accountId, bankId, amount)
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get topup transaction
   */
  static topupCardTransaction(accountId, uuid) {
    return api.cardsTopupTransaction
      .get(accountId, uuid)
      .then(data => {
        if (isPlainObject(data) && !isEmpty(data)) {
          return data;
        }
        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  static topupCardThreeDSecureContinue(orderUuid, authenticationId) {
    return api.cardsTopupThreeDSecureContinue
      .post(orderUuid, authenticationId)
      .catch(err => Promise.reject(err.message || err));
  }
}
