import React from 'react';

import Config from 'config';

const Calculator = ({ height, width, color, circleColor, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 80 80" width={width} {...props}>
    <circle cx="40" cy="40" r="40" fill={circleColor} />
    <g filter="url(#filter0_d)">
      <rect x="17" y="17" width="47" height="47" rx="4" fill={color} />
      <path
        d="M58.169 30.316c0 .938-.749 1.698-1.673 1.698H22.914c-.925 0-1.673-.76-1.673-1.698v-6.524c0-.938.748-1.698 1.673-1.698h33.58c.924 0 1.673.76 1.673 1.698v6.524h.002zM26.92 35.322h-5.681v4.07h5.68v-4.07zM34.86 35.322h-5.682v4.07h5.681v-4.07zM42.544 35.322h-5.68v4.07h5.68v-4.07zM50.484 35.322h-5.681v4.07h5.68v-4.07zM58.17 35.322h-5.682v4.07h5.681v-4.07zM26.92 41.597h-5.681v4.07h5.68v-4.07zM34.86 41.597h-5.682v4.07h5.681v-4.07zM42.544 41.597h-5.68v4.07h5.68v-4.07zM26.92 48.042h-5.681v4.07h5.68v-4.07zM34.86 48.042h-5.682v4.07h5.681v-4.07zM42.544 48.042h-5.68v4.07h5.68v-4.07zM26.92 54.487h-5.681v4.07h5.68v-4.07zM34.86 54.487h-5.682v4.07h5.681v-4.07zM42.544 54.487h-5.68v4.07h5.68v-4.07zM58.17 54.487h-5.682v4.07h5.681v-4.07zM50.484 41.597h-5.681v4.07h5.68v-4.07zM50.484 48.042h-5.681v10.516h5.68V48.042zM58.17 41.597h-5.682v4.07h5.681v-4.07zM58.17 48.042h-5.682v4.07h5.681v-4.07z"
        fill={circleColor}
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="13"
        y="17"
        width="55"
        height="55"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

Calculator.defaultProps = {
  circleColor: Config.theme.colors.pomegranate,
  color: Config.theme.colors.white,
  height: 80,
  width: 80,
};

export default Calculator;
