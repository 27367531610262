import React, { FC } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Typography,
} from '@material-ui/core';
import { Close, SaveAlt } from '@material-ui/icons';

import Config from 'config';
import { Button, ButtonLink } from 'components/Atoms/Button';
import { useI18n } from 'utils/i18nConnect';
import { investorPaths, registerPaths } from 'router/paths';

import { useStyles } from './TransferIsaModal.styles';

export interface TransferIsaModalProps {
  open: boolean;
  loggedIn?: boolean;
  onClose: () => void;
}

export const TransferIsaModal: FC<TransferIsaModalProps> = ({
  loggedIn = false,
  open,
  onClose,
}) => {
  const t = useI18n()('Molecules.TransferIsaModal');
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      scroll="body"
      classes={{
        paper: classes.root,
      }}
    >
      <Box textAlign="center">
        {loggedIn ? (
          <>
            <DialogTitle>
              {t`To transfer your ISA please download the form and send it back to us`}
            </DialogTitle>
            <DialogContent>
              <Box mb={3}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <Button
                      startIcon={<SaveAlt />}
                      variant="contained"
                      color="primary"
                      href={`${Config.docs.url}/My_Account_Docs/BritishPearl_ISA_Transfer_Authority_Form.pdf`}
                      /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
                      // @ts-ignore
                      target="_blank"
                    >
                      {t`Download form`}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>
              {t`You need an account with us to transfer your ISA`}
            </DialogTitle>
            <DialogContent>
              <Box mb={3}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={3}>
                    <ButtonLink
                      fullWidth
                      to={investorPaths.isaAccount()}
                      variant="outlined"
                      color="primary"
                    >
                      {t`Log in`}
                    </ButtonLink>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ButtonLink
                      fullWidth
                      to={registerPaths.register()}
                      variant="contained"
                      color="primary"
                    >
                      {t`Join`}
                    </ButtonLink>
                  </Grid>
                </Grid>
              </Box>

              <Typography variant="body2">
                {t`If you are a new user, you will be able to transfer your ISA once you have completed registration and opened an ISA with us.`}
              </Typography>
            </DialogContent>
          </>
        )}
      </Box>
      <Box position="absolute" top={-16} right={-16}>
        <Fab
          color="primary"
          size="small"
          onClick={onClose}
          className={classes.fab}
        >
          <Close />
        </Fab>
      </Box>
    </Dialog>
  );
};
