import React from 'react';
import i18n from 'es2015-i18n-tag';

import { Tooltip } from 'components/Atoms/Tooltip';

export const isaCheckItems = [
  {
    id: 'a',
    description: i18n(
      'IsaLandingPage'
    )`Earn tax-free interest up to 4.30% p.a.`,
  },
  {
    id: 'f',
    description: (
      <>
        {i18n('IsaLandingPage')`Loans secured with a`}{' '}
        <Tooltip
          title={
            <>
              {i18n('IsaLandingPage')`Loans are repaid first`}
              <br />
              {i18n('IsaLandingPage')`(before anyone else)`}
              <br />
              {i18n('IsaLandingPage')`when the property is sold`}
            </>
          }
        >
          {i18n('IsaLandingPage')`first charge`}
        </Tooltip>{' '}
        {i18n('IsaLandingPage')`over UK property`}
      </>
    ),
  },
  {
    id: 'b',
    description: i18n('IsaLandingPage')`Register and invest in minutes`,
  },
  {
    id: 'g',
    description: i18n('IsaLandingPage')`3 - 5 year investment terms available`,
  },
  {
    id: 'c',
    description: i18n(
      'IsaLandingPage'
    )`Interest accrues daily, and usually paid monthly`,
  },
  {
    id: 'h',
    description: i18n(
      'IsaLandingPage'
    )`Diversify loans across multiple UK properties`,
  },
  {
    id: 'd',
    description: i18n(
      'IsaLandingPage'
    )`Option to exit early through our Resale Market`,
  },
  {
    id: 'i',
    description: i18n(
      'IsaLandingPage'
    )`Transfer funds from existing ISAs for free`,
  },
  {
    id: 'e',
    description: (
      <>
        {i18n('IsaLandingPage')`Fully`}{' '}
        <Tooltip
          title={i18n(
            'IsaLandingPage'
          )`Withdraw and replace ISA funds in the same tax year with no penalties`}
        >
          {i18n('IsaLandingPage')`flexible`}
        </Tooltip>{' '}
        {i18n('IsaLandingPage')`ISA`}
      </>
    ),
  },
  {
    id: 'j',
    description: i18n(
      'IsaLandingPage'
    )`Manage your IFISA with our easy to use dashboard`,
  },
];
