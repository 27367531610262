import React from 'react';

import Config from 'config';

const Wallet = ({ height, width, color, circleColor, ...props }) => (
  <svg fill="none" height={height} viewBox="0 0 80 80" width={width} {...props}>
    <circle cx="40" cy="40" r="40" fill={circleColor} />
    <path
      d="M49.318 38.322a1.833 1.833 0 0 0-1.827 1.83v5.167c0 1.02.833 1.83 1.827 1.83h12.395v-8.828H49.318zm2.567 6.51a2.025 2.025 0 0 1-2.035-2.039c0-1.135.902-2.039 2.035-2.039s2.035.904 2.035 2.04a2.025 2.025 0 0 1-2.035 2.038z"
      fill={color}
    />
    <path
      d="M61.714 36.236H49.227c-2.104 0-3.816 1.715-3.816 3.8v5.375a3.823 3.823 0 0 0 3.816 3.823h12.487v7.414c0 1.53-1.248 2.78-2.775 2.78H21.061a2.786 2.786 0 0 1-2.775-2.78V28.822c0-1.53 1.249-2.78 2.775-2.78h1.984l.892 1.553 37.777.146v8.495zM24.322 25.143h34.194l-2.448-9.487c-.343-1.332-1.91-2.172-3.5-1.886l-26.924 4.877c-1.591.287-2.595 1.599-2.252 2.93l.93 3.566z"
      fill={color}
    />
  </svg>
);

Wallet.defaultProps = {
  circleColor: Config.theme.colors.bostonBlue,
  color: Config.theme.colors.white,
  height: 80,
  width: 80,
};

export default Wallet;
