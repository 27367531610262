import defaultsDeep from 'lodash/defaultsDeep';
import set from 'lodash/set';

import defaultConfig from 'config.default.json';

import environmentConfig from 'config.json';

const config = environmentConfig || {};

// TODO https://britishpearl.atlassian.net/browse/PIT-1981
if (process.env.USE_PROD_PIP_URL) {
  // set PROD api url for `yarn build:ssr` (internals/ssrInit.js)
  set(config, 'api.url', 'https://api.britishpearl.com');
}

export default defaultsDeep(config, defaultConfig);
