import React, { FC } from 'react';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import { Button, ButtonProps } from './Button';
import { useGetRouteMatch } from './useGetRouteMatch';

type ButtonLinkProps = {
  activeProps?: Partial<ButtonProps>;
  exact?: boolean;
  to: string;
} & Omit<ReactRouterLinkProps, 'to'> &
  Partial<ButtonProps>;

export const ButtonLink: FC<ButtonLinkProps> = ({
  to,
  exact,
  activeProps,
  ...props
}) => {
  const match = useGetRouteMatch(to, exact);
  return (
    <Button
      /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
      // @ts-ignore
      to={to}
      component={ReactRouterLink}
      {...props}
      {...(match && activeProps)}
    />
  );
};
