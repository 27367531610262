import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    lowerRoman: {
      listStyleType: 'lower-roman',
    },
    lowerLatin: {
      listStyleType: 'none',
      padding: 0,
      '& > li': {
        position: 'relative',
        paddingLeft: '3ch',
        counterIncrement: 'lowerLatin',
        '&:before': {
          content: '"(" counter(lowerLatin, lower-latin) ")"',
          width: '3ch',
          position: 'absolute',
          left: 0,
        },
      },
    },
    legal: {
      listStyleType: 'none',
      counterReset: 'tier1',
      padding: 0,
      '& > li': {
        counterIncrement: 'tier1',
        position: 'relative',
        paddingLeft: '4ch',
        '&:before': {
          content: 'counter(tier1) "."',
          width: '4ch',
          position: 'absolute',
          left: 0,
        },
      },
      '& > li > ol': {
        listStyleType: 'none',
        counterReset: 'tier2',
        padding: 0,
        marginLeft: '-4ch',
      },
      '& > li > ol > li': {
        counterIncrement: 'tier2',
        position: 'relative',
        paddingLeft: '4ch',
        '&:before': {
          content: 'counter(tier1) "." counter(tier2) "."',
          width: '4ch',
          position: 'absolute',
          left: 0,
        },
      },
    },
  })
);
