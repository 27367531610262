import { Schema, ValidationError } from 'yup';
import { RecordOf } from 'immutable';
import { set } from 'lodash';

// taken from here: https://github.com/patwritescode/redux-form-yup
// and added immutable js support
export function createImmutableAsyncValidation<S extends Schema<{}>, R>(
  schema: S
) {
  return async (values: RecordOf<R>) => {
    try {
      await schema.validate(values.toJS(), { abortEarly: false });
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error: ValidationError) => {
        set(formErrors, error.path, error.message);
      });
      throw formErrors;
    }
  };
}
