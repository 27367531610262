import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { format, parse } from 'date-fns';

import React, { FC, useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { isNaN } from 'lodash';

import { TextField } from './TextField';

export const dateFieldReduxFormatString = 'dd / MM / yyyy';

export const DateFieldRedux: FC<WrappedFieldProps &
  Partial<KeyboardDatePickerProps>> = ({
  meta: { submitting, error, invalid, touched },
  input: { value, onChange, ...inputProps },
  disabled,
  ...rest
}) => {
  const handleChange = useCallback(
    (date: Date | null, inputValue) => {
      if (date && !isNaN(date.getTime())) {
        onChange(format(date, dateFieldReduxFormatString));
      }

      if (date === null) {
        onChange(null);
      }

      onChange(inputValue);
    },
    [onChange]
  );

  return (
    <KeyboardDatePicker
      autoOk
      fullWidth
      variant="inline"
      format={dateFieldReduxFormatString}
      placeholder="dd/mm/yyyy"
      value={
        value ? parse(value, dateFieldReduxFormatString, new Date()) : null
      }
      disabled={disabled || submitting}
      error={touched && invalid}
      helperText={touched && error}
      onChange={handleChange}
      InputAdornmentProps={{ position: 'end' }}
      // render prop not passing the same props
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      TextFieldComponent={TextField}
      {...inputProps}
      {...rest}
    />
  );
};
