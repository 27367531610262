import React from 'react';
import { Box } from '@material-ui/core';

import { useOurLoansFaq } from 'components/Pages/Faq/useOurLoansFaq';
import { Link } from 'components/Atoms/Link';
import { ExternalLink } from 'components/Atoms/ExternalLink';
import { Text } from 'components/Atoms/Text';
import { commonPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

export const useLoansFaqData = () => {
  const t = useI18n()('LoansPage');
  const ourLoansFaq = useOurLoansFaq();

  return [
    {
      id: 101,
      question: t`What is a share investment?`,
      answer: (
        <Text>
          {t`Share Investors buy shares in a property, entitling them to a share of net rental income and exposure to property price changes when the property is sold.`}
        </Text>
      ),
    },
    {
      id: 102,
      question: t`What is a loan investment?`,
      answer: (
        <Text>
          {t`Loan Investors provide a portion of a secured loan against a property. Interest accrues daily and is usually paid monthly during the term of the investment. Investors receive their capital back once the loan matures and/or the property is sold.`}
        </Text>
      ),
    },
    {
      id: 103,
      question: t`How does it work?`,
      answer: (
        <>
          <Text paragraph>
            {t`British Pearl enables people to invest in buy to let and development projects, as either shareholders, lenders, or both.`}
          </Text>
          <Text>
            {t`We source and manage all our properties, taking the pain out of property investing and management. Our robust, easy-to-use platform gives you access to an exciting market, plus flexibility and control over your investment.`}
          </Text>
        </>
      ),
    },
    {
      id: 104,
      question: t`What will I earn?`,
      answer: (
        <Text>
          {t`We can’t guarantee what your earnings will be. Each of our properties has a unique share and loan return, based on a number of factors including loan-to-value. For our latest returns, check out our site.`}
        </Text>
      ),
    },
    {
      id: 105,
      question: t`What is the British Pearl IFISA? `,
      answer: (
        <>
          <Text paragraph>
            {t`With a British Pearl ISA, you can invest savings of up to £20,000 p.a. and transfer your existing ISAs tax free in loans secured against UK property. Just like a bank providing a mortgage, your capital is secured with a first charge.`}
          </Text>
          <Text paragraph>
            {t`At the moment, it’s only available for our loan investments.`}
          </Text>
          <Text paragraph>
            <ExternalLink href="https://www.gov.uk/individual-savings-accounts">
              {t`Find out more about our IFISA.`}
            </ExternalLink>
          </Text>
          <Text>
            <Box component="span" fontWeight="bold">
              {t`Capital at risk. No FSCS on invested sums. Tax treatment depends on individual circumstances and may change. Before investing `}
              <Link to={commonPaths.keyRisks()}>{t`read more`}</Link>
            </Box>
          </Text>
        </>
      ),
    },
    {
      id: 106,
      question: t`Can I access my money early?`,
      answer: (
        <Text>
          {t`Yes. Just because it’s a property investment doesn’t mean it has to be illiquid. If you need to access your money before you investment matures, you can sell your loans or shares on to other investors in the Resale Market. This is subject to demand and fees.`}
        </Text>
      ),
    },
    ...ourLoansFaq,
  ];
};
