// TODO this component will be replaced in future by extending implementation of Molecules/HeroHeader
import React from 'react';

import styled, { css } from 'styled-components';

import { Img } from 'components/Atoms/Img';
import Config from 'config';

import media from 'utils/responsive';
import { isServerSide } from 'utils/isSSR';

import Content from './Content';
import Overlay from './Overlay';
import Video from './Video';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ spacing }) => (spacing ? `${spacing / 1.75}rem` : 0)};
  position: relative;
  width: 100%;
  
  ${({ noGradient }) =>
    noGradient ||
    css`
      &::before {
        background: linear-gradient(
          135deg,
          ${Config.theme.colors.loans} 0%,
          ${Config.theme.colors.bostonBlue} 50%,
          ${Config.theme.colors.shares} 100%
        );
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.75;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        ${media.large`
          min-height: ${({ formHero }) => (formHero ? 'auto' : '40rem')};
        `};
      }
    `}

  ${media.medium`
    margin-bottom: ${({ spacing }) => (spacing ? `${spacing / 1.5}rem` : 0)};
  `}

  ${media.large`
    height: ${({ formHero, noCurve }) =>
      formHero ? 'auto' : (noCurve && '40rem') || '36.375rem'};
    margin-bottom: ${({ spacing }) => (spacing ? `${spacing}rem` : 0)};
  `}
`;

const HeroImage = styled(Img)`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @supports (object-fit: cover) {
    height: 100%;
  }
`;

const Curve = styled.svg`
  width: 100%;
  bottom: ${({ formHero }) => (formHero ? 0 : '-1.25rem')};
  position: ${({ formHero }) => (formHero ? 'relative' : 'absolute')};
  z-index: 10;

  ${media.large`
    bottom: ${({ formHero }) => (formHero ? 0 : '-6.25rem')};
  `};
`;

class Hero extends React.PureComponent {
  state = {
    showVideo: false,
  };

  toggleVideo = () => {
    if (!isServerSide()) {
      document.body.classList.toggle('videoLocked');
    }
    this.setState(prevState => ({ showVideo: !prevState.showVideo }));
  };

  render() {
    const {
      button,
      children,
      className,
      curveColor,
      formHero,
      heading,
      homepage,
      image,
      imagePosition,
      noGradient,
      noCurve,
      overlay,
      overlayOffset,
      subheading,
      video,
    } = this.props;
    const { showVideo } = this.state;

    return (
      <Wrapper
        className={className}
        formHero={formHero}
        homepage={homepage}
        noGradient={noGradient}
        noCurve={noCurve}
        showVideo={showVideo}
        spacing={video ? 14 : overlayOffset}
      >
        <HeroImage
          alt={`${heading}`}
          noGradient={noGradient}
          position={imagePosition}
          sizes="100vw"
          src={image}
        />

        {(heading || subheading || button || overlay || children) && (
          <Content
            button={button}
            formHero={formHero}
            heading={heading}
            homepage={homepage}
            overlay={overlay}
            subheading={subheading}
          >
            {children}
          </Content>
        )}

        {(overlay || video) && (
          <Overlay
            offset={video ? 12 : overlayOffset}
            overlay={overlay}
            showVideo={showVideo}
            toggleVideo={this.toggleVideo}
            videoImage={video && video.image}
          />
        )}

        {showVideo && (
          <Video
            showVideo={showVideo}
            toggleVideo={this.toggleVideo}
            url={video && video.url}
          />
        )}

        {noCurve || (
          <Curve
            fill={curveColor || Config.theme.colors.white}
            formHero={formHero}
            preserveAspectRatio="none"
            viewBox="0 0 1000 100"
          >
            <path d="M 0 0 c 0 0 200 50 500 50 s 500 -50 500 -50 v 101 h -1000 v -100 z" />
          </Curve>
        )}
      </Wrapper>
    );
  }
}

Hero.defaultProps = {
  button: null,
  children: null,
  className: '',
  curveColor: '',
  formHero: false,
  heading: '',
  homepage: false,
  imagePosition: '',
  left: false,
  noGradient: false,
  overlay: null,
  overlayOffset: 0,
  subheading: '',
  video: null,
};

export default Hero;
