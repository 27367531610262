import React from 'react';

import styled from 'styled-components';

import { Img as ImgBase } from 'components/Atoms/Img';
import { PlayButton } from 'components/Atoms/Icons';

import media from 'utils/responsive';

const Wrapper = styled.div`
  align-items: stretch;
  display: flex;
  margin-bottom: ${({ offset }) => (offset ? `-${offset / 2}rem` : 0)};
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  z-index: 50;

  ${media.large`
    margin-bottom: ${({ offset }) => (offset ? `-${offset}rem` : 0)};
  `}
  ${media.wide`
    margin-left: auto;
    margin-right: auto;
    max-width: 88rem;
  `};
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`;

const Img = styled(ImgBase)`
  display: block;
  margin: 0;
  width: 100%;
  min-height: 100%;
`;

const VideoOverlay = styled.div`
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;

  ${media.medium`
    &:hover ${ImgWrapper} {
      transform: scale(1.02);
    }
  `}
`;

const VideoImage = styled.div`
  border-radius: 1.5rem;
  max-width: 44.8125rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${media.large`
    height: 26.6875rem;
    margin: 0;
    width: auto;
  `}
`;

const Play = styled(PlayButton)`
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2));
  height: 4.5rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4.5rem;

  ${media.large`
    height: 5.5rem;
    width: 5.5rem;
  `};
`;

// eslint-disable-next-line react/prop-types
const Overlay = ({ videoImage, overlay, toggleVideo, offset }) => (
  <Wrapper offset={offset}>
    {videoImage ? (
      <VideoOverlay>
        <VideoImage onClick={toggleVideo}>
          <ImgWrapper>
            <Img alt="hero-video" src={videoImage} sizes="100vw" />
          </ImgWrapper>
          <Play />
        </VideoImage>
      </VideoOverlay>
    ) : (
      overlay
    )}
  </Wrapper>
);

export default Overlay;
