import { Pip } from 'services/Pip';
import * as type from './actionTypes';
import {
  AllowedClassificationMethod,
  ClassifyErrorAction,
  ClassifyRequestAction,
  ClassifyRisksAcceptedAction,
  ClassifySuccessAction,
  QuestionAnsweredAction,
  QuestionAnswerPayload,
  QuizAttemptErrorAction,
  QuizAttemptRequestAction,
  QuizAttemptSuccessAction,
  QuizCompleteAction,
  QuizStatusErrorAction,
  QuizStatusRequestAction,
  QuizSuccessAction,
  QuizToggleResultAction,
  ScreeningActionTypes,
} from './types';

export const screeningSelection = (
  classificationMethod: AllowedClassificationMethod | undefined
): ScreeningActionTypes => ({
  type: type.ACCREDITATION_SCREENING,
  payload: classificationMethod,
});

export const questionAnswered = (
  payload: QuestionAnswerPayload
): QuestionAnsweredAction => ({
  type: type.QUESTION_ANSWERED,
  payload,
});

export const toggleQuizResults = (): QuizToggleResultAction => ({
  type: type.TOGGLE_QUIZ_RESULTS,
});

export const quizStatusRequest = (): QuizStatusRequestAction => ({
  type: type.QUIZ_STATUS_REQUEST,
});

export const quizStatusSuccess = (
  payload: Pip.QuizTryInfoView
): QuizSuccessAction => ({
  type: type.QUIZ_STATUS_SUCCESS,
  payload,
});

// TODO type
export const quizStatusError = (errors: unknown): QuizStatusErrorAction => ({
  type: type.QUIZ_STATUS_ERROR,
  payload: errors,
});

export const quizComplete = (): QuizCompleteAction => ({
  type: type.QUIZ_COMPLETE,
});

export const quizAttemptRequest = (
  quizVersion: string,
  classificationMethod: AllowedClassificationMethod
): QuizAttemptRequestAction => ({
  type: type.QUIZ_ATTEMPT_REQUEST,
  payload: {
    quizVersion,
    classificationMethod,
  },
});

export const quizAttemptSuccess = (): QuizAttemptSuccessAction => ({
  type: type.QUIZ_ATTEMPT_SUCCESS,
});

// TODO types
export const quizAttemptError = (errors: unknown): QuizAttemptErrorAction => ({
  type: type.QUIZ_ATTEMPT_ERROR,
  payload: errors,
});

export const classify = (
  classificationMethod: AllowedClassificationMethod,
  esignature: string,
  quizVersion: string
): ClassifyRequestAction => ({
  type: type.CLASSIFY_REQUEST,
  payload: {
    classificationMethod,
    esignature,
    quizVersion,
  },
});

export const classifySuccess = (): ClassifySuccessAction => ({
  type: type.CLASSIFY_SUCCESS,
});

// TODO type
export const classifyError = (errors: unknown): ClassifyErrorAction => ({
  type: type.CLASSIFY_ERROR,
  payload: errors,
});

export const classifySetRisksAccepted = (
  accepted: boolean
): ClassifyRisksAcceptedAction => ({
  type: type.CLASSIFY_SET_RISKS_ACCEPTED,
  payload: accepted,
});
