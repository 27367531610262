import React from 'react';

import Config from 'config';

const Briefcase = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill="none">
      <path
        d="M10.95 16.014c0 .024.02.042.043.042h2.014c.024 0 .042-.018.042-.042v-2.56h-2.1v2.56zm2.057.965h-2.014c-.533 0-.965-.438-.965-.97v-3.48h3.944v3.482c0 .533-.432.965-.965.965z"
        fill={fill2}
      />
      <path
        d="M10.49 14.545H3.44c-1.135 0-2.055-.92-2.055-2.055H.462c0 1.645 1.333 2.98 2.98 2.98h7.048v-.925zm12.125-2.055c0 1.135-.92 2.055-2.056 2.055h-7.05v.924h7.05c1.64 0 2.973-1.34 2.973-2.98h-.923z"
        fill={fill2}
      />
      <path
        d="M22.07 21.09c.3 0 .545-.244.545-.545V6.448c0-.3-.244-.546-.545-.546H1.93c-.3 0-.545.245-.545.546v14.097c0 .3.244.546.545.546h20.14zm0 .924H1.93c-.81 0-1.468-.658-1.468-1.47V6.45c0-.81.657-1.47 1.468-1.47h20.14c.81 0 1.468.66 1.468 1.47v14.097c0 .81-.657 1.47-1.468 1.47z"
        fill={fill}
      />
      <path
        d="M9.44 5.44V3.428c0-.024.02-.042.043-.042h5.034c.024 0 .042.018.042.042V5.44h.92V3.428c0-.534-.43-.965-.968-.965h-5.03c-.535 0-.967.43-.967.965V5.44h.924z"
        fill={fill}
      />
    </g>
  </svg>
);

Briefcase.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default Briefcase;
