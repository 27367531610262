import React, { FC } from 'react';
import { FormControl, RadioGroup } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import { FormControlProps } from '@material-ui/core/FormControl';

export type RadioGroupFieldReduxProps = WrappedFieldProps &
  Pick<FormControlProps, 'margin' | 'disabled'>;

export const RadioGroupFieldRedux: FC<RadioGroupFieldReduxProps> = ({
  input,
  children,
  margin = 'normal',
  disabled,
  ...rest
}) => {
  return (
    <FormControl margin={margin} disabled={disabled}>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};
