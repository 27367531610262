import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GLOBAL_MODAL_NAMES, selectors as globalSelectors } from 'store/App';
import { createStructuredSelector } from 'reselect';
import { ReaccreditationDialogContainer } from './ReaccreditationDialogContainer';

const modalComponentsByName = {
  [GLOBAL_MODAL_NAMES.reaccreditation]: ReaccreditationDialogContainer,
};

const GlobalModalRaw = ({ name }) => {
  const ModalComponent = modalComponentsByName[name];
  return ModalComponent ? <ModalComponent /> : null;
};

GlobalModalRaw.propTypes = {
  name: PropTypes.oneOf(Object.values(GLOBAL_MODAL_NAMES)),
};

GlobalModalRaw.defaultProps = {
  name: null,
};

const GlobalModal = connect(
  createStructuredSelector({
    name: globalSelectors.selectModal,
  })
)(GlobalModalRaw);

export { GlobalModalRaw, GlobalModal };
