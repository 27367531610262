import { createSelector } from 'reselect';

import { Pip } from 'services/Pip';

import { NOTICES_STORE_KEY } from './constants';
import { NoticesState } from './types';

const selectNoticesState = (state: AppState): NoticesState =>
  state.get(NOTICES_STORE_KEY);

export const selectNoticesById = createSelector(
  selectNoticesState,
  notices => notices.byId
);

export const selectNoticesAllIds = createSelector(
  selectNoticesState,
  notices => notices.allIds
);

export const selectNoticesListState = createSelector(
  selectNoticesAllIds,
  selectNoticesById,
  (allIdsState, noticesById) => ({
    ...allIdsState,
    data: allIdsState.data
      .reduce<Pip.NoticeView[]>((noticesList, noticeId) => {
        const noticeState = noticesById[noticeId];

        if (noticeState && noticeState.data) {
          noticesList.push(noticeState.data);
        }

        return noticesList;
      }, [])
      .sort((a, b) => b.id - a.id),
  })
);
