import React from 'react';
import { Box } from '@material-ui/core';

import { Crane } from 'components/Atoms/Icons';
import { Error as Template } from 'components/Templates';
import { i18nConnect } from 'utils/i18nConnect';
import { ButtonLink } from 'components/Atoms/Button';
import { commonPaths } from 'router/paths';

const ErrorNotFound = ({ i18n }) => (
  <Template
    description={i18n('ErrorNotFound')`Page not found`}
    Icon={Crane}
    message={i18n('ErrorNotFound')`Page not found`}
    title={i18n('ErrorNotFound')`Not found`}
  >
    <Box my={4} textAlign="center">
      <ButtonLink to={commonPaths.home()} color="secondary" variant="contained">
        {i18n('ErrorNotFound')`Go to homepage`}
      </ButtonLink>
    </Box>
  </Template>
);

export default i18nConnect(ErrorNotFound);
