import { Pip } from 'services/Pip';

import {
  NOTICES_GET_ERROR,
  NOTICES_GET_REQUEST,
  NOTICES_GET_SUCCESS,
} from './actionTypes';
import {
  GetNoticesErrorAction,
  GetNoticesRequestAction,
  GetNoticesSuccessAction,
} from './types';

export const getNoticesRequest = (): GetNoticesRequestAction => ({
  type: NOTICES_GET_REQUEST,
});

export const getNoticesSuccess = (
  data: Pip.NoticeView[]
): GetNoticesSuccessAction => ({
  type: NOTICES_GET_SUCCESS,
  payload: data,
});

export const getNoticesError = (error: Error): GetNoticesErrorAction => ({
  type: NOTICES_GET_ERROR,
  payload: error,
});
