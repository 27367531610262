/**
 * This is simple version of [lexich/redux-api](https://raw.githubusercontent.com/lexich/redux-api)
 * Provides only API for fetch/request data, without redux things...
 */

import { merge } from 'lodash';

import cacheManager from './cache';

import endpoint from './endpoint';

import transformers from './transformers';

/**
 * Default configuration for each endpoint
 */
const defaultEndpointConfig = {
  transformer: transformers.object,
};

function Api(configuration) {
  const config = configuration || {};

  const fetchHolder = {
    fetch: null,
    rootUrl: null,
    options: {},
    responseHandler: null,
  };

  const cfg = {
    use(key, value) {
      fetchHolder[key] = value;

      return this;
    },
  };

  const initEndpoint = value => {
    const opts =
      typeof value === 'object'
        ? { ...defaultEndpointConfig, ...value }
        : { ...defaultEndpointConfig, url: value };

    const {
      url,
      urlOptions,
      options,
      transformer,
      prefetch,
      postfetch,
      validation,
      helpers,
    } = opts;

    const fetch = opts.fetch
      ? opts.fetch
      : (...args) => {
          if (!fetchHolder.fetch) {
            throw Error('Fetch adapter not set');
          }

          return fetchHolder.fetch(...args);
        };

    const meta = {
      holder: fetchHolder,
      cache: cacheManager(opts.cache),
      urlOptions,
      fetch,
      prefetch,
      postfetch,
      validation,
      helpers,
      transformer,
    };

    return endpoint(url, options, meta);
  };

  return Object.keys(config).reduce(
    (acc, key) => merge(acc, { [key]: initEndpoint(config[key]) }),
    cfg
  );
}

Api.transformers = transformers;

export class ServerError extends Error {
  constructor(message, args = {}) {
    super(message);

    this.id = message;
    this.values = args;
  }
}

export default Api;
