import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import styled from 'styled-components';

import { makeSelectIsSubmitting } from 'store/SessionProvider/selectors';
import { actions as sessionActions } from 'store/SessionProvider';

import { ErrorMessages } from 'components/Atoms/ErrorMessages';
import { scrollToFirstError } from 'utils/forms';
import { i18nConnect } from 'utils/i18nConnect';
import { ButtonSubmit } from 'components/Atoms/Button';

import { dispatchPromise } from 'utils/saga';

import { PasswordFieldRedux } from 'components/Atoms/Form';
import { Box, Grid } from '@material-ui/core';
import { Link } from 'components/Atoms/Link';
import { loginPaths } from 'router/paths';

import validate from './validate';

const StyledForm = styled.form`
  width: 100%;
  margin: 32px auto;
  max-width: 40rem;
  text-align: left;
`;

export class ForgotPasswordChangeForm extends React.PureComponent {
  render() {
    const {
      className,
      handleSubmit,
      submitting,
      error,
      isSubmitting,
      i18n,
    } = this.props;

    return (
      <StyledForm
        className={className}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <ErrorMessages errors={error} />
        <Field
          name="token"
          component="input"
          type="hidden"
          autoComplete="off"
        />
        <Field
          component={PasswordFieldRedux}
          showStrengthChecker
          label={i18n('ForgotPasswordChangeForm')`New password`}
          name="password"
          autoComplete="off"
        />
        <Field
          component={PasswordFieldRedux}
          label={i18n('ForgotPasswordChangeForm')`Repeat your new password`}
          id="repeatPassword"
          autoComplete="off"
          name="repeatPassword"
        />
        <Box mt={4}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Link to={loginPaths.login()} data-test-id="login-link">
                {i18n('ForgotPasswordChangeForm')`Back to log in`}
              </Link>
            </Grid>
            <Grid item>
              <ButtonSubmit
                // TODO: https://britishpearl.atlassian.net/browse/PIT-1887
                loading={isSubmitting || submitting}
              >
                {i18n('ForgotPasswordChangeForm')`Change password`}
              </ButtonSubmit>
            </Grid>
          </Grid>
        </Box>
      </StyledForm>
    );
  }
}

ForgotPasswordChangeForm.defaultProps = {
  invalid: false,
  pristine: true,
  submitting: false,
};

const onSubmit = (values, dispatch, { history }) => {
  const { token, password } = values.toJS();

  dispatchPromise(
    dispatch,
    sessionActions.forgotPasswordChangeRequest({ token, password })
  ).then(() => history.push('/login'));
};

const mapStateToProps = createStructuredSelector({
  isSubmitting: makeSelectIsSubmitting(),
});

export default withRouter(
  connect(mapStateToProps)(
    i18nConnect(
      reduxForm({
        form: 'ForgotPasswordChangeForm',
        validate,
        onSubmit,
        onSubmitFail: scrollToFirstError,
      })(ForgotPasswordChangeForm)
    )
  )
);
