import { fromJS } from 'immutable';

import { LOGOUT } from 'store/SessionProvider/actionTypes';

import * as type from './actionTypes';

const initialState = fromJS({
  data: {},
  error: null,
  submitting: false,
});

export const investorWithdrawalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case type.INVESTOR_WITHDRAWALS_FIND_REQUEST:
      return state.set('error', null).set('submitting', true);
    case type.INVESTOR_WITHDRAWALS_FIND_SUCCESS:
      return state
        .update('data', data =>
          data.set(String(action.accountId), fromJS(action.data))
        )
        .set('error', null)
        .set('submitting', false);
    case type.INVESTOR_WITHDRAWALS_FIND_ERROR:
      return state
        .set('submitting', false)
        .set('error', fromJS(action.errors || { _error: 'Error' }));
    case type.INVESTOR_WITHDRAWALS_CANCEL_REQUEST:
      return state.set('submitting', true).set('error', null);
    case type.INVESTOR_WITHDRAWALS_CANCEL_SUCCESS:
      return state.set('submitting', false);
    case type.INVESTOR_WITHDRAWALS_CANCEL_ERROR:
      return state
        .set('submitting', false)
        .set('error', fromJS(action.errors || { _error: 'Error' }));
    default:
      return state;
  }
};
