import React from 'react';

const Close = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <path
      clipRule="evenodd"
      d="M3.4286 0L0 3.4286 8.5714 12 0 20.5714 3.4286 24 12 15.4286 20.5714 24 24 20.5714 15.4286 12 24 3.4286 20.5714 0 12 8.5714 3.4286 0z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

Close.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Close;
