import React from 'react';

import Config from 'config';

const Download = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 34" width={width} {...props}>
    <g fill={fill}>
      <path d="M24.105 29.9626H.19v3.9732h23.915v-3.9732zm-.3962-15.8895c-.6132-.6134-1.608-.6134-2.2208 0l-7.7898 7.7903V1.5712C13.6982.703 12.994 0 12.127 0c-.8677 0-1.5707.7032-1.5707 1.5714v20.2923l-7.685-7.6847c-.6134-.614-1.6078-.614-2.2212 0-.6142.6134-.6142 1.608 0 2.2217l10.3661 10.366a1.532 1.532 0 0 0 .1152.1048c.0182.0148.0371.027.056.0421.0225.0156.0446.034.0668.0494.0233.015.046.0273.0685.0416.021.013.0417.0252.0635.0367.024.0134.0476.0239.0713.0352.0232.0107.0455.0217.0678.0316.0234.0095.047.0172.0706.0251.025.0091.0496.0187.0747.0261.0242.0072.0472.0124.0711.0184.0259.0055.0513.014.0776.0187.0275.005.0553.008.0829.013.0232.0035.046.0076.0692.01.103.01.207.01.31 0 .024-.0024.0458-.0065.0692-.01.0273-.005.0558-.007.0824-.013.026-.005.052-.0132.0776-.0187.0244-.006.0476-.0112.0716-.0184.0251-.0074.0495-.017.0747-.0261a.6063.6063 0 0 0 .0706-.0251 1.4642 1.4642 0 0 0 .0678-.0316c.0239-.0115.0476-.022.071-.0352.0219-.0115.0424-.025.0633-.0367.0234-.0143.0462-.0263.0687-.0416.023-.0154.0452-.0338.0673-.0494.0182-.0148.0373-.027.0558-.0421a1.6692 1.6692 0 0 0 .1661-.1604c.0182-.016.037-.0314.0549-.0486l10.3661-10.3667c.6137-.6149.6137-1.6095.0003-2.2227z" />
    </g>
  </svg>
);

Download.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 34,
  width: 24,
};

export default Download;
