import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, typography, palette }) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      position: 'relative',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    userName: {
      textAlign: 'right',
      fontWeight: typography.fontWeightBold,
      color: palette.primary.main,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    account: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      lineHeight: '1rem',
      paddingBottom: spacing(1.5),
      '&:last-of-type': {
        marginLeft: spacing(2),
      },
    },
  })
);
