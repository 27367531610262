import React from 'react';

const HandCoins = props => (
  <svg viewBox="0 0 39 39" fill="none" {...props}>
    <path
      d="M18.0767 26.0554C18.3599 25.7717 18.3599 25.3124 18.0767 25.0286C17.7931 24.7457 17.3339 24.7457 17.0501 25.0286C16.7672 25.3124 16.7672 25.7717 17.0501 26.0554C17.3339 26.3384 17.7931 26.3384 18.0767 26.0554Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M14.6406 8.8327C14.6406 13.2335 18.221 16.8141 22.6218 16.8141C27.0225 16.8141 30.6029 13.2335 30.6029 8.8327C30.6029 4.4319 27.0225 0.851562 22.6218 0.851562C18.221 0.851562 14.6406 4.4319 14.6406 8.8327ZM29.1519 8.8327C29.1519 12.4334 26.2225 15.3629 22.6218 15.3629C19.021 15.3629 16.0916 12.4334 16.0916 8.8327C16.0916 5.23223 19.021 2.30281 22.6218 2.30281C26.2225 2.30281 29.1519 5.23223 29.1519 8.8327Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M9.35727 37.7968C9.6385 38.0803 10.0964 38.0835 10.3813 37.8028L13.899 34.3373C14.4966 33.7397 14.6783 32.8548 14.4155 32.0857L15.1722 31.356C15.5796 30.9625 16.1159 30.7456 16.6829 30.7456H26.3285C28.0397 30.7456 29.6571 30.0884 30.8832 28.8952C30.9337 28.8461 30.5002 29.3567 37.4636 21.0343C38.4964 19.8124 38.3419 17.9775 37.1189 16.9436C35.9035 15.9193 34.0828 16.0665 33.0472 17.2674L28.7658 21.6679C28.2251 21.0025 27.4007 20.5877 26.51 20.5877H18.4191C17.2678 20.1061 16.0462 19.8623 14.7858 19.8623C11.2919 19.8623 8.24112 21.476 6.6039 24.5015C5.91414 24.3708 5.18668 24.5794 4.66334 25.1028L1.21171 28.5646C0.929905 28.8473 0.929338 29.3046 1.21057 29.5881L9.35727 37.7968ZM14.7855 21.3132C15.8968 21.3132 16.9713 21.5369 17.9797 21.978C18.0715 22.0183 18.1705 22.039 18.2706 22.039H26.51C27.2967 22.039 27.9612 22.6806 27.9612 23.4899C27.9612 24.2903 27.3103 24.9412 26.51 24.9412H20.5902C20.1896 24.9412 19.8647 25.2661 19.8647 25.6667C19.8647 26.0675 20.1896 26.3922 20.5902 26.3922H26.51C28.1103 26.3922 29.4122 25.0903 29.4122 23.4899C29.4122 23.3624 29.4031 23.2359 29.3866 23.1106C33.5283 18.8533 34.1123 18.2551 34.1373 18.2253C34.6544 17.6149 35.5724 17.5376 36.183 18.0524C36.7943 18.5692 36.8716 19.4866 36.3531 20.1004L29.8453 27.8802C28.8951 28.7926 27.6474 29.2946 26.3285 29.2946H16.6829C15.7378 29.2946 14.843 29.6561 14.1643 30.3118L13.5477 30.9069L7.8635 25.223C9.19199 22.7307 11.6848 21.3132 14.7855 21.3132ZM5.69018 26.1282C5.92945 25.8889 6.29687 25.8458 6.58859 26.0262C6.71418 26.1027 6.35357 25.7744 12.873 32.2847C13.163 32.5747 13.1474 33.0369 12.8769 33.3073L9.87806 36.2617L2.74885 29.078L5.69018 26.1282Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M21.7506 4.47967V5.32989C20.906 5.62927 20.2993 6.43583 20.2993 7.38187C20.2993 8.58194 21.2757 9.5586 22.476 9.5586C22.8761 9.5586 23.2015 9.88406 23.2015 10.2841C23.2015 10.6841 22.8761 11.0096 22.476 11.0096C22.1662 11.0096 21.8314 10.8148 21.534 10.4607C21.276 10.154 20.8184 10.1145 20.5117 10.3722C20.2049 10.6302 20.1652 11.0878 20.4232 11.3946C20.811 11.8558 21.2672 12.1773 21.7506 12.3395V13.1863C21.7506 13.5872 22.0754 13.9118 22.476 13.9118C22.8766 13.9118 23.2015 13.5872 23.2015 13.1863V12.3361C24.0461 12.0364 24.6528 11.2298 24.6528 10.2841C24.6528 9.08373 23.6764 8.10735 22.476 8.10735C22.076 8.10735 21.7506 7.7819 21.7506 7.38187C21.7506 6.98185 22.076 6.65611 22.476 6.65611C22.7332 6.65611 23.0045 6.78737 23.2608 7.03544C23.5488 7.31412 24.0081 7.30646 24.2868 7.01871C24.5654 6.73067 24.5578 6.2714 24.27 5.99272C23.9018 5.63636 23.5332 5.43479 23.2015 5.32479V4.47967C23.2015 4.0788 22.8766 3.75391 22.476 3.75391C22.0754 3.75391 21.7506 4.0788 21.7506 4.47967Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default HandCoins;
