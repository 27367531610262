import Color from 'color';

import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import Config from 'config';
import { ResaleTables as ResaleTablesBase } from 'components/Molecules';
import { i18nConnect } from 'utils/i18nConnect';

import media from 'utils/responsive';
import Box from '@material-ui/core/Box';

const Wrapper = styled.div`
  background-color: ${({ type }) =>
    Color(
      type === 'Shares' ? Config.theme.colors.shares : Config.theme.colors.loans
    )
      .fade(0.85)
      .string()};
  border-radius: 1rem;
  display: ${({ summary }) => (summary ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  margin-bottom: 1rem;
  min-height: 7.1rem;
  padding: 1rem;

  ${media.medium`
    padding: 1.5rem;
    margin-bottom: 1rem;
  `} ${media.large`
    display: flex;
  `};
`;

const Title = styled.div`
  color: ${({ type }) =>
    type === 'Shares' ? Config.theme.colors.shares : Config.theme.colors.loans};
  font-size: 1rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 1;
  margin-bottom: 0.5rem;

  ${media.medium`
    font-size: 1.25rem;
  `};
`;

const Details = styled.div`
  display: flex;
  flex-flow: wrap;
`;

const Item = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  margin-bottom: 0;
  width: 50%;

  &:not(:last-child) {
    border-right: 1px solid
      ${({ type }) =>
        Color(
          type === 'Shares'
            ? Config.theme.colors.shares
            : Config.theme.colors.loans
        )
          .fade(0.85)
          .string()};
  }

  ${media.small.only`
    padding: .25rem 1rem .25rem 0;

    &:nth-child(2n) {
      padding-left: 1rem;
      padding-right: 0;
      border: 0;
    }
  `} ${media.medium`
    font-size: 1.25rem;
    padding: 0 1rem;
    width: 25%;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  `};
`;

const Label = styled.span`
  font-size: 0.875rem;
  line-height: 1;
  margin-top: -0.25rem;
`;

const EmptyState = styled.p`
  font-size: 1rem;
  text-align: center;
  width: 100%;
`;

const Section = styled.div`
  ${media.wide`
    width: 50%;

    &:first-child {
      margin-right: .75rem;
    }

    &:last-child {
      margin-left: .75rem;
    }

    &:only-child {
      margin-left: 0;
      margin-right: 0;
    }
  `};
`;

const valueFormatting = (i18n, type, key, value) => {
  switch (`${key}${type}`) {
    case 'allocatedProfit':
    case 'assetQuantityLoans':
    case 'indicativeUnitPrice':
    case 'indicativeValuation':
    case 'accruedInterest':
      return i18n('InvestmentDetails')`${value}:c`;
    case 'assetQuantityShares':
      return i18n('InvestmentDetails')`${value}:n`;
    default:
      break;
  }

  if (type) {
    return valueFormatting(i18n, '', key, value);
  }

  return i18n('InvestmentDetails')`${value}:n`;
};

const labelFormatting = (i18n, type, key) => {
  switch (key) {
    case 'allocatedProfit':
      return type === 'Loans'
        ? i18n('InvestmentDetails')`Interest received`
        : i18n('InvestmentDetails')`Dividends received`;
    case 'assetQuantity':
      return type === 'Loans'
        ? i18n('InvestmentDetails')`Principal`
        : i18n('InvestmentDetails')`Quantity`;
    case 'indicativeUnitPrice':
      return i18n('InvestmentDetails')`Indicative price`;
    case 'indicativeValuation':
      return i18n('InvestmentDetails')`Indicative valuation`;
    case 'accruedInterest':
      return i18n('InvestmentDetails')`Interest accrued`;
    default:
      break;
  }

  return null;
};

const sharesOrder = [
  'assetQuantity',
  'indicativeUnitPrice',
  'allocatedProfit',
  'indicativeValuation',
];

const loansOrder = [
  'assetQuantity',
  'accruedInterest',
  'allocatedProfit',
  'indicativeValuation',
];

const labelSorter = (a, b, type) => {
  const order = type === 'Shares' ? sharesOrder : loansOrder;

  return order.indexOf(a) - order.indexOf(b);
};

const getData = type => {
  const isShares = type === 'Shares';
  return [
    {
      type: isShares ? 'shares' : 'loans',
      lookup1: isShares ? 'shareBid' : 'loanBid',
      lookup2: isShares ? 'shareOffer' : 'loanOffer',
    },
  ];
};

const hasMarketplace = data => {
  if (
    data.shareBid.length ||
    data.shareOffer.length ||
    data.loanBid.length ||
    data.loanOffer.length
  ) {
    return true;
  }

  return false;
};

const Investments = ({ item, type, label, i18n, accountId }) => {
  const itemObj = item.toJS();
  const summary = itemObj[`${type.toLowerCase().slice(0, -1)}Summary`];
  const order = type === 'Shares' ? sharesOrder : loansOrder;

  return (
    <Section>
      <Wrapper summary={summary} type={type}>
        <Title type={type}>{type}</Title>

        <Details label={label}>
          {summary ? (
            Object.keys(summary)
              .filter(key => order.includes(key))
              .sort((a, b) => labelSorter(a, b, type))
              .map(key => (
                <Item key={shortid.generate()} type={type}>
                  {valueFormatting(i18n, type, key, summary[key])}

                  <Label>{labelFormatting(i18n, type, key)}</Label>
                </Item>
              ))
          ) : (
            <EmptyState>
              {type === 'Shares'
                ? i18n('InvestmentDetails')`You have no share investments`
                : i18n('InvestmentDetails')`You have no loan investments`}
            </EmptyState>
          )}
        </Details>
      </Wrapper>

      {hasMarketplace(item.get('marketplaceOfAccount').toJS()) && (
        <Box mb={2}>
          <ResaleTablesBase
            accountId={accountId}
            data={getData(type)}
            i18n={i18n}
            isAccountResale
            item={item}
            marketplace={item.get('marketplaceOfAccount')}
            suspended={item.get('investmentSuspended')}
          />
        </Box>
      )}
    </Section>
  );
};

export default i18nConnect(Investments);
