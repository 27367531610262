import i18n from 'es2015-i18n-tag';

import Config from 'config';
import {
  DoubleRosette,
  Email,
  Person,
  Wallet,
} from 'components/Atoms/NewIcons';

export const confirmEmailItem = {
  id: 'confirmEmailItem',
  title: i18n(
    'molecules.KeyItemsSection.confirmEmailItem.title'
  )`Confirm email`,
  color: Config.theme.colors.mangoTango,
  description: i18n(
    'molecules.KeyItemsSection.confirmEmailItem.description'
  )`Confirm your email address to start the account opening process`,
  Icon: Email,
};

export const accreditationItem = {
  id: 'accreditationItem',
  title: i18n(
    'molecules.KeyItemsSection.accreditationItem.title'
  )`Accreditation`,
  color: Config.theme.colors.asparagus,
  description: i18n(
    'molecules.KeyItemsSection.accreditationItem.description'
  )`It is a legal requirement to confirm what type of investor you are and ensure our investments are suitable`,
  Icon: DoubleRosette,
};

export const verifyIdItem = {
  id: 'verifyIdItem',
  title: i18n('molecules.KeyItemsSection.verifyIdItem.title')`Verify ID`,
  color: Config.theme.colors.bostonBlue,
  description: i18n(
    'molecules.KeyItemsSection.verifyIdItem.description'
  )`We have to verify your identity to open your free account and prevent fraud.`,
  Icon: Person,
};

export const fundWalletItem = {
  id: 'fundWalletItem',
  title: i18n('molecules.KeyItemsSection.fundWalletItem.title')`Fund wallet`,
  color: Config.theme.colors.black,
  description: i18n(
    'molecules.KeyItemsSection.fundWalletItem.description'
  )`Securely fund your wallet via debit card or bank transfer`,
  Icon: Wallet,
};

export const accreditationAndVerifyIdItem = {
  id: 'accreditationAndVerifyId',
  title: i18n(
    'molecules.KeyItemsSection.accreditationAndVerifyId.title'
  )`Accreditation & verify ID`,
  color: Config.theme.colors.asparagus,
  description: i18n(
    'molecules.KeyItemsSection.accreditationAndVerifyId.description'
  )`It is a legal requirement to confirm what type of investor you are and verify your identity to open your free account.`,

  Icon: DoubleRosette,
};

export const openIsaItem = {
  id: 'openIsaItem',
  title: i18n(
    'molecules.KeyItemsSection.accreditationAndVerifyId.title'
  )`Open ISA`,
  color: Config.theme.colors.bostonBlue,
  description: i18n(
    'molecules.KeyItemsSection.accreditationAndVerifyId.description'
  )`All that is needed is your National Insurance (NI) number to open your ISA.
      You can open it in a second, it is that simple.`,
  Icon: Person,
};

export const fundIsaWalletItem = {
  id: 'fundIsaWalletItem',
  title: i18n('molecules.KeyItemsSection.fundIsaWalletItem.title')`Fund wallet`,
  color: Config.theme.colors.black,
  description: i18n(
    'molecules.KeyItemsSection.fundIsaWalletItem.description'
  )`Securely fund your wallet via debit card or bank transfer. Easily transfer cash from your main account into your
      ISA. When choosing your investment, simply select your ISA.`,
  Icon: Wallet,
};

export const fundAndInvestItem = {
  id: 'fundAndInvestItem',
  title: i18n(
    'molecules.KeyItemsSection.fundIsaWalletItem.title'
  )`Fund & invest`,
  color: Config.theme.colors.black,
  description: i18n(
    'molecules.KeyItemsSection.fundIsaWalletItem.description'
  )`Securely fund your wallet via debit card or bank transfer. Then pick a property to start earning interest.`,
  Icon: Wallet,
};
