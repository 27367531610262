import React from 'react';

import Config from 'config';
import { Generic as Template } from 'components/Templates';
import { MarketingLanding } from 'components/Pages/MarketingLanding';

import useComponents, { templateProps } from './layout';

export const LoansPage = () => {
  const components = useComponents();

  return (
    <Template
      description={templateProps.description}
      title={templateProps.title}
      navLinks={templateProps.navLinks}
      canonicalLink={Config.canonicalLinks.loansLandingPage}
    >
      <MarketingLanding components={components} />
    </Template>
  );
};
