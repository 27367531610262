import * as actions from './actionTypes';

export const investorActivityFindRequest = accountId => ({
  type: actions.INVESTOR_ACTIVITY_FIND_REQUEST,
  accountId,
});

export const investorActivityFindSuccess = (data, accountId) => ({
  type: actions.INVESTOR_ACTIVITY_FIND_SUCCESS,
  data,
  accountId,
});

export const investorActivityFindError = errors => ({
  type: actions.INVESTOR_ACTIVITY_FIND_ERROR,
  error: true,
  payload: errors,
});

const cancelSharesOrLoans = type => (orderId, accountId) => ({
  type,
  payload: {
    accountId,
    orderId,
  },
});

export const investorActivityCancelShares = cancelSharesOrLoans(
  actions.INVESTOR_ACTIVITY_CANCEL_SHARE_BID
);

export const investorActivityCancelLoans = cancelSharesOrLoans(
  actions.INVESTOR_ACTIVITY_CANCEL_LOAN_BID
);

export const investorActivityConfirmCancel = ({
  type,
  orderId,
  accountId,
}) => ({
  type: actions.INVESTOR_ACTIVITY_CANCEL_REQUEST,
  payload: { type, orderId, accountId },
});

export const investorActivityCancelSuccess = () => ({
  type: actions.INVESTOR_ACTIVITY_CANCEL_SUCCESS,
});

export const investorActivityCancelError = () => ({
  type: actions.INVESTOR_ACTIVITY_CANCEL_ERROR,
});
