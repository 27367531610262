import React from 'react';
import PropTypes from 'prop-types';

import { isServerSide } from 'utils/isSSR';

import { getCurrent } from 'utils/responsive';

import {
  Wrapper,
  ImageContainer,
  HeroImage,
  GradientBackground,
  HeroContent,
  ContentContainer,
} from './components';

export const Hero = ({
  image,
  content,
  imageConfig = {},
  currentBreakpoint = isServerSide() ? '' : getCurrent(),
}) => {
  return (
    <Wrapper>
      <ImageContainer parentWidth={imageConfig.parentWidth}>
        <HeroImage
          src={image}
          config={imageConfig}
          currentBreakpoint={currentBreakpoint}
        />
      </ImageContainer>
      <ContentContainer>
        <GradientBackground currentBreakpoint={currentBreakpoint} />
        <HeroContent>{content}</HeroContent>
      </ContentContainer>
    </Wrapper>
  );
};

Hero.propTypes = {
  image: PropTypes.shape({
    height: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    placeholder: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    width: PropTypes.number,
  }).isRequired,
  content: PropTypes.node.isRequired,
  imageConfig: PropTypes.shape({}),
  currentBreakpoint: PropTypes.string,
};

Hero.defaultProps = {
  imageConfig: {},
  currentBreakpoint: '',
};

export default Hero;
