import { call, put, takeLatest } from 'redux-saga/effects';
import { PromiseType } from 'utility-types';

import { Notices } from 'services/Pip';

import { logger } from 'services/Logger';
import { getNoticesSuccess, getNoticesError } from './actions';
import { NOTICES_GET_REQUEST } from './actionTypes';

export function* getNotices() {
  try {
    const notices: PromiseType<ReturnType<typeof Notices.findAll>> = yield call(
      Notices.findAll
    );

    yield put(getNoticesSuccess(notices));
  } catch (error) {
    yield call(logger.error, error);
    yield put(getNoticesError(error));
  }
}

export function* noticesSaga() {
  yield takeLatest(NOTICES_GET_REQUEST, getNotices);
}
