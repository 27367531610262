import { createSelector } from 'reselect';

const select = state => state.get('investorWithdrawals');

export const makeSelectIsSubmitting = () =>
  createSelector(select, state => state.get('submitting'));

export const makeSelectData = accountId =>
  createSelector(select, state => state.getIn(['data', `${accountId}`]));

export const makeSelectError = () =>
  createSelector(select, state => state.get('error'));
