import React from 'react';

import Config from 'config';

const OpenhandGraph = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <path
      d="M9.34237377 21.667275 C9.54118033 21.496275 9.73967213 21.3022875 9.9256918 21.08805 C10.4731279 20.4576 10.8071213 19.7811 10.8071213 19.073925 L10.8071213 17.96805 L10.0202361 17.96805 L10.0202361 19.073925 C10.0202361 19.576275 9.76095738 20.101425 9.31951475 20.60985 C9.15977705 20.7942 8.98744918 20.9625375 8.81512131 21.11055 C8.71321967 21.198225 8.63610492 21.258375 8.59558033 21.28725 L8.43820328 21.39975 L8.43820328 22.4920125 L9.22508852 22.4920125 L9.22508852 21.7656375 C9.2608918 21.7366125 9.30023607 21.7037625 9.34272787 21.667275 L9.34237377 21.667275 Z M4.36670164 12.146025 L2.25718033 13.754475 C1.32039344 14.57235 1.05481967 15.0363 1.05481967 16.05795 L1.05481967 19.47045 L1.0552918 22.5925875 L1.84217705 22.5922125 L1.84170492 19.4699625 L1.84170492 16.0574625 C1.84170492 15.2729625 2.00104918 14.9943375 2.76865574 14.3230875 L4.85783607 12.7308375 L4.36622951 12.1450875 L4.36670164 12.146025 Z"
      fill={fill}
    />
    <path
      d="M9.49286557 22.8670125 L9.49286557 24 L10.2797508 24 L10.2797508 22.1170125 L0 22.1170125 L0 24 L0.786885246 24 L0.786885246 22.8670125 L9.49286557 22.8670125 Z M8.50119344 15.770025 C8.46523279 15.805425 8.42868197 15.84165 8.39158033 15.8785125 C8.18580984 16.0832625 7.97169836 16.30215 7.76156066 16.521 C7.68798689 16.597575 7.62023607 16.668675 7.55972459 16.7325 C7.5232918 16.770975 7.49748197 16.7983875 7.48331803 16.8135 L7.38338361 16.920225 L7.38338361 18.0687 C7.38338361 18.82305 7.07728525 19.3776 6.55518689 19.769925 C6.20187541 20.0355 5.73997377 20.207025 5.58810492 20.207025 L5.58810492 20.957025 C6.67636721 20.957025 8.1706623 19.8344625 8.1706623 18.0687 L8.1706623 17.2074 C8.22338361 17.1520875 8.28082623 17.091675 8.34259672 17.0274 C8.5491541 16.8124125 8.75925246 16.5975 8.96069508 16.397325 C8.9968918 16.3614 9.03230164 16.326225 9.06731803 16.2918375 C9.14856393 16.2115875 9.22607213 16.1365875 9.29937049 16.0668375 L9.32840656 16.0357125 C9.96830164 15.2733375 9.96420984 14.3039625 9.43975082 13.5925875 C8.9053377 12.8677125 7.98822295 12.6607125 7.3395541 13.2790875 L5.36580984 15.1600875 C4.87424262 15.6284625 4.3480918 16.1298375 3.82201967 16.6315875 L3.12051148 17.3002125 L3.67683934 17.8304625 L4.37834754 17.1618375 C4.90438033 16.6600875 5.43041311 16.1587125 5.92158689 15.6907125 C6.84657049 14.8090875 7.53666885 14.1513375 7.89580328 13.8093375 C8.15500328 13.5622125 8.51586885 13.6435875 8.796 14.0234625 C9.12216393 14.4655875 9.12798689 15.0610875 8.7267541 15.5512125 C8.65514754 15.6194625 8.58039344 15.6922125 8.50229508 15.7690875 L8.50119344 15.770025 Z M20.9612852 2.51325 L20.9612852 3.26325 L23.9915803 3.26325 L23.9915803 2.385675 C23.9915803 1.0365 22.8865574 0 21.4886557 0 L19.3791344 0 L19.3791344 0.75 L21.4886557 0.75 C22.4547934 0.75 23.2047344 1.4535 23.2047344 2.3856375 L23.2047344 2.5132875 L20.9613246 2.5132875 L20.9612852 2.51325 Z"
      fill={fill}
    />
    <path
      d="M18.9856918 2.88825 L18.9856918 18.0957375 L7.77678689 18.0957375 L7.77678689 18.8457375 L19.772577 18.8457375 L19.772577 2.88825 C19.772577 1.7075625 20.7772328 0.75 22.0159869 0.75 L22.0159869 0 L7.24941639 0 C5.57602623 0 4.21912131 1.293375 4.21912131 2.88825 L4.21912131 16.4601 L5.00600656 16.4601 L5.00600656 2.88825 C5.00600656 1.7075625 6.01062295 0.75 7.24941639 0.75 L19.9790164 0.75 C19.3688656 1.278375 18.9856918 2.04075 18.9856918 2.88825 Z"
      fill={fill}
    />
    <path
      d="M14.7956852 13.316475 L13.971423 13.316475 L13.971423 14.1021 L13.606977 14.4494625 L13.9713836 14.7967125 L13.9713836 15.5823375 L14.7956459 15.5823375 L15.1600918 15.9295875 L15.5244197 15.5823375 L16.348682 15.5823375 L16.348682 14.7967125 L16.7130098 14.4490875 L16.348682 14.1014625 L16.348682 13.31625 L15.5244197 13.31625 L15.1596984 12.968925 L14.794977 13.31625 L14.7956852 13.316475 Z M15.1600918 11.9085 L15.8501902 12.566475 L17.1351738 12.566475 L17.1351738 13.7914875 L17.8252721 14.4494625 L17.1347803 15.1072125 L17.1347803 16.3319625 L15.8497967 16.3319625 L15.1594623 16.9897125 L14.4689705 16.3315875 L13.1842623 16.3315875 L13.1842623 15.1068375 L12.4941639 14.4489 L13.1844984 13.79085 L13.1844984 12.56625 L14.4697574 12.56625 L15.1600918 11.908275 L15.1600918 11.9085 Z"
      fill={fill}
    />
    <path
      d="M8.73938361 5.388375 L8.73938361 10.3738875 L9.52626885 10.3738875 L9.52626885 5.013375 C9.52626885 4.806225 9.35012459 4.638375 9.13282623 4.638375 L6.97746885 4.638375 C6.76017049 4.638375 6.58402623 4.806225 6.58402623 5.013375 L6.58402623 10.3738875 L7.37091148 10.3738875 L7.37091148 5.388375 L8.73938361 5.388375 Z M12.511318 3.333975 L12.511318 10.37385 L13.2982033 10.37385 L13.2982033 2.958975 C13.2982033 2.7519 13.122059 2.583975 12.9047607 2.583975 L10.7493639 2.583975 C10.5320656 2.583975 10.3559213 2.7519 10.3559213 2.958975 L10.3559213 10.37385 L11.1428066 10.37385 L11.1428066 3.333975 L12.511318 3.333975 Z M16.2832525 6.9291 L16.2832525 10.37385 L17.0701377 10.37385 L17.0701377 6.5541 C17.0701377 6.3469875 16.8939541 6.1791 16.6766951 6.1791 L14.5212984 6.1791 C14.304 6.1791 14.1278557 6.3469875 14.1278557 6.5541 L14.1278557 10.37385 L14.914741 10.37385 L14.914741 6.9291 L16.2832131 6.9291 L16.2832525 6.9291 Z"
      fill={fill}
    />
  </svg>
);

OpenhandGraph.defaultProps = {
  fill: Config.theme.colors.akaroa,
  height: 24,
  width: 24,
};

export default OpenhandGraph;
