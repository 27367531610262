import { createSelector } from 'reselect';

import { InvestmentWithProperty } from 'services/Pip';

import { INVESTMENTS_STORE_KEY } from './constants';
import { InvestmentsState } from './types';

const selectInvestmentsState = (state: AppState): InvestmentsState =>
  state.get(INVESTMENTS_STORE_KEY);

export const selectInvestmentsById = createSelector(
  selectInvestmentsState,
  investments => investments.byId
);

export const selectInvestmentsAllIds = createSelector(
  selectInvestmentsState,
  investments => investments.allIds
);

export const selectInvestmentsAllIdsError = createSelector(
  selectInvestmentsAllIds,
  allIdsState => allIdsState.error
);

export const selectInvestmentsAllIdsLoading = createSelector(
  selectInvestmentsAllIds,
  allIdsState => allIdsState.loading
);
export const selectInvestmentsList = createSelector(
  selectInvestmentsAllIds,
  selectInvestmentsById,
  (allIdsState, investmentsById) =>
    allIdsState.data.reduce<InvestmentWithProperty[]>(
      (investmentsList, investmentId) => {
        const investmentState = investmentsById[investmentId];

        if (investmentState && investmentState.data) {
          investmentsList.push(investmentState.data);
        }

        return investmentsList;
      },
      []
    )
);

export const selectComingSoonInvestmentsList = createSelector(
  selectInvestmentsState,
  state => state.comingSoon
);

export const makeSelectInvestmentByIdState = (id: number) =>
  createSelector(selectInvestmentsById, byIdMap => byIdMap[id]);
