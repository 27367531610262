import React from 'react';

import Config from 'config';

const NewWallet = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 36 38" width={width} {...props}>
    <path
      d="M1.46205 0C0.647743 0 0 0.667272 0 1.46429V5.59768C0 6.41324 0.66625 7.06197 1.46205 7.06197H11.3818V0H1.46205ZM3.51633 5.20844C2.60948 5.20844 1.88771 4.48556 1.88771 3.57732C1.88771 2.66909 2.60948 1.94621 3.51633 1.94621C4.42317 1.94621 5.14494 2.66909 5.14494 3.57732C5.14494 4.48556 4.42317 5.20844 3.51633 5.20844Z"
      fill={fill}
      transform="translate(24.0078 20.6572)"
    />
    <path
      d="M34.7561 8.15556H24.7624C23.0782 8.15556 21.7087 9.52718 21.7087 11.1954V15.4956C21.7087 17.1823 23.0782 18.5539 24.7624 18.5539H34.7561V24.4852C34.7561 25.7086 33.7567 26.7095 32.5353 26.7095H2.22084C0.999377 26.7095 0 25.7086 0 24.4852V2.22424C0 1.00091 0.999377 0 2.22084 0H3.80889L4.52306 1.24231L34.7561 1.35964V8.15556Z"
      fill={fill}
      transform="translate(0.634277 10.8334)"
    />
    <path
      d="M0.798047 9.14286H28.1633L26.2045 1.55305C25.9302 0.487524 24.6766 -0.184576 23.4033 0.0449216L1.85583 3.94638C0.582572 4.17588 -0.220561 5.22501 0.0536795 6.29054L0.798047 9.14286Z"
      fill={fill}
      transform="translate(4.66766 0.971436)"
    />
  </svg>
);

NewWallet.defaultProps = {
  fill: Config.theme.colors.white,
  height: 38,
  width: 36,
};

export default NewWallet;
