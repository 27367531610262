import React from 'react';

import Config from 'config';

const DigitalBook = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 48 48" width={width} {...props}>
    <g fill="none">
      <path
        d="M41.8857 25.0536h-12.656l-1.839 1.8443h-7.012L18.54 25.0533H5.8835V6.5724H7.286v-.8838H5v20.2487h13.173l1.8388 1.8443h7.7458l1.8388-1.8443h13.173V5.6886h-2.286v.8837h1.4023"
        fill={fill}
      />
      <path
        d="M8.6886 22.2487V2h9.6633c3.7336 0 5.9744 2.4654 5.9744 5.9747V24.093h-2.5558l-.1233-.2397c-.5797-1.127-1.5775-1.6046-3.2956-1.6046H8.6885zm9.6633-.8837c1.9066 0 3.181.558 3.9483 1.8443h1.1424V7.9747c0-3.039-1.865-5.091-5.091-5.091H9.5722V21.365h8.7796z"
        fill={fill}
      />
      <path
        d="M38.1972 2.8837h-8.7796c-3.226 0-5.091 2.052-5.091 5.091v15.2346h1.1423c.7672-1.2862 2.0414-1.8443 3.9484-1.8443h8.7796V2.8837zm-8.7796 19.365c-1.718 0-2.716.4775-3.2957 1.6046l-.1236.2397H23.443V7.9747C23.443 4.4654 25.6836 2 29.4175 2h9.6633v20.2487h-9.6634z"
        fill={fill}
      />
      <path
        d="M37.717 44.4186c.774 0 1.4023-.6284 1.4023-1.4024v-6.455c0-.774-.6284-1.4025-1.4024-1.4025h-3.689c-.774 0-1.4024.6285-1.4024 1.4025v6.455c0 .774.6283 1.4024 1.4023 1.4024h3.6886zm0 .8837h-3.6887c-1.262 0-2.286-1.024-2.286-2.286v-6.455c0-1.2622 1.024-2.2863 2.286-2.2863h3.6886c1.262 0 2.286 1.024 2.286 2.2862v6.455c0 1.262-1.024 2.286-2.286 2.286z"
        fill={fill2}
      />
      <path
        d="M36.3145 34.717v-.8858c0-1.7018-1.4363-3.0813-3.2083-3.0813H11.897c-1.284 0-2.3247-.9997-2.3247-2.2328v-2.657h-.8837v2.657c0 1.702 1.4362 3.0813 3.2083 3.0813h21.209c1.284 0 2.3244.9994 2.3244 2.2325v.8858h.8838zm-.8838 1.8442v2.7664h.8838v-2.7664"
        fill={fill2}
      />
    </g>
  </svg>
);

DigitalBook.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 108,
  width: 108,
};

export default DigitalBook;
