import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { Section } from 'components/Molecules/Section';

export const FooterDivider: FC = () => {
  return (
    <Section color="grey">
      <Box height="2px" bgcolor="white" />
    </Section>
  );
};
