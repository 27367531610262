import React from 'react';

const Plus = ({ width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16.9706"
      cy="16.9707"
      r="11"
      transform="rotate(-45 16.9706 16.9707)"
      fill="white"
      stroke="#3D3935"
      strokeWidth="2"
    />
    <rect
      x="15.7891"
      y="9"
      width="2.37477"
      height="15.6735"
      rx="1.18738"
      fill="#3D3935"
    />
    <rect
      x="9.35107"
      y="18.0244"
      width="2.37477"
      height="15.6735"
      rx="1.18738"
      transform="rotate(-90 9.35107 18.0244)"
      fill="#3D3935"
    />
  </svg>
);

Plus.defaultProps = {
  width: 34,
  height: 34,
};

export default Plus;
