import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import { actions as sessionActions } from 'store/SessionProvider';
import { makeSelectIsSubmitting } from 'store/SessionProvider/selectors';
import { ErrorMessages } from 'components/Atoms/ErrorMessages';

import { i18nConnect } from 'utils/i18nConnect';

import { TextFieldRedux } from 'components/Atoms/Form';
import { ButtonSubmit } from 'components/Atoms/Button';
import { Link } from 'components/Atoms/Link';
import { Box, Grid } from '@material-ui/core';
import { loginPaths } from 'router/paths';

import validate from './validate';

export class ForgotPasswordResetForm extends React.PureComponent {
  render() {
    const {
      className,
      handleSubmit,
      submitting,
      error,
      isSubmitting,
      i18n,
    } = this.props;

    return (
      <form className={className} onSubmit={handleSubmit} noValidate>
        <ErrorMessages errors={error} />
        <p>
          {i18n(
            'ForgotPasswordResetForm'
          )`Please enter your email address below to reset your password`}
        </p>
        <Field
          component={TextFieldRedux}
          label={i18n('ForgotPasswordResetForm')`Email`}
          name="email"
          autoComplete="off"
          data-test-id="user-email"
          // TODO: https://britishpearl.atlassian.net/browse/PIT-1887
          disabled={submitting || isSubmitting}
        />
        <Box mt={2}>
          <Grid
            container
            direction="row-reverse"
            s
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <ButtonSubmit
                // TODO: https://britishpearl.atlassian.net/browse/PIT-1887
                loading={submitting || isSubmitting}
              >
                {i18n('ForgotPasswordResetForm')`Reset Password`}
              </ButtonSubmit>
            </Grid>
            <Grid item>
              <Link to={loginPaths.login()}>
                {i18n('ForgotPasswordResetForm')`Back to login`}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </form>
    );
  }
}

ForgotPasswordResetForm.defaultProps = {
  invalid: false,
  pristine: true,
  submitting: false,
};

const onSubmit = (values, dispatch) => {
  dispatch(sessionActions.forgotPasswordResetRequest(values.get('email')));
};

const mapStateToProps = createStructuredSelector({
  isSubmitting: makeSelectIsSubmitting(),
});

export default connect(mapStateToProps)(
  i18nConnect(
    reduxForm({
      form: 'ForgotPasswordResetForm',
      validate,
      onSubmit,
    })(ForgotPasswordResetForm)
  )
);
