import React from 'react';

const Title = ({ className, content }) =>
  content && (
    <span data-test-id={content} className={className}>
      {content}
    </span>
  );

Title.defaultProps = {
  content: null,
};

export default Title;
