import React, { FC } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

import { useStyles } from './OrderedList.styles';

export const OrderedList: FC<TypographyProps & {
  listStyleType?: 'lowerRoman' | 'lowerLatin' | 'legal';
}> = ({ children, variant = 'body2', paragraph, listStyleType }) => {
  const classes = useStyles();
  return (
    <Typography
      component="ol"
      variant={variant}
      paragraph={paragraph}
      className={listStyleType && classes[listStyleType]}
    >
      {children}
    </Typography>
  );
};
