import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { OpenIsaPage } from 'components/Pages/MarketingLanding/pages/isa/noIncentive/open-isa/OpenIsaPage';
import { LoansPage } from 'components/Pages/MarketingLanding/pages/loans/noIncentive/LoansPage';
import { SharesPage } from 'components/Pages/MarketingLanding/pages/shares/noIncentive/SharesPage';

import { commonPaths } from 'router/paths';

export default () => (
  <Switch>
    <Route exact path={commonPaths.landingIsaop()} component={OpenIsaPage} />
    <Route exact path={commonPaths.landingIsatrns()} component={OpenIsaPage} />
    <Route exact path={commonPaths.landingLoans()} component={LoansPage} />
    <Route exact path={commonPaths.landingShares()} component={SharesPage} />
    <Redirect
      exact
      from={commonPaths.isaCashback()}
      to={commonPaths.landingIsaop()}
    />
    <Redirect
      exact
      from={commonPaths.loansCashback()}
      to={commonPaths.landingLoans()}
    />
    <Redirect
      exact
      from={commonPaths.sharesCashback()}
      to={commonPaths.landingShares()}
    />
  </Switch>
);
