/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { reducer as formReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';

import globalReducer from 'store/App/reducer';
import languageProviderReducer from 'store/LanguageProvider/reducer';
import PortfolioReducer from 'store/Portfolio/reducer';
import routeReducer from 'store/Router/reducer';
import sessionProviderReducer from 'store/SessionProvider/reducer';
import { investorWalletReducer } from 'store/InvestorWallet';
import { investorWithdrawalsReducer } from 'store/InvestorWithdrawals';
import {
  ONBOARDING_ACCREDITATION_STORE_KEY,
  onboardingAccreditationReducer,
} from 'store/OnboardingAccreditation';
import { INVESTMENTS_STORE_KEY, investmentsReducer } from 'store/Investments';
import { NOTICES_STORE_KEY, noticesReducer } from 'store/Notices';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    form: formReducer,
    global: globalReducer,
    language: languageProviderReducer,
    session: sessionProviderReducer,
    portfolio: PortfolioReducer,
    route: routeReducer,
    investorWallet: investorWalletReducer,
    investorWithdrawals: investorWithdrawalsReducer,
    [ONBOARDING_ACCREDITATION_STORE_KEY]: onboardingAccreditationReducer,
    [INVESTMENTS_STORE_KEY]: investmentsReducer,
    [NOTICES_STORE_KEY]: noticesReducer,
    ...asyncReducers,
  });
}
