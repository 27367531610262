import { call, put, takeEvery } from 'redux-saga/effects';

import { Portfolio } from 'services/Pip';

import { logger } from 'services/Logger';
import {
  findAllPortfolioPropertiesError,
  findAllPortfolioPropertiesSuccess,
} from './actions';
import * as type from './actionTypes';

export function* getPortfolioPropertyFindAll(id) {
  try {
    return yield call([Portfolio, Portfolio.get], id);
  } catch (error) {
    yield call(logger.error, error);
    yield put(findAllPortfolioPropertiesError(id, error));
  }

  return false;
}

export function* getPortfolioPropertyFindAllFlow({ accountId, accountType }) {
  const portfolio = yield call(getPortfolioPropertyFindAll, accountId);

  if (portfolio) {
    const portfoliosWithTypes = portfolio.map(x => ({
      ...x,
      accountType,
    }));
    yield put(
      findAllPortfolioPropertiesSuccess(accountId, portfoliosWithTypes)
    );
  }
}

export function* getPortfolioPropertyFindAllWatcher() {
  yield takeEvery(
    type.PORTFOLIO_PROPERTY_FINDALL_REQUEST,
    getPortfolioPropertyFindAllFlow
  );
}

export default [getPortfolioPropertyFindAllWatcher];
