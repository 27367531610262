// TODO this component will be replaced in future by extending implementation of Molecules/HeroHeader
import React from 'react';

import styled from 'styled-components';

import { Container } from 'components/Atoms/Grid';
import Config from 'config';

import media from 'utils/responsive';

export const Wrapper = styled(Container)`
  color: ${Config.theme.colors.white};
  display: flex;
  flex-direction: column;
  margin: ${({ homepage }) => (homepage ? '3.25rem auto 2rem' : '2rem auto 0')};
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 100;

  ${media.medium`
    margin: ${({ homepage }) =>
      homepage ? '6.75rem auto 5rem' : '4rem auto 0'};
  `}
  ${media.large`
    margin: ${({ formHero, homepage }) => {
      if (formHero) {
        return '4rem auto 0';
      }
      if (homepage) {
        return '4rem auto';
      }
      return 'auto';
    }};
  `};
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 1.2;
  margin-bottom: ${({ subheading, button }) => {
    if (!subheading) {
      return button ? '1.25rem' : 0;
    }

    return '4rem';
  }};
  text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);

  ${media.large`
    font-size: 2.2rem;
  `};
  ${media.wide`
    font-size: 3.3rem;
  `};
`;

export const Subheading = styled.p`
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 0 auto 1rem;

  ${media.large`
    max-width: 80%;
    margin-bottom: ${({ button }) => (button ? '2rem' : 0)};
    font-size: 2rem;
    line-height: 1.5;
  `};
  ${media.wide`
    width: 75rem;
    font-size: 2.2rem;
  `}
  br {
    line-height: 1.81;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 0;
`;

const Content = ({
  button,
  children,
  formHero,
  heading,
  homepage,
  overlay,
  subheading,
}) => (
  <Wrapper formHero={formHero} homepage={homepage} overlay={overlay}>
    {heading && (
      <Heading subheading={subheading} button={button}>
        {heading}
      </Heading>
    )}

    {subheading && <Subheading button={button}>{subheading}</Subheading>}

    {button && <ButtonWrapper>{button}</ButtonWrapper>}

    {children}
  </Wrapper>
);

Content.defaultProps = {
  button: null,
  children: null,
  formHero: false,
  heading: '',
  homepage: false,
  subheading: '',
};

export const ContentBase = styled(Content)``;

export default Content;
