import Bargraph from './Bargraph';
import Bricks from './Bricks';

import Briefcase from './Briefcase';
import BuildPortfolio from './BuildPortfolio';
import BuyShares from './BuyShares';
import Calendar from './Calendar';
import Cancel from './Cancel';
import ChatQuestion from './ChatQuestion';
import Chevron from './Chevron';
import Close from './Close';
import Contact from './Contact';
import Crane from './Crane';
import CreditCard from './CreditCard';
import Diamond from './Diamond';
import DigitalBook from './DigitalBook';
import Document from './Document';
import Download from './Download';
import EarnRent from './EarnRent';
import Email from './Email';
import EnvelopeOpened from './EnvelopeOpened';
import ErrorTriangle from './ErrorTriangle';
import Examine from './Examine';
import ExitDoor from './ExitDoor';
import Eye from './Eye';
import Facebook from './Facebook';
import Faq from './Faq';
import FolderStar from './FolderStar';
import Glossary from './Glossary';
import GooglePlus from './GooglePlus';
import HandCoins from './HandCoins';
import House from './House';
import HouseMagnify from './HouseMagnify';
import Instagram from './Instagram';
import KeyPropertyfob from './KeyPropertyfob';
import Linkedin from './Linkedin';
import Location from './Location';
import Lock from './Lock';
import Logo from './Logo';
import LogoMark from './LogoMark';
import LogoMarkGray from './LogoMarkGray';
import MailIcon from './MailIcon';
import MastercardLogo from './MastercardLogo';
import Minus from './Minus';
import NewClose from './NewClose';
import NewRosette from './NewRosette';
import NewVerifyID from './NewVerifyID';
import NewWallet from './NewWallet';
import OpenhandBars from './OpenhandBars';
import OpenhandGraph from './OpenhandGraph';
import OpenhandMoney from './OpenhandMoney';
import PhoneRinging from './PhoneRinging';
import PlayButton from './PlayButton';
import Plug from './Plug';
import Plus from './Plus';
import ProgressCircle from './ProgressCircle';
import Properties from './Properties';
import Quote from './Quote';
import Resale from './Resale';
import ResaleMarket from './ResaleMarket';
import Risks from './Risks';
import Rosette from './Rosette';
import Service from './Service';
import SidebarDashboard from './SidebarDashboard';
import SidebarDocuments from './SidebarDocuments';
import SidebarMyIsa from './SidebarMyIsa';
import SidebarPortfolio from './SidebarPortfolio';
import SidebarProfile from './SidebarProfile';
import SidebarPlus from './SidebarPlus';
import SidebarRewards from './SidebarRewards';
import SidebarWallet from './SidebarWallet';
import Solicitors from './Solicitors';
import Surveyors from './Surveyors';
import Team from './Team';
import Tick from './Tick';
import TickCircle from './TickCircle';
import TickShield from './TickShield';
import Twitter from './Twitter';
import VisaLogo from './VisaLogo';
import Wallet from './Wallet';
import Withdraw from './Withdraw';
import Youtube from './Youtube';

export {
  Bargraph,
  Bricks,
  Briefcase,
  BuildPortfolio,
  BuyShares,
  Calendar,
  Cancel,
  ChatQuestion,
  Chevron,
  Close,
  Contact,
  Crane,
  CreditCard,
  Diamond,
  DigitalBook,
  Document,
  Download,
  EarnRent,
  Email,
  EnvelopeOpened,
  ErrorTriangle,
  Examine,
  ExitDoor,
  Eye,
  Facebook,
  Faq,
  FolderStar,
  Glossary,
  GooglePlus,
  HandCoins,
  House,
  HouseMagnify,
  Instagram,
  KeyPropertyfob,
  Linkedin,
  Location,
  Lock,
  Logo,
  LogoMark,
  LogoMarkGray,
  MailIcon,
  MastercardLogo,
  Minus,
  NewClose,
  NewRosette,
  NewVerifyID,
  NewWallet,
  OpenhandBars,
  OpenhandGraph,
  OpenhandMoney,
  PhoneRinging,
  PlayButton,
  Plug,
  Plus,
  ProgressCircle,
  Properties,
  Quote,
  Resale,
  ResaleMarket,
  Risks,
  Rosette,
  Service,
  SidebarDashboard,
  SidebarDocuments,
  SidebarMyIsa,
  SidebarPlus,
  SidebarPortfolio,
  SidebarProfile,
  SidebarRewards,
  SidebarWallet,
  Solicitors,
  Surveyors,
  Team,
  Tick,
  TickCircle,
  TickShield,
  Twitter,
  VisaLogo,
  Wallet,
  Withdraw,
  Youtube,
};
