import React, { FC } from 'react';
import {
  Button as MuiButton,
  CircularProgress,
  PropTypes,
} from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import { useStyles } from './Button.styles';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: PropTypes.Color | 'white';
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  variant,
  color,
  size = 'large',
  loading = false,
  startIcon,
  disabled,
  children,
  className: propsClassName,
  disableElevation = true,
  ...other
}) => {
  const classes = useStyles();
  const contained = variant === 'contained';
  const outlined = variant === 'outlined';
  const white = color === 'white';
  const primary = color === 'primary';
  const secondary = color === 'secondary';
  const large = size === 'large';

  const className = clsx(propsClassName, classes.root, {
    [classes.outlined]: outlined,
    [classes.outlinedWhite]: outlined && white,
    [classes.containedWhite]: contained && white,
    [classes.outlinedPrimary]: outlined && primary,
    [classes.outlinedSecondary]: outlined && secondary,
    [classes.large]: large,
  });

  return (
    <MuiButton
      className={className}
      variant={variant}
      // Currently MUI Button does not support custom colours so to prevent
      // console warnings it has been omitted
      color={color === 'white' ? undefined : color}
      size={size}
      disabled={disabled || loading}
      startIcon={
        loading ? <CircularProgress color="inherit" size={20} /> : startIcon
      }
      disableElevation={disableElevation}
      {...other}
    >
      {children}
    </MuiButton>
  );
};
