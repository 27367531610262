import React from 'react';

const VisaLogo = ({ height, width, ...props }) => (
  <svg height={height} viewBox="0 0 48 48" width={width} {...props}>
    <path
      d="M23.7678 16.501l-3.1825 14.8787H16.736l3.183-14.8787h3.849zm16.194 9.6076l2.0263-5.5876 1.166 5.5876h-3.192zm4.2968 5.271h3.559L44.708 16.5012h-3.283c-.7404 0-1.3638.4292-1.64 1.0908L34.0092 31.38h4.0427l.802-2.222h4.9375l.4666 2.222zm-10.0488-4.8572c.0168-3.9264-5.428-4.1442-5.392-5.8988.012-.5332.5203-1.1008 1.6324-1.246.551-.071 2.072-.1286 3.7964.6657l.6743-3.1565c-.927-.3348-2.1186-.6575-3.601-.6575-3.8054 0-6.4824 2.0212-6.5038 4.918-.0243 2.142 1.9125 3.3366 3.3684 4.05 1.501.729 2.004 1.1967 1.997 1.8482-.0106.9982-1.1976 1.4403-2.302 1.4568-1.9367.0303-3.059-.523-3.9535-.9404l-.6985 3.262c.9006.412 2.5606.771 4.279.789 4.0454 0 6.691-1.9976 6.7027-5.091zM18.2678 16.501l-6.237 14.8787H7.9626L4.893 19.5054c-.1858-.7298-.348-.9984-.914-1.3067-.9255-.503-2.4537-.9733-3.797-1.2656l.0907-.432h6.55c.8345 0 1.5848.5557 1.7757 1.5168l1.6213 8.6103 4.0043-10.127h4.0438z"
      fill="#182E66"
      fillRule="evenodd"
    />
  </svg>
);

VisaLogo.defaultProps = {
  height: 48,
  width: 48,
};

export default VisaLogo;
