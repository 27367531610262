import { isPlainObject } from 'lodash';

import api from '../endpoints/Costs';

export default class Cost {
  /**
   * Get investment summary details when buying.
   */
  static getBuySummary(accountId, amount, investmentId, assetType, unitPrice) {
    return api.costsBuySummary
      .get(accountId, amount, investmentId, assetType, unitPrice)
      .then(investmentSummary => {
        if (isPlainObject(investmentSummary)) {
          return {
            ...investmentSummary,
            type: assetType,
          };
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }

  /**
   * Get investment summary details when selling.
   */
  static getSellSummary(accountId, amount, investmentId, assetType, unitPrice) {
    return api.costsSellSummary
      .get(accountId, amount, investmentId, assetType, unitPrice)
      .then(investmentSummary => {
        if (isPlainObject(investmentSummary)) {
          return {
            ...investmentSummary,
            type: assetType,
          };
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }
}
