import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RequireNotAuth } from './Auth';
import { loginPaths } from './paths';

import Loader from './Loader';

export default () => (
  <RequireNotAuth>
    <Switch>
      <Route exact path={loginPaths.login()} component={Loader} />
      <Route exact path={loginPaths.forgotPassword()} component={Loader} />
      <Route component={Loader} exact path={loginPaths.changePassword()} />
      <Redirect to={loginPaths.login()} />
    </Switch>
  </RequireNotAuth>
);
