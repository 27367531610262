import { differenceInYears, isFuture, isPast } from 'date-fns';
import { isEmpty, trim } from 'lodash';
import { isEmail, isLength, matches } from 'validator';

export const validateRequired = (i18n, value, field = null) => {
  if (isEmpty(trim(value))) {
    return field
      ? i18n('Validation')`Please enter ${field}:s(i18n)`
      : i18n('Validation')`Required`;
  }

  return '';
};

export const validateRequiredSelectFilter = (
  i18n,
  value,
  array,
  field = null
) => {
  if (isEmpty(trim(value))) {
    return field
      ? i18n('Validation')`Please select ${field}:s(i18n)`
      : i18n('Validation')`Required`;
  }

  return field
    ? !array.includes(value) &&
        i18n('Validation')`Choose ${field}:s(i18n) from the list`
    : !array.includes(value) && i18n('Validation')`Required`;
};

export const validateName = (i18n, value, field) => {
  let result = validateRequired(i18n, value, field);

  if (!result && !/^[A-Za-z-'.\s]*$/.test(value)) {
    result = i18n('Validation')`Please enter a valid ${field}:s(i18n)`;
  }

  return result;
};

export const validateEmail = (i18n, value, field = null) => {
  let result = validateRequired(i18n, value, field);

  if (!result && !isEmail(value)) {
    result = i18n('Validation')`Invalid email address`;
  }

  return result;
};

export const validatePhone = (i18n, value) => {
  if (isEmpty(trim(value))) {
    return i18n('Validation')`Please enter your phone number`;
  }
  if (!matches(value, /^\+?[()\- 0-9]+$/)) {
    return i18n('Validation')`Invalid phone number`;
  }

  return '';
};

export const validateBirthDate = (i18n, value) => {
  const trimmedValue = trim(value);

  if (isEmpty(trimmedValue)) {
    return i18n('Validation')`Please enter your date of birth`;
  }

  const [day, month, year] = trimmedValue.split('/').map(x => trim(x));
  const date = new Date(`${year}-${month}-${day}`);

  if (
    !(day && month && year) ||
    year.length < 4 ||
    year[0] === '0' ||
    Number.isNaN(date.getTime())
  ) {
    return i18n('Validation')`Please enter a valid date`;
  }

  if (
    date.getUTCFullYear() !== parseInt(year, 10) ||
    date.getUTCMonth() + 1 !== parseInt(month, 10) ||
    date.getUTCDate() !== parseInt(day, 10)
  ) {
    return i18n('Validation')`Please enter a valid date`;
  }

  if (isPast(date)) {
    if (differenceInYears(new Date(), date) > 120) {
      return i18n('Validation')`Please check your date birth`;
    }
    if (differenceInYears(new Date(), date) < 18) {
      return i18n('Validation')`You must be at least 18 years old`;
    }
  } else if (isFuture(date)) {
    return i18n('Validation')`You cannot be born in the future`;
  }

  return '';
};

export const validateSameValue = (i18n, value1, value2) => {
  if (value1 !== value2) {
    return i18n('Validation')`Passwords must be identical`;
  }

  return '';
};

export const validateRegisterPassword = (i18n, value, field = null) => {
  let result = validateRequired(i18n, value, field);

  if (!result && !isLength(value, { min: 8 })) {
    result = i18n('Validation')`Must have at least 8 characters`;
  }
  if (!result && !/\d/.test(value)) {
    result = i18n('Validation')`Must have at least one digit`;
  }
  if (!result && value.toLowerCase() === value) {
    result = i18n('Validation')`Must have at least one uppercase letter`;
  }
  return result;
};

export const validateNin = (i18n, value) => {
  const newValue = trim(value).replace(/[^0-9a-zA-z]/g, '');

  if (isEmpty(newValue)) {
    return i18n('Validation')`Please enter your National Insurance number`;
  }
  if (
    !/^[abceghj-prstw-z][abceghj-nprstw-z]\d{6}[abcd]$/.test(
      newValue.toLowerCase()
    )
  ) {
    return i18n('Validation')`Invalid UK National Insurance number`;
  }

  return '';
};
