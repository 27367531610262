import React, { PropsWithChildren } from 'react';

import { NavLink, NavLinkProps } from 'components/Atoms/Link';

import { useStyles } from './NavItem.styles';

export const NavLinkItem = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<NavLinkProps>
>(function NavLinkItem({ to, children, onClick }, ref) {
  const classes = useStyles();

  return (
    <NavLink
      ref={ref}
      underline="none"
      className={classes.root}
      data-test-id={to}
      to={to}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
});
