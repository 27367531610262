import React from 'react';
import styled from 'styled-components';
import { space as s, width as w } from 'styled-system';

const Element = styled(({ width, px, ...props }) => <div {...props} />)`
  ${w} ${s};
`;

const Column = props => <Element px={[0, 1]} {...props} />;

export default Column;
