import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { UserProgress } from 'components/Molecules';
import { Sidebar } from 'components/Organisms';
import { AppBar } from 'components/Organisms/AppBar';
import { Footer } from 'components/Organisms/footer';
import { Container } from 'components/Atoms/Container';
import { makeSelectSidebarCollapsed } from 'store/App/selectors';
import { makeSelectFundedWallet } from 'store/SessionProvider/selectors';
import media from 'utils/responsive';

import withBaseTemplate from '../Base';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const SidebarContainer = styled.div`
  transition: padding-left 0.3s;
  padding-left: 0;
  margin-top: ${({ hasBanner }) => (hasBanner ? '0' : '3rem')};
  ${media.large`
    padding-left: 4.75rem;
  `} ${media.wide`
    padding-left: ${({ isSidebarCollapsed }) =>
      isSidebarCollapsed ? '4.75rem' : '20.25rem'};
  `};
`;

export const InvestorTemplate = ({
  children,
  isSidebarCollapsed,
  fundedWallet,
  heading,
  banner,
  hideFooter,
}) => {
  return (
    <>
      <AppBar />
      <Wrapper>
        <Sidebar />
        <SidebarContainer
          isSidebarCollapsed={isSidebarCollapsed}
          hasBanner={!!banner}
        >
          {banner && <Box pb={6}>{banner}</Box>}
          <Container>
            {!fundedWallet && <UserProgress />}
            <Box mb={8} textAlign="center">
              <Typography variant="h1">
                <Box fontWeight="300">{heading}</Box>
              </Typography>
            </Box>
            {React.Children.toArray(children)}
          </Container>
          {!hideFooter && <Footer hideMarketing hideCore />}
        </SidebarContainer>
      </Wrapper>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isSidebarCollapsed: makeSelectSidebarCollapsed(),
  fundedWallet: makeSelectFundedWallet(),
});

export default withBaseTemplate(connect(mapStateToProps)(InvestorTemplate));
