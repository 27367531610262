import Config from 'config';
import Api from 'services/Api';

import ApiAdapter from '../adapters/api';

import OAuth from '../repositories/Auth';

// TODO Not used. To be removed?
export default Api({
  incentives: {
    url: '/incentives',
    helpers: {
      findAll: () => [],
    },
    transformer({ data: { tradeNotionalCashbackIncentives } }) {
      return tradeNotionalCashbackIncentives;
    },
  },
})
  .use('fetch', ApiAdapter(OAuth))
  .use('rootUrl', Config.api.url);
