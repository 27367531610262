import { differenceInMonths, getDaysInMonth } from 'date-fns';

const calculateRemainingDays = (fromDateDay, toDateDay, daysInFromMonth) => {
  if (fromDateDay === toDateDay) {
    return 0;
  }
  if (toDateDay > fromDateDay) {
    return toDateDay - fromDateDay + 1;
  }
  return daysInFromMonth - (fromDateDay - toDateDay);
};

export function calculateMonthsDifference(fromDate, toDate) {
  const fromDateDay = fromDate.getDate();
  const toDateDay = toDate.getDate();

  const numberOfMonths = differenceInMonths(toDate, fromDate);
  const daysInFromMonth = getDaysInMonth(fromDate);
  const remainingDays = calculateRemainingDays(
    fromDateDay,
    toDateDay,
    daysInFromMonth
  );
  const partialMonth =
    Math.round((remainingDays * 100) / daysInFromMonth) / 100;

  return numberOfMonths + partialMonth;
}

// Convert YYYY-MM-DD to a date object
export const exitDateStringToDate = exitDateString =>
  new Date(
    ...exitDateString
      .split('-')
      .map((x, i) => (i !== 1 ? Number(x) : Number(x) - 1))
  );
