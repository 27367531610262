import React from 'react';

const ResaleMarket = props => (
  <svg viewBox="0 0 38 30" fill="none" {...props}>
    <path
      d="M37.5702 9.88897L28.0782 0.397005C27.5486 -0.132467 26.6901 -0.132308 26.1606 0.397323C25.9064 0.651571 25.7636 0.996236 25.7636 1.35576V5.42373H16.2716C15.5227 5.42373 14.9156 6.03083 14.9156 6.77975C14.9156 7.52867 15.5227 8.13577 16.2716 8.13577H27.1196C27.8685 8.13577 28.4756 7.52867 28.4756 6.77975V4.62905L34.6942 10.8476L28.4756 17.0662V14.9156C28.4756 14.1667 27.8685 13.5596 27.1196 13.5596H12.2036V9.49162C12.2035 8.7427 11.5962 8.13577 10.8474 8.13593C10.4879 8.136 10.1432 8.27878 9.88893 8.53295L0.397044 18.0249C-0.132348 18.5545 -0.132348 19.4128 0.397044 19.9423L9.88901 29.4342C10.1433 29.6886 10.4881 29.8315 10.8477 29.8316C11.0259 29.8321 11.2025 29.7971 11.367 29.7285C11.8736 29.5184 12.2038 29.024 12.2036 28.4755V24.4076H21.6956C22.4445 24.4076 23.0516 23.8005 23.0516 23.0516C23.0516 22.3026 22.4445 21.6955 21.6956 21.6955H10.8477C10.0988 21.6955 9.49166 22.3026 9.49166 23.0516V25.2022L3.27307 18.9836L9.49166 12.765V14.9156C9.49166 15.6645 10.0988 16.2716 10.8477 16.2716H25.7636V20.3396C25.7637 21.0885 26.371 21.6955 27.1198 21.6953C27.4793 21.6952 27.824 21.5524 28.0782 21.2983L37.5702 11.8063C38.0995 11.2768 38.0995 10.4184 37.5702 9.88897Z"
      fill="currentColor"
    />
  </svg>
);

export default ResaleMarket;
