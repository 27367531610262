import React from 'react';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { Field, reduxForm } from 'redux-form/immutable';

import { createStructuredSelector } from 'reselect';

import styled from 'styled-components';

import { readIsaLsRecord } from 'utils/isa-flow';
import { ErrorMessages } from 'components/Atoms/ErrorMessages';
import { ButtonSubmit } from 'components/Atoms/Button';
import { loginPaths, registerPaths } from 'router/paths';

import { ResendVerificationEmailLink as ResendVerificationEmailLinkBase } from 'components/Molecules';
import Config from 'config';

import { makeSelectRedirectToAfterLogin } from 'store/App/selectors';

import { actions as sessionActions } from 'store/SessionProvider';
import {
  makeSelectIsPasswordChangeSuccess,
  makeSelectIsPasswordResetSuccess,
  makeSelectIsSubmitting,
} from 'store/SessionProvider/selectors';

import { i18nConnect } from 'utils/i18nConnect';
import { normalizeEmail } from 'utils/normalize';
import media from 'utils/responsive';

import { dispatchPromise } from 'utils/saga';

import { PasswordFieldRedux, TextFieldRedux } from 'components/Atoms/Form';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'components/Atoms/Link';
import validate from './validate';

const FormFooter = styled.div`
  color: ${Config.theme.colors.white};
  display: flex;
  flex-direction: column;
  text-align: center;

  ${media.medium`
    flex-direction: row;
    flex-wrap: wrap;
  `};
`;

const Form = styled.form`
  text-align: left;
  width: 100%;
`;

const ResendVerificationEmailLink = styled(ResendVerificationEmailLinkBase)`
  margin-top: 2.5rem;
`;

export const LoginForm = ({
  className,
  handleSubmit,
  submitting,
  error,
  isSubmitting,
  i18n,
}) => (
  <Form
    className={className}
    data-test-id="login-form"
    disabled={submitting}
    onSubmit={handleSubmit}
    autoComplete="off"
  >
    <ErrorMessages errors={error} />

    <Field
      component={TextFieldRedux}
      label={i18n('LoginForm')`Email`}
      name="email"
      normalize={normalizeEmail}
      type="email"
      autoComplete="off"
      data-test-id="user-email"
    />

    <Field
      component={PasswordFieldRedux}
      label={i18n('LoginForm')`Password`}
      name="password"
      autoComplete="off"
      data-test-id="user-password"
    />

    <Box textAlign="right">
      <Link to={loginPaths.forgotPassword()}>
        {i18n('LoginForm')`Forgot password?`}
      </Link>
    </Box>

    <Box mt={4} textAlign="center">
      <ButtonSubmit
        // TODO: https://britishpearl.atlassian.net/browse/PIT-1887
        loading={submitting || isSubmitting}
      >
        {i18n('LoginForm')`Log in`}
      </ButtonSubmit>
    </Box>

    <Box mt={2} textAlign="center">
      <Typography variant="body1">
        {i18n('LoginForm')`Need to register an account? `}
        <Link data-test-id="join-button" to={registerPaths.register()}>
          {i18n('RegisterForm')`Register`}
        </Link>
      </Typography>
    </Box>

    <FormFooter />
    {error && <ResendVerificationEmailLink />}
  </Form>
);

LoginForm.defaultProps = {
  invalid: false,
  pristine: true,
  submitting: false,
  transparent: true,
};

const onSubmit = (values, dispatch, { redirectAfterLogin, history }) => {
  dispatchPromise(dispatch, sessionActions.loginSubmit(values.toJS()))
    .then(() =>
      history.push(
        readIsaLsRecord() || redirectAfterLogin || '/investor/dashboard'
      )
    )
    .catch(() => {});
};

const mapStateToProps = createStructuredSelector({
  redirectAfterLogin: makeSelectRedirectToAfterLogin(),
  isSubmitting: makeSelectIsSubmitting(),
  isPasswordResetSuccess: makeSelectIsPasswordResetSuccess(),
  isPasswordChangeSuccess: makeSelectIsPasswordChangeSuccess(),
});

export default withRouter(
  connect(mapStateToProps)(
    i18nConnect(
      reduxForm({
        form: 'LoginForm',
        validate,
        onSubmit,
      })(LoginForm)
    )
  )
);
