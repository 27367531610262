import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const RoutedModalContext = createContext({
  closeModal: () => null,
});

export const RoutedModalContextProvider = ({ children, closeModal }) => (
  <RoutedModalContext.Provider
    value={{
      closeModal,
    }}
  >
    {children}
  </RoutedModalContext.Provider>
);

RoutedModalContextProvider.propTypes = {
  children: PropTypes.node,
  closeModal: PropTypes.func.isRequired,
};

RoutedModalContextProvider.defaultProps = {
  children: null,
};

export const useRoutedModalContext = () => useContext(RoutedModalContext);
