export const setConfigByMedia = (
  currentBreakpoint,
  isMultiBreakpointImageHero
) => {
  const defaultConfig = {
    width: '100%',
    bgSize: 'cover',
    bgPositionY: 'center',
    bgPositionx: '65%',
    parentWidth: '65%',
  };

  if (!isMultiBreakpointImageHero) {
    return defaultConfig;
  }

  const multiHeroDefault = {
    bgColor: 'white',
    type: 'place',
    bgSize: 'cover',
    bgPositionY: 'center',
    bgPositionX: '40%',
    width: 'auto',
    margin: '0',
    parentWidth: '65%',
    maxWidth: 'none',
    height: '100%',
  };
  switch (currentBreakpoint) {
    case 'xsmall':
      return {
        ...multiHeroDefault,
        bgPositionX: '100%',
        bgPositionY: '0',
        bgSize: '6rem',
        margin: '25% auto 0 auto',
        parentWidth: '35%',
        maxWidth: '100%',
        width: '12rem',
        height: '26rem',
      };
    case 'small':
      return {
        ...multiHeroDefault,
        bgPositionX: '100%',
        bgPositionY: '0',
        bgSize: '6rem',
        margin: '25% auto 0 auto',
        parentWidth: '35%',
        maxWidth: '100%',
        width: '12rem',
        height: '26rem',
      };
    case 'medium':
      return {
        ...multiHeroDefault,
        bgPositionX: 'center',
        bgPositionY: '35%',
        bgSize: 'contain',
        margin: '0 1rem 0 2.5rem',
        parentWidth: '35%',
        maxWidth: '35rem',
      };
    case 'large':
      return {
        ...multiHeroDefault,
        bgPositionX: 'center',
        bgPositionY: '65%',
        bgSize: 'contain',
        margin: '0 12% 0 auto',
        parentWidth: '50%',
        maxWidth: '20rem',
      };
    default:
      return {
        ...multiHeroDefault,
        bgPositionX: 'center',
        bgPositionY: '35%',
        bgSize: 'contain',
        margin: '0 4.5% 0 auto',
        parentWidth: '50%',
        maxWidth: '37.5rem',
      };
  }
};
