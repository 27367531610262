import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { ButtonLink } from 'components/Atoms/Button';
import { H2 } from 'components/Atoms/Heading';
import { propertyPaths, registerPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';
import { useStyles } from './CalculatorSectionSharesText.styles';

export interface CalculatorSectionSharesTextProps {
  loggedIn?: boolean;
}

export const CalculatorSectionSharesText: FC<CalculatorSectionSharesTextProps> = ({
  loggedIn = false,
}) => {
  const t = useI18n()('Molecules.CalculatorSectionSharesText');
  const classes = useStyles();
  return (
    <div>
      <H2 paragraph>{t`Estimate the return you could earn on your shares`}</H2>
      <ul className={classes.ul}>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`Enter your investment amount`}
        </Typography>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`Select your property investment`}
        </Typography>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`See dividends and capital growth`}
        </Typography>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`See how much your investment could grow`}
        </Typography>
      </ul>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={6} xl={5}>
          <ButtonLink
            fullWidth
            to={propertyPaths.shares()}
            variant="outlined"
            color="primary"
          >
            {t`View properties`}
          </ButtonLink>
        </Grid>
        {!loggedIn && (
          <Grid item xs={12} sm={6} md={4} lg={6} xl={5}>
            <ButtonLink
              fullWidth
              to={registerPaths.register()}
              variant="contained"
              color="primary"
            >
              {t`Join`}
            </ButtonLink>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
