import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import config from 'config';

import { LoggerProvider } from './types';

const { Severity } = Sentry;

// https://docs.sentry.io/platforms/javascript/#decluttering-sentry
const ignoreErrors: Array<string | RegExp> = [
  'User unauthorized',
  'Request failed with status code 401',
];

// https://docs.sentry.io/platforms/javascript/#decluttering-sentry
const blacklistUrls: Array<string | RegExp> = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

/* eslint-disable class-methods-use-this */
export class SentryLoggerProvider implements LoggerProvider {
  public constructor() {
    Sentry.init({
      dsn: config.sentry.apiKey,
      environment: config.env,
      integrations: [
        new RewriteFrames({
          root: global.__rootdir__,
        }),
      ],
      ignoreErrors,
      blacklistUrls,
    });
  }

  public log(message: string): void {
    Sentry.captureMessage(message, Severity.fromString('log'));
  }

  public info(message: string): void {
    Sentry.captureMessage(message, Severity.fromString('info'));
  }

  public warn(message: string): void {
    Sentry.captureMessage(message, Severity.fromString('warn'));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error(err: any): void {
    Sentry.captureException(err);
  }
}
