const getImage = (src, currentBreakpoint) => {
  switch (currentBreakpoint) {
    case 'xsmall':
      return src.images[2].path;
    case 'small':
      return src.images[2].path;
    case 'medium':
      return src.images[2].path;
    case 'large':
      return src.images[3].path;
    default:
      return src.images[3].path;
  }
};

export const stylesForFit = (src, currentBreakpoint) => `
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 65%;
  background-image: url(${getImage(src, currentBreakpoint)});
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    background-position-x: center;
  }
`;

export const stylesForPlace = (src, currentBreakpoint, config) => `
  background-color: ${config.bgColor};
  background-position-x: ${config.bgPositionX};
  background-position-y: ${config.bgPositionY};
  background-repeat: no-repeat;
  background-size: ${config.bgSize};
  background-image: url(${getImage(src, currentBreakpoint)});
  width: ${config.width};
  height: ${config.height};
  margin: ${config.margin};
  max-width:${config.maxWidth};
  ${config.breakpoints}
`;
