import { BoxProps } from '@material-ui/core';

import { Color } from './types';

type GetColors = (
  color?: Color
) => { color: BoxProps['color']; bgcolor: BoxProps['bgcolor'] };

export const getColors: GetColors = color => {
  switch (color) {
    case 'white':
      return {
        color: 'common.black',
        bgcolor: 'common.white',
      };
    case 'black':
      return {
        color: 'common.white',
        bgcolor: 'common.black',
      };
    case 'primary':
      return {
        color: 'common.white',
        bgcolor: 'primary.main',
      };
    case 'grey':
      return {
        color: 'common.black',
        bgcolor: 'grey.100',
      };
    default:
      return {
        color: 'common.black',
        bgcolor: 'common.white',
      };
  }
};
