import { isNumber } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled, { css } from 'styled-components';

import { LoadingLink as BaseLink } from 'components/Atoms/LoadingLink';
import { SidebarWallet } from 'components/Atoms/Icons';

import Config from 'config';

import { makeSelectSidebarCollapsed } from 'store/App/selectors';
import { makeSelectAccountBalance } from 'store/SessionProvider/selectors';

import { useI18n } from 'utils/i18nConnect';
import media from 'utils/responsive';
import { investorPaths } from 'router/paths';

const Balance = styled.div`
  color: ${Config.theme.colors.black};
  font-size: 1.125rem;
  margin-bottom: 0;
  opacity: 1;
  padding: 1rem 4.5rem 0;
  transition: all 0.2s;
  white-space: nowrap;

  ${media.large`
    padding-top: 1.5rem;
  `} span:last-child {
    color: #888;
    display: block;
    font-size: 0.75rem;
  }
`;

const Link = styled(BaseLink)`
  align-items: center;
  color: ${Config.theme.colors.black};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${Config.theme.fontWeight.medium};
  line-height: 1;
  overflow: hidden;
  padding: 1rem 1.5rem;
  text-overflow: clip;
  transition: padding 0.3s, margin 0.3s;
  white-space: nowrap;
  text-decoration: none;

  ${media.large`
    margin-top: 1rem;
  `} svg {
    color: ${Config.theme.colors.wafer};
    margin-right: 1.5rem;
    min-width: 1.5rem;
    transition: all 0.3s;
  }

  span {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &:hover {
    svg {
      color: ${Config.theme.colors.quickSand};
    }
  }

  &.selected {
    color: ${Config.theme.colors.quickSand};

    svg {
      color: ${Config.theme.colors.quickSand};
    }
  }

  ${media.large`
    &:hover {
      border-right: .25rem solid ${Config.theme.colors.wafer};
    }

    &.selected {
      border-right: .25rem solid ${Config.theme.colors.quickSand};
    }
  `};
`;

const WalletLinks = styled.div`
  border-bottom: 1px solid ${Config.theme.colors.pampas};
  border-top: 1px solid ${Config.theme.colors.pampas};
  display: flex;
  font-size: 0.875rem;
  opacity: 1;
  transition: opacity 0.1s;

  a {
    flex: 1;
    padding: 1rem 0;
    text-align: center;
    white-space: nowrap;

    &:last-of-type {
      border-left: 1px solid ${Config.theme.colors.pampas};
    }
  }
`;

const Wrapper = styled.div`
  border-top: 1px solid ${Config.theme.colors.pampas};
  margin-bottom: 1rem;

  ${media.large`
    margin-bottom: 0;
    margin-top: 2rem;

    ${media.large`
      ${({ isCollapsed }) =>
        isCollapsed &&
        css`
          ${Balance} {
            margin-bottom: -3.25rem;
            opacity: 0;
          }

          ${Link} {
            margin-top: 0;
            position: relative;
            z-index: 100;

            svg {
              margin-right: 1.75rem;
            }

            span {
              opacity: 0;
            }
          }

          ${WalletLinks} {
            opacity: 0;
          }
        `}
    `}
  `};
`;

const Wallet = ({ isCollapsed, balance, mobile }) => {
  const i18n = useI18n();
  return (
    <Wrapper isCollapsed={isCollapsed}>
      <Balance>
        <span>
          {isNumber(balance)
            ? i18n('Wallet')`${balance}:c`
            : i18n('Wallet')`Calculating...`}
        </span>

        <span>{i18n('Wallet')`Cash available`}</span>
      </Balance>

      <Link
        data-test-id={`sidebar${mobile ? 'mobile-' : '-'}wallet`}
        to={investorPaths.wallet()}
      >
        <SidebarWallet />

        {i18n('Wallet')`My wallet`}
      </Link>
    </Wrapper>
  );
};

Wallet.defaultProps = {
  balance: undefined,
  mobile: false,
  isCollapsed: false,
};

Wallet.propTypes = {
  balance: PropTypes.number,
  mobile: PropTypes.bool,
  isCollapsed: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isCollapsed: makeSelectSidebarCollapsed(),
  balance: makeSelectAccountBalance(),
});

export default connect(mapStateToProps)(Wallet);
