import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as ContainerBase } from 'components/Atoms/Grid';
import { UserProgress } from 'components/Molecules';
import { AppBar } from 'components/Organisms/AppBar';
import Config from 'config';
import media from 'utils/responsive';
import { Footer } from 'components/Organisms/footer';
import withBaseTemplate from '../Base';

export const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: ${Config.theme.fontWeight.medium};
  margin: 2rem auto;
  text-align: center;

  ${media.medium`
    font-size: 2.5rem;
    margin: 4rem auto;
  `};
`;

export const Heading = styled.h3`
  font-size: 1.25rem;
  font-weight: ${Config.theme.fontWeight.medium};

  ${media.medium`
    font-size: 1.75rem;
  `};
`;

export const Container = styled(ContainerBase)`
  margin: 0 auto 3rem;
  max-width: 87.5rem;
  padding: 0 1.5rem;
  position: relative;

  ${media.medium`
    padding: 0 3rem;
  `} ${media.large`
    padding: 0 4rem;
  `};
`;

const MessageWrapper = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${Config.theme.colors.desertStorm};
  padding: 2rem;
  text-align: center;
  margin: 4rem 0;

  ${media.large`
    padding: 4rem;
    margin: 10rem 0;
  `};
`;

const MesssageTitle = styled.h2`
  color: ${Config.theme.colors.pomegranate};
  font-size: 1.5rem;
  font-weight: ${Config.theme.fontWeight.medium};
`;

export const OnboardingMessage = ({ children, title }) => (
  <Container>
    <MessageWrapper>
      {title && <MesssageTitle>{title}</MesssageTitle>}

      {children}
    </MessageWrapper>
  </Container>
);

OnboardingMessage.defaultProps = {
  title: null,
};

OnboardingMessage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export const NewOnboardingTemplate = ({ children }) => (
  <>
    <AppBar />

    <UserProgress />

    {React.Children.toArray(children)}

    <Footer hideMarketing hideLegal />
  </>
);

NewOnboardingTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withBaseTemplate(NewOnboardingTemplate);
