import React from 'react';

import Config from 'config';

const EarnRent = ({ fill, fill2, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 64 64" width={width} {...props}>
    <g fill="none" fillRule="evenodd" stroke={fill} strokeWidth="2">
      <path
        d="M37.2462 45.4457l-14.767 9.062L1 40.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M41.0615 15.4v2.8615m-18.123 8.5847v2.8615" />
      <path
        d="M37.7752 32.597L15.3077 17.6065l10.112-6.022 5.9403 3.682 17.3323 10.737V47.556l-10.917 6.9516z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M43.0572 23.0308L50.6 18.3515c-1.1453-.7115-1.384-1.7154 0-2.573l-8.2967-5.1477c-1.3826.8577-3.0024.7102-4.1477 0l-7.5864 4.7077m-9.583 5.7845l-8.54 5.322c1.432.892 1.1985 1.931.0136 2.6683l8.592 5.3542c1.185-.7387 2.8504-.8827 4.2838.0094l8.0952-5.045" />
      <path
        d="M37.2462 31.9448l-14.767 9.209L1 27.9358l15.8758-9.6743m9.878-5.796L41.4722 3 63 16.4645l-14.8757 9.4278"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2462 38.7922l-14.767 9.0386L1 33.523m62-10.4922l-15.2615 9.5384M37.2462 52.1226l-14.767 9.062L1 46.877m62-10.4924L47.7385 45.923M63 29.7077l-15.2615 9.5385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

EarnRent.defaultProps = {
  fill: Config.theme.colors.akaroa,
  fill2: Config.theme.colors.quickSand,
  height: 24,
  width: 24,
};

export default EarnRent;
