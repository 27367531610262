import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import shortid from 'shortid';

import styled, { css } from 'styled-components';

import Config from 'config';
import { makeSelectUser } from 'store/SessionProvider/selectors';
import {
  MailIcon,
  NewRosette,
  NewVerifyID,
  NewWallet,
} from 'components/Atoms/Icons';
import { Role } from 'services/Pip';
import media from 'utils/responsive';

const Wrapper = styled.div`
  align-items: stretch;
  display: flex;
  margin: 2rem auto;
  max-width: 21rem;
  padding-bottom: 1rem;
  position: relative;

  ${media.medium`
    margin-bottom: 3rem;
    margin-top: 3rem;
    max-width: 87.5rem;
    padding: 0;
  `};
`;

const EmailIcon = styled(MailIcon)`
  height: 1.4569rem;
  width: 2.109375rem;

  ${media.medium`
    height: 1.7263rem;
    width: 2.5rem;
  `};
`;

const AccreditIcon = styled(NewRosette)`
  height: 2.2675rem;
  width: 1.6875rem;

  ${media.medium`
    height: 2.6875rem;
    width: 2rem;
  `};
`;

const VerifyIcon = styled(NewVerifyID)`
  height: 1.9287rem;
  width: 1.7356rem;

  ${media.medium`
    height: 2.2856rem;
    width: 2.0581rem;
  `};
`;

const FundsIcon = styled(NewWallet)`
  height: 1.9287rem;
  width: 1.8319rem;

  ${media.medium`
    height: 2.2856rem;
    width: 2.1725rem;
  `};
`;

const IconWrapper = styled.div`
  align-items: center;
  background-color: ${Config.theme.colors.pomegranate};
  border-radius: 50%;
  display: flex;
  height: 3.375rem;
  justify-content: center;
  margin: 0 auto 1rem;
  width: 3.375rem;

  &::after {
    content: '';
    height: 0.25rem;
    left: 50%;
    position: absolute;
    width: 100%;
    z-index: -1;

    ${({ completed }) =>
      completed
        ? css`
            background-color: ${Config.theme.colors.cashmere};
            pointer-events: none;
          `
        : css`
            background-color: #c4c4c4;
          `} ${media.medium`
      height: .3125rem;
    `};
  }

  ${media.medium`
    height: 4rem;
    width: 4rem;
  `};
`;

const StepItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;

  ${({ completed, active }) => {
    if (completed) {
      return css`
        pointer-events: none;

        ${IconWrapper} {
          background-color: ${Config.theme.colors.cashmere};
        }
      `;
    }

    if (!completed && !active) {
      return css`
        pointer-events: none;

        ${IconWrapper} {
          background-color: #c4c4c4;
        }
      `;
    }

    return false;
  }};

  &:last-of-type ${IconWrapper}::after {
    display: none;
  }
`;

const Title = styled.span`
  color: ${({ completed, active }) => {
    if (completed) {
      return Config.theme.colors.cashmere;
    }

    if (!completed && !active) {
      return '#c4c4c4';
    }

    return Config.theme.colors.pomegranate;
  }};
  font-weight: ${Config.theme.fontWeight.medium};
`;

const DesktopTitle = Title.extend`
  display: none;

  ${media.medium`
    display: block;
  `};
`;

const MobTitle = Title.extend`
  background-color: white;
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;

  ${({ active }) =>
    !active &&
    css`
      display: none;
    `} ${media.medium`
    display: none;
  `};
`;

const progressData = roles => {
  const accredited = roles.includes(Role.InvestorClassified);
  const verified = roles.includes(Role.InvestorVerified);

  return [
    {
      completed: true,
      title: 'Email confirmed',
      icon: <EmailIcon />,
      active: false,
    },
    {
      completed: accredited || verified,
      title: accredited || verified ? 'Accredited' : 'Get accredited',
      icon: <AccreditIcon />,
      active: !accredited && !verified,
    },
    {
      completed: verified,
      title: verified ? 'ID Verified' : 'Verify ID',
      icon: <VerifyIcon fill={Config.theme.colors.white} />,
      active: accredited && !verified,
    },
    {
      completed: false,
      title: 'Add funds',
      icon: <FundsIcon />,
      active: verified,
    },
  ];
};

export const UserProgress = ({ className, user }) => {
  const steps = user ? progressData(user.get('roles').toJS()) : null;

  return (
    <Wrapper className={className}>
      {steps &&
        steps.map(({ completed, active, title, icon }) => (
          <Fragment key={shortid.generate()}>
            <StepItem completed={completed} active={active}>
              <IconWrapper completed={completed}>{icon}</IconWrapper>

              <DesktopTitle completed={completed} active={active}>
                {title}
              </DesktopTitle>
            </StepItem>

            <MobTitle active={active}>{title}</MobTitle>
          </Fragment>
        ))}
    </Wrapper>
  );
};

UserProgress.defaultProps = {
  className: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps)(UserProgress);
