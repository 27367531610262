import React, { FC } from 'react';
import { differenceInMonths } from 'date-fns';
import { Box, Typography } from '@material-ui/core';

import { investmentPaths } from 'router/paths';
import { Link } from 'components/Atoms/Link';
import { useI18n } from 'utils/i18nConnect';

import { useStyles } from './PropertyCardTitle.styles';

export interface PropertyCardTitleProps {
  investmentId?: number;
  ltv?: number;
  title: string;
  subtitle: string;
  expectedExitDate: Date;
  comingSoon?: boolean;
}

export const PropertyCardTitle: FC<PropertyCardTitleProps> = ({
  investmentId,
  title,
  subtitle,
  expectedExitDate,
  ltv,
  comingSoon,
}) => {
  const t = useI18n()('PropertyCard');
  const classes = useStyles();

  return (
    <Box bgcolor="common.white" textAlign="center" p={2.5}>
      <Typography variant="h3">
        <Link
          to={investmentPaths.investmentById({ investmentId })}
          className={classes.titleLink}
          underline="hover"
        >
          {title}
        </Link>
      </Typography>
      <Typography variant="body2">
        <Box component="span" fontWeight="bold">
          {subtitle}
        </Box>
      </Typography>
      <Typography variant="body2">
        {comingSoon
          ? t`Coming soon`
          : `${differenceInMonths(
              expectedExitDate,
              Date.now()
            )} ${t`months left`} ${ltv ? ` | LTV ${ltv}%` : ''}`}
      </Typography>
    </Box>
  );
};
