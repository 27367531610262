export const SIDEBAR_COLLAPSE_TOGGLE = 'appGlobal/SIDEBAR_COLLAPSE_TOGGLE';
export const NEWSLETTER_SUBSCRIBE = 'appGlobal/NEWSLETTER_SUBSCRIBE';
// TODO To be replaced with new approach (see comment in ./reducer.js)
export const OPEN_MODAL = 'appGlobal/OPEN_MODAL';
export const CLOSE_MODAL = 'appGlobal/CLOSE_MODAL';

export const OPEN_GLOBAL_MODAL = 'appGlobal/OPEN_GLOBAL_MODAL';
export const CLOSE_GLOBAL_MODAL = 'appGlobal/CLOSE_GLOBAL_MODAL';

export const SHOW_VIDEO = 'appGlobal/SHOW_VIDEO';
export const CLOSE_VIDEO = 'appGlobal/CLOSE_VIDEO';
