import React from 'react';
import styled from 'styled-components';

import { useI18n } from 'utils/i18nConnect';

const Wrapper = styled.p`
  text-align: center;
`;

const StyledSpan = styled.span`
  border: none;
  display: block;
  padding: 0;
`;

export const CallCenter = () => {
  const t = useI18n()('CallCenter');
  return (
    <Wrapper>
      <strong>
        {t`If you have any questions or queries, please contact us at:`}
      </strong>

      <StyledSpan>
        {t`support@britishpearl.com or call us on +44 203 657 7799`}
      </StyledSpan>
    </Wrapper>
  );
};

export default CallCenter;
