import React from 'react';
import PropTypes from 'prop-types';

import { useI18n } from 'utils/i18nConnect';

/**
 * Generates Property Subtitle, which is simple description, ie "2-bedroom flat, freehold, buy to let (immediate)"
 */
export const Subtitle = ({ investment, className }) => {
  const i18n = useI18n();
  const propertyDescriptionInvestment = {
    BuyToLetRefurbishment: i18n('Property')`refurbished & tenanted`,
    BuyToLetImmediate: i18n('Property')`tenanted`,
    Development: i18n('Property')`development`,
  };

  const propertyDescriptionOwnership = {
    Freehold: i18n('Property')`freehold`,
    Leasehold: i18n('Property')`leasehold`,
  };

  const propertyDescriptionType = {
    House: i18n('Property')`house`,
    BlockOfFlats: i18n('Property')`Multiple units`,
    Flat: i18n('Property')`flat`,
  };

  if (!investment) {
    return null;
  }

  const {
    investmentType,
    property: {
      ownershipType,
      propertyType,
      amenities: { bedrooms },
    },
  } = investment.toJS();

  return (
    <span className={className}>
      {propertyType !== 'BlockOfFlats' &&
        bedrooms &&
        i18n('Property')`${Number(bedrooms)}:n-bedroom `}

      {propertyDescriptionType[propertyType]}

      {ownershipType && <>, {propertyDescriptionOwnership[ownershipType]}</>}

      {investmentType && <>, {propertyDescriptionInvestment[investmentType]}</>}
    </span>
  );
};

Subtitle.defaultProps = {
  className: undefined,
};

Subtitle.propTypes = {
  className: PropTypes.string,
  investment: PropTypes.shape({}).isRequired,
};

export default Subtitle;
