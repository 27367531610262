import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    titleLink: {
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  })
);
