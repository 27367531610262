import {
  validateEmail,
  validateRequired,
} from 'services/validation/fieldValidator';

export default (values, { i18n }) => ({
  email: validateEmail(
    i18n,
    values.get('email'),
    i18n('Validation')`your email address`
  ),
  password: validateRequired(i18n, values.get('password'), 'password'),
});
