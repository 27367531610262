import countries from './countries.json';

export const allCountries = countries.map(({ country }) => country);

export const allNationalities = [
  ...new Set(countries.map(({ nationality }) => nationality)),
];

export const isoCodeByNationalityOrCountry = input =>
  (countries.find(x => x.country === input || x.nationality === input) || {})
    .value;

export const filterByIsoCode = isoCode =>
  countries.find(x => x.value === isoCode) || {};

export const isUkIso = isoCode => ['GB', 'GG', 'IM', 'JE'].includes(isoCode);
