import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { Section } from 'components/Molecules/Section';
import { useI18n } from 'utils/i18nConnect';

export const FooterTradingName: FC = () => {
  const t = useI18n();
  return (
    <Section size="medium" color="grey">
      <Typography variant="body2">
        {t(
          'Footer'
        )`British Pearl \u00AE is the trading name of British Pearl Limited (Company No. 7151774), a company authorised and regulated by the Financial Conduct Authority (Register No. 674693) and British Pearl Finance Limited (Company No. 10575280 and Register No. 770867), which is an appointed representative of British Pearl Limited. Both companies are wholly owned subsidiaries of British Pearl Group Limited (Company No. 9701436) and all are registered in England and Wales at 14th Floor, 33 Cavendish Square, London, W1G 0PW`}
      </Typography>
    </Section>
  );
};
