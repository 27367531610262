import Calculator from './Calculator';
import DoubleRosette from './DoubleRosette';
import Dude from './Dude';
import Email from './Email';
import Lock from './Lock';
import Person from './Person';
import Tick from './Tick';
import TickShield from './TickShield';
import Wallet from './Wallet';

export {
  Calculator,
  DoubleRosette,
  Dude,
  Email,
  Lock,
  Person,
  Tick,
  TickShield,
  Wallet,
};
