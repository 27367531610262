import React from 'react';

const Contact = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M3.762 12.628l17.9025-7.8955-3.8238 12.5532-5.355-1.6955-.2757.8705 5.7951 1.8348a.4565.4565 0 0 0 .5745-.3022L22.828 4.046c.1138-.3736-.2636-.7084-.6209-.5508L2.2721 12.287c-.3868.1706-.3539.7301.0503.8542l4.8698 1.4946.2679-.8728-3.6982-1.135z" />
      <path d="M14.5497 11.184L7.856 14.449l1.3109 5.4287 5.3828-8.6936zm-6.0138 9.9704l-1.6535-6.8479a.4565.4565 0 0 1 .2436-.5174l8.4434-4.1183c.4114-.2007.8292.2615.5883.6506L9.3678 21.2875c-.2134.3447-.7367.261-.8319-.1331z" />
    </g>
  </svg>
);

Contact.defaultProps = {
  height: 24,
  width: 24,
};

export default Contact;
