import React from 'react';

const Team = ({ fill, height, width, ...props }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} {...props}>
    <g fill={fill}>
      <path d="M9.7202 16.5653c1.1338 0 2.139-1.0425 2.139-2.1391v-3.8504c0-1.5523-2.5037-2.567-4.706-2.567-2.2023 0-4.706 1.0147-4.706 2.567v3.8504c0 1.0966 1.0053 2.1391 2.139 2.1391v-.8556c-.654 0-1.2834-.6526-1.2834-1.2835v-3.8504c0-.8685 2.0798-1.7113 3.8504-1.7113s3.8504.8428 3.8504 1.7113v3.8504c0 .6309-.6293 1.2835-1.2834 1.2835v.8556zm5.1338.8557c1.1433 0 2.1391-.667 2.1391-1.7113v-3.8504c0-1.54-2.281-2.567-4.2782-2.567-.1455 0-.3028.008-.4675.0233l.0794.852a4.2274 4.2274 0 0 1 .3881-.0196c1.5781 0 3.4226.8304 3.4226 1.7113v3.8504c0 .4824-.5573.8556-1.2835.8556v.8556zm-7.7008-9.84c-1.2384 0-2.139-.895-2.139-2.139v-.8557c0-1.2441.9006-2.1391 2.139-2.1391s2.1391.895 2.1391 2.139v.8557c0 1.2441-.9007 2.1391-2.139 2.1391zm0-.8556c.767 0 1.2835-.5131 1.2835-1.2835v-.8556c0-.7703-.5164-1.2835-1.2835-1.2835-.767 0-1.2834.5132-1.2834 1.2835v.8556c0 .7704.5164 1.2835 1.2834 1.2835z" />
      <path d="M15.2818 22.5548V12.715h-.8556v9.84zm5.1339-3.4226c1.1432 0 2.1391-.6669 2.1391-1.7113v-3.8504c0-1.5399-2.281-2.5669-4.2782-2.5669-.2792 0-.5963.0284-.9254.0828l.1395.8441c.2848-.047.5557-.0712.7859-.0712 1.578 0 3.4226.8304 3.4226 1.7112v3.8504c0 .4825-.5573.8557-1.2835.8557v.8556z" />
      <path d="M20.8435 22.5548v-8.1286h-.8556v8.1286zm-10.6955 0V11.4314h-.8557v11.1234zM4.1585 11.4314v11.1234h.8556V11.4314zm2.5669 4.2783v6.8451h.8556v-6.8451zm5.9895-6.8452c-1.2384 0-2.1391-.895-2.1391-2.1391v-.8556c0-1.2442.9007-2.1392 2.1391-2.1392s2.1391.895 2.1391 2.1392v.8556c0 1.2441-.9007 2.1391-2.1391 2.1391zm0-.8556c.767 0 1.2835-.5132 1.2835-1.2835v-.8556c0-.7704-.5164-1.2835-1.2835-1.2835-.767 0-1.2835.5131-1.2835 1.2835v.8556c0 .7703.5165 1.2835 1.2835 1.2835zm5.9895 2.5669c-1.2384 0-2.1391-.895-2.1391-2.1391V7.581c0-1.244.9007-2.139 2.1391-2.139s2.1391.895 2.1391 2.139v.8557c0 1.2441-.9007 2.1391-2.139 2.1391zm0-.8556c.767 0 1.2835-.5132 1.2835-1.2835V7.581c0-.7703-.5164-1.2834-1.2835-1.2834-.767 0-1.2835.5131-1.2835 1.2834v.8557c0 .7703.5165 1.2835 1.2835 1.2835z" />
    </g>
  </svg>
);

Team.defaultProps = {
  fill: 'currentColor',
  height: 24,
  width: 24,
};

export default Team;
