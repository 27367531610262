import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import queryString from 'query-string';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectAccounts,
  makeSelectFundedWallet,
  makeSelectRoles,
  selectSessionLoading,
  makeSelectUser,
  selectUserAccreditationExpired,
  makeSelectIsLoggedIn,
} from 'store/SessionProvider/selectors';
import { accountsFindAllRequest } from 'store/SessionProvider/actions';
import { selectors as sessionSelectors } from '../../store/SessionProvider';
import { findRedirection } from './findRedirection';
import { LoaderOverlay } from '../../components/Atoms';

class InvestorAuth extends React.Component {
  componentDidMount() {
    const {
      accounts: accountsProp,
      dispatchAccountsFindAllRequest,
    } = this.props;
    const accounts = accountsProp || Map({});

    if (!this.hasAccounts(accounts)) {
      dispatchAccountsFindAllRequest();
    }
  }

  hasAccounts() {
    const { accounts } = this.props;

    return accounts && accounts.has('items') && accounts.get('items').size > 0;
  }

  render() {
    const {
      loggedIn,
      roles,
      location,
      children,
      user,
      userClassificationInfo,
      userAccreditationExpired,
      accounts: accountsProp,
      dispatchAccountsFindAllRequest,
      loading,
    } = this.props;

    const accounts = accountsProp || Map({});

    const urlParam = queryString.parse(location.search);

    if (!user && !urlParam.orderUuid && loading) {
      return <LoaderOverlay active />;
    }

    this.hasAccounts();

    if (urlParam.orderUuid && !this.hasAccounts(accounts)) {
      dispatchAccountsFindAllRequest();
      return null;
    }

    const redirectTo = findRedirection({
      loggedIn,
      roles,
      currentPathname: location.pathname,
      userClassified: !!userClassificationInfo,
      userAccreditationExpired,
    });

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return children;
  }
}

InvestorAuth.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({}),
  userAccreditationExpired: PropTypes.bool.isRequired,
  userClassificationInfo: PropTypes.shape({}),
  accounts: PropTypes.shape({}),
  dispatchAccountsFindAllRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
};

InvestorAuth.defaultProps = {
  roles: [],
  accounts: null,
  user: null,
  userClassificationInfo: null,
  loading: false,
  loggedIn: false,
};

export default withRouter(
  connect(
    createStructuredSelector({
      roles: makeSelectRoles(),
      user: makeSelectUser(),
      userAccreditationExpired: selectUserAccreditationExpired,
      userClassificationInfo: sessionSelectors.selectClassificationInfo,
      fundedWallet: makeSelectFundedWallet(),
      accounts: makeSelectAccounts(),
      loading: selectSessionLoading,
      loggedIn: makeSelectIsLoggedIn(),
    }),
    { dispatchAccountsFindAllRequest: accountsFindAllRequest }
  )(InvestorAuth)
);
