import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import media from 'utils/responsive';

import Breakdown from './Breakdown';
import Overview from './Overview';

const Items = styled.div`
  margin-bottom: 2rem;

  ${media.large`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr;

    ${({ open }) =>
      open &&
      css`
        min-height: 25rem;
      `}
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${media.medium`
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    -ms-grid-row: 1;
    -ms-grid-column: 1;

    & ~ & {
      grid-column: 2 / 3;

      -ms-grid-column: 2;
    }

    & ~ & ~ & {
      grid-column: 3 / 4;
      -ms-grid-column: 3;
    }
  `};
`;

const Panel = styled(({ open, ...props }) => <div {...props} />)`
  display: none;

  ${({ open }) =>
    open &&
    css`
      display: block;
    `} ${media.large`
    grid-column: 1 / 4;
    grid-row: 2 / 3;

    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 2;
  `};
`;

export class AccountOverview extends React.PureComponent {
  state = {
    tabIndex: -1,
  };

  handleSelect = index => {
    const { tabIndex } = this.state;
    this.setState({ tabIndex: index !== tabIndex ? index : -1 });
  };

  render() {
    const { tabIndex } = this.state;
    const { className, data } = this.props;

    return (
      <Items className={className} open={tabIndex > -1}>
        {data.value && (
          <Fragment key="value">
            <Header>
              <Overview
                clickHandler={() => this.handleSelect(0)}
                id={0}
                itemSelected={tabIndex}
                label={data.value.label}
                large
                sum={data.value.data.reduce((a, b) => a + b.itemAmount, 0)}
              />
            </Header>

            <Panel open={tabIndex === 0}>
              <Breakdown breakdown={data.value.data} />
            </Panel>
          </Fragment>
        )}

        {data.profitLoss && (
          <Fragment key="profitLoss">
            <Header>
              <Overview
                clickHandler={() => this.handleSelect(1)}
                id={1}
                itemSelected={tabIndex}
                label={data.profitLoss.label}
                sum={data.profitLoss.sum}
              />
            </Header>

            <Panel open={tabIndex === 1}>
              <Breakdown breakdown={data.profitLoss.data} />
            </Panel>
          </Fragment>
        )}

        {data.netCash && (
          <Fragment key="netCash">
            <Header>
              <Overview
                clickHandler={() => this.handleSelect(2)}
                id={2}
                itemSelected={tabIndex}
                label={data.netCash.label}
                sum={data.netCash.data.reduce((a, b) => a + b.itemAmount, 0)}
              />
            </Header>

            <Panel open={tabIndex === 2}>
              <Breakdown breakdown={data.netCash.data} />
            </Panel>
          </Fragment>
        )}
      </Items>
    );
  }
}

AccountOverview.defaultProps = {
  className: '',
  data: {},
};

export default AccountOverview;
