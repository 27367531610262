import { isEmpty } from 'lodash';

import api from '../endpoints/Accounts';

export default class AccountDashboard {
  /**
   * Get account dashboard
   */
  static get() {
    return api.dashboard
      .get()
      .then(data => {
        if (!isEmpty(data)) {
          return data;
        }

        return Promise.reject();
      })
      .catch(err => Promise.reject(err.message || err));
  }
}
