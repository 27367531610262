const root = '/refer-friend';

module.exports = {
  referFriendPaths: {
    root: () => root,
    register: () => `${root}/register`,
    details: () => `${root}/details`,
    termsAndConditions: () => `${root}/terms-and-conditions`,
  },
};
