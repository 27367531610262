module.exports = {
  investorPaths: {
    investor: () => '/investor',
    dashboard: () => '/investor/dashboard',
    mainAccount: () => '/investor/main-account',
    isaAccount: () => '/investor/isa-account',
    documents: () => '/investor/documents',
    notices: () => '/investor/notices',
    profile: () => '/investor/profile',
    taxStatement: () => '/investor/tax-statement',
    referFriend: () => '/investor/refer-friend',
    wallet: () => '/investor/wallet',
    activity: () => '/investor/activity',
    fundWallet: () => '/investor/wallet/fund-wallet',
    transferFunds: () => '/investor/wallet/transfer-funds',
    withdrawFunds: () => '/investor/wallet/withdraw-funds',
  },
};
