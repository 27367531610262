export const ACCREDITATION_SCREENING =
  'OnboardingAccreditation/ACCREDITATION_SCREENING';

export const QUESTION_ANSWERED = 'OnboardingAccreditation/QUESTION_ANSWERED';
export const TOGGLE_QUIZ_RESULTS =
  'OnboardingAccreditation/TOGGLE_QUIZ_RESULTS';
export const QUIZ_STATUS_REQUEST =
  'OnboardingAccreditation/QUIZ_STATUS_REQUEST';
export const QUIZ_STATUS_SUCCESS =
  'OnboardingAccreditation/QUIZ_STATUS_SUCCESS';
export const QUIZ_STATUS_ERROR = 'OnboardingAccreditation/QUIZ_STATUS_ERROR';
export const QUIZ_ATTEMPT_REQUEST =
  'OnboardingAccreditation/QUIZ_ATTEMPT_REQUEST';
export const QUIZ_ATTEMPT_SUCCESS =
  'OnboardingAccreditation/QUIZ_ATTEMPT_SUCCESS';
export const QUIZ_ATTEMPT_ERROR = 'OnboardingAccreditation/QUIZ_ATTEMPT_ERROR';
export const QUIZ_COMPLETE = 'OnboardingAccreditation/QUIZ_COMPLETE';

export const CLASSIFY_REQUEST = 'OnboardingAccreditation/CLASSIFY_REQUEST';
export const CLASSIFY_SUCCESS = 'OnboardingAccreditation/CLASSIFY_SUCCESS';
export const CLASSIFY_ERROR = 'OnboardingAccreditation/CLASSIFY_ERROR';
export const CLASSIFY_SET_RISKS_ACCEPTED =
  'OnboardingAccreditation/CLASSIFY_SET_RISKS_ACCEPTED';
