// Build-time generated content based on the json files in the app/blog-posts directory
// Result is an object with keys to the url and dynamic imports of the json file
// For example:
// {
//  '/8-proptech-companies-you-need-to-know-about': {
//    dynamicImport: () => import('...'),
//  },
//  ...
// }
// eslint-disable-next-line import/no-unresolved
import content from '../../../../blogpostsData';

export default {
  content: {
    get: url => content[url].dynamicImport(),
    getAll: () =>
      Promise.all(
        Object.keys(content).map(key => content[key].dynamicImport())
      ),
  },
};
