import React from 'react';

import { Plug } from 'components/Atoms/Icons';

import { Error as Template } from 'components/Templates';

import { i18nConnect } from 'utils/i18nConnect';

export const ErrorInternalComponent = ({ i18n }) => (
  <Template
    description={i18n('ErrorInternal')`Something has gone wrong`}
    Icon={Plug}
    message={i18n('ErrorInternal')`Something has gone wrong`}
    title={i18n('ErrorInternal')`Error`}
  />
);

export const ErrorInternal = i18nConnect(ErrorInternalComponent);
