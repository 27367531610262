import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';

import { H2 } from 'components/Atoms/Heading';
import { Section } from 'components/Molecules/Section';
import { ExpandableBlock } from 'components/Molecules/ExpandableBlock';
import { useI18n } from 'utils/i18nConnect';
import { FaqItem } from './types';

interface FaqSectionProps {
  faqList: FaqItem[];
}

export const FaqSection: FC<FaqSectionProps> = ({ faqList }) => {
  const t = useI18n()('FaqSection');

  return (
    <Section size="large">
      <Box textAlign="center" mb={8}>
        <H2>{t`Frequently asked questions`}</H2>
      </Box>
      <Grid container justify="center">
        <Grid item xs={12} lg={10} xl={8}>
          {faqList.map(data => (
            <ExpandableBlock
              key={data.id}
              title={data.question}
              text={data.answer}
            />
          ))}
        </Grid>
      </Grid>
    </Section>
  );
};
