import React, { FC } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { useStyles } from './UnorderedList.styles';

export const UnorderedList: FC<TypographyProps> = ({
  children,
  variant = 'body2',
  paragraph,
}) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.root}
      component="ul"
      variant={variant}
      paragraph={paragraph}
    >
      {children}
    </Typography>
  );
};
