import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useExpansionPanelStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      backgroundColor: palette.grey[100],
      boxShadow: 'none',
      marginBottom: spacing(2),
      borderRadius: '16px',
      '&:first-child': {
        borderRadius: '16px',
      },
      '&:last-child': {
        marginBottom: spacing(0),
        borderRadius: '16px',
      },
      '&:before': {
        display: 'none',
      },
    },
  })
);

export const useExpansionPanelSummaryStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      minHeight: spacing(8),
      '&$expanded': {
        minHeight: spacing(8),
      },
    },
    content: {
      '&$expanded': {
        margin: spacing(1.5, 0),
      },
    },
    expanded: {},
  })
);
