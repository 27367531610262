import React from 'react';
import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

import { FeaturedBy } from 'components/Molecules/FeaturedBy';
import { CapitalAtRisk } from 'components/Molecules/CapitalAtRisk';
import { Public as Template } from 'components/Templates';
import { i18nConnect } from 'utils/i18nConnect';
import { CashbackHero } from 'components/Molecules/CashbackHero';
import { AboutBanner } from 'components/Molecules/AboutBanner';
import { KeyItemsSection } from 'components/Molecules/KeyItemsSection';

import { securityItems } from '../data';
import Benefits from './Benefits';
import FeaturedInvestments from './FeaturedInvestments';

export const Home = ({ i18n, user }) => (
  <Template
    testId="homepage"
    title={i18n('Home')`UK's Leading Property Investment Platform`}
    description={i18n(
      'Home'
    )`British Pearl is the UK's leading property investment platform. Gain easy access to the property market and find out how to invest as a shareholder or lender.`}
  >
    <CashbackHero
      title={
        <>
          Hassle-free
          <br />
          property investing
        </>
      }
      text={<>Build a diversified property portfolio online</>}
      isLoggedIn={!!user}
    />

    <CapitalAtRisk color="white" />

    <AboutBanner />

    <FeaturedInvestments />

    <Benefits heading={i18n('Home')`Why invest with us`} />

    <Divider />

    <KeyItemsSection
      heading={i18n('Home')`Have peace of mind`}
      items={securityItems(i18n)}
    />

    <FeaturedBy withCaption />
  </Template>
);

Home.propTypes = {
  i18n: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    attributionGroup: PropTypes.string,
    lastName: PropTypes.string,
    birthDate: PropTypes.string,
    addressId: PropTypes.number,
    role: PropTypes.string,
    nationalityIsoCode: PropTypes.string,
    phoneNumber: PropTypes.string,
    title: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
  }),
};

Home.defaultProps = {
  user: null,
};

export default i18nConnect(Home);
