import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import media from 'utils/responsive';

import appConfig from 'config';

const RawButton = ({
  className,
  label,
  ghost = false,
  buttonType,
  buttonDestination,
  appearance = 'default',
  onClick = () => {},
}) => {
  const classNames = [className, ghost && 'ghost', appearance].join(' ');

  switch (buttonType) {
    case 'anchor':
      return (
        <ScrollLink
          className={classNames}
          to={buttonDestination}
          smooth
          duration={500}
          offset={-150}
        >
          {label}
        </ScrollLink>
      );

    case 'openModal':
      return (
        <button type="button" onClick={onClick} className={classNames}>
          {label}
        </button>
      );

    case 'internalLink':
      return (
        <Link className={classNames} to={buttonDestination}>
          {label}
        </Link>
      );

    default:
      return (
        <a
          className={classNames}
          href={buttonDestination}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </a>
      );
  }
};

const Button = styled(RawButton)`
  display: inline-block;
  min-width: ${({ isCompact }) => (isCompact ? '130px' : '176px')};
  padding: 1rem 0.5rem;

  border: none;
  outline: 0;
  box-shadow: none;
  background: transparent;

  font-size: 1rem;
  font-family: ${appConfig.theme.fontFamily.base.join(', ')};
  font-weight: ${appConfig.theme.fontWeight.medium};
  line-height: 1;
  white-space: nowrap;
  text-align: center;

  cursor: pointer;
  border-radius: 1.25rem;
  transition: box-shadow 0.3s;

  ${media.small`
    min-width: 140px;
  `}

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }

  &.light {
    color: ${appConfig.theme.colors.pomegranate};
    background-color: ${appConfig.theme.colors.white};
  }

  &.defaultInverted {
    color: ${appConfig.theme.colors.pomegranate};
    background-color: ${appConfig.theme.colors.white};
  }

  &.default {
    color: ${appConfig.theme.colors.white};
    background: ${appConfig.theme.button.cta.background};
  }

  &.dark {
    color: ${appConfig.theme.colors.black};
    background-color: ${appConfig.theme.colors.white};
  }

  &.ghost {
    border: 0.125rem solid;
    background: transparent;
    padding: 0.9rem 0.5rem;

    &.light {
      color: ${appConfig.theme.colors.white};
      border-color: ${appConfig.theme.colors.white};
    }

    &.default {
      color: ${appConfig.theme.colors.pomegranate};
      border-color: ${appConfig.theme.colors.pomegranate};
    }

    &.defaultInverted {
      color: ${appConfig.theme.colors.pomegranate};
      border-color: ${appConfig.theme.colors.white};
    }

    &.dark {
      color: ${appConfig.theme.colors.white};
      background-color: ${appConfig.theme.colors.white};
    }
  }
`;

Button.propTypes = {
  label: propTypes.string.isRequired,
  appearance: propTypes.oneOf(['light', 'default', 'defaultInverted', 'dark']),
  buttonDestination: propTypes.string,
  buttonType: propTypes.oneOf(['anchor', 'openModal', 'internalLink']),
  ghost: propTypes.bool,
  onClick: propTypes.func,
};

export default Button;
