import React, { FC } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { ThemeNames } from './types';
import { themeMap } from './themeMap';

interface ThemeProviderProps {
  themeName?: ThemeNames;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
  themeName = 'core',
}) => (
  <MuiThemeProvider theme={themeMap[themeName]}>{children}</MuiThemeProvider>
);
