import React, { FC } from 'react';
import { Box, CircularProgress, Collapse } from '@material-ui/core';

import { useI18n } from 'utils/i18nConnect';
import { investorPaths, loginPaths } from 'router/paths';
import { SessionAccounts, SessionUser } from 'store/SessionProvider';
import { Pip } from 'services/Pip';
import { NavigationMenu } from '../NavigationMenu';
import { useStyles } from './LoggedInMenu.styles';
import { MenuTrigger } from './MenuTrigger';

export const getAccountTypeString = (accountType: Pip.AccountType): string =>
  accountType === Pip.AccountType.LoanIsa ? 'ISA' : 'Main';

interface LoggedInMenuProps {
  accounts?: SessionAccounts;
  user: SessionUser;
  loading?: boolean;
}

export const LoggedInMenu: FC<LoggedInMenuProps> = ({
  user,
  accounts,
  loading,
}) => {
  const t = useI18n()('LoggedInMenu');
  const classes = useStyles();

  return (
    <NavigationMenu
      navLinks={[
        { label: t`Account`, to: investorPaths.dashboard() },
        { label: t`Add funds`, to: investorPaths.fundWallet() },
        { label: t`Logout`, to: loginPaths.logout() },
      ]}
      component={MenuTrigger}
    >
      <div className={classes.root}>
        <div data-test-id="logged-in-user-name" className={classes.userName}>
          {`${user.get('firstName')} ${user.get('lastName')}`}
        </div>
        <Collapse in={loading}>
          <Box py={1}>
            <CircularProgress size={18} thickness={2} />
          </Box>
        </Collapse>
        <Collapse in={!loading}>
          {accounts && (
            <Box display="flex">
              {accounts
                .get('items')
                .sort(a =>
                  a.get('accountType') === Pip.AccountType.LoanIsa ? 1 : -1
                )
                .map(accountDetailed => (
                  <div
                    className={classes.account}
                    key={accountDetailed.get('accountType')}
                  >
                    <Box fontWeight="bold">
                      {t`${accountDetailed.get(
                        'totalReservedAndBalanceAmount'
                      )}:c`}
                    </Box>
                    <Box fontWeight="normal" textAlign="rights">
                      {getAccountTypeString(accountDetailed.get('accountType'))}
                    </Box>
                  </div>
                ))}
            </Box>
          )}
        </Collapse>
      </div>
    </NavigationMenu>
  );
};
