import React, { FC, useCallback, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { TransferIsaModal } from 'components/Molecules/TransferIsaModal';
import { Button, ButtonLink } from 'components/Atoms/Button';
import { H2 } from 'components/Atoms/Heading';
import { investorPaths, propertyPaths } from 'router/paths';
import { useI18n } from 'utils/i18nConnect';

import { useStyles } from './CalculatorSectionIsaLoansText.styles';

export interface CalculatorSectionIsaLoansTextProps {
  isaAccountOpened: boolean;
  loggedIn: boolean;
}

export const CalculatorSectionIsaLoansText: FC<CalculatorSectionIsaLoansTextProps> = ({
  isaAccountOpened,
  loggedIn,
}) => {
  const t = useI18n()('Molecules.CalculatorSectionIsaLoansText');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  return (
    <div>
      <H2 paragraph>
        {t`Calculate the interest you can earn on your ISA savings with our investments`}
      </H2>
      <ul className={classes.ul}>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`Enter your investment amount`}
        </Typography>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`Select your property investment`}
        </Typography>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`See returns by month, year and term`}
        </Typography>
        <Typography
          className={classes.li}
          variant="body1"
          component="li"
          gutterBottom
        >
          {t`See how much your savings could grow`}
        </Typography>
      </ul>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={6} xl={5}>
          {isaAccountOpened ? (
            <ButtonLink
              fullWidth
              to={propertyPaths.loans()}
              variant="outlined"
              color="primary"
            >
              {t`View properties`}
            </ButtonLink>
          ) : (
            <ButtonLink
              fullWidth
              to={investorPaths.isaAccount()}
              variant="outlined"
              color="primary"
            >
              {t`Open ISA`}
            </ButtonLink>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6} xl={5}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={openModal}
          >
            {t`Transfer ISA`}
          </Button>
        </Grid>
      </Grid>
      <TransferIsaModal loggedIn={loggedIn} open={open} onClose={closeModal} />
    </div>
  );
};
