const PARTICIPATION_FEE_PERCENT = 0.02;

export const Market = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const Periods = {
  ANNUAL: 'annual',
  MONTH: 'month',
  TERM: 'term',
};

const PeriodsData = {
  [Periods.ANNUAL]: {
    valueFunction: baseValue => baseValue,
  },
  [Periods.MONTH]: {
    valueFunction: baseValue => baseValue / 12,
  },
  [Periods.TERM]: {
    valueFunction: (baseValue, monthsUntilExit) =>
      (baseValue * monthsUntilExit) / 12,
  },
};

// For reasons unknown, in the primary market we round up instead of down.
// It was decided to use rounding down here for both markets and until the backend
// is fixed, the eventual fee will be a penny higher
const participationFee = amount =>
  Math.floor(amount * PARTICIPATION_FEE_PERCENT);

const roundToDecimalPlaces = (number, numberOfDecimalPlaces) =>
  Number(number.toFixed(numberOfDecimalPlaces));

const convertMinus0ToZero = number => (Object.is(number, -0) ? 0 : number);

const calculateEstimatedCapitalGainAnnualPercent = ({
  marketType,
  capitalGain,
  purchasePrice,
  estimatedShareUnitPrice,
  monthsUntilExit,
}) => {
  if (marketType === Market.SECONDARY) {
    const estimatedProfit = purchasePrice / estimatedShareUnitPrice - 1;
    return roundToDecimalPlaces(
      capitalGain - estimatedProfit / (monthsUntilExit / 12),
      4
    );
  }
  return capitalGain / (purchasePrice / 100);
};

// Math.round() is used to fix floating point issues to the nearest penny
// Such as (0.1 + 0.2 = 0.30000000000000004)
const valuePerPeriod = (...args) =>
  Object.values(Periods).reduce(
    (acc, period) => ({
      ...acc,
      [period]: Math.round(PeriodsData[period].valueFunction(...args)),
    }),
    {}
  );

const sumPerPeriod = (baseObj, objOrValue) =>
  Object.keys(baseObj).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]:
        baseObj[curr] +
        (typeof objOrValue === 'object' ? objOrValue[curr] : objOrValue),
    }),
    {}
  );

export function calculateShareReturns({
  investmentAmount,
  purchasePrice,
  monthsUntilExit,
  termInYears,
  dividendReturn,
  capitalGain,
  marketType,
  estimatedShareUnitPrice,
}) {
  // All prices at this point are assumed to be in pennies

  const unitsPurchased = Math.floor(
    investmentAmount / (purchasePrice * (1 + PARTICIPATION_FEE_PERCENT))
  );
  const unitsCost = unitsPurchased * purchasePrice;
  const participationFees = participationFee(unitsCost);
  const totalCost = unitsCost + participationFees;
  const estimatedIncomeAnnualPercent = roundToDecimalPlaces(
    dividendReturn / (purchasePrice / 100),
    4
  );
  const estimatedCapitalGainAnnualPercent = calculateEstimatedCapitalGainAnnualPercent(
    {
      marketType,
      capitalGain,
      purchasePrice,
      estimatedShareUnitPrice,
      monthsUntilExit,
    }
  );
  const estimatedAnnualPercentages = {
    estimatedIncome: estimatedIncomeAnnualPercent,
    estimatedCapitalGain: estimatedCapitalGainAnnualPercent,
    estimatedReturn:
      estimatedIncomeAnnualPercent + estimatedCapitalGainAnnualPercent,
  };
  const estimatedIncome = valuePerPeriod(
    totalCost * dividendReturn,
    monthsUntilExit
  );
  const estimatedCapitalGain = valuePerPeriod(
    totalCost * estimatedCapitalGainAnnualPercent,
    monthsUntilExit
  );
  const estimatedReturn = sumPerPeriod(estimatedIncome, estimatedCapitalGain);
  const totalInvestmentAndReturn = sumPerPeriod(estimatedReturn, totalCost);

  const totalCapitalGain = roundToDecimalPlaces(
    marketType === Market.PRIMARY
      ? capitalGain * termInYears
      : (estimatedCapitalGainAnnualPercent * monthsUntilExit) / 12,
    4
  );

  return {
    unitsPurchased,
    unitsCost,
    participationFees,
    totalCost,
    estimatedAnnualPercentages,
    estimatedIncome,
    estimatedCapitalGain,
    estimatedReturn,
    totalCapitalGain,
    totalInvestmentAndReturn,
  };
}

export function calculateLoanReturns({
  investmentAmount,
  purchasePrice,
  monthsUntilExit,
  loanInterestRate,
}) {
  // All prices at this point are assumed to be in pennies

  const principalPurchased = roundToDecimalPlaces(
    Math.floor(investmentAmount / purchasePrice) * 100,
    2
  );
  const totalCost = roundToDecimalPlaces(
    (principalPurchased * purchasePrice) / 100,
    2
  );
  const unroundedEstimatedAnnualIncomePercentage =
    loanInterestRate / (purchasePrice / 100);
  const unroundedEstimatedAnnualCapitalGainPercentage = convertMinus0ToZero(
    ((1 / (purchasePrice / 100) - 1) / monthsUntilExit) * 12
  );
  const estimatedAnnualPercentages = {
    estimatedIncome: roundToDecimalPlaces(
      unroundedEstimatedAnnualIncomePercentage,
      4
    ),
    estimatedCapitalGain: roundToDecimalPlaces(
      unroundedEstimatedAnnualCapitalGainPercentage,
      4
    ),
  };
  estimatedAnnualPercentages.estimatedReturn = roundToDecimalPlaces(
    estimatedAnnualPercentages.estimatedIncome +
      estimatedAnnualPercentages.estimatedCapitalGain,
    4
  );
  const estimatedIncome = valuePerPeriod(
    principalPurchased * loanInterestRate,
    monthsUntilExit
  );
  const estimatedCapitalGain = valuePerPeriod(
    totalCost * unroundedEstimatedAnnualCapitalGainPercentage,
    monthsUntilExit
  );
  const estimatedReturn = sumPerPeriod(estimatedIncome, estimatedCapitalGain);
  const totalInvestmentAndReturn = sumPerPeriod(estimatedReturn, totalCost);

  return {
    principalPurchased,
    participationFees: 0,
    totalCost,
    estimatedAnnualPercentages,
    estimatedIncome,
    estimatedCapitalGain,
    estimatedReturn,
    totalInvestmentAndReturn,
  };
}
